import React, { useCallback, useLayoutEffect } from 'react';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { IconButton } from 'react-native-paper';
import { ActivityIndicator, View } from 'react-native';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from '@emotion/native';
import Layout from '../../Component/Layout';
import { Button, CSTheme } from '../../Component';
import { useDownloadFile } from '../../../Infrastructure/Hook';
import { CareshipColors } from '../../Component/CSTheme';
import { DownloadIcon } from '../../Component/Icon';
import { CustomerStackNavigationProp } from '../Navigator/CustomerStackNavigationProp';
import { CustomerNavigationParamList } from '../Navigator/CustomerStackNavigator';
import PdfReader from '../../Component/PdfReader';

type PdfScreen = RouteProp<CustomerNavigationParamList, 'PdfView'>;

const ButtonContainerShadow = styled(View)({
  display: 'flex',
  flexDirection: 'row',
  shadowColor: 'rgb(0,0,0)',
  shadowOffset: {
    height: 0,
    width: 0,
  },
  shadowOpacity: 0.2,
  shadowRadius: 8,
  elevation: 3,
});

const ButtonContainer = styled(View)({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: 'white',
  flexGrow: 1,
  justifyContent: 'center',
  padding: 16,
});

export default function ServiceDocumentationPdfScreen() {
  const intl = useIntl();
  const navigation = useNavigation<CustomerStackNavigationProp<'PdfView'>>();
  const {
    params: { uri, filename, headerTitle },
  } = useRoute<PdfScreen>();
  const { download, isDownloadInProgress, isDownloadLoading } = useDownloadFile();

  const handleSharePdf = useCallback<() => void>(
    () => download(filename, uri),
    [download, filename, uri],
  );

  useLayoutEffect(() => {
    navigation.setOptions({
      headerTitle,
      headerBackTitle: intl.formatMessage({ id: 'HEADER_BACK_BUTTON' }),
      headerRight: () => {
        return (
          <IconButton
            onPress={handleSharePdf}
            style={{ margin: -8 }}
            disabled={isDownloadInProgress || isDownloadLoading}
            icon={
              isDownloadLoading
                ? () => <ActivityIndicator color={CSTheme.colors.background} />
                : () => <DownloadIcon color={CareshipColors.white} />
            }
          />
        );
      },
    });
  }, [handleSharePdf, isDownloadInProgress, isDownloadLoading, navigation, headerTitle, intl]);

  return (
    <Layout>
      <PdfReader
        style={{ flex: 1 }}
        source={{
          uri,
        }}
      />
      <ButtonContainerShadow>
        <ButtonContainer>
          <Button
            onPress={handleSharePdf}
            loading={isDownloadInProgress || isDownloadLoading}
            disabled={isDownloadInProgress || isDownloadLoading}
          >
            <FormattedMessage id="SERVICE_DOCUMENTATION_PDF_VIEW_DOWNLOAD" />
          </Button>
        </ButtonContainer>
      </ButtonContainerShadow>
    </Layout>
  );
}
