/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import Constants from 'expo-constants';
import { Link } from '@react-navigation/native';
import React, { ReactElement, useCallback } from 'react';
import styled from '@emotion/native';
import { Platform } from 'react-native';
import * as t from 'io-ts';
import { FormattedMessage, useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import { ioTsResolver } from '@hookform/resolvers/io-ts';
import { graphql } from 'react-relay/hooks';
import { emailAddress } from '../../../Infrastructure/Validation';
import { CareshipColors } from '../../Component/CSTheme';
import { Alert, Button, Container, InputWithValidation, Text } from '../../Component';
import {
  EnvironmentEnum,
  RequestPasswordResetFormMutation,
} from './__generated__/RequestPasswordResetFormMutation.graphql';
import useLogger from '../../../Infrastructure/Hook/useLogger';
import { useAsyncMutation } from '../../../Infrastructure/Relay';
import AuthTitle from './AuthTitle';
import Manifest from '../../../Infrastructure/Constants/Manifest';

const Form = styled.View({
  width: '100%',
  maxWidth: 400,
});

const Success = styled.View({
  width: '100%',
  maxWidth: 400,
  marginBottom: 'auto',
});

const Input = styled(InputWithValidation)({
  height: 64,
  width: '100%',
}) as typeof InputWithValidation;

const ResetPasswordLink = styled(Text)({
  textAlign: 'center',
  marginTop: 8,
  padding: 16,
  width: '100%',
  marginBottom: 16,
  color: CareshipColors.slate300,
  fontSize: 14,
  textDecorationLine: 'none',
}).withComponent(Link);

const Wrapper = styled.View({
  flex: 1,
  alignItems: 'center',
  justifyContent: 'center',
  paddingHorizontal: 16,
  paddingVertical: 32,
  backgroundColor: Platform.select({
    native: '#fff',
  }),
});

const schema = t.type({
  username: emailAddress,
});

const Btn = styled(Button)({
  marginTop: Platform.select({ web: 8 }),
});

function SuccessMessage(): ReactElement {
  return (
    <Wrapper>
      <Success>
        <AuthTitle>
          <FormattedMessage id="FORGOTTEN_PASSWORD_REQUEST_SUCCESS" />
        </AuthTitle>
        <Text center>
          <FormattedMessage id="FORGOTTEN_PASSWORD_EMAIL_SENT" />
        </Text>
        <ResetPasswordLink to="/login">
          <FormattedMessage id="FORGOTTEN_PASSWORD_REQUEST_LOGIN_BUTTON" />
        </ResetPasswordLink>
      </Success>
    </Wrapper>
  );
}

const environmentInventoryName = Constants?.expoConfig?.extra?.environmentInventoryName;

const environmentName: string =
  Platform.OS === 'web' ? Manifest.extra.environmentInventoryName : environmentInventoryName;

export default function RequestPasswordResetForm(): ReactElement {
  const intl = useIntl();
  const logger = useLogger();
  const [commit] = useAsyncMutation<RequestPasswordResetFormMutation>(
    graphql`
      mutation RequestPasswordResetFormMutation($input: RequestCaregiverPasswordResetInput!) {
        requestCaregiverPasswordReset(input: $input) {
          status
        }
      }
    `,
  );

  const {
    handleSubmit,
    control,
    setError,
    formState: { isSubmitting, errors, isSubmitSuccessful },
  } = useForm<{ username: string; serverError?: string }>({
    mode: 'onTouched',
    defaultValues: {
      username: '',
    },
    resolver: ioTsResolver(schema),
  });

  const onSubmit = useCallback(
    async (data: { username: string }) => {
      try {
        await commit({
          variables: {
            input: {
              email: data.username,
              environment: environmentName
                .toLocaleUpperCase()
                .replace('-', '_') as unknown as EnvironmentEnum,
            },
          },
        });
      } catch (error) {
        logger.error(
          `Failed requesting password reset: ${
            error instanceof Error ? error.message : JSON.stringify(error)
          }`,
        );
        setError('serverError', {
          type: 'internal',
          message: intl.formatMessage({ id: 'ERROR_UNKNOWN' }),
        });
      }
    },
    [commit, intl, logger, setError],
  );

  const handlePressSubmit = useCallback<() => void>(
    () => handleSubmit(onSubmit)(),
    [handleSubmit, onSubmit],
  );

  if (isSubmitSuccessful) {
    return <SuccessMessage />;
  }

  return (
    <Wrapper>
      <Form>
        <Container marginBottom="base">
          <AuthTitle>
            <FormattedMessage id="FORGOTTEN_PASSWORD" />
          </AuthTitle>
          <Text center marginBottom="loose">
            <FormattedMessage id="FORGOTTEN_PASSWORD_LEGEND" />
          </Text>
        </Container>
        {errors.serverError?.message && (
          <Alert type="error">
            <Text>{errors.serverError.message}</Text>
          </Alert>
        )}
        <Input
          marginBottom="loose"
          control={control}
          name="username"
          label={intl.formatMessage({ id: 'EMAIL' })}
          autoComplete="username"
          keyboardType="email-address"
          autoCapitalize="none"
          accessibilityLabel="username"
          accessibilityHint="My Careship Username"
          disabled={isSubmitting}
          testID="username"
        />
        <Btn
          disabled={isSubmitting}
          accessibilityRole="button"
          accessibilityLabel="submit"
          accessibilityHint="Submit password request form"
          onPress={handlePressSubmit}
          testID="submit"
        >
          <FormattedMessage id="FORGOTTEN_PASSWORD_REQUEST_BUTTON" />
        </Btn>

        <ResetPasswordLink to="/login?isPasswordReset=false">
          <FormattedMessage id="BUTTON_CANCEL" />
        </ResetPasswordLink>
      </Form>
    </Wrapper>
  );
}
