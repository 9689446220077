export interface AddressResponse {
  readonly street: string;
  readonly house_number: string;
  readonly postal_code: string;
  readonly city: string;
  readonly additional_information: string;
}

export interface AddressObject {
  readonly street: string;
  readonly houseNumber: string;
  readonly postalCode: string;
  readonly city: string;
  readonly additionalInformation: string;
}

export default class Address {
  constructor(
    public readonly street: string,
    public readonly houseNumber: string,
    public readonly postalCode: string,
    public readonly city: string,
    public readonly additionalInformation: string,
  ) {}

  public static fromObject(address: AddressObject) {
    return new Address(
      address.street,
      address.houseNumber,
      address.postalCode,
      address.city,
      address.additionalInformation,
    );
  }

  static fromResponse(response: AddressResponse) {
    return new Address(
      response.street,
      response.house_number,
      response.postal_code,
      response.city,
      response.additional_information,
    );
  }
}
