import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';
import { CareshipColors } from '../CSTheme';

interface UserPlusIconProps extends SvgProps {
  color?: string;
  size?: number;
}

function UserPlusIcon({ color = CareshipColors.slate400, size = 24, ...props }: UserPlusIconProps) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" {...props}>
      <Path
        fill={color}
        d="M8.60714286,4 C6.06268812,4 4,6.06268812 4,8.60714286 C4,11.1515976 6.06268812,13.2142857 8.60714286,13.2142857 C11.1515976,13.2142857 13.2142857,11.1515976 13.2142857,8.60714286 C13.2142857,6.06268812 11.1515976,4 8.60714286,4 Z M8.60714286,5.5 C10.3231705,5.5 11.7142857,6.89111524 11.7142857,8.60714286 C11.7142857,10.3231705 10.3231705,11.7142857 8.60714286,11.7142857 C6.89111524,11.7142857 5.5,10.3231705 5.5,8.60714286 C5.5,6.89111524 6.89111524,5.5 8.60714286,5.5 Z M8.60714286,13.8569906 C13.1013652,13.8569906 16.2142857,17.5073549 16.2142857,22.3212763 C16.2142857,22.7354899 15.8784993,23.0712763 15.4642857,23.0712763 C15.0500722,23.0712763 14.7142857,22.7354899 14.7142857,22.3212763 C14.7142857,18.2874946 12.2152403,15.3569906 8.60714286,15.3569906 C4.99904544,15.3569906 2.5,18.2874946 2.5,22.3212763 C2.5,22.7354899 2.16421356,23.0712763 1.75,23.0712763 C1.33578644,23.0712763 1,22.7354899 1,22.3212763 C1,17.5073549 4.11292056,13.8569906 8.60714286,13.8569906 Z M19.75,1 C20.1296958,1 20.443491,1.28215388 20.4931534,1.64822944 L20.5,1.75 L20.5,4 L22.75,4 C23.1642136,4 23.5,4.33578644 23.5,4.75 C23.5,5.12969577 23.2178461,5.44349096 22.8517706,5.49315338 L22.75,5.5 L20.5,5.5 L20.5,7.75 C20.5,8.16421356 20.1642136,8.5 19.75,8.5 C19.3703042,8.5 19.056509,8.21784612 19.0068466,7.85177056 L19,7.75 L19,5.5 L16.75,5.5 C16.3357864,5.5 16,5.16421356 16,4.75 C16,4.37030423 16.2821539,4.05650904 16.6482294,4.00684662 L16.75,4 L19,4 L19,1.75 C19,1.33578644 19.3357864,1 19.75,1 Z"
      />
    </Svg>
  );
}

export default UserPlusIcon;
