import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';
import { CareshipColors } from '../CSTheme';

interface ShareIconProps extends SvgProps {
  color?: string;
  size?: number;
}

function ShareIcon({ color = CareshipColors.slate400, size = 24, ...props }: ShareIconProps) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" {...props}>
      <Path
        fill={color}
        d="M6.75,8 C7.16421356,8 7.5,8.32495462 7.5,8.72580645 C7.5,9.09325397 7.21784612,9.39692674 6.85177056,9.44498715 L6.75,9.4516129 L5.25,9.4516129 C4.86010924,9.4516129 4.55480987,9.69802055 4.50663422,9.99772983 L4.5,10.0806452 L4.5,20.9193548 C4.5,21.2271594 4.77280847,21.4992174 5.14589309,21.5424229 L5.25,21.5483871 L18.75,21.5483871 C19.1398908,21.5483871 19.4451901,21.3019794 19.4933658,21.0022702 L19.5,20.9193548 L19.5,10.0806452 C19.5,9.77284061 19.2271915,9.50078257 18.8541069,9.4575771 L18.75,9.4516129 L17.25,9.4516129 C16.8357864,9.4516129 16.5,9.12665829 16.5,8.72580645 C16.5,8.35835894 16.7821539,8.05468617 17.1482294,8.00662576 L17.25,8 L18.75,8 C19.9279746,8 20.9110337,8.8416645 20.9942903,9.93066748 L21,10.0806452 L21,20.9193548 C21,22.0296152 20.0681572,22.9195519 18.9093585,22.9948389 L18.75,23 L5.25,23 C4.07202542,23 3.08896629,22.1583355 3.00570969,21.0693325 L3,20.9193548 L3,10.0806452 C3,8.97038481 3.93184284,8.08044812 5.09064146,8.00516111 L5.25,8 L6.75,8 Z M12,1 C12.3796958,1 12.693491,1.28215388 12.7431534,1.64822944 L12.75,1.75 L12.75,14.25 C12.75,14.6642136 12.4142136,15 12,15 C11.6203042,15 11.306509,14.7178461 11.2568466,14.3517706 L11.25,14.25 L11.25,1.75 C11.25,1.33578644 11.5857864,1 12,1 Z M11.4696699,1.21966991 C11.7359365,0.953403352 12.1526002,0.9291973 12.4462117,1.14705176 L12.5303301,1.21966991 L16.2803301,4.96966991 C16.5732233,5.26256313 16.5732233,5.73743687 16.2803301,6.03033009 C16.0140635,6.29659665 15.5973998,6.3208027 15.3037883,6.10294824 L15.2196699,6.03033009 L12,2.811 L8.78033009,6.03033009 C8.51406352,6.29659665 8.09739984,6.3208027 7.80378835,6.10294824 L7.71966991,6.03033009 C7.45340335,5.76406352 7.4291973,5.34739984 7.64705176,5.05378835 L7.71966991,4.96966991 L11.4696699,1.21966991 Z"
      />
    </Svg>
  );
}

export default ShareIcon;
