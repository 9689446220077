import { NavigationContainerRef } from '@react-navigation/native';
import { useCallback, useState } from 'react';

export default function useNavigationContainerRef() {
  const [navigationContainerRef, setNavigationContainerRef] =
    useState<NavigationContainerRef | null>(null);
  const navigationContainerCallbackRef = useCallback(
    (node: NavigationContainerRef | null) => {
      if (!node || navigationContainerRef) {
        return;
      }

      setNavigationContainerRef(node);
    },
    [setNavigationContainerRef, navigationContainerRef],
  );

  return {
    navigationContainerRef,
    navigationContainerCallbackRef,
  };
}
