import React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';
import { CareshipColors } from '../CSTheme';

type AccountIconProps = SvgProps & {
  color?: string;
  size?: number;
};

export default function AccountIcon({
  color = CareshipColors.slate400,
  size = 24,
  ...props
}: AccountIconProps) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" {...props}>
      <Path
        d="M12 1c6.075 0 11 4.925 11 11s-4.925 11-11 11S1 18.075 1 12 5.925 1 12 1Zm0 1.5a9.5 9.5 0 1 0 0 19 9.5 9.5 0 0 0 0-19Zm.17 2.5a4.25 4.25 0 1 0 0 8.5 4.25 4.25 0 0 0 0-8.5Zm0 1.5a2.75 2.75 0 1 1 0 5.5 2.75 2.75 0 0 1 0-5.5Zm0 7.5c3.57 0 6.608 2.42 7.162 5.69a.75.75 0 0 1-1.48.25c-.428-2.53-2.826-4.44-5.681-4.44-2.854 0-5.25 1.908-5.682 4.436a.75.75 0 0 1-1.478-.252C5.568 16.417 8.603 14 12.17 14Z"
        fill={color}
      />
    </Svg>
  );
}
