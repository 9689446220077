import { useEffect, useState } from 'react';
import { reaction } from 'mobx';
import { Severity } from '@sentry/types';
import { useStores } from '../../../Infrastructure/Store';
import { BookingUnion } from '../Store/Model/Booking';
import Contract from '../../Contract/Store/Model/Contract';
import useLogger from '../../../Infrastructure/Hook/useLogger';
import useBookingService from './useBookingService';
import { useContractService } from '../../Contract/Hook';

export type BookingContractTuple = { booking: BookingUnion; contract: Contract };

export default function useBooking(id: string): Partial<BookingContractTuple> {
  const stores = useStores();
  const logger = useLogger();
  const bookingService = useBookingService();
  const contractService = useContractService();
  const [bookingContract, setBookingContract] = useState<Partial<BookingContractTuple>>({
    booking: undefined,
    contract: undefined,
  });

  useEffect(
    () =>
      reaction(
        () => ({
          bookingViewData: stores.bookingStore.viewData.bookings,
          contractViewData: stores.contractStore.viewData,
        }),
        ({ bookingViewData, contractViewData }) => {
          if (!bookingViewData.length || !contractViewData.length) {
            return;
          }

          logger.addBreadcrumb({
            level: Severity.Info,
            type: 'stores',
            data: {
              contractViewData,
              bookingViewData,
            },
          });

          const booking = stores.bookingStore.getOneByBookingId(id);

          if (booking === undefined) {
            logger.error(`Booking ${id} not found.`);

            return;
          }

          const contract = stores.contractStore.getContract(booking.contractId);

          if (!contract) {
            logger.error(
              `Contract (${booking.contractId}) not found for booking ${booking.bookingId}`,
            );

            return;
          }

          setBookingContract({ booking, contract });
        },
        {
          fireImmediately: true,
        },
      ),
    [id, logger, stores.bookingStore, stores.contractStore],
  );

  useEffect(() => {
    void (async () => {
      await contractService.fetchContractList();
      await bookingService.fetchBookingList();
    })();
  }, []);

  return bookingContract;
}
