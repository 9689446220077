import React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';
import { CareshipColors } from '../CSTheme';

type MultipleActionTimeIconProps = SvgProps & {
  color?: string;
  size?: number;
};

export default function MultipleActionTimeIcon({
  color = CareshipColors.primary,
  size = 24,
  ...props
}: MultipleActionTimeIconProps) {
  return (
    <Svg width={size} height={size} viewBox="0 0 36 36" {...props}>
      <Path
        d="M9 11.25A5.625 5.625 0 119 0a5.625 5.625 0 010 11.25zM9 9a3.375 3.375 0 100-6.75A3.375 3.375 0 009 9z"
        fill={CareshipColors.slate400}
      />
      <Path
        d="M3.482 24.75H1.125A1.125 1.125 0 010 23.625V20.25a9.002 9.002 0 0116.808-4.478 1.125 1.125 0 11-1.951 1.12A6.751 6.751 0 002.25 20.25v2.25H4.5c.578 0 1.062.438 1.12 1.013L6.642 33.75h4.714l.385-3.837a1.125 1.125 0 012.238.225l-.486 4.85A1.125 1.125 0 0112.375 36h-6.75a1.125 1.125 0 01-1.12-1.013L3.483 24.75z"
        fill={CareshipColors.slate400}
      />
      <Path
        d="M25.875 36c-5.592 0-10.125-4.533-10.125-10.125S20.283 15.75 25.875 15.75 36 20.283 36 25.875 31.467 36 25.875 36zm0-2.25a7.875 7.875 0 100-15.75 7.875 7.875 0 000 15.75z"
        fill={color}
      />
      <Path
        d="M29.853 24.75a1.125 1.125 0 010 2.25h-3.978a1.125 1.125 0 01-1.125-1.125v-3.978a1.125 1.125 0 012.25 0v2.853h2.853z"
        fill={color}
      />
      <Path
        d="M24.75 10.125A5.063 5.063 0 1124.75 0a5.063 5.063 0 010 10.125zm0-2.25a2.812 2.812 0 100-5.625 2.812 2.812 0 000 5.625z"
        fill={CareshipColors.slate400}
      />
      <Path
        d="M31.574 12.937a1.125 1.125 0 01-1.948 1.126 5.625 5.625 0 00-9.106-.888 1.125 1.125 0 01-1.695-1.48 7.875 7.875 0 0112.75 1.242z"
        fill={CareshipColors.slate400}
      />
    </Svg>
  );
}
