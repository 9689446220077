import React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';
import { CareshipColors } from '../CSTheme';

type EyeIconProps = SvgProps & {
  color?: string;
  size?: number;
};

export default function EyeIcon({
  color = CareshipColors.primary,
  size = 24,
  ...props
}: EyeIconProps) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" {...props}>
      <Path
        d="M12.2918975,5.00176116 C15.7856112,5.07591641 19.6020717,7.48464356 22.4401241,10.9093818 C23.1862917,11.8185738 23.1862917,13.1777895 22.438082,14.089459 C19.5255601,17.6074975 15.5883855,20.0646202 12.0122339,19.9980537 L12.0122339,19.9980537 L11.7025294,19.9976123 C8.18402669,19.9233237 4.38809363,17.5064673 1.55937547,14.0869815 C0.813207876,13.1777895 0.813207876,11.8185738 1.56137929,10.9069506 C4.47665393,7.38501327 8.43598314,4.93509593 12.0122668,5.00131017 Z M12.0122668,6.50105314 L11.70696,6.50215973 C8.73151401,6.5799117 5.28916778,8.75583592 2.71888306,11.8609815 C2.42670535,12.216995 2.42670535,12.7793684 2.7170241,13.1331256 C5.36466024,16.3337029 8.9227891,18.5548628 12.0122339,18.4983096 L12.0122339,18.4983096 L12.291897,18.4972128 C15.2887381,18.4193636 18.7128037,16.2370353 21.2806165,13.1353818 C21.5727942,12.7793684 21.5727942,12.216995 21.2828891,11.8637373 C18.6262969,8.65798306 15.0488894,6.44483062 12.0122668,6.50105314 L12.0122668,6.50105314 Z M11.9971123,8.74818166 L12.2266799,8.75610018 C13.1380954,8.81153521 14.0010722,9.19819853 14.6503508,9.84765029 C15.3537359,10.5512229 15.7486428,11.505512 15.7481126,12.5001818 C15.7475598,14.5711193 14.0683833,16.2495497 11.9974458,16.2491816 C9.92650831,16.2488134 8.2479286,14.569786 8.24811263,12.4988484 C8.24829667,10.4279107 9.92717488,8.74918159 11.9971123,8.74818166 L11.9971123,8.74818166 Z M11.9983127,10.2491815 L11.8440733,10.2543718 C10.6733547,10.3335266 9.74821845,11.3081925 9.74811263,12.4989817 C9.74800221,13.7415442 10.75515,14.7489607 11.9977125,14.7491815 C13.2402749,14.7494025 14.247781,13.7423441 14.2481126,12.4995819 C14.2484308,11.9027448 14.0115203,11.3302526 13.5895493,10.908169 C13.1675782,10.4860854 12.5951493,10.2490224 11.9983127,10.2491815 L11.9983127,10.2491815 Z"
        fill={color}
      />
    </Svg>
  );
}
