import React, { ComponentProps, FC } from 'react';
import { Text, Title as BaseTitle } from 'react-native-paper';
import { StyleSheet } from 'react-native';
import styled from '@emotion/native';
import { SpacedBoxProps } from '@emotion/react';
import CSTheme, { CareshipColors } from './CSTheme';
import withSpacing, { shouldForwardProp } from '../Root/Theme/withSpacing';

enum TitleSize {
  h0 = 32,
  h1 = 24,
  h2 = 22,
  h3 = 20,
  h4 = 18,
  h5 = 16,
  h6 = 14,
}

enum LineHeight {
  h0 = 40,
  h1 = 30,
  h2 = 28,
  h3 = 26,
  h4 = 24,
  h5 = 22,
  h6 = 20,
}

const styles = StyleSheet.create({
  h0: {
    fontSize: TitleSize.h0,
    lineHeight: LineHeight.h0,
  },
  h1: {
    fontSize: TitleSize.h1,
    lineHeight: LineHeight.h1,
  },
  h2: {
    fontSize: TitleSize.h2,
    lineHeight: LineHeight.h2,
  },
  h3: {
    fontSize: TitleSize.h3,
    lineHeight: LineHeight.h3,
  },
  h4: {
    fontSize: TitleSize.h4,
    lineHeight: LineHeight.h4,
  },
  h5: {
    fontSize: TitleSize.h5,
    lineHeight: LineHeight.h5,
  },
  h6: {
    fontSize: TitleSize.h6,
    lineHeight: LineHeight.h6,
  },
  title: {
    ...CSTheme.fonts.medium,
    color: CSTheme.colors.text,
    marginBottom: 0,
  },
  muted: {
    color: CareshipColors.slate300,
  },
  center: {
    textAlign: 'center',
  },
  uppercase: {
    textTransform: 'uppercase',
    letterSpacing: 1.125,
  },
  thin: {
    fontFamily: CSTheme.fonts.regular.fontFamily,
  },
});

type TitleProps = ComponentProps<typeof BaseTitle> & {
  size?: keyof typeof TitleSize;
  thin?: boolean;
  center?: boolean;
  uppercase?: boolean;
  muted?: boolean;
};

const Title: FC<TitleProps> = ({
  children,
  thin,
  center,
  muted,
  uppercase,
  size = 'h1',
  style,
  ...rest
}) => (
  <Text
    style={[
      styles.title,
      styles[size],
      center && styles.center,
      uppercase && styles.uppercase,
      muted && styles.muted,
      thin && styles.thin,
      style,
    ]}
    {...rest}
  >
    {children}
  </Text>
);

export default styled(Title, {
  shouldForwardProp,
})<TitleProps & SpacedBoxProps>(withSpacing(() => []));
