import React, { ReactElement, useState } from 'react';
import { useShouldShowBookingFeedback } from '../../Hook';
import QuestionOne from './QuestionOne';
import QuestionTwo from './QuestionTwo';
import QuestionThree from './QuestionThree.tsx';
import FeedbackFrequencyPrompt from './FeedbackFrequencyPrompt';
import useLogger from '../../../../Infrastructure/Hook/useLogger';
import FulfillBookingFeedbackConfirmation from './FulfillBookingFeedbackConfirmation';
import useBookingFeedbackMutation from './useBookingFeedbackMutation';

interface FormState {
  careReceiverMood: string;
  careReceiverMoodAdditionalText: string;
  customerEnergy: string;
  customerEnergyAdditionalText: string;
  activitiesPerformed: string;
}

function isEmptyForm(state: FormState) {
  return !Object.values(state).some(Boolean);
}

interface FulfillBookingFeedbackFormProps {
  bookingId: string;
  onSubmit: () => void;
}

export default function FulfillBookingFeedbackForm({
  bookingId,
  onSubmit,
}: FulfillBookingFeedbackFormProps): ReactElement {
  const logger = useLogger();
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const {
    shouldPromptForContinuation,
    setFeedbackSurveyFrequency,
    disableFeedbackSurveyPermanently,
  } = useShouldShowBookingFeedback(bookingId);
  const [formState, setFormState] = useState<FormState>({
    careReceiverMood: '',
    careReceiverMoodAdditionalText: '',
    customerEnergy: '',
    customerEnergyAdditionalText: '',
    activitiesPerformed: '',
  });
  const [isPromptFrequencyActive, setIsPromptFrequencyActive] = useState(false);
  const [isSubmissionFinalized, setIsSubmissionFinalized] = useState(false);

  const questions = [QuestionOne, QuestionTwo, QuestionThree];

  const [commit, isInFlight] = useBookingFeedbackMutation();

  function thankYouOrDismiss() {
    if (isEmptyForm(formState)) {
      onSubmit();
      return;
    }

    setIsSubmissionFinalized(true);
  }

  const submitAnswers = (state: FormState) => {
    if (isEmptyForm(state)) {
      if (!shouldPromptForContinuation) {
        onSubmit();
        return;
      }

      //setIsPromptFrequencyActive(true);

      return;
    }

    commit({
      variables: {
        bookingId,
        ...state,
      },
      onError: (error) => {
        logger.error('failed to commit booking feedback', { error });

        /*if (shouldPromptForContinuation) {
          setIsPromptFrequencyActive(true);

          return;
        }*/

        thankYouOrDismiss();
      },
      onCompleted: () => {
        /*if (shouldPromptForContinuation) {
          setIsPromptFrequencyActive(true);

          return;
        }*/

        thankYouOrDismiss();
      },
    });
  };

  const handleStepSubmit = (value: Partial<FormState>) => {
    const newState = {
      ...formState,
      ...value,
    };

    if (currentQuestion < questions.length - 1) {
      setCurrentQuestion((c) => c + 1);
      setFormState(newState);
      return;
    }

    submitAnswers(newState);
  };

  const handleAbort = () => {
    submitAnswers(formState);
  };

  const handleSkipQuestion = () => {
    if (currentQuestion < questions.length - 1) {
      setCurrentQuestion((c) => c + 1);
      return;
    }

    submitAnswers(formState);
  };

  if (isSubmissionFinalized) {
    return <FulfillBookingFeedbackConfirmation onPress={() => onSubmit()} />;
  }

  if (isPromptFrequencyActive) {
    return (
      <FeedbackFrequencyPrompt
        onFrequencyChange={(frequency: 1 | 3) => {
          setFeedbackSurveyFrequency(frequency);
          thankYouOrDismiss();
        }}
        onDisableFeedback={() => {
          disableFeedbackSurveyPermanently();
          thankYouOrDismiss();
        }}
      />
    );
  }

  const QuestionComponent = questions[currentQuestion];

  return (
    <>
      <QuestionComponent isLoading={isInFlight} onSubmit={handleStepSubmit} />
      {/*<Container vertical>
        {currentQuestion < questions.length - 1 && (
          <Button
            disabled={isInFlight}
            dark={false}
            color="transparent"
            thin
            size="sm"
            onPress={handleSkipQuestion}
          >
            <FormattedMessage id="BOOKING_FEEDBACK_SKIP_QUESTION" />
          </Button>
        )}
        <Button
          style={{ marginTop: 8 }}
          disabled={isInFlight}
          loading={isInFlight}
          dark={false}
          thin
          size="sm"
          color="transparent"
          onPress={handleAbort}
        >
          <FormattedMessage id="BOOKING_FEEDBACK_ABORT" />
        </Button>
      </Container>*/}
    </>
  );
}
