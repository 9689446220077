import { FormattedMessage, useIntl } from 'react-intl';
import React from 'react';
import styled from '@emotion/native';
import { Platform } from 'react-native';
import { Card, MailtoLink, Text } from '../../Component';

interface YearHasAnonymizedInvoicesCardProps {
  year: string;
}

const Link = styled(MailtoLink)(({ theme: { colors } }) => ({
  color: colors.slate400,
  ...Platform.select({
    native: {
      textDecorationLine: 'underline',
    },
  }),
}));

export default function YearHasAnonymizedInvoicesCard({
  year,
}: YearHasAnonymizedInvoicesCardProps) {
  const intl = useIntl();

  return (
    <Card
      mode="outlined"
      margin={Platform.select({ native: 'base' })}
      marginTop="none"
      marginBottom={Platform.select({ web: 'base' })}
    >
      <Text>
        <FormattedMessage
          id="INVOICE_LIST_WITH_ANONYMIZED"
          values={{
            selectedYear: year,
            customerServiceLink: (
              <Link
                to="finance@careship.de"
                subject={intl.formatMessage({ id: 'INVOICE_REQUEST_ANONYMIZED_SUBJECT' }, { year })}
                body={intl.formatMessage({ id: 'INVOICE_REQUEST_ANONYMIZED_BODY' }, { year })}
              >
                <FormattedMessage id="INVOICE_LIST_WITH_ANONYMIZED_LINK" />
              </Link>
            ),
          }}
        />
      </Text>
    </Card>
  );
}
