import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';
import { CareshipColors } from '../CSTheme';

interface JourneyLocationIconProps extends SvgProps {
  color?: string;
  colorAccent?: string;
  size?: number;
}

function JourneyLocationIcon({
  color = CareshipColors.slate400,
  colorAccent = CareshipColors.primary,
  size = 24,
  ...props
}: JourneyLocationIconProps) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" {...props}>
      <Path d="M17.375 5.5a1.125 1.125 0 102.25 0 1.125 1.125 0 00-2.25 0z" fill="#495159" />
      <Path
        d="M18.5.25c2.9 0 5.25 2.35 5.25 5.25 0 1.797-1.682 4.548-4.069 7.6a1.5 1.5 0 01-2.364 0c-2.385-3.05-4.067-5.802-4.067-7.599A5.25 5.25 0 0118.5.25zm0 1.5a3.75 3.75 0 00-3.75 3.751c0 1.302 1.622 3.956 3.749 6.675 2.13-2.721 3.751-5.375 3.751-6.676a3.75 3.75 0 00-3.75-3.75zM5.3 9.75a3.25 3.25 0 100-6.5 3.25 3.25 0 000 6.5zm0-1.5a1.75 1.75 0 110-3.5 1.75 1.75 0 010 3.5z"
        fill={color}
      />
      <Path
        d="M2.25 17.25l.575 5.824a.75.75 0 00.746.676H7.43a.75.75 0 00.746-.676l.576-5.824H10a.75.75 0 00.75-.75v-1.95c0-2.925-2.349-5.3-5.25-5.3S.25 11.625.25 14.55v1.95c0 .414.336.75.75.75h1.25zm5.821-1.5a.75.75 0 00-.746.676L6.75 22.25H4.251l-.576-5.824a.75.75 0 00-.746-.676H1.75v-1.2c0-2.1 1.68-3.8 3.75-3.8 2.07 0 3.75 1.7 3.75 3.8v1.2H8.071z"
        fill={color}
      />
      <Path
        d="M10.74 23.123c.102-.61.308-1.113.617-1.52a.75.75 0 00-1.195-.907c-.461.608-.761 1.338-.902 2.18a.75.75 0 101.48.247zm1.91-2.492a5.688 5.688 0 011.41-.382c.146-.02.256-.036.375-.05a.75.75 0 00-.185-1.489c-.128.016-.248.032-.407.055a7.178 7.178 0 00-1.786.488.75.75 0 10.593 1.378zm3.733-.604l.693-.044c.617-.038.955-.061 1.317-.092a.75.75 0 00-.126-1.494c-.348.03-.678.052-1.284.09-.345.02-.512.03-.698.043a.75.75 0 10.098 1.497zm4.12-.458c1.08-.287 1.813-.784 2.136-1.61a.75.75 0 10-1.397-.547c-.116.298-.46.531-1.124.708a.75.75 0 00.386 1.45zm1.513-4.354c-.514-.443-1.25-.742-2.205-.922a.75.75 0 10-.277 1.475c.718.134 1.218.337 1.502.583a.75.75 0 10.98-1.136z"
        fill={colorAccent}
      />
    </Svg>
  );
}

export default JourneyLocationIcon;
