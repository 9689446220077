import { configure } from 'mobx';
import SessionStore from './SessionStore';
import ProfileStore from '../../Context/Profile/Store/ProfileStore';
import ContractStore from '../../Context/Contract/Store/ContractStore';
import BookingStore from '../../Context/Booking/Store/BookingStore';
import DefinitionStore from '../../Context/Definition/Store/DefinitionStore';
import ProposalStore from '../../Context/Proposal/Store/ProposalStore';

/**
 * Enforce changing state only via actions, warn when observers without
 */
configure({
  enforceActions: 'always',
  reactionRequiresObservable: true,
});

/**
 * Stores are exported for use in hydration routines,
 * don't use them directly in components.
 */
const stores = {
  sessionStore: new SessionStore(),
  profileStore: new ProfileStore(),
  contractStore: new ContractStore(),
  bookingStore: new BookingStore(),
  proposalStore: new ProposalStore(),
  definitionStore: new DefinitionStore(),
};

type Stores = typeof stores;

export default stores;
export type StoreName = keyof Stores;
