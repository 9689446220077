import React, { ReactNode } from 'react';
import { Subheading } from 'react-native-paper';
import { StyleSheet, View } from 'react-native';
import CSTheme, { CareshipColors } from '../CSTheme';

type FormGroupTitleProps = {
  children: ReactNode;
};

const styles = StyleSheet.create({
  title: {
    textTransform: 'uppercase',
    ...CSTheme.fonts.medium,
    color: CareshipColors.slate300,
  },
  titleRow: {
    borderBottomWidth: 1,
    borderBottomColor: CareshipColors.slate200,
    paddingBottom: 4,
  },
});

export default function FormGroupTitle({ children }: FormGroupTitleProps) {
  return (
    <View style={styles.titleRow}>
      <Subheading style={styles.title}>{children}</Subheading>
    </View>
  );
}
