import React, { ReactNode } from 'react';
import { Text } from 'react-native-paper';
import { StyleSheet } from 'react-native';
import { CareshipColors } from '../CSTheme';

type HintProps = {
  children: ReactNode;
  color?: string;
};

const styles = StyleSheet.create({
  text: {
    fontSize: 14,
    color: CareshipColors.slate300,
  },
});

export default function HintText({ children, color }: HintProps) {
  return <Text style={[styles.text, color ? { color } : undefined]}>{children}</Text>;
}
