import { Animated, StyleSheet, View } from 'react-native';
import React from 'react';
import { CareshipColors } from './CSTheme';

const styles = StyleSheet.create({
  background: {
    width: 80,
    height: 8,
    padding: 1.5,
    borderRadius: 8,
    backgroundColor: CareshipColors.slate050,
  },
  progressBar: {
    backgroundColor: CareshipColors.secondary,
    borderRadius: 8,
    flex: 1,
    minWidth: 6,
  },
});

interface ProgressBarProps {
  backgroundColor?: string;
  progress: Animated.Value | number;
}

export default function ProgressBar({ progress, backgroundColor }: ProgressBarProps) {
  const width =
    typeof progress === 'number'
      ? `${progress * 100}%`
      : progress.interpolate({
          inputRange: [0, 1],
          outputRange: ['0%', '100%'],
        });

  return (
    <View
      style={[
        styles.background,
        {
          backgroundColor,
        },
      ]}
    >
      <Animated.View style={[styles.progressBar, { width }]} />
    </View>
  );
}
