import React, { ComponentProps, forwardRef, useState } from 'react';
import { TextInput as RNPTextInput } from 'react-native-paper';
import { Platform, TextInput as RNTextInput, View } from 'react-native';
import { CareshipColors } from '../CSTheme';

type TextInputProps = ComponentProps<typeof RNPTextInput>;

/**
 * TextInput extends react-native-paper component with an outline
 */
export default forwardRef<RNTextInput, TextInputProps>(function TextInput(
  { autoComplete, style, ...props }: TextInputProps,
  ref,
) {
  // eslint-disable-next-line react/destructuring-assignment
  const [isFocused, setIsFocused] = useState<boolean>(props.autoFocus || false);
  let borderColor: string;

  switch (true) {
    case props.error:
      borderColor = CareshipColors.red;
      break;
    case isFocused:
      borderColor = CareshipColors.primary;
      break;
    default:
      borderColor = CareshipColors.slate200;
  }

  return (
    <View
      style={[
        {
          borderColor,
          overflow: 'hidden',
          borderWidth: 1,
          borderRadius: 8,
        },
        style,
      ]}
    >
      <RNPTextInput
        ref={ref}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        render={(inputProps) => (
          <RNTextInput
            {...inputProps}
            style={[
              inputProps.style,
              {
                minHeight: (inputProps.numberOfLines || 1) * 22 + 32 + 4,
              },
            ]}
          />
        )}
        style={[
          {
            backgroundColor: CareshipColors.white,
            marginBottom: -2,
            paddingBottom: 2,
          },
        ]}
        {...{
          ...props,
          // Noisy warnings on web
          ...(Platform.OS !== 'web' ? { autoComplete } : {}),
        }}
      />
    </View>
  );
});
