import { ScrollView, View } from 'react-native';
import React, { useCallback, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql } from 'react-relay';
import styled from '@emotion/native';
import { useNavigation } from '@react-navigation/native';
import { useForm, Controller } from 'react-hook-form';
import { useMutation, PreloadedQuery, usePreloadedQuery, useFragment } from 'react-relay/hooks';
import { Button, TextInput, Text, Container } from '../../Component';
import { intl } from '../../../Infrastructure/Intl';
import StickyFooter from '../../Component/StickyFooter';
import { events, trackEvent } from '../../../Infrastructure/Tracking/Tracking';
import { AccountStackNavigationProp } from '../Navigator/AccountStackNavigationProp';
import { EditContactAndAddressFormMutation } from './__generated__/EditContactAndAddressFormMutation.graphql';
import { EditContactAndAddressFormQuery } from './__generated__/EditContactAndAddressFormQuery.graphql';
import { EditContactAndAddressFormCaregiverFragment$key } from './__generated__/EditContactAndAddressFormCaregiverFragment.graphql';

const SaveButton = styled(Button)({
  marginVertical: 24,
  marginHorizontal: 16,
});

const editContactAndAddressFormCaregiverFragment = graphql`
  fragment EditContactAndAddressFormCaregiverFragment on Caregiver {
    id
    phoneNumber
    mobilePhoneNumber
    company
    address {
      street
      city
      postalCode
      additionalInformation
    }
  }
`;

const editContactAndAddressFormMutation = graphql`
  mutation EditContactAndAddressFormMutation($input: UpdateCaregiverInput!) {
    updateCaregiver(input: $input) {
      caregiver {
        ...EditContactAndAddressFormCaregiverFragment
      }
    }
  }
`;

export const editContactAndAddressFormQuery = graphql`
  query EditContactAndAddressFormQuery {
    me {
      ... on Caregiver {
        name {
          firstName
          lastName
        }
        email
        ...EditContactAndAddressFormCaregiverFragment
      }
    }
  }
`;

interface ContactDataProps {
  preloadedQuery: PreloadedQuery<EditContactAndAddressFormQuery>;
}

export default function EditContactAndAddressForm({ preloadedQuery }: ContactDataProps) {
  const footerButtonsRef = useRef<View>(null);
  const navigation = useNavigation<AccountStackNavigationProp<'EditContactAndAddress'>>();
  const {
    me: { name, email, ...meFragment },
  } = usePreloadedQuery(editContactAndAddressFormQuery, preloadedQuery);

  const me = useFragment(
    editContactAndAddressFormCaregiverFragment,
    meFragment as EditContactAndAddressFormCaregiverFragment$key,
  );

  const defaultValues = {
    phoneNumber: me?.phoneNumber ?? '',
    mobilePhoneNumber: me?.mobilePhoneNumber ?? '',
    company: me?.company,
    address: {
      street: me?.address.street,
      city: me?.address.city,
      postalCode: me?.address.postalCode,
      additionalInformation: me?.address.additionalInformation,
    } as const,
  };

  const {
    handleSubmit,
    control,
    formState: { isDirty },
  } = useForm({
    mode: 'onSubmit',
    defaultValues,
  });

  const [commitMutation, isInFlight] = useMutation<EditContactAndAddressFormMutation>(
    editContactAndAddressFormMutation,
  );

  const handleSave = useCallback(
    (input: typeof defaultValues) => {
      commitMutation({
        variables: {
          input: {
            contactAndAddress: {
              ...input,
              address: {
                ...input.address,
                houseNumber: '',
              },
            },
          },
        },
        onCompleted: () => {
          trackEvent(events.CONTACT_AND_ADDRESS_UPDATED);
          navigation.navigate('ContactAndAddress');
        },
      });
    },
    [navigation, commitMutation],
  );

  const handleSubmitPress = useCallback(() => {
    void handleSubmit(handleSave)();
  }, [handleSave, handleSubmit]);

  const saveButton = (
    <SaveButton testID="submitButton" disabled={!isDirty || isInFlight} onPress={handleSubmitPress}>
      <FormattedMessage id="BUTTON_SAVE" />
    </SaveButton>
  );

  return (
    <Container>
      <ScrollView style={{ paddingTop: 32 }}>
        <Text style={{ textAlign: 'right', paddingBottom: 8 }} size="small" muted>
          <FormattedMessage id="CONTACT_AND_ADDRESS_CONTACT_US_NAME_CHANGE" />
        </Text>
        <TextInput
          accessibilityLabel="Text input field"
          accessibilityHint={intl.formatMessage({ id: 'CONTACT_AND_ADDRESS_FIRST_NAME' })}
          label={intl.formatMessage({ id: 'CONTACT_AND_ADDRESS_FIRST_NAME' })}
          value={name?.firstName}
          disabled
          style={{ marginBottom: 16 }}
        />
        <TextInput
          accessibilityLabel="Text input field"
          accessibilityHint={intl.formatMessage({ id: 'CONTACT_AND_ADDRESS_LAST_NAME' })}
          label={intl.formatMessage({ id: 'CONTACT_AND_ADDRESS_LAST_NAME' })}
          value={name?.lastName}
          disabled
          style={{ marginBottom: 16 }}
        />
        <Controller
          key="company"
          name="company"
          control={control}
          render={({ field: { value, onChange } }) => (
            <TextInput
              accessibilityLabel="Text input field"
              accessibilityHint={intl.formatMessage({ id: 'CONTACT_AND_ADDRESS_COMPANY' })}
              label={intl.formatMessage({ id: 'CONTACT_AND_ADDRESS_COMPANY' })}
              value={value}
              onChangeText={onChange}
              style={{ marginBottom: 16 }}
            />
          )}
        />
        <Controller
          key="address.street"
          name="address.street"
          control={control}
          render={({ field: { value, onChange } }) => (
            <TextInput
              accessibilityLabel="Text input field"
              accessibilityHint={intl.formatMessage({ id: 'CONTACT_AND_ADDRESS_STREET' })}
              label={intl.formatMessage({ id: 'CONTACT_AND_ADDRESS_STREET' })}
              value={value}
              onChangeText={onChange}
              style={{ marginBottom: 16 }}
            />
          )}
        />
        <Controller
          key="address.postalCode"
          name="address.postalCode"
          control={control}
          render={({ field: { value, onChange } }) => (
            <TextInput
              accessibilityLabel="Text input field"
              accessibilityHint={intl.formatMessage({ id: 'CONTACT_AND_ADDRESS_POSTAL_CODE' })}
              label={intl.formatMessage({ id: 'CONTACT_AND_ADDRESS_POSTAL_CODE' })}
              value={value}
              onChangeText={onChange}
              style={{ marginBottom: 16 }}
            />
          )}
        />
        <Controller
          key="address.city"
          name="address.city"
          control={control}
          render={({ field: { value, onChange } }) => (
            <TextInput
              accessibilityLabel="Text input field"
              accessibilityHint={intl.formatMessage({ id: 'CONTACT_AND_ADDRESS_CITY' })}
              label={intl.formatMessage({ id: 'CONTACT_AND_ADDRESS_CITY' })}
              value={value}
              onChangeText={onChange}
              style={{ marginBottom: 16 }}
            />
          )}
        />
        <Controller
          key="address.additionalInformation"
          name="address.additionalInformation"
          control={control}
          render={({ field: { value, onChange } }) => (
            <TextInput
              accessibilityLabel="Text input field"
              accessibilityHint={intl.formatMessage({
                id: 'CONTACT_AND_ADDRESS_ADDITIONAL_INFORMATION',
              })}
              label={intl.formatMessage({ id: 'CONTACT_AND_ADDRESS_ADDITIONAL_INFORMATION' })}
              value={value}
              onChangeText={onChange}
              style={{ marginBottom: 16 }}
            />
          )}
        />
        <Controller
          key="mobilePhoneNumber"
          name="mobilePhoneNumber"
          control={control}
          render={({ field: { value, onChange } }) => (
            <TextInput
              accessibilityLabel="Text input field"
              accessibilityHint={intl.formatMessage({
                id: 'CONTACT_AND_ADDRESS_MOBILE_PHONE_NUMBER',
              })}
              label={intl.formatMessage({ id: 'CONTACT_AND_ADDRESS_MOBILE_PHONE_NUMBER' })}
              value={value}
              onChangeText={onChange}
              style={{ marginBottom: 16 }}
            />
          )}
        />
        <Controller
          key="phoneNumber"
          name="phoneNumber"
          control={control}
          render={({ field: { value, onChange } }) => (
            <TextInput
              accessibilityLabel="Text input field"
              accessibilityHint={intl.formatMessage({ id: 'CONTACT_AND_ADDRESS_PHONE_NUMBER' })}
              label={intl.formatMessage({ id: 'CONTACT_AND_ADDRESS_PHONE_NUMBER' })}
              value={value}
              onChangeText={onChange}
              style={{ marginBottom: 16 }}
            />
          )}
        />
        <Text style={{ textAlign: 'right', paddingBottom: 8 }} size="small" muted>
          <FormattedMessage id="CONTACT_AND_ADDRESS_CONTACT_US_EMAIL_CHANGE" />
        </Text>
        <TextInput
          accessibilityLabel="Text input field"
          accessibilityHint={intl.formatMessage({ id: 'CONTACT_AND_ADDRESS_EMAIL' })}
          label={intl.formatMessage({ id: 'CONTACT_AND_ADDRESS_EMAIL' })}
          value={email}
          disabled
          style={{ marginBottom: 16 }}
        />
        <View
          style={{ marginTop: 'auto', marginBottom: 32 }}
          collapsable={false}
          ref={footerButtonsRef}
        >
          {saveButton}
        </View>
      </ScrollView>
      <StickyFooter sticky={isDirty} alignWithRef={footerButtonsRef}>
        {saveButton}
      </StickyFooter>
    </Container>
  );
}
