import { View } from 'react-native';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import { useNavigation } from '@react-navigation/native';
import { graphql } from 'react-relay';
import { PreloadedQuery, usePreloadedQuery } from 'react-relay/hooks';
import styled from '@emotion/native';
import { ListItemAction } from '../../Component';
import { AccountStackNavigationProp } from '../Navigator/AccountStackNavigationProp';
import { BankIcon, InvoiceIcon } from '../../Component/Icon';
import { FinancialOverviewQuery } from './__generated__/FinancialOverviewQuery.graphql';
import { MonthlyEarnings } from '../../Component/CaregiverStats';
import useProfile from '../../../Context/Profile/Hook/useProfile';

export const financialOverviewQuery = graphql`
  query FinancialOverviewQuery {
    me {
      ... on Caregiver {
        stats {
          ...MonthlyEarnings_monthlyBalance
        }
      }
    }
  }
`;

const MonthlyEarningsContainer = styled(View)({
  marginVertical: 32,
  marginHorizontal: 16,
});

interface FinancialOverviewProps {
  financialOverviewQueryReference: PreloadedQuery<FinancialOverviewQuery>;
}

export default function FinancialOverview({
  financialOverviewQueryReference,
}: FinancialOverviewProps) {
  const { profile } = useProfile();
  const navigation = useNavigation<AccountStackNavigationProp<'Account'>>();

  const handleBankTaxPress = () => {
    navigation.navigate('FinancialDetails');
  };

  const handleInvoiceListPress = () => {
    navigation.navigate('InvoiceList');
  };

  const {
    me: { stats },
  } = usePreloadedQuery(financialOverviewQuery, financialOverviewQueryReference);

  return (
    <View>
      {stats && (
        <MonthlyEarningsContainer>
          <MonthlyEarnings stats={stats} />
        </MonthlyEarningsContainer>
      )}
      {!profile?.isCareshipEmployee && (
        <ListItemAction actionLabel=" " icon={<InvoiceIcon />} onPress={handleInvoiceListPress}>
          <FormattedMessage id="INVOICE_LIST" />
        </ListItemAction>
      )}
      <ListItemAction actionLabel=" " onPress={handleBankTaxPress} icon={<BankIcon />}>
        <FormattedMessage id="BANK_TAX_DETAILS" />
      </ListItemAction>
    </View>
  );
}
