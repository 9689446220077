import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from '@emotion/native';
import { Animated, LayoutChangeEvent, Platform } from 'react-native';
import {
  PanGestureHandler,
  PanGestureHandlerStateChangeEvent,
  State,
} from 'react-native-gesture-handler';
import setColor from 'color';
import { CareshipColors } from '../CSTheme';
import { clamp, inverseLerp, lerp } from '../../../Infrastructure/Math';

interface SliderProps {
  color?: string;
  value?: number;
  maximumValue?: number;
  minimumValue?: number;
  disabled?: boolean;
  // Continuously called on drag
  onValueChange?: (value: number) => void;
  // Called when the slider is released, even if the value hasn't changed
  onSlidingComplete?: (value: number) => void;
}

const KNOB_RADIUS = 12;
const TRACK_HEIGHT = KNOB_RADIUS * 0.583333333 * 2;

const Container = styled.View<{ disabled?: boolean }>(({ disabled }) => ({
  position: 'relative',
  paddingTop: KNOB_RADIUS - TRACK_HEIGHT / 2,
  opacity: disabled ? 0.7 : 1,
}));

const Knob = styled(Animated.View)<{ color?: string }>(({ color }) => ({
  height: KNOB_RADIUS * 2,
  width: KNOB_RADIUS * 2,
  borderRadius: KNOB_RADIUS,
  backgroundColor: color || CareshipColors.secondary,
  position: 'absolute',
  left: 0,
  top: 0,
  shadowColor: 'rgb(73,81,89)',
  shadowOpacity: 0.4,
  shadowOffset: {
    height: 1.5,
    width: 0,
  },
  shadowRadius: 1.5,
}));

const Track = styled.View<{ color?: string }>(({ color }) => ({
  height: TRACK_HEIGHT,
  borderRadius: TRACK_HEIGHT / 2,
  backgroundColor: color || CareshipColors.slate200,
  position: 'relative',
  overflow: 'hidden',
}));

const Bar = styled(Animated.View)<{ color?: string }>(({ color }) => ({
  height: TRACK_HEIGHT,
  backgroundColor: setColor(color || CareshipColors.secondary)
    .alpha(0.38)
    .rgb()
    .string(),
  position: 'absolute',
  right: 0,
  top: 0,
  width: '100%',
}));

function SliderUi({
  color,
  minimumValue = 0,
  maximumValue = 1,
  value = 0,
  onValueChange,
  onSlidingComplete,
  disabled,
}: SliderProps) {
  const [containerWidth, setContainerWidth] = useState(1);

  let lastX = 0;
  const knobMax = Math.max(1, containerWidth - KNOB_RADIUS * 2);

  const { current: pan } = useRef(new Animated.Value(0));

  // set the position to match value from props
  useEffect(() => {
    pan.setOffset(knobMax * inverseLerp(minimumValue, maximumValue, value));
    pan.setValue(0);
  }, [knobMax, value, minimumValue, maximumValue, pan]);

  pan.addListener(({ value: x }) => {
    lastX = clamp(0, knobMax, x);

    if (onValueChange) {
      onValueChange(lerp(minimumValue, maximumValue, lastX / knobMax));
    }
  });

  const translateX = pan.interpolate({
    inputRange: [0, knobMax],
    outputRange: [0, knobMax],
    extrapolate: 'clamp',
  });

  const onLayout = useCallback(
    ({
      nativeEvent: {
        layout: { width },
      },
    }: LayoutChangeEvent) => {
      setContainerWidth(width);
    },
    [],
  );

  const knobStyle = {
    transform: [
      {
        translateX,
      },
    ],
  };

  const barTransform = [
    { translateX: KNOB_RADIUS - containerWidth }, // start with right edge aligned to the middle of the knob
    { translateX }, // move the bar the same amount as the knob is dragged
  ];

  const handlePanGestureStateChange = (event: PanGestureHandlerStateChangeEvent) => {
    if (event.nativeEvent.oldState !== State.ACTIVE || event.nativeEvent.state !== State.END) {
      return;
    }

    pan.setOffset(lastX);
    pan.setValue(0);

    if (onSlidingComplete) {
      onSlidingComplete(lerp(minimumValue, maximumValue, lastX / knobMax));
    }
  };

  const handlePanGestureEvent = Animated.event([{ nativeEvent: { translationX: pan } }], {
    useNativeDriver: Platform.OS === 'ios', // https://github.com/software-mansion/react-native-gesture-handler/issues/984
  });

  return (
    <Container disabled={disabled} onLayout={onLayout}>
      <Track>
        <Bar
          color={color}
          style={{
            transform: barTransform,
          }}
        />
      </Track>
      <PanGestureHandler
        minDist={1}
        enabled={!disabled}
        onHandlerStateChange={handlePanGestureStateChange}
        onGestureEvent={handlePanGestureEvent}
      >
        <Knob color={color} style={knobStyle} />
      </PanGestureHandler>
    </Container>
  );
}

export default function Slider({
  color,
  maximumValue,
  minimumValue,
  onSlidingComplete,
  onValueChange,
  value,
  disabled,
}: SliderProps) {
  return useMemo(
    () => (
      <SliderUi
        value={value}
        disabled={disabled}
        maximumValue={maximumValue}
        onValueChange={onValueChange}
        minimumValue={minimumValue}
        color={color}
        onSlidingComplete={onSlidingComplete}
      />
    ),
    [color, value, minimumValue, maximumValue, onValueChange, onSlidingComplete, disabled],
  );
}
