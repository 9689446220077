import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';
import { CareshipColors } from '../CSTheme';

interface CheckIconProps extends SvgProps {
  color?: string;
  size?: number;
}

function CheckIcon({ color = CareshipColors.slate400, size = 24, ...props }: CheckIconProps) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" {...props}>
      <Path
        fill={color}
        d="M20.5 4.7c.3-.3.8-.3 1.1 0 .3.3.3.7.1 1l-.1.1L9 18.8c-.3.2-.7.3-1 .1l-.1-.1L2.5 14c-.3-.3-.3-.8-.1-1.1.3-.3.7-.3 1-.1l.1.1 4.8 4.4L20.5 4.7z"
      />
    </Svg>
  );
}

export default CheckIcon;
