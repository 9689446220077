export interface SubServiceResponse {
  id: string;
  label_en: string;
  label_de: string;
}

export default class SubService {
  constructor(
    public readonly id: string,
    public readonly labelDe: string,
    public readonly labelEn: string,
  ) {}

  public static fromObject(subService: SubService) {
    return new SubService(subService.id, subService.labelDe, subService.labelEn);
  }

  public static fromResponse(response: SubServiceResponse) {
    return new SubService(response.id, response.label_de, response.label_en);
  }
}
