/**
 * @generated SignedSource<<e93af1ee0e0bf266b039a3ba92429b3c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ContractConfirmationStatusEnum = "PENDING" | "CONFIRMED" | "DEFERRED" | "REJECTED" | "%future added value";
export type FirstBookingStatus = "confirmed" | "created" | "declined" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ContractConfirmationActions_contract$data = {
  readonly confirmationStatus: ContractConfirmationStatusEnum;
  readonly contractId: string;
  readonly firstBookingStatus: FirstBookingStatus;
  readonly firstBooking: {
    readonly endDate: string;
  } | null;
  readonly bookings: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
      };
    } | null> | null;
  };
  readonly " $fragmentType": "ContractConfirmationActions_contract";
};
export type ContractConfirmationActions_contract$key = {
  readonly " $data"?: ContractConfirmationActions_contract$data;
  readonly " $fragmentSpreads": FragmentRefs<"ContractConfirmationActions_contract">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ContractConfirmationActions_contract",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "confirmationStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "contractId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstBookingStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Booking",
      "kind": "LinkedField",
      "name": "firstBooking",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "endDate",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BookingConnection",
      "kind": "LinkedField",
      "name": "bookings",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "BookingEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Booking",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Contract",
  "abstractKey": null
};

(node as any).hash = "48881f5b88082a33b24f7aae6e95bd8b";

export default node;
