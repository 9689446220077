import React, { ReactElement } from 'react';
import { isPast } from 'date-fns';
import { FormattedMessage } from 'react-intl';
import { BookingStatus, FirstBookingStatus } from '../../../Context/Booking/Store/Model/Booking';
import { CareshipColors } from '../../Component/CSTheme';

export default function getLabel(
  booking:
    | {
        endDate: Date;
        status: BookingStatus | '%future added value';
        isFirstBooking: boolean;
      }
    | null
    | undefined,
  firstBookingStatus: FirstBookingStatus = null,
): {
  label: ReactElement | null;
  color?: string;
} {
  if (!booking) {
    return { label: null };
  }

  const isPastBooking = isPast(booking.endDate);
  const isConfirmedFirstOrNotFirst = ([null, 'confirmed'] as FirstBookingStatus[]).includes(
    firstBookingStatus,
  );

  // Invoiced booking
  if (booking.status === 'invoiced') {
    return { label: <FormattedMessage id="INVOICED_BOOKING" />, color: CareshipColors.secondary };
  }

  // Documented booking
  if (booking.status !== 'created') {
    return { label: null };
  }

  // Undocumented booking
  if (booking.status === 'created' && isPastBooking) {
    return { label: <FormattedMessage id="UNDOCUMENTED_BOOKING" /> };
  }

  if (booking.isFirstBooking) {
    // Unconfirmed first appointment
    if (firstBookingStatus === 'created') {
      return {
        label: <FormattedMessage id="FIRST_APPOINTMENT_UNCONFIRMED" />,
      };
    }

    // Confirmed first appointment
    if (firstBookingStatus === 'confirmed') {
      return {
        label: <FormattedMessage id="FIRST_APPOINTMENT_CONFIRMED" />,
        color: CareshipColors.secondary,
      };
    }

    // Declined first appointment
    if (firstBookingStatus === 'declined') {
      return {
        label: <FormattedMessage id="FIRST_APPOINTMENT_DECLINED" />,
        color: CareshipColors.red,
      };
    }
  }

  // Default case
  return { label: null };
}
