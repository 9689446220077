import React, { ReactNode } from 'react';
import { Checkbox, Text } from 'react-native-paper';
import { StyleProp, StyleSheet, ViewStyle } from 'react-native';
import CSTheme, { CareshipColors } from '../CSTheme';
import TouchableFormRow from './TouchableFormRow';
import { useCachedAssets } from '../../../Infrastructure/Hook';

type Props = {
  label: ReactNode;
  status: 'checked' | 'unchecked';
  onPress: () => void;
  style?: StyleProp<ViewStyle>;
  testID?: string;
};

const styles = StyleSheet.create({
  text: {
    fontSize: 18,
    color: CareshipColors.slate300,
    flexShrink: 1,
  },
  active: {
    color: CSTheme.colors.text,
  },
});

export default function CheckboxItem({ label, status, onPress, style, testID }: Props) {
  return (
    <TouchableFormRow onPress={onPress} style={style} testID={testID}>
      <Checkbox.Android status={status} color={CSTheme.colors.primary} />
      <Text style={[styles.text, status === 'checked' && styles.active]}>{label}</Text>
    </TouchableFormRow>
  );
}
