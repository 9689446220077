import React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';
import { CareshipColors } from '../CSTheme';

type CalendarEditIconProps = SvgProps & {
  color?: string;
  size?: number;
};

export default function CalendarEditIcon({
  color = CareshipColors.primary,
  size = 24,
  ...props
}: CalendarEditIconProps) {
  return (
    <Svg width={size} height={size} viewBox="0 0 36 36" {...props}>
      <Path
        d="M33.15 21.384a2.047 2.047 0 000-2.898l-.012-.011c-.8-.8-2.097-.8-2.898 0L19.036 29.678l-.727 3.636 3.636-.727L33.15 21.384zm-9.855 13.035a1.125 1.125 0 01-.574.308l-5.625 1.125a1.125 1.125 0 01-1.324-1.324l1.125-5.625c.043-.217.15-.417.308-.574L28.65 16.883a4.299 4.299 0 016.078 0l.012.012a4.296 4.296 0 010 6.08L23.295 34.419z"
        fill={color}
      />
      <Path
        d="M13.5 24.75a1.125 1.125 0 010 2.25H3.375A3.375 3.375 0 010 23.625V5.648a3.375 3.375 0 013.375-3.375h20.25A3.375 3.375 0 0127 5.646v6.728a1.125 1.125 0 01-2.25 0V5.647c0-.62-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v17.978c0 .621.504 1.125 1.125 1.125H13.5z"
        fill={CareshipColors.slate400}
      />
      <Path
        fill={CareshipColors.slate400}
        d="M1.125 11.25V9h24.75v2.25zM8.99 5.625a1.125 1.125 0 01-2.25 0v-4.5a1.125 1.125 0 012.25 0v4.5zM20.24 5.625a1.125 1.125 0 01-2.25 0v-4.5a1.125 1.125 0 012.25 0v4.5z"
      />
    </Svg>
  );
}
