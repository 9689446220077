/**
 * @generated SignedSource<<82475bd91ea01a6a9613010c5bbc1996>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type LanguageEnum = "GERMAN" | "ENGLISH" | "SPANISH" | "FRENCH" | "ARABIC" | "CHINESE" | "CROATIAN" | "CZECH" | "DANISH" | "DUTCH" | "FINNISH" | "GREEK" | "HEBREW" | "HUNGARIAN" | "ITALIAN" | "JAPANESE" | "KOREAN" | "POLISH" | "PORTUGUESE" | "NORWEGIAN" | "ROMANIAN" | "RUSSIAN" | "SERBIAN" | "SIGN_LANGUAGE" | "SWEDISH" | "TURKISH" | "VIETNAMESE" | "OTHER" | "%future added value";
export type LanguagesFormQuery$variables = {};
export type LanguagesFormQuery$data = {
  readonly me: {
    readonly id?: string;
    readonly languages?: ReadonlyArray<{
      readonly key: LanguageEnum;
    } | null>;
  };
};
export type LanguagesFormQuery = {
  variables: LanguagesFormQuery$variables;
  response: LanguagesFormQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "kind": "InlineFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Language",
      "kind": "LinkedField",
      "name": "languages",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "key",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Caregiver",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "LanguagesFormQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "LanguagesFormQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v1/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v0/*: any*/)
            ],
            "type": "Node",
            "abstractKey": "__isNode"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "41131f01504975eea98003e341be7a45",
    "id": null,
    "metadata": {},
    "name": "LanguagesFormQuery",
    "operationKind": "query",
    "text": "query LanguagesFormQuery {\n  me {\n    __typename\n    ... on Caregiver {\n      id\n      languages {\n        key\n      }\n    }\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d50b10d9b870ebc0d54e6c9ca915620a";

export default node;
