/**
 * @generated SignedSource<<7c5d5b844e43a928fde9f75aea2ec5a4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditFinancialDetailsScreenQuery$variables = {};
export type EditFinancialDetailsScreenQuery$data = {
  readonly me: {
    readonly " $fragmentSpreads": FragmentRefs<"EditFinancialDetailsFormFragment">;
  };
};
export type EditFinancialDetailsScreenQuery = {
  variables: EditFinancialDetailsScreenQuery$variables;
  response: EditFinancialDetailsScreenQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "EditFinancialDetailsScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "EditFinancialDetailsFormFragment"
              }
            ],
            "type": "Caregiver",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "EditFinancialDetailsScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "CaregiverBankAccount",
                "kind": "LinkedField",
                "name": "bank",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "bic",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "iban",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "CaregiverTax",
                "kind": "LinkedField",
                "name": "tax",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "taxNo",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "ustId",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Caregiver",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v0/*: any*/)
            ],
            "type": "Node",
            "abstractKey": "__isNode"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3e91c0425728153ef5f887d9cbbf5e21",
    "id": null,
    "metadata": {},
    "name": "EditFinancialDetailsScreenQuery",
    "operationKind": "query",
    "text": "query EditFinancialDetailsScreenQuery {\n  me {\n    __typename\n    ... on Caregiver {\n      ...EditFinancialDetailsFormFragment\n    }\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n}\n\nfragment EditFinancialDetailsFormFragment on Caregiver {\n  id\n  bank {\n    bic\n    iban\n  }\n  tax {\n    taxNo\n    ustId\n  }\n}\n"
  }
};
})();

(node as any).hash = "390c85691654dc9333ece471e19a12f1";

export default node;
