import { Link, RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import React, { ReactElement, useCallback } from 'react';
import styled from '@emotion/native';
import { Platform } from 'react-native';
import * as t from 'io-ts';
import { FormattedMessage, useIntl } from 'react-intl';
import { FieldErrors, ResolverResult, UnpackNestedValue, useForm } from 'react-hook-form';
import { ioTsResolver } from '@hookform/resolvers/io-ts';
import { graphql } from 'react-relay/hooks';
import { withMessage } from 'io-ts-types';
import { composeResolvers, minLength, nonEmptyString } from '../../../Infrastructure/Validation';
import { CareshipColors } from '../../Component/CSTheme';
import { Alert, Button, Container, InputWithValidation, Text } from '../../Component';
import useLogger from '../../../Infrastructure/Hook/useLogger';
import { useAsyncMutation } from '../../../Infrastructure/Relay';
import AuthTitle from './AuthTitle';
import { PasswordResetFormMutation } from './__generated__/PasswordResetFormMutation.graphql';
import { AppNavigationParamList, AppStackNavigationProp } from '../../Root/Navigator/AppNavigator';

const Form = styled.View({
  width: '100%',
  maxWidth: 400,
});

const Input = styled(InputWithValidation)({
  height: 64,
  width: '100%',
}) as typeof InputWithValidation;

const ResetPasswordLink = styled(Text)({
  textAlign: 'center',
  marginTop: 8,
  padding: 16,
  width: '100%',
  marginBottom: 16,
  color: CareshipColors.slate300,
  fontSize: 14,
  textDecorationLine: 'none',
}).withComponent(Link);

const Wrapper = styled.View({
  flex: 1,
  alignItems: 'center',
  justifyContent: 'center',
  paddingHorizontal: 16,
  paddingVertical: 32,
  backgroundColor: Platform.select({
    native: '#fff',
  }),
});

const schema = t.type({
  password: withMessage(minLength(6), () => 'PASSWORD_MIN_LENGTH'),
  confirmPassword: nonEmptyString,
});

const Btn = styled(Button)({
  marginTop: Platform.select({ web: 8 }),
});

const matchingPasswordResolver = <
  TFieldValues extends { password: string; confirmPassword: string },
>(
  values: UnpackNestedValue<TFieldValues>,
): ResolverResult<TFieldValues> => {
  if (values.password !== values.confirmPassword) {
    return {
      errors: {
        confirmPassword: {
          type: 'validation',
          message: 'PASSWORD_MISMATCH',
        },
      } as FieldErrors<TFieldValues>,
      values: {},
    };
  }

  return {
    errors: {},
    values,
  };
};

const passwordResetFormMutation = graphql`
  mutation PasswordResetFormMutation($input: ResetCaregiverPasswordInput!) {
    resetCaregiverPassword(input: $input) {
      status
    }
  }
`;

export default function PasswordResetForm(): ReactElement {
  const intl = useIntl();
  const logger = useLogger();
  const {
    params: { token, email },
  } = useRoute<RouteProp<AppNavigationParamList, 'PasswordReset'>>();
  const [commit] = useAsyncMutation<PasswordResetFormMutation>(passwordResetFormMutation);
  const navigation = useNavigation<AppStackNavigationProp<'PasswordReset'>>();

  const {
    handleSubmit,
    control,
    setError,
    formState: { isSubmitting, errors },
  } = useForm<{
    password: string;
    confirmPassword: string;
    serverError?: string;
  }>({
    mode: 'onTouched',
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
    resolver: composeResolvers(ioTsResolver(schema), matchingPasswordResolver),
  });

  const onSubmit = useCallback(
    async (data: { password: string }) => {
      try {
        await commit({
          variables: {
            input: {
              email,
              token,
              password: data.password,
            },
          },
        });

        navigation.navigate('Auth', {
          isPasswordReset: true,
        });
      } catch (error) {
        logger.error(
          `Failed resetting password: ${
            error instanceof Error ? error.message : JSON.stringify(error)
          }`,
        );
        setError('serverError', {
          type: 'internal',
          message: intl.formatMessage({ id: 'ERROR_UNKNOWN' }),
        });
      }
    },
    [commit, email, navigation, intl, logger, setError, token],
  );

  const handlePressSubmit = useCallback<() => void>(
    () => handleSubmit(onSubmit)(),
    [handleSubmit, onSubmit],
  );

  return (
    <Wrapper>
      <Form>
        <Container marginBottom="base">
          <AuthTitle>
            <FormattedMessage id="PASSWORD_RESET" />
          </AuthTitle>
        </Container>
        {errors.serverError?.message && (
          <Alert type="error">
            <Text>{errors.serverError.message}</Text>
          </Alert>
        )}
        <Input
          marginBottom="loose"
          control={control}
          name="password"
          label={intl.formatMessage({ id: 'NEW_PASSWORD' })}
          autoComplete="password"
          keyboardType="default"
          autoCapitalize="none"
          accessibilityLabel="password"
          accessibilityHint="My Careship new password"
          disabled={isSubmitting}
          secureTextEntry
          testID="password"
        />
        <Input
          marginBottom="loose"
          control={control}
          name="confirmPassword"
          label={intl.formatMessage({ id: 'NEW_PASSWORD_CONFIRMATION' })}
          autoComplete="password"
          keyboardType="default"
          autoCapitalize="none"
          accessibilityLabel="confirm password"
          accessibilityHint="confirm new My Careship password"
          disabled={isSubmitting}
          secureTextEntry
          testID="confirmPassword"
        />
        <Btn
          disabled={isSubmitting}
          accessibilityRole="button"
          accessibilityLabel="submit"
          accessibilityHint="Submit password reset form"
          onPress={handlePressSubmit}
          testID="submit"
        >
          <FormattedMessage id="PASSWORD_RESET_BUTTON" />
        </Btn>

        <ResetPasswordLink to="/login">
          <FormattedMessage id="BUTTON_CANCEL" />
        </ResetPasswordLink>
      </Form>
    </Wrapper>
  );
}
