import { Avatar as BaseAvatar } from 'react-native-paper';
import React, { ComponentProps } from 'react';

const { Image } = BaseAvatar;

const Avatar = ({ source, size, ...props }: ComponentProps<typeof Image>) => (
  <Image accessibilityIgnoresInvertColors {...props} size={size} source={source} />
);

export default Avatar;
