function checkRoute({ name }: { name: string }, routeNames: string[]): boolean {
  return routeNames.includes(name);
}

type State = { routes: { name: string; state?: State }[] };
type RouterState = {
  index?: number;
  routes: { name: string; state?: State }[];
};

function check({ routes, index }: RouterState, routeNames: string[]): boolean {
  const route = routes[index || 0];

  if (!route || (routes.length > 1 && index === undefined)) {
    return false;
  }

  if (checkRoute(route, routeNames)) {
    return true;
  }

  const { state } = route;

  return !!state && check(state, routeNames);
}

export default function isActiveRoute(state: RouterState, routeNames: string[]) {
  if (!state.routes) {
    return false;
  }

  return check(state, routeNames);
}
