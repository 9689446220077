import * as t from 'io-ts';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { either } from 'fp-ts';
import { pipe } from 'fp-ts/function';

function storageKey(contractId: string) {
  return `feedback-survey--${contractId}`;
}

function parse(s: string): either.Either<Error, unknown> {
  return either.tryCatch(
    (): unknown => JSON.parse(s),
    (reason) => new Error(String(reason)),
  );
}

const StateDecoder = t.type({
  frequency: t.union([t.literal(1), t.literal(3)]),
  bookingCount: t.number,
  disabled: t.boolean,
});

export interface StoreData {
  frequency: number;
  bookingCount: number;
  disabled: boolean;
}

const initialState: StoreData = {
  bookingCount: 0,
  disabled: false,
  frequency: 1,
};

export async function getFeedbackSurveyData(contractId: string): Promise<StoreData> {
  return pipe(
    String(await AsyncStorage.getItem(storageKey(contractId))),
    parse,
    // eslint-disable-next-line @typescript-eslint/unbound-method
    either.chainW(StateDecoder.decode),
    either.getOrElse(() => initialState),
  );
}

export async function setFeedbackSurveyData(contractId: string, value: StoreData): Promise<void> {
  return AsyncStorage.setItem(storageKey(contractId), JSON.stringify(value));
}
