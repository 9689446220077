import React, { ComponentProps } from 'react';
import { HelperText } from 'react-native-paper';

type InputErrorMessageProps = Pick<
  ComponentProps<typeof HelperText>,
  'visible' | 'children' | 'padding' | 'style' | 'testID'
>;

export default function InputErrorMessage({ visible, ...props }: InputErrorMessageProps) {
  return visible ? <HelperText type="error" {...props} /> : null;
}
