/**
 * @generated SignedSource<<7a776d953bee39e8034d2cfbf6c33037>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FinancialOverviewQuery$variables = {};
export type FinancialOverviewQuery$data = {
  readonly me: {
    readonly stats?: {
      readonly " $fragmentSpreads": FragmentRefs<"MonthlyEarnings_monthlyBalance">;
    };
  };
};
export type FinancialOverviewQuery = {
  variables: FinancialOverviewQuery$variables;
  response: FinancialOverviewQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "FinancialOverviewQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CaregiverStats",
                "kind": "LinkedField",
                "name": "stats",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "MonthlyEarnings_monthlyBalance"
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Caregiver",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "FinancialOverviewQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CaregiverStats",
                "kind": "LinkedField",
                "name": "stats",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "monthlyDocumentedDurationInMinutes",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "monthlyDocumentedGrossEarningsInCents",
                    "storageKey": null
                  },
                  (v0/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "Caregiver",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v0/*: any*/)
            ],
            "type": "Node",
            "abstractKey": "__isNode"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "40107cb07fcac376688928e3e2aa4f42",
    "id": null,
    "metadata": {},
    "name": "FinancialOverviewQuery",
    "operationKind": "query",
    "text": "query FinancialOverviewQuery {\n  me {\n    __typename\n    ... on Caregiver {\n      stats {\n        ...MonthlyEarnings_monthlyBalance\n        id\n      }\n    }\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n}\n\nfragment MonthlyEarnings_monthlyBalance on CaregiverStats {\n  monthlyDocumentedDurationInMinutes\n  monthlyDocumentedGrossEarningsInCents\n}\n"
  }
};
})();

(node as any).hash = "fb13609996f1f139b996ba5c050d8e82";

export default node;
