import React, { ComponentProps, useCallback, useState } from 'react';
import {
  SectionList,
  SectionListData,
  SectionListRenderItem,
  SectionListRenderItemInfo,
  StyleSheet,
} from 'react-native';
import { useRefetchableFragment } from 'react-relay/hooks';
import { graphql } from 'react-relay';
import { useNavigation } from '@react-navigation/native';
import { isFuture } from 'date-fns';
import DividingHeader from '../../Component/DividingHeader';
import { intl } from '../../../Infrastructure/Intl';
import ProposalListItem from './ProposalListItem';
import ContractListItem from './ContractListItem';
import { useFocusEffect } from '../../../Infrastructure/Hook';
import { ContractListItem_contract$key } from './__generated__/ContractListItem_contract.graphql';
import { ProposalContractSectionList_query$key } from './__generated__/ProposalContractSectionList_query.graphql';
import nonNullEdges from '../../../Infrastructure/Relay/nonNullEdges';
import { ProposalListItem_proposal$key } from './__generated__/ProposalListItem_proposal.graphql';
import { CustomerStackNavigationProp } from '../Navigator/CustomerStackNavigationProp';

const itemHeight = 96 + 1;

const styles = StyleSheet.create({
  itemText: { marginTop: 8 },
  item: { minHeight: itemHeight },
  container: {
    paddingTop: 32,
    paddingBottom: 64,
  },
  lastItem: {
    marginBottom: 32,
  },
});

interface ProposalContractSectionListProps {
  allProposalsAndContractsFragmentRef: ProposalContractSectionList_query$key;
}

const isActiveContract = ({ node: { isActive } }: { node: { isActive: boolean } }): boolean =>
  isActive;

const isActiveProposal = ({
  node: { status, expiresAt },
}: {
  node: { status: string; expiresAt: string };
}): boolean => status !== 'DECLINED' && isFuture(new Date(expiresAt));

const renderSectionHeader: ComponentProps<typeof TypedSectionList>['renderSectionHeader'] = ({
  section: { title },
}) => <DividingHeader>{title}</DividingHeader>;

function ContractListItemWithFirstBooking({
  last,
  onPress,
  contractFragment,
}: {
  last: boolean;
  onPress: () => void;
  contractFragment: ContractListItem_contract$key;
}) {
  return (
    <ContractListItem
      contractFragment={contractFragment}
      addressStyle={styles.itemText}
      style={[styles.item, last ? styles.lastItem : undefined]}
      onPress={onPress}
    />
  );
}

type ContractRenderProps = {
  contractId: string;
} & ContractListItem_contract$key;

type ProposalRenderProps = {
  serviceRequestId: string;
} & ProposalListItem_proposal$key;

class TypedSectionList extends SectionList<ProposalRenderProps | ContractRenderProps> {}

export default function ProposalContractSectionList({
  allProposalsAndContractsFragmentRef,
}: ProposalContractSectionListProps) {
  const [isFetchingProposalsAndContracts] = useState(false);

  const [{ allContracts, allProposals }, refetchProposalsAndContracts] = useRefetchableFragment(
    graphql`
      fragment ProposalContractSectionList_query on Query
      @refetchable(queryName: "ProposalContractSectionListRefetch_query") {
        allContracts {
          edges {
            node {
              contractId
              isActive
              ...ContractListItem_contract
            }
          }
        }
        allProposals {
          edges {
            node {
              id
              serviceRequestId
              expiresAt
              status
              ...ProposalListItem_proposal
            }
          }
        }
      }
    `,
    allProposalsAndContractsFragmentRef,
  );

  const refresh = () => {
    refetchProposalsAndContracts({}, { fetchPolicy: 'store-and-network' });
  };
  const refreshing = isFetchingProposalsAndContracts;

  useFocusEffect(
    useCallback(() => {
      const { dispose } = refetchProposalsAndContracts(
        {},
        {
          fetchPolicy: 'store-and-network',
        },
      );
      return () => {
        dispose();
      };
    }, [refetchProposalsAndContracts]),
  );

  const navigation = useNavigation<CustomerStackNavigationProp<'CustomerDashboard'>>();

  const handleContractSelected = useCallback(
    (contractId: string) => {
      navigation.navigate('CustomerDetailView', {
        contractId,
      });
    },
    [navigation],
  );

  const handleProposalSelected = useCallback(
    (serviceRequestId: string) => {
      navigation.navigate('ProposalDetailView', {
        serviceRequestId,
      });
    },
    [navigation],
  );

  const renderProposalItem: SectionListRenderItem<ProposalRenderProps> = ({
    item: { serviceRequestId, ...proposalFragment },
    index,
    section,
  }) => {
    const handlePress = () => handleProposalSelected(serviceRequestId);
    const isLast = index === section.data.length - 1;

    return (
      <ProposalListItem
        addressStyle={styles.itemText}
        key={serviceRequestId}
        style={[styles.item, isLast ? styles.lastItem : undefined]}
        onPress={handlePress}
        proposalFragment={proposalFragment}
      />
    );
  };

  const renderContract: SectionListRenderItem<ContractRenderProps> = ({
    item: { contractId, ...contractFragment },
    index,
    section,
  }) => {
    const handlePress = () => handleContractSelected(contractId);
    const isLast = index === section.data.length - 1;

    return (
      <ContractListItemWithFirstBooking
        contractFragment={contractFragment}
        key={contractId}
        last={isLast}
        onPress={handlePress}
      />
    );
  };

  const contractSection: SectionListData<ContractRenderProps | ProposalRenderProps> = {
    title: intl.formatMessage({ id: 'MY_CUSTOMERS' }),
    data:
      allContracts?.edges
        ?.filter(nonNullEdges)
        .filter(isActiveContract)
        .map(({ node }) => node) || [],
    keyExtractor: (item) => (item as ContractRenderProps).contractId,
    renderItem: (props) => {
      return renderContract(props as SectionListRenderItemInfo<ContractRenderProps>);
    },
  };

  const proposalSection: SectionListData<ProposalRenderProps | ContractRenderProps> = {
    title: intl.formatMessage({ id: 'MY_PROPOSALS' }),
    data:
      allProposals?.edges
        ?.filter(nonNullEdges)
        .filter(isActiveProposal)
        .map(({ node }) => node) || [],
    keyExtractor: (item) => (item as ProposalRenderProps).serviceRequestId,
    renderItem: (props) => {
      return renderProposalItem(props as SectionListRenderItemInfo<ProposalRenderProps>);
    },
  };

  const sections = [
    // If there's no proposals we don't want to show the title for them
    ...(proposalSection.data.length !== 0 ? [proposalSection] : []),
    contractSection,
  ];

  return (
    <TypedSectionList
      initialNumToRender={6}
      refreshing={refreshing}
      onRefresh={refresh}
      contentContainerStyle={[styles.container]}
      sections={sections}
      stickySectionHeadersEnabled
      renderSectionHeader={renderSectionHeader}
    />
  );
}
