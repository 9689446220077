import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';
import { CareshipColors } from '../CSTheme';

interface RepetitionIconProps extends SvgProps {
  color?: string;
  size?: number;
}

function RepetitionIcon({
  color = CareshipColors.slate400,
  size = 24,
  ...props
}: RepetitionIconProps) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" {...props}>
      <Path
        fill={color}
        d="M10 5c.4 0 .7.3.8.6v.1l.1 4.4c0 .4-.3.7-.6.7h-.1l-3.9.3c-.4 0-.8-.3-.8-.7-.1-.4.2-.7.5-.8h.1l3-.2c-.7-.8-1.5-1.2-3-1.2-2.2 0-3.9 1.8-3.9 4.2 0 2.4 1.9 4.2 4.2 4.2 1.7 0 2.9-.8 3.8-2.2.3-.4.5-.9.7-1.4l.1-.2.5-1.3.1-.3c.2-.6.5-1.2.9-1.7 1.2-1.8 2.8-2.9 5.1-2.9 3.1 0 5.6 2.6 5.6 5.8s-2.5 5.8-5.7 5.7c-1.7-.1-3.3-1-4.4-2.4-.2-.3-.2-.8.2-1 .3-.2.8-.2 1 .2.8 1.1 2 1.7 3.2 1.8 2.3 0 4.1-1.9 4.1-4.2s-1.9-4.2-4.1-4.2c-1.7 0-2.9.8-3.8 2.2-.2.4-.4.7-.6 1.1l-.1.3-.3.9-.2.6-.1.2c-.2.6-.5 1.2-.9 1.7-1.2 1.8-2.8 2.9-5.1 2.9-3.1 0-5.7-2.6-5.7-5.8C.7 9.2 3 6.6 6.1 6.6c1.4 0 2.4.3 3.2.8V5.6c0-.4.3-.7.6-.8l.1.2z"
      />
    </Svg>
  );
}

export default RepetitionIcon;
