import { AuthorizedClient, isError, logger, SuccessResponse } from '../../Service';
import { SessionStore } from '../../Store';

export default class PushTokenService {
  constructor(private sessionStore: SessionStore, private apiClient: AuthorizedClient) {}

  async register(): Promise<SuccessResponse<any>> {
    const endpoint = '/caregiver-app/notification-token';
    const token = this.sessionStore.expoPushToken;

    if (!token) {
      throw new Error(`attempted POST ${endpoint} with no expoPushToken in store`);
    }

    const result = await this.apiClient.request(endpoint, {
      method: 'POST',
      body: JSON.stringify({
        token,
      }),
    });

    if (isError(result)) {
      throw new Error('failed to associate push token with user');
    }

    logger.debug('push token associated to user');

    return result;
  }

  async deregister() {
    const endpoint = '/caregiver-app/notification-token/delete';
    const token = this.sessionStore.expoPushToken;

    if (!token) {
      throw new Error(`attempted POST ${endpoint} with no expoPushToken in store`);
    }

    const result = await this.apiClient.request(endpoint, {
      method: 'POST',
      body: JSON.stringify({
        token,
      }),
    });

    if (isError(result)) {
      throw new Error('failed to deregister push token from user');
    }

    logger.debug('device push token deregister complete');

    return result;
  }
}
