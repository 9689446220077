import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';
import CSTheme from '../CSTheme';

type PrinterIconProps = SvgProps & {
  color?: string;
  accentColor?: string;
  size?: number;
};

export default function PrinterIcon({
  color = CSTheme.colors.text,
  accentColor,
  size = 24,
  ...props
}: PrinterIconProps) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" {...props}>
      <Path
        d="M18.5,12.5 L5.5,12.5 C5.08578644,12.5 4.75,12.8357864 4.75,13.25 L4.75,22.25 C4.75,22.6642136 5.08578644,23 5.5,23 L18.5,23 C18.9142136,23 19.25,22.6642136 19.25,22.25 L19.25,13.25 C19.25,12.8357864 18.9142136,12.5 18.5,12.5 Z M17.75,14 L17.75,21.5 L6.25,21.5 L6.25,14 L17.75,14 Z"
        fill={accentColor || color}
      />
      <Path
        d="M18.039856,1 C18.6427232,1 19.0918915,1.18734758 19.3363057,1.65852016 C19.462325,1.90145547 19.4965845,2.06989112 19.5018682,2.449983 L19.5018682,2.449983 L19.499,7 L20.8405273,7 C21.9633166,7 22.9052689,7.82022645 22.9850554,8.88854977 L22.9905273,9.03571429 L22.9905273,15.4642857 C22.9905273,16.5539812 22.0973919,17.4215857 20.9924501,17.4949697 L20.8405273,17.5 L20.4767921,17.5 C20.0625785,17.5 19.7267921,17.1642136 19.7267921,16.75 C19.7267921,16.3703042 20.008946,16.056509 20.3750215,16.0068466 L20.4767921,16 L20.8405273,16 C21.1758423,16 21.4340903,15.7936277 21.4823868,15.5475526 L21.4905273,15.4642857 L21.4905273,9.03571429 C21.4905273,8.78287398 21.2658108,8.55121765 20.9492624,8.50741479 L20.8405273,8.5 L3.15,8.5 C2.81468501,8.5 2.556437,8.7063723 2.50814055,8.9524474 L2.5,9.03571429 L2.5,15.4642857 C2.5,15.717126 2.7247165,15.9487823 3.04126495,15.9925852 L3.15,16 L3.49867282,16 C3.91288639,16 4.24867282,16.3357864 4.24867282,16.75 C4.24867282,17.1296958 3.96651894,17.443491 3.60044338,17.4931534 L3.49867282,17.5 L3.15,17.5 C2.02721079,17.5 1.08525841,16.6797736 1.00547198,15.6114502 L1,15.4642857 L1,9.03571429 C1,7.94601878 1.89313546,7.07841427 2.99807726,7.00503032 L3.15,7 L5,7 L4.99929686,2.70979027 C4.99955963,1.6372691 5.32326944,1.05945243 6.33063138,1.00435207 L6.49542236,1 Z M5.75,9.5 C6.16421356,9.5 6.5,9.83578644 6.5,10.25 C6.5,10.6296958 6.21784612,10.943491 5.85177056,10.9931534 L5.75,11 L4.75,11 C4.33578644,11 4,10.6642136 4,10.25 C4,9.87030423 4.28215388,9.55650904 4.64822944,9.50684662 L4.75,9.5 L5.75,9.5 Z M18.001,2.5 L6.501,2.5 L6.499,7 L17.999,7 L18,2.98486328 L18.001,2.5 Z"
        fill={color}
      />
    </Svg>
  );
}
