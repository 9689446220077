import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';
import { CareshipColors } from '../CSTheme';

interface MapPinIconProps extends SvgProps {
  color?: string;
  size?: number;
}

function MapPinIcon({ color = CareshipColors.slate400, size = 24, ...props }: MapPinIconProps) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" {...props}>
      <Path
        fill={color}
        d="M12 1c5.1 0 8.8 3.6 8.8 9.1 0 2.3-1.2 4.9-3.3 7.7-1.4 1.9-2.8 3.5-4.6 5.3-.2.2-.5.4-.9.4s-.7-.2-.9-.4l-1.3-1.3-.3-.3-2.1-2.4c-1.4-1.8-2.6-3.5-3.3-5.2-.6-1.4-.9-2.6-.9-3.8C3.2 4.8 7 1 12 1zm0 1.5c-4.1 0-7.2 3.1-7.2 7.6 0 .9.3 2 .8 3.2.7 1.5 1.7 3.1 3.1 4.8.6.8 1.3 1.5 2 2.3l.3.3 1.1 1.1.3-.3c1.3-1.4 2.4-2.6 3.5-4l.4-.5c1.9-2.6 3-5 3-6.9-.1-4.7-3-7.6-7.3-7.6zM12 6c1.8 0 3.2 1.5 3.2 3.2s-1.5 3.2-3.2 3.2S8.8 11 8.8 9.2 10.2 6 12 6zm0 1.5c-1 0-1.8.8-1.8 1.8S11 11 12 11s1.8-.8 1.8-1.8S13 7.5 12 7.5z"
      />
    </Svg>
  );
}

export default MapPinIcon;
