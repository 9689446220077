import styled from '@emotion/native';
import { sidebarMenuWidth } from '../Navigation/SidebarMenu';

export default styled.View(({ theme: { breakpoints } }) => [
  {
    display: 'flex',
    alignItems: 'center',
    minHeight: '100vh',
    flex: 1,
    width: 'auto',
    flexWrap: 'wrap',
    flexDirection: 'row',
  },
  breakpoints.up('md', {
    paddingLeft: sidebarMenuWidth,
  }),
  breakpoints.down('md', {
    paddingTop: 72,
  }),
]);
