import styled from '@emotion/native';
import { sidebarMenuWidth } from '../Navigation/SidebarMenu';

export default styled.View<{ menuOpen?: boolean }>(({ menuOpen, theme: { breakpoints } }) => [
  {
    alignSelf: 'stretch',
    maxWidth: 780,
    flexShrink: 1,
    flex: 1,
    flexBasis: '100%',
    paddingLeft: 80 - 16,
    paddingRight: 16,
  },
  breakpoints.down('md', {
    transform: menuOpen ? [{ translateX: -sidebarMenuWidth }] : [],
    opacity: menuOpen ? 0.3 : 1,
    paddingLeft: 0,
    paddingRight: 0,
  }),
]);
