import React from 'react';
import { FormattedDate } from 'react-intl';
import { isThisYear } from 'date-fns';
import { DividingHeader } from '../../../Component';

export default function DateSectionListHeader({ date: dateString }: { date?: string }) {
  if (!dateString) {
    return null;
  }

  const date = new Date(dateString);

  return (
    <DividingHeader>
      <FormattedDate value={date} month="long" />{' '}
      {isThisYear(date) ? null : <FormattedDate value={date} year="numeric" />}
    </DividingHeader>
  );
}
