/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { ReactElement, useCallback, useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { isPast } from 'date-fns';
import { Button, Container, CSTheme, Layout } from '../../Component';
import useBookings from '../../../Context/Booking/Hook/useBookings';
import BookingList from '../Component/BookingList/BookingList';
import BookingTabs from '../Component/BookingTabs';
import EmptyBookingList from '../Component/BookingList/EmptyBookingList';
import { events, trackEvent } from '../../../Infrastructure/Tracking/Tracking';
import { BookingStackNavigationProp } from '../Navigator/BookingStackNavigationProp';
import { useFocusEffect } from '../../../Infrastructure/Hook';
import Booking from '../../../Context/Booking/Store/Model/Booking';
import useProfile from '../../../Context/Profile/Hook/useProfile';
import { AppNavigationParamList } from "../../Root/Navigator/AppNavigator";

type FilterValue = 'past' | 'upcoming';

export default function BookingListScreen(): ReactElement {
  // TODO: review the typing. Prev dev did not make it.
  const { params } = useRoute<RouteProp<AppNavigationParamList, 'DashboardTab'>>();
  const { profile } = useProfile();
  const [filter, setFilter] = useState<FilterValue>('upcoming');
  const { bookings, refresh } = useBookings(
    useCallback(
      (booking: Booking) => {
        switch (filter) {
          case 'past':
            return isPast(booking.endDate);
          case 'upcoming':
            // includes bookings where the date is now
            return !isPast(booking.endDate);
          default:
            throw new Error('Invalid filter value');
        }
      },
      [filter],
    ),
  );
  useFocusEffect(refresh);

  useEffect(() => {
    if (params?.isPastBooking) {
      setFilter('past');
    }
  }, [params?.isPastBooking]);

  const navigation = useNavigation<BookingStackNavigationProp<'Diary'>>();

  return (
    <Layout>
      <BookingList
        emptyListPlaceholder={filter === 'upcoming' ? <EmptyBookingList /> : undefined}
        ListHeaderComponent={
          <>
            <BookingTabs
              style={{ marginBottom: 16, marginTop: 16 }}
              filter={filter}
              onFilterChange={setFilter}
            />
            {!profile?.isCareshipEmployee && (
              <Container vertical style={{ marginBottom: 16, alignItems: 'center' }}>
                <Button
                  onPress={() => {
                    // @ts-ignore TODO: review the typing. Prev dev did not make it.
                    navigation.navigate('AddAppointment');
                    trackEvent(events.BOOKING_LIST_ADD_APPOINTMENT);
                  }}
                  size="sm"
                  icon={<MaterialCommunityIcons size={24} color="white" name="plus-circle-outline" />}
                  theme={CSTheme}
                >
                  <FormattedMessage id="BUTTON_ADD_BOOKING" />
                </Button>
              </Container>
            )}
          </>
        }
        onBookingSelected={(booking) => {
          // @ts-ignore TODO: review the typing. Prev dev did not make it.
          navigation.navigate('BookingDetail', {
            bookingId: booking.bookingId,
          });

          trackEvent(events.BOOKING_LIST_OPEN_BOOKING, {
            status: booking.status,
            isPast: booking.hasNotEnded,
          });
        }}
        bookings={filter === 'past' ? bookings.reverse() : bookings}
      />
    </Layout>
  );
}
