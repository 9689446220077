/**
 * @generated SignedSource<<9921b6ac8f2c10b478999ddac1ef86cb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ExperienceAndSkillEnum = "CHILDCARE" | "OFFICE_WORK" | "COMPUTERS_AND_INTERNET" | "WHEELCHAIR" | "POST_REHAB" | "PAIN_RELIEF" | "MASSAGE" | "COACHING" | "%future added value";
export type LanguageEnum = "GERMAN" | "ENGLISH" | "SPANISH" | "FRENCH" | "ARABIC" | "CHINESE" | "CROATIAN" | "CZECH" | "DANISH" | "DUTCH" | "FINNISH" | "GREEK" | "HEBREW" | "HUNGARIAN" | "ITALIAN" | "JAPANESE" | "KOREAN" | "POLISH" | "PORTUGUESE" | "NORWEGIAN" | "ROMANIAN" | "RUSSIAN" | "SERBIAN" | "SIGN_LANGUAGE" | "SWEDISH" | "TURKISH" | "VIETNAMESE" | "OTHER" | "%future added value";
export type MedicalConditionEnum = "DEMENTIA" | "STROKE" | "LIFE_SUPPORT" | "DEPRESSIONS" | "MENTAL_DISABILITY" | "DOWN_SYNDROME" | "PARKINSONS" | "DIABETES" | "MULTIPLE_SCLEROSIS" | "%future added value";
export type UpdateCaregiverInput = {
  currentPosition?: string | null;
  aboutMe?: string | null;
  languages?: ReadonlyArray<LanguageEnum> | null;
  contactAndAddress?: CaregiverContactAndAddressInput | null;
  additionalInfo?: CaregiverAdditionalInfoInput | null;
  experienceWithMedicalConditions?: ReadonlyArray<MedicalConditionEnum> | null;
  experienceAndSkills?: ReadonlyArray<ExperienceAndSkillEnum> | null;
  clientMutationId?: string | null;
};
export type CaregiverContactAndAddressInput = {
  mobilePhoneNumber: string;
  phoneNumber: string;
  company: string;
  address: CaregiverContactAndAddressAddressInput;
};
export type CaregiverContactAndAddressAddressInput = {
  street: string;
  houseNumber: string;
  city: string;
  postalCode: string;
  additionalInformation?: string | null;
};
export type CaregiverAdditionalInfoInput = {
  hasDriversLicence: boolean;
  hasCar: boolean;
  isSmoker: boolean;
  noHomesWithPets: boolean;
};
export type LanguagesFormMutation$variables = {
  input: UpdateCaregiverInput;
};
export type LanguagesFormMutation$data = {
  readonly updateCaregiver: {
    readonly caregiver: {
      readonly id: string;
      readonly languages: ReadonlyArray<{
        readonly key: LanguageEnum;
      } | null>;
    };
  } | null;
};
export type LanguagesFormMutation = {
  variables: LanguagesFormMutation$variables;
  response: LanguagesFormMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateCaregiverPayload",
    "kind": "LinkedField",
    "name": "updateCaregiver",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Caregiver",
        "kind": "LinkedField",
        "name": "caregiver",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Language",
            "kind": "LinkedField",
            "name": "languages",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "key",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LanguagesFormMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LanguagesFormMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "8db45c9bf340721ece70ffc06f8d73c8",
    "id": null,
    "metadata": {},
    "name": "LanguagesFormMutation",
    "operationKind": "mutation",
    "text": "mutation LanguagesFormMutation(\n  $input: UpdateCaregiverInput!\n) {\n  updateCaregiver(input: $input) {\n    caregiver {\n      id\n      languages {\n        key\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "17c1a3ff1337199445a80c24ce64ee1d";

export default node;
