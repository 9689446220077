/**
 * @generated SignedSource<<e86d8073c97ca7d30575e06f1ef01c1f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DashboardQuery$variables = {
  splitDate: string;
  startDate: string;
};
export type DashboardQuery$data = {
  readonly allContracts: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly isActive: boolean;
      };
    } | null> | null;
  } | null;
  readonly me: {
    readonly stats?: {
      readonly " $fragmentSpreads": FragmentRefs<"MonthlyEarnings_monthlyBalance" | "CustomerSatisfaction_customerSatisfactionScore" | "ProposalResponse_stats">;
    };
  };
};
export type DashboardQuery = {
  variables: DashboardQuery$variables;
  response: DashboardQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "splitDate"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "startDate"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "ContractConnection",
  "kind": "LinkedField",
  "name": "allContracts",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ContractEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Contract",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isActive",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "kind": "Variable",
  "name": "startDate",
  "variableName": "startDate"
},
v4 = [
  {
    "kind": "Variable",
    "name": "startDate",
    "variableName": "splitDate"
  }
],
v5 = [
  {
    "kind": "Variable",
    "name": "endDate",
    "variableName": "splitDate"
  },
  (v3/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DashboardQuery",
    "selections": [
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CaregiverStats",
                "kind": "LinkedField",
                "name": "stats",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "MonthlyEarnings_monthlyBalance"
                  },
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "CustomerSatisfaction_customerSatisfactionScore"
                  },
                  {
                    "args": [
                      {
                        "kind": "Variable",
                        "name": "splitDate",
                        "variableName": "splitDate"
                      },
                      (v3/*: any*/)
                    ],
                    "kind": "FragmentSpread",
                    "name": "ProposalResponse_stats"
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Caregiver",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DashboardQuery",
    "selections": [
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CaregiverStats",
                "kind": "LinkedField",
                "name": "stats",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "monthlyDocumentedDurationInMinutes",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "monthlyDocumentedGrossEarningsInCents",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "customerSatisfactionScore",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": (v4/*: any*/),
                    "kind": "ScalarField",
                    "name": "averageMinutesToFirstCall",
                    "storageKey": null
                  },
                  {
                    "alias": "previousAverageMinutesToFirstCall",
                    "args": (v5/*: any*/),
                    "kind": "ScalarField",
                    "name": "averageMinutesToFirstCall",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": (v4/*: any*/),
                    "kind": "ScalarField",
                    "name": "proposalResponseRate",
                    "storageKey": null
                  },
                  {
                    "alias": "previousProposalResponseRate",
                    "args": (v5/*: any*/),
                    "kind": "ScalarField",
                    "name": "proposalResponseRate",
                    "storageKey": null
                  },
                  (v1/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "Caregiver",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v1/*: any*/)
            ],
            "type": "Node",
            "abstractKey": "__isNode"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "44dccdf7637f224c27c886dd56280f90",
    "id": null,
    "metadata": {},
    "name": "DashboardQuery",
    "operationKind": "query",
    "text": "query DashboardQuery(\n  $splitDate: DateTime!\n  $startDate: DateTime!\n) {\n  allContracts {\n    edges {\n      node {\n        id\n        isActive\n      }\n    }\n  }\n  me {\n    __typename\n    ... on Caregiver {\n      stats {\n        ...MonthlyEarnings_monthlyBalance\n        ...CustomerSatisfaction_customerSatisfactionScore\n        ...ProposalResponse_stats_2KgINI\n        id\n      }\n    }\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n}\n\nfragment CustomerSatisfaction_customerSatisfactionScore on CaregiverStats {\n  customerSatisfactionScore\n}\n\nfragment MonthlyEarnings_monthlyBalance on CaregiverStats {\n  monthlyDocumentedDurationInMinutes\n  monthlyDocumentedGrossEarningsInCents\n}\n\nfragment ProposalResponse_stats_2KgINI on CaregiverStats {\n  averageMinutesToFirstCall(startDate: $splitDate)\n  previousAverageMinutesToFirstCall: averageMinutesToFirstCall(startDate: $startDate, endDate: $splitDate)\n  proposalResponseRate(startDate: $splitDate)\n  previousProposalResponseRate: proposalResponseRate(startDate: $startDate, endDate: $splitDate)\n}\n"
  }
};
})();

(node as any).hash = "ddc9944bf043aff3515242e453c4c58e";

export default node;
