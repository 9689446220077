import * as React from 'react';
import Svg, { Path, SvgProps, G } from 'react-native-svg';
import { CareshipColors } from '../CSTheme';

export default function TravelDistanceIllustration(props: SvgProps) {
  return (
    <Svg viewBox="0 0 288 48" {...props}>
      <G transform="translate(0,2)">
        <Path
          fill={CareshipColors.slate300}
          d="M12 13.5c-2 0-3.6 1.6-3.6 3.6s1.6 3.6 3.6 3.6 3.6-1.6 3.6-3.6-1.6-3.6-3.6-3.6zm0 1.5c1.2 0 2.1.9 2.1 2.1s-.9 2.1-2.1 2.1-2.1-.9-2.1-2.1.9-2.1 2.1-2.1zm0 5.7c-3.2 0-5.8 2.6-5.8 5.8v2.2c0 .4.4.6.7.6h1.5l.6 6.5c0 .4.4.7.7.7H14.1c.3 0 .6-.3.6-.7l.6-6.5h1.5c.4 0 .8-.3.8-.8v-2.1c.2-3.2-2.4-5.7-5.6-5.7zm.2 1.5c2.3.1 4.1 2 4.1 4.3v1.4h-1.5c-.3 0-.6.3-.6.7l-.7 6.4h-2.9l-.6-6.5v-.1c-.1-.3-.4-.6-.7-.6H7.9v-1.4c0-2.4 1.9-4.3 4.3-4.3v.1z"
        />
        <Path
          fill={CareshipColors.slate300}
          d="M276 11c5.1 0 8.8 3.6 8.8 9.1 0 2.3-1.2 4.9-3.3 7.7-1.4 1.9-2.8 3.5-4.6 5.3-.2.2-.5.4-.9.4s-.7-.2-.9-.4l-1.3-1.3-.3-.3-2.1-2.4c-1.4-1.8-2.6-3.5-3.3-5.2-.6-1.4-.9-2.6-.9-3.8 0-5.3 3.8-9.1 8.8-9.1zm0 1.5c-4.1 0-7.2 3.1-7.2 7.6 0 .9.3 2 .8 3.2.7 1.5 1.7 3.1 3.1 4.8.6.8 1.3 1.5 2 2.3l.3.3 1.1 1.1.3-.3c1.3-1.4 2.4-2.6 3.5-4l.4-.5c1.9-2.6 3-5 3-6.9-.1-4.7-3-7.6-7.3-7.6zm0 3.5c1.8 0 3.2 1.5 3.2 3.2s-1.5 3.2-3.2 3.2-3.2-1.5-3.2-3.2 1.4-3.2 3.2-3.2zm0 1.5c-1 0-1.8.8-1.8 1.8S275 21 276 21s1.8-.8 1.8-1.8-.8-1.7-1.8-1.7z"
        />
        <Path
          d="M24 34.1c18.7 11.3 36.1 12.9 52.2 5 24.1-11.9 51.1-17 66.5-17s28 1 39.2-11S216-3.6 221 5.2s-19.9 17-13.6 28.9 33.7 12.5 56.7-2"
          fill="none"
          stroke={CareshipColors.slate300}
          strokeLinecap="round"
          strokeDasharray={2}
        />
      </G>
    </Svg>
  );
}
