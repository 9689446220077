import styled from '@emotion/native';
import { CareshipColors } from '../CSTheme';

export default styled.Text<{ active?: boolean }>(({ active, theme: { breakpoints } }) => [
  {
    height: 48,
    padding: 8,
    paddingBottom: 10,
    backgroundColor: active ? CareshipColors.white : 'transparent',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  breakpoints.up('md', {
    paddingLeft: 16,
    borderTopLeftRadius: 24,
    borderBottomLeftRadius: 24,
  }),
  breakpoints.down('md', {
    paddingRight: 16,
    borderBottomRightRadius: 24,
    borderTopRightRadius: 24,
    flexDirection: 'row-reverse',
    justifyContent: 'flex-end',
  }),
]);
