import React from 'react';
import Svg, { Path, Rect, SvgProps } from 'react-native-svg';

export default function BookingUpcomingIllustration({
  width = 310,
  height = 166,
  ...props
}: SvgProps) {
  return (
    <Svg width={width} height={height} viewBox="0 0 309.15 166.4" {...props}>
      <Path
        d="M235.32 93h-23L215 62.84a10.38 10.38 0 00-10.32-11.32H101.57A10.37 10.37 0 0091.25 61L88 97.34a10.3 10.3 0 00.47 4.13H60.26a10.14 10.14 0 00-10-8.48H10.13A10.14 10.14 0 000 104l3.32 38.64A10.14 10.14 0 0013.45 152h2.48l-1.77 14.41h4.19L23.68 152H52l-1.78 14.41h4.2L59.76 152l3.46-16.51h114l-.47 5.48a10.14 10.14 0 0010.09 11h-2.34l5.32 14.41H194L192.24 152h28.34l5.33 14.41h4.19L228.33 152H232a10.13 10.13 0 0010.09-9.29l3.33-38.71a10.15 10.15 0 00-10.1-11z"
        fill="#b6d4ff"
      />
      <Path
        data-name="Fill-7"
        d="M67.31 108.66H181.9a10.38 10.38 0 0010.32-9.47l3.22-36.35a10.38 10.38 0 00-10.32-11.32H70.54A10.38 10.38 0 0060.21 61L57 97.34a10.39 10.39 0 0010.32 11.32"
        fill="#dceeff"
        fillRule="evenodd"
      />
      <Path
        data-name="Fill-11"
        d="M41.58 134.16h123.1a11.1 11.1 0 000-22.2H41.58a11.1 11.1 0 000 22.2"
        fill="#dceeff"
        fillRule="evenodd"
      />
      <Path
        d="M196.13 93h-6a10.13 10.13 0 00-10.13 9.27l-2.74 31.89H29l-2.74-31.89A10.14 10.14 0 0016.15 93h-6A10.14 10.14 0 000 104l3.32 38.64A10.14 10.14 0 0013.45 152H192.8a10.15 10.15 0 0010.1-9.29l3.32-38.71a10.15 10.15 0 00-10.09-11z"
        fill="#cce3ff"
        fillRule="evenodd"
      />
      <Path
        data-name="Fill-1"
        fill="#dceeff"
        fillRule="evenodd"
        d="M230.98 53.63h56.88V5.13h-56.88v48.5z"
      />
      <Path
        data-name="Stroke-2"
        fill="none"
        stroke="#dceeff"
        strokeWidth={0.5}
        d="M230.98 53.63h56.88V5.13h-56.88v48.5z"
      />
      <Path
        data-name="Fill-4"
        fill="#fff"
        fillRule="evenodd"
        d="M227.63 53.69h56.89V5.2h-56.89v48.49z"
      />
      <Path
        data-name="Stroke-5"
        d="M285.25 4.46H226.9v50h58.35zm-1.46 1.47V53h-55.43V6z"
        fill="#dceeff"
      />
      <Path
        data-name="Fill-7"
        fill="#b6d4ff"
        fillRule="evenodd"
        d="M231.86 30.49h47.82V9.09h-47.82v21.4z"
      />
      <Path
        data-name="Dates"
        d="M233.61 45.44a1.76 1.76 0 11-1.75 1.76 1.75 1.75 0 011.75-1.76zm7.29 0a1.76 1.76 0 11-1.75 1.76 1.75 1.75 0 011.75-1.76zm7.58 0a1.76 1.76 0 11-1.75 1.76 1.75 1.75 0 011.75-1.76zm7.29 0A1.76 1.76 0 11254 47.2a1.75 1.75 0 011.77-1.76zm-22.16-5.87a1.76 1.76 0 11-1.75 1.76 1.75 1.75 0 011.75-1.76zm7.29 0a1.76 1.76 0 11-1.75 1.76 1.75 1.75 0 011.75-1.76zm7.58 0a1.76 1.76 0 11-1.75 1.76 1.75 1.75 0 011.75-1.76zm7.29 0a1.76 1.76 0 11-1.77 1.76 1.75 1.75 0 011.77-1.76zm7.29 0a1.76 1.76 0 11-1.75 1.76 1.75 1.75 0 011.75-1.76zm7.58 0a1.76 1.76 0 11-1.75 1.76 1.75 1.75 0 011.75-1.76zm7.29 0a1.76 1.76 0 11-1.75 1.76 1.75 1.75 0 011.75-1.76zm-29.45-5.86a1.76 1.76 0 11-1.75 1.76 1.75 1.75 0 011.75-1.76zm7.29 0a1.76 1.76 0 11-1.77 1.76 1.75 1.75 0 011.77-1.76zm7.58 0a1.76 1.76 0 11-1.75 1.76 1.75 1.75 0 011.75-1.76zm7.29 0a1.76 1.76 0 11-1.75 1.76 1.75 1.75 0 011.75-1.76zm7.29 0a1.76 1.76 0 11-1.75 1.76 1.75 1.75 0 011.75-1.76z"
        fill="#b6d4ff"
        fillRule="evenodd"
      />
      <Path
        data-name="Rectangle"
        fill="#dceeff"
        d="M234.78 0h3.79v7.62h-3.79zM272.39 0h3.79v7.62h-3.79z"
      />
      <Path
        data-name="Fill-1"
        d="M292 61.61c0 8.22-5 14.89-11.22 14.89s-11.28-6.67-11.28-14.89 5-14.88 11.23-14.88S292 53.39 292 61.61"
        fill="#cce3ff"
        fillRule="evenodd"
      />
      <Path
        data-name="Stroke-3"
        fill="none"
        stroke="#dceeff"
        strokeWidth={2.187}
        d="M280.81 139.79V62.37"
      />
      <Path
        data-name="Fill-5"
        d="M268.72 83.48c5.3 5.27 6.38 12.75 2.4 16.7s-11.49 2.89-16.8-2.38-6.32-12.75-2.39-16.71 11.49-2.88 16.79 2.39"
        fill="#cce3ff"
        fillRule="evenodd"
      />
      <Path
        data-name="Fill-8"
        d="M306.57 94.31c4.27 4.25 3.12 12.29-2.58 18s-13.79 6.81-18.06 2.56"
        fill="#cce3ff"
        fillRule="evenodd"
      />
      <Path
        data-name="Stroke-10"
        fill="none"
        stroke="#dceeff"
        strokeWidth={2.187}
        d="M300.81 101.8l-19.67 19.61"
      />
      <Path
        data-name="Stroke-12"
        fill="none"
        stroke="#dceeff"
        strokeWidth={2.187}
        d="M295.13 83.04l-14.38 14.12"
      />
      <Path
        data-name="Stroke-14"
        fill="none"
        stroke="#dceeff"
        strokeWidth={2.187}
        d="M265.83 114.05l15.01 15.1"
      />
      <Path
        data-name="Stroke-16"
        fill="none"
        stroke="#dceeff"
        strokeWidth={2.187}
        d="M260.65 89.87l20.08 20.2"
      />
      <Path
        data-name="Fill-18"
        d="M286.83 166.38h-12A16.81 16.81 0 01258 149.66v-15.73h45.66v15.73a16.82 16.82 0 01-16.81 16.72"
        fill="#ecf6ff"
        fillRule="evenodd"
      />
      <Path
        data-name="Stroke-20"
        fill="none"
        stroke="#dceeff"
        strokeWidth={2.187}
        d="M303.64 142.67h-45.66"
      />
      <Path
        data-name="Stroke-22"
        fill="none"
        stroke="#dceeff"
        strokeWidth={2.187}
        d="M303.64 151.44h-45.66"
      />
      <Path
        d="M127.53 22.26c2.62 4.88 2.31 17.26-1.07 18.14-1.35.34-4.22-.51-7.14-2.27l1.84 13h-10.82l3.72-17.41a12.28 12.28 0 01-3.25-8.8c.68-9.67 14.11-7.55 16.72-2.66z"
        fill="#ffb300"
        fillRule="evenodd"
      />
      <Path
        data-name="hair"
        d="M123.26 16.94a14 14 0 00-4.53-.5 7.84 7.84 0 00.55-4A12.17 12.17 0 00118 8.92a5 5 0 00-1-1.47 3.16 3.16 0 00-1.53-.83 4.18 4.18 0 00-1.76 0c-.25.06-.48.17-.74.24s-.38-.1-.56-.26a3.23 3.23 0 00-1.62-.6 4.29 4.29 0 00-1.91.23 3.75 3.75 0 00-1.59 1 3 3 0 00-.48.73c0 .11 0 .18-.16.22a3.2 3.2 0 01-.33.07 4.15 4.15 0 00-2.32 2.13 6.75 6.75 0 00-.37 2.81 12.17 12.17 0 002.07 5.33 6.59 6.59 0 003.42 1.94 9.8 9.8 0 00-1.79 3.34 16.43 16.43 0 00-.81 4.06 13.09 13.09 0 00.2 4 4.32 4.32 0 001.93 2.82 8.77 8.77 0 001.49.7 9.54 9.54 0 001.54.5 3.08 3.08 0 002.91-.63 5.07 5.07 0 00.78-1c.08-.12.12-.2 0-.33a2.41 2.41 0 00-.41-.37 3.93 3.93 0 01-1.4-2.55c-.12-1.06.17-2.43 1.19-2.89 1.27-.57 2.67.08 3.79.69a6.2 6.2 0 013-4c1.7-.86 3.66-1 5.41-1.7a3.23 3.23 0 001.26-.8 1.8 1.8 0 00.36-.59 1 1 0 00-.25-.75 13.85 13.85 0 00-5.06-4.02z"
        fill="#191847"
        fillRule="evenodd"
      />
      <Path
        data-name="LegLower"
        d="M183.9 161.42q-13.34-16.32-18-24.2c-5.38-9-9.78-17-11.17-20.06-3.31-7.29 7.63-10.7 10.1-7.3q5.63 7.73 24 48.59z"
        fill="#ffb300"
        fillRule="evenodd"
      />
      <Path
        data-name="LegLower"
        d="M165.69 158.26l-16.55-41.75c-3.54-7.61 13.55-12.62 16.15-9.07 5.91 8 19.94 39.76 21.7 42.16z"
        fill="#5e95a0"
        fillRule="evenodd"
      />
      <Path
        data-name="LegLower"
        d="M121.59 149.9c3.23-4.72 15-20.57 21.38-27.13 1.84-1.89 3.63-3.7 5.33-5.38 5-5 15.07 3.39 10 8-12.53 11.38-30.83 26.73-32.35 28.1-2.12 1.87-6.39-.63-4.36-3.59z"
        fill="#ffb300"
        fillRule="evenodd"
      />
      <Path
        data-name="shoe"
        d="M122.59 147.6a10.41 10.41 0 00-2.39 2.4 24.13 24.13 0 00-1.43 3.29l22 12.72c1.67-1.57 1.93-2.78.79-3.65s-2.09-1.59-2.84-2.18l-7.67-10.59a.31.31 0 00-.45-.07l-1.69 1.3a8 8 0 01-3.24-.8 7.76 7.76 0 01-2.15-2.31.64.64 0 00-.9-.16.05.05 0 00-.03.05zM180.18 159.53a10.5 10.5 0 00-.86 3.3 23.11 23.11 0 00.41 3.57h25.43c.66-2.19.28-3.38-1.14-3.55s-2.61-.33-3.55-.47L188.53 157a.33.33 0 00-.43.16l-.81 2a8.07 8.07 0 01-3.22.93 7.74 7.74 0 01-3-.93.65.65 0 00-.86.31s-.02.05-.03.06z"
        fill="#e4e4e4"
        fillRule="evenodd"
      />
      <Path
        data-name="Leg-and-Butt"
        d="M143.19 118.59q-20.62-3.36-27.63-4.6c-13.32-2.36-17.2-9.54-14.9-25.21h24.53q4.86 1.41 40.68 17.44c4.3 2.14 3.5 8.44 2.71 10.52q0 .12-21.73 37.32l-20.06-12.81 16.44-21.15c.43-.87.42-1.38-.04-1.51z"
        fill="#80bbc1"
        fillRule="evenodd"
      />
      <Path
        data-name="Skin"
        d="M82.16 78.25l9.24 1.84q-13.94 26-15 27.6c-1.52 2.34-.1 6.64.42 8.9-3.28.77-2-4.18-6.14-3.15-3.79.94-7 3.36-10.53.1-.44-.4-.76-1.66.73-2.3 3.69-1.6 9.14-4.6 10-5.77q1.88-2.38 11.28-27.22zm91.39-13.9c-1.46 2.13-2.89 4.43-2.31 5.18s2.26-.08 2.93 1.13q.66 1.22-8.51 2.9L153.43 83l-5.22-6.7 16.34-7.2a39 39 0 018.29-6.52c.8-.28 2.16-.36.71 1.77z"
        fill="#ffb300"
        fillRule="evenodd"
      />
      <Path
        data-name="Coat-Back"
        d="M116.51 43.59l3.9-.67c8.49 8.26 12 28.1 17.62 30.73 5.35 2.48 12.93.08 21.95-3.17l2.76 6.19c-8.19 8.88-25.64 16.39-32.74 12.11-11.29-6.86-13.9-31.33-13.49-45.19z"
        fill="#e87613"
        fillRule="evenodd"
      />
      <Path
        data-name="Shirt"
        d="M99.51 92.71H134c1.58 0 1.28-2.28 1.05-3.43-2.62-13.28-12.88-27.62-12.88-46.53l-10-1.57C104 54.45 101.14 70.79 99.51 92.71z"
        fill="#dde3e9"
        fillRule="evenodd"
      />
      <Path
        data-name="Coat-Front"
        d="M115.51 41.18q13.41 45.79 7.39 61H91.85a113.65 113.65 0 014.22-22.95q-10.54 19.11-17.73 27.5l-7.23-1.42q7-43.44 40.46-64.13z"
        fill="#e87613"
        fillRule="evenodd"
      />
      <Rect
        data-name="Rectangle"
        x={167.82}
        y={58.29}
        width={17.82}
        height={8.91}
        rx={1.62}
        transform="rotate(-54 176.729 62.743)"
        fill="#191847"
      />
      <Path
        data-name="Rectangle"
        d="M178.89 55.31l4.2 3.07a.81.81 0 01.18 1.13L175.7 70a.81.81 0 01-1.13.18l-4.2-3.08a.8.8 0 01-.18-1.12l7.57-10.49a.81.81 0 011.13-.19z"
        fill="#8991dc"
        fillRule="evenodd"
      />
      <Path
        data-name="Combined-Shape"
        d="M180.58 26.63a1 1 0 011.41-.1l1.81 1.57 1.57-1.81a1 1 0 011.42-.1 1 1 0 01.09 1.41l-1.57 1.82 1.81 1.58a1 1 0 01-1.31 1.51L184 30.93l-1.58 1.81a1 1 0 11-1.51-1.31l1.58-1.82-1.82-1.61a1 1 0 01-.09-1.37z"
        fill="#ff9b21"
        fillRule="evenodd"
      />
      <Path
        data-name="Combined-Shape-Copy"
        d="M177.69 38.51a.82.82 0 01.82.82v2h2a.82.82 0 110 1.63h-2v2a.82.82 0 11-1.64 0v-2h-2a.82.82 0 010-1.63h2v-2a.82.82 0 01.82-.82z"
        fill="#ff9b21"
        fillRule="evenodd"
      />
      <Path
        data-name="Combined-Shape"
        d="M173.29 28.51a3.2 3.2 0 11-3.21 3.2 3.2 3.2 0 013.21-3.2zm0 1.6a1.6 1.6 0 101.6 1.6 1.6 1.6 0 00-1.6-1.6z"
        fill="#bedadd"
        fillRule="evenodd"
      />
      <Path
        data-name="Combined-Shape-Copy-4"
        d="M177.89 18.9a2.2 2.2 0 11-2.2 2.2 2.21 2.21 0 012.2-2.2zm0 1.1a1.1 1.1 0 101.1 1.1 1.1 1.1 0 00-1.1-1.1z"
        fill="#bedadd"
        fillRule="evenodd"
      />
      <Path
        data-name="Path-5"
        d="M166.46 67.29q4-4.05 5.67-4.05h3.24q.81 1.62-2.43 1.62a3.2 3.2 0 00-2.43 2.43 3.81 3.81 0 00.81 3.24z"
        fill="#ffb300"
        fillRule="evenodd"
      />
    </Svg>
  );
}
