import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';
import { CareshipColors } from '../CSTheme';

interface CalendarRepetitionIconProps extends SvgProps {
  color?: string;
  size?: number;
}

function SpeechBubbleFeedback({
  color = CareshipColors.slate400,
  size = 24,
  ...props
}: CalendarRepetitionIconProps) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" {...props}>
      <Path
        fill={color}
        d="M12 1h.3c4.5.1 8.6 3 10.1 7.3 1.6 4.4.2 9.3-3.5 12.2-3.5 2.8-8.3 3.2-12.2 1.1l-.2-.1-5.3 2.2c-.6.2-1.2-.3-1-.9v-.1l2.2-5.3-.2-.3C.6 14 .6 10.2 2.2 7l.2-.3C4.3 3.2 8 1 12 1zm0 1.5c-3.4 0-6.6 1.9-8.3 4.9-1.7 3-1.6 6.7.2 9.6.1.2.1.5.1.7l-1.6 3.9L6.3 20c.2-.1.4-.1.6 0l.1.1c3.4 2.1 7.8 1.9 11-.7s4.4-6.8 3-10.6c-1.4-3.8-5-6.3-9-6.3zM12 15h.1c.6.1 1 .5 1 1.1 0 .6-.5 1.1-1.1 1.1-.6 0-1.1-.5-1.1-1.1 0-.6.4-1.1 1-1.1h.1zm0-8.5c.4 0 .8.3.8.7V12.9c0 .5-.4.9-.8.9s-.8-.3-.8-.7V7.4c-.1-.5.3-.9.8-.9z"
      />
    </Svg>
  );
}

export default SpeechBubbleFeedback;
