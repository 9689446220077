import styled from '@emotion/native';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Alert from './Alert';
import Container from './Container';

const FullScreen = styled.View({
  flex: 1,
  alignItems: 'center',
  justifyContent: 'center',
});

export default function ErrorFullscreen() {
  return (
    <FullScreen>
      <Container>
        <Alert type="error">
          <FormattedMessage id="ERROR_UNKNOWN" />
        </Alert>
      </Container>
    </FullScreen>
  );
}
