import { Feather } from '@expo/vector-icons';
import React, { ComponentProps, FC, ReactElement, ReactNode } from 'react';
import { StyleProp, StyleSheet, View, ViewStyle, TextStyle } from 'react-native';
import styled from '@emotion/native';
import { SpacedBoxProps } from '@emotion/react';
import Text from './Text';
import Title from './Title';
import CSTheme from './CSTheme';
import TouchableRow from './TouchableRow';
import Row from './Row';
import withSpacing, { shouldForwardProp } from '../Root/Theme/withSpacing';

const styles = StyleSheet.create({
  actionLabel: {
    marginRight: 4,
  },
  action: {
    marginLeft: 'auto',
    flexWrap: 'nowrap',
    flexDirection: 'row',
    alignItems: 'center',
    maxHeight: 24,
  },
  icon: {
    marginRight: 8,
    marginLeft: 4,
  },
  inner: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
  main: {
    overflow: 'hidden',
    flexShrink: 1,
    marginRight: 16,
  },
});

type ListItemProps = {
  multiline?: boolean;
  icon?: ComponentProps<typeof Feather>['name'] | ReactElement;
  actionIcon?: ReactElement | null;
  actionIconLeft?: ReactElement | null;
  children: ReactNode;
  actionLabel?: ReactNode;
  onPress?: (() => void) | null;
  style?: StyleProp<ViewStyle>;
  titleProps?: Omit<
    ComponentProps<typeof Title>,
    'style' | 'size' | 'ellipseMode' | 'numberOfLines' | 'children'
  >;
  labelProps?: Omit<ComponentProps<typeof Text>, 'style' | 'size' | 'children'>;
  labelStyle?: StyleProp<TextStyle>;
};

function ListItemAction({
  onPress,
  icon,
  children,
  actionLabel,
  style,
  titleProps,
  labelProps,
  labelStyle,
  multiline,
  actionIcon,
  actionIconLeft,
}: ListItemProps) {
  const Wrapper: FC = ({ children: wrapperChildren }) =>
    onPress ? (
      <View style={style}>
        <TouchableRow onPress={onPress}>{wrapperChildren}</TouchableRow>
      </View>
    ) : (
      <Row style={[{ marginHorizontal: 16 }, style]}>{wrapperChildren}</Row>
    );

  const iconWithStyles = (el?: ReactElement) => {
    if (!el) return null;

    return React.cloneElement(el, { style: styles.icon, size: 24 });
  };

  const [firstChild, ...restChildren] = Array.prototype.concat(children) as typeof children[];

  return (
    <Wrapper>
      <View style={styles.inner}>
        {typeof icon === 'string' ? (
          <Feather name={icon} color={CSTheme.colors.text} size={24} style={styles.icon} />
        ) : (
          iconWithStyles(icon)
        )}
        <View style={styles.main}>
          <Title
            ellipsizeMode="tail"
            numberOfLines={multiline ? undefined : 1}
            size="h4"
            thin
            {...titleProps}
          >
            {firstChild}
          </Title>
          {restChildren}
        </View>
        {(actionLabel || actionIcon || actionIconLeft) && (
          <View style={styles.action}>
            {actionIconLeft}
            {actionLabel && (
              <Text muted size="large" style={[styles.actionLabel, labelStyle]} {...labelProps}>
                {actionLabel}
              </Text>
            )}
            {Boolean(onPress) &&
              (actionIcon || (
                <Feather name="chevron-right" color={CSTheme.colors.disabled} size={24} />
              ))}
          </View>
        )}
      </View>
    </Wrapper>
  );
}

export default styled(ListItemAction, {
  shouldForwardProp,
})<ListItemProps & SpacedBoxProps>(withSpacing());
