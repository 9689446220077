import { StyleProp, StyleSheet, Text as RNText, View, ViewStyle } from 'react-native';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { FormattedDate, FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import React, { useCallback } from 'react';
import { graphql, useFragment } from 'react-relay/hooks';
import { addMonths, format, isPast, isWithinInterval, startOfMonth, subDays } from 'date-fns';
import { css } from '@emotion/native';
import { useNavigation } from '@react-navigation/native';
import nonNullEdges from '../../../Infrastructure/Relay/nonNullEdges';
import {
  Button,
  Container,
  DividingHeader,
  ListItemAction,
  ListItemData,
  Text,
  Title,
} from '../../Component';
import { CareshipColors } from '../../Component/CSTheme';
import MessageRow from './MessageRow';
import {
  AwardIcon,
  BankIcon,
  CheckIcon,
  ClockIcon,
  EuroIcon,
  MapPinIcon,
  RepetitionIcon,
  EditIcon,
  AlertCircle,
} from '../../Component/Icon';
import { toDurationString } from '../../../Infrastructure/Intl';
import {
  CareReceiverDetails_contract$data,
  CareReceiverDetails_contract$key,
} from './__generated__/CareReceiverDetails_contract.graphql';
import { CustomerStackNavigationProp } from '../Navigator/CustomerStackNavigationProp';
import {
  isMobilePhoneNumber,
  openMap,
  openEmailAddress,
  openPhoneNumber,
} from '../../../Infrastructure/LinkHandler';
import useProfile from '../../../Context/Profile/Hook/useProfile';

const styles = StyleSheet.create({
  centeredRow: {
    alignItems: 'center',
  },
  ctaButton: {
    marginTop: 8,
    marginBottom: 32,
  },
  statisticDividingHeader: { marginTop: 24 },
  statisticRow: {
    backgroundColor: CareshipColors.slate050,
    flexDirection: 'row',
    justifyContent: 'space-around',
    paddingTop: 24,
    paddingBottom: 20,
    borderBottomWidth: 1,
    borderBottomColor: CareshipColors.slate100,
  },
  customerSinceRow: {
    marginTop: 16,
    paddingBottom: 48,
  },
  customerSinceTitle: {
    marginBottom: 8,
  },
  statisticText: {
    // Supply top level lineHeight for android
    lineHeight: 32,
    marginTop: 4,
  },
  earningsDetail: {
    marginTop: 8,
    marginLeft: 32,
  },
  commissionDetail: {
    marginTop: 4,
  },
  section: {
    marginTop: 24,
  },
  additionalServices: {
    marginTop: 16,
  },
});

const missingServiceDocumentationActionLabel = css({
  marginLeft: 4,
  color: CareshipColors.red,
});

export function isCurrentMonthServiceDocumentation(
  year: string,
  month: string,
  date: Date = new Date(),
) {
  return year === format(date, 'y') && month === format(date, 'M');
}

export function isNearEndOfMonth(date: Date = new Date()) {
  const serviceDocumentationWarningEnd = startOfMonth(addMonths(date, 1));
  const serviceDocumentationWarningStart = subDays(serviceDocumentationWarningEnd, 4);

  return isWithinInterval(date, {
    start: serviceDocumentationWarningStart,
    end: serviceDocumentationWarningEnd,
  });
}

type ContractStats = {
  minuteCount: number;
  grossEarningsInCents: number;
};

// TODO: Replace client side booking calculation with a stats field
// containing: { bookingCount, durationInMinutes, grossPriceInCents }
// See \Careship\Application\CaregiverApp\Booking\Persistence\PostgresBookingRepository::loadStatsByCaregiverAndContractId
// in backend for the relevant query.
const getStats = (bookings: CareReceiverDetails_contract$data['bookings']): ContractStats =>
  (bookings?.edges?.filter(nonNullEdges) || [])
    .filter(({ node }) => ['fulfilled', 'invoiced'].includes(node.status))
    .reduce<ContractStats>(
      (
        carry,
        {
          node: {
            pricing: { durationInMinutes, grossPricePerHourInCents },
          },
        },
      ) => ({
        minuteCount: durationInMinutes + carry.minuteCount,
        grossEarningsInCents:
          (durationInMinutes * grossPricePerHourInCents) / 60 + carry.grossEarningsInCents,
      }),
      {
        minuteCount: 0,
        grossEarningsInCents: 0,
      },
    );

export interface CareReceiverDetailsProps {
  style?: StyleProp<ViewStyle>;
  contractFragment: CareReceiverDetails_contract$key;
}

export default function CareReceiverDetails({ style, contractFragment }: CareReceiverDetailsProps) {
  const { profile } = useProfile();
  const isCareshipEmployee = profile?.isCareshipEmployee ?? false;

  const intl = useIntl();
  const navigation = useNavigation<CustomerStackNavigationProp<'CustomerDetailView'>>();

  const {
    contractId,
    careReceiver: { address, careDegree, name, isSelfBooker, email, phoneNumber },
    cancelationScheduledFor,
    createdAt,
    serviceDocumentation,
    paymentMethod,
    hasFulfilledBooking,
    pricing,
    serviceRequest,
    bookings,
  } = useFragment(
    graphql`
      fragment CareReceiverDetails_contract on Contract {
        contractId
        careReceiver {
          email
          careDegree
          name {
            firstName
            lastName
          }
          phoneNumber
          address {
            city
            street
            houseNumber
            postalCode
          }
          isSelfBooker
        }
        createdAt
        hasFulfilledBooking
        serviceRequest {
          description
          schedule {
            frequency
            durationInMinutes
          }
          subServices {
            id
            label
            subServiceId
          }
        }
        pricing {
          grossCustomerPricePerHourInCents
          grossCaregiverPricePerHourInCents
          commissionRate
        }
        cancelationScheduledFor
        serviceDocumentation {
          edges {
            node {
              month
              year
              lastUploadedFile {
                filename
              }
            }
          }
        }
        paymentMethod
        bookings {
          edges {
            node {
              id
              status
              pricing {
                durationInMinutes
                grossPricePerHourInCents
              }
            }
          }
        }
      }
    `,
    contractFragment,
  );

  const handleEmailPress = useCallback<() => void>(() => openEmailAddress(email), [email]);
  const handleCallPress = useCallback<() => void>(
    () => openPhoneNumber(phoneNumber),
    [phoneNumber],
  );
  const showCareDegree = !['none', 'unknown'].includes(careDegree);
  const fullAddress = `${address.street} ${address.postalCode} ${address.city}`;
  const handleDirectionPress = useCallback(() => openMap(fullAddress), [fullAddress]);

  const { grossEarningsInCents, minuteCount } = getStats(bookings);

  const handlePressServiceDocumentationPeriod = useCallback(() => {
    navigation.navigate('ServiceDocumentation', { contractId });
  }, [navigation, contractId]);

  const handleCreateBooking = useCallback(() => {
    navigation.navigate('AddAppointment', {
      contractId,
    });
  }, [navigation, contractId]);

  const isCanceledContract = cancelationScheduledFor && isPast(new Date(cancelationScheduledFor));

  const { node: currentServiceDocumentation } =
    serviceDocumentation.edges
      ?.filter(nonNullEdges)
      .find(({ node: { year, month } }) => isCurrentMonthServiceDocumentation(year, month)) || {};

  const displayServiceDocumentationWarning =
    paymentMethod === 'direct_reimbursement' &&
    currentServiceDocumentation &&
    isNearEndOfMonth() &&
    !currentServiceDocumentation.lastUploadedFile;

  return (
    <View style={style}>
      {hasFulfilledBooking && !isCanceledContract && !profile?.isCareshipEmployee && (
        <Container style={styles.centeredRow}>
          <Button
            style={styles.ctaButton}
            onPress={handleCreateBooking}
            icon={<MaterialCommunityIcons size={24} color="white" name="plus-circle-outline" />}
            size="sm"
          >
            <FormattedMessage id="BUTTON_ADD_BOOKING" />
          </Button>
        </Container>
      )}
      <DividingHeader>
        <FormattedMessage id="CONTACT_DETAILS" />
      </DividingHeader>
      {Boolean(phoneNumber) && (
        <ListItemAction
          onPress={handleCallPress}
          actionLabel={<FormattedMessage id="ACTION_CALL" />}
          icon="phone"
        >
          {phoneNumber}
        </ListItemAction>
      )}
      {isMobilePhoneNumber(phoneNumber) && <MessageRow phone={phoneNumber} />}
      {isSelfBooker && (
        <ListItemAction
          onPress={handleEmailPress}
          actionLabel={<FormattedMessage id="ACTION_EMAIL" />}
          icon="mail"
        >
          {email}
        </ListItemAction>
      )}
      <ListItemAction
        onPress={handleDirectionPress}
        actionLabel={<FormattedMessage id="ACTION_DIRECTIONS" />}
        icon={<MapPinIcon />}
      >
        {address.street
          ? `${address.street} ${address.houseNumber}`
          : `${address.postalCode} ${address.city}`}
      </ListItemAction>
      {showCareDegree && (
        <ListItemData
          actionLabel={<FormattedMessage id="CARE_DEGREE" values={{ degree: careDegree }} />}
          icon={<AwardIcon />}
        >
          <FormattedMessage id="CARE_DEGREE_LABEL" />
        </ListItemData>
      )}
      {!hasFulfilledBooking && (
        <>
          <View style={styles.section}>
            <DividingHeader rightGutter>
              <FormattedMessage id="DETAILS" />
            </DividingHeader>
            <ListItemData
              icon={<RepetitionIcon />}
              actionLabel={
                <FormattedMessage
                  id="BOOKING_FREQUENCY"
                  // empty string means once
                  values={{ frequency: serviceRequest.schedule.frequency || 'once' }}
                />
              }
              titleProps={{ muted: true }}
              labelProps={{ muted: false }}
            >
              <FormattedMessage id="FREQUENCY_LABEL" />
            </ListItemData>
            <ListItemData
              icon={<ClockIcon />}
              actionLabel={toDurationString(serviceRequest.schedule.durationInMinutes)}
              titleProps={{ muted: true }}
              labelProps={{ muted: false }}
            >
              <FormattedMessage id="DURATION_LABEL" />
            </ListItemData>
          </View>
          <View style={styles.section}>
            <DividingHeader rightGutter>
              <FormattedMessage id="SERVICES_REQUESTED" />
            </DividingHeader>
            {serviceRequest.subServices.map((service) => (
              <ListItemData key={service.id} icon={<CheckIcon />}>
                {service.label}
              </ListItemData>
            ))}
          </View>
          {Boolean(serviceRequest.description) && (
            <View style={styles.section}>
              <DividingHeader rightGutter>
                <FormattedMessage
                  id="MORE_ABOUT_CARE_RECEIVER"
                  values={{
                    name: intl.formatMessage({ id: 'NAME_FULL' }, name),
                  }}
                />
              </DividingHeader>
              <Container style={styles.additionalServices}>
                <Text size="xlarge">{serviceRequest.description}</Text>
              </Container>
            </View>
          )}
        </>
      )}
      <DividingHeader rightGutter style={styles.statisticDividingHeader}>
        <FormattedMessage id="FINANCES_SECTION" />
      </DividingHeader>
      {hasFulfilledBooking && (
        <Container>
          <View style={styles.statisticRow}>
            <View>
              <View>
                <Text center uppercase size="small">
                  <FormattedMessage tagName={RNText} id="TOTAL_HOURS_SERVED" />
                </Text>
              </View>
              {/* Android needs center on top level and ios on first nested */}
              <Title center style={styles.statisticText}>
                <Title center size="h0">
                  <FormattedNumber value={minuteCount / 60} maximumFractionDigits={1} />
                </Title>
                <Title muted size="h1">
                  <FormattedMessage tagName={RNText} id="HOURS_WITH_UNIT" values={{ hours: '' }} />
                </Title>
              </Title>
            </View>
            {!isCareshipEmployee && (
              <View>
                <View>
                  <Text center uppercase size="small">
                    <FormattedMessage tagName={RNText} id="TOTAL_EARNINGS" />
                  </Text>
                </View>
                <Text center style={styles.statisticText}>
                  <Title center size="h0">
                    <FormattedNumber
                      value={grossEarningsInCents / 100}
                      minimumFractionDigits={2}
                      maximumFractionDigits={2}
                    />
                  </Title>
                  <Title muted size="h1">
                    {' '}
                    €
                  </Title>
                </Text>
              </View>
            )}
          </View>
        </Container>
      )}
      <ListItemData
        actionLabel={<FormattedMessage id="PAYMENT_METHOD" values={{ paymentMethod }} />}
        icon={<BankIcon />}
      >
        <FormattedMessage id="PAYMENT_METHOD_LABEL" />
      </ListItemData>
      <ListItemAction
        onPress={handlePressServiceDocumentationPeriod}
        icon={<EditIcon />}
        labelStyle={missingServiceDocumentationActionLabel}
        actionLabel={
          displayServiceDocumentationWarning ? (
            <FormattedMessage id="SERVICE_DOCUMENTATION_LABEL_MISSING" />
          ) : (
            ' '
          )
        }
        actionIconLeft={
          displayServiceDocumentationWarning ? <AlertCircle color={CareshipColors.red} /> : null
        }
      >
        <FormattedMessage id="SERVICE_DOCUMENTATION_LABEL" />
      </ListItemAction>
      {!isCareshipEmployee && (
        <ListItemData
          actionLabel={
            <FormattedNumber
              // eslint-disable-next-line react/style-prop-object
              style="currency"
              currency="EUR"
              value={pricing.grossCaregiverPricePerHourInCents / 100}
            />
          }
          icon={<EuroIcon />}
        >
          <FormattedMessage id="EARNINGS_PER_HOUR" />
          <View style={styles.earningsDetail}>
            <Text uppercase size="small">
              <FormattedMessage
                id="CUSTOMER_COST_PER_HOUR"
                values={{
                  cost: intl.formatNumber(pricing.grossCustomerPricePerHourInCents / 100, {
                    currency: 'EUR',
                    style: 'currency',
                  }),
                }}
              />
            </Text>
            <Text uppercase size="small" style={styles.commissionDetail}>
              <FormattedMessage
                id="CARESHIP_COMMISSION"
                values={{
                  commissionRate: intl.formatNumber(pricing.commissionRate, {
                    style: 'percent',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }),
                }}
              />
            </Text>
          </View>
        </ListItemData>
      )}
      <Container>
        <View style={styles.customerSinceRow}>
          <Text center uppercase size="small" style={styles.customerSinceTitle}>
            <FormattedMessage id="YOUR_CUSTOMER_SINCE" />
          </Text>
          <Text center bold size="large">
            <FormattedDate value={new Date(createdAt)} day="2-digit" month="short" year="numeric" />
          </Text>
        </View>
      </Container>
    </View>
  );
}
