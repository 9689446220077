import { Feather } from '@expo/vector-icons';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import HintRow from './Form/HintRow';
import { RoundIcon } from './Icon';
import HintText from './Form/HintText';
import { CareshipColors } from './CSTheme';
import { useStores } from '../../Infrastructure/Store';

interface OverlappingBookingCountRowProps {
  bookings: { startDate: Date; endDate: Date; bookingId?: string }[];
}

export default function OverlappingBookingCountRow({ bookings }: OverlappingBookingCountRowProps) {
  const { bookingStore } = useStores();

  const overlappingBookingCount = bookingStore.overlappingCount(bookings);

  return (
    <HintRow warning={!!overlappingBookingCount}>
      <RoundIcon warning={!!overlappingBookingCount}>
        <Feather
          style={{
            marginTop: overlappingBookingCount ? -1 : 0,
          }}
          color="white"
          size={14}
          name={overlappingBookingCount ? 'x' : 'check'}
        />
      </RoundIcon>
      <HintText color={!overlappingBookingCount ? CareshipColors.secondary : CareshipColors.red}>
        <FormattedMessage
          id="BOOKING_VALIDATION_OVERLAPS"
          values={{ itemCount: overlappingBookingCount }}
        />
      </HintText>
    </HintRow>
  );
}
