import { useState, useEffect } from 'react';
import { reaction } from 'mobx';
import { useStores } from '../../../Infrastructure/Store';
import useDefinitionService from '../../Definition/Hook/useDefinitionService';

export default function useCaregiverBookingCancellationReasons(): any {
  const { definitionStore } = useStores();
  const definitionsService = useDefinitionService();
  const [caregiverBookingCancellationReasons, setCaregiverBookingCancellationReasons] = useState<string[]>([]);

  useEffect(
    () =>
      reaction(
        () => definitionStore.caregiverCancellationReasons,
        (cancellationReasons) => setCaregiverBookingCancellationReasons(Array.from(cancellationReasons)),
        {
          fireImmediately: true,
        },
      ),
    [definitionStore.caregiverCancellationReasons],
  );

  useEffect(() => {
    void definitionsService.fetchBookingCancellationReasonList();
  }, [definitionsService]);

  return caregiverBookingCancellationReasons;
}
