import React from 'react';
import styled from '@emotion/native';
import { useLazyLoadQuery } from 'react-relay/hooks';
import { graphql } from 'react-relay';
import { ScrollView } from 'react-native-gesture-handler';
import { Hr } from '../../Component';
import BookedOut from '../Component/BookedOut';
import { AvailabilityScreenQuery } from './__generated__/AvailabilityScreenQuery.graphql';
import Absences from '../Component/Absences';
import TravelDistance from '../Component/TravelDistance';
import { withLayout } from '../../HOC';

const Divider = styled(Hr)(({ theme: { breakpoints } }) => [
  breakpoints.up('md', {
    marginTop: 48,
    marginBottom: 50 /* compensate for Title line height */,
  }),
  breakpoints.down('md', {
    marginTop: 24,
    marginBottom: 32 /* compensate for Title line height */,
  }),
]);

export default withLayout(function AvailabilityScreen() {
  const data = useLazyLoadQuery<AvailabilityScreenQuery>(
    graphql`
      query AvailabilityScreenQuery {
        me {
          ... on Caregiver {
            ...BookedOutFragment
            ...AbsencesFragment
            ...TravelDistanceFragment
          }
        }
      }
    `,
    {},
  );

  return (
    <ScrollView>
      <BookedOut fragmentRef={data.me} />
      <Divider solid />
      <TravelDistance fragmentRef={data.me} />
      <Divider solid />
      <Absences fragmentRef={data.me} />
    </ScrollView>
  );
});
