import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';
import { Feather } from '@expo/vector-icons';

const styles = StyleSheet.create({
  outer: {
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
  },
  bg: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 1,
  },
  splash: {},
  icon: {
    position: 'relative',
    zIndex: 2,
    marginRight: -4,
    marginTop: -4,
  },
});

export default function Splash({
  size,
  name,
  color,
  style,
  ...props
}: SvgProps & { size: number; name: string }) {
  const svgStyle = { fill: color } as StyleProp<ViewStyle>;
  return (
    <View
      style={[
        styles.outer,
        {
          width: size,
          height: size,
        },
        style,
      ]}
    >
      <Svg style={[styles.bg, svgStyle]} width={48} height={48} viewBox="0 0 48 48" {...props}>
        <Path d="M22.595 46.148l-1.537 1.328a2.148 2.148 0 01-3.324-.66l-.912-1.817a2.148 2.148 0 00-2.595-1.075l-1.929.64A2.148 2.148 0 019.48 42.68l-.147-2.027a2.148 2.148 0 00-1.987-1.987l-2.027-.147a2.148 2.148 0 01-1.883-2.818l.64-1.929A2.148 2.148 0 003 31.178l-1.816-.912a2.148 2.148 0 01-.661-3.324l1.328-1.537a2.148 2.148 0 000-2.81L.524 21.058a2.148 2.148 0 01.66-3.324l1.817-.912a2.148 2.148 0 001.075-2.595l-.64-1.929A2.148 2.148 0 015.32 9.48l2.027-.147a2.148 2.148 0 001.987-1.987l.147-2.027a2.148 2.148 0 012.818-1.883l1.929.64A2.148 2.148 0 0016.822 3l.912-1.816a2.148 2.148 0 013.324-.661l1.537 1.328a2.148 2.148 0 002.81 0L26.942.524a2.148 2.148 0 013.324.66l.912 1.817a2.148 2.148 0 002.595 1.075l1.929-.64A2.148 2.148 0 0138.52 5.32l.147 2.027a2.148 2.148 0 001.987 1.987l2.027.147a2.148 2.148 0 011.883 2.818l-.64 1.929A2.148 2.148 0 0045 16.822l1.816.912a2.148 2.148 0 01.661 3.324l-1.328 1.537a2.148 2.148 0 000 2.81l1.328 1.537a2.148 2.148 0 01-.66 3.324l-1.817.912a2.148 2.148 0 00-1.075 2.595l.64 1.929a2.148 2.148 0 01-1.883 2.818l-2.027.147a2.148 2.148 0 00-1.987 1.987l-.147 2.027a2.148 2.148 0 01-2.818 1.883l-1.929-.64A2.148 2.148 0 0031.178 45l-.912 1.816a2.148 2.148 0 01-3.324.661l-1.537-1.328a2.148 2.148 0 00-2.81 0z" />
      </Svg>
      <Feather size={24} name="user-plus" color="white" style={styles.icon} />
    </View>
  );
}
