/**
 * @generated SignedSource<<7359dca2669c007feefc9e4d9ff0f376>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type PaymentMethod = "invoice" | "sepa_direct_debit" | "direct_reimbursement" | "%future added value";
export type ServiceRequestFrequencyEnum = "ONCE_PER_WEEK" | "TWO_THREE_TIMES_PER_WEEK" | "FOUR_TIMES_PER_WEEK_OR_MORE" | "EVERY_TWO_WEEKS" | "ONCE_OR_TWICE_A_MONTH" | "ON_DEMAND" | "%future added value";
export type Status = "created" | "fulfilled" | "invoiced" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type CareReceiverDetails_contract$data = {
  readonly contractId: string;
  readonly careReceiver: {
    readonly email: string;
    readonly careDegree: string;
    readonly name: {
      readonly firstName: string;
      readonly lastName: string;
    };
    readonly phoneNumber: string;
    readonly address: {
      readonly city: string;
      readonly street: string;
      readonly houseNumber: string;
      readonly postalCode: string;
    };
    readonly isSelfBooker: boolean;
  };
  readonly createdAt: string;
  readonly hasFulfilledBooking: boolean;
  readonly serviceRequest: {
    readonly description: string;
    readonly schedule: {
      readonly frequency: ServiceRequestFrequencyEnum | null;
      readonly durationInMinutes: number;
    };
    readonly subServices: ReadonlyArray<{
      readonly id: string;
      readonly label: string;
      readonly subServiceId: string;
    }>;
  };
  readonly pricing: {
    readonly grossCustomerPricePerHourInCents: number;
    readonly grossCaregiverPricePerHourInCents: number;
    readonly commissionRate: number;
  };
  readonly cancelationScheduledFor: string | null;
  readonly serviceDocumentation: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly month: string;
        readonly year: string;
        readonly lastUploadedFile: {
          readonly filename: string;
        } | null;
      };
    } | null> | null;
  };
  readonly paymentMethod: PaymentMethod;
  readonly bookings: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly status: Status;
        readonly pricing: {
          readonly durationInMinutes: number;
          readonly grossPricePerHourInCents: number;
        };
      };
    } | null> | null;
  };
  readonly " $fragmentType": "CareReceiverDetails_contract";
};
export type CareReceiverDetails_contract$key = {
  readonly " $data"?: CareReceiverDetails_contract$data;
  readonly " $fragmentSpreads": FragmentRefs<"CareReceiverDetails_contract">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "durationInMinutes",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CareReceiverDetails_contract",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "contractId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CareReceiver",
      "kind": "LinkedField",
      "name": "careReceiver",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "email",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "careDegree",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Name",
          "kind": "LinkedField",
          "name": "name",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "firstName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lastName",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "phoneNumber",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Address",
          "kind": "LinkedField",
          "name": "address",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "city",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "street",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "houseNumber",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "postalCode",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isSelfBooker",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasFulfilledBooking",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ServiceRequest",
      "kind": "LinkedField",
      "name": "serviceRequest",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Schedule",
          "kind": "LinkedField",
          "name": "schedule",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "frequency",
              "storageKey": null
            },
            (v0/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "SubServiceDefinition",
          "kind": "LinkedField",
          "name": "subServices",
          "plural": true,
          "selections": [
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "label",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "subServiceId",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ContractPricing",
      "kind": "LinkedField",
      "name": "pricing",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "grossCustomerPricePerHourInCents",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "grossCaregiverPricePerHourInCents",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "commissionRate",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cancelationScheduledFor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ServiceDocumentationConnection",
      "kind": "LinkedField",
      "name": "serviceDocumentation",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ServiceDocumentationEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ServiceDocumentation",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "month",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "year",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "LastUploadedFile",
                  "kind": "LinkedField",
                  "name": "lastUploadedFile",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "filename",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "paymentMethod",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BookingConnection",
      "kind": "LinkedField",
      "name": "bookings",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "BookingEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Booking",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "status",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "BookingPricing",
                  "kind": "LinkedField",
                  "name": "pricing",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "grossPricePerHourInCents",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Contract",
  "abstractKey": null
};
})();

(node as any).hash = "ee8828c034a54d17dbc035b93b4c734d";

export default node;
