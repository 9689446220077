import ProfileStore, { CaregiverProfileResponse } from '../Store/ProfileStore';
import AuthorizedClient, { isError } from '../../../Infrastructure/Service/AuthorizedClient';
import Sentry from '../../../Infrastructure/ErrorTracking/Sentry';
import { logger } from '../../../Infrastructure/Service';

export default class ProfileService {
  constructor(private profileStore: ProfileStore, private apiClient: AuthorizedClient) {}

  async fetchProfile(): Promise<void> {
    const endpoint = '/caregiver-app/me';
    const result = await this.apiClient.request<CaregiverProfileResponse>(endpoint, {method: 'GET'});

    if (isError(result)) {
      logger.warn(
        `Failed fetching ${endpoint}. Response: ${result.status} ${JSON.stringify(
          result.errorCode,
        )}`,
      );

      return;
    }

    Sentry.setUser({ id: result.data.caregiver_id });

    this.profileStore.handleProfileResponse(result.data);
  }
}
