import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {Button, Container, Label, RadioButtonGroup, RadioButtonItem, Title} from '../../../Component';
import BookingFeedbackPrivacyHint from './BookingFeedbackPrivacyHint';
import {TextInput} from "react-native-paper";
import {StyleSheet} from "react-native";

interface QuestionOneProps {
  onSubmit: (value: { careReceiverMood: string, careReceiverMoodAdditionalText: string }) => void;
  isLoading: boolean;
}



const styles = StyleSheet.create({
  container: {
    marginTop: 15,
  }
});

export default function QuestionOne({ onSubmit, isLoading }: QuestionOneProps) {
  const intl = useIntl();
  const [fieldValue, setFieldValue] = useState<string>('');
  const [additionalText, setAdditionalTextValue] = useState<string>('');
  return (
    <>
      <Container style={styles.container}>
        <Title size="h5" center>
          <FormattedMessage id="BOOKING_FEEDBACK_TITLE_QUESTION_ONE" />
        </Title>
      </Container>
      <Container style={styles.container}>
        <RadioButtonGroup value={fieldValue} onValueChange={(value) => setFieldValue(value)}>
          <RadioButtonItem
            label={intl.formatMessage({ id: 'BOOKING_FEEDBACK_MOOD_VERY_GOOD' })}
            value="sehr gut"
          />
          <RadioButtonItem
            label={intl.formatMessage({ id: 'BOOKING_FEEDBACK_MOOD_GOOD' })}
            value="Gut"
          />
          <RadioButtonItem
            label={intl.formatMessage({ id: 'BOOKING_FEEDBACK_MOOD_FAIR' })}
            value="Mittelmäßig"
          />
          <RadioButtonItem
            label={intl.formatMessage({ id: 'BOOKING_FEEDBACK_MOOD_SO_LALA' })}
            value="Schwankend"
          />
          <RadioButtonItem
            label={intl.formatMessage({ id: 'BOOKING_FEEDBACK_MOOD_POOR' })}
            value="Schlecht"
          />
        </RadioButtonGroup>
      </Container>

      <Container style={styles.container}>
          <TextInput mode='outlined' placeholder={intl.formatMessage({ id: 'BOOKING_FEEDBACK_MOOD_FREE_TEXT' })} onChangeText={(additionalText) => setAdditionalTextValue(additionalText)}/>
      </Container>

      <Container style={styles.container}>
        {/*<BookingFeedbackPrivacyHint />*/}
          <Button
            disabled={!fieldValue|| isLoading}
            loading={isLoading}
            onPress={() => onSubmit({ careReceiverMood: fieldValue, careReceiverMoodAdditionalText: additionalText })}
          >
            <FormattedMessage id="BOOKING_FEEDBACK_SUBMIT_STEP_1" />
          </Button>
      </Container>
    </>
  );
}
