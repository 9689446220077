import {
  Disposable,
  GraphQLTaggedNode,
  IEnvironment,
  MutationConfig,
  MutationParameters,
} from 'relay-runtime';
import { useCallback } from 'react';
import { useMutation, UseMutationConfig } from 'react-relay/hooks';

export default function useAsyncMutation<T extends MutationParameters>(
  mutation: GraphQLTaggedNode,
  commitMutationFn?: (environment: IEnvironment, config: MutationConfig<T>) => Disposable,
): readonly [(config: UseMutationConfig<T>) => Promise<T['response']>, boolean] {
  const [commit, isInFlight] = useMutation<T>(mutation, commitMutationFn);

  const asyncCommit = useCallback(
    (config: UseMutationConfig<T>) =>
      new Promise<T['response']>((resolve, reject) => {
        commit({
          ...config,
          /**
           * Payload errors are rejected, to use them as server side validation
           * the catch block needs to check if the error is an array and items
           * instanceof PayloadError.
           *
           * As we typically attempt to validate client side the need of
           * displaying something specific should be relatively rare.
           */
          onCompleted: (response, errors) => (errors?.length ? reject(errors) : resolve(response)),
          onError: (error) => reject(error),
        });
      }),
    [commit],
  );

  return <const>[asyncCommit, isInFlight];
}
