import { useCallback, useState } from 'react';
import * as FileSystem from 'expo-file-system';
import { Platform, Share } from 'react-native';
import * as Sharing from 'expo-sharing';
import { logger } from '../Service';

interface UserDownloadFileReturn {
  download: (filename: string, url: string) => Promise<boolean>;
  isDownloadLoading: boolean;
  isDownloadInProgress: boolean;
}

export default function useDownloadFile(): UserDownloadFileReturn {
  const [isDownloadLoading, setIsDownloadLoading] = useState(false);
  const [isDownloadInProgress, setIsDownloadInProgress] = useState(false);

  const download = useCallback(async (filename: string, url: string): Promise<boolean> => {
    if (Platform.OS === 'web') {
      throw new Error('Web version not supported');
    }

    setIsDownloadInProgress(true);
    setIsDownloadLoading(true);

    const downloadResumable = FileSystem.createDownloadResumable(
      url,
      `${FileSystem.documentDirectory || ''}${filename}`,
      {},
    );

    const downloadedFile = await downloadResumable.downloadAsync();
    setIsDownloadLoading(false);

    if (!downloadedFile) {
      logger.error(`failed to download ${url} for sharing`);
      setIsDownloadInProgress(false);

      return false;
    }

    logger.debug(`Finished downloading ${url} to ${downloadedFile.uri}`);

    let downloaded = false;

    try {
      if (Platform.OS === 'android' && (await Sharing.isAvailableAsync())) {
        await Sharing.shareAsync(downloadedFile.uri);

        downloaded = true;
      }

      if (Platform.OS === 'ios') {
        await Share.share({ url: downloadedFile.uri });

        downloaded = true;
      }
    } catch (error) {
      logger.error(String(error));
    }

    setIsDownloadInProgress(false);

    return downloaded;
  }, []);

  return {
    download,
    isDownloadLoading,
    isDownloadInProgress,
  };
}
