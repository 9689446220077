import RelayModernEnvironment from 'relay-runtime/lib/store/RelayModernEnvironment';
import { loadQuery } from 'react-relay/hooks';
import {
  AuthorizedClientInterface,
  ErrorResponse,
  isError,
  SuccessResponse,
} from '../../../Infrastructure/Service/AuthorizedClient';
import BookingStore, { BookingListResponse, BookingResponse } from '../Store/BookingStore';
import { logger } from '../../../Infrastructure/Service';
import fetchSingleBooking from './fetchSingleBooking';
import fetchAllBookings from './fetchAllBookings';
import { fetchAllBookingsQuery } from './__generated__/fetchAllBookingsQuery.graphql';
import { fetchSingleBookingQuery } from './__generated__/fetchSingleBookingQuery.graphql';

export default class BookingService {
  constructor(
    private bookingStore: BookingStore,
    private apiClient: AuthorizedClientInterface,
    private relayEnvironment: RelayModernEnvironment,
  ) {}

  async fetchBookingList(): Promise<void> {
    const endpoint = '/caregiver-app/booking';
    // also refresh the relay store
    loadQuery<fetchAllBookingsQuery>(
      this.relayEnvironment,
      fetchAllBookings,
      {},
      {
        fetchPolicy: 'store-and-network',
      },
    );

    const result = await this.apiClient.request<BookingListResponse>(endpoint, {method: 'GET'});

    if (!isError(result)) {
      this.bookingStore.handleBookingListResponse(result.data);

      return;
    }

    logger.warn(
      `Failed fetching ${endpoint}. Response: ${result.status} ${JSON.stringify(result.errorCode)}`,
    );
  }

  async fetchBooking(bookingId: string): Promise<SuccessResponse<BookingResponse> | ErrorResponse> {
    const endpoint = `/caregiver-app/booking/${bookingId}`;
    // also refresh the relay store
    loadQuery<fetchSingleBookingQuery>(
      this.relayEnvironment,
      fetchSingleBooking,
      { bookingId },
      {
        fetchPolicy: 'store-and-network',
      },
    );

    const result = await this.apiClient.request<BookingResponse>(endpoint, {method: 'GET'});

    if (isError(result)) {
      logger.warn(
        `Failed fetching ${endpoint}. Response: ${result.status} ${JSON.stringify(
          result.errorCode,
        )}`,
      );

      return result;
    }

    this.bookingStore.handleBookingResponse(result.data);

    return result;
  }

  async createBooking(data: { contractId: string; startDate: string; endDate: string }) {
    const endpoint = '/caregiver-app/booking';

    return this.apiClient.request(endpoint, {
      method: 'POST',
      body: JSON.stringify({
        contract_id: data.contractId,
        end_date: data.endDate,
        start_date: data.startDate,
      }),
    });
  }

  async fulfillBooking(data: {
    bookingId: string;
    startDate: string;
    endDate: string;
    mainServiceIds: string[];
    otherServiceDescription?: string;
  }) {
    const endpoint = `/caregiver-app/booking/fulfill/${data.bookingId}`;

    return this.apiClient.request(endpoint, {
      method: 'POST',
      body: JSON.stringify({
        end_date: data.endDate,
        start_date: data.startDate,
        main_service_ids: data.mainServiceIds,
        other_service_description: data.otherServiceDescription,
      }),
    });
  }

  async fulfillBookingFeedback(data: {
    bookingId: string;
    startDate: string;
    endDate: string;
    mainServiceIds: string[];
    otherServiceDescription?: string;
  }) {
    const endpoint = `/caregiver-app/booking/fulfill/${data.bookingId}`;

    return this.apiClient.request(endpoint, {
      method: 'POST',
      body: JSON.stringify({
        end_date: data.endDate,
        start_date: data.startDate,
        main_service_ids: data.mainServiceIds,
        other_service_description: data.otherServiceDescription,
      }),
    });
  }

  async deleteBooking(bookingId: string) {
    const endpoint = `/caregiver-app/booking/delete/${bookingId}`;

    const result = await this.apiClient.request(endpoint, {
      method: 'POST',
    });

    if (!isError(result) || result.status === 404) {
      // also refresh the relay store
      loadQuery(
        this.relayEnvironment,
        fetchAllBookings,
        {},
        {
          fetchPolicy: 'store-and-network',
        },
      );

      this.bookingStore.delete(bookingId);
    }

    return result;
  }

  async cancelBooking(bookingId: string, cancellationType: string, cancellationReason: string, extraText: string) {
    const endpoint = `/caregiver-app/booking/cancel/${bookingId}`;

    const result = await this.apiClient.request(endpoint, {
      method: 'POST',
      body: JSON.stringify({
        cancellation_type: cancellationType,
        cancellation_reason: cancellationReason,
        cancellation_extra_reason: extraText,
      }),
    });

    if (!isError(result) || result.status === 404) {
      // also refresh the relay store
      loadQuery(
        this.relayEnvironment,
        fetchAllBookings,
        {},
        {
          fetchPolicy: 'store-and-network',
        },
      );

      this.bookingStore.delete(bookingId);
    }

    return result;
  }

  async updateBooking({
    bookingId,
    endDate,
    startDate,
  }: {
    bookingId: string;
    startDate: string;
    endDate: string;
  }) {
    const endpoint = `/caregiver-app/booking/${bookingId}`;

    const response = await this.apiClient.request(endpoint, {
      method: 'POST',
      body: JSON.stringify({
        end_date: endDate,
        start_date: startDate,
      }),
    });

    if (isError(response)) {
      return response;
    }

    const fetchBookingResponse = await this.fetchBooking(bookingId);

    if (isError(fetchBookingResponse)) {
      return fetchBookingResponse;
    }

    return response;
  }

  async uploadImage({ bookingId, imageProof }: { bookingId: string; imageProof: string }): Promise<SuccessResponse<unknown> | ErrorResponse> {
    const endpoint = `/caregiver-app/booking/upload-image/${bookingId}`;

    const response = await this.apiClient.request(endpoint, {
      method: 'POST',
      body: JSON.stringify({
        image_proof_base64: imageProof,
      }),
    });

    if (isError(response)) {
      return response;
    }

    return response;
  }


  async getUploadImage({ bookingId }: { bookingId: string}): Promise<SuccessResponse<unknown> | ErrorResponse> {
    const endpoint = `/caregiver-app/booking/image/download/${bookingId}/request-token`;
    const responseToken = await this.apiClient.request(endpoint, {
      method: 'POST',
    });
    return responseToken;
  }



  async firstBookingConfirm({
    bookingId,
    startDate,
    endDate,
  }: {
    bookingId: string;
    startDate: string;
    endDate: string;
  }): Promise<SuccessResponse<BookingResponse> | ErrorResponse> {
    const updateResult = await this.updateBooking({
      bookingId,
      startDate,
      endDate,
    });

    if (isError(updateResult)) {
      return updateResult;
    }

    return this.apiClient.request(`/caregiver-app/first-booking/confirm/${bookingId}`, {
      method: 'POST',
    });
  }

  async firstBookingUpdateConfirmationStatus({
    bookingId,
    ...data
  }: {
    reason: string;
    description: string;
    bookingId: string;
  }) {
    const endpoint = `/caregiver-app/first-booking/update-confirmation-status/${bookingId}`;

    return this.apiClient.request(endpoint, {
      method: 'POST',
      body: JSON.stringify(data),
    });
  }

  async firstBookingDecline({
    bookingId,
    reason,
    description,
  }: {
    bookingId: string;
    reason: string;
    description: string;
  }): Promise<SuccessResponse<BookingResponse> | ErrorResponse> {
    const endpoint = `/caregiver-app/first-booking/decline/${bookingId}`;

    return this.apiClient.request(endpoint, {
      method: 'POST',
      body: JSON.stringify({
        decline_description: description,
        decline_reason: reason,
      }),
    });
  }
}
