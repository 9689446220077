import { useState, useEffect } from 'react';
import { reaction } from 'mobx';
import { useStores } from '../../../Infrastructure/Store';
import useDefinitionService from '../../Definition/Hook/useDefinitionService';

export default function useCustomerBookingCancellationReasons(): any {
  const { definitionStore } = useStores();
  const definitionsService = useDefinitionService();
  const [customerBookingCancellationReasons, setCustomerBookingCancellationReasons] = useState<string[]>([]);

  useEffect(
    () =>
      reaction(
        () => definitionStore.customerCancellationReasons,
        (cancellationReasons) => setCustomerBookingCancellationReasons(Array.from(cancellationReasons)),
        {
          fireImmediately: true,
        },
      ),
    [definitionStore.customerCancellationReasons],
  );

  useEffect(() => {
    void definitionsService.fetchBookingCancellationReasonList();
  }, [definitionsService]);

  return customerBookingCancellationReasons;
}
