import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';
import { CareshipColors } from '../CSTheme';

interface UploadShareIconProps extends SvgProps {
  color?: string;
  accentColor?: string;
  size?: number;
}

function UploadShareIcon({
  color = CareshipColors.slate400,
  accentColor,
  size = 24,
  ...props
}: UploadShareIconProps) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" {...props}>
      <Path
        d="M 22.329 15.674 C 22.709 15.674 23.023 15.956 23.073 16.322 L 23.079 16.424 L 23.079 20.424 C 23.079 21.615 22.154 22.59 20.983 22.669 L 20.829 22.674 L 3.329 22.674 C 2.138 22.674 1.164 21.749 1.084 20.578 L 1.079 20.424 L 1.079 16.424 C 1.079 16.01 1.415 15.674 1.829 15.674 C 2.209 15.674 2.523 15.956 2.572 16.322 L 2.579 16.424 L 2.579 20.424 C 2.579 20.804 2.861 21.118 3.228 21.167 L 3.329 21.174 L 20.829 21.174 C 21.209 21.174 21.523 20.892 21.573 20.526 L 21.579 20.424 L 21.579 16.424 C 21.579 16.01 21.915 15.674 22.329 15.674 Z"
        fill={color}
      />
      <Path
        d="M 12.08 16.174 C 12.46 16.174 12.774 15.892 12.823 15.526 L 12.83 15.424 L 12.83 4.235 L 16.05 7.454 C 16.316 7.721 16.733 7.745 17.027 7.527 L 17.111 7.454 C 17.377 7.188 17.401 6.771 17.183 6.478 L 17.111 6.394 L 12.611 1.894 C 12.344 1.627 11.928 1.603 11.634 1.821 L 11.55 1.894 L 7.05 6.394 C 6.757 6.687 6.757 7.161 7.05 7.454 C 7.316 7.721 7.733 7.745 8.027 7.527 L 8.111 7.454 L 11.33 4.235 L 11.33 15.424 C 11.33 15.838 11.666 16.174 12.08 16.174 Z"
        fill={accentColor || color}
      />
    </Svg>
  );
}

export default UploadShareIcon;
