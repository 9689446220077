export default function toggleSet<T>(key: T) {
  return (set: Set<T>): Set<T> => {
    if (set.has(key)) {
      set.delete(key);
    } else {
      set.add(key);
    }

    return new Set(set);
  };
}
