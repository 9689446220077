import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from '@emotion/native';
import { CareshipColors } from '../../Component/CSTheme';
import { ShareIcon, CheckIcon } from '../../Component/Icon';
import { Card, Title, Button, Text } from '../../Component';
import { ConfettiLargeIllustration, ConfettiSmallIllustration } from './Illustrations';
import useBreakpoints from '../../Root/Theme/useBreakpoints';
import useShare from '../../../Infrastructure/Hook/useShare';

const CodeCardContent = styled(Card)({ overflow: 'hidden', borderTopRightRadius: 16 });

const DashedBoxContainer = styled.View({
  flexDirection: 'row',
  justifyContent: 'center',
  marginBottom: 24,
});

const CodeTitle = styled(Title)({
  marginBottom: 24,
});

const CodeSubtitle = styled(Text)({
  marginBottom: 16,
});

const DashedBox = styled.View(({ theme }) => ({
  borderColor: theme.colors.slate300,
  borderStyle: 'dashed',
  borderWidth: 1,
  borderRadius: 8,
  paddingHorizontal: 24,
  paddingVertical: 16,
}));

const ConfettiContainer = styled.View({
  position: 'absolute',
  top: 0,
  left: 0,
});

const ReferralCodeShareButtonContainer = styled.View(({ theme: { breakpoints } }) =>
  breakpoints.up('sm', {
    width: 290,
    alignSelf: 'center',
  }),
);

const ShareCodeButtonHint = styled(Text)({
  marginTop: 8,
});

interface ReferralCodeCardProps {
  customerReferralAmount: number;
  caregiverReferralAmount: number;
  referralCode: string;
}

export default function ReferralCodeCard({
  customerReferralAmount,
  caregiverReferralAmount,
  referralCode,
}: ReferralCodeCardProps) {
  const intl = useIntl();
  const breakpoints = useBreakpoints();
  const smDown = breakpoints.down('sm');
  const { share, isShareSupported, copiedToClipboard } = useShare();

  const handleShare = useCallback(() => {
    void share(intl.formatMessage({ id: 'REFERRAL_CODE_SHARE_MESSAGE' }, { referralCode }));
  }, [intl, share, referralCode]);

  return (
    <Card mode="raised" marginHorizontal="base" marginVertical="extraLoose">
      <CodeCardContent padding="loose">
        <ConfettiContainer>
          {smDown(true) ? <ConfettiSmallIllustration /> : <ConfettiLargeIllustration />}
        </ConfettiContainer>
        <Title size="h3" center>
          <FormattedMessage
            id="REFERRAL_CODE_CUSTOMER_AMOUNT"
            values={{
              amount: intl.formatNumber(customerReferralAmount, {
                style: 'currency',
                currency: 'EUR',
                minimumFractionDigits: 0,
              }),
            }}
          />
        </Title>
        <CodeTitle size="h3" center>
          <FormattedMessage
            id="REFERRAL_CODE_CAREGIVER_AMOUNT"
            values={{
              amount: intl.formatNumber(caregiverReferralAmount, {
                style: 'currency',
                currency: 'EUR',
                minimumFractionDigits: 0,
              }),
            }}
          />
        </CodeTitle>
        <CodeSubtitle uppercase muted center bold>
          <FormattedMessage id="REFERRAL_CODE_YOUR_CODE" />
        </CodeSubtitle>
        <DashedBoxContainer>
          <DashedBox>
            <Title size="h5" center>
              {referralCode}
            </Title>
          </DashedBox>
        </DashedBoxContainer>
        <ReferralCodeShareButtonContainer>
          {isShareSupported ? (
            <Button onPress={handleShare} icon={<ShareIcon color="white" />}>
              <FormattedMessage id="REFERRAL_CODE_SHARE_BUTTON" />
            </Button>
          ) : (
            <Button
              color={copiedToClipboard ? CareshipColors.green : CareshipColors.primary}
              icon={copiedToClipboard && <CheckIcon color="white" />}
              onPress={handleShare}
            >
              <FormattedMessage
                id={
                  copiedToClipboard
                    ? 'REFERRAL_CODE_COPY_BUTTON_COPIED'
                    : 'REFERRAL_CODE_COPY_BUTTON'
                }
              />
            </Button>
          )}
        </ReferralCodeShareButtonContainer>
        {!isShareSupported && (
          <ShareCodeButtonHint center muted size="large">
            <FormattedMessage id="REFERRAL_CODE_COPY_BUTTON_HINT" />
          </ShareCodeButtonHint>
        )}
      </CodeCardContent>
    </Card>
  );
}
