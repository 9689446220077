import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';
import { CareshipColors } from '../CSTheme';

interface CalendarRepetitionIconProps extends SvgProps {
  color?: string;
  size?: number;
}

function CalendarRepetitionIcon({
  color = CareshipColors.slate400,
  size = 24,
  ...props
}: CalendarRepetitionIconProps) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" {...props}>
      <Path
        fill={color}
        d="M17.2.5c.4 0 .7.3.7.6V3h2.8c1.2 0 2.2.9 2.2 2.1v15.7c0 1.2-1 2.2-2.2 2.2H3.2C2 23 1 22 1 20.8V5.2C1 4 2 3 3.2 3H6V1.2c0-.4.3-.8.8-.8.4 0 .7.3.7.6v2h9V1.2c0-.4.3-.7.7-.7zM21.5 9h-19v11.8c0 .4.3.7.6.7h17.6c.4 0 .8-.3.8-.8V9zM18 16c.8 0 1.5.7 1.5 1.5S18.8 19 18 19s-1.5-.7-1.5-1.5.7-1.5 1.5-1.5zM6 4.5H3.2c-.4 0-.8.3-.8.8v2.2h19V5.2c0-.4-.3-.7-.6-.7H18v1c0 .4-.3.8-.8.8-.4 0-.7-.3-.7-.6V4.6h-9v1c0 .4-.3.8-.8.8-.3-.2-.6-.4-.7-.8V4.5zM10 16c.8 0 1.5.7 1.5 1.5S10.8 19 10 19s-1.5-.7-1.5-1.5S9.2 16 10 16zm4-5c.8 0 1.5.7 1.5 1.5S14.8 14 14 14s-1.5-.7-1.5-1.5.7-1.5 1.5-1.5zm-8 0c.8 0 1.5.7 1.5 1.5S6.8 14 6 14s-1.5-.7-1.5-1.5S5.2 11 6 11z"
      />
    </Svg>
  );
}

export default CalendarRepetitionIcon;
