import { action, computed, makeObservable, observable } from 'mobx';
import AbstractStore from '../../../Infrastructure/Store/AbstractStore';
import Contract, { ContractAggregateResponse, ContractObject } from './Model/Contract';

export type ContractAggregateListResponse = ContractAggregateResponse[];

/**
 * @deprecated only used by the useBooking hook. Once that is replaced with
 * graphql this and all related models can be removed.
 */
export default class ContractStore extends AbstractStore {
  constructor() {
    super();
    makeObservable(this, {
      contracts: observable,
      viewData: computed,
      hydrate: action,
      reset: action,
      handleContractListResponse: action,
      handleContractResponse: action,
    });
  }

  public contracts: Map<string, Contract> = new Map<string, Contract>();

  public get viewData(): Array<Contract> {
    return Array.from(this.contracts.values())
      .filter((c) => c.isActive)
      .sort((a, b) =>
        a.careReceiver.name.lastName.localeCompare(b.careReceiver.name.lastName, 'de', {
          ignorePunctuation: true,
        }),
      );
  }

  public getContract(contractId: string): Contract | null {
    if (!this.contracts.has(contractId)) {
      return null;
    }

    return this.contracts.get(contractId) as Contract;
  }

  hydrate = (
    data?: {
      contracts: { [id: string]: ContractObject } | null;
    } | null,
  ) => {
    if (!data?.contracts) {
      return;
    }

    this.contracts = new Map<string, Contract>(
      Object.values(data.contracts).map<[string, Contract]>((contract) => [
        contract.contractId,
        Contract.fromObject(contract),
      ]),
    );
  };

  reset = () => {
    this.contracts = new Map<string, Contract>();
  };

  handleContractListResponse(response: ContractAggregateListResponse) {
    this.contracts = new Map<string, Contract>(
      response.map((contract) => [contract.contract_id, Contract.fromResponse(contract)]),
    );
  }

  handleContractResponse(response: ContractAggregateResponse) {
    this.contracts.set(response.contract_id, Contract.fromResponse(response));
  }
}
