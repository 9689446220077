import { useState } from 'react';
import Booking from '../../../Context/Booking/Store/Model/Booking';
import useBookingService from '../../../Context/Booking/Hook/useBookingService';
import { intl } from '../../../Infrastructure/Intl';
import { isError } from '../../../Infrastructure/Service';
import { useShowAlert } from '../../Dialog';

export default function useDeleteBooking(booking: Pick<Booking, 'startDate' | 'bookingId'>): {
  deleteBooking: () => Promise<boolean>;
  isDeleting: boolean;
} {
  const [isDeleting, setIsDeleting] = useState(false);
  const bookingService = useBookingService();
  const showAlert = useShowAlert();
  const { bookingId } = booking;

  const deleteBooking = async () => {
    setIsDeleting(true);

    const result = await bookingService.deleteBooking(bookingId);

    if (isError(result)) {
      showAlert(intl.formatMessage({ id: 'ERROR_RESPONSE' }, { errorCode: result.errorCode }));
      setIsDeleting(false);

      return false;
    }

    setIsDeleting(false);

    return true;
  };

  return { isDeleting, deleteBooking };
}
