import { StyleSheet, View, ViewProps } from 'react-native';
import React, { ReactNode } from 'react';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import CSTheme from './CSTheme';

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
    backgroundColor: CSTheme.colors.primary,
  },
});

type NavigatorSafeAreaProps = ViewProps & {
  children?: ReactNode;
  forceInsetTop?: boolean;
};

export default function NavigatorSafeArea({
  forceInsetTop,
  style,
  ...props
}: NavigatorSafeAreaProps) {
  const { top } = useSafeAreaInsets();
  return (
    <View
      style={[
        styles.wrapper,
        {
          paddingTop: forceInsetTop ? top : undefined,
        },
        style,
      ]}
      {...props}
    />
  );
}
