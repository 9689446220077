import { isFuture, isPast } from 'date-fns';
import Service from './Service';
import Pricing from './Pricing';
import FirstBookingConfirmationStatus from './FirstBookingConfirmationStatus';

export type BookingStatus = 'created' | 'fulfilled' | 'invoiced';

export type FirstBookingStatus = null | 'created' | 'confirmed' | 'declined';

type BaseBooking = {
  readonly bookingId: string;
  readonly contractId: string;
  readonly startDate: Date;
  readonly endDate: Date;
  readonly status: BookingStatus;
  readonly isFirstBooking: boolean;
  readonly pricing: Pricing;
  readonly services: Service[];
  readonly otherServiceDescription: string;
  // getters
  readonly hasNotEnded: boolean;
  readonly isUndocumented: boolean;
  readonly isFulfilled: boolean;
  readonly isDocumentingAllowed: boolean;
  readonly isInvoiced: boolean;
};

export interface FirstBooking extends BaseBooking {
  readonly isFirstBooking: true;
  readonly firstBookingStatus: NonNullable<FirstBookingStatus>;
  readonly firstBookingConfirmationStatus: FirstBookingConfirmationStatus | null;
}

export interface NonFirstBooking extends BaseBooking {
  readonly isFirstBooking: false;
  readonly firstBookingStatus: null;
  readonly firstBookingConfirmationStatus: null;
}

export type BookingUnion = FirstBooking | NonFirstBooking;

export default class Booking {
  constructor(
    public readonly bookingId: string,
    public readonly contractId: string,
    public readonly startDate: Date,
    public readonly endDate: Date,
    public readonly status: BookingStatus,
    public readonly isFirstBooking: boolean,
    public readonly pricing: Pricing,
    public readonly services: Service[],
    public readonly firstBookingStatus: FirstBookingStatus,
    public readonly otherServiceDescription: string,
    public readonly firstBookingConfirmationStatus: null | FirstBookingConfirmationStatus,
  ) {}

  get hasNotEnded() {
    return isFuture(this.endDate);
  }

  get isUndocumented() {
    return !this.hasNotEnded && this.status === 'created';
  }

  get isFulfilled() {
    return this.status === 'fulfilled' || this.status === 'invoiced';
  }

  get isDocumentingAllowed() {
    return this.status === 'created' && isPast(this.startDate);
  }

  get isInvoiced() {
    return this.status === 'invoiced';
  }

  static isFirstBookingTypeGuard = (booking: BookingUnion): booking is FirstBooking => {
    return booking.isFirstBooking;
  };
}
