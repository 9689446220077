import React, { ReactNode } from 'react';
import styled from '@emotion/native';
import Card from '../Card';
import Tooltip from '../Tooltip';

const TitleRow = styled.View<{ hasTooltip: boolean }>(({ hasTooltip }) => ({
  position: 'relative',
  paddingTop: 16,
  paddingHorizontal: hasTooltip ? 46 : 16,
  overflow: 'visible',
  flexDirection: 'row',
}));

const TooltipTrigger = styled.View({
  position: 'absolute',
  right: 0,
  top: 6,
});

interface CardWithTooltipProps {
  children: ReactNode;
  tooltipContent?: ReactNode;
  title: ReactNode;
}

export default function CardWithTooltip({ children, title, tooltipContent }: CardWithTooltipProps) {
  return (
    <Card mode="contained" padding="none" marginVertical="tight">
      {(title || tooltipContent) && (
        <TitleRow hasTooltip={!!tooltipContent}>
          {title}
          {tooltipContent && (
            <TooltipTrigger>
              <Tooltip>{tooltipContent}</Tooltip>
            </TooltipTrigger>
          )}
        </TitleRow>
      )}
      {children}
    </Card>
  );
}
