import { autorun, toJS } from 'mobx';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useEffect } from 'react';
import stores, { StoreName } from '../Store/stores';

export default function useStorePersistence() {
  useEffect(() => {
    // auto-save store state to storage
    const disposers = Object.keys(stores).map((k) =>
      autorun(
        async () => {
          try {
            if (stores[k as StoreName].isInitialized) {
              await AsyncStorage.setItem(k, JSON.stringify(toJS(stores[k as StoreName])));
            }
          } catch (e) {
            // TODO: add error handling
            // eslint-disable-next-line no-console
            console.error(e);
          }
        },
        { delay: 500 },
      ),
    );
    return () => {
      disposers.map((dispose) => dispose());
    };
  }, []);
}
