import Schedule, { ScheduleResponse } from '../../../Proposal/Store/Model/Schedule';
import SubService, { SubServiceResponse } from '../../../Proposal/Store/Model/SubService';

export interface ServiceRequestResponse {
  description: string;
  schedule: ScheduleResponse;
  sub_services: SubServiceResponse[];
}

export interface ServiceRequestObject {
  readonly description: string;
  readonly schedule: Schedule;
  readonly subServices: SubService[];
}

export default class ServiceRequest implements ServiceRequestObject {
  constructor(
    public readonly description: string,
    public readonly schedule: Schedule,
    public readonly subServices: SubService[],
  ) {}

  public static fromObject(serviceRequest: ServiceRequest) {
    return new ServiceRequest(
      serviceRequest.description,
      Schedule.fromObject(serviceRequest.schedule),
      serviceRequest.subServices.map((r) => SubService.fromObject(r)),
    );
  }

  public static fromResponse(response: ServiceRequestResponse) {
    return new ServiceRequest(
      response.description,
      Schedule.fromResponse(response.schedule),
      response.sub_services.map((r) => SubService.fromResponse(r)),
    );
  }
}
