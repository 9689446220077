import { StyleProp, View, ViewStyle } from 'react-native';
import { FormattedMessage } from 'react-intl';
import React, { useCallback } from 'react';
import { graphql, useFragment } from 'react-relay/hooks';
import { DividingHeader, ListItemAction } from '../../Component';
import MessageRow from './MessageRow';
import { AdditionalContactDetails_contract$key } from './__generated__/AdditionalContactDetails_contract.graphql';
import {
  isMobilePhoneNumber,
  openEmailAddress,
  openPhoneNumber,
} from '../../../Infrastructure/LinkHandler';

type AdditionalContactDetailsProps = {
  style: StyleProp<ViewStyle>;
  contractFragment: AdditionalContactDetails_contract$key;
};

export default function AdditionalContactDetails({
  style,
  contractFragment,
}: AdditionalContactDetailsProps) {
  const {
    customer: {
      name: { lastName, firstName },
      email,
      phoneNumber,
    },
  } = useFragment(
    graphql`
      fragment AdditionalContactDetails_contract on Contract {
        customer {
          phoneNumber
          email
          name {
            firstName
            lastName
          }
        }
      }
    `,
    contractFragment,
  );

  const handleEmailPress = useCallback<() => void>(() => openEmailAddress(email), [email]);
  const handleCallPress = useCallback<() => void>(
    () => openPhoneNumber(phoneNumber),
    [phoneNumber],
  );

  return (
    <View style={style}>
      <DividingHeader>
        <FormattedMessage id="CONTACT_DETAILS" />
      </DividingHeader>
      <ListItemAction icon="user">
        <FormattedMessage id="NAME_FULL" values={{ firstName, lastName }} />
      </ListItemAction>
      {Boolean(phoneNumber) && (
        <ListItemAction
          onPress={handleCallPress}
          actionLabel={<FormattedMessage id="ACTION_CALL" />}
          icon="phone"
        >
          {phoneNumber}
        </ListItemAction>
      )}
      {isMobilePhoneNumber(phoneNumber) && <MessageRow phone={phoneNumber} />}
      {Boolean(email) && (
        <ListItemAction
          onPress={handleEmailPress}
          actionLabel={<FormattedMessage id="ACTION_EMAIL" />}
          icon="mail"
        >
          {email}
        </ListItemAction>
      )}
    </View>
  );
}
