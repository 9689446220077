import React, { ReactNode } from 'react';
import { TouchableRipple } from 'react-native-paper';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';
import { CareshipColors } from './CSTheme';

type Props = {
  children: ReactNode;
  onPress: () => void;
  contentStyle?: StyleProp<ViewStyle>;
  style?: StyleProp<ViewStyle>;
  testID?: string;
};

const styles = StyleSheet.create({
  row: {
    minHeight: 64,
    justifyContent: 'center',
    marginHorizontal: 16,
    borderBottomColor: CareshipColors.slate100,
    borderBottomWidth: 1,
    paddingVertical: 16,
  },
  content: {
    flexDirection: 'row',
    alignItems: 'center',
  },
});

export default function TouchableRow({ children, onPress, contentStyle, style, testID }: Props) {
  return (
    <TouchableRipple onPress={onPress} rippleColor={CareshipColors.rippleColor} testID={testID}>
      <View style={[styles.row, style]} pointerEvents="none">
        <View style={[styles.content, contentStyle]}>{children}</View>
      </View>
    </TouchableRipple>
  );
}
