import React, { ComponentProps } from 'react';
import { Feather } from '@expo/vector-icons';
import { StatusBar } from 'react-native';
import IconWithBadge from '../../Component/Icon/IconWithBadge';
import DashboardScreen from '../../Dashboard/Screen/DashboardScreen';
import AccountNavigation from '../../Account/Navigation/AccountNavigation';
import { intl } from '../../../Infrastructure/Intl';
import BookingNavigation from '../../Booking/Screen/BookingNavigation';
import { Screen, Navigator } from '../Navigator/TabNavigator';
import useBookings from '../../../Context/Booking/Hook/useBookings';
import CustomerNavigation from '../../Customer/Navigation/CustomerNavigation';
import { CareshipColors } from '../../Component/CSTheme';
import { useProposals } from '../../../Context/Proposal/Hook';
import { usePushTokenRegistration } from '../../../Infrastructure/PushNotification';

function getTabBarIcon(name: ComponentProps<typeof Feather>['name'], badgeCount?: number) {
  const iconSize = 24;

  return ({ color }: { color: string }) => (
    <IconWithBadge width={iconSize} height={iconSize} badgeCount={badgeCount}>
      <Feather color={color} size={iconSize} name={name} />
    </IconWithBadge>
  );
}

export default function TabNavigation() {
  const { undocumentedBookingCount } = useBookings();
  const { openProposalCount } = useProposals();

  usePushTokenRegistration();

  return (
    <>
      <StatusBar backgroundColor={CareshipColors.primary} barStyle="light-content" />
      <Navigator
        screenOptions={{
          // @ts-ignore (exists on web stack navigator)
          headerShown: false,
        }}
        backBehavior="initialRoute"
        initialRouteName="DashboardTab"
      >
        <Screen
          name="DashboardTab"
          component={DashboardScreen}
          options={{
            title: intl.formatMessage({ id: 'DASHBOARD' }),
            tabBarIcon: getTabBarIcon('home'),
          }}
        />
        <Screen
          name="CustomerTab"
          component={CustomerNavigation}
          options={{
            title: intl.formatMessage({ id: 'CUSTOMERS' }),
            tabBarIcon: getTabBarIcon('users', openProposalCount),
          }}
        />
        <Screen
          name="BookingListTab"
          component={BookingNavigation}
          options={{
            title: intl.formatMessage({ id: 'DIARY' }),
            tabBarIcon: getTabBarIcon('calendar', undocumentedBookingCount),
          }}
        />
        <Screen
          name="AccountTab"
          component={AccountNavigation}
          options={{
            title: intl.formatMessage({ id: 'ACCOUNT' }),
            tabBarIcon: getTabBarIcon('user'),
          }}
        />
      </Navigator>
    </>
  );
}
