import { StyleSheet, View } from 'react-native';
import { FormattedMessage } from 'react-intl';
import React, { ReactElement } from 'react';
import { FaqIcon } from '../../Component/Icon';
import { Button, Card, Text, Title } from '../../Component';
import useHandleFeedback from '../Hook/useHandleFeedback';

const styles = StyleSheet.create({
  card: {
    alignItems: 'flex-start',
  },
  container: {
    marginTop: 16,
  },
  text: {
    marginBottom: 16,
  },
  header: {
    flexDirection: 'row',
    marginBottom: 12,
  },
  icon: {
    marginRight: 16,
  },
});

export default function FeedbackCard(): ReactElement {
  const handleFeedback = useHandleFeedback();

  return (
    <Card mode="outlined" marginTop="extraLoose" marginHorizontal="base" style={styles.card}>
      <View style={styles.header}>
        <FaqIcon style={styles.icon} />
        <Title size="h4">
          <FormattedMessage id="SEND_FEEDBACK_TITLE" />
        </Title>
      </View>
      <Text size="large" style={styles.text}>
        <FormattedMessage id="SEND_FEEDBACK_TEXT" />
      </Text>
      <Button size="sm" onPress={handleFeedback}>
        <FormattedMessage id="SEND_FEEDBACK_BTN" />
      </Button>
    </Card>
  );
}
