import * as Linking from 'expo-linking';
import { FormattedMessage } from 'react-intl';
import React, { useCallback } from 'react';
import { ListItemAction } from '../../Component';

type MessageRowProps = { phone: string };

export default function MessageRow({ phone }: MessageRowProps) {
  const handlePress = useCallback(() => {
    void Linking.openURL(`sms:${phone}`);
  }, [phone]);

  return (
    <ListItemAction
      onPress={handlePress}
      actionLabel={<FormattedMessage id="ACTION_MESSAGE" />}
      icon="message-circle"
    >
      {phone}
    </ListItemAction>
  );
}
