import { useCallback, useEffect, useRef, useState } from 'react';
import { reaction } from 'mobx';
import { useStores } from '../../../Infrastructure/Store';
import { CaregiverProfile } from '../Store/Profile';
import useLogger from '../../../Infrastructure/Hook/useLogger';
import useProfileService from './useProfileStore';

export default function useProfile(): {
  profile: CaregiverProfile | null;
  refresh: () => void;
  isLoading: boolean;
} {
  const stores = useStores();
  const [profile, setProfile] = useState<CaregiverProfile | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const isLoadingRef = useRef(isLoading);
  const logger = useLogger();

  const profileService = useProfileService();

  const refresh = useCallback(() => {
    let isCancelled = false;


    if (!stores.sessionStore.isLoggedIn || isCancelled || isLoadingRef.current) {
      return () => {};
    }

    setIsLoading(true);
    if(stores.profileStore.viewData?.caregiverId == undefined)
    {
      logger.debug('profile refresh requested');
      void (async () => {
        await profileService.fetchProfile();
        if (!isCancelled) {
          setIsLoading(false);
        }
      })();
    }
    else
    {
      setIsLoading(false);
    }

    return () => {
      isCancelled = false;
    };
  }, [logger, profileService, stores.sessionStore.isLoggedIn]);

  useEffect(refresh, [refresh]);

  useEffect(
    () =>
      reaction(
        () => stores.profileStore.viewData,
        (data) => {
          setProfile(data);
        },
        {
          fireImmediately: true,
        },
      ),
    [stores.profileStore],
  );

  return { profile, refresh, isLoading };
}
