import CareReceiver, { CareReceiverObject, CareReceiverResponse } from './CareReceiver';
import Customer, { CustomerObject, CustomerResponse } from './Customer';
import Stats, { StatsResponse } from './Stats';
import Pricing, { PricingResponse } from './Pricing';
import ServiceRequest, { ServiceRequestResponse } from './ServiceRequest';
import { FirstBookingStatus } from '../../../Booking/Store/Model/Booking';
import FirstBookingConfirmationStatus from '../../../Booking/Store/Model/FirstBookingConfirmationStatus';

export enum ContractStatus {
  pending = 'pending',
  confirmed = 'confirmed',
  confirmationDeferred = 'deferred',
  rejected = 'rejected',
}

export type ContractAggregateResponse = {
  readonly contract_id: string;
  readonly confirmation_status: ContractStatus;
  readonly is_active: boolean;
  readonly created_at: string;
  readonly care_receiver: CareReceiverResponse;
  readonly customer: CustomerResponse;
  readonly stats: StatsResponse;
  readonly pricing: PricingResponse;
  readonly has_fulfilled_booking: boolean;
  readonly service_request: ServiceRequestResponse;
  readonly cancelation_initiated_at: string | null;
  readonly cancelation_scheduled_for: string | null;
  readonly first_booking_status: FirstBookingStatus;
  readonly first_booking_confirmation_status: null | FirstBookingConfirmationStatus;
};

export interface ContractObject {
  readonly contractId: string;
  readonly createdAt: Date;
  readonly careReceiver: CareReceiverObject;
  readonly isActive: boolean;
  readonly customer: CustomerObject;
  readonly stats: Stats;
  readonly pricing: Pricing;
  readonly hasFulfilledBooking: boolean;
  readonly serviceRequest: ServiceRequest;
  readonly cancelationInitiatedAt: Date | null;
  readonly cancelationScheduledFor: Date | null;
  readonly confirmationStatus: ContractStatus;
  readonly firstBookingStatus: FirstBookingStatus;
  readonly firstBookingConfirmationStatus: null | FirstBookingConfirmationStatus;
}

export default class Contract implements ContractObject {
  constructor(
    public readonly contractId: string,
    public readonly createdAt: Date,
    public readonly careReceiver: CareReceiver,
    public readonly isActive: boolean,
    public readonly customer: Customer,
    public readonly stats: Stats,
    public readonly pricing: Pricing,
    public readonly hasFulfilledBooking: boolean,
    public readonly serviceRequest: ServiceRequest,
    public readonly cancelationInitiatedAt: Date | null,
    public readonly cancelationScheduledFor: Date | null,
    public readonly confirmationStatus: ContractStatus,
    public readonly firstBookingStatus: FirstBookingStatus,
    public readonly firstBookingConfirmationStatus: null | FirstBookingConfirmationStatus,
  ) {}

  public static fromObject(contract: ContractObject) {
    return new Contract(
      contract.contractId,
      new Date(contract.createdAt),
      CareReceiver.fromObject(contract.careReceiver),
      contract.isActive,
      Customer.fromObject(contract.customer),
      Stats.fromObject(contract.stats),
      Pricing.fromObject(contract.pricing),
      contract.hasFulfilledBooking,
      ServiceRequest.fromObject(contract.serviceRequest),
      contract.cancelationInitiatedAt ? new Date(contract.cancelationInitiatedAt) : null,
      contract.cancelationScheduledFor ? new Date(contract.cancelationScheduledFor) : null,
      contract.confirmationStatus,
      contract.firstBookingStatus,
      contract.firstBookingConfirmationStatus
        ? new FirstBookingConfirmationStatus(
            contract.firstBookingConfirmationStatus.reason,
            contract.firstBookingConfirmationStatus.description,
          )
        : null,
    );
  }

  public static fromResponse(response: ContractAggregateResponse) {
    return new Contract(
      response.contract_id,
      new Date(response.created_at),
      CareReceiver.fromResponse(response.care_receiver),
      response.is_active,
      Customer.fromResponse(response.customer),
      Stats.fromResponse(response.stats),
      Pricing.fromResponse(response.pricing),
      response.has_fulfilled_booking,
      ServiceRequest.fromResponse(response.service_request),
      response.cancelation_initiated_at ? new Date(response.cancelation_initiated_at) : null,
      response.cancelation_scheduled_for ? new Date(response.cancelation_scheduled_for) : null,
      response.confirmation_status,
      response.first_booking_status,
      response.first_booking_confirmation_status
        ? new FirstBookingConfirmationStatus(
            response.first_booking_confirmation_status.reason,
            response.first_booking_confirmation_status.description,
          )
        : null,
    );
  }
}
