import { Platform, StyleSheet, View } from 'react-native';
import React, { ReactNode } from 'react';
import { Text, IconButton } from 'react-native-paper';
import { useNavigation } from '@react-navigation/native';
import CSTheme from './CSTheme';

const styles = StyleSheet.create({
  bar: {
    backgroundColor: CSTheme.colors.background,
    zIndex: 2,
    height: 64,
    flexDirection: 'row',
    alignItems: 'center',
    position: 'relative',
  },
  left: {
    position: 'absolute',
    zIndex: 2,
  },
  middle: {
    flexShrink: 0,
    flexGrow: 1,
    flex: 8,
    alignItems: 'center',
  },
  right: { flex: 0 },
  title: {
    ...CSTheme.fonts.medium,
    fontSize: 20,
  },
});

type Props = {
  backButton?: ReactNode;
  title?: string;
};

export default (function ModalHeader({ backButton, title }: Props) {
  const navigation = useNavigation();

  if (Platform.OS !== 'ios') {
    return null;
  }

  return (
    <View style={styles.bar}>
      <View style={styles.left}>
        <IconButton onPress={() => navigation.goBack()} icon={() => backButton} />
      </View>
      <View style={styles.middle}>
        <Text style={styles.title}>{title}</Text>
      </View>
      <View style={styles.right} />
    </View>
  );
});
