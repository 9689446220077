import { useIntl } from 'react-intl';
import React, { useCallback } from 'react';
import { ListItemAction } from '../../Component';

interface InvoiceListItemProps {
  invoiceId: string;
  invoiceNumber?: string;
  invoiceType?: string;
  onPressInvoice: ({
    invoiceId,
    filename,
    title,
  }: {
    invoiceId: string;
    filename: string;
    title: string;
  }) => Promise<void>;
}

export default function InvoiceListItem({
  invoiceId,
  onPressInvoice,
  invoiceNumber = '-',
  invoiceType = '-',
}: InvoiceListItemProps) {
  const intl = useIntl();
  const invoiceTypeText = intl.formatMessage({ id: 'INVOICE_TYPE' }, { invoiceType });
  const invoiceName = `${invoiceNumber} (${invoiceTypeText})`;
  const filename = `${invoiceNumber}_${invoiceTypeText.toLowerCase()}.pdf`;

  const handleInvoicePress = useCallback(() => {
    void onPressInvoice({
      invoiceId,
      filename,
      title: invoiceName,
    });
  }, [invoiceId, invoiceName, filename, onPressInvoice]);

  return (
    <ListItemAction actionLabel=" " onPress={handleInvoicePress}>
      {invoiceName}
    </ListItemAction>
  );
}
