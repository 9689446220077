import { View, StyleSheet } from 'react-native';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import { Text } from '../../../Component';

import { CareshipColors } from '../../../Component/CSTheme';

const styles = StyleSheet.create({
  row: {
    height: 82,
    alignItems: 'center',
    justifyContent: 'center',
    borderBottomColor: CareshipColors.slate200,
    borderBottomWidth: 1,
    marginLeft: 16,
    paddingRight: 16,
  },
});

export default function BookingItemErrorFallback() {
  return (
    <View style={styles.row}>
      <Text size="small" bold uppercase muted>
        <FormattedMessage id="ERROR_LOADING_DETAILS" />
      </Text>
    </View>
  );
}
