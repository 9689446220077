import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';
import { CareshipColors } from '../CSTheme';

interface ArrowRightIconProps extends SvgProps {
  color?: string;
  size?: number;
}

function ArrowRightIcon({
  color = CareshipColors.slate400,
  size = 24,
  ...props
}: ArrowRightIconProps) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" {...props}>
      <Path
        fill={color}
        d="M14.7 5.4l6.1 6.1c.3.3.3.7.1 1l-.1.1-6 6c-.3.3-.8.3-1.1 0-.3-.3-.3-.7-.1-1l.1-.1 4.7-4.7H3.8c-.5 0-.8-.4-.8-.8s.3-.7.6-.7H18.4l-4.7-4.7c-.3-.3-.3-.7-.1-1l.1-.1c.3-.3.7-.3 1-.1z"
      />
    </Svg>
  );
}

export default ArrowRightIcon;
