import React, { cloneElement, isValidElement, ReactElement } from 'react';
import { StyleProp, StyleSheet, View, ViewProps } from 'react-native';
import { Feather } from '@expo/vector-icons';
import CSTheme, { CareshipColors } from './CSTheme';
import Title from './Title';

const styles = StyleSheet.create({
  base: {
    borderRadius: CSTheme.roundness,
    borderWidth: 1,
    paddingVertical: 16,
    paddingHorizontal: 16,
    marginBottom: 16,
  },
  error: {
    backgroundColor: CareshipColors.error,
    borderColor: CareshipColors.red,
  },
  info: {
    backgroundColor: CareshipColors.slate050,
    borderWidth: 0,
  },
  title: {
    marginBottom: 8,
  },
  withIcon: {
    marginRight: 16,
    paddingRight: 24,
  },
  icon: {
    position: 'absolute',
    top: '50%',
    right: -16,
    marginTop: -4,
    backgroundColor: CareshipColors.green,
    borderRadius: 16,
    overflow: 'hidden',
    padding: 4,
  },
});
const textStyles = StyleSheet.create({
  error: {
    color: CareshipColors.red,
  },
  info: {
    color: CareshipColors.slate400,
  },
});

type AlertChild = ReactElement<{ style?: StyleProp<any> }>;
type AlertProps = Omit<ViewProps, 'children'> & {
  type?: 'error' | 'info';
  children: AlertChild | AlertChild[];
  icon?: boolean;
  title?: ReactElement<{ style?: any; size?: string }> | string;
};

export default function Alert({
  children,
  style,
  type = 'info',
  title,
  icon,
  ...props
}: AlertProps) {
  function withStyles(child: AlertChild, i?: number) {
    return isValidElement(child)
      ? cloneElement(child, {
          style: [textStyles[type], child.props.style],
          key: i,
        })
      : children;
  }

  return (
    <View style={[styles.base, styles[type], icon && styles.withIcon, style]} {...props}>
      {Boolean(title) &&
        (isValidElement(title) ? (
          cloneElement(title, {
            style: [styles.title, textStyles[type], title.props.style],
            size: title.props.size || 'h3',
          })
        ) : (
          <Title style={styles.title} size="h3">
            {title}
          </Title>
        ))}
      {Array.isArray(children) ? children.map(withStyles) : withStyles(children)}
      {icon && type === 'info' && (
        <View style={styles.icon}>
          <Feather name="check" size={24} color="white" />
        </View>
      )}
    </View>
  );
}
