import React, { ReactElement } from 'react';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { Feather } from '@expo/vector-icons';
import ModalHeader from '../../Component/ModalHeader';
import { intl } from '../../../Infrastructure/Intl';
import { CareshipColors } from '../../Component/CSTheme';
import { Layout } from '../../Component';
import { AppNavigationParamList, AppStackNavigationProp } from '../../Root/Navigator/AppNavigator';
import ProposalDeclineForm, { ProposalDeclineFormData } from '../Component/ProposalDeclineForm';
import { useProposalDeclineReasons, useProposalService } from '../../../Context/Proposal/Hook';
import KeyboardAvoidingSafeScrollView from '../../Component/KeyboardAvoidingSafeScrollView';
import { useShowAlert } from '../../Dialog';

export default function ProposalDeclineScreen(): ReactElement {
  const navigation = useNavigation<AppStackNavigationProp<'App'>>();
  const route = useRoute<RouteProp<AppNavigationParamList, 'DeclineProposal'>>();
  const proposalService = useProposalService();
  const declineReasons = useProposalDeclineReasons();
  const showAlert = useShowAlert();

  const handleSubmit = async (data: ProposalDeclineFormData) => {
    try {
      await proposalService.declineProposal(route.params.serviceRequestId, data);
      navigation.navigate('App', {
        screen: 'CustomerTab',
        params: {
          screen: 'CustomerDashboard',
        },
      });
    } catch (e) {
      showAlert(String(e));
    }
  };

  return (
    <Layout>
      <ModalHeader
        backButton={<Feather name="x" size={24} color={CareshipColors.primary} />}
        title={intl.formatMessage({ id: 'PROPOSAL_DECLINE_MODAL_TITLE' })}
      />
      <KeyboardAvoidingSafeScrollView>
        <ProposalDeclineForm
          onSubmit={handleSubmit}
          onCancel={() => navigation.goBack()}
          reasons={declineReasons}
        />
      </KeyboardAvoidingSafeScrollView>
    </Layout>
  );
}
