import { createContext } from 'react';
import AuthorizedClient from './AuthorizedClient';
import OauthService from './OauthService';

export type ServicesType = {
  authorizedClient: AuthorizedClient;
  oauthService: OauthService;
};

const serviceContext = createContext<ServicesType | null>(null);

export default serviceContext;
