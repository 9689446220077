/**
 * @generated SignedSource<<ef1a06bfca2024aa3f7d8cbb3228fa25>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditFinancialDetailsFormFragment$data = {
  readonly id: string;
  readonly bank: {
    readonly bic: string;
    readonly iban: string;
  };
  readonly tax: {
    readonly taxNo: string;
    readonly ustId: string;
  };
  readonly " $fragmentType": "EditFinancialDetailsFormFragment";
};
export type EditFinancialDetailsFormFragment$key = {
  readonly " $data"?: EditFinancialDetailsFormFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"EditFinancialDetailsFormFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditFinancialDetailsFormFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CaregiverBankAccount",
      "kind": "LinkedField",
      "name": "bank",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "bic",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "iban",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CaregiverTax",
      "kind": "LinkedField",
      "name": "tax",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "taxNo",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "ustId",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Caregiver",
  "abstractKey": null
};

(node as any).hash = "883b9831afa9a81df9cac669b33d5aac";

export default node;
