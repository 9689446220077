/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import Constants from 'expo-constants';
import { Platform } from 'react-native';
import useAuthorizedClient from '../../../Infrastructure/Hook/useAuthorizedClient';
import { AuthorizedClient, isSuccess } from '../../../Infrastructure/Service';
import Manifest from '../../../Infrastructure/Constants/Manifest';

const apiBackendUrl = Constants?.expoConfig?.extra?.apiBackendUrl;

const apiURI: string = Platform.OS === 'web' ? Manifest.extra.apiEndpoint : apiBackendUrl;

export async function retrieveServiceDocumentationDownloadUrl(
  authorizedClient: AuthorizedClient,
  serviceDocumentationId: string,
): Promise<string | undefined> {
  const resp = await authorizedClient.request<{ token_id: string }>(
    `/caregiver-app/service-documentation/${serviceDocumentationId}/request-token`,
    {
      method: 'POST',
    },
  );

  if (isSuccess(resp)) {
    return `${apiURI}/caregiver-app/service-documentation/download/${resp.data.token_id}`;
  }

  return undefined;
}

export default function useServiceDocumentationDownloadUrl(serviceDocumentationId: string) {
  const authorizedClient = useAuthorizedClient();

  return {
    generateUrl: async () => {
      if (!serviceDocumentationId) {
        return undefined;
      }

      const url = await retrieveServiceDocumentationDownloadUrl(
        authorizedClient,
        serviceDocumentationId,
      );

      return url;
    },
  };
}
