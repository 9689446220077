import { StyleSheet } from 'react-native';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { isPast } from 'date-fns';
import { graphql, useFragment } from 'react-relay/hooks';
import { useNavigation } from '@react-navigation/native';
import { ClipboardListIcon } from '../../../Component/Icon';
import { NavFlatButton } from '../../../Component';
import { FirstAppointmentChecklistButton_contract$key } from './__generated__/FirstAppointmentChecklistButton_contract.graphql';
import { events, trackEvent } from '../../../../Infrastructure/Tracking/Tracking';
import { CustomerStackNavigationProp } from '../../Navigator/CustomerStackNavigationProp';

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 16,
    marginTop: 32,
  },
});

interface CallChecklistButtonProps {
  contractFragment: FirstAppointmentChecklistButton_contract$key;
}

export default function FirstAppointmentChecklistButton({
  contractFragment,
}: CallChecklistButtonProps) {
  const navigation = useNavigation<CustomerStackNavigationProp<'CustomerDetailView'>>();
  const { contractId, hasFulfilledBooking, firstBookingStatus, firstBooking } = useFragment(
    graphql`
      fragment FirstAppointmentChecklistButton_contract on Contract {
        contractId
        firstBookingStatus
        hasFulfilledBooking
        firstBooking {
          endDate
        }
      }
    `,
    contractFragment,
  );

  const handlePressFirstAppointmentChecklist = useCallback(() => {
    navigation.navigate('FirstAppointmentChecklist', {
      contractId,
    });

    trackEvent(events.FIRST_APPOINTMENT_CHECKLIST_CLICK);
  }, [navigation, contractId]);

  if (
    hasFulfilledBooking ||
    firstBookingStatus !== 'confirmed' ||
    !firstBooking?.endDate ||
    isPast(new Date(firstBooking.endDate))
  ) {
    return null;
  }

  return (
    <NavFlatButton
      icon={<ClipboardListIcon size={22} />}
      onPress={handlePressFirstAppointmentChecklist}
      style={styles.container}
    >
      <FormattedMessage id="FIRST_APPOINTMENT_CHECKLIST" />
    </NavFlatButton>
  );
}
