import { NavigationContainer } from '@react-navigation/native';
import React from 'react';
import { useNavigationContainerRef, useScreenTracking } from '../../../Infrastructure/Hook';
import useDocumentTitleFormatter from '../Navigator/useDocumentTitleFormatter';
import linking from '../Navigator/linking/linking';
import { CSTheme, Loading } from '../../Component';
import { CareshipColors } from '../../Component/CSTheme';
import NavigationContainerRefProvider from '../../../Infrastructure/Navigation/NavigationContainerRefProvider';
import AppNavigation from './AppNavigation';

const theme = {
  dark: false,
  colors: {
    primary: CSTheme.colors.primary,
    background: CSTheme.colors.background,
    card: CSTheme.colors.background,
    text: CSTheme.colors.text,
    border: CareshipColors.slate200,
    notification: CSTheme.colors.accent,
  },
};

export default function AppNavigationWithContainerAndProvider() {
  const { navigationContainerRef, navigationContainerCallbackRef } = useNavigationContainerRef();
  const formatter = useDocumentTitleFormatter();
  const handleStateChange = useScreenTracking();

  return (
    <NavigationContainer
      ref={navigationContainerCallbackRef}
      linking={linking}
      documentTitle={{
        formatter,
      }}
      fallback={<Loading />}
      theme={theme}
      onStateChange={handleStateChange}
    >
      <NavigationContainerRefProvider navigationContainerRef={navigationContainerRef}>
        <AppNavigation />
      </NavigationContainerRefProvider>
    </NavigationContainer>
  );
}
