/**
 * @generated SignedSource<<85109ac6567bd4b7649ba8aa0a39ab9f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ProposalStatusEnum = "OPEN" | "DECLINED" | "ACCEPTED" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ProposalListItem_proposal$data = {
  readonly serviceRequestId: string;
  readonly status: ProposalStatusEnum;
  readonly careReceiver: {
    readonly gender: string;
    readonly name: string;
    readonly address: {
      readonly street: string;
      readonly postalCode: string;
      readonly city: string;
    };
  };
  readonly " $fragmentType": "ProposalListItem_proposal";
};
export type ProposalListItem_proposal$key = {
  readonly " $data"?: ProposalListItem_proposal$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProposalListItem_proposal">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProposalListItem_proposal",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "serviceRequestId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProposalCareReceiver",
      "kind": "LinkedField",
      "name": "careReceiver",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "gender",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ProposalAddress",
          "kind": "LinkedField",
          "name": "address",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "street",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "postalCode",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "city",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Proposal",
  "abstractKey": null
};

(node as any).hash = "c40352210495f6480b6cd97e2ca08871";

export default node;
