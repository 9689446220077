import { StyleSheet, View } from 'react-native';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import { Formik, Field, FieldProps, ErrorMessage } from 'formik';
import { TextInput } from 'react-native-paper';
import { Button, Container, InputErrorMessage, CheckboxItem, Text } from '../../Component';
import { intl } from '../../../Infrastructure/Intl';
import { CareshipColors } from '../../Component/CSTheme';
import { events, trackEvent } from '../../../Infrastructure/Tracking/Tracking';

const styles = StyleSheet.create({
  submitButton: { flex: 1, marginLeft: 8 },
  cancelButton: { flex: 1, marginRight: 8 },
  group: {
    marginTop: 24,
    marginBottom: 8,
  },
  careshipOnlyHint: {
    marginVertical: 8,
  },
  buttonRow: {
    flexDirection: 'row',
  },
});

export type ProposalDeclineFormData = {
  reasons: string[];
  details: string;
};

type Props = {
  onSubmit: (values: ProposalDeclineFormData) => Promise<void>;
  onCancel: () => void;
  reasons: string[];
};

function validate(values: ProposalDeclineFormData) {
  const errors: { [key in keyof Partial<ProposalDeclineFormData>]: string } = {};

  if (values.reasons.length < 1) {
    errors.reasons = intl.formatMessage({ id: 'VALIDATION_PROPOSAL_MIN_DECLINE_REASONS' });
  }

  // Must have at least one service
  if (values.reasons.includes('other') && !values.details) {
    errors.details = intl.formatMessage({ id: 'VALIDATION_PROPOSAL_OTHER_REASON' });
  }

  return errors;
}

export default function ProposalDeclineForm({ onCancel, onSubmit, reasons: rawReasons }: Props) {
  const reasons = rawReasons.sort((a) => (a === 'other' ? 1 : -1));

  return (
    <Formik
      validate={validate}
      initialValues={{ reasons: [], details: '' } as ProposalDeclineFormData}
      onSubmit={(values) => {
        void onSubmit(values);

        trackEvent(events.PROPOSAL_DECLINE_FINALIZE_CLICK);
      }}
      onReset={onCancel}
    >
      {({ setFieldValue, handleReset, handleSubmit, errors, isSubmitting }) => (
        <>
          <View style={[styles.group]}>
            {reasons.map((reason) => (
              <Field key={reason} name="reasons">
                {({ field, form }: FieldProps<string[]>) => {
                  const isChecked = field.value.includes(reason);
                  const isOther = reason === 'other';
                  const nextReasons = isChecked
                    ? field.value.filter((r: string) => r !== reason)
                    : field.value.concat(reason);
                  const label = intl.formatMessage(
                    { id: 'DEFINITION_PROPOSAL_DECLINE_REASON' },
                    { reason },
                  );
                  const status = isChecked ? 'checked' : 'unchecked';
                  const handlePress = () => setFieldValue('reasons', nextReasons);

                  return (
                    <>
                      <CheckboxItem
                        onPress={handlePress}
                        style={[isOther && { borderBottomWidth: 0 }]}
                        status={status}
                        label={label}
                      />
                      {isOther && isChecked && (
                        <Field name="details">
                          {({ field: detailField }: FieldProps<string>) => (
                            <View
                              style={{
                                borderTopColor: 'rgba(255,179,0,0.15)',
                                borderTopWidth: 1,
                              }}
                            >
                              <TextInput
                                accessibilityLabel="Text input field"
                                accessibilityHint={intl.formatMessage({
                                  id: 'PROPOSAL_DECLINE_DESCRIPTION_LABEL',
                                })}
                                textAlign="left"
                                multiline
                                style={{
                                  marginTop: -2,
                                  paddingHorizontal: 16,
                                  paddingBottom: 8,
                                  borderRadius: 0,
                                  backgroundColor: 'rgba(255,179,0,0.1)',
                                }}
                                underlineColor="rgba(255,179,0,0.15)"
                                label={intl.formatMessage({
                                  id: 'PROPOSAL_DECLINE_DESCRIPTION_LABEL',
                                })}
                                onBlur={() => form.setFieldTouched('details')}
                                theme={{ roundness: 0 }}
                                maxLength={100}
                                onChangeText={(text) => form.setFieldValue('details', text)}
                                value={detailField.value}
                              />
                            </View>
                          )}
                        </Field>
                      )}
                    </>
                  );
                }}
              </Field>
            ))}
            {errors && (
              <InputErrorMessage>
                <ErrorMessage name="reasons" />
                <ErrorMessage name="details" />
              </InputErrorMessage>
            )}
          </View>

          <Container>
            <Text style={styles.careshipOnlyHint} size="large" center muted>
              <FormattedMessage id="ONLY_VISIBLE_TO_CARESHIP" />
            </Text>

            <View style={styles.buttonRow}>
              <Button
                style={styles.cancelButton}
                color={CareshipColors.slate100}
                dark={false}
                disabled={isSubmitting}
                onPress={handleReset}
              >
                <FormattedMessage id="BUTTON_CANCEL" />
              </Button>

              <Button
                style={styles.submitButton}
                color={CareshipColors.red}
                loading={isSubmitting}
                disabled={isSubmitting}
                onPress={handleSubmit}
              >
                <FormattedMessage id="PROPOSAL_DECLINE_BUTTON" />
              </Button>
            </View>
          </Container>
        </>
      )}
    </Formik>
  );
}
