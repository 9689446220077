import * as SecureStore from 'expo-secure-store';
import { isPast } from 'date-fns';
import { Platform } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';

import { TokenResponseData } from '../Model/TokenResponseData';

enum TokenKeys {
  ACCESS_TOKEN = 'access_token',
  REFRESH_TOKEN = 'refresh_token',
  EXPIRES_AT = 'expires_at',
  TOKEN_TYPE = 'token_type',
}

export default class OAuthTokenStore {
  constructor(private namespace: string) {}

  private static getItemAsync(key: string): Promise<string | null> {
    if (Platform.OS === 'web') {
      return AsyncStorage.getItem(key);
    }

    return SecureStore.getItemAsync(key);
  }

  private static setItemAsync(key: string, value: string): Promise<void> {
    if (Platform.OS === 'web') {
      return AsyncStorage.setItem(key, value);
    }

    return SecureStore.setItemAsync(key, value);
  }

  private static deleteItemAsync(key: string): Promise<void> {
    if (Platform.OS === 'web') {
      return AsyncStorage.removeItem(key);
    }

    return SecureStore.deleteItemAsync(key);
  }

  private getStorageKey(key: string): string {
    return `${this.namespace}--${key}`;
  }

  getAccessToken(): Promise<string | null> {
    return OAuthTokenStore.getItemAsync(this.getStorageKey(TokenKeys.ACCESS_TOKEN));
  }

  getAccessTokenExpiresAt(): Promise<string | null> {
    return OAuthTokenStore.getItemAsync(this.getStorageKey(TokenKeys.EXPIRES_AT));
  }

  async isTokenExpired(): Promise<boolean> {
    const expiresAt = await this.getAccessTokenExpiresAt();

    return !expiresAt || isPast(new Date(expiresAt));
  }

  getRefreshToken(): Promise<string | null> {
    return OAuthTokenStore.getItemAsync(this.getStorageKey(TokenKeys.REFRESH_TOKEN));
  }

  async isLoggedIn() {
    return Boolean((await this.getRefreshToken()) && (await this.getAccessToken()));
  }

  async handleTokenData(jsonResponse: TokenResponseData): Promise<void> {
    const expiresAt = new Date(new Date().getTime() + jsonResponse.expires_in * 1000);

    await Promise.all([
      OAuthTokenStore.setItemAsync(
        this.getStorageKey(TokenKeys.ACCESS_TOKEN),
        jsonResponse.access_token,
      ),
      jsonResponse.refresh_token
        ? OAuthTokenStore.setItemAsync(
            this.getStorageKey(TokenKeys.REFRESH_TOKEN),
            jsonResponse.refresh_token,
          )
        : Promise.resolve(),
      OAuthTokenStore.setItemAsync(
        this.getStorageKey(TokenKeys.EXPIRES_AT),
        expiresAt.toISOString(),
      ),
      OAuthTokenStore.setItemAsync(
        this.getStorageKey(TokenKeys.TOKEN_TYPE),
        jsonResponse.token_type,
      ),
    ]);
  }

  async clearTokenData(): Promise<void> {
    await Promise.all([
      OAuthTokenStore.deleteItemAsync(this.getStorageKey(TokenKeys.ACCESS_TOKEN)),
      OAuthTokenStore.deleteItemAsync(this.getStorageKey(TokenKeys.REFRESH_TOKEN)),
      OAuthTokenStore.deleteItemAsync(this.getStorageKey(TokenKeys.EXPIRES_AT)),
      OAuthTokenStore.deleteItemAsync(this.getStorageKey(TokenKeys.TOKEN_TYPE)),
    ]);
  }
}

export const userTokenStore = new OAuthTokenStore('caregiver');
export const definitionsTokenStore = new OAuthTokenStore('definitions');
