import { action, computed, makeObservable, observable } from 'mobx';
import AbstractStore from '../../../Infrastructure/Store/AbstractStore';
import Name from './Name';
import Profile, { ProfileObject } from './Profile';
import ProfileImage from './ProfileImage';

export type CaregiverProfileResponse = {
  readonly caregiver_id: string;
  readonly name: {
    readonly first_name: string;
    readonly last_name: string;
  };
  readonly profile_image_hash: string;
  readonly is_careship_employee: boolean;
};

export default class ProfileStore extends AbstractStore {
  constructor() {
    super();
    makeObservable(this, {
      profile: observable,
      viewData: computed,
      hydrate: action,
      reset: action,
      handleProfileResponse: action,
    });
  }

  public profile: Profile | null = null;

  public get viewData() {
    return this.profile?.viewData || null;
  }

  hydrate = (data?: { profile: ProfileObject }) => {
    if (!data?.profile) {
      return;
    }

    this.profile = new Profile(
      data.profile.caregiverId,
      new Name(data.profile.name.firstName, data.profile.name.lastName),
      new ProfileImage(data.profile.profileImage.imageHash),
      data.profile.isCareshipEmployee,
    );
  };

  reset = () => {
    this.profile = null;
  };

  handleProfileResponse(response: CaregiverProfileResponse) {
    this.profile = new Profile(
      response.caregiver_id,
      new Name(response.name.first_name, response.name.last_name),
      new ProfileImage(response.profile_image_hash),
      response.is_careship_employee,
    );
  }
}
