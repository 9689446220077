/**
 * @generated SignedSource<<a9932f60e7f164fa0d0a4ccff9be804d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AdditionalContactDetails_contract$data = {
  readonly customer: {
    readonly phoneNumber: string;
    readonly email: string;
    readonly name: {
      readonly firstName: string;
      readonly lastName: string;
    };
  };
  readonly " $fragmentType": "AdditionalContactDetails_contract";
};
export type AdditionalContactDetails_contract$key = {
  readonly " $data"?: AdditionalContactDetails_contract$data;
  readonly " $fragmentSpreads": FragmentRefs<"AdditionalContactDetails_contract">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AdditionalContactDetails_contract",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Customer",
      "kind": "LinkedField",
      "name": "customer",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "phoneNumber",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "email",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Name",
          "kind": "LinkedField",
          "name": "name",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "firstName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lastName",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Contract",
  "abstractKey": null
};

(node as any).hash = "90c3430479a9c4d2ddef3a7a428bc3d9";

export default node;
