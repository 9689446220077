import React, { ComponentProps, FC } from 'react';
import { Text as BaseText } from 'react-native-paper';
import { StyleSheet } from 'react-native';
import styled from '@emotion/native';
import { SpacedBoxProps } from '@emotion/react';
import CSTheme, { CareshipColors } from './CSTheme';
import withSpacing, { shouldForwardProp } from '../Root/Theme/withSpacing';

const styles = StyleSheet.create({
  xlarge: {
    fontSize: 20,
    lineHeight: 24,
  },
  large: {
    fontSize: 16,
    lineHeight: 22,
  },
  medium: {
    fontSize: 14,
    lineHeight: 18,
  },
  small: {
    fontSize: 12,
    lineHeight: 14,
  },
  muted: {
    color: CareshipColors.slate300,
  },
  uppercase: {
    textTransform: 'uppercase',
    letterSpacing: 0.3,
  },
  bold: {
    ...CSTheme.fonts.medium,
  },
  center: {
    textAlign: 'center',
    width: '100%',
  },
});

type TextSizes = 'large' | 'medium' | 'small' | 'xlarge';

type TextProps = ComponentProps<typeof BaseText> & {
  size?: TextSizes;
  bold?: boolean;
  center?: boolean;
  uppercase?: boolean;
  muted?: boolean;
};

const Text: FC<TextProps> = ({
  children,
  bold,
  muted,
  center,
  uppercase,
  size = 'medium',
  style,
  ...rest
}) => (
  <BaseText
    style={[
      styles[size],
      muted && styles.muted,
      uppercase && styles.uppercase,
      bold && styles.bold,
      center && styles.center,
      style,
    ]}
    {...rest}
  >
    {children}
  </BaseText>
);

export default styled(Text, {
  shouldForwardProp,
})<TextProps & SpacedBoxProps>(withSpacing());
