import { Formik, Field, FieldProps, FormikHelpers } from 'formik';
import { View, StyleSheet, Platform } from 'react-native';
import React, { useCallback } from 'react';
import { TextInput } from 'react-native-paper';
import { FormattedMessage, useIntl } from 'react-intl';
import { useFragment } from 'react-relay/hooks';
import { graphql } from 'react-relay';
import {
  RadioButtonItem,
  RadioButtonGroup,
  Button,
  Text,
  Container,
  InputErrorMessage,
  Title,
} from '../../Component';
import { CareshipColors } from '../../Component/CSTheme';
import { trackEvent, events } from '../../../Infrastructure/Tracking/Tracking';
import { FirstBookingDeclineForm_contract$key } from './__generated__/FirstBookingDeclineForm_contract.graphql';
import ErrorFullscreen from '../../Component/ErrorFullscreen';

const styles = StyleSheet.create({
  title: {
    color: CareshipColors.red,
    textDecorationLine: 'line-through',
    marginTop: Platform.OS === 'android' ? 32 : undefined,
  },
  formTitle: {
    marginTop: 32,
    marginBottom: 24,
  },
  hint: {
    marginTop: 32,
    marginBottom: 16,
  },
  hintWarning: {
    color: CareshipColors.red,
  },
  hintInfo: {
    color: CareshipColors.blue,
  },
  submitButton: {
    marginBottom: 16,
  },
});

export type FirstBookingDeclineFormPayload = {
  reason: string;
  description: string;
  bookingId: string;
};

type FirstBookingDeclineFormProps = {
  contractFragment: FirstBookingDeclineForm_contract$key;
  firstBookingDeclineReasons: string[];
  handleFirstBookingDecline: (payload: FirstBookingDeclineFormPayload) => Promise<string | void>;
};

export default function FirstBookingDeclineForm({
  contractFragment,
  firstBookingDeclineReasons,
  handleFirstBookingDecline,
}: FirstBookingDeclineFormProps) {
  const intl = useIntl();
  const { firstBooking, careReceiver } = useFragment(
    graphql`
      fragment FirstBookingDeclineForm_contract on Contract {
        careReceiver {
          name {
            firstName
            lastName
          }
        }
        firstBookingStatus
        firstBookingConfirmationStatus {
          reason
        }
        firstBooking(isFirstBooking: true) {
          bookingId
        }
      }
    `,
    contractFragment,
  );

  const validate = useCallback(
    ({ reason, description }: FirstBookingDeclineFormPayload) => {
      const errors: { [key in keyof Partial<FirstBookingDeclineFormPayload>]: string } = {};

      if (!reason.length) {
        errors.reason = intl.formatMessage({
          id: 'VALIDATION_FIRST_BOOKING_MIN_DECLINE_REASONS',
        });
      }

      if (!description.trim().length) {
        errors.description = intl.formatMessage({
          id: 'VALIDATION_FIRST_BOOKING_REQUIRED_DESCRIPTION',
        });
      }

      return errors;
    },
    [intl],
  );

  const handleFormSubmit = useCallback(
    async (
      data: FirstBookingDeclineFormPayload,
      helpers: FormikHelpers<FirstBookingDeclineFormPayload>,
    ) => {
      const errorCode = await handleFirstBookingDecline(data);

      if (errorCode) {
        helpers.setStatus({
          error: intl.formatMessage({ id: 'ERROR_RESPONSE' }, { errorCode }),
        });
      }

      trackEvent(events.FIRST_BOOKING_DECLINE_SUBMIT_CLICK);
    },
    [handleFirstBookingDecline, intl],
  );

  if (!firstBooking) {
    return <ErrorFullscreen />;
  }

  return (
    <>
      <Container>
        <Title thin style={styles.title} center>
          <FormattedMessage
            id="NAME_FULL"
            values={{
              firstName: careReceiver.name.firstName,
              lastName: careReceiver.name.lastName,
            }}
          />
        </Title>
        <Text bold size="xlarge" style={styles.formTitle}>
          <FormattedMessage id="FIRST_APPOINTMENT_DECLINE_FORM_TITLE" />
        </Text>
      </Container>
      <Formik
        validate={validate}
        initialValues={
          {
            reason: '',
            description: '',
            bookingId: firstBooking.bookingId,
          } as FirstBookingDeclineFormPayload
        }
        onSubmit={handleFormSubmit}
      >
        {({ setFieldValue, status, handleSubmit, isSubmitting, dirty, isValid }) => (
          <View>
            <Field name="reason">
              {({ field }: FieldProps<FirstBookingDeclineFormPayload['reason']>) => (
                <RadioButtonGroup
                  value={field.value}
                  onValueChange={(firstBookingDeclineReason) =>
                    setFieldValue('reason', firstBookingDeclineReason)
                  }
                >
                  {firstBookingDeclineReasons.map((firstBookingDeclineReason) => (
                    <RadioButtonItem
                      key={firstBookingDeclineReason}
                      label={intl.formatMessage(
                        {
                          id: 'FIRST_APPOINTMENT_DECLINE_FORM_REASONS',
                        },
                        { reason: firstBookingDeclineReason },
                      )}
                      value={firstBookingDeclineReason}
                    />
                  ))}
                </RadioButtonGroup>
              )}
            </Field>
            <Field name="description">
              {({ field }: FieldProps<FirstBookingDeclineFormPayload['description']>) => (
                <TextInput
                  accessibilityLabel="Text input field"
                  accessibilityHint={intl.formatMessage({
                    id: 'FIRST_APPOINTMENT_DECLINE_FORM_DESCRIPTION_TITLE',
                  })}
                  textAlign="left"
                  multiline
                  style={{
                    marginTop: -1,
                    paddingHorizontal: 16,
                    paddingBottom: 8,
                    backgroundColor: 'rgba(255,179,0,0.1)',
                  }}
                  underlineColor="rgba(255,179,0,0.15)"
                  label={intl
                    .formatMessage({
                      id: 'FIRST_APPOINTMENT_DECLINE_FORM_DESCRIPTION_TITLE',
                    })
                    .toUpperCase()}
                  placeholder={intl.formatMessage({
                    id: 'FIRST_APPOINTMENT_DECLINE_FORM_DESCRIPTION_PLACEHOLDER',
                  })}
                  theme={{ roundness: 0 }}
                  maxLength={100}
                  onChangeText={(description) => setFieldValue('description', description)}
                  value={field.value}
                />
              )}
            </Field>
            {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access */}
            {status?.error && <InputErrorMessage>{status?.error}</InputErrorMessage>}
            <Container>
              <View style={styles.hint}>
                <Text center size="large" style={styles.hintWarning}>
                  <FormattedMessage id="FIRST_APPOINTMENT_DECLINE_FORM_HINT_WARNING" />
                </Text>
              </View>
              <Button
                style={styles.submitButton}
                color={CareshipColors.red}
                loading={isSubmitting}
                disabled={isSubmitting || !dirty || !isValid}
                onPress={handleSubmit}
              >
                <FormattedMessage id="PROPOSAL_DECLINE_BUTTON" />
              </Button>
            </Container>
          </View>
        )}
      </Formik>
    </>
  );
}
