import { useRef } from 'react';
import useAuthorizedClient from '../../Hook/useAuthorizedClient';
import { useStores } from '../../Store';
import PushTokenService from '../Service/PushTokenService';

export default function usePushTokenService() {
  const stores = useStores();
  const authorizedClient = useAuthorizedClient();
  const { current: pushTokenService } = useRef(
    new PushTokenService(stores.sessionStore, authorizedClient),
  );

  return pushTokenService;
}
