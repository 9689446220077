import { graphql } from 'react-relay';

export default graphql`
  query fetchAllContractsQuery {
    allContracts {
      edges {
        node {
          id
          hasFulfilledBooking
          cancelationScheduledFor
          firstBookingStatus
          firstBookingConfirmationStatus {
            reason
          }
        }
      }
    }
  }
`;
