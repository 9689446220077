import { graphql } from 'react-relay';
import { PreloadedQuery, usePreloadedQuery } from 'react-relay/hooks';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from '@react-navigation/native';
import styled from '@emotion/native';
import { TouchableRipple } from 'react-native-paper';
import { Card, Title } from '../../../Component';
import { Edit2Icon } from '../../../Component/Icon';
import { AboutMeCardQuery } from './__generated__/AboutMeCardQuery.graphql';

export const aboutMeCardQuery = graphql`
  query AboutMeCardQuery {
    me {
      ... on Caregiver {
        aboutMe
        currentPosition
      }
    }
  }
`;

const TitleRow = styled.View({
  flexDirection: 'row',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  height: 28,
});

const IconTouchable = styled(TouchableRipple)({
  borderRadius: 16,
  width: 32,
  height: 32,
  transform: [{ translateX: 8 }, { translateY: -8 }],
  alignItems: 'center',
  justifyContent: 'center',
});

interface AboutMeCardProps {
  preloadedQuery: PreloadedQuery<AboutMeCardQuery>;
}

export default function AboutMeCard({ preloadedQuery }: AboutMeCardProps) {
  const {
    me: { aboutMe, currentPosition },
  } = usePreloadedQuery(aboutMeCardQuery, preloadedQuery);
  return (
    <Card topmost padding="base" marginVertical="base" marginHorizontal="base" mode="outlined">
      <TitleRow>
        <Title size="h5" muted uppercase>
          <FormattedMessage id="PROFILE_ABOUT_ME" />
        </Title>
        <Link to="/account/about-me/edit">
          <IconTouchable>
            <Edit2Icon />
          </IconTouchable>
        </Link>
      </TitleRow>
      <Title size="h4" thin>
        {currentPosition}
      </Title>
      <Title size="h4" thin>
        „{aboutMe}“
      </Title>
    </Card>
  );
}
