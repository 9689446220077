import React, { ReactNode } from 'react';
import { StyleProp, StyleSheet, ViewStyle } from 'react-native';
import TouchableRow from '../TouchableRow';

type Props = {
  children: ReactNode;
  onPress: () => void;
  style?: StyleProp<ViewStyle>;
  testID?: string;
};

const styles = StyleSheet.create({
  content: {
    marginLeft: -8,
  },
});

export default function TouchableFormRow({ children, onPress, style, testID }: Props) {
  return (
    <TouchableRow testID={testID} style={style} onPress={onPress} contentStyle={styles.content}>
      {children}
    </TouchableRow>
  );
}
