import { Switch } from 'react-native-paper';
import React, { useCallback } from 'react';
import styled, { css } from '@emotion/native';
import { graphql, useMutation, useRefetchableFragment } from 'react-relay/hooks';
import { FormattedMessage, useIntl } from 'react-intl';
import { SelectorStoreUpdater } from 'relay-runtime';
import { CareshipColors } from '../../Component/CSTheme';
import { Loading, Text, Title } from '../../Component';
import {
  BookedOutMutation,
  BookedOutMutation$data,
} from './__generated__/BookedOutMutation.graphql';
import useLogger from '../../../Infrastructure/Hook/useLogger';
import { BookedOutFragment$key } from './__generated__/BookedOutFragment.graphql';
import { useShowAlert } from '../../Dialog';

const textWrap = css({ flexWrap: 'wrap', flexShrink: 1, marginTop: 8 });

const Card = styled.View(({ theme: { breakpoints } }) => [
  {
    position: 'relative',
    borderColor: CareshipColors.slate200,
    borderRadius: 16,
    borderStyle: 'solid',
    borderWidth: 1,
    marginTop: 24,
    marginHorizontal: 16,
    padding: 16,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  breakpoints.up('md', {
    padding: 24,
  }),
]);

const TextWrapper = styled.View({
  flexWrap: 'wrap',
  alignItems: 'flex-start',
  flexShrink: 1,
  marginRight: 8,
});

const LoaderOverlay = styled.View({
  position: 'absolute',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  borderRadius: 16,
  backgroundColor: 'rgba(200, 200, 200, 0.4)',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 2,
});

const updater =
  (id: string): SelectorStoreUpdater<BookedOutMutation$data> =>
  (store, data) => {
    const meRecord = store.get(id);
    meRecord?.setValue(Boolean(data.setCaregiverBookedOut?.bookedOut), 'bookedOut');
  };

export default function BookedOut({ fragmentRef }: { fragmentRef: BookedOutFragment$key }) {
  const logger = useLogger();
  const intl = useIntl();
  const showAlert = useShowAlert();

  const [{ bookedOut, id }, refetch] = useRefetchableFragment(
    graphql`
      fragment BookedOutFragment on Caregiver
      @refetchable(queryName: "bookedOutFragmentRefetchQuery") {
        id
        bookedOut
      }
    `,
    fragmentRef,
  );

  const [commitMutation, isInFlight] = useMutation<BookedOutMutation>(
    graphql`
      mutation BookedOutMutation($bookedOut: Boolean!) {
        setCaregiverBookedOut(bookedOut: $bookedOut) {
          bookedOut
        }
      }
    `,
  );

  const handleBookedOutChange = useCallback(
    (hasAvailability: boolean) => {
      commitMutation({
        variables: {
          bookedOut: !hasAvailability,
        },
        optimisticResponse: {
          setCaregiverBookedOut: {
            bookedOut: !hasAvailability,
          },
        },
        optimisticUpdater: updater(id), // id optional due to query on a union type
        onError: (error) => {
          refetch({});
          showAlert(intl.formatMessage({ id: 'ERROR_UNKNOWN' }));
          logger.error('setCaregiverBookedOut mutation failed', { relayError: error });
        },
        updater: updater(id),
      });
    },
    [commitMutation, id, intl, logger, refetch, showAlert],
  );

  return (
    <Card>
      {isInFlight && (
        <LoaderOverlay>
          <Loading />
        </LoaderOverlay>
      )}
      <TextWrapper>
        <Title uppercase size="h5" muted>
          <FormattedMessage id="AVAILABILITY_TITLE" />
        </Title>
        <Text muted style={textWrap} size="large">
          <FormattedMessage id="AVAILABILITY_DESCRIPTION" />
        </Text>
      </TextWrapper>
      <Switch
        testID="bookedOutSwitch"
        disabled={isInFlight}
        value={!bookedOut}
        onValueChange={handleBookedOutChange}
      />
    </Card>
  );
}
