import React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
import { CareshipColors } from '../CSTheme';

type MenuIconProps = SvgProps & {
  color?: string;
  size?: number;
};

export default function MenuIcon({
  color = CareshipColors.primary,
  size = 24,
  ...props
}: MenuIconProps) {
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      {...props}
      strokeWidth={2}
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    >
      <Path d="M3 12h18M3 6h18M3 18h18" />
    </Svg>
  );
}
