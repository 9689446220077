import { StyleSheet, Text } from 'react-native';
import React, { ReactNode } from 'react';
import CSTheme, { CareshipColors } from './CSTheme';

type TagProps = {
  children?: ReactNode;
  color?: 'error';
};

const styles = StyleSheet.create({
  error: {
    backgroundColor: CareshipColors.red,
  },
  tag: {
    backgroundColor: CareshipColors.slate400,
    borderRadius: 4,
    paddingVertical: 4,
    paddingHorizontal: 8,
    overflow: 'hidden',
    flex: 0,
    flexGrow: 1,
    color: CareshipColors.white,
    ...CSTheme.fonts.medium,
  },
});

export default function Tag({ children, color }: TagProps) {
  return <Text style={[styles.tag, color === 'error' && styles.error]}>{children}</Text>;
}
