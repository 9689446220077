import React from 'react';
import { ScrollView } from 'react-native';
import { graphql, useLazyLoadQuery } from 'react-relay/hooks';
import ReferralCode from '../Component/ReferralCode';
import { ReferralCodeScreenQuery } from './__generated__/ReferralCodeScreenQuery.graphql';
import { withLayout } from '../../HOC';

const referralCodeScreenQuery = graphql`
  query ReferralCodeScreenQuery {
    me {
      ... on Caregiver {
        referral {
          referralCode
          customerReferralAmount
          caregiverReferralAmount
        }
      }
    }
  }
`;

export default withLayout(function ReferralCodeScreen() {
  const {
    me: { referral },
  } = useLazyLoadQuery<ReferralCodeScreenQuery>(referralCodeScreenQuery, {});

  if (!referral?.referralCode) {
    return null;
  }

  return (
    <ScrollView>
      <ReferralCode
        customerReferralAmount={referral.customerReferralAmount}
        caregiverReferralAmount={referral.caregiverReferralAmount}
        referralCode={referral.referralCode}
      />
    </ScrollView>
  );
});
