import { useContext } from 'react';
import serviceContext from '../Service/serviceContext';

export default function useServices() {
  const services = useContext(serviceContext);

  if (!services) {
    // avoids having to null check when using the hook
    throw new Error('useServices must be used within a ServicesProvider.');
  }

  return services;
}
