/**
 * @generated SignedSource<<c6ce3a36b58adf0d1f3092f7ad056987>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProposalResponse_stats$data = {
  readonly averageMinutesToFirstCall: number | null;
  readonly previousAverageMinutesToFirstCall: number | null;
  readonly proposalResponseRate: number | null;
  readonly previousProposalResponseRate: number | null;
  readonly " $fragmentType": "ProposalResponse_stats";
};
export type ProposalResponse_stats$key = {
  readonly " $data"?: ProposalResponse_stats$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProposalResponse_stats">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Variable",
    "name": "startDate",
    "variableName": "splitDate"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "endDate",
    "variableName": "splitDate"
  },
  {
    "kind": "Variable",
    "name": "startDate",
    "variableName": "startDate"
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "splitDate"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "startDate"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProposalResponse_stats",
  "selections": [
    {
      "alias": null,
      "args": (v0/*: any*/),
      "kind": "ScalarField",
      "name": "averageMinutesToFirstCall",
      "storageKey": null
    },
    {
      "alias": "previousAverageMinutesToFirstCall",
      "args": (v1/*: any*/),
      "kind": "ScalarField",
      "name": "averageMinutesToFirstCall",
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v0/*: any*/),
      "kind": "ScalarField",
      "name": "proposalResponseRate",
      "storageKey": null
    },
    {
      "alias": "previousProposalResponseRate",
      "args": (v1/*: any*/),
      "kind": "ScalarField",
      "name": "proposalResponseRate",
      "storageKey": null
    }
  ],
  "type": "CaregiverStats",
  "abstractKey": null
};
})();

(node as any).hash = "08db5a6bd0ace550fc1880c91f6dd927";

export default node;
