import React from 'react';
import { StyleProp, TextStyle, ViewStyle, StyleSheet, View } from 'react-native';
import { FormattedMessage, FormattedDate } from 'react-intl';
import { useFragment } from 'react-relay/hooks';
import { graphql } from 'react-relay';
import { SectionListItem, Text } from '../../Component';
import Title from '../../Component/Title';
import { CareshipColors } from '../../Component/CSTheme';
import ContractCancelationIcon from '../../Component/Icon/ContractCancelationIcon';
import getLabel from '../../Booking/Component/getLabel';
import { ContractListItem_contract$key } from './__generated__/ContractListItem_contract.graphql';

type ContractListItemProps = {
  onPress: () => void;
  style?: StyleProp<ViewStyle>;
  addressStyle?: StyleProp<TextStyle>;
  contractFragment: ContractListItem_contract$key;
};

const styles = StyleSheet.create({
  sectionContent: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  hint: {
    display: 'flex',
    flexGrow: 1,
    backgroundColor: CareshipColors.slate050,
    padding: 12,
    marginRight: 16,
    marginTop: 12,
    flexDirection: 'row',
    alignItems: 'center',
  },
  hintText: {
    color: CareshipColors.slate300,
    fontSize: 16,
  },
  hintIcon: {
    marginRight: 8,
  },
});

export default function ContractListItem({
  onPress,
  addressStyle,
  style,
  contractFragment,
}: ContractListItemProps) {
  const {
    firstBooking,
    careReceiver,
    hasFulfilledBooking,
    cancelationScheduledFor: cancelationScheduledForStr,
    contractId,
  } = useFragment(
    graphql`
      fragment ContractListItem_contract on Contract {
        contractId
        careReceiver {
          name {
            firstName
            lastName
          }
          address {
            street
            houseNumber
            postalCode
            city
          }
        }
        cancelationScheduledFor
        hasFulfilledBooking
        firstBooking {
          endDate
          status
        }
      }
    `,
    contractFragment,
  );
  const cancelationScheduledFor = cancelationScheduledForStr
    ? new Date(cancelationScheduledForStr)
    : null;
  const { color, label } = getLabel(
    firstBooking
      ? {
          status: firstBooking.status,
          endDate: new Date(firstBooking.endDate),
          isFirstBooking: true,
        }
      : null,
  );
  const {
    name: { firstName, lastName },
    address,
  } = careReceiver;

  return (
    <SectionListItem
      title={
        <Title size="h3">
          <FormattedMessage id="NAME_FULL" values={{ firstName, lastName }} />{' '}
        </Title>
      }
      style={style}
      onPress={onPress}
      columnTitle={hasFulfilledBooking ? undefined : <FormattedMessage id="FIRST_APPOINTMENT" />}
      columnTitleColor={hasFulfilledBooking ? undefined : color}
      labelColor={hasFulfilledBooking ? undefined : color}
      label={hasFulfilledBooking ? undefined : label}
      testID={contractId}
    >
      <View style={styles.sectionContent}>
        <View>
          <Text size="large" style={addressStyle}>
            {address.street
              ? `${address.street} ${address.houseNumber}`
              : `${address.postalCode} ${address.city}`}
          </Text>
        </View>
        {cancelationScheduledFor && (
          <View style={styles.hint}>
            <ContractCancelationIcon style={styles.hintIcon} />
            <Text style={styles.hintText}>
              <FormattedMessage
                id="COLLABORATION_ENDS"
                values={{
                  endsAt: (
                    <FormattedDate
                      value={cancelationScheduledFor}
                      year="numeric"
                      month="2-digit"
                      day="2-digit"
                    />
                  ),
                }}
              />
            </Text>
          </View>
        )}
      </View>
    </SectionListItem>
  );
}
