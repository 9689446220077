import React, { ReactElement, useCallback } from 'react';
import { Platform } from 'react-native';
import { FormattedMessage, useIntl } from 'react-intl';
import * as t from 'io-ts';
import { useForm } from 'react-hook-form';
import { ioTsResolver } from '@hookform/resolvers/io-ts';
import { Link, RouteProp, useRoute } from '@react-navigation/native';
import styled from '@emotion/native';
import { Alert, Button, InputWithValidation, Text } from '../../Component';
import useSession from '../../../Infrastructure/Hook/useSession';
import { CareshipColors } from '../../Component/CSTheme';
import { nonEmptyString } from '../../../Infrastructure/Validation';
import { AppNavigationParamList } from '../../Root/Navigator/AppNavigator';

const Form = styled.View({
  width: '100%',
  maxWidth: 400,
});

const Input = styled(InputWithValidation)({
  height: 64,
  width: '100%',
  marginBottom: Platform.select({ web: 28 }),
}) as typeof InputWithValidation;

const ResetPasswordLink = styled(Text)({
  textAlign: 'center',
  marginTop: 8,
  padding: 16,
  width: '100%',
  marginBottom: 16,
  color: CareshipColors.slate300,
  fontSize: 14,
  textDecorationLine: 'none',
}).withComponent(Link);

const Wrapper = styled.View({
  flex: 1,
  backgroundColor: '#fff',
  alignItems: 'center',
  justifyContent: 'center',
  padding: 16,
});

const schema = t.type({
  username: nonEmptyString,
  password: nonEmptyString,
});

const Btn = styled(Button)({
  marginTop: Platform.select({ web: 8 }),
});

export default function LoginForm(): ReactElement {
  const intl = useIntl();
  const { params } = useRoute<RouteProp<AppNavigationParamList, 'Auth'>>();
  const [, { handleSubmit: submit, errorMessage }] = useSession();
  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = useForm<{ username: string; password: string }>({
    mode: 'onTouched',
    defaultValues: {
      username: '',
      password: '',
    },
    resolver: ioTsResolver(schema),
  });

  const handlePressSubmit = useCallback<() => void>(
    () => handleSubmit(submit)(),
    [handleSubmit, submit],
  );

  return (
    <Wrapper>
      <Form>
        {params?.isPasswordReset && (
          <Alert>
            <FormattedMessage id="PASSWORD_WAS_RESET" />
          </Alert>
        )}
        {errorMessage ? (
          <Alert type="error">
            <Text testID="loginErrorMessage">
              <FormattedMessage
                id={errorMessage?.toUpperCase()}
                defaultMessage={intl.formatMessage({ id: 'ERROR_UNKNOWN' })}
              />
            </Text>
          </Alert>
        ) : null}
        <Input
          control={control}
          name="username"
          label={intl.formatMessage({ id: 'EMAIL' })}
          autoComplete="username"
          keyboardType="email-address"
          autoCapitalize="none"
          accessibilityLabel="username"
          accessibilityHint="My Careship Username"
          disabled={isSubmitting}
          testID="username"
        />
        <Input
          control={control}
          name="password"
          label={intl.formatMessage({ id: 'PASSWORD' })}
          autoComplete="password"
          keyboardType="email-address"
          autoCapitalize="none"
          accessibilityHint="My Careship password"
          accessibilityLabel="password"
          disabled={isSubmitting}
          testID="password"
          secureTextEntry
        />
        <Btn
          disabled={isSubmitting}
          accessibilityRole="button"
          accessibilityLabel="submit"
          accessibilityHint="Submit login form"
          onPress={handlePressSubmit}
          testID="submit"
        >
          <FormattedMessage id="LOGIN" />
        </Btn>

        <ResetPasswordLink to="/request-password-reset">
          <FormattedMessage id="RESET_PASSWORD" />
        </ResetPasswordLink>
      </Form>
    </Wrapper>
  );
}
