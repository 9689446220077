import styled from '@emotion/native';

export default styled.View(({ theme: { breakpoints } }) => [
  {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  breakpoints.up('md', {
    marginTop: 'auto',
  }),
]);
