import { useEffect, useState } from 'react';
import { reaction } from 'mobx';
import { useStores } from '../../../Infrastructure/Store';
import useDefinitionService from '../../Definition/Hook/useDefinitionService';

export default function useProposalDeclineReasons(): string[] {
  const stores = useStores();
  const [proposalDeclineReasons, setProposalDeclineReasons] = useState<string[]>([]);
  const definitionService = useDefinitionService();

  useEffect(
    () =>
      reaction(
        () => ({
          declineReasons: stores.definitionStore.proposalDeclineReasons,
        }),
        ({ declineReasons }) => {
          setProposalDeclineReasons(Array.from(declineReasons.values()));
        },
        {
          fireImmediately: true,
        },
      ),
    [stores.definitionStore],
  );

  useEffect(() => {
    void definitionService.fetchProposalDeclineReasonList();
  }, [definitionService]);

  return proposalDeclineReasons;
}
