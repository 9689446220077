import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';
import { CareshipColors } from '../CSTheme';

interface PersonCheckIconProps extends SvgProps {
  color?: string;
  size?: number;
}

function PersonCheckIcon({
  color = CareshipColors.slate400,
  size = 24,
  ...props
}: PersonCheckIconProps) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" {...props}>
      <Path
        d="M6,0 C3.92893219,0 2.25,1.67893219 2.25,3.75 C2.25,5.82106781 3.92893219,7.5 6,7.5 C8.07106781,7.5 9.75,5.82106781 9.75,3.75 C9.75,1.67893219 8.07106781,0 6,0 Z M6,1.5 C7.24264069,1.5 8.25,2.50735931 8.25,3.75 C8.25,4.99264069 7.24264069,6 6,6 C4.75735931,6 3.75,4.99264069 3.75,3.75 C3.75,2.50735931 4.75735931,1.5 6,1.5 Z"
        fill={color}
      />
      <Path
        d="M4.45623841,7.70343916 C7.08301555,7.00367966 9.8521007,8.1570562 11.2054476,10.5146129 C11.4116636,10.8738452 11.2876194,11.3322316 10.9283871,11.5384476 C10.5691548,11.7446636 10.1107684,11.6206194 9.9045524,11.2613871 C8.88948585,9.49312134 6.81255662,8.62804089 4.84236434,9.15288967 C2.94253606,9.65899384 1.59957202,11.3356132 1.50530078,13.2826011 L1.5,13.5 L1.5,15 L3,15 C3.35028508,15 3.64876072,15.2412982 3.72893962,15.5732257 L3.74627789,15.6753722 L4.428,22.5 L7.57,22.5 L7.82671525,19.9424408 C7.86446168,19.5646259 8.17641297,19.2804347 8.54561217,19.2674107 L8.64755922,19.2707153 C9.0253741,19.3084617 9.30956529,19.620413 9.32258929,19.9896122 L9.31928475,20.0915592 L8.99628475,23.3245592 C8.96145972,23.673133 8.69165145,23.9461518 8.35337159,23.9929108 L8.25,24 L3.75,24 C3.39971492,24 3.10123928,23.7587018 3.02106038,23.4267743 L3.00372211,23.3246278 L2.321,16.5 L0.75,16.5 C0.370304234,16.5 0.0565090391,16.2178461 0.006846616,15.8517706 L0,15.75 L0,13.4996847 C0.00114285727,10.7812991 1.82946127,8.40319866 4.45623841,7.70343916 Z"
        fill={color}
      />
      <Path
        d="M17.25,10.5 C17.6642136,10.5 18,10.8357864 18,11.25 C18,11.6642136 17.6642136,12 17.25,12 C14.3505051,12 12,14.3505051 12,17.25 C12,20.1494949 14.3505051,22.5 17.25,22.5 C20.1494949,22.5 22.5,20.1494949 22.5,17.25 C22.5,16.8357864 22.8357864,16.5 23.25,16.5 C23.6642136,16.5 24,16.8357864 24,17.25 C24,20.9779221 20.9779221,24 17.25,24 C13.5220779,24 10.5,20.9779221 10.5,17.25 C10.5,13.5220779 13.5220779,10.5 17.25,10.5 Z"
        fill="#FFB300"
      />
      <Path
        d="M22.7196699,12.2186699 C23.0125631,11.9257767 23.4874369,11.9257767 23.7803301,12.2186699 C24.0465966,12.4849365 24.0708027,12.9016002 23.8529482,13.1952117 L23.7803301,13.2793301 L18.3107076,18.7489523 C18.0295295,19.0305312 17.6479294,19.1887543 17.25,19.1887543 C16.9018117,19.1887543 16.5661257,19.0676147 16.2996335,18.8490504 L16.1898241,18.7494843 L14.4698241,17.0304843 C14.1768458,16.7376762 14.1767077,16.2628025 14.4695157,15.9698241 C14.7357049,15.7034802 15.1523615,15.679153 15.4460363,15.896922 L15.5301759,15.9695157 L17.249,17.688 L22.7196699,12.2186699 Z"
        fill="#FFB300"
      />
    </Svg>
  );
}

export default PersonCheckIcon;
