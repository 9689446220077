import React, { useState } from 'react';
import styled, { css } from '@emotion/native';
import { graphql, useRefetchableFragment } from 'react-relay/hooks';
import { FormattedMessage } from 'react-intl';
import { useNavigation } from '@react-navigation/native';
import { Platform } from 'react-native';
import { Card, Container, Title, ToggleIconButton } from '../../Component';
import { AbsencesFragment$key } from './__generated__/AbsencesFragment.graphql';
import nonNullEdges from '../../../Infrastructure/Relay/nonNullEdges';
import AbsenceLineItem from './AbsenceLineItem';
import CreateAbsenceForm from './CreateAbsenceForm';
import { AccountStackNavigationProp } from '../Navigator/AccountStackNavigationProp';

const containerStyle = css({ marginBottom: 32 });

const titleStyle = css({ marginBottom: 16 });

const Header = styled.View({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'baseline',
});

export default function Absences({ fragmentRef }: { fragmentRef: AbsencesFragment$key }) {
  const [{ absences }] = useRefetchableFragment(
    graphql`
      fragment AbsencesFragment on Caregiver
      @refetchable(queryName: "AbsencesFragmentRefetchQuery") {
        absences {
          __id
          edges {
            node {
              id
              from
              to
              reason
            }
          }
        }
      }
    `,
    fragmentRef,
  );

  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const navigation = useNavigation<AccountStackNavigationProp<'Availability'>>();

  if (!absences) {
    return null;
  }

  // eslint-disable-next-line no-underscore-dangle
  const absenceEdgeId = absences?.__id;

  const handlePressCreate = () => {
    if (Platform.OS === 'web') {
      setIsCreateOpen((open) => !open);
      return;
    }

    navigation.navigate('CreateAbsence', {
      absenceEdgeId,
    });
  };

  return (
    <Container style={containerStyle}>
      <Header>
        <Title style={titleStyle} uppercase size="h5" muted>
          <FormattedMessage id="ABSENCES_TITLE" />
        </Title>
        <ToggleIconButton onPress={handlePressCreate} createOpen={isCreateOpen} />
      </Header>
      {isCreateOpen && (
        <Card mode="raised">
          <CreateAbsenceForm
            onCompleted={() => setIsCreateOpen(false)}
            absenceEdgeId={absenceEdgeId}
          />
        </Card>
      )}
      {absences.edges
        ?.filter(nonNullEdges)
        .reverse()
        .map(({ node }) => (
          <AbsenceLineItem node={node} key={node.id} />
        ))}
    </Container>
  );
}
