import { useState } from 'react';
import { logger } from '../Service';
import { UseShare } from './types/UseShare';

type NavigatorWithShare = Omit<Navigator, 'share'> & {
  share?: (data?: { text: string } | undefined) => Promise<void>;
  canShare?: (data?: { text: string } | undefined) => boolean;
};

const useShare: UseShare = () => {
  const [copiedToClipboard, setCopiedToClipboard] = useState(false);
  const navigatorWithShare = navigator as NavigatorWithShare;
  const isShareSupported = Boolean(
    navigatorWithShare.share &&
      navigatorWithShare.canShare &&
      navigatorWithShare.canShare({ text: '' }),
  );

  const share = async (message: string): Promise<boolean> => {
    try {
      if (
        navigatorWithShare.share &&
        navigatorWithShare.canShare &&
        navigatorWithShare.canShare({ text: message })
      ) {
        await navigatorWithShare.share({ text: message });
      } else {
        await navigator.clipboard.writeText(message);

        setCopiedToClipboard(true);
      }

      return true;
    } catch (error) {
      /**
       * We don't want to treat the closing of the sharing options as an error
       * in order to keep consistency across all platforms.
       */
      if (new Error(String(error)).message.includes('AbortError')) {
        return true;
      }

      logger.error(String(error));

      return false;
    }
  };

  return {
    share,
    isShareSupported,
    copiedToClipboard,
  };
};

export default useShare;
