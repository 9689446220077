import { Alert, Platform } from 'react-native';
import { useCallback } from 'react';

export default (): ((errorMessage: string) => void) => {
  return useCallback(function showAlert(errorMessage: string) {
    if (Platform.OS === 'web') {
      // eslint-disable-next-line no-alert
      alert(errorMessage);
    } else {
      Alert.alert(errorMessage);
    }
  }, []);
};
