import React, { useEffect } from 'react';
import { PreloadedQuery, usePreloadedQuery, useQueryLoader } from 'react-relay/hooks';
import { graphql } from 'react-relay';
import { Layout } from '../../../Component';
import { EditAboutMeScreenQuery } from './__generated__/EditAboutMeScreenQuery.graphql';
import AboutMeForm from '../../Component/AboutMe/AboutMeForm';

export const editAboutMeQuery = graphql`
  query EditAboutMeScreenQuery {
    me {
      ...AboutMeFormFragment
    }
  }
`;

function QueryLoader({
  preloadedQuery,
}: {
  preloadedQuery: PreloadedQuery<EditAboutMeScreenQuery>;
}) {
  const data = usePreloadedQuery(editAboutMeQuery, preloadedQuery);
  return data ? <AboutMeForm fragmentRef={data.me} /> : null;
}

export default function EditAboutMeScreen() {
  const [preloadedQuery, loadQuery] = useQueryLoader<EditAboutMeScreenQuery>(editAboutMeQuery);

  useEffect(() => {
    loadQuery(
      {},
      {
        fetchPolicy: 'store-and-network',
      },
    );
  }, [loadQuery]);

  return <Layout>{preloadedQuery && <QueryLoader preloadedQuery={preloadedQuery} />}</Layout>;
}
