import { action, computed, makeObservable, observable } from 'mobx';
import AbstractStore from '../../../Infrastructure/Store/AbstractStore';
import Booking, { BookingStatus, FirstBookingStatus, BookingUnion } from './Model/Booking';
import FirstBookingConfirmationStatus from './Model/FirstBookingConfirmationStatus';
import Pricing from './Model/Pricing';
import Service from './Model/Service';
import isOverlapping from './isOverlapping';
import logger from '../../../Infrastructure/Service/Logger';

export interface BookingResponse {
  readonly booking_id: string;
  readonly contract_id: string;
  readonly start_date: string;
  readonly end_date: string;
  readonly status: BookingStatus;
  readonly is_first_booking: boolean;
  readonly pricing: {
    duration_in_minutes: number;
    gross_price_per_hour_in_cents: number;
  };
  readonly services: {
    id: string;
    label_en: string;
    label_de: string;
  }[];
  readonly other_service_description: string;
  readonly first_booking_status: FirstBookingStatus;
  readonly first_booking_confirmation_status: null | {
    readonly reason: string;
    readonly description: string;
  };
}

interface NewBooking {
  startDate: Date;
  endDate: Date;
  bookingId?: string;
}

export type BookingListResponse = BookingResponse[];

const sortBookings = ({ endDate: a }: Booking, { endDate: b }: Booking): number =>
  a.getTime() - b.getTime();

const responseToBooking = (r: BookingResponse): BookingUnion =>
  new Booking(
    r.booking_id,
    r.contract_id,
    new Date(r.start_date),
    new Date(r.end_date),
    r.status,
    r.is_first_booking,
    new Pricing(r.pricing.duration_in_minutes, r.pricing.gross_price_per_hour_in_cents),
    r.services.map((s) => new Service(s.id, s.label_en, s.label_de)),
    r.first_booking_status,
    r.other_service_description,
    r.first_booking_confirmation_status
      ? new FirstBookingConfirmationStatus(
          r.first_booking_confirmation_status.reason,
          r.first_booking_confirmation_status.description,
        )
      : null,
  ) as BookingUnion;

export default class BookingStore extends AbstractStore {
  constructor() {
    super();
    makeObservable(this, {
      bookings: observable,
      viewData: computed,
      hydrate: action,
      delete: action,
      reset: action,
      handleBookingResponse: action,
      handleBookingListResponse: action,
    });
  }

  public bookings: BookingUnion[] = [];

  public get viewData() {
    return {
      bookings: this.bookings,
      undocumentedBookingCount: this.bookings.filter(
        (booking) => booking.isUndocumented && booking.firstBookingStatus !== 'declined',
      ).length,
    };
  }

  getOneByBookingId(id: string) {
    return computed(() => this.bookings.find(({ bookingId }) => id === bookingId)).get();
  }

  hydrate = (data?: { bookings: BookingUnion[] } | null) => {
    if (!data?.bookings) {
      return;
    }

    this.bookings = data.bookings.map(
      (b) =>
        new Booking(
          b.bookingId,
          b.contractId,
          new Date(b.startDate),
          new Date(b.endDate),
          b.status,
          b.isFirstBooking,
          new Pricing(b.pricing.durationInMinutes, b.pricing.grossPricePerHourInCents),
          b.services.map((s) => new Service(s.id, s.labelEn, s.labelDe)),
          b.firstBookingStatus,
          b.otherServiceDescription,
          b.firstBookingConfirmationStatus
            ? new FirstBookingConfirmationStatus(
                b.firstBookingConfirmationStatus.reason,
                b.firstBookingConfirmationStatus.description,
              )
            : null,
        ) as BookingUnion,
    );
  };

  delete(bookingId: string) {
    this.bookings = this.bookings.filter((b) => b.bookingId !== bookingId);
  }

  reset = () => {
    this.bookings = [];
  };

  handleBookingResponse(response: BookingResponse) {
    this.bookings[this.bookings.findIndex(({ bookingId }) => response.booking_id === bookingId)] =
      responseToBooking(response);

    this.bookings = this.bookings.slice().sort(sortBookings);
  }

  handleBookingListResponse(response: BookingListResponse) {
    this.bookings = response.map(responseToBooking).sort(sortBookings);
  }

  overlappingCount(newBooking: NewBooking | NewBooking[]): number {
    const newBookingArr = Array.isArray(newBooking) ? newBooking : [newBooking];

    return this.bookings
      .filter((booking) => !newBookingArr.some((nb) => nb.bookingId === booking.bookingId))
      .filter((booking) => {
        try {
          return isOverlapping(newBookingArr, booking);
        } catch (e) {
          logger.error(
            `booking ${
              booking.bookingId
            } interval invalid ${booking.startDate.toISOString()} - ${booking.endDate.toISOString()}`,
          );
          return false;
        }
      }).length;
  }
}
