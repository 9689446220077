import { graphql, useMutation } from 'react-relay/hooks';
import { useIntl } from 'react-intl';
import { Disposable } from 'relay-runtime';
import { useDeleteAbsenceMutation } from './__generated__/useDeleteAbsenceMutation.graphql';
import useLogger from '../../../Infrastructure/Hook/useLogger';
import { useShowAlert } from '../../Dialog';

export default function useDeleteAbsence({ id }: { id: string }): {
  deleteAbsence: () => Disposable;
  isInFlight: boolean;
} {
  const logger = useLogger();
  const intl = useIntl();
  const showAlert = useShowAlert();
  const [commitMutation, isInFlight] = useMutation<useDeleteAbsenceMutation>(
    graphql`
      mutation useDeleteAbsenceMutation($input: DeleteCaregiverAbsenceInput!) {
        deleteCaregiverAbsence(input: $input) {
          deletedId @deleteRecord
          clientMutationId
        }
      }
    `,
  );

  return {
    deleteAbsence: () =>
      commitMutation({
        variables: {
          input: { id },
        },
        onError: (error) => {
          showAlert(intl.formatMessage({ id: 'ERROR_UNKNOWN' }));
          logger.error('deleteCaregiverAbsence mutation failed', { relayError: error });
        },
      }),
    isInFlight,
  };
}
