import React from 'react';
import Svg, { G, Path, SvgProps } from 'react-native-svg';
import { CareshipColors } from '../CSTheme';

type ChemieStimmtIconProps = SvgProps & {
  color?: string;
  size?: number;
};

export default function ChemieStimmtIcon({
  color = CareshipColors.primary,
  size = 24,
  ...props
}: ChemieStimmtIconProps) {
  return (
    <Svg width={size} height={size} viewBox="0 0 36 36" {...props}>
      <G fillRule="nonzero" fill="none">
        <G fill={CareshipColors.slate400}>
          <Path d="M17.625 14.25a5.625 5.625 0 110-11.25 5.625 5.625 0 010 11.25zm0-2.25a3.375 3.375 0 100-6.75 3.375 3.375 0 000 6.75zM6 16.5a4.5 4.5 0 110-9 4.5 4.5 0 010 9zm0-2.25a2.25 2.25 0 100-4.5 2.25 2.25 0 000 4.5z" />
          <Path d="M2.422 25.499H1.125A1.125 1.125 0 010 24.374v-1.127a6.755 6.755 0 018.909-6.394 1.125 1.125 0 11-.718 2.132A4.505 4.505 0 002.25 23.25h1.125c.55 0 1.02.397 1.11.94l.968 5.81h2.594l.407-2.444a1.125 1.125 0 012.22.37l-.564 3.384c-.09.542-.56.94-1.11.94H4.5c-.55 0-1.02-.398-1.11-.94l-.968-5.81z" />
          <Path d="M12.882 26.25h-1.257a1.125 1.125 0 01-1.125-1.125V21.75a6.75 6.75 0 1113.5 0v3.375c0 .621-.504 1.125-1.125 1.125h-1.257l-1.002 8.015c-.07.563-.549.985-1.116.985H15a1.125 1.125 0 01-1.116-.985l-1.002-8.015zM21.75 24v-2.25a4.5 4.5 0 10-9 0V24h1.125c.567 0 1.046.422 1.116.985L15.993 33h2.514l1.002-8.015c.07-.563.549-.985 1.116-.985h1.125z" />
        </G>
        <Path
          d="M32.828.047a3.769 3.769 0 012.77 2 3.673 3.673 0 01-.694 4.293l-4.475 4.572c-.441.45-1.167.45-1.608 0l-4.458-4.556a3.679 3.679 0 01-.71-4.309 3.768 3.768 0 012.77-2 3.807 3.807 0 013.202.98 3.808 3.808 0 013.203-.98zm.484 4.702a1.43 1.43 0 00.282-1.679 1.519 1.519 0 00-1.119-.8c-.489-.078-.986.079-1.332.418l-.73.716a1.125 1.125 0 01-1.575 0l-.731-.716a1.557 1.557 0 00-1.332-.418 1.518 1.518 0 00-1.118.8c-.286.56-.174 1.233.297 1.696l3.671 3.75 3.687-3.767z"
          fill={color}
        />
      </G>
    </Svg>
  );
}
