import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';
import { CareshipColors } from '../CSTheme';

interface BirthdayIconProps extends SvgProps {
  color?: string;
  size?: number;
}

export default function BirthdayIcon({
  color = CareshipColors.slate400,
  size = 24,
  ...props
}: BirthdayIconProps) {
  return (
    <Svg viewBox="0 0 24 24" width={size} height={size} {...props}>
      <Path
        d="M13.25 7.982c.69 0 1.25.56 1.25 1.25v2.25h4.25a2.75 2.75 0 0 1 2.745 2.582l.005.168v3.001c0 .777-.508 1.443-1.25 1.67v1.829a2.25 2.25 0 0 1-2.096 2.245l-.154.005H5.5a2.25 2.25 0 0 1-2.245-2.096l-.005-.154v-1.826A1.763 1.763 0 0 1 2 17.22v-2.988a2.75 2.75 0 0 1 2.75-2.75H9v-2.25c0-.647.492-1.18 1.122-1.244l.128-.006Zm2.714 9c-.625 0-.936.174-1.623.803l-.207.186c-.82.724-1.396 1.011-2.384 1.011-1.065 0-1.686-.337-2.661-1.184l-.236-.202c-.562-.47-.864-.614-1.339-.614-.542 0-.887.2-1.505.792l-.428.403c-.29.26-.55.45-.831.582v1.973c0 .38.282.693.648.743l.102.007H18a.75.75 0 0 0 .743-.648l.007-.102V18.76c-.328-.166-.64-.428-1.014-.803l-.255-.254c-.534-.523-.906-.721-1.517-.721Zm1.286 2.268a.5.5 0 1 1 0 1 .5.5 0 0 1 0-1ZM7.5 19a.5.5 0 1 1 0 1 .5.5 0 0 1 0-1Zm8-.75a.5.5 0 1 1 0 1 .5.5 0 0 1 0-1Zm3.25-5.268h-14c-.69 0-1.25.56-1.25 1.25v2.988c0 .145.117.262.214.264l.153-.009c.242-.032.464-.175.86-.55l.428-.407c.783-.715 1.407-1.036 2.36-1.036.977 0 1.562.32 2.48 1.117l.238.204c.618.52.956.679 1.517.679.586 0 .883-.167 1.551-.779l.21-.19c.838-.738 1.431-1.031 2.453-1.031 1.07 0 1.778.378 2.571 1.154l.38.378c.377.363.565.468.793.47l.073-.004a.249.249 0 0 0 .22-.247v-3.001c0-.69-.56-1.25-1.25-1.25ZM13 9.482h-2.5v2H13v-2Zm-1.763-8.28a.75.75 0 0 1 1.072.045l.195.195.316.337c.156.173.31.354.457.541.321.408.587.811.777 1.205.206.424.321.83.321 1.225 0 1.63-1.142 3-2.625 3s-2.625-1.37-2.625-3c0-.394.115-.8.32-1.225.191-.394.457-.797.778-1.205.221-.28.456-.547.691-.794l.082-.084Zm.513 1.631-.155.178-.193.238a5.28 5.28 0 0 0-.606.93c-.115.237-.171.434-.171.571 0 .856.537 1.5 1.125 1.5s1.125-.644 1.125-1.5c0-.137-.056-.334-.17-.572a5.28 5.28 0 0 0-.607-.93l-.193-.237-.155-.178Z"
        fill={color}
      />
    </Svg>
  );
}
