import { useCallback, useState } from 'react';
import { useRelayEnvironment } from 'react-relay/hooks';
import { fetchQuery, graphql } from 'react-relay';
import { useProposalService } from '../../../Context/Proposal/Hook';
import { intl } from '../../../Infrastructure/Intl';
import { events, trackEvent } from '../../../Infrastructure/Tracking/Tracking';
import { isError } from '../../../Infrastructure/Service';
import { useShowAlert } from '../../Dialog';

export default function useAcceptProposal(onAccept?: () => void): {
  isAccepting: boolean;
  handleAccept: (serviceRequestId: string) => Promise<void>;
} {
  const proposalService = useProposalService();
  const showAlert = useShowAlert();
  const [isAccepting, setIsAccepting] = useState(false);
  const environment = useRelayEnvironment();

  const handleAccept = useCallback(
    async (serviceRequestId: string) => {
      setIsAccepting(true);
      trackEvent(events.PROPOSAL_ACCEPT_FINALIZE_CLICK);

      const result = await proposalService.acceptProposal(serviceRequestId);
      // by using fetchQuery instead of one of the hooks this runs in the background.
      // when given the opportunity replace the entire proposal acceptance with
      // a mutation that returns the proposal and thus updates the store automatically.
      fetchQuery(
        environment,
        graphql`
          query useAcceptProposalQuery($id: ID!) {
            proposal(serviceRequestId: $id) {
              id
              status
            }
          }
        `,
        { id: serviceRequestId },
        {
          networkCacheConfig: {
            force: true,
          },
        },
      ).subscribe({
        // needed for executing the request
        complete: () => {},
      });

      if (isError(result)) {
        showAlert(intl.formatMessage({ id: 'ERROR_RESPONSE' }, { errorCode: result.errorCode }));
        setIsAccepting(false);

        return;
      }

      setIsAccepting(false);

      if (onAccept) {
        onAccept();
      }
    },
    [proposalService, environment, onAccept, showAlert],
  );

  return { isAccepting, handleAccept };
}
