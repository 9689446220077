import { action, makeObservable, observable } from 'mobx';
import AbstractStore from '../../../Infrastructure/Store/AbstractStore';
import Service from '../../Booking/Store/Model/Service';
import CancellationReasons from "../../Booking/Store/Model/CancellationReasons";
import {array} from "fp-ts";

type ServiceResponse = {
  id: string;
  label: {
    en: string;
    de: string;
  };
};

type BookingCancellationReasonsResponse = {
  label: string;
};

export type ConfirmationStatusUpdateReasonListResponse = string[];

export type ServiceListResponse = {
  main_service: ServiceResponse;
  subservices: ServiceResponse[];
}[];

export default class DefinitionStore extends AbstractStore {
  constructor() {
    super();
    makeObservable(this, {
      firstBookingDeclineReasons: observable,
      caregiverCancellationReasons: observable,
      customerCancellationReasons: observable,
      proposalDeclineReasons: observable,
      confirmationStatusUpdateReasons: observable,
      services: observable,
      hydrate: action,
      reset: action,
      handleConfirmationStatusUpdateReasons: action,
      handleFirstBookingDeclineReasonListResponse: action,
      handleBookingCancellationReasonListResponse: action,
      handleProposalDeclineReasonListResponse: action,
      handleServiceListResponse: action,
    });
  }

  public firstBookingDeclineReasons: Set<string> = new Set();
  public caregiverCancellationReasons: Set<string> = new Set();

  public customerCancellationReasons: Set<string> = new Set();

  public proposalDeclineReasons: Set<string> = new Set();

  public confirmationStatusUpdateReasons: Set<string> = new Set();

  public services: Service[] = [];


  hydrate = (data: typeof DefinitionStore) => {
    Object.assign(this, data);
  };

  reset = () => {
    this.services = [];
  };

  handleFirstBookingDeclineReasonListResponse(response: string[]) {
    this.firstBookingDeclineReasons = new Set(response);
  }

  handleBookingCancellationReasonListResponse(caregiverReasons: string[], customerReasons: string[], ) {
    console.log('response inside the handle method')
    console.log(caregiverReasons);
    this.caregiverCancellationReasons = new Set(caregiverReasons);
    this.customerCancellationReasons = new Set(customerReasons);

    console.log(this.caregiverCancellationReasons, this.customerCancellationReasons)
  }

  handleProposalDeclineReasonListResponse(response: string[]) {
    this.proposalDeclineReasons = new Set(response);
  }

  handleConfirmationStatusUpdateReasons(response: ConfirmationStatusUpdateReasonListResponse) {
    this.confirmationStatusUpdateReasons = new Set(response);
  }

  handleServiceListResponse(response: ServiceListResponse) {
    this.services = response
      .flatMap<ServiceResponse>(({ main_service: mainService }) => mainService)
      .map(({ id, label: { de: labelDe, en: labelEn } }) => new Service(id, labelEn, labelDe));
  }
}
