import React, { ReactElement, useCallback, useLayoutEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Platform, StyleSheet, Text as RNText } from 'react-native';
import { PreloadedQuery, usePreloadedQuery } from 'react-relay/hooks';
import { graphql } from 'react-relay';
import { useNavigation } from '@react-navigation/native';
import { Tab, TabRow, Text } from '../../Component';
import CareReceiverDetails from './CareReceiverDetails';
import AdditionalContactDetails from './AdditionalContactDetails';
import FirstBookingActions from './FirstBookingActions';
import StatusBarContractCancelation from './StatusBarContractCancelation';
import FirstCallActions from './FirstCallActions';
import FirstAppointmentChecklistButton from './FirstAppointmentChecklist/FirstAppointmentChecklistButton';
import StatusBarFirstAppointment from './StatusBarFirstAppointment';
import ContractConfirmationActions from './ContractConfirmationActions';
import { CustomerDetailQuery } from './__generated__/CustomerDetailQuery.graphql';
import IsNewCustomerCard from './IsNewCustomerCard';
import { CustomerStackNavigationProp } from '../Navigator/CustomerStackNavigationProp';
import InsuranceDirectPaymentInfo from './InsuranceDirectPaymentInfo';

interface CustomerDetailProps {
  preloadedQuery: PreloadedQuery<CustomerDetailQuery>;
}

const styles = StyleSheet.create({
  tabContent: {
    marginTop: 24,
  },
  tabRow: {
    marginTop: 16,
  },
});

export const customerDetailQuery = graphql`
  query CustomerDetailQuery($contractId: ID!) {
    contract(contractId: $contractId) {
      id
      careReceiver {
        isSelfBooker
        name {
          firstName
          lastName
        }
      }
      ...CareReceiverDetails_contract
      ...FirstCallActions_contract
      ...IsNewCustomerCard_contract
      ...StatusBarFirstAppointment_contract
      ...FirstAppointmentChecklistButton_contract
      ...FirstBookingActions_contract
      ...ContractConfirmationActions_contract
      ...AdditionalContactDetails_contract
      ...StatusBarContractCancelation_contract
      ...InsuranceDirectPaymentInfo_contract
    }
  }
`;
export default function CustomerDetail({ preloadedQuery }: CustomerDetailProps): ReactElement {
  const intl = useIntl();
  const navigation = useNavigation<CustomerStackNavigationProp<'CustomerDetailView'>>();
  const [currentTab, setCurrentTab] = useState<'careReceiver' | 'additionalContact'>(
    'careReceiver',
  );
  const { contract } = usePreloadedQuery<CustomerDetailQuery>(customerDetailQuery, preloadedQuery);

  useLayoutEffect(() => {
    if (!contract?.careReceiver.name) {
      return;
    }

    const { firstName, lastName } = contract.careReceiver.name;
    navigation.setOptions({
      title: intl.formatMessage({ id: 'NAME_FULL' }, { firstName, lastName }),
    });
  }, [contract, intl, navigation]);

  const handleSelectAdditionalContactTab = useCallback(
    () => setCurrentTab('additionalContact'),
    [setCurrentTab],
  );

  const handleSelectCareReceiverTab = useCallback(
    () => setCurrentTab('careReceiver'),
    [setCurrentTab],
  );

  if (!contract) {
    return (
      <Text>
        <FormattedMessage id="ERROR_RESPONSE" values={{ errorCode: 'CONTRACT_NOT_FOUND' }} />
      </Text>
    );
  }

  return (
    <>
      {Platform.OS === 'web' && <IsNewCustomerCard contractFragment={contract} />}
      <StatusBarFirstAppointment contractFragment={contract} />
      <StatusBarContractCancelation contractFragment={contract} />
      {Platform.OS !== 'web' && <IsNewCustomerCard contractFragment={contract} />}
      <FirstCallActions contractFragment={contract} />
      <FirstAppointmentChecklistButton contractFragment={contract} />
      <FirstBookingActions contractFragment={contract} />
      <ContractConfirmationActions contractFragment={contract} />
      <InsuranceDirectPaymentInfo contractFragment={contract} />
      {!contract.careReceiver.isSelfBooker && (
        <TabRow style={styles.tabRow}>
          <Tab active={currentTab === 'careReceiver'} onPress={handleSelectCareReceiverTab}>
            <FormattedMessage id="CUSTOMER" tagName={RNText} />
          </Tab>
          <Tab
            active={currentTab === 'additionalContact'}
            onPress={handleSelectAdditionalContactTab}
          >
            <FormattedMessage tagName={RNText} id="ADDITIONAL_CONTACT" />
          </Tab>
        </TabRow>
      )}

      {currentTab === 'careReceiver' && (
        <>
          {contract && (
            <CareReceiverDetails style={styles.tabContent} contractFragment={contract} />
          )}
        </>
      )}

      {currentTab === 'additionalContact' && (
        <AdditionalContactDetails style={styles.tabContent} contractFragment={contract} />
      )}
    </>
  );
}
