import styled from '@emotion/native';

export default styled.View(({ theme: { breakpoints } }) => [
  {
    paddingHorizontal: 16,
    marginTop: 24,
    marginBottom: 64,
  },
  breakpoints.down('md', { display: 'none' }),
]);
