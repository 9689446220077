import { useRef } from 'react';
import { useStores } from '../../../Infrastructure/Store';
import ProfileService from '../Service/ProfileService';
import useAuthorizedClient from '../../../Infrastructure/Hook/useAuthorizedClient';

export default function useProfileService() {
  const { profileStore } = useStores();
  const authorizedClient = useAuthorizedClient();

  return useRef(new ProfileService(profileStore, authorizedClient)).current;
}
