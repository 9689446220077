import styled, { css } from '@emotion/native';
import { Platform } from 'react-native';
import { isAfter, isBefore } from 'date-fns/fp';
import { FormattedMessage } from 'react-intl';
import { RadioButton, TouchableRipple } from 'react-native-paper';
import React, { ReactNode, useCallback, useState } from 'react';
import CSTheme, { CareshipColors } from '../../Component/CSTheme';
import useBreakpoints from '../../Root/Theme/useBreakpoints';
import { FormGroupTitle, InputErrorMessage } from '../../Component';
import DateTimeInput from '../../Component/Form/DateTimeInput';
import { intl } from '../../../Infrastructure/Intl';
import Label from '../../Component/Form/Label';

const RadioButtonRow = styled.View(({ theme: { breakpoints, colors } }) => [
  breakpoints.down('lg', {
    borderBottomWidth: 1,
    borderBottomColor: colors.slate100,
    minHeight: 64,
    paddingVertical: 16,
    marginHorizontal: 16,
  }),
  breakpoints.up('lg', {
    paddingRight: 16,
  }),
  {
    flexDirection: 'row',
    alignItems: 'center',
  },
]);

const RadioButtonTouchable = styled(TouchableRipple)(({ theme: { breakpoints } }) => [
  { borderRadius: 16 },
  breakpoints.up('lg', {
    marginRight: 16,
  }),
]);

export const Container = styled.View(({ theme: { breakpoints } }) => [
  breakpoints.up('lg', {
    marginHorizontal: 24,
  }),
  {
    marginHorizontal: 16,
  },
]);

const Group = styled.View(({ theme: { breakpoints } }) => [
  breakpoints.down('lg', {
    marginVertical: 24,
  }),
  {
    marginBottom: 24,
  },
]);

const DateRow = styled.View(({ theme: { breakpoints } }) =>
  Platform.OS === 'web'
    ? [
        breakpoints.up('lg', {
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: 8,
          marginTop: 16,
        }),
      ]
    : [],
);

const AbsenceReasonsList = styled.View(({ theme: { breakpoints } }) => [
  Platform.OS === 'web' &&
    breakpoints.up('lg', {
      flexDirection: 'row',
      marginHorizontal: 16,
    }),
]);

const inputLgStyle = css({
  flex: 1,
  borderBottomWidth: 1,
  borderBottomColor: CareshipColors.slate100,
  maxWidth: 'calc(50% - 48px)',
});

const SubmitRow = styled.View(({ theme: { breakpoints } }) => [
  breakpoints.up('md', {
    justifyContent: 'flex-end',
    flexDirection: 'row',
  }),
]);

const REASONS = ['OTHER', 'SICK', 'VACATION'] as const;

export type Reason = typeof REASONS[number];

interface AbsenceFormProps {
  onStartDateChange: (start: Date) => void;
  startDate: Date;
  onEndDateChange: (value: Date) => void;
  endDate: Date;
  reason: Reason;
  onReasonChange: (value: Reason) => void;
  error?: ReactNode;
  children?: ReactNode;
}

export default function AbsenceForm({
  onStartDateChange,
  onEndDateChange,
  onReasonChange,
  startDate,
  endDate,
  reason,
  error,
  children,
}: AbsenceFormProps) {
  const [focusedInput, setFocusedInput] = useState<'startDate' | 'endDate' | null>(null);
  const breakpoints = useBreakpoints();
  const lgDown = breakpoints.down('lg');
  const lgUp = breakpoints.up('lg');

  const handleStartDateChange = useCallback(
    (start: Date) => {
      onStartDateChange(start);

      if (isAfter(endDate, start)) {
        onEndDateChange(start);
      }
    },
    [endDate, onEndDateChange, onStartDateChange],
  );

  const handleEndDateChange = useCallback(
    (end: Date) => {
      onEndDateChange(end);

      if (isBefore(startDate, end)) {
        onStartDateChange(end);
      }
    },
    [onEndDateChange, onStartDateChange, startDate],
  );

  const handleReasonPress = useCallback(
    (value: Reason) => {
      onReasonChange(value);
      setFocusedInput(null);
    },
    [onReasonChange, setFocusedInput],
  );

  const handleStartDateFocussed = useCallback(() => setFocusedInput('startDate'), []);
  const handleEndDateFocussed = useCallback(() => setFocusedInput('endDate'), []);

  return (
    <>
      <Group>
        {lgDown(
          <Container>
            <FormGroupTitle>
              <FormattedMessage id="ABSENCE_PERIOD" />
            </FormGroupTitle>
          </Container>,
        )}

        <DateRow>
          <DateTimeInput
            style={lgUp(inputLgStyle)}
            inline={!lgDown(true)}
            testID="start-date"
            isFocused={focusedInput === 'startDate'}
            label={intl.formatMessage({ id: 'DATEPICKER_START_DATE_LABEL' })}
            onChange={handleStartDateChange}
            mode="date"
            onFocused={handleStartDateFocussed}
            value={startDate}
          />
          <DateTimeInput
            style={lgUp(inputLgStyle)}
            inline={!lgDown(true)}
            testID="end-date"
            isFocused={focusedInput === 'endDate'}
            label={intl.formatMessage({ id: 'DATEPICKER_END_DATE_LABEL' })}
            minimumDate={startDate}
            onChange={handleEndDateChange}
            onFocused={handleEndDateFocussed}
            mode="date"
            value={endDate}
          />
        </DateRow>
      </Group>

      <Group>
        {lgDown(
          <Container>
            <FormGroupTitle>
              <FormattedMessage id="ABSENCE_REASON" />
            </FormGroupTitle>
          </Container>,
        )}

        <RadioButton.Group value={reason} onValueChange={(r) => onReasonChange(r as Reason)}>
          <AbsenceReasonsList>
            {REASONS.map((value) => (
              <RadioButtonTouchable
                rippleColor={CareshipColors.rippleColor}
                onPress={() => handleReasonPress(value)}
                key={value}
              >
                <RadioButtonRow>
                  <RadioButton.Android color={CSTheme.colors.primary} value={value} />
                  <Label active={reason === value}>
                    <FormattedMessage
                      id="ABSENCE_TYPE"
                      values={{ type: value.toLocaleLowerCase() }}
                    />
                  </Label>
                </RadioButtonRow>
              </RadioButtonTouchable>
            ))}
          </AbsenceReasonsList>
        </RadioButton.Group>
      </Group>
      <Container>
        {error && (
          <InputErrorMessage>
            <FormattedMessage id="ERROR_RESPONSE" values={{ errorCode: error }} />
          </InputErrorMessage>
        )}
        <SubmitRow>{children}</SubmitRow>
      </Container>
    </>
  );
}
