import React, { ComponentProps, FC } from 'react';
import CSTheme from './CSTheme';
import PaperButton from './PaperButton';

/**
 * Button component
 */
const MiniButton: FC<ComponentProps<typeof PaperButton>> = ({ labelStyle, ...props }) => (
  <PaperButton
    mode="text"
    compact
    style={{
      shadowOpacity: 0,
    }}
    contentStyle={{
      paddingVertical: 4,
    }}
    labelStyle={[
      {
        fontSize: 16,
        ...CSTheme.fonts.regular,
        textTransform: 'none',
        letterSpacing: 0,
      },
      labelStyle,
    ]}
    {...props}
  />
);

export default MiniButton;
