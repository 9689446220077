import { useNavigation } from '@react-navigation/native';
import React, { useCallback, useEffect } from 'react';
import { Platform } from 'react-native';
import { useQueryLoader } from 'react-relay/hooks';
import { Layout } from '../../Component';
import InvoiceList, { invoiceListQuery } from '../Component/InvoiceList';
import { InvoiceListQuery } from '../Component/__generated__/InvoiceListQuery.graphql';
import useInvoiceDownloadUrl from '../Hook/useInvoiceDownloadUrl';
import { AccountStackNavigationProp } from '../Navigator/AccountStackNavigationProp';
import { events, trackEvent } from '../../../Infrastructure/Tracking/Tracking';

export default function InvoiceListScreen() {
  const [preloadedQuery, loadQuery] = useQueryLoader<InvoiceListQuery>(invoiceListQuery);
  const { generateUrl } = useInvoiceDownloadUrl();
  const navigation = useNavigation<AccountStackNavigationProp<'InvoiceList'>>();

  const onPressInvoice = useCallback(
    async ({
      invoiceId,
      filename,
      title,
    }: {
      invoiceId: string;
      filename: string;
      title: string;
    }) => {
      if (Platform.OS === 'web') {
        trackEvent(events.INVOICE_DOWNLOAD_CLICK, {
          invoice_id: invoiceId,
        });

        const uri = await generateUrl(invoiceId);

        if (uri) {
          window.location.href = uri;
        }

        return;
      }

      trackEvent(events.INVOICE_DETAIL_CLICK, {
        invoice_id: invoiceId,
      });

      navigation.navigate('InvoiceDetail', {
        invoiceId,
        filename,
        title,
      });
    },
    [generateUrl, navigation],
  );

  useEffect(() => {
    loadQuery({}, { fetchPolicy: 'store-and-network' });
  }, [loadQuery]);

  return (
    <Layout>
      {preloadedQuery && (
        <InvoiceList preloadedQuery={preloadedQuery} onPressInvoice={onPressInvoice} />
      )}
    </Layout>
  );
}
