import React, {
  ComponentProps,
  FC,
  forwardRef,
  memo,
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { PreloadedQuery, useMutation, usePreloadedQuery } from 'react-relay/hooks';
import { graphql } from 'react-relay';
import { Platform, SectionList, View } from 'react-native';
import { FormattedMessage } from 'react-intl';
import styled from '@emotion/native';
import { SelectorStoreUpdater } from 'relay-runtime';
import { useNavigation } from '@react-navigation/native';
import { IconButton } from 'react-native-paper';
import { Feather } from '@expo/vector-icons';
import { ServicesQuery, ServicesQuery$data } from './__generated__/ServicesQuery.graphql';
import { Button, CSTheme, DividingHeader, HeaderActionButton } from '../../Component';
import { logger } from '../../../Infrastructure/Service';
import { ServicesMutation, ServicesMutation$data } from './__generated__/ServicesMutation.graphql';
import { CareshipColors } from '../../Component/CSTheme';
import { AccountStackNavigationProp } from '../Navigator/AccountStackNavigationProp';
import { intl } from '../../../Infrastructure/Intl';
import usePlainAlert from '../../Dialog/useShowAlert';
import { setEquals, toggleSet } from '../../../Infrastructure/Set';
import StickyFooter from '../../Component/StickyFooter';
import CheckboxInputRowWithTooltip from '../../Component/CheckboxInputRowWithTooltip';

const ActionButton = styled<FC<Omit<ComponentProps<typeof Button>, 'theme'>>>(Button)(
  ({ theme: { breakpoints, spacing } }) => [
    spacing({
      marginHorizontal: 'base',
    }),
    {
      marginBottom: 24,
      flex: 1,
    },
    breakpoints.up('md', {
      marginLeft: 16,
    }),
  ],
);

export const servicesQuery = graphql`
  query ServicesQuery {
    me {
      ... on Caregiver {
        id
        subServiceIds
      }
    }
    definitions {
      services {
        id
        serviceId
        label
        subServices {
          id
          subServiceId
          label
        }
      }
    }
  }
`;

const updater =
  (id?: string): SelectorStoreUpdater<ServicesMutation$data> =>
  (store, { updateCaregiverServices }) => {
    if (!id) {
      logger.error('no id given to ServicesMutation updater fn');

      return;
    }

    const meRecord = store.get<ServicesQuery$data['me']>(id);

    if (meRecord && updateCaregiverServices !== null) {
      meRecord.setValue(updateCaregiverServices.subServiceIds, 'subServiceIds');
    }
  };

interface ActionButtonsProps {
  inFlight: boolean;
  dirty: boolean;
  onSubmit: () => void;
  onAbort: () => void;
}

const ActionButtons = forwardRef<View, ActionButtonsProps>(function ActionButtonsComponent(
  { dirty, inFlight, onAbort, onSubmit }: ActionButtonsProps,
  ref,
) {
  return (
    <View
      collapsable={false}
      ref={ref}
      style={{
        flexDirection: 'row',
        paddingTop: 24,
      }}
    >
      <ActionButton disabled={inFlight || !dirty} loading={inFlight} onPress={onSubmit}>
        <FormattedMessage id="ABSENCE_SAVE" />
      </ActionButton>
      {Platform.OS === 'web' && (
        <ActionButton
          mode="outlined"
          color={CareshipColors.slate400}
          disabled={inFlight}
          onPress={onAbort}
        >
          <FormattedMessage id="BUTTON_CANCEL" />
        </ActionButton>
      )}
    </View>
  );
});

export default memo(function Services({
  preloadedQuery,
}: {
  preloadedQuery: PreloadedQuery<ServicesQuery>;
}) {
  const footerButtonsRef = useRef<View>(null);
  const showAlert = usePlainAlert();
  const navigation = useNavigation<AccountStackNavigationProp<'Services'>>();
  const {
    me: { subServiceIds, id },
    definitions: { services },
  } = usePreloadedQuery(servicesQuery, preloadedQuery);
  const [commitMutation, isInFlight] = useMutation<ServicesMutation>(
    graphql`
      mutation ServicesMutation($input: UpdateCaregiverServicesInput!) {
        updateCaregiverServices(input: $input) {
          subServiceIds
        }
      }
    `,
  );

  const initialSubServiceIds = new Set<string>(subServiceIds);
  const [selectedSubServices, setSelectedSubServices] = useState<Set<string>>(initialSubServiceIds);

  const isDirty = !setEquals(selectedSubServices, initialSubServiceIds);

  const onCompleted = useCallback(() => {
    navigation.goBack();
  }, [navigation]);

  const handleSubmit = useCallback(() => {
    if (selectedSubServices.size === 0) {
      showAlert(intl.formatMessage({ id: 'VALIDATION_AT_LEAST_ONE_SERVICE' }));

      return;
    }

    commitMutation({
      variables: {
        input: {
          subServiceIds: Array.from(selectedSubServices.values()),
        },
      },
      onCompleted,
      updater: updater(id),
      onError: (error) => {
        showAlert(intl.formatMessage({ id: 'ERROR_UNKNOWN' }));

        logger.error('setCaregiverBookedOut mutation failed', { relayError: error });
      },
    });
  }, [commitMutation, id, onCompleted, selectedSubServices, showAlert]);

  useLayoutEffect(() => {
    const headerRight = () =>
      Platform.select({
        ios: (
          <HeaderActionButton
            onPress={handleSubmit}
            disabled={isInFlight || !isDirty}
            title={intl.formatMessage({ id: 'BUTTON_SAVE' })}
          />
        ),
        android: (
          <IconButton
            style={{ marginRight: 8 }}
            disabled={isInFlight || !isDirty}
            onPress={handleSubmit}
            icon={() => <Feather color={CSTheme.colors.background} size={24} name="save" />}
          />
        ),
      });

    navigation.setOptions({
      headerRight,
    });
  }, [handleSubmit, isDirty, isInFlight, navigation]);

  function handleServiceSelection(subServiceId: string) {
    setSelectedSubServices(toggleSet(subServiceId));
  }

  return (
    <>
      <SectionList
        ListHeaderComponent={<View style={{ height: 32 }} />}
        sections={services.map((data) => ({
          title: data.label,
          key: data.serviceId,
          data: data.subServices,
        }))}
        keyExtractor={(item) => item.subServiceId}
        stickySectionHeadersEnabled
        renderItem={({ item: { subServiceId, label }, index, section }) => (
          <CheckboxInputRowWithTooltip
            key={subServiceId}
            onPress={() => handleServiceSelection(subServiceId)}
            status={selectedSubServices.has(subServiceId) ? 'checked' : 'unchecked'}
            bottommost={index === section.data.length - 1}
            label={label}
            subserviceId={subServiceId}
          />
        )}
        renderSectionHeader={({ section: { title } }) => (
          <DividingHeader style={{ marginBottom: 8, overflow: 'hidden', flex: 1, marginRight: 15 }}>
            {title}
          </DividingHeader>
        )}
        ListFooterComponent={
          <ActionButtons
            ref={footerButtonsRef}
            inFlight={isInFlight}
            dirty={isDirty}
            onSubmit={handleSubmit}
            onAbort={onCompleted}
          />
        }
      />
      <StickyFooter sticky={isDirty} alignWithRef={footerButtonsRef}>
        <ActionButtons
          inFlight={isInFlight}
          dirty={isDirty}
          onSubmit={handleSubmit}
          onAbort={onCompleted}
        />
      </StickyFooter>
    </>
  );
});
