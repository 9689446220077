/* eslint-disable react/no-unused-prop-types */
import { StyleSheet, View } from 'react-native';
import React, { useCallback, useEffect, useState } from 'react';
import { DatePicker, TimePicker } from '@material-ui/pickers';
import { roundToNearestMinutes } from 'date-fns';
import { FormattedDate, FormattedTime } from 'react-intl';
import { Portal, TouchableRipple } from 'react-native-paper';
import { usePopper } from 'react-popper';
import styled from '@emotion/native';
import { Options } from '@popperjs/core';
import { CareshipColors } from '../CSTheme';
import Hr from '../Hr';
import { Props } from './DateTimeInput';
import Title from '../Title';

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    marginLeft: 16,
    marginRight: 16,
    minHeight: 64,
    alignItems: 'center',
  },
  labelText: {
    minWidth: 50,
    fontFamily: 'basier-circle-regular',
    fontSize: 18,
    color: CareshipColors.slate300,
    marginLeft: 'auto',
  },
  pickers: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignSelf: 'stretch',
    flex: 1,
  },
  picker: {
    marginLeft: 24,
    height: '100%',
    alignItems: 'stretch',
    display: 'flex',
  },
  touchable: {
    paddingHorizontal: 8,
    justifyContent: 'center',
  },
});

const CalendarOverlay = styled.View<{ open: boolean }>(({ open }) => ({
  display: open ? undefined : 'none',
  pointerEvents: open ? undefined : 'none',
  opacity: open ? 1 : 0,
  borderRadius: 6,
  maxWidth: 'calc(100% - 32px)',
  overflow: 'visible',
  position: 'absolute',
  shadowColor: 'rgb(73,81,89)',
  shadowOpacity: 0.5,
  shadowOffset: {
    height: 2,
    width: 0,
  },
  shadowRadius: 4,
}));

const popperOptions: Partial<Options> = {
  modifiers: [
    {
      name: 'preventOverflow',
      options: {
        padding: 16,
      },
    },
  ],
};

const inside = (target: EventTarget | null) => (element: HTMLElement | null) => {
  if (!element || !(target instanceof Node)) {
    return false;
  }

  return element.contains(target);
};

export default function DateTimeInput({
  style,
  mode,
  fadeDate,
  value,
  label,
  onChange,
  inline,
  isFocused,
  onFocused,
  ...props
}: Props) {
  const [pickerType, setPickerType] = useState<'date' | 'time' | false>(false);
  const [referenceDateElement, setReferenceDateElement] = React.useState<HTMLSpanElement | null>(
    null,
  );
  const [popperDateElement, setPopperDateElement] = React.useState<HTMLDivElement | null>(null);
  const [referenceTimeElement, setReferenceTimeElement] = React.useState<HTMLSpanElement | null>(
    null,
  );
  const [popperTimeElement, setPopperTimeElement] = React.useState<HTMLDivElement | null>(null);
  const {
    styles: { popper: popperDateStyles, reference: referenceDateStyles },
    attributes: dateAttributes,
    update: updateDatePopper,
  } = usePopper(referenceDateElement, popperDateElement, popperOptions);
  const {
    styles: { popper: popperTimeStyles, reference: referenceTimeStyles },
    attributes: timeAttributes,
    update: updateTimePopper,
  } = usePopper(referenceTimeElement, popperTimeElement, popperOptions);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const focusedCb = useCallback(onFocused, []);

  const listener = useCallback(
    (ev: Event) => {
      const containsTarget = inside(ev.target);
      const pickerElements = [
        referenceDateElement,
        referenceTimeElement,
        popperTimeElement,
        popperDateElement,
      ];

      if (pickerElements.some(containsTarget)) {
        return;
      }

      setPickerType(false);
    },
    [popperDateElement, popperTimeElement, referenceDateElement, referenceTimeElement],
  );

  useEffect(() => {
    document.addEventListener('click', listener);

    return () => document.removeEventListener('click', listener);
  }, [listener]);

  useEffect(() => {
    if (!isFocused) {
      setPickerType(false);
    }
  }, [isFocused]);

  useEffect(() => {
    if (pickerType) {
      focusedCb();
    }
  }, [focusedCb, pickerType]);

  const handleDateChange = (newDate: Date | null) => {
    if (newDate === null) {
      return;
    }

    onChange(roundToNearestMinutes(newDate, { nearestTo: props.minuteInterval || 1 }));
  };

  const handlePress = (type: 'date' | 'time') => () => {
    setPickerType((d) => (d === type ? false : type));
  };

  useEffect(() => {
    if (updateDatePopper && pickerType) void updateDatePopper();
    if (updateTimePopper && pickerType) void updateTimePopper();
  }, [pickerType, updateDatePopper, updateTimePopper]);

  return (
    <>
      <View style={[styles.row, style]}>
        <label style={StyleSheet.flatten(styles.labelText)} htmlFor="date">
          {label}
        </label>
        <View style={[styles.pickers]}>
          {(mode !== 'time' || !fadeDate) && (
            <span
              ref={setReferenceDateElement}
              style={{ ...referenceDateStyles, ...StyleSheet.flatten(styles.picker) }}
              {...dateAttributes.reference}
            >
              <TouchableRipple
                style={styles.touchable}
                rippleColor={CareshipColors.rippleColor}
                onPress={handlePress('date')}
              >
                <Title size="h4" thin>
                  <FormattedDate value={value} />
                </Title>
              </TouchableRipple>
              <Portal>
                <CalendarOverlay
                  open={pickerType === 'date'}
                  // @ts-ignore
                  ref={setPopperDateElement}
                  // @ts-ignore
                  style={popperDateStyles}
                  {...dateAttributes.popper}
                >
                  <DatePicker
                    autoOk
                    disableToolbar
                    disabled={pickerType !== 'time'}
                    format="d.MM.yyyy"
                    value={value}
                    onChange={handleDateChange}
                    onClose={() => setPickerType(false)}
                    variant="static"
                  />
                </CalendarOverlay>
              </Portal>
            </span>
          )}
          {mode !== 'date' && (
            <View style={styles.picker}>
              <span
                ref={setReferenceTimeElement}
                style={{ ...referenceTimeStyles, ...StyleSheet.flatten(styles.picker) }}
                {...timeAttributes.reference}
              >
                <TouchableRipple
                  style={styles.touchable}
                  rippleColor={CareshipColors.rippleColor}
                  onPress={handlePress('time')}
                >
                  <Title size="h4" thin>
                    <FormattedTime value={value} />
                  </Title>
                </TouchableRipple>
                <Portal>
                  <CalendarOverlay
                    open={pickerType === 'time'}
                    // @ts-ignore
                    ref={setPopperTimeElement}
                    // @ts-ignore
                    style={popperTimeStyles}
                    {...timeAttributes.popper}
                  >
                    {pickerType === 'time' && ( // remounting the component to reset it to hour view
                      <TimePicker
                        autoOk
                        disableToolbar
                        openTo="hours"
                        disabled={pickerType !== 'time'}
                        ampm={false}
                        value={value}
                        minutesStep={5}
                        variant="static"
                        onChange={handleDateChange}
                        onClose={() => setPickerType(false)}
                      />
                    )}
                  </CalendarOverlay>
                </Portal>
              </span>
            </View>
          )}
        </View>
      </View>
      {!inline && <Hr noMargins solid hairline />}
    </>
  );
}
