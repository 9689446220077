import { View } from 'react-native';
import React, { ReactNode } from 'react';
import { CareshipColors } from '../CSTheme';

type RoundIconProps = {
  warning?: boolean;
  children: ReactNode;
};
export default function RoundIcon({ warning, children }: RoundIconProps) {
  return (
    <View
      style={{
        backgroundColor: warning ? CareshipColors.red : CareshipColors.secondary,
        borderRadius: 32,
        padding: 2,
        marginRight: 8,
        marginVertical: 8,
      }}
    >
      {children}
    </View>
  );
}
