import { FormattedMessage } from 'react-intl';
import React from 'react';
import { Text, ViewProps } from 'react-native';
import useBookings from '../../../Context/Booking/Hook/useBookings';
import { Tab, TabRow } from '../../Component';
import { events, trackEvent } from '../../../Infrastructure/Tracking/Tracking';

type FilterValue = 'past' | 'upcoming';

export default function BookingTabs({
  filter,
  onFilterChange,
  style,
}: {
  filter: FilterValue;
  onFilterChange: (value: FilterValue) => void;
  style?: ViewProps['style'];
}) {
  const handleButtonPress = (value: FilterValue) => () => {
    onFilterChange(value);
    trackEvent(events.BOOKING_LIST_TAB_CHANGE, {
      tab: value,
    });
  };
  const { undocumentedBookingCount } = useBookings();

  return (
    <>
      <TabRow style={style}>
        <Tab active={filter === 'upcoming'} onPress={handleButtonPress('upcoming')}>
          <FormattedMessage
            id="UPCOMING_CUSTOMERS"
            tagName={Text}
            defaultMessage="Upcoming"
            description="Upcoming button label"
          />
        </Tab>
        <Tab
          active={filter === 'past'}
          onPress={handleButtonPress('past')}
          badgeCount={undocumentedBookingCount}
        >
          <FormattedMessage
            tagName={Text}
            id="PAST"
            defaultMessage="Past"
            description="Past button label"
          />
        </Tab>
      </TabRow>
    </>
  );
}
