import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';
import { CareshipColors } from '../CSTheme';

interface AwardIconProps extends SvgProps {
  color?: string;
  size?: number;
}

function AwardIcon({ color = CareshipColors.slate400, size = 24, ...props }: AwardIconProps) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" {...props}>
      <Path
        fill={color}
        d="M12.2 1c4 0 7.2 3.2 7.2 7.2 0 2.2-1 4.1-2.5 5.5l1 8.6c.1.5-.5 1-1 .8h-.1L12.2 21l-4.7 2.1c-.5.2-1.1-.1-1.1-.7v-.1l1-8.6C6 12.4 5 10.4 5 8.2 5 4.2 8.2 1 12.2 1zm3.4 13.7c-1 .5-2.2.8-3.4.8s-2.4-.3-3.4-.8l-.7 6.5 3.8-1.7c.2-.1.3-.1.5 0h.1l3.8 1.7-.7-6.5zM12.2 2.5C9 2.5 6.4 5.1 6.4 8.3S9.1 14 12.2 14 18 11.4 18 8.2s-2.6-5.7-5.8-5.7z"
      />
    </Svg>
  );
}

export default AwardIcon;
