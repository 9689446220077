import { useCallback } from 'react';
import { definitionsTokenStore, userTokenStore } from '../Auth/OAuthTokenStore';
import { asyncResetStores } from '../Store';
import { events, trackEvent } from '../Tracking/Tracking';
import Sentry from '../ErrorTracking/Sentry';

export const logout = async () => {
  await userTokenStore.clearTokenData();
  await definitionsTokenStore.clearTokenData();
  await asyncResetStores();
  trackEvent(events.USER_LOGGED_OUT);
  Sentry.setUser(null);
};

export default function useHandleLogout() {
  return useCallback(() => logout(), []);
}
