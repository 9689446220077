import { ViewProps } from 'react-native';
import styled from '@emotion/native';
import { CareshipColors } from './CSTheme';

interface RowProps extends ViewProps {
  vertical?: boolean;
}

export default styled.View<RowProps>(({ theme: { spacing }, vertical }) => [
  spacing({
    marginHorizontal: 'base',
  }),
  {
    minHeight: 64,
    borderBottomColor: CareshipColors.slate100,
    borderBottomWidth: 1,
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 20,
  },
  vertical && {
    paddingVertical: 16,
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
]);
