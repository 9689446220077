import React, { ReactElement, useContext, ReactNode } from 'react';
import { RadioButton } from 'react-native-paper';
import { View, StyleProp, ViewStyle, StyleSheet } from 'react-native';
import CSTheme from '../CSTheme';
import { RadioButtonContext } from './RadioButtonGroup';
import TouchableFormRow from './TouchableFormRow';
import Label from './Label';

type Props = {
  value: string;
  label: ReactElement | string;
  children?: ReactNode;
  style?: StyleProp<ViewStyle>;
  testID?: string;
};

const styles = StyleSheet.create({
  formRow: {
    display: 'flex',
    flexDirection: 'column',
  },
  formRowContent: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  label: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  labelWrapper: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
});

export default function RadioButtonItem({ label, value, children, style, testID }: Props) {
  const radioButtonContext = useContext(RadioButtonContext);

  if (!radioButtonContext) {
    throw new Error('RadioButtonItem must be used within a RadioButtonGroup.');
  }

  return (
    <TouchableFormRow
      style={[styles.formRow, style]}
      onPress={() => radioButtonContext.onValueChange(value)}
      testID={testID}
    >
      <View style={styles.formRowContent}>
        <View style={styles.label}>
          <RadioButton.Android color={CSTheme.colors.primary} value={value} />
          <View style={styles.labelWrapper}>
            <Label active={radioButtonContext.value === value}>{label}</Label>
          </View>
        </View>
        {children}
      </View>
    </TouchableFormRow>
  );
}
