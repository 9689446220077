import React, { useEffect } from 'react';
import { useQueryLoader } from 'react-relay/hooks';
import { Layout } from '../../Component';
import { intl } from '../../../Infrastructure/Intl';
import ExperienceAndAbilitiesForm, {
  experienceAndAbilitiesFormQuery,
} from '../Component/ExperienceAndAbilitiesForm';
import { ExperienceAndAbilitiesFormQuery } from '../Component/__generated__/ExperienceAndAbilitiesFormQuery.graphql';

export default function ExperienceAndAbilitiesScreen() {
  const [preloadedQuery, loadQuery] = useQueryLoader<ExperienceAndAbilitiesFormQuery>(
    experienceAndAbilitiesFormQuery,
  );

  useEffect(() => {
    loadQuery(
      { locale: intl.locale.startsWith('en') ? 'EN' : 'DE' },
      {
        fetchPolicy: 'store-and-network',
      },
    );
  }, [loadQuery]);

  return (
    <Layout>
      {preloadedQuery && <ExperienceAndAbilitiesForm preloadedQuery={preloadedQuery} />}
    </Layout>
  );
}
