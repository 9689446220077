import styled from '@emotion/native';
import React, { ReactNode, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { View } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { TouchableRipple } from 'react-native-paper';
import { Feather } from '@expo/vector-icons';
import {
  ArrowDownIcon,
  ArrowRightIcon,
  BulletIcon,
  Edit3Icon,
  PrinterIcon,
  UploadShareIcon,
} from '../../Component/Icon';
import { ButtonScrollTop, Card, CSTheme, Text } from '../../Component';
import { WithoutTheme } from '../../Root/Theme/WithoutTheme';
import useBreakpoints from '../../Root/Theme/useBreakpoints';

const Description = styled<WithoutTheme<typeof Text>>(Text)(({ theme: { breakpoints } }) => ({
  marginTop: 32,
  marginHorizontal: 16,
  marginBottom: 8,
  ...breakpoints.up('sm', {
    marginBottom: 0,
  }),
}));

const StepCard = styled(Card)(({ theme: { colors, breakpoints } }) => ({
  backgroundColor: colors.slate050,
  borderRadius: 8,
  marginTop: 16,
  ...breakpoints.up('sm', {
    marginTop: 24,
  }),
}));

const StepCardTitle = styled(View)({
  marginBottom: 8,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
});

const StepCardTitleIcon = styled(View)({
  marginRight: 8,
});

const StepCardItemContent = styled(View)({
  display: 'flex',
  flexDirection: 'row',
  marginTop: 16,
});

const StepCardItemIcon = styled(View)({
  marginRight: 16,
});

const StepCardItemChildren = styled(View)({
  flex: 1,
});

const StepCardItemTextSecondary = styled<WithoutTheme<typeof Text>>(Text)(({ theme }) => ({
  color: theme.colors.secondary,
}));

const ArrowDownIconContainer = styled(View)(({ theme: { breakpoints } }) => ({
  display: 'flex',
  alignItems: 'center',
  marginVertical: 16,
  ...breakpoints.up('sm', {
    marginVertical: 32,
  }),
}));

const WhatHappensNowTitleContainer = styled(View)({
  display: 'flex',
  alignItems: 'center',
  marginBottom: 16,
});

const WhatHappensNowTitle = styled(Text)({
  maxWidth: 340,
  display: 'flex',
  justifyContent: 'center',
  textAlign: 'center',
});

const WhatHappensNowSubtitle = styled<WithoutTheme<typeof Text>>(Text)(({ theme }) => ({
  color: theme.colors.secondary,
  marginTop: 24,
}));

const AccentText = styled<WithoutTheme<typeof Text>>(Text)(({ theme }) => ({
  color: theme.colors.secondary,
}));

const ButtonScrollTopContainer = styled(View)({
  marginTop: 24,
  marginBottom: 16,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
});

const StepCardLink = styled(View)(({ theme: { breakpoints } }) => ({
  flexDirection: 'column',
  ...breakpoints.up('sm', {
    flexDirection: 'row',
  }),
}));

const StepCardLinkTouchable = styled(TouchableRipple)({
  marginTop: 16,
  marginBottom: 8,
});

const StepCardLinkContent = styled(View)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
});

const StepCardLinkText = styled<WithoutTheme<typeof Text>>(Text)({
  textDecorationLine: 'underline',
  flex: 1,
});

interface StepCardItemProps {
  itemIcon?: ReactNode;
  children: ReactNode;
  halfMarginTop?: boolean;
}

function StepCardItem({ children, itemIcon = <BulletIcon />, halfMarginTop }: StepCardItemProps) {
  return (
    <StepCardItemContent style={halfMarginTop ? { marginTop: 8 } : { marginTop: 16 }}>
      <StepCardItemIcon>{itemIcon}</StepCardItemIcon>
      <StepCardItemChildren>{children}</StepCardItemChildren>
    </StepCardItemContent>
  );
}

interface ServiceDocumetationHowToProps {
  onPresshowToFillServiceDocumentation: () => void;
}

export default function ServiceDocumetationHowTo({
  onPresshowToFillServiceDocumentation,
}: ServiceDocumetationHowToProps) {
  const scrollViewRef = useRef<ScrollView>(null);
  const breakpoints = useBreakpoints();
  const smAndDown = breakpoints.down('sm');

  return (
    <ScrollView ref={scrollViewRef}>
      <Description size="large">
        <FormattedMessage id="SERVICE_DOCUMENTATION_HOW_TO_DESCRIPTION" />
      </Description>
      <StepCard padding="base" marginHorizontal="base">
        <StepCardTitle>
          <StepCardTitleIcon>
            <PrinterIcon accentColor={CSTheme.colors.primary} />
          </StepCardTitleIcon>
          <Text size="large" bold>
            <FormattedMessage
              id="SERVICE_DOCUMENTATION_HOW_TO_PRINT"
              values={{ element: 'title' }}
            />
          </Text>
        </StepCardTitle>
        <Text size="large">
          <FormattedMessage
            id="SERVICE_DOCUMENTATION_HOW_TO_PRINT"
            values={{ element: 'description' }}
          />
        </Text>
      </StepCard>
      <StepCard padding="base" marginHorizontal="base">
        <StepCardTitle>
          <StepCardTitleIcon>
            <Edit3Icon accentColor={CSTheme.colors.primary} />
          </StepCardTitleIcon>
          <Text size="large" bold>
            <FormattedMessage
              id="SERVICE_DOCUMENTATION_HOW_TO_FILL"
              values={{ element: 'title' }}
            />
          </Text>
        </StepCardTitle>
        <Text size="large">
          <FormattedMessage
            id="SERVICE_DOCUMENTATION_HOW_TO_FILL"
            values={{ element: 'description' }}
          />
        </Text>
        <StepCardLink>
          <StepCardLinkTouchable onPress={onPresshowToFillServiceDocumentation}>
            <StepCardLinkContent>
              <StepCardLinkText size="large">
                <FormattedMessage
                  id="SERVICE_DOCUMENTATION_HOW_TO_FILL"
                  values={{ element: 'link' }}
                />
              </StepCardLinkText>
              {smAndDown(true) && (
                <Feather name="chevron-right" color={CSTheme.colors.primary} size={24} />
              )}
            </StepCardLinkContent>
          </StepCardLinkTouchable>
        </StepCardLink>
        <StepCardItem>
          <Text size="large">
            <Text size="large" bold>
              <FormattedMessage
                id="SERVICE_DOCUMENTATION_HOW_TO_FILL"
                values={{
                  element: 'firstItemBold',
                }}
              />
            </Text>
            <FormattedMessage
              id="SERVICE_DOCUMENTATION_HOW_TO_FILL"
              values={{
                element: 'firstItem',
              }}
            />
          </Text>
        </StepCardItem>
        <StepCardItem>
          <Text size="large">
            <Text size="large" bold>
              <FormattedMessage
                id="SERVICE_DOCUMENTATION_HOW_TO_FILL"
                values={{
                  element: 'secondItemBold',
                }}
              />
            </Text>
            <FormattedMessage
              id="SERVICE_DOCUMENTATION_HOW_TO_FILL"
              values={{
                element: 'secondItem',
              }}
            />
          </Text>
          <Text>
            <StepCardItemTextSecondary size="large" bold>
              <FormattedMessage
                id="SERVICE_DOCUMENTATION_HOW_TO_FILL"
                values={{
                  element: 'secondItemImportant',
                }}
              />
            </StepCardItemTextSecondary>
            <Text size="large">
              <FormattedMessage
                id="SERVICE_DOCUMENTATION_HOW_TO_FILL"
                values={{
                  element: 'secondItemImportantMessage',
                }}
              />
            </Text>
          </Text>
        </StepCardItem>
        <StepCardItem>
          <Text size="large">
            <Text size="large" bold>
              <FormattedMessage
                id="SERVICE_DOCUMENTATION_HOW_TO_FILL"
                values={{
                  element: 'thirdItemBold',
                }}
              />
            </Text>
            <FormattedMessage
              id="SERVICE_DOCUMENTATION_HOW_TO_FILL"
              values={{
                element: 'thirdItem',
              }}
            />
          </Text>
          <StepCardItem halfMarginTop itemIcon={<ArrowRightIcon />}>
            <Text size="large">
              <Text size="large" bold>
                <FormattedMessage
                  id="SERVICE_DOCUMENTATION_HOW_TO_FILL"
                  values={{
                    element: 'thirdItemFirstItemBold',
                  }}
                />
              </Text>
              <FormattedMessage
                id="SERVICE_DOCUMENTATION_HOW_TO_FILL"
                values={{
                  element: 'thirdItemFirstItem',
                }}
              />
            </Text>
          </StepCardItem>
          <StepCardItem halfMarginTop itemIcon={<ArrowRightIcon />}>
            <Text size="large">
              <Text size="large" bold>
                <FormattedMessage
                  id="SERVICE_DOCUMENTATION_HOW_TO_FILL"
                  values={{
                    element: 'thirdItemSecondItemBold',
                  }}
                />
              </Text>
              <FormattedMessage
                id="SERVICE_DOCUMENTATION_HOW_TO_FILL"
                values={{
                  element: 'thirdItemSecondItem',
                }}
              />
            </Text>
          </StepCardItem>
        </StepCardItem>
        <StepCardItem>
          <Text size="large">
            <Text size="large" bold>
              <FormattedMessage
                id="SERVICE_DOCUMENTATION_HOW_TO_FILL"
                values={{
                  element: 'fourthItemBold',
                }}
              />
            </Text>
            <FormattedMessage
              id="SERVICE_DOCUMENTATION_HOW_TO_FILL"
              values={{
                element: 'fourthItem',
              }}
            />
          </Text>
        </StepCardItem>
      </StepCard>
      <StepCard padding="base" marginHorizontal="base">
        <StepCardTitle>
          <StepCardTitleIcon>
            <UploadShareIcon accentColor={CSTheme.colors.primary} />
          </StepCardTitleIcon>
          <Text size="large" bold>
            <FormattedMessage
              id="SERVICE_DOCUMENTATION_HOW_TO_UPLOAD"
              values={{ element: 'title' }}
            />
          </Text>
        </StepCardTitle>
        <Text size="large">
          <Text size="large" bold>
            <FormattedMessage
              id="SERVICE_DOCUMENTATION_HOW_TO_UPLOAD"
              values={{
                element: 'descriptionBold',
              }}
            />
          </Text>
          <FormattedMessage
            id="SERVICE_DOCUMENTATION_HOW_TO_UPLOAD"
            values={{
              element: 'description',
            }}
          />
        </Text>
      </StepCard>
      <ArrowDownIconContainer>
        <ArrowDownIcon />
      </ArrowDownIconContainer>
      <Card marginHorizontal="base" marginVertical="base" marginTop="none">
        <WhatHappensNowTitleContainer>
          <WhatHappensNowTitle size="xlarge" bold>
            <FormattedMessage
              id="SERVICE_DOCUMENTATION_HOW_TO_WHAT_HAPPENS_NOW"
              values={{ element: 'title' }}
            />
          </WhatHappensNowTitle>
        </WhatHappensNowTitleContainer>
        <Text size="large">
          <FormattedMessage
            id="SERVICE_DOCUMENTATION_HOW_TO_WHAT_HAPPENS_NOW"
            values={{ element: 'description' }}
          />
        </Text>
        <WhatHappensNowSubtitle size="large" bold>
          <FormattedMessage
            id="SERVICE_DOCUMENTATION_HOW_TO_WHAT_HAPPENS_NOW"
            values={{ element: 'dirTitle' }}
          />
        </WhatHappensNowSubtitle>
        <Text size="large">
          <Text size="large" bold>
            <FormattedMessage
              id="SERVICE_DOCUMENTATION_HOW_TO_WHAT_HAPPENS_NOW"
              values={{ element: 'dirDescriptionBold' }}
            />
          </Text>
          <FormattedMessage
            id="SERVICE_DOCUMENTATION_HOW_TO_WHAT_HAPPENS_NOW"
            values={{ element: 'dirDescription' }}
          />
        </Text>
        <StepCardItem halfMarginTop>
          <Text size="large">
            <FormattedMessage
              id="SERVICE_DOCUMENTATION_HOW_TO_WHAT_HAPPENS_NOW"
              values={{ element: 'dirItemsFirst' }}
            />
          </Text>
        </StepCardItem>
        <StepCardItem halfMarginTop>
          <Text size="large">
            <FormattedMessage
              id="SERVICE_DOCUMENTATION_HOW_TO_WHAT_HAPPENS_NOW"
              values={{ element: 'dirItemsSecond' }}
            />
          </Text>
        </StepCardItem>
        <WhatHappensNowSubtitle size="large" bold>
          <FormattedMessage
            id="SERVICE_DOCUMENTATION_HOW_TO_WHAT_HAPPENS_NOW"
            values={{ element: 'customerPaymentTitle' }}
          />
        </WhatHappensNowSubtitle>
        <Text size="large">
          <Text size="large" bold>
            <FormattedMessage
              id="SERVICE_DOCUMENTATION_HOW_TO_WHAT_HAPPENS_NOW"
              values={{ element: 'customerPaymentDescriptionBold' }}
            />
          </Text>
          <FormattedMessage
            id="SERVICE_DOCUMENTATION_HOW_TO_WHAT_HAPPENS_NOW"
            values={{ element: 'customerPaymentDescription' }}
          />
        </Text>
        <StepCardItem halfMarginTop>
          <Text size="large">
            <FormattedMessage
              id="SERVICE_DOCUMENTATION_HOW_TO_WHAT_HAPPENS_NOW"
              values={{
                element: 'customerPaymentItem',
              }}
            />
            <AccentText size="large">
              <FormattedMessage
                id="SERVICE_DOCUMENTATION_HOW_TO_WHAT_HAPPENS_NOW"
                values={{
                  element: 'customerPaymentItemAccent',
                }}
              />
            </AccentText>
          </Text>
        </StepCardItem>
        <ButtonScrollTopContainer>
          <ButtonScrollTop scrollViewRef={scrollViewRef} />
        </ButtonScrollTopContainer>
      </Card>
    </ScrollView>
  );
}
