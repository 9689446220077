import React, { useEffect } from 'react';
import { useQueryLoader } from 'react-relay/hooks';
import SupportingDocumentList, {
  supportingDocumentListQuery,
} from '../Component/SupportingDocumentList';
import { SupportingDocumentListQuery } from '../Component/__generated__/SupportingDocumentListQuery.graphql';
import { Layout } from '../../Component';

export default function HelpCenterScreen() {
  const [queryReference, loadQuery] = useQueryLoader<SupportingDocumentListQuery>(
    supportingDocumentListQuery,
  );

  useEffect(() => {
    loadQuery(
      {},
      {
        fetchPolicy: 'store-and-network',
      },
    );
  }, [loadQuery]);

  return (
    <Layout>{queryReference && <SupportingDocumentList queryReference={queryReference} />}</Layout>
  );
}
