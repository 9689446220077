import { ThemeProvider } from '@emotion/react';
import React, { ReactNode } from 'react';
import useBreakpoints from './useBreakpoints';
import { CareshipColors as colors } from '../../Component/CSTheme';
import useSpacing, { Spacings, SpacingToken, spacingTokens } from './useSpacing';

interface EmotionThemeProviderProps {
  children?: ReactNode;
}

// TODO: apply responsive spacing to components with hardcoded margins
const margins = {
  none: {
    xxs: spacingTokens.none,
  },
  tight: {
    xxs: spacingTokens.xs,
    // xl: spacingTokens.sm,
  },
  base: {
    xxs: spacingTokens.md,
    // xl: spacingTokens.lg,
  },
  loose: {
    xxs: spacingTokens.lg,
    // xl: spacingTokens.xl,
  },
  extraLoose: {
    xxs: spacingTokens.xl,
    // xl: spacingTokens.xxl,
  },
} as {
  [key in Spacings]: Partial<Record<SpacingToken, number>>;
};

export default function EmotionThemeProvider({ children }: EmotionThemeProviderProps) {
  const breakpoints = useBreakpoints();
  const spacing = useSpacing(margins);

  return (
    <ThemeProvider theme={{ breakpoints, colors, spacing, spacingTokens }}>
      {children}
    </ThemeProvider>
  );
}
