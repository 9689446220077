import React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';
import { CareshipColors } from '../CSTheme';

type AccountingCoinsIconProps = SvgProps & {
  color?: string;
  size?: number;
};

export default function AccountingCoinsIcon({
  color = CareshipColors.primary,
  size = 24,
  ...props
}: AccountingCoinsIconProps) {
  return (
    <Svg width={size} height={size} viewBox="0 0 36 36" {...props}>
      <Path
        d="M16.992 25.563c-3.831 1.916-9.903 1.916-13.734 0-4.344-2.172-4.344-6.204 0-8.376 3.831-1.916 9.903-1.916 13.734 0 4.344 2.172 4.344 6.204 0 8.376zM4.264 19.2c-2.685 1.343-2.685 3.009 0 4.352 3.198 1.599 8.524 1.599 11.722 0 2.685-1.343 2.685-3.009 0-4.352-3.198-1.599-8.524-1.599-11.722 0z"
        fill={color}
      />
      <Path
        d="M0 21.375a1.125 1.125 0 012.25 0v9c0 1.652 3.446 3.375 7.875 3.375 4.43 0 7.875-1.723 7.875-3.375v-9a1.125 1.125 0 012.25 0v9c0 3.319-4.612 5.625-10.125 5.625S0 33.694 0 30.375v-9z"
        fill={color}
      />
      <Path
        d="M18 25.875a1.125 1.125 0 012.25 0c0 3.319-4.612 5.625-10.125 5.625S0 29.194 0 25.875a1.125 1.125 0 012.25 0c0 1.652 3.446 3.375 7.875 3.375 4.43 0 7.875-1.723 7.875-3.375z"
        fill={color}
      />
      <Path
        d="M23.625 24.75a1.125 1.125 0 010-2.25h9c.621 0 1.125-.504 1.125-1.125v-18c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v11.26a1.125 1.125 0 01-2.25 0V3.376A3.375 3.375 0 013.375 0h29.25A3.375 3.375 0 0136 3.375v18a3.375 3.375 0 01-3.375 3.375h-9z"
        fill={CareshipColors.slate400}
      />
      <Path
        d="M12.375 12.375a1.125 1.125 0 112.25 0h-2.25a5.625 5.625 0 119.336 4.228 1.125 1.125 0 11-1.484-1.691 3.375 3.375 0 10-5.602-2.537c0 1.5-2.25 1.5-2.25 0zM8.437 6.75a1.687 1.687 0 11-3.374 0 1.687 1.687 0 013.374 0zM30.938 18a1.687 1.687 0 11-1.688-1.687c.932 0 1.688.755 1.688 1.687z"
        fill={CareshipColors.slate400}
      />
    </Svg>
  );
}
