import styled from '@emotion/native';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { View, Image, ImageURISource, Platform, Pressable } from 'react-native';
import { Dialog as RNPDialog, Portal } from 'react-native-paper';
import { ScrollView } from 'react-native-gesture-handler';
import { CareshipColors } from '../../Component/CSTheme';
import { CheckCircleFilledIcon, CloseIcon, CrossCircleFilledIcon } from '../../Component/Icon';
import { Button, Title, Text } from '../../Component';
import { WithoutTheme } from '../../Root/Theme/WithoutTheme';
import useBreakpoints from '../../Root/Theme/useBreakpoints';

// eslint-disable-next-line global-require
const LNWBadImage = require('../Assets/images/LNW-bad.jpg') as ImageURISource;
// eslint-disable-next-line global-require
const LNWGoodImage = require('../Assets/images/LNW-good.jpg') as ImageURISource;

interface ServiceDocumentationInstructionDialogProps {
  visible: boolean;
  onAccept: () => void;
  onDismiss: () => void;
}

const DialogScrollView = styled(ScrollView)({
  display: 'flex',
  maxHeight: 580,
  flexGrow: 1,
  ...Platform.select({
    web: {
      maxHeight: 660,
    },
  }),
});

const DialogTitle = styled(Title)(({ theme: { breakpoints } }) => ({
  marginTop: 16,
  marginBottom: 32,
  marginHorizontal: 16,
  ...breakpoints.up('md', {
    marginTop: 32,
    marginBottom: 16,
    marginHorizontal: 32,
  }),
}));

const DialogText = styled<WithoutTheme<typeof Text>>(Text)(({ theme: { breakpoints } }) => ({
  marginBottom: 40,
  marginHorizontal: 16,
  ...breakpoints.up('md', {
    marginHorizontal: 32,
  }),
}));

const DialogFooterShadow = styled(View)({
  display: 'flex',
  flexDirection: 'row',
  shadowColor: 'rgb(0,0,0)',
  shadowOffset: {
    height: 0,
    width: 0,
  },
  shadowOpacity: 0.2,
  shadowRadius: 8,
  /* Adding borderTop instead of elevation since while running on Android shadows
    stay visible for a few ms after the overlay is closed */
  ...Platform.select({
    android: {
      borderTopColor: CareshipColors.slate100,
      borderTopWidth: 1,
    },
  }),
});

const DialogFooterContainer = styled(View)(({ theme: { breakpoints } }) => ({
  display: 'flex',
  flexDirection: 'row',
  backgroundColor: 'white',
  borderBottomLeftRadius: 8,
  borderBottomRightRadius: 8,
  flexGrow: 1,
  justifyContent: 'center',
  padding: 16,
  ...breakpoints.up('md', {
    padding: 24,
  }),
}));

const PhotosContainer = styled(View)(({ theme: { breakpoints } }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  ...breakpoints.up('md', {
    flexDirection: 'row',
  }),
  marginBottom: 32,
}));

const PhotoContainer = styled(View)({
  backgroundColor: CareshipColors.white,
  shadowColor: 'rgb(0,0,0)',
  shadowOpacity: 0.2,
  shadowOffset: {
    height: 8,
    width: 0,
  },
  shadowRadius: 8,
});

const PhotoContainerFirst = styled(PhotoContainer)(({ theme: { breakpoints } }) => ({
  marginBottom: 48,
  ...breakpoints.up('md', {
    marginBottom: 0,
  }),
}));

const PhotoContainerLast = styled(PhotoContainer)(({ theme: { breakpoints } }) => ({
  marginLeft: 0,
  ...breakpoints.up('md', {
    marginLeft: 48,
  }),
}));

const Photo = styled(Image)({
  width: 255,
  height: 340,
  resizeMode: 'contain',
});

const PhotoIcon = styled(View)({
  position: 'absolute',
  top: -21,
  right: -21,
  zIndex: 1,
  backgroundColor: CareshipColors.white,
  borderRadius: 44,
});

const Dialog = styled(RNPDialog)({
  maxWidth: 622,
  alignSelf: 'center',
});

const CloseIconContainer = styled(Pressable)({
  position: 'absolute',
  top: 8,
  right: 8,
  zIndex: 1,
  borderRadius: 24,
  backgroundColor: CareshipColors.white,
});

export default function ServiceDocumentationInstructionDialog({
  visible,
  onAccept,
  onDismiss,
}: ServiceDocumentationInstructionDialogProps) {
  const breakpoints = useBreakpoints();
  const mdDown = breakpoints.down('md');

  // Hack to set the position of the modal, setTimeout to wait for the dom update
  useEffect(() => {
    if (Platform.OS !== 'web') {
      return;
    }

    setTimeout(() => {
      const modal = document.querySelector('[aria-label="Close modal"]');

      if (modal && modal.parentElement) {
        modal.parentElement.style.position = 'fixed';
      }
    });
  }, [visible]);

  return (
    <Portal>
      <Dialog visible={visible} onDismiss={onDismiss}>
        <View>
          <CloseIconContainer onPress={onDismiss}>
            <CloseIcon color={CareshipColors.slate300} />
          </CloseIconContainer>
          <DialogScrollView>
            <DialogTitle size={mdDown(true) ? 'h4' : 'h1'} center>
              <FormattedMessage id="SERVICE_DOCUMENTATION_INSTRUCTION_TITLE" />
            </DialogTitle>
            <DialogText>
              <FormattedMessage id="SERVICE_DOCUMENTATION_INSTRUCTION_TEXT" />
            </DialogText>
            <PhotosContainer>
              <PhotoContainerFirst>
                <PhotoIcon>
                  <CrossCircleFilledIcon color={CareshipColors.red} size={43} />
                </PhotoIcon>
                <Photo source={LNWBadImage} />
              </PhotoContainerFirst>
              <PhotoContainerLast>
                <PhotoIcon>
                  <CheckCircleFilledIcon color={CareshipColors.secondary} size={43} />
                </PhotoIcon>
                <Photo source={LNWGoodImage} />
              </PhotoContainerLast>
            </PhotosContainer>
          </DialogScrollView>
          <DialogFooterShadow>
            <DialogFooterContainer>
              <Button size="sm" onPress={onAccept}>
                <FormattedMessage id="SERVICE_DOCUMENTATION_INSTRUCTION_BUTTON" />
              </Button>
            </DialogFooterContainer>
          </DialogFooterShadow>
        </View>
      </Dialog>
    </Portal>
  );
}
