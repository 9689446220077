import { NavigationState } from '@react-navigation/routers';
import { useRef } from 'react';
import { events, trackEvent } from '../Tracking/Tracking';

const getActiveRouteName = (state: NavigationState | undefined): string => {
  if (!state) {
    return '';
  }

  const route = state.routes[state.index];

  if (route.state) {
    return getActiveRouteName(route.state as NavigationState);
  }

  return route.name;
};

export default function useScreenTracking() {
  const routeNameRef = useRef<string>();

  function handleStateChange(state?: NavigationState): void {
    const previousRouteName = routeNameRef.current;
    const currentRouteName = getActiveRouteName(state);

    if (previousRouteName !== currentRouteName) {
      trackEvent(events.NAVIGATION_CHANGE, {
        previousRouteName,
        currentRouteName,
      });
    }

    // Save the current route name for later comparision
    routeNameRef.current = currentRouteName;
  }

  return handleStateChange;
}
