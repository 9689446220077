import React, { useState, Suspense } from 'react';
import styled from '@emotion/native';
import { useScreenOptions } from '../../Infrastructure/Hook';
import { SidebarMenu } from './Navigation';
import LayoutColumns from './Layout/LayoutColumns';
import Content from './Layout/Content';
import { Button, CareshipLogo, Title } from './index';
import { CareshipColors } from './CSTheme';
import { MenuIcon, CloseIcon } from './Icon';
import SuspenseFullScreenFallback from './SuspenseFullScreenFallback';
import { LayoutProps } from './Layout';

const TopBar = styled.View(({ theme: { breakpoints } }) => [
  {
    // Targets web, position: 'fixed' is valid
    position: 'fixed' as unknown as 'absolute',
    width: '100%',
    zIndex: 10,
    flexDirection: 'row',
    height: 72,
    backgroundColor: CareshipColors.slate400,
    justifyContent: 'space-between',
    paddingHorizontal: 16,
    alignItems: 'center',
  },
  breakpoints.up('md', {
    display: 'none',
  }),
]);

const PageTitleContainer = styled.View({
  marginTop: 32,
  marginHorizontal: 16,
});

export default function Layout({ children }: LayoutProps) {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const { title, headerShown } = useScreenOptions();

  function toggleMenu() {
    setIsMenuOpen(!isMenuOpen);
  }

  return (
    <>
      <TopBar>
        <CareshipLogo width={113} color={CareshipColors.white} />
        <Button mode="text" compact color="transparent" onPress={() => toggleMenu()}>
          {isMenuOpen ? (
            <CloseIcon color={CareshipColors.white} />
          ) : (
            <MenuIcon color={CareshipColors.white} />
          )}
        </Button>
      </TopBar>
      <LayoutColumns>
        <SidebarMenu open={isMenuOpen} />
        <Content menuOpen={isMenuOpen}>
          <Suspense fallback={<SuspenseFullScreenFallback />}>
            {headerShown && (
              <PageTitleContainer>
                <Title size="h1">{title}</Title>
              </PageTitleContainer>
            )}
            {children}
          </Suspense>
        </Content>
      </LayoutColumns>
    </>
  );
}
