import { ScrollView, View } from 'react-native';
import { FormattedMessage } from 'react-intl';
import React, { useCallback, useState } from 'react';
import { Feather } from '@expo/vector-icons';
import {
  Button,
  Container,
  FormGroupTitle, Hint,
  PressableWithConfirmation, RadioButtonGroup, RadioButtonItem,
} from '../../../Component';
import styles from './CancelBookingForm.styles';
import Booking from '../../../../Context/Booking/Store/Model/Booking';
import { NonNullableProperties } from '../../../../Infrastructure/types/NonNullableProperties';
import { ErrorResponse } from '../../../../Infrastructure/Service';
import {useCancelBooking, useDeleteBooking} from '../../Hook';
import CareReceiver from '../../../../Context/Contract/Store/Model/CareReceiver';
import Name from '../../../../Context/Contract/Store/Model/Name';
import { intl } from '../../../../Infrastructure/Intl';
import useProfile from "../../../../Context/Profile/Hook/useProfile";
import {TextInput} from "react-native-paper";
import useCustomerBookingCancellationReasons
  from "../../../../Context/Booking/Hook/useCustomerBookingCancellationReasons";
import useCaregiverBookingCancellationReasons
  from "../../../../Context/Booking/Hook/useCaregiverBookingCancellationReasons";

type FormData = {
  bookingId: string | null;
  cancellation_type: string | null,
  cancellation_reason: string | null,
  extraText: string | null
};

type Props = {
  onSubmit: ({ bookingId, cancellation_type, cancellation_reason, extraText }: NonNullableProperties<FormData>) => void;
  booking: Pick<Booking, 'startDate' | 'endDate' | 'bookingId' | 'isFirstBooking'>;
  careReceiver: Pick<CareReceiver, 'gender'> & { name: Pick<Name, 'lastName'> };
  isLoading?: boolean;
  error?: ErrorResponse;
  onBookingDeleted: () => void;

};

function validate(data: FormData): data is NonNullableProperties<FormData> {
  return data.bookingId !== null;
}

export default function CancelBookingForm({
  isLoading,
  error,
  booking,
  onSubmit,
  onBookingDeleted,
  careReceiver
}: Props) {
  const { isDeleting, deleteBooking } = useDeleteBooking(booking);

  const [otherServiceDescription, setOtherServiceDescription] = useState('');
  const [fieldValue, setFieldValue] = useState('Caregiver');
  const [reasonFieldValue, setReasonFieldValue] = useState('');

  const customerCancellationReasons = useCustomerBookingCancellationReasons();
  const caregiverCancellationReasons = useCaregiverBookingCancellationReasons();

  const formData = {
    bookingId: booking.bookingId,
    cancellation_type: fieldValue,
    cancellation_reason: reasonFieldValue,
    extraText: otherServiceDescription
  };
  const { isCancelling, cancelBooking } = useCancelBooking(booking, fieldValue, reasonFieldValue, otherServiceDescription);

  const handleCancel = useCallback<() => void>(async () => {

    if (isCancelling) {
      return;
    }

    if(formData.cancellation_reason.trim() == '' || formData.cancellation_type.trim() == '')
    {
      alert('Please select a reason');
      return;
    }

    if((formData.cancellation_reason.trim() == 'customer_andere' || formData.cancellation_reason.trim() == 'caregiver_andere') && formData.extraText.trim() == '' )
    {
      alert('please provide a reason');
      return;
    }
    if (await cancelBooking()) {
      onBookingDeleted();
    }
  }, [cancelBooking, isCancelling, onBookingDeleted]);


  console.log(customerCancellationReasons)
  return (
    <ScrollView>
      <View style={[styles.group]}>
        <Container>
          <FormattedMessage tagName={FormGroupTitle} id="APPOINTMENT_CANCELLATION_PERSON_LABEL" />
          {/*<Hint hint={<FormattedMessage id="APPOINTMENT_SERVICES_HINT" />} />*/}

        <RadioButtonGroup value={fieldValue} onValueChange={(value) => {setFieldValue(value); setReasonFieldValue(''), setOtherServiceDescription('')} }>
          <RadioButtonItem
            key={'Caregiver'}
            value={'Caregiver'}
            label={intl.formatMessage({ id: 'CAREGIVER_CANCELLED_BOOKING' })}
          />
          <RadioButtonItem
            key={'Customer'}
            value={'Customer'}
            label={intl.formatMessage({ id: 'CUSTOMER_CANCELLED_BOOKING' })}
          />
        </RadioButtonGroup>
        </Container>
        <Container>
          <FormattedMessage tagName={FormGroupTitle} id="APPOINTMENT_CANCELATION_REASONS_LABEL" />
          {/*<Hint hint={<FormattedMessage id="APPOINTMENT_CANCELATION_REASONS_HINT" />} />*/}


        {customerCancellationReasons && fieldValue == 'Customer' &&  (
          <RadioButtonGroup value={reasonFieldValue} onValueChange={(value) => setReasonFieldValue(value)}>
            {customerCancellationReasons.map((opt) => (
              <RadioButtonItem
                key={opt}
                value={opt}
                label={intl.formatMessage({ id: opt.toUpperCase().trim() })}
              />
            ))}
          </RadioButtonGroup>
        )}

        {caregiverCancellationReasons && fieldValue == 'Caregiver' && (
          <RadioButtonGroup value={reasonFieldValue} onValueChange={(value) => setReasonFieldValue(value)}>
            {caregiverCancellationReasons.map((opt) => (
              <RadioButtonItem
                key={opt}
                value={opt}
                label={intl.formatMessage({ id: opt.toUpperCase().trim() })}
              />
            ))}
          </RadioButtonGroup>
        )}
        </Container>
        <Container>
        {reasonFieldValue == 'customer_andere' && fieldValue == 'Customer' && (
          <View
            style={{
              borderTopColor: 'rgba(255,179,0,0.15)',
              borderTopWidth: 1,
            }}
          >
            <TextInput
              accessibilityLabel="Text input field"
              accessibilityHint={intl.formatMessage({ id: 'OTHER_SERVICES_LABEL' })}
              textAlign="left"
              multiline
              style={{
                marginTop: -1,
                paddingHorizontal: 16,
                paddingBottom: 8,
                backgroundColor: 'rgba(255,179,0,0.1)',
              }}
              underlineColor="rgba(255,179,0,0.15)"
              label={intl.formatMessage({ id: 'OTHER_SERVICES_LABEL' })}
              theme={{ roundness: 0 }}
              maxLength={100}
              onChangeText={(text) => setOtherServiceDescription(text)}
              value={otherServiceDescription}
            />
          </View>
        )}


        {reasonFieldValue == 'caregiver_andere' && fieldValue == 'Caregiver' && (

            <View
            style={{
              borderTopColor: 'rgba(255,179,0,0.15)',
              borderTopWidth: 1,
            }}
          >
            <TextInput
              accessibilityLabel="Text input field"
              accessibilityHint={intl.formatMessage({ id: 'OTHER_REASONS_LABEL' })}
              textAlign="left"
              multiline
              style={{
                marginTop: -1,
                paddingHorizontal: 16,
                paddingBottom: 8,
                backgroundColor: 'rgba(255,179,0,0.1)',
              }}
              underlineColor="rgba(255,179,0,0.15)"
              label={intl.formatMessage({ id: 'OTHER_REASONS_LABEL' })}
              theme={{ roundness: 0 }}
              maxLength={100}
              onChangeText={(text) => setOtherServiceDescription(text)}
              value={otherServiceDescription}
            />
          </View>

        )}
        </Container>
        <Container>
          <PressableWithConfirmation
            dialogTitle={intl.formatMessage({ id: 'BOOKING_CANCEL_CONFIRM_TITLE' })}
            dialogContent={intl.formatMessage(
              { id: 'BOOKING_CANCELLATION_CONFIRM_MESSAGE' }
            )
            }
            confirmButtonLabel={intl.formatMessage({ id: 'BOOKING_CANCEL_CONFIRM_SUBMIT' })}
            abortButtonLabel={intl.formatMessage({ id: 'BUTTON_CANCEL' })}
          >

            <Button
              style={styles.submitButton}
              loading={isCancelling}
              onPress={handleCancel}
              disabled={isCancelling }
              icon={<Feather color="white" size={32} name="check" />}
            >
              <FormattedMessage id="BUTTON_CANCEL" />
            </Button>
          </PressableWithConfirmation>
        </Container>
      </View>

    </ScrollView>
  );
}
