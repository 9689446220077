import React, { ComponentProps } from 'react';
import Svg, { Line } from 'react-native-svg';
import { css } from '@emotion/native';
import { CareshipColors } from './CSTheme';

type HrProps = {
  color?: string;
  size?: number;
  bleed?: boolean;
  solid?: boolean;
  hairline?: boolean;
  noMargins?: boolean;
  style?: ComponentProps<typeof Svg>['style'];
};

export default function Hr({
  hairline,
  solid,
  bleed,
  style,
  color = CareshipColors.slate200,
  noMargins,
  ...props
}: HrProps) {
  const height = hairline ? 1 : 2;

  const svgStyle = css({
    height: Math.max(1, height),
    marginVertical: noMargins ? 0 : 16,
    marginHorizontal: bleed ? 0 : 16,
  });

  return (
    <Svg
      height={Math.max(1, height)}
      style={[svgStyle, style]}
      vectorEffect="non-scaling-stroke"
      {...props}
    >
      <Line
        y1="100%"
        x1="-100%"
        x2="100%"
        stroke={color}
        strokeWidth={height}
        {...(solid
          ? {}
          : {
              strokeLinecap: 'butt',
              strokeDasharray: '4 4',
            })}
      />
    </Svg>
  );
}
