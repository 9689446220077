import React, { useCallback } from 'react';
import { StyleSheet, View } from 'react-native';
import { Feather } from '@expo/vector-icons';
import { TouchableRipple } from 'react-native-paper';
import { FormattedMessage } from 'react-intl';
import Proposal from '../../../Context/Proposal/Store/Model/Proposal';
import { Button, CSTheme, Text, Title } from '../../Component';
import { CareshipColors } from '../../Component/CSTheme';
import { Splash } from '../../Component/Icon';

const styles = StyleSheet.create({
  card: {
    borderRadius: 8,
    borderWidth: 2,
    borderColor: CareshipColors.slate400,
    marginBottom: 8,
  },
  cardFocused: {
    borderColor: CareshipColors.primary,
  },
  touchable: {
    padding: 16,
  },
  body: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  footer: {
    flexDirection: 'row',
    marginTop: 16,
  },
  addressLine: {
    flexDirection: 'row',
    paddingVertical: 8,
  },
  mapPin: {
    marginRight: 8,
  },
});

export default function ProposalCard({
  proposal: { careReceiver, serviceRequestId },
  onPress,
}: {
  proposal: Pick<Proposal, 'careReceiver' | 'serviceRequestId'>;
  onPress: (serviceRequestId: string) => void;
}) {
  const {
    address: { street, postalCode, city },
  } = careReceiver;

  const handlePress = useCallback(() => onPress(serviceRequestId), [onPress, serviceRequestId]);

  return (
    <View style={[styles.card, styles.cardFocused]} testID={serviceRequestId}>
      <TouchableRipple style={styles.touchable} onPress={handlePress}>
        <>
          <View style={styles.body}>
            <View>
              <Text uppercase>
                <FormattedMessage id="SERVICE_NEW" />
              </Text>
              <View style={styles.addressLine}>
                <Feather
                  name="map-pin"
                  size={24}
                  color={CSTheme.colors.text}
                  style={styles.mapPin}
                />
                <Title size="h3">{street || `${postalCode} ${city}`}</Title>
              </View>
            </View>
            <Splash color={CareshipColors.primary} size={48} name="user-plus" />
          </View>
          <View style={styles.footer}>
            <Button size="xs" color={CSTheme.colors.text} onPress={handlePress}>
              <FormattedMessage id="PROPOSAL_DISPLAY_DETAILS" />
            </Button>
          </View>
        </>
      </TouchableRipple>
    </View>
  );
}
