import { useState, useEffect } from 'react';
import { reaction } from 'mobx';
import { useStores } from '../../../Infrastructure/Store';
import useDefinitionService from '../../Definition/Hook/useDefinitionService';

export default function useConfirmationStatusUpdateReasons(excludeReason?: string): string[] {
  const { definitionStore } = useStores();
  const definitionsService = useDefinitionService();
  const [confirmationStatusUpdateReasons, setConfirmationStatusUpdateReasons] = useState<string[]>(
    [],
  );

  useEffect(
    () =>
      reaction(
        () => definitionStore.confirmationStatusUpdateReasons,
        (declineReasons) =>
          setConfirmationStatusUpdateReasons(
            Array.from(declineReasons).filter((reason) => reason !== excludeReason),
          ),
        {
          fireImmediately: true,
        },
      ),
    [definitionStore.confirmationStatusUpdateReasons, excludeReason],
  );

  useEffect(() => {
    void definitionsService.fetchConfirmationStatusUpdateReasons();
  }, [definitionsService]);

  return confirmationStatusUpdateReasons;
}
