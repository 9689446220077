import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';
import { CareshipColors } from '../CSTheme';

interface ClockIconProps extends SvgProps {
  color?: string;
  size?: number;
}

function ClockIcon({ color = CareshipColors.slate400, size = 24, ...props }: ClockIconProps) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" {...props}>
      <Path
        fill={color}
        d="M12 1.2C6.1 1.2 1.2 6.1 1.2 12S6.1 22.8 12 22.8 22.8 17.9 22.8 12 17.9 1.2 12 1.2zm0 1.6c5.1 0 9.2 4.1 9.2 9.2s-4.1 9.2-9.2 9.2-9.2-4.1-9.2-9.2S6.9 2.8 12 2.8zM12 5c.4 0 .7.3.7.6v6.3l2.8 2.6c.3.3.3.8.1 1.1-.3.3-.7.3-1 .1l-.1-.1-3.1-2.8v-.1l.1.1-.1-.1c-.1-.1-.1-.2-.2-.4V5.8c0-.5.4-.8.8-.8z"
      />
    </Svg>
  );
}

export default ClockIcon;
