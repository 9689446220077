/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import Constants from 'expo-constants';
import { Platform } from 'react-native';
import { Environment, RecordSource, Store } from 'relay-runtime';
import {
  RelayNetworkLayer,
  urlMiddleware,
  authMiddleware,
  cacheMiddleware,
  retryMiddleware,
  loggerMiddleware,
} from 'react-relay-network-modern';
import Manifest from '../Constants/Manifest';
import { logger } from '../Service';
import { services } from '../Service/ServiceProvider';

let environmentInstance: Environment | null = null;

const graphqlUrl = Constants?.expoConfig?.extra?.graphqlUrl;

const graphURI: string = Platform.OS === 'web' ? Manifest.extra.graphqlEndpoint : graphqlUrl;

const refresh = () => {
  logger.info('resetting relay environment');
  const source = new RecordSource();
  const store = new Store(source);

  const network = new RelayNetworkLayer([
    cacheMiddleware({
      size: 100, // max 500 requests
      ttl: 900000, // 15 minutes
    }),
    __DEV__
      ? loggerMiddleware({
        logger: (msg: string, data: any) => {
          logger.debug(`[RELAY-NETWORK] ${msg}`);
          logger.trace(`[RELAY-NETWORK-TRACE] ${msg}`, data);
        },
      })
      : null,
    urlMiddleware({
      url: () => Promise.resolve(graphURI),
    }),
    retryMiddleware({
      fetchTimeout: 120000,
      retryDelays: (attempt) => (2 ** attempt + 4) * 100, // exponential backoff
      beforeRetry: ({ forceRetry, abort, delay, attempt }) => {
        if (attempt > 7) abort();
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        (window as any).forceRelayRetry = forceRetry;
        logger.debug(`call \`forceRelayRetry()\` for immediately retry! Or wait ${delay} ms.`);
      },
      statusCodes: [500, 502, 503, 504],
    }),
    authMiddleware({
      allowEmptyToken: true,
      token: async () => services.oauthService.asyncGetToken().catch(() => ''),
      tokenRefreshPromise: async () => services.oauthService.asyncGetToken(),
    }),
  ]);

  environmentInstance = new Environment({
    network,
    store,
  });

  return environmentInstance;
};

const getInstance = () => environmentInstance || refresh();

export default {
  getEnvironment: getInstance.bind(this),
  resetEnvironment: refresh.bind(this),
};
