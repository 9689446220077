/**
 * @generated SignedSource<<ec4d89e1c5982261b7057971a21c5d71>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PaymentMethod = "invoice" | "sepa_direct_debit" | "direct_reimbursement" | "%future added value";
export type Status = "created" | "fulfilled" | "invoiced" | "%future added value";
export type ServiceDocumentationListQuery$variables = {
  contractId: string;
};
export type ServiceDocumentationListQuery$data = {
  readonly contract: {
    readonly bookings: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly status: Status;
          readonly startDate: string;
        };
      } | null> | null;
    };
    readonly serviceDocumentation: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly serviceDocumentationId: string;
          readonly year: string;
          readonly month: string;
          readonly lastUploadedFile: {
            readonly pathInStorage: string;
          } | null;
          readonly " $fragmentSpreads": FragmentRefs<"ServiceDocumentationRow_serviceDocumentationQuery">;
        };
      } | null> | null;
    };
    readonly paymentMethod: PaymentMethod;
  } | null;
};
export type ServiceDocumentationListQuery = {
  variables: ServiceDocumentationListQuery$variables;
  response: ServiceDocumentationListQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "contractId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "contractId",
    "variableName": "contractId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startDate",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "serviceDocumentationId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "year",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "month",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pathInStorage",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "paymentMethod",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ServiceDocumentationListQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Contract",
        "kind": "LinkedField",
        "name": "contract",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BookingConnection",
            "kind": "LinkedField",
            "name": "bookings",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "BookingEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Booking",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ServiceDocumentationConnection",
            "kind": "LinkedField",
            "name": "serviceDocumentation",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ServiceDocumentationEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ServiceDocumentation",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "ServiceDocumentationRow_serviceDocumentationQuery"
                      },
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "LastUploadedFile",
                        "kind": "LinkedField",
                        "name": "lastUploadedFile",
                        "plural": false,
                        "selections": [
                          (v7/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v8/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ServiceDocumentationListQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Contract",
        "kind": "LinkedField",
        "name": "contract",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BookingConnection",
            "kind": "LinkedField",
            "name": "bookings",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "BookingEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Booking",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v9/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ServiceDocumentationConnection",
            "kind": "LinkedField",
            "name": "serviceDocumentation",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ServiceDocumentationEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ServiceDocumentation",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v9/*: any*/),
                      (v6/*: any*/),
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "LastUploadedFile",
                        "kind": "LinkedField",
                        "name": "lastUploadedFile",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "filename",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "mimeType",
                            "storageKey": null
                          },
                          (v7/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v8/*: any*/),
          (v9/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "840c3ca58137b29700b6c2cbe9545d82",
    "id": null,
    "metadata": {},
    "name": "ServiceDocumentationListQuery",
    "operationKind": "query",
    "text": "query ServiceDocumentationListQuery(\n  $contractId: ID!\n) {\n  contract(contractId: $contractId) {\n    bookings {\n      edges {\n        node {\n          status\n          startDate\n          id\n        }\n      }\n    }\n    serviceDocumentation {\n      edges {\n        node {\n          ...ServiceDocumentationRow_serviceDocumentationQuery\n          serviceDocumentationId\n          year\n          month\n          lastUploadedFile {\n            pathInStorage\n          }\n          id\n        }\n      }\n    }\n    paymentMethod\n    id\n  }\n}\n\nfragment ServiceDocumentationRow_serviceDocumentationQuery on ServiceDocumentation {\n  id\n  month\n  serviceDocumentationId\n  lastUploadedFile {\n    filename\n    mimeType\n    pathInStorage\n  }\n}\n"
  }
};
})();

(node as any).hash = "3077e54d175a29304e3405f1737ccaff";

export default node;
