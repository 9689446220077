import { addMinutes, areIntervalsOverlapping } from 'date-fns';
import Booking from './Model/Booking';

export default function isOverlapping(
  bookings: {
    startDate: Date;
    endDate: Date;
  }[],
  { startDate: start, endDate: end }: Pick<Booking, 'startDate' | 'endDate'>,
) {
  return !!bookings.filter(({ startDate, endDate }) => {
    const adjustedEnd = startDate === endDate ? addMinutes(endDate, 5) : endDate;

    return areIntervalsOverlapping({ start, end }, { start: startDate, end: adjustedEnd });
  }).length;
}
