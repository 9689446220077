import * as Device from 'expo-device';
import * as WebBrowser from 'expo-web-browser';
import Manifest from '../../../Infrastructure/Constants/Manifest';

export default function useHandleFeedback() {
  const feedbackUrl =
    'https://docs.google.com/forms/d/e/1FAIpQLSczGoGflDGgRG7ffz43o2xmH_VxEPMr5STvsv-Rry5GyXmIdg/viewform?usp=pp_url&entry.1225903967=';

  const deviceInfo = {
    revision: String(Manifest.extra.commitHash),
    brand: Device.brand,
    manufacturer: Device.manufacturer,
    modelName: Device.modelName,
    modelId: String(Device.modelId),
    designName: Device.designName,
    productName: Device.productName,
    totalMemory: Device.totalMemory,
    osName: Device.osName,
    osVersion: Device.osVersion,
    osBuildId: Device.osBuildId,
    deviceName: Device.deviceName,
  };

  return () => {
    void WebBrowser.openBrowserAsync(feedbackUrl + encodeURI(JSON.stringify(deviceInfo)), {
      dismissButtonStyle: 'close',
    });
  };
}
