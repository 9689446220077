import { graphql } from 'react-relay';
import { useForm } from 'react-hook-form';
import { ioTsResolver } from '@hookform/resolvers/io-ts';
import { SpacedBoxProps } from '@emotion/react';
import React, { useCallback, useLayoutEffect, useRef } from 'react';
import { useFragment, useMutation } from 'react-relay/hooks';
import * as t from 'io-ts';
import * as tt from 'io-ts-types';
import { withMessage } from 'io-ts-types';
import { useNavigation } from '@react-navigation/native';
import { ScrollView, View } from 'react-native';
import { FormattedMessage } from 'react-intl';
import styled from '@emotion/native';
import { AboutMeFormFragment$key } from './__generated__/AboutMeFormFragment.graphql';
import {
  InputWithValidation,
  HeaderSaveButton,
  Container,
  Button,
  Card,
  Text,
} from '../../../Component';
import { intl } from '../../../../Infrastructure/Intl';
import { AccountStackNavigationProp } from '../../Navigator/AccountStackNavigationProp';
import { events, trackEvent } from '../../../../Infrastructure/Tracking/Tracking';
import StickyFooter from '../../../Component/StickyFooter';
import withSpacing, { shouldForwardProp } from '../../../Root/Theme/withSpacing';
import { AlertCircle } from '../../../Component/Icon';
import { CareshipColors } from '../../../Component/CSTheme';

interface AboutMeFormProps {
  fragmentRef: AboutMeFormFragment$key;
}

const nonEmptyString = withMessage(tt.NonEmptyString, () => 'VALIDATION_REQUIRED');

const schema = t.type({
  aboutMe: nonEmptyString,
  currentPosition: nonEmptyString,
});

const SaveButton = styled(Button, {
  shouldForwardProp,
})<SpacedBoxProps>(withSpacing({}));

interface AboutMeData {
  aboutMe: string;
  currentPosition: string;
}

export default function AboutMeForm({ fragmentRef }: AboutMeFormProps) {
  const navigation = useNavigation<AccountStackNavigationProp<'EditAboutMe'>>();
  const footerButtonsRef = useRef<View>(null);
  const { aboutMe, currentPosition } = useFragment(
    graphql`
      fragment AboutMeFormFragment on Caregiver {
        id
        aboutMe
        currentPosition
      }
    `,
    fragmentRef,
  );

  const [commitMutation, isInFlight] = useMutation<any>(
    graphql`
      mutation AboutMeFormMutation($input: UpdateCaregiverInput!) {
        updateCaregiver(input: $input) {
          caregiver {
            ...AboutMeFormFragment
          }
        }
      }
    `,
  );

  const {
    handleSubmit,
    control,
    formState: { isDirty },
  } = useForm<AboutMeData>({
    mode: 'onTouched',
    defaultValues: {
      aboutMe,
      currentPosition,
    },
    resolver: ioTsResolver(schema),
  });

  const handleSave = useCallback(
    (data: AboutMeData) => {
      commitMutation({
        variables: {
          input: data,
        },
        onCompleted: () => {
          navigation.navigate('Profile');
          trackEvent(events.FINANCIAL_DETAILS_UPDATED);
        },
      });
    },
    [commitMutation, navigation],
  );

  const handlePressSubmit = useCallback(() => {
    void handleSubmit(handleSave)();
  }, [handleSave, handleSubmit]);

  useLayoutEffect(() => {
    navigation.setOptions({
      headerRight: () => <HeaderSaveButton dirty={isDirty} onPress={handlePressSubmit} />,
      headerBackTitle: intl.formatMessage({ id: 'BUTTON_CANCEL' }),
    });
  }, [handlePressSubmit, isDirty, navigation]);

  const saveButton = (
    <SaveButton
      marginHorizontal="base"
      marginVertical="loose"
      disabled={!isDirty || isInFlight}
      onPress={handlePressSubmit}
    >
      <FormattedMessage id="BUTTON_SAVE" />
    </SaveButton>
  );

  return (
    <>
      <ScrollView contentContainerStyle={{ flexShrink: 0, flexGrow: 1 }}>
        <Card
          topmost
          mode="contained"
          marginHorizontal="base"
          marginVertical="base"
          style={{ flexDirection: 'row' }}
        >
          <AlertCircle size={24} color={CareshipColors.slate400} />
          <Text size="large" style={{ flexWrap: 'wrap', flex: 1, paddingLeft: 8 }}>
            <FormattedMessage id="PROFILE_ABOUT_ME_INTRO" />
          </Text>
        </Card>
        <Container>
          <InputWithValidation
            marginTop="base"
            control={control}
            defaultValue={currentPosition}
            name="currentPosition"
            label={intl.formatMessage({ id: 'PROFILE_CURRENT_POSITION' })}
            disabled={isInFlight}
            keyboardType="default"
            accessibilityLabel="current position"
            accessibilityHint="current position"
          />
          <InputWithValidation
            multiline
            numberOfLines={8}
            control={control}
            defaultValue={aboutMe}
            name="aboutMe"
            label={intl.formatMessage({ id: 'PROFILE_ABOUT_ME' })}
            disabled={isInFlight}
            keyboardType="default"
            accessibilityLabel="about me"
            accessibilityHint="about me"
          />
        </Container>
        <View style={{ marginTop: 'auto' }} collapsable={false} ref={footerButtonsRef}>
          {saveButton}
        </View>
      </ScrollView>
      <StickyFooter sticky={isDirty} alignWithRef={footerButtonsRef}>
        {saveButton}
      </StickyFooter>
    </>
  );
}
