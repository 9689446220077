import styled from '@emotion/native';
import React from 'react';
import Loading from './Loading';

const FullScreen = styled.View({
  flex: 1,
  alignItems: 'center',
  justifyContent: 'center',
});

export default function SuspenseFullScreenFallback() {
  return (
    <FullScreen>
      <Loading />
    </FullScreen>
  );
}
