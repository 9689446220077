import { StyleSheet } from 'react-native';
import { CareshipColors } from '../../../Component/CSTheme';

export default StyleSheet.create({
  container: {
    marginTop: 32,
  },
  group: {
    marginBottom: 32,
  },
  lastGroup: {
    marginBottom: 16,
  },
  timeInput: {
    paddingVertical: 16,
  },
  timeInputRight: {
    marginLeft: 8,
  },
  timeInputLeft: {
    marginRight: 8,
  },
  recurringRow: {
    paddingVertical: 16,
    flexDirection: 'row',
    alignItems: 'center',
    borderBottomColor: CareshipColors.slate100,
    borderBottomWidth: 1,
  },
  recurringIcon: {
    marginRight: 8,
  },
  careReceiverItemWithHint: {
    height: 128,
  },
  careReceiverHint: {
    display: 'flex',
    flexGrow: 1,
    backgroundColor: CareshipColors.slate050,
    padding: 12,
    marginLeft: 8,
    marginTop: 12,
    marginBottom: 8,
    flexDirection: 'row',
    alignItems: 'center',
  },
  careReceiverHintText: {
    color: CareshipColors.slate300,
    fontSize: 16,
  },
  careReceiverHintIcon: {
    marginRight: 8,
  },
});
