import { ScrollView, View } from 'react-native';
import { FormattedMessage, useIntl } from 'react-intl';
import React, { useCallback, useState } from 'react';
import { Feather } from '@expo/vector-icons';
import { ErrorMessage, Field, FieldProps, Formik, FormikHelpers } from 'formik';
import { useFragment } from 'react-relay/hooks';
import { graphql } from 'react-relay';
import {
  Button,
  Container,
  CSTheme,
  DateTimeInterval,
  FormGroupTitle,
  InputErrorMessage,
} from '../../../Component';
import styles from './FirstBookingConfirmForm.styles';
import { events, trackEvent } from '../../../../Infrastructure/Tracking/Tracking';
import OverlappingBookingCountRow from '../../../Component/OverlappingBookingCountRow';
import { useStores } from '../../../../Infrastructure/Store';
import { FirstBookingConfirmForm_contract$key } from './__generated__/FirstBookingConfirmForm_contract.graphql';
import ErrorFullscreen from '../../../Component/ErrorFullscreen';

type FormData = {
  bookingId: string;
  bookingInterval: {
    start: Date;
    end: Date;
  };
};

export interface FirstBookingConfirmFormPayload {
  bookingId: string;
  startDate: Date;
  endDate: Date;
}

interface FirstBookingConfirmFormProps {
  onSubmit: ({
    bookingId,
    startDate,
    endDate,
  }: FirstBookingConfirmFormPayload) => Promise<string | void>;
  contractFragment: FirstBookingConfirmForm_contract$key;
}

function toFormData({ bookingId, startDate, endDate }: FirstBookingConfirmFormPayload): FormData {
  return {
    bookingId,
    bookingInterval: { start: startDate, end: endDate },
  };
}

function toPayload({
  bookingId,
  bookingInterval: { start, end },
}: FormData): FirstBookingConfirmFormPayload {
  return {
    bookingId,
    startDate: start,
    endDate: end,
  };
}

export default function FirstBookingConfirmForm({
  contractFragment,
  onSubmit,
}: FirstBookingConfirmFormProps) {
  const [focused, setFocused] = useState<'appointmentTime' | null>(null);
  const { bookingStore } = useStores();
  const intl = useIntl();
  const { firstBooking } = useFragment(
    graphql`
      fragment FirstBookingConfirmForm_contract on Contract {
        firstBooking(isFirstBooking: true) {
          bookingId
          startDate
          endDate
        }
      }
    `,
    contractFragment,
  );

  const validate = useCallback(
    (data: FormData) => {
      const errors: { [key in keyof FormData]?: string } = {};

      if (data.bookingId === null) {
        errors.bookingId = intl.formatMessage({ id: 'ERROR_UNKNOWN' });
      }

      const overlappingCount = bookingStore.overlappingCount(toPayload(data));
      if (overlappingCount) {
        errors.bookingInterval = intl.formatMessage(
          { id: 'BOOKING_VALIDATION_OVERLAPS' },
          { itemCount: overlappingCount },
        );
      }

      return errors;
    },
    [bookingStore, intl],
  );

  if (!firstBooking) {
    return <ErrorFullscreen />;
  }

  const handleFormSubmit = async (data: FormData, helpers: FormikHelpers<FormData>) => {
    const errorCode = await onSubmit(toPayload(data));

    if (errorCode) {
      helpers.setStatus({
        error: intl.formatMessage({ id: 'ERROR_RESPONSE' }, { errorCode }),
      });
    }

    trackEvent(events.FIRST_BOOKING_CONFIRM_SUBMIT_CLICK);
  };

  return (
    <ScrollView>
      <Formik
        validate={validate}
        initialValues={toFormData({
          bookingId: firstBooking.bookingId,
          startDate: new Date(firstBooking.startDate),
          endDate: new Date(firstBooking.endDate),
        })}
        onSubmit={handleFormSubmit}
      >
        {({ setFieldValue, handleSubmit, status, errors, isSubmitting, values }) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
          const statusError = status?.error;

          return (
            <>
              <View style={[styles.dateGroup]}>
                <Container>
                  <FormattedMessage tagName={FormGroupTitle} id="APPOINTMENT_DATE_LABEL" />
                  <OverlappingBookingCountRow
                    bookings={[
                      {
                        bookingId: firstBooking.bookingId,
                        startDate: values.bookingInterval.start,
                        endDate: values.bookingInterval.end,
                      },
                    ]}
                  />
                </Container>
                <Field name="bookingInterval">
                  {({ field }: FieldProps<FormData['bookingInterval']>) => (
                    <DateTimeInterval
                      focused={focused === 'appointmentTime'}
                      onIntervalChange={(interval) => setFieldValue('bookingInterval', interval)}
                      onFocus={() => setFocused('appointmentTime')}
                      interval={field.value}
                    />
                  )}
                </Field>
              </View>
              <Container>
                {(errors || Boolean(statusError)) && (
                  <InputErrorMessage>
                    <ErrorMessage name="api" />
                    <ErrorMessage name="bookingId" />
                    <ErrorMessage name="bookingInterval" />
                    {statusError}
                  </InputErrorMessage>
                )}
                <Button
                  color={CSTheme.colors.accent}
                  style={styles.submitButton}
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  onPress={handleSubmit}
                  icon={<Feather color="white" size={32} name="check" />}
                >
                  <FormattedMessage id="FIRST_APPOINTMENT_CONFIRM_FORM_SUBMIT" />
                </Button>
              </Container>
            </>
          );
        }}
      </Formik>
    </ScrollView>
  );
}
