import Schedule, { ScheduleResponse } from './Schedule';
import Pricing, { PricingResponse } from './Pricing';
import CareReceiver, { CareReceiverResponse } from './CareReceiver';
import SubService, { SubServiceResponse } from './SubService';

export type Status = 'open' | 'declined' | 'accepted';

export type ProposalResponse = {
  service_request_id: string;
  care_receiver: CareReceiverResponse;
  status: Status;
  expires_at: string;
  schedule: ScheduleResponse;
  sub_services: SubServiceResponse[];
  description: string;
  pricing: PricingResponse;
};

export default class Proposal {
  constructor(
    public readonly serviceRequestId: string,
    public readonly careReceiver: CareReceiver,
    public readonly status: Status,
    public readonly expiresAt: Date,
    public readonly schedule: Schedule,
    public readonly subServices: SubService[],
    public readonly description: string,
    public readonly pricing: Pricing,
  ) {}

  public static fromObject(proposal: Proposal) {
    return new Proposal(
      proposal.serviceRequestId,
      CareReceiver.fromObject(proposal.careReceiver),
      proposal.status,
      proposal.expiresAt,
      Schedule.fromObject(proposal.schedule),
      proposal.subServices.map((subService) => SubService.fromObject(subService)),
      proposal.description,
      Pricing.fromObject(proposal.pricing),
    );
  }

  public static fromResponse(response: ProposalResponse) {
    return new Proposal(
      response.service_request_id,
      CareReceiver.fromResponse(response.care_receiver),
      response.status,
      new Date(response.expires_at),
      Schedule.fromResponse(response.schedule),
      response.sub_services.map((subService) => SubService.fromResponse(subService)),
      response.description,
      Pricing.fromResponse(response.pricing),
    );
  }
}
