/**
 * @generated SignedSource<<61579c53e9760a51f5ba7a832badcf5e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ProposalBookingTypeEnum = "ONE_TIME" | "REGULAR" | "%future added value";
export type ProposalStatusEnum = "OPEN" | "DECLINED" | "ACCEPTED" | "%future added value";
export type ServiceRequestFrequencyEnum = "ONCE_PER_WEEK" | "TWO_THREE_TIMES_PER_WEEK" | "FOUR_TIMES_PER_WEEK_OR_MORE" | "EVERY_TWO_WEEKS" | "ONCE_OR_TWICE_A_MONTH" | "ON_DEMAND" | "%future added value";
export type ProposalDetailQuery$variables = {
  serviceRequestId: string;
};
export type ProposalDetailQuery$data = {
  readonly proposal: {
    readonly description: string;
    readonly status: ProposalStatusEnum;
    readonly isNewCustomer: boolean | null;
    readonly careReceiver: {
      readonly gender: string;
      readonly name: string;
      readonly age: number;
      readonly careDegree: string;
      readonly address: {
        readonly street: string;
        readonly city: string;
        readonly postalCode: string;
      };
    };
    readonly schedule: {
      readonly expectedAppointmentSchedule: {
        readonly isFlexible: boolean;
        readonly preferences: string;
      } | null;
      readonly bookingType: ProposalBookingTypeEnum;
      readonly frequency: ServiceRequestFrequencyEnum | null;
      readonly durationInMinutes: number;
      readonly date: string;
      readonly isFlexible: boolean;
      readonly isAllDay: boolean;
    };
    readonly pricing: {
      readonly grossPricePerHourInCents: number;
    };
    readonly subServices: ReadonlyArray<{
      readonly id: string;
      readonly label: string;
    }>;
  } | null;
};
export type ProposalDetailQuery = {
  variables: ProposalDetailQuery$variables;
  response: ProposalDetailQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "serviceRequestId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "serviceRequestId",
    "variableName": "serviceRequestId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isNewCustomer",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "ProposalCareReceiver",
  "kind": "LinkedField",
  "name": "careReceiver",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gender",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "age",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "careDegree",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProposalAddress",
      "kind": "LinkedField",
      "name": "address",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "street",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "city",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "postalCode",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isFlexible",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "ProposalSchedule",
  "kind": "LinkedField",
  "name": "schedule",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ProposalExpectedAppointmentSchedule",
      "kind": "LinkedField",
      "name": "expectedAppointmentSchedule",
      "plural": false,
      "selections": [
        (v6/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "preferences",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bookingType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "frequency",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "durationInMinutes",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "date",
      "storageKey": null
    },
    (v6/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isAllDay",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "ProposalPricing",
  "kind": "LinkedField",
  "name": "pricing",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "grossPricePerHourInCents",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "SubServiceDefinition",
  "kind": "LinkedField",
  "name": "subServices",
  "plural": true,
  "selections": [
    (v9/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "label",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProposalDetailQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Proposal",
        "kind": "LinkedField",
        "name": "proposal",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v10/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProposalDetailQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Proposal",
        "kind": "LinkedField",
        "name": "proposal",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v10/*: any*/),
          (v9/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f14aed84ddeb004588a3c506b927ffbb",
    "id": null,
    "metadata": {},
    "name": "ProposalDetailQuery",
    "operationKind": "query",
    "text": "query ProposalDetailQuery(\n  $serviceRequestId: ID!\n) {\n  proposal(serviceRequestId: $serviceRequestId) {\n    description\n    status\n    isNewCustomer\n    careReceiver {\n      gender\n      name\n      age\n      careDegree\n      address {\n        street\n        city\n        postalCode\n      }\n    }\n    schedule {\n      expectedAppointmentSchedule {\n        isFlexible\n        preferences\n      }\n      bookingType\n      frequency\n      durationInMinutes\n      date\n      isFlexible\n      isAllDay\n    }\n    pricing {\n      grossPricePerHourInCents\n    }\n    subServices {\n      id\n      label\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "be068ac10ff4fb58a36094e4374d86a4";

export default node;
