import React, { useCallback, useRef } from 'react';
import styled from '@emotion/native';
import { ScrollView, View } from 'react-native';
import { useForm, Controller } from 'react-hook-form';
import { useNavigation } from '@react-navigation/native';
import { FormattedMessage, useIntl } from 'react-intl';
import { graphql } from 'react-relay';
import { useMutation, PreloadedQuery, usePreloadedQuery } from 'react-relay/hooks';
import { pipe } from 'fp-ts/function';
import { CheckboxItem, Button, Hr } from '../../Component';
import StickyFooter from '../../Component/StickyFooter';
import { LanguagesFormQuery } from './__generated__/LanguagesFormQuery.graphql';
import { events, trackEvent } from '../../../Infrastructure/Tracking/Tracking';
import { LanguagesFormMutation } from './__generated__/LanguagesFormMutation.graphql';
import { AccountStackNavigationProp } from '../Navigator/AccountStackNavigationProp';
import { logger } from '../../../Infrastructure/Service';
import usePlainAlert from '../../Dialog/useShowAlert';

const SaveButton = styled(Button)({
  marginVertical: 24,
  marginHorizontal: 16,
});

const topLanguages = ['GERMAN', 'ENGLISH', 'SPANISH', 'FRENCH'] as const;

const otherLanguages = [
  'ARABIC',
  'CHINESE',
  'CROATIAN',
  'CZECH',
  'DANISH',
  'DUTCH',
  'FINNISH',
  'GREEK',
  'HEBREW',
  'HUNGARIAN',
  'ITALIAN',
  'JAPANESE',
  'KOREAN',
  'POLISH',
  'PORTUGUESE',
  'NORWEGIAN',
  'ROMANIAN',
  'RUSSIAN',
  'SERBIAN',
  'SIGN_LANGUAGE',
  'SWEDISH',
  'TURKISH',
  'VIETNAMESE',
  'OTHER',
] as const;

type Language = typeof topLanguages[number] | typeof otherLanguages[number];

function getSelectedKeys<K extends string, V extends boolean>(record: Record<K, V>): K[] {
  return pipe(
    Object.entries(record) as [K, V][],
    (entries: [K, V][]) => entries.filter(([, v]) => v),
    (entries) => entries.map(([key]) => key),
  );
}

const languagesFormMutation = graphql`
  mutation LanguagesFormMutation($input: UpdateCaregiverInput!) {
    updateCaregiver(input: $input) {
      caregiver {
        id
        languages {
          key
        }
      }
    }
  }
`;

export const languagesFormQuery = graphql`
  query LanguagesFormQuery {
    me {
      ... on Caregiver {
        id
        languages {
          key
        }
      }
    }
  }
`;

interface LanguagesFormProps {
  preloadedQuery: PreloadedQuery<LanguagesFormQuery>;
}

export default function LanguagesForm({ preloadedQuery }: LanguagesFormProps) {
  const footerButtonsRef = useRef<View>(null);
  const intl = useIntl();
  const showAlert = usePlainAlert();
  const navigation = useNavigation<AccountStackNavigationProp<'Languages'>>();

  const {
    me: { languages: cgLanguages },
  } = usePreloadedQuery(languagesFormQuery, preloadedQuery);

  const keyToTranslation = (key: Language) => ({
    key,
    label: intl.formatMessage({ id: 'LANGUAGES' }, { key }),
  });

  const otherLanguagesWithTranslations = otherLanguages.map(keyToTranslation);
  const topLanguagesWithTranslations = topLanguages.map(keyToTranslation);

  otherLanguagesWithTranslations.sort((a, b) => {
    return a.label.localeCompare(b.label);
  });

  const defaultValues = [...topLanguages, ...otherLanguages].reduce(
    (accum, key) => ({
      ...accum,
      [key]: cgLanguages?.some((lang) => lang?.key === key),
    }),
    {} as { [key in Language]: boolean },
  );

  const {
    handleSubmit,
    control,
    formState: { isDirty },
  } = useForm({
    mode: 'onSubmit',
    defaultValues,
  });

  const [commitMutation, isInFlight] = useMutation<LanguagesFormMutation>(languagesFormMutation);

  const handleSave = useCallback(
    (input: typeof defaultValues) => {
      commitMutation({
        variables: {
          input: {
            languages: getSelectedKeys(input),
          },
        },
        onError: (error) => {
          logger.error('LanguagesFormMutation failed', { relayError: error });

          showAlert(intl.formatMessage({ id: 'ERROR_UNKNOWN' }));
        },
        onCompleted: () => {
          trackEvent(events.LANGUAGES_UPDATED);
          navigation.navigate('Profile');
        },
      });
    },
    [navigation, showAlert, intl, commitMutation],
  );

  const handlePressSubmit = useCallback(() => {
    void handleSubmit(handleSave)();
  }, [handleSave, handleSubmit]);

  const saveButton = (
    <SaveButton testID="submitButton" disabled={!isDirty || isInFlight} onPress={handlePressSubmit}>
      <FormattedMessage id="BUTTON_SAVE" />
    </SaveButton>
  );

  const renderCheckbox = (
    { key, label }: { key: Language; label: string },
    index: number,
    languages: unknown[],
  ) => {
    const isLast = languages.length - 1 === index;

    return (
      <Controller
        key={key}
        name={key}
        control={control}
        render={({ field: { value, onChange } }) => (
          <CheckboxItem
            onPress={() => onChange(!value)}
            status={value ? 'checked' : 'unchecked'}
            label={label}
            style={isLast && { borderBottomWidth: 0 }}
          />
        )}
      />
    );
  };

  return (
    <>
      <ScrollView style={{ paddingTop: 32 }}>
        {topLanguagesWithTranslations.map(renderCheckbox)}
        <Hr solid />
        {otherLanguagesWithTranslations.map(renderCheckbox)}
        <View style={{ marginTop: 'auto' }} collapsable={false} ref={footerButtonsRef}>
          {saveButton}
        </View>
      </ScrollView>
      <StickyFooter sticky={isDirty} alignWithRef={footerButtonsRef}>
        {saveButton}
      </StickyFooter>
    </>
  );
}
