import { StyleSheet, Text as RNText, View } from 'react-native';
import { FormattedMessage } from 'react-intl';
import React, { useCallback, useRef } from 'react';
import { Feather } from '@expo/vector-icons';
import { TouchableRipple } from 'react-native-paper';
import { differenceInMinutes, isPast } from 'date-fns';
import { graphql, useFragment } from 'react-relay/hooks';
import styled from '@emotion/native';
import { useNavigation } from '@react-navigation/native';
import { Button, Container, CSTheme, Text } from '../../Component';
import { CareshipColors } from '../../Component/CSTheme';
import ScheduleDetails from './ScheduleDetails';
import { FirstBookingActions_contract$key } from './__generated__/FirstBookingActions_contract.graphql';
import CardWithTooltip from '../../Component/CaregiverStats/CardWithTooltip';
import { CustomerStackNavigationProp } from '../Navigator/CustomerStackNavigationProp';
import Manifest from '../../../Infrastructure/Constants/Manifest';
import Tooltip from '../../Component/Tooltip';
import useProfile from '../../../Context/Profile/Hook/useProfile';

interface FirstBookingActionsProps {
  contractFragment: FirstBookingActions_contract$key;
}

const styles = StyleSheet.create({
  buttonRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 16,
    marginBottom: 8,
  },
  bold: {
    ...CSTheme.fonts.medium,
  },
  container: {
    backgroundColor: CareshipColors.slate050,
  },
  containerHorizontalPadding: {
    paddingHorizontal: 16,
  },
  containerTopPadding: {
    paddingTop: 16,
  },
  containerBottomPadding: {
    paddingBottom: 16,
  },
  containerTop: {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    marginTop: 16,
  },
  containerBottomBorder: {
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },
  containerBottom: {
    marginTop: 2,
    marginBottom: 8,
  },
  edgeButton: { minWidth: 78 },
});

const TooltipContent = styled.Text({
  color: CareshipColors.white,
  lineHeight: 22,
});

const CenteredButton = styled.Text({
  flexDirection: 'row',
  alignSelf: 'center',
  marginTop: 16,
  marginBottom: 8,
});

export default function FirstBookingActions({ contractFragment }: FirstBookingActionsProps) {
  const { profile } = useProfile();
  const tooltipBoundaryRef = useRef<View>(null);
  const navigation = useNavigation<CustomerStackNavigationProp<'CustomerDetailView'>>();
  const {
    contractId,
    serviceRequest: { schedule: serviceRequestSchedule },
    firstBookingStatus,
    firstBookingConfirmationStatus,
    firstBooking,
    hasFulfilledBooking,
  } = useFragment(
    graphql`
      fragment FirstBookingActions_contract on Contract {
        contractId
        hasFulfilledBooking
        firstBookingStatus
        firstBookingConfirmationStatus {
          description
          reason
        }
        firstBooking {
          bookingId
          startDate
          endDate
          isFirstBooking
        }
        serviceRequest {
          schedule {
            durationInMinutes
            isFlexible
            isAllDay
          }
        }
      }
    `,
    contractFragment,
  );

  const handlePressDocumentBooking = useCallback(() => {
    navigation.navigate('FulfillBooking', {
      bookingId: firstBooking!.bookingId,
    });
  }, [firstBooking, navigation]);

  const handlePressBooking = useCallback(() => {
    navigation.navigate('EditAppointment', {
      bookingId: firstBooking!.bookingId,
    });
  }, [firstBooking, navigation]);

  const handleFirstBookingConfirm = useCallback(() => {
    navigation.navigate('FirstBookingConfirm', {
      contractId,
    });
  }, [navigation, contractId]);

  const handleFirstBookingDecline = useCallback(() => {
    navigation.navigate('FirstBookingDecline', {
      contractId,
    });
  }, [navigation, contractId]);

  const handleFirstBookingUpdateConfirmationStatus = useCallback(() => {
    navigation.navigate('FirstBookingUpdateConfirmationStatus', {
      contractId,
    });
  }, [navigation, contractId]);

  if (hasFulfilledBooking || !firstBooking) {
    return null;
  }

  const { startDate: startDateString, endDate: endDateString } = firstBooking;
  const startDate = new Date(startDateString);
  const endDate = new Date(endDateString);
  const isDocumentationPending = firstBookingStatus === 'confirmed' && isPast(endDate);
  // First booking here is first chronological booking, not necessarily the one created by the system.
  // In the edge case where CG either created new booking before the first or had the first booking
  // deleted by customer excellence hide response buttons. First booking response only works for the
  // booking marked as first.
  const isPendingResponse = firstBookingStatus === 'created' && firstBooking.isFirstBooking;
  const isConfirmed =
    firstBookingStatus === 'confirmed' && firstBooking.isFirstBooking && !hasFulfilledBooking;
  const hasActionsAvailable = isDocumentationPending || isPendingResponse || isConfirmed;

  const isMaybeDisabled: boolean =
    !!firstBookingConfirmationStatus?.reason &&
    firstBookingConfirmationStatus.reason !== 'not_reachable';

  const TooltipBodyText = styled.Text({
    color: CareshipColors.white,
  });

  let schedule = {
    ...serviceRequestSchedule,
    date: startDate,
    durationInMinutes: differenceInMinutes(endDate, startDate),
  };

  if (firstBookingStatus === 'confirmed') {
    schedule = { ...schedule, isFlexible: false, isAllDay: false };
  }

  if(!profile?.isCareshipEmployee)
  {
    return (
      <Container>
        <TouchableRipple
          onPress={handlePressBooking}
          style={[
            styles.container,
            styles.containerHorizontalPadding,
            styles.containerTopPadding,
            styles.containerBottomPadding,
            styles.containerTop,
            !hasActionsAvailable && styles.containerBottomBorder,
          ]}
        >
          <ScheduleDetails
            iconRight={
              !profile?.isCareshipEmployee && <Feather color="gray" size={24} name="edit-2" />
            }
            title={
              firstBookingStatus === 'confirmed' ? (
                <FormattedMessage id="FIRST_APPOINTMENT_CONFIRMED_FOR" />
              ) : (
                <FormattedMessage id="FIRST_APPOINTMENT" />
              )
            }
            schedule={schedule}
          />
        </TouchableRipple>
        {hasActionsAvailable && (
          <View
            style={[
              styles.container,
              styles.containerBottomPadding,
              styles.containerBottom,
              styles.containerBottomBorder,
            ]}
          >
            {isDocumentationPending && (
              <View style={[styles.containerTopPadding, styles.containerHorizontalPadding]}>
                <Button onPress={handlePressDocumentBooking} size="xs" color={CSTheme.colors.text}>
                  <FormattedMessage id="FIRST_APPOINTMENT_ACTION_DOCUMENT" />
                </Button>
              </View>
            )}
            {isPendingResponse && (
              <>
                <CardWithTooltip
                  tooltipContent={
                    <>
                      <TooltipContent>
                        <FormattedMessage
                          id="FIRST_APPOINTMENT_TOOLTIP"
                          values={{
                            prefix: (
                              <RNText style={styles.bold}>
                                <FormattedMessage id="FIRST_APPOINTMENT_TOOLTIP_PREFIX" />
                              </RNText>
                            ),
                            yes: (
                              <RNText style={styles.bold}>
                                <FormattedMessage id="FIRST_APPOINTMENT_TOOLTIP_YES" />
                              </RNText>
                            ),
                            maybe: (
                              <RNText style={styles.bold}>
                                <FormattedMessage id="FIRST_APPOINTMENT_TOOLTIP_MAYBE" />
                              </RNText>
                            ),
                            no: (
                              <RNText style={styles.bold}>
                                <FormattedMessage id="FIRST_APPOINTMENT_TOOLTIP_NO" />
                              </RNText>
                            ),
                          }}
                        />
                      </TooltipContent>
                    </>
                  }
                  title={
                    <Text center style={{ width: '100%' }}>
                      <Text bold size="large">
                        <FormattedMessage id="FIRST_APPOINTMENT_ACTION_TITLE" />
                      </Text>
                    </Text>
                  }
                >
                  <View style={[styles.buttonRow, styles.containerHorizontalPadding]}>
                    <Button
                      onPress={handleFirstBookingConfirm}
                      size="xs"
                      color={CSTheme.colors.accent}
                      style={styles.edgeButton}
                    >
                      <FormattedMessage id="FIRST_APPOINTMENT_ACTION_CONFIRM" />
                    </Button>
                    <Button
                      testID="update-confirmation-status-btn"
                      onPress={handleFirstBookingUpdateConfirmationStatus}
                      disabled={isMaybeDisabled}
                      size="xs"
                      color={CSTheme.colors.text}
                    >
                      <FormattedMessage id="FIRST_APPOINTMENT_ACTION_MAYBE" />
                    </Button>
                    <Button
                      onPress={handleFirstBookingDecline}
                      size="xs"
                      color={CSTheme.colors.error}
                      style={styles.edgeButton}
                    >
                      <FormattedMessage id="FIRST_APPOINTMENT_ACTION_DECLINE" />
                    </Button>
                  </View>
                </CardWithTooltip>
              </>
            )}
            {Manifest.extra.features.firstBookingConfirmationCancelation &&
              isConfirmed &&
              !profile?.isCareshipEmployee && (
                <View style={[styles.containerTopPadding, styles.containerHorizontalPadding]}>
                  <Text bold center size="large">
                    <FormattedMessage id="FIRST_APPOINTMENT_CANCELATION_ACTION_TITLE" />
                  </Text>
                  <CenteredButton>
                    <Button
                      onPress={handleFirstBookingDecline}
                      size="xs"
                      testID="cancel-first-booking-confirmation"
                      color={CSTheme.colors.error}
                    >
                      <FormattedMessage id="FIRST_APPOINTMENT_CANCELATION_ACTION_BUTTON" />
                    </Button>
                    <Tooltip boundary={tooltipBoundaryRef}>
                      <TooltipBodyText>
                        <FormattedMessage id="FIRST_APPOINTMENT_CANCELATION_ACTION_TOOLTIP" />
                      </TooltipBodyText>
                    </Tooltip>
                  </CenteredButton>
                </View>
              )}
          </View>
        )}
      </Container>
    );
  }
  return (<Container></Container>);
}
