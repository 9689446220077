/**
 * @generated SignedSource<<81318f7b305ab6042733461b651b9cfc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ExperienceAndSkillEnum = "CHILDCARE" | "OFFICE_WORK" | "COMPUTERS_AND_INTERNET" | "WHEELCHAIR" | "POST_REHAB" | "PAIN_RELIEF" | "MASSAGE" | "COACHING" | "%future added value";
export type LanguageEnum = "GERMAN" | "ENGLISH" | "SPANISH" | "FRENCH" | "ARABIC" | "CHINESE" | "CROATIAN" | "CZECH" | "DANISH" | "DUTCH" | "FINNISH" | "GREEK" | "HEBREW" | "HUNGARIAN" | "ITALIAN" | "JAPANESE" | "KOREAN" | "POLISH" | "PORTUGUESE" | "NORWEGIAN" | "ROMANIAN" | "RUSSIAN" | "SERBIAN" | "SIGN_LANGUAGE" | "SWEDISH" | "TURKISH" | "VIETNAMESE" | "OTHER" | "%future added value";
export type MedicalConditionEnum = "DEMENTIA" | "STROKE" | "LIFE_SUPPORT" | "DEPRESSIONS" | "MENTAL_DISABILITY" | "DOWN_SYNDROME" | "PARKINSONS" | "DIABETES" | "MULTIPLE_SCLEROSIS" | "%future added value";
export type UpdateCaregiverInput = {
  currentPosition?: string | null;
  aboutMe?: string | null;
  languages?: ReadonlyArray<LanguageEnum> | null;
  contactAndAddress?: CaregiverContactAndAddressInput | null;
  additionalInfo?: CaregiverAdditionalInfoInput | null;
  experienceWithMedicalConditions?: ReadonlyArray<MedicalConditionEnum> | null;
  experienceAndSkills?: ReadonlyArray<ExperienceAndSkillEnum> | null;
  clientMutationId?: string | null;
};
export type CaregiverContactAndAddressInput = {
  mobilePhoneNumber: string;
  phoneNumber: string;
  company: string;
  address: CaregiverContactAndAddressAddressInput;
};
export type CaregiverContactAndAddressAddressInput = {
  street: string;
  houseNumber: string;
  city: string;
  postalCode: string;
  additionalInformation?: string | null;
};
export type CaregiverAdditionalInfoInput = {
  hasDriversLicence: boolean;
  hasCar: boolean;
  isSmoker: boolean;
  noHomesWithPets: boolean;
};
export type AboutMeFormMutation$variables = {
  input: UpdateCaregiverInput;
};
export type AboutMeFormMutation$data = {
  readonly updateCaregiver: {
    readonly caregiver: {
      readonly " $fragmentSpreads": FragmentRefs<"AboutMeFormFragment">;
    };
  } | null;
};
export type AboutMeFormMutation = {
  variables: AboutMeFormMutation$variables;
  response: AboutMeFormMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AboutMeFormMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateCaregiverPayload",
        "kind": "LinkedField",
        "name": "updateCaregiver",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Caregiver",
            "kind": "LinkedField",
            "name": "caregiver",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "AboutMeFormFragment"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AboutMeFormMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateCaregiverPayload",
        "kind": "LinkedField",
        "name": "updateCaregiver",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Caregiver",
            "kind": "LinkedField",
            "name": "caregiver",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "aboutMe",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "currentPosition",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "63d7bf93a2b7b5e9e2c1cb8a83a80050",
    "id": null,
    "metadata": {},
    "name": "AboutMeFormMutation",
    "operationKind": "mutation",
    "text": "mutation AboutMeFormMutation(\n  $input: UpdateCaregiverInput!\n) {\n  updateCaregiver(input: $input) {\n    caregiver {\n      ...AboutMeFormFragment\n      id\n    }\n  }\n}\n\nfragment AboutMeFormFragment on Caregiver {\n  id\n  aboutMe\n  currentPosition\n}\n"
  }
};
})();

(node as any).hash = "735beeec29e94b53f3e70d0c71e85181";

export default node;
