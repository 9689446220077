import React from 'react';
import { Platform, SectionList, View } from 'react-native';
import { FormattedMessage } from 'react-intl';
import { graphql, useLazyLoadQuery } from 'react-relay/hooks';
import styled from '@emotion/native';
import { getMonth, getYear } from 'date-fns';
import { TouchableRipple } from 'react-native-paper';
import { Feather } from '@expo/vector-icons';
import {
  ClipboardList2Icon,
  PaymentMethodDirIcon,
  PdfIcon,
  PrinterIcon,
} from '../../Component/Icon';
import { Card, DividingHeader, Text, TopBanner } from '../../Component';
import { CareshipColors } from '../../Component/CSTheme';
import ServiceDocumentationRow from './ServiceDocumentationRow';
import { ServiceDocumentationRow_serviceDocumentationQuery$key } from './__generated__/ServiceDocumentationRow_serviceDocumentationQuery.graphql';
import { ServiceDocumentationListQuery } from './__generated__/ServiceDocumentationListQuery.graphql';
import nonNullEdges from '../../../Infrastructure/Relay/nonNullEdges';

interface ServiceDocumentationListProps {
  onPressServiceDocumentationPeriod: (serviceDocumentationPeriod: string, title: string) => void;
  onPressServiceDocumentationHowTo: () => void;
  onPressServiceDocumentationTemplatePdf: () => void;
  contractId: string;
}

interface ServiceDocumentationItem extends ServiceDocumentationRow_serviceDocumentationQuery$key {
  serviceDocumentationId: string;
  month: number;
  year: number;
}

function nodeToItem(node: NodeData): ServiceDocumentationItem {
  return {
    ...node,
    serviceDocumentationId: node.serviceDocumentationId,
    month: parseInt(node.month, 10),
    year: parseInt(node.year, 10),
  };
}

type NodeData = ServiceDocumentationRow_serviceDocumentationQuery$key & {
  readonly serviceDocumentationId: string;
  readonly year: string;
  readonly month: string;
  readonly lastUploadedFile: {
    readonly pathInStorage: string;
  } | null;
};

const TouchableBanner = styled(View)<{
  borderRadiusTop?: boolean;
  borderRadiusBottom?: boolean;
  dividerBottom?: boolean;
}>(({ borderRadiusTop, borderRadiusBottom, dividerBottom }) => ({
  overflow: 'hidden',
  ...(borderRadiusTop && {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  }),
  ...(borderRadiusBottom && {
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  }),
  ...(dividerBottom && {
    marginBottom: 2,
  }),
}));

const TouchableBannerContent = styled(View)(({ theme: { colors } }) => ({
  display: 'flex',
  flexDirection: 'row',
  backgroundColor: colors.slate050,
  padding: 16,
}));

const TouchableBannerText = styled(Text)({
  flex: 1,
  marginLeft: 8,
});

const getSectionListItemsGroupedByYear = (items: ServiceDocumentationItem[]) => {
  const groupedByYearMap = new Map<string, { title: string; data: ServiceDocumentationItem[] }>();

  items.forEach((item) => {
    const year = item.year.toString();

    groupedByYearMap.set(year, {
      title: year,
      data: [...(groupedByYearMap.get(year)?.data || []), item],
    });
  });

  return Array.from(groupedByYearMap.values());
};

export default function ServiceDocumentationList({
  onPressServiceDocumentationPeriod,
  onPressServiceDocumentationHowTo,
  onPressServiceDocumentationTemplatePdf,
  contractId,
}: ServiceDocumentationListProps) {
  const { contract } = useLazyLoadQuery<ServiceDocumentationListQuery>(
    graphql`
      query ServiceDocumentationListQuery($contractId: ID!) {
        contract(contractId: $contractId) {
          bookings {
            edges {
              node {
                status
                startDate
              }
            }
          }
          serviceDocumentation {
            edges {
              node {
                ...ServiceDocumentationRow_serviceDocumentationQuery
                serviceDocumentationId
                year
                month
                lastUploadedFile {
                  pathInStorage
                }
              }
            }
          }
          paymentMethod
        }
      }
    `,
    {
      contractId,
    },
    {},
  );

  if (!contract) {
    return null;
  }

  const getFulfilledBookingsCount = ({ month, year }: { month: number; year: number }) => {
    return (contract.bookings.edges || [])
      .filter(nonNullEdges)
      .filter(
        ({ node: { status, startDate } }) =>
          status === 'fulfilled' &&
          getMonth(new Date(startDate)) + 1 === month &&
          getYear(new Date(startDate)) === year,
      ).length;
  };

  const items = getSectionListItemsGroupedByYear(
    (contract.serviceDocumentation?.edges || [])
      .filter(nonNullEdges)
      .map(({ node }) => nodeToItem(node)),
  );

  return (
    <SectionList
      ListHeaderComponent={
        <>
          {contract.paymentMethod === 'direct_reimbursement' && (
            <TopBanner
              backgroundColor="darkGray"
              icon={<PaymentMethodDirIcon color={CareshipColors.white} />}
              title={<FormattedMessage id="SERVICE_DOCUMENTATION_TOP_BANNER_DIR" />}
            />
          )}
          <Card marginHorizontal="base" marginVertical="base">
            <TouchableBanner borderRadiusTop dividerBottom>
              <TouchableRipple onPress={onPressServiceDocumentationHowTo}>
                <TouchableBannerContent>
                  <View>
                    <ClipboardList2Icon />
                  </View>
                  <TouchableBannerText size="large">
                    <FormattedMessage id="SERVICE_DOCUMENTATION_HOW_TO" />
                  </TouchableBannerText>
                  <View>
                    <Feather name="chevron-right" color={CareshipColors.slate300} size={24} />
                  </View>
                </TouchableBannerContent>
              </TouchableRipple>
            </TouchableBanner>
            <TouchableBanner borderRadiusBottom>
              <TouchableRipple onPress={onPressServiceDocumentationTemplatePdf}>
                <TouchableBannerContent>
                  <View>
                    <PdfIcon />
                  </View>
                  <TouchableBannerText size="large">
                    <FormattedMessage id="SERVICE_DOCUMENTATION_TEMPLATE" />
                  </TouchableBannerText>
                  <View>
                    {Platform.select({
                      web: <PrinterIcon />,
                      native: (
                        <Feather name="chevron-right" color={CareshipColors.slate300} size={24} />
                      ),
                    })}
                  </View>
                </TouchableBannerContent>
              </TouchableRipple>
            </TouchableBanner>
          </Card>
        </>
      }
      sections={items}
      keyExtractor={({ serviceDocumentationId }) => serviceDocumentationId}
      stickySectionHeadersEnabled
      renderItem={({ item, index, section: { data } }) => {
        return (
          <ServiceDocumentationRow
            key={item.serviceDocumentationId}
            sectionData={data}
            index={index}
            serviceDocumentation={item}
            onPressServiceDocumentationPeriod={onPressServiceDocumentationPeriod}
            fulfilledBookingsCount={getFulfilledBookingsCount({
              month: item.month,
              year: item.year,
            })}
          />
        );
      }}
      renderSectionHeader={({ section: { title } }) => {
        return (
          <DividingHeader key={title} rightGutter>
            {title}
          </DividingHeader>
        );
      }}
    />
  );
}
