import { Theme, Breakpoint } from '@emotion/react';
import { useWindowDimensions } from 'react-native';

const breakpoints: Record<Breakpoint, number> = {
  xxs: 0,
  xs: 375,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
};

function toNumber(bp: Breakpoint | number) {
  return typeof bp === 'number' ? bp : breakpoints[bp];
}

function getNextBreakpoint(bp: Breakpoint) {
  return (
    Object.values(breakpoints)
      // remove BPs smaller than the passed bp
      .filter((v) => v > breakpoints[bp])
      // use Infinity as the fallback for no breakpoint and then find smallest
      .reduce((a, b) => Math.min(a, b), Infinity)
  );
}

export function createBreakpoints(width: number): Theme['breakpoints'] {
  function isDown(bp: Breakpoint | number): boolean {
    return width < toNumber(bp);
  }

  // Include breakpoint in range when checking if screen width is larger
  function isUp(bp: Breakpoint | number): boolean {
    return width >= toNumber(bp);
  }

  function up(bp: Breakpoint | number): <T>(value: T) => T | undefined;
  function up<T>(bp: Breakpoint | number, value: T): T | undefined;
  function up<T>(bp: Breakpoint | number, value?: T) {
    if (!value) {
      return <CT>(v: CT) => up(bp, v);
    }

    return isUp(bp) ? value : undefined;
  }

  function down(bp: Breakpoint | number): <T>(value: T) => T | undefined;
  function down<T>(bp: Breakpoint | number, value: T): T | undefined;
  function down<T>(bp: Breakpoint | number, value?: T) {
    if (!value) {
      return <CT>(v: CT) => down(bp, v);
    }

    return isDown(bp) ? value : undefined;
  }

  function only(bp: Breakpoint): <T>(value: T) => T | undefined;
  function only<T>(bp: Breakpoint, value: T): T | undefined;
  function only<T>(bp: Breakpoint, value?: T) {
    if (!value) {
      return <CT>(v: CT) => only(bp, v);
    }

    return isUp(bp) && isDown(getNextBreakpoint(bp)) ? value : undefined;
  }

  function between(
    fromBp: Breakpoint | number,
    toBp: Breakpoint | number,
  ): <T>(value: T) => T | undefined;
  function between<T>(
    fromBp: Breakpoint | number,
    toBp: Breakpoint | number,
    value: T,
  ): T | undefined;
  function between<T>(fromBp: Breakpoint | number, toBp: Breakpoint | number, value?: T) {
    if (!value) {
      return <CT>(v: CT) => between(fromBp, toBp, v);
    }
    const from = toNumber(fromBp);
    const to = toNumber(toBp);

    // Math min/max allows mixing the order of the breakpoints
    return isUp(Math.min(from, to)) && isDown(Math.max(to, from)) ? value : undefined;
  }

  return {
    up,
    down,
    only,
    between,
  };
}

export default function useBreakpoints(): Theme['breakpoints'] {
  const { width } = useWindowDimensions();

  return createBreakpoints(width);
}
