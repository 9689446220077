import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';
import Svg, { Circle, G } from 'react-native-svg';
import React, { ReactNode } from 'react';
import { CareshipColors } from './CSTheme';
import { logger } from '../../Infrastructure/Service';

const styles = StyleSheet.create({
  wrapper: {
    position: 'relative',
    overflow: 'hidden',
  },
  gauge: { position: 'absolute' },
  label: {
    position: 'absolute',
    justifyContent: 'center',
  },
  labelContent: { alignSelf: 'center' },
});

export default function Gauge({
  value: uncheckedValue,
  children,
  color = CareshipColors.slate400,
  trackColor = CareshipColors.slate100,
  backgroundColor = CareshipColors.slate050,
  radius = 50,
  style,
}: {
  value: number;
  children?: ReactNode;
  color?: string;
  trackColor?: string;
  backgroundColor?: string;
  radius?: number;
  style?: StyleProp<ViewStyle>;
}) {
  if (uncheckedValue < 0 || uncheckedValue > 1) {
    logger.warn(`invalid value given to Gauge, must be 0-1. Received ${uncheckedValue}.`);
  }
  const value = Math.max(0, Math.min(1, uncheckedValue));

  const trackWidth = 6;
  const dotSize = 8;
  const dotStrokeWidth = 2;
  const size = (radius + dotSize + dotStrokeWidth / 2) * 2;
  const midpoint = size / 2;

  const circumference = 2 * radius * Math.PI;
  const angle = 360 * value;
  const radian = angle * (Math.PI / 180);
  const dotTransformXY = `translate(${(radius * Math.cos(radian)).toFixed(2)} ${(
    radius * Math.sin(radian)
  ).toFixed(2)})`;
  const gaugeRotate = `rotate(${-90}, ${midpoint}, ${midpoint})`;

  return (
    <View style={[styles.wrapper, { height: size, width: size }, style]}>
      <Svg style={styles.gauge} width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
        <G transform={gaugeRotate}>
          <Circle
            stroke={trackColor}
            cx={midpoint}
            cy={midpoint}
            r={radius}
            strokeWidth={trackWidth}
            fill="none"
          />
          <Circle
            id="bar"
            stroke={color}
            cx={midpoint}
            cy={midpoint}
            r={radius}
            strokeDasharray={circumference}
            strokeDashoffset={circumference * (1 - value)}
            strokeWidth={trackWidth}
            fill="none"
          />
          <Circle
            id="dot"
            fill={color}
            r={dotSize}
            strokeWidth={dotStrokeWidth}
            stroke={backgroundColor}
            origin={[midpoint, midpoint]}
            transform={dotTransformXY}
            cx={midpoint}
            cy={midpoint}
          />
        </G>
      </Svg>

      {children && (
        <View
          style={[
            styles.label,
            {
              height: size,
              width: size,
            },
          ]}
        >
          <View style={styles.labelContent}>{children}</View>
        </View>
      )}
    </View>
  );
}
