import styled from '@emotion/native';
import { FormattedMessage } from 'react-intl';
import React, { useCallback } from 'react';
import { CareshipColors } from '../CSTheme';
import { CareshipLogo } from '../index';
import useHandleLogout from '../../../Infrastructure/Hook/useHandleLogout';
import LogoContainer from './LogoContainer';
import MainMenu from './MainMenu';
import MenuItem from './MenuItem';
import BottomLinks from './BottomLinks';

export const sidebarMenuWidth = 244;

interface SidebarMenuProps {
  open?: boolean;
}

const Menu = styled.View<SidebarMenuProps>(({ open, theme: { breakpoints } }) => [
  {
    // Targets web, position: 'fixed' is valid
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    position: 'fixed' as any,
    top: 0,
    left: 0,
    height: '100vh',
    display: 'flex',
    flexBasis: '1',
    alignSelf: 'stretch',
    backgroundColor: CareshipColors.slate400,
    minWidth: sidebarMenuWidth,
    paddingLeft: 16,
  },
  breakpoints.down('md', {
    left: '100%',
    paddingLeft: 0,
    paddingRight: 16,
    transform: [{ translateX: open ? -sidebarMenuWidth : 0 }],
  }),
]);

export default function SidebarMenu(props: SidebarMenuProps) {
  const logout = useHandleLogout();
  const handleLogout = useCallback(() => {
    void logout();
  }, [logout]);

  return (
    <Menu {...props}>
      <LogoContainer>
        <CareshipLogo color={CareshipColors.white} width={158} />
      </LogoContainer>
      <MainMenu>
        <MenuItem iconName="home" to="/" activeRouteNames={['DashboardTab']}>
          <FormattedMessage id="DASHBOARD" />
        </MenuItem>
        <MenuItem
          iconName="users"
          to="/customers"
          activeRouteNames={[
            'CustomerTab',
            'FirstAppointmentChecklist',
            'FirstCallChecklist',
            'FirstBookingConfirm',
            'FirstBookingDecline',
            'FirstBookingUpdateConfirmationStatus',
            'DeclineProposal',
          ]}
        >
          <FormattedMessage id="CUSTOMERS" />
        </MenuItem>
        <MenuItem
          to="/bookings"
          iconName="calendar"
          activeRouteNames={[
            'BookingListTab',
            'AddAppointment',
            'EditAppointment',
            'FulfillBooking',
            'EditAppointment',
          ]}
        >
          <FormattedMessage id="DIARY" />
        </MenuItem>
        <MenuItem to="/account" iconName="user" activeRouteNames={['AccountTab']}>
          <FormattedMessage id="ACCOUNT" />
        </MenuItem>
      </MainMenu>
      <BottomLinks>
        <MenuItem secondary to="/" onPress={handleLogout} iconName="log-out">
          <FormattedMessage id="LOGOUT" />
        </MenuItem>
      </BottomLinks>
    </Menu>
  );
}
