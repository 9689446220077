import React from 'react';
import { useRoute, RouteProp, useNavigation } from '@react-navigation/native';
import { Feather } from '@expo/vector-icons';
import { useLazyLoadQuery } from 'react-relay/hooks';
import { graphql } from 'react-relay';
import useFirstBookingDeclineReasons from '../../../Context/Booking/Hook/useFirstBookingDeclineReasons';
import FirstBookingDeclineForm, {
  FirstBookingDeclineFormPayload,
} from '../Component/FirstBookingDeclineForm';
import { AppNavigationParamList } from '../../Root/Navigator/AppNavigator';
import { Loading, KeyboardAvoidingSafeScrollView } from '../../Component';
import ModalHeader from '../../Component/ModalHeader';
import { CareshipColors } from '../../Component/CSTheme';
import { intl } from '../../../Infrastructure/Intl';
import { useBookingService } from '../../../Context/Booking/Hook';
import { isError } from '../../../Infrastructure/Service';
import { CustomerStackNavigationProp } from '../Navigator/CustomerStackNavigationProp';
import { withLayout } from '../../HOC';
import { FirstBookingDeclineScreenQuery } from './__generated__/FirstBookingDeclineScreenQuery.graphql';

export default withLayout(function FirstBookingDeclineScreen() {
  const navigation = useNavigation<CustomerStackNavigationProp<'CustomerDashboard'>>();
  const declineReasons = useFirstBookingDeclineReasons();
  const route = useRoute<RouteProp<AppNavigationParamList, 'FirstBookingDecline'>>();
  const bookingService = useBookingService();
  const { contract } = useLazyLoadQuery<FirstBookingDeclineScreenQuery>(
    graphql`
      query FirstBookingDeclineScreenQuery($contractId: ID!) {
        contract(contractId: $contractId) {
          ...FirstBookingDeclineForm_contract
        }
      }
    `,
    { contractId: route.params.contractId },
  );

  const handleFirstBookingDecline = async (
    data: FirstBookingDeclineFormPayload,
  ): Promise<void | string> => {
    const result = await bookingService.firstBookingDecline(data);

    if (isError(result)) {
      return result.errorCode;
    }

    await bookingService.fetchBooking(data.bookingId);

    navigation.navigate('CustomerDashboard');

    return Promise.resolve();
  };

  return (
    <>
      <ModalHeader
        backButton={<Feather name="x" size={24} color={CareshipColors.primary} />}
        title={intl.formatMessage({ id: 'FIRST_APPOINTMENT_DECLINE_MODAL_TITLE' })}
      />
      {!contract || !declineReasons.length ? (
        <Loading />
      ) : (
        <KeyboardAvoidingSafeScrollView>
          <FirstBookingDeclineForm
            contractFragment={contract}
            firstBookingDeclineReasons={declineReasons}
            handleFirstBookingDecline={handleFirstBookingDecline}
          />
        </KeyboardAvoidingSafeScrollView>
      )}
    </>
  );
});
