/**
 * @generated SignedSource<<d2020685b8a3a2c6bbb47d7db2ece2ae>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
export type ProposalStatusEnum = "OPEN" | "DECLINED" | "ACCEPTED" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ProposalContractSectionList_query$data = {
  readonly allContracts: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly contractId: string;
        readonly isActive: boolean;
        readonly " $fragmentSpreads": FragmentRefs<"ContractListItem_contract">;
      };
    } | null> | null;
  } | null;
  readonly allProposals: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly serviceRequestId: string;
        readonly expiresAt: string;
        readonly status: ProposalStatusEnum;
        readonly " $fragmentSpreads": FragmentRefs<"ProposalListItem_proposal">;
      };
    } | null> | null;
  } | null;
  readonly " $fragmentType": "ProposalContractSectionList_query";
};
export type ProposalContractSectionList_query$key = {
  readonly " $data"?: ProposalContractSectionList_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProposalContractSectionList_query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [],
      "operation": require('./ProposalContractSectionListRefetch_query.graphql')
    }
  },
  "name": "ProposalContractSectionList_query",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ContractConnection",
      "kind": "LinkedField",
      "name": "allContracts",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ContractEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Contract",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "contractId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isActive",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "ContractListItem_contract"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProposalConnection",
      "kind": "LinkedField",
      "name": "allProposals",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProposalEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Proposal",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "serviceRequestId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "expiresAt",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "status",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "ProposalListItem_proposal"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "6722fcbc0c81d2b3861dda3593b9c8bf";

export default node;
