import React, { useEffect } from 'react';
import { ScrollView } from 'react-native';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { useQueryLoader } from 'react-relay/hooks';
import { CustomerNavigationParamList } from '../Navigator/CustomerStackNavigator';
import ProposalDetail, { proposalDetailQuery } from '../Component/ProposalDetail';
import { CustomerStackNavigationProp } from '../Navigator/CustomerStackNavigationProp';
import { events, trackEvent } from '../../../Infrastructure/Tracking/Tracking';
import { withLayout } from '../../HOC';
import { ProposalDetailQuery } from '../Component/__generated__/ProposalDetailQuery.graphql';

type NewAppointmentScreenRouteProp = RouteProp<CustomerNavigationParamList, 'ProposalDetailView'>;

export default withLayout(function ProposalDetailScreen() {
  const navigation = useNavigation<CustomerStackNavigationProp<'ProposalDetailView'>>();
  const [preloadedQuery, loadQuery] = useQueryLoader<ProposalDetailQuery>(proposalDetailQuery);

  const {
    params: { serviceRequestId },
  } = useRoute<NewAppointmentScreenRouteProp>();

  useEffect(() => {
    loadQuery(
      { serviceRequestId },
      {
        fetchPolicy: 'store-and-network',
      },
    );
  }, [serviceRequestId, loadQuery]);

  const onAccept = () => {
    navigation.navigate('CustomerDashboard');

    trackEvent(events.PROPOSAL_ACCEPT_CLICK);
  };

  const onDecline = () => {
    navigation.navigate('DeclineProposal', {
      serviceRequestId,
    });

    trackEvent(events.PROPOSAL_DECLINE_CLICK);
  };

  return (
    <ScrollView>
      {preloadedQuery && (
        <ProposalDetail
          preloadedQuery={preloadedQuery}
          serviceRequestId={serviceRequestId}
          onAccept={onAccept}
          onDecline={onDecline}
        />
      )}
    </ScrollView>
  );
});
