import React from 'react';
import { View, StyleSheet } from 'react-native';
import { useIntl, FormattedMessage } from 'react-intl';
import { useFragment, graphql } from 'react-relay/hooks';
import { Text, Title } from '..';
import { CareshipColors } from '../CSTheme';
import { MonthlyEarnings_monthlyBalance$key } from './__generated__/MonthlyEarnings_monthlyBalance.graphql';
import CardWithTooltip from './CardWithTooltip';
import useBreakpoints from '../../Root/Theme/useBreakpoints';
import useProfile from '../../../Context/Profile/Hook/useProfile';

const styles = StyleSheet.create({
  container: {
    display: 'flex',
  },
  title: {
    alignSelf: 'center',
    marginBottom: 8,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  hoursSection: {
    borderRightColor: CareshipColors.white,
    borderRightWidth: 2,
    flex: 1,
  },
  hoursSectionUnitsSpacing: {
    width: 2,
  },
  earningsSection: {
    borderLeftColor: CareshipColors.white,
    flex: 1,
  },
  cardSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: '50%',
    paddingHorizontal: 16,
    paddingVertical: 0,
    marginVertical: 16,
  },
  cardSectionTitle: {
    marginBottom: 8,
  },
  hoursContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
  },
  minutesContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    marginLeft: 8,
  },
});

export default function MonthlyEarnings({ stats }: { stats: MonthlyEarnings_monthlyBalance$key }) {
  const { profile } = useProfile();
  const isCareshipEmployee = profile?.isCareshipEmployee ?? false;

  const { monthlyDocumentedDurationInMinutes, monthlyDocumentedGrossEarningsInCents } = useFragment(
    graphql`
      fragment MonthlyEarnings_monthlyBalance on CaregiverStats {
        monthlyDocumentedDurationInMinutes
        monthlyDocumentedGrossEarningsInCents
      }
    `,
    stats,
  );

  const hours = Math.floor(monthlyDocumentedDurationInMinutes / 60);
  const minutes = monthlyDocumentedDurationInMinutes % 60;

  const { down } = useBreakpoints();
  const smDown = down('sm');
  const cardSectionValueSize = smDown(true) ? 'h3' : 'h1';
  const cardSectionUnitSize = smDown(true) ? 'h5' : 'h3';

  const intl = useIntl();

  return (
    <View style={styles.container}>
      <CardWithTooltip
        tooltipContent={
          <>
            <Title style={{ color: '#fff', marginBottom: 4 }} size="h6">
              <FormattedMessage
                id={
                  isCareshipEmployee
                    ? 'MONTHLY_HOURS_TOOLTIP_TITLE'
                    : 'MONTHLY_EARNINGS_TOOLTIP_TITLE'
                }
              />
            </Title>
            <Text style={{ color: '#fff' }}>
              <FormattedMessage
                id={
                  isCareshipEmployee
                    ? 'MONTHLY_HOURS_TOOLTIP_BODY'
                    : 'MONTHLY_EARNINGS_TOOLTIP_BODY'
                }
              />
            </Text>
          </>
        }
        title={
          <Text center style={{ width: '100%' }}>
            <Text center bold uppercase>
              <FormattedMessage
                id={
                  isCareshipEmployee
                    ? 'MONTHLY_EARNINGS_DOCUMENTED_HOURS'
                    : 'MONTHLY_EARNINGS_TITLE_1'
                }
              />{' '}
            </Text>
            <Text center>
              <FormattedMessage
                id="MONTHLY_EARNINGS_TITLE_2"
                values={{
                  month: intl.formatDate(new Date(), {
                    month: 'long',
                  }),
                }}
              />
            </Text>
          </Text>
        }
      >
        <View style={styles.row}>
          <View style={[styles.cardSection, isCareshipEmployee ? {} : styles.hoursSection]}>
            {!isCareshipEmployee && (
              <Text size="small" center uppercase style={styles.cardSectionTitle}>
                <FormattedMessage id="MONTHLY_EARNINGS_DOCUMENTED_HOURS" />
              </Text>
            )}
            <View testID="documentedHours">
              <View style={styles.hoursContainer}>
                <Title size={cardSectionValueSize}>{hours}</Title>
                <View style={styles.hoursSectionUnitsSpacing} />
                <Title size={cardSectionUnitSize} thin>
                  {'\u2009'}
                  <FormattedMessage id="MONTHLY_EARNINGS_HOURS" />
                </Title>
              </View>
              {minutes > 0 && (
                <View style={styles.minutesContainer}>
                  <Title size={cardSectionValueSize}>{minutes}</Title>
                  <View style={styles.hoursSectionUnitsSpacing} />
                  <Title size={cardSectionUnitSize} thin>
                    {'\u2009'}
                    <FormattedMessage id="MONTHLY_EARNINGS_MINUTES" />
                  </Title>
                </View>
              )}
            </View>
          </View>

          {!isCareshipEmployee && (
            <View style={[styles.cardSection, styles.earningsSection]}>
              <Text size="small" center uppercase style={styles.cardSectionTitle}>
                <FormattedMessage id="MONTHLY_EARNINGS_EARNINGS" />
              </Text>
              <Title size={cardSectionValueSize} testID="earnedValue">
                {intl
                  .formatNumberToParts(monthlyDocumentedGrossEarningsInCents / 100, {
                    style: 'currency',
                    currency: 'EUR',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                  .map(({ type, value }) =>
                    type === 'currency' || type === 'literal' ? (
                      <Title key={value} size={cardSectionUnitSize} thin>
                        {value}
                      </Title>
                    ) : (
                      value
                    ),
                  )}
              </Title>
            </View>
          )}
        </View>
      </CardWithTooltip>
    </View>
  );
}
