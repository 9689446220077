import { createTheme } from '@material-ui/core';
import { CareshipColors } from '../../Component/CSTheme';

export default createTheme({
  palette: {
    primary: {
      main: CareshipColors.primary,
    },
  },
  typography: {
    fontFamily: 'basier-circle-regular',
  },
  overrides: {
    MuiInput: {
      input: {
        paddingTop: 1,
        paddingLeft: 8,
        fontSize: 18,
        textAlign: 'right',
        color: CareshipColors.slate400,
        '&::after': {
          display: 'none',
        },
      },
      underline: {
        '&:after, &:before': {
          display: 'none',
        },
      },
    },
    MuiFormControl: {
      root: {
        marginLeft: 'auto',
      },
    },
  },
});
