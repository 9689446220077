import { SectionList } from 'react-native';
import React, { ReactElement, ComponentProps, useMemo } from 'react';
import { startOfMonth } from 'date-fns';
import BookingListItem from '../BookingListItem/BookingListItem';
import { BookingContractTuple } from '../../../../Context/Booking/Hook/useBookings';
import styles from './BookingList.styles';
import DateSectionListHeader from './DateSectionListHeader';
import Booking from '../../../../Context/Booking/Store/Model/Booking';

type BookingListProps = {
  emptyListPlaceholder?: ReactElement;
  bookings: BookingContractTuple[];
  ListHeaderComponent?: ComponentProps<typeof SectionList>['ListHeaderComponent'];
  onBookingSelected: (booking: Booking) => void;
};

export default function BookingList({
  emptyListPlaceholder,
  onBookingSelected,
  bookings,
  ListHeaderComponent,
}: BookingListProps) {
  const sections = useMemo(() => {
    const byMonth = bookings.reduce<{ [key: string]: (typeof bookings)[0][] }>((carry, entry) => {
      const ym = startOfMonth(entry.booking.startDate).toISOString();
      if (Array.isArray(carry[ym])) {
        carry[ym].push(entry);
      } else {
        // eslint-disable-next-line no-param-reassign
        carry[ym] = [entry];
      }

      return carry;
    }, {});

    return Object.keys(byMonth).map((k) => ({ title: k, data: byMonth[k] }));
  }, [bookings]);

  if (emptyListPlaceholder && !bookings.length) {
    return (
      <SectionList
        sections={[{ data: [{}] }]}
        keyExtractor={() => ''}
        ListHeaderComponent={ListHeaderComponent}
        renderItem={emptyListPlaceholder ? () => emptyListPlaceholder : undefined}
      />
    );
  }

  return (
    <SectionList
      sections={sections}
      keyExtractor={(item) => item.booking.bookingId}
      ListHeaderComponent={ListHeaderComponent}
      stickySectionHeadersEnabled
      renderItem={({ item: { booking, contract }, index, section }) => (
        <BookingListItem
          key={booking.bookingId}
          onPress={() => onBookingSelected(booking)}
          style={[index === section.data.length - 1 ? styles.lastItem : undefined]}
          booking={booking}
          contract={contract}
        />
      )}
      renderSectionHeader={({ section: { title } }) => <DateSectionListHeader date={title} />}
    />
  );
}
