export type AddressResponse = {
  readonly street: string;
  readonly postal_code: string;
  readonly city: string;
};

export default class Address {
  constructor(
    public readonly street: string,
    public readonly postalCode: string,
    public readonly city: string,
  ) {}

  get fullAddress() {
    return `${this.street} ${this.postalCode} ${this.city}`;
  }

  public static fromObject(address: Address) {
    return new Address(address.street, address.postalCode, address.city);
  }

  static fromResponse(response: AddressResponse) {
    return new Address(response.street, response.postal_code, response.city);
  }
}
