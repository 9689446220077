/**
 * @generated SignedSource<<ff911bda2ebb1dfecc559bdda05a2092>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateCaregiverServicesInput = {
  subServiceIds: ReadonlyArray<string>;
  clientMutationId?: string | null;
};
export type ServicesMutation$variables = {
  input: UpdateCaregiverServicesInput;
};
export type ServicesMutation$data = {
  readonly updateCaregiverServices: {
    readonly subServiceIds: ReadonlyArray<string>;
  } | null;
};
export type ServicesMutation = {
  variables: ServicesMutation$variables;
  response: ServicesMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateCaregiverServicesPayload",
    "kind": "LinkedField",
    "name": "updateCaregiverServices",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "subServiceIds",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ServicesMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ServicesMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "eec2cedaead007a731cd2fe609082873",
    "id": null,
    "metadata": {},
    "name": "ServicesMutation",
    "operationKind": "mutation",
    "text": "mutation ServicesMutation(\n  $input: UpdateCaregiverServicesInput!\n) {\n  updateCaregiverServices(input: $input) {\n    subServiceIds\n  }\n}\n"
  }
};
})();

(node as any).hash = "2196505134ffaad26e65668300b24470";

export default node;
