/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import Constants from 'expo-constants';
import { Platform } from 'react-native';
import Manifest from '../Constants/Manifest';
import { Logger } from './Logger';
import { OauthServiceInterface } from '../Contract/OauthServiceInterface';

const { apiEndpoint } = Manifest.extra as { apiEndpoint: string };

const apiBackendUrl = Constants?.expoConfig?.extra?.apiBackendUrl;

const apiURI: string = Platform.OS === 'web' ? apiEndpoint : apiBackendUrl;

export type SuccessResponse<R> = { status: number; data: R };
export type ErrorResponse = { status: number | string; errorCode: string };

/**
 * Type guards for inferring the type of a response
 */
export function isError<S>(result: SuccessResponse<S> | ErrorResponse): result is ErrorResponse {
  return (result as ErrorResponse).errorCode !== undefined;
}

export function isSuccess<S>(
  result: SuccessResponse<S> | ErrorResponse,
): result is SuccessResponse<S> {
  return (result as SuccessResponse<S>).data !== undefined;
}
export interface AuthorizedClientInterface {
  request: <R = unknown>(
    endpoint: string,
    init?: RequestInit,
  ) => Promise<SuccessResponse<R> | ErrorResponse>;
}

export default class AuthorizedClient implements AuthorizedClientInterface {
  constructor(private oauthService: OauthServiceInterface, private logger: Logger) {}

  public async request<R = unknown>(
    endpoint: string,
    init?: RequestInit,
  ): Promise<SuccessResponse<R> | ErrorResponse> {
    const url = `${apiURI}${endpoint}`;

    try {
      let result = await this.fetch(url, init);

      if (result.status === 401) {
        this.logger.debug('token invalid, attempting to refresh');
        // token not valid, retry
        await this.oauthService.refreshToken();

        result = await this.fetch(url, init);
      }

      if (result.status >= 300) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const json = (await result.json()) as { error_code?: string; error?: string };
        const { error_code: errorCode, error } = json;

        return {
          status: result.status,
          errorCode: errorCode || error || 'unexpected server error',
        };
      }

      return { status: result.status, data: (await result.json()) as R };
    } catch (e) {
      return {
        status: (e as Error).message,
        errorCode: 'OAUTH_ERROR',
      };
    }
  }

  private async fetch(endpoint: string, init?: RequestInit): Promise<Response> {
    const token = await this.oauthService.asyncGetToken();

    const authorizedInit = {
      ...(init || {}),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token || ''}`,
        ...(init?.headers || {}),
      },
    };

    this.logger.debug(`fetch [${authorizedInit.method}] ${endpoint}`, {
      ...authorizedInit,
      headers: {
        ...authorizedInit.headers,
        Authorization: `${authorizedInit.headers.Authorization.slice(
          0,
          12,
        )}...${authorizedInit.headers.Authorization.slice(
          authorizedInit.headers.Authorization.length - 8,
        )}`,
      },
    });

    return fetch(endpoint, authorizedInit);
  }
}
