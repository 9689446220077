import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { Platform } from 'react-native';
import { Layout } from '../../Component';
import ServiceDocumetationHowTo from '../Component/ServiceDocumentationHowTo';
import { CustomerStackNavigationProp } from '../Navigator/CustomerStackNavigationProp';

export default function ServiceDocumentationHowToScreen() {
  const navigation = useNavigation<CustomerStackNavigationProp<'ServiceDocumentationHowTo'>>();

  const onPresshowToFillServiceDocumentation = () => {
    const uri =
      'https://www.careship.de/app/uploads/2022/01/Leistungsnachweis_Ausfuellhilfe_202111.pdf';

    if (Platform.OS === 'web') {
      window.location.href = uri;

      return;
    }

    navigation.navigate('PdfView', {
      filename: 'Leistungsnachweis_Ausfuellhilfe_202111.pdf',
      headerTitle: 'Leistungsnachweis Ausfuellhilfe',
      uri,
    });
  };

  return (
    <Layout>
      <ServiceDocumetationHowTo
        onPresshowToFillServiceDocumentation={onPresshowToFillServiceDocumentation}
      />
    </Layout>
  );
}
