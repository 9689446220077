import React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';
import { CareshipColors } from '../CSTheme';

type MobilePhoneIconProps = SvgProps & {
  color?: string;
  size?: number;
};

export default function MobilePhoneIcon({
  color = CareshipColors.slate400,
  size = 24,
  ...props
}: MobilePhoneIconProps) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" {...props}>
      <Path
        d="M15.75.527h-7.5a3.75 3.75 0 0 0-3.75 3.75v15.499a3.75 3.75 0 0 0 3.75 3.75h7.5a3.75 3.75 0 0 0 3.75-3.75V4.277a3.75 3.75 0 0 0-3.75-3.75Zm-7.5 1.5h7.5A2.25 2.25 0 0 1 18 4.277v15.499a2.25 2.25 0 0 1-2.25 2.25h-7.5A2.25 2.25 0 0 1 6 19.776V4.277a2.25 2.25 0 0 1 2.25-2.25Zm4.9 17.098a1.125 1.125 0 1 1-2.25 0 1.125 1.125 0 0 1 2.25 0Z"
        fill={color}
      />
    </Svg>
  );
}
