import React from 'react';
import { useLazyLoadQuery } from 'react-relay/hooks';
import { graphql } from 'react-relay';
import { ScrollView } from 'react-native-gesture-handler';
import { Layout } from '../../Component';
import EditFinancialDetailsForm from '../Component/EditFinancialDetailsForm';
import { EditFinancialDetailsScreenQuery } from './__generated__/EditFinancialDetailsScreenQuery.graphql';

function EditFinancialDetailsContainer() {
  const data = useLazyLoadQuery<EditFinancialDetailsScreenQuery>(
    graphql`
      query EditFinancialDetailsScreenQuery {
        me {
          ... on Caregiver {
            ...EditFinancialDetailsFormFragment
          }
        }
      }
    `,
    {},
  );

  return (
    <ScrollView>
      <EditFinancialDetailsForm fragmentRef={data.me} />
    </ScrollView>
  );
}

export default function EditFinancialDetailsScreen() {
  return (
    <Layout>
      <EditFinancialDetailsContainer />
    </Layout>
  );
}
