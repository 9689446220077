/**
 * @generated SignedSource<<cc248a70ab8adb27838a0d68bc23a440>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditAboutMeScreenQuery$variables = {};
export type EditAboutMeScreenQuery$data = {
  readonly me: {
    readonly " $fragmentSpreads": FragmentRefs<"AboutMeFormFragment">;
  };
};
export type EditAboutMeScreenQuery = {
  variables: EditAboutMeScreenQuery$variables;
  response: EditAboutMeScreenQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "EditAboutMeScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "AboutMeFormFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "EditAboutMeScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "aboutMe",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "currentPosition",
                "storageKey": null
              }
            ],
            "type": "Caregiver",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v0/*: any*/)
            ],
            "type": "Node",
            "abstractKey": "__isNode"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "dc0f3cf5a0e58b5ac9549993f5ea6c23",
    "id": null,
    "metadata": {},
    "name": "EditAboutMeScreenQuery",
    "operationKind": "query",
    "text": "query EditAboutMeScreenQuery {\n  me {\n    __typename\n    ...AboutMeFormFragment\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n}\n\nfragment AboutMeFormFragment on Caregiver {\n  id\n  aboutMe\n  currentPosition\n}\n"
  }
};
})();

(node as any).hash = "bfddd03c68a8b79e9388a60c4c025842";

export default node;
