import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';
import CSTheme from '../CSTheme';

type Edit2IconProps = SvgProps & {
  color?: string;
  accentColor?: string;
  size?: number;
};

export default function Edit2Icon({
  color = CSTheme.colors.text,
  accentColor,
  size = 24,
  ...props
}: Edit2IconProps) {
  return (
    <Svg viewBox="0 0 24 24" width={size} height={size} {...props}>
      <Path
        d="M10.75,3.50101378 C11.1642136,3.50101378 11.5,3.83680022 11.5,4.25101378 C11.5,4.63070954 11.2178461,4.94450474 10.8517706,4.99416716 L10.75,5.00101378 L3.25,5.00101378 C2.87030423,5.00101378 2.55650904,5.28316766 2.50684662,5.64924322 L2.5,5.75101378 L2.5,20.7510138 C2.5,21.1307095 2.78215388,21.4445047 3.14822944,21.4941672 L3.25,21.5010138 L18.25,21.5010138 C18.6296958,21.5010138 18.943491,21.2188599 18.9931534,20.8527843 L19,20.7510138 L19,13.2510138 C19,12.8368002 19.3357864,12.5010138 19.75,12.5010138 C20.1296958,12.5010138 20.443491,12.7831677 20.4931534,13.1492432 L20.5,13.2510138 L20.5,20.7510138 C20.5,21.9418778 19.5748384,22.9166587 18.4040488,22.995823 L18.25,23.0010138 L3.25,23.0010138 C2.05913601,23.0010138 1.08435508,22.0758522 1.00519081,20.9050626 L1,20.7510138 L1,5.75101378 C1,4.56014979 1.92516159,3.58536886 3.09595119,3.50620459 L3.25,3.50101378 L10.75,3.50101378 Z"
        fill={color}
      />
      <Path
        d="M18.3786699,1.37868369 C19.5502482,0.207105436 21.4497518,0.207105436 22.6213301,1.37868369 C23.7929083,2.55026195 23.7929083,4.44976561 22.6213301,5.62134386 L22.6213301,5.62134386 L13.0753301,15.1673439 C12.9606167,15.2820573 12.8118005,15.3564829 12.651206,15.3794559 L12.651206,15.3794559 L8.93920602,15.9104559 C8.44422128,15.9812632 8.01986787,15.5570378 8.09052589,15.0620317 L8.09052589,15.0620317 L8.62052589,11.3490317 C8.64346134,11.1883537 8.71790176,11.0394518 8.83266991,10.9246837 L8.83266991,10.9246837 Z M17.8477998,4.031 L10.07,11.8080138 L9.716,14.2830138 L12.191,13.9290138 L19.9687998,6.152 L17.8477998,4.031 Z M21.5606699,2.43934386 C20.9748781,1.85355205 20.0251219,1.85355205 19.4393301,2.43934386 L19.4393301,2.43934386 L18.9087998,2.97 L21.0297998,5.091 L21.5606699,4.56068369 C22.0732378,4.04811585 22.1373087,3.25688779 21.7528829,2.67465489 L21.6636411,2.55326732 Z"
        fill={accentColor || color}
      />
    </Svg>
  );
}
