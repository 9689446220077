export type PricingResponse = Readonly<{
  gross_price_per_hour_in_cents: number;
}>;

export default class Pricing {
  constructor(public readonly grossPricePerHourInCents: number) {}

  public static fromObject(response: Pricing) {
    return new Pricing(response.grossPricePerHourInCents);
  }

  public static fromResponse(response: PricingResponse) {
    return new Pricing(response.gross_price_per_hour_in_cents);
  }
}
