import React, { useCallback } from 'react';
import { Platform, StyleSheet, View } from 'react-native';
import { Feather } from '@expo/vector-icons';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { TouchableRipple } from 'react-native-paper';
import { isPast } from 'date-fns';
import { Button, CSTheme, Text, Title } from '../../Component';

const styles = StyleSheet.create({
  card: {
    backgroundColor: CSTheme.colors.background,
    borderRadius: 8,
    ...Platform.select({
      default: {
        shadowColor: '#000',
        shadowOffset: {
          width: 0,
          height: 0,
        },
        shadowOpacity: 0.16,
        shadowRadius: 1.25,
        elevation: 1,
      },
      web: {
        shadowOpacity: 0.16,
        shadowRadius: 4,
      },
    }),
    width: '100%',
    marginVertical: 24,
  },
  button: {
    borderRadius: 8,
    overflow: 'hidden',
    paddingVertical: 36,
    alignItems: 'center',
  },
  appointment: {
    marginBottom: 16,
  },
  time: {
    fontSize: 56,
    lineHeight: 67,
    ...CSTheme.fonts.medium,
    marginTop: 4,
  },
  today: {
    marginBottom: 24,
  },
  addressRow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    marginRight: 8,
  },
  documentButton: {
    marginTop: 24,
  },
});

type HighlightedBookingProps = {
  name: string;
  startTime: Date;
  address: string;
  bookingId: string;
  onPress: (bookingId: string) => void;
  onDocumentBookingPress: () => void;
};

export default function HighlightedBooking({
  address,
  name,
  startTime,
  onPress,
  bookingId,
  onDocumentBookingPress,
}: HighlightedBookingProps) {
  const handlePress = useCallback(() => {
    onPress(bookingId);
  }, [onPress, bookingId]);

  return (
    <View style={styles.card}>
      <TouchableRipple style={styles.button} onPress={handlePress}>
        <>
          <Text style={styles.appointment} uppercase size="large" bold>
            <FormattedMessage id="APPOINTMENT" />
          </Text>
          <Title thin size="h1">
            {name}
          </Title>
          <Text style={styles.time}>
            <FormattedDate value={startTime} hour="2-digit" minute="2-digit" hour12={false} />
          </Text>
          <Title style={styles.today}>
            <FormattedMessage id="TODAY" />
          </Title>
          <View style={styles.addressRow}>
            <Feather style={styles.icon} name="map-pin" size={24} color={CSTheme.colors.text} />
            <Text size="large">{address}</Text>
          </View>
          {isPast(startTime) && (
            <Button
              style={styles.documentButton}
              size="sm"
              icon={<Feather color="white" size={24} name="edit" />}
              onPress={() => onDocumentBookingPress()}
            >
              <FormattedMessage id="BUTTON_DOCUMENT_BOOKING_NOW" />
            </Button>
          )}
        </>
      </TouchableRipple>
    </View>
  );
}
