import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import { SpacedBoxProps } from '@emotion/react';
import useBreakpoints from './useBreakpoints';

export type Spacings = 'none' | 'tight' | 'base' | 'loose' | 'extraLoose';
export type SpacingToken = 'none' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

export const spacingTokens = {
  none: 0,
  xs: 4,
  sm: 8,
  md: 16,
  lg: 24,
  xl: 32,
  xxl: 48,
} as const;

export type SpacingTokens = typeof spacingTokens;

export default (dict: Record<Spacings, Partial<Record<Breakpoint, number>>>) => {
  const { up } = useBreakpoints();

  const spacing = (
    key: undefined | Spacings,
    adjustValue: (size: number) => number = (s) => s,
  ): number | undefined => {
    if (!key) {
      return undefined;
    }

    // override breakpoints and remove margins
    if (key === 'none') {
      return 0;
    }

    return (Object.entries(dict[key]) as [Breakpoint, number][]).reduce(
      (carry, [bp, value]) => up(bp, adjustValue(value)) || carry,
      undefined as undefined | number,
    );
  };

  return function spacingStyles({
    bottommost,
    margin,
    marginBottom,
    marginHorizontal,
    marginRight,
    marginLeft,
    padding,
    topmost,
    marginTop,
    marginVertical,
  }: SpacedBoxProps) {
    const mb = marginBottom || marginVertical || margin;
    const mt = marginTop || marginVertical || margin;
    const mr = marginRight || marginHorizontal || margin;
    const ml = marginLeft || marginHorizontal || margin;

    return {
      marginBottom: spacing(mb, bottommost ? (v) => v * 2 : undefined),
      marginTop: spacing(mt, topmost ? (v) => v * 2 : undefined),
      marginLeft: spacing(ml),
      marginRight: spacing(mr),
      padding: spacing(padding),
    };
  };
};
