import { PreloadedQuery, usePreloadedQuery, useMutation } from 'react-relay/hooks';
import { graphql } from 'react-relay';
import React, { useLayoutEffect, useCallback, useRef } from 'react';
import styled from '@emotion/native';
import { FormattedMessage } from 'react-intl';
import { FlatList, Platform, View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { Feather } from '@expo/vector-icons';
import { IconButton } from 'react-native-paper';
import { useForm, Controller } from 'react-hook-form';
import { record, array } from 'fp-ts';
import { pipe } from 'fp-ts/function';
import { CheckboxItem, Button, HeaderActionButton, CSTheme } from '../../Component';
import {
  EducationQualificationsQuery,
  CaregiverEducationAndQualificationEnum,
} from './__generated__/EducationQualificationsQuery.graphql';
import { intl } from '../../../Infrastructure/Intl';
import { EducationQualificationsMutation } from './__generated__/EducationQualificationsMutation.graphql';
import { logger } from '../../../Infrastructure/Service';
import usePlainAlert from '../../Dialog/useShowAlert';
import StickyFooter from '../../Component/StickyFooter';

const SaveButton = styled(Button)({
  marginVertical: 24,
  marginHorizontal: 16,
});

interface EducationQualificationsProps {
  preloadedQuery: PreloadedQuery<EducationQualificationsQuery>;
}

export const educationQualificationsQuery = graphql`
  query EducationQualificationsQuery($locale: Locale) {
    me {
      ... on Caregiver {
        id
        educationAndQualifications {
          key
        }
      }
    }
    definitions {
      educationAndQualifications {
        key
        label(locale: $locale)
      }
    }
  }
`;

export default function EducationQualifications({ preloadedQuery }: EducationQualificationsProps) {
  const footerButtonsRef = useRef<View>(null);
  const {
    me: { educationAndQualifications },
    definitions: { educationAndQualifications: educationAndQualificationsDefinitions },
  } = usePreloadedQuery(educationQualificationsQuery, preloadedQuery);

  const navigation = useNavigation();
  const showAlert = usePlainAlert();

  const onCompleted = useCallback(() => {
    navigation.goBack();
  }, [navigation]);

  const [commitMutation, isInFlight] = useMutation<EducationQualificationsMutation>(
    graphql`
      mutation EducationQualificationsMutation(
        $input: UpdateCaregiverEducationAndQualificationsInput!
      ) {
        updateCaregiverEducationAndQualifications(input: $input) {
          caregiver {
            id
            educationAndQualifications {
              key
            }
          }
        }
      }
    `,
  );

  const defaultValues = educationAndQualificationsDefinitions.reduce(
    (accum, { key }) => ({
      ...accum,
      [key]: Boolean(educationAndQualifications?.some(({ key: k }) => k === key)),
    }),
    {},
  );

  const {
    handleSubmit,
    control,
    formState: { isDirty },
    getValues,
  } = useForm<Record<CaregiverEducationAndQualificationEnum, boolean>>({
    mode: 'onSubmit',
    defaultValues,
  });

  const handleSave = useCallback(() => {
    const selectedEducationAndQualifications = pipe(
      record.toArray(getValues()),
      array.filter(([, v]) => v),
      array.map(([v]) => v),
    );

    commitMutation({
      variables: {
        input: {
          educationAndQualifications: selectedEducationAndQualifications,
        },
      },
      onCompleted,
      onError: (error) => {
        logger.error('EducationQualificationsMutation failed', { relayError: error });

        showAlert(intl.formatMessage({ id: 'ERROR_UNKNOWN' }));
      },
    });
  }, [commitMutation, onCompleted, showAlert, getValues]);

  const handlePressSubmit = useCallback(() => {
    void handleSubmit(handleSave)();
  }, [handleSave, handleSubmit]);

  useLayoutEffect(() => {
    const headerRight = () =>
      Platform.select({
        ios: (
          <HeaderActionButton
            onPress={handlePressSubmit}
            disabled={!isDirty || isInFlight}
            title={intl.formatMessage({ id: 'BUTTON_SAVE' })}
          />
        ),
        android: (
          <IconButton
            style={{ marginRight: 8 }}
            disabled={!isDirty || isInFlight}
            onPress={handlePressSubmit}
            icon={() => <Feather color={CSTheme.colors.background} size={24} name="save" />}
          />
        ),
      });

    navigation.setOptions({
      headerRight,
    });
  }, [handlePressSubmit, isDirty, navigation, isInFlight]);

  const saveButton = (
    <SaveButton disabled={!isDirty || isInFlight} onPress={handlePressSubmit}>
      <FormattedMessage id="BUTTON_SAVE" />
    </SaveButton>
  );

  return (
    <>
      <FlatList
        style={{ flex: 1 }}
        contentContainerStyle={{ flex: 1, flexShrink: 0 }}
        ListHeaderComponent={<View style={{ height: 32 }} />}
        data={educationAndQualificationsDefinitions}
        keyExtractor={(item) => item.key}
        renderItem={({ item: { key, label } }) => (
          <Controller
            key={key}
            name={key}
            control={control}
            render={({ field: { value, onChange } }) => (
              <CheckboxItem
                onPress={() => onChange(!value)}
                status={value ? 'checked' : 'unchecked'}
                label={label}
              />
            )}
          />
        )}
        ListFooterComponentStyle={{ marginTop: 'auto' }}
        ListFooterComponent={
          <View style={{ marginTop: 'auto' }} collapsable={false} ref={footerButtonsRef}>
            {saveButton}
          </View>
        }
      />
      <StickyFooter sticky={isDirty} alignWithRef={footerButtonsRef}>
        {saveButton}
      </StickyFooter>
    </>
  );
}
