import React, { ReactNode } from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import styles from './DividingHeader.styles';
import Title from './Title';

export default function DividingHeader({
  noGutter,
  children,
  rightGutter,
  style,
}: {
  noGutter?: boolean;
  rightGutter?: boolean;
  children?: ReactNode;
  style?: StyleProp<ViewStyle>;
}) {
  return (
    <View
      style={[
        styles.titleContainer,
        noGutter && styles.noGutter,
        rightGutter && styles.rightGutter,
        style,
      ]}
    >
      <Title size="h5" style={styles.title}>
        {children}
      </Title>
      <View style={styles.titleAfter} />
    </View>
  );
}
