import React, { useCallback } from 'react';
import { Linking, Platform, StyleSheet, TextProps } from 'react-native';
import Text from './Text';

interface MailtoLinkProps extends TextProps {
  to: string;
  subject?: string;
  body?: string;
}

const toQuery = (params: Record<string, string | undefined>): string => {
  let queryParams = Object.entries<string | undefined>(params)
    .filter((entry): entry is [string, string] => Boolean(entry[1]))
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    .join('&');

  if (queryParams.length > 0) {
    queryParams = `?${queryParams}`;
  }

  return queryParams;
};

const MailtoLink = ({ body, subject, to, style, children, ...rest }: MailtoLinkProps) => {
  const link = `mailto:${to}${toQuery({ body, subject })}`;

  const handlePress = useCallback<() => void>(() => Linking.openURL(link), [link]);

  return Platform.select({
    web: (
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      <a {...rest} style={StyleSheet.flatten(style)} href={link}>
        {children}
      </a>
    ),
    default: (
      <Text {...rest} style={style} onPress={handlePress}>
        {children}
      </Text>
    ),
  });
};

export default MailtoLink;
