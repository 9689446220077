import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {Button, CheckboxItem, Container, Label, RadioButtonGroup, RadioButtonItem, Title} from '../../../Component';
import BookingFeedbackPrivacyHint from './BookingFeedbackPrivacyHint';
import { toggleSet } from '../../../../Infrastructure/Set';
import {TextInput} from "react-native-paper";
import {StyleSheet} from "react-native";

interface QuestionTwoProps {
  onSubmit: (value: { customerEnergy: string, customerEnergyAdditionalText: string }) => void;
  isLoading: boolean;
}

const styles = StyleSheet.create({
  container: {
    marginTop: 15,
  }
});

export default function QuestionTwo({ onSubmit, isLoading }: QuestionTwoProps) {
  const intl = useIntl();
  const [fieldValue, setFieldValue] = useState<string>('');
  const [additionalText, setAdditionalTextValue] = useState<string>('');
  const options = [
    {
      value: 'Sehr hoch',
      label: intl.formatMessage({ id: 'BOOKING_FEEDBACK_ENERGY_VERY_HIGH' }),
    },
    {
      value: 'Hoch',
      label: intl.formatMessage({ id: 'BOOKING_FEEDBACK_ENERGY_HIGH' }),
    },
    {
      value: 'Mittelmäßig',
      label: intl.formatMessage({ id: 'BOOKING_FEEDBACK_ENERGY_FAIR' }),
    },
    {
      value: 'Schwankend',
      label: intl.formatMessage({ id: 'BOOKING_FEEDBACK_ENERGY_SO_LALA' }),
    },
    {
      value: 'Niedrig',
      label: intl.formatMessage({ id: 'BOOKING_FEEDBACK_ENERGY_LOW' }),
    },
  ];

  return (
    <>
      <Container style={styles.container}>
        <Title size="h5" center>
          <FormattedMessage id="BOOKING_FEEDBACK_TITLE_QUESTION_TWO" />
        </Title>
      </Container>
      <Container style={styles.container}>
        <RadioButtonGroup value={fieldValue} onValueChange={(value) => setFieldValue(value)}>
          {options.map((opt) => (
            <RadioButtonItem
              key={opt.value}
              value={opt.value}
              label={opt.label}
            />
          ))}
        </RadioButtonGroup>
      </Container>
      <Container style={styles.container}>
        <Label />
        <TextInput mode='outlined' placeholder={intl.formatMessage({ id: 'BOOKING_FEEDBACK_MOOD_FREE_TEXT' })} onChangeText={(additionalText) => setAdditionalTextValue(additionalText)}/>
      </Container>
      <Container style={styles.container}>
        {/*<BookingFeedbackPrivacyHint />*/}
        <Button
          disabled={!fieldValue|| isLoading}
          loading={isLoading}
          onPress={() => onSubmit({ customerEnergy: fieldValue, customerEnergyAdditionalText: additionalText})}
        >
          <FormattedMessage id="BOOKING_FEEDBACK_SUBMIT_STEP_2" />
        </Button>
      </Container>
    </>
  );
}
