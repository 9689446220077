import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';
import { CareshipColors } from '../CSTheme';

interface ArrowUpIconProps extends SvgProps {
  color?: string;
  size?: number;
}

function ArrowUpIcon({ color = CareshipColors.slate400, size = 24, ...props }: ArrowUpIconProps) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" {...props}>
      <Path
        fill={color}
        d="M12.0945567,3.00104204 L12.05,3 C12.0873356,3 12.124034,3.0027281 12.1599067,3.00799583 C12.1813889,3.01034847 12.2032407,3.01439588 12.2249127,3.0194172 C12.2369749,3.02338693 12.2489126,3.02655681 12.2607296,3.03000971 C12.2840549,3.03529386 12.30687,3.04298853 12.3293454,3.05180965 C12.3372781,3.05693294 12.3452907,3.06031326 12.353231,3.06382736 C12.4047991,3.08401038 12.4542391,3.11232752 12.5006171,3.14667851 L12.5847228,3.21915202 L18.6767382,9.29927501 C18.9699175,9.59188192 18.9703814,10.0667554 18.6777745,10.3599347 C18.4117682,10.6264613 17.9951284,10.6510744 17.7013042,10.4335069 L17.6171149,10.360971 L12.799,5.553 L12.8,20.25 C12.8,20.6642136 12.4642136,21 12.05,21 C11.6703042,21 11.356509,20.7178461 11.3068466,20.3517706 L11.3,20.25 L11.299,5.564 L6.50012131,10.3606618 C6.23374996,10.6268235 5.81707679,10.6508656 5.52355106,10.4328955 L5.43946122,10.3602443 C5.17329948,10.0938729 5.14925743,9.67719977 5.36722744,9.38367405 L5.43987869,9.2995842 L11.4833262,3.258677 C11.6208391,3.10021198 11.8237167,3 12.05,3 L12.0605038,3.00002071 C12.0718595,3.00010485 12.0832136,3.00044529 12.0945567,3.00104204 Z"
      />
    </Svg>
  );
}

export default ArrowUpIcon;
