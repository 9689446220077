import { NavigationContainerRef } from '@react-navigation/native';
import React, { FC, ReactNode } from 'react';
import navigationContainerRefContext from './navigationContainerRefContext';

interface NavigationContainerRefProviderProps {
  children: ReactNode;
  navigationContainerRef: NavigationContainerRef | null;
}

const NavigationContainerRefProvider: FC<NavigationContainerRefProviderProps> = ({
  children,
  navigationContainerRef,
}: NavigationContainerRefProviderProps) => {
  return (
    <navigationContainerRefContext.Provider value={navigationContainerRef}>
      {navigationContainerRef ? children : null}
    </navigationContainerRefContext.Provider>
  );
};

export default NavigationContainerRefProvider;
