import { View, StyleSheet, Platform } from 'react-native';
import { FormattedMessage } from 'react-intl';
import React, { useCallback } from 'react';
import { Feather } from '@expo/vector-icons';
import { graphql, useFragment } from 'react-relay/hooks';
import { useNavigation } from '@react-navigation/native';
import { Button, CSTheme } from '../../Component';
import { CareshipColors } from '../../Component/CSTheme';
import FirstCallChecklistButton from './FirstCallChecklist/CallChecklistButton';
import { FirstCallActions_contract$key } from './__generated__/FirstCallActions_contract.graphql';
import { openPhoneNumber } from '../../../Infrastructure/LinkHandler';
import { CustomerStackNavigationProp } from '../Navigator/CustomerStackNavigationProp';
import useProfile from '../../../Context/Profile/Hook/useProfile';

const styles = StyleSheet.create({
  firstCallButton: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 24,
  },
});

interface FirstCallActionsProps {
  contractFragment: FirstCallActions_contract$key;
}

export default function FirstCallActions({ contractFragment }: FirstCallActionsProps) {
  const { profile } = useProfile();
  const navigation = useNavigation<CustomerStackNavigationProp<'CustomerDetailView'>>();
  const {
    // contractId,
    firstBookingStatus,
    hasFulfilledBooking,
    careReceiver: { phoneNumber },
  } = useFragment(
    graphql`
      fragment FirstCallActions_contract on Contract {
        contractId
        firstBookingStatus
        hasFulfilledBooking
        careReceiver {
          phoneNumber
        }
      }
    `,
    contractFragment,
  );

  const handleFirstCallButtonPress = useCallback<() => void>(
    () => openPhoneNumber(phoneNumber),
    [phoneNumber],
  );
  // const handlePressFirstCallChecklist = useCallback(() => {
  //   navigation.navigate('FirstCallChecklist', {
  //     contractId,
  //   });

  //   trackEvent(events.FIRST_CALL_CHECKLIST_CLICK);
  // }, [navigation, contractId]);
  const handlePressFirstCallChecklist = () => {
    const uri = 'https://www.careship.de/app/uploads/2023/04/Neukunde_TelefonatErsttermin-4.pdf';
    if (Platform.OS === 'web') {
      window.location.href = uri;

      return;
    }

    navigation.navigate('PdfView', {
      filename: 'Neukunde_TelefonatErsttermin-4.pdf',
      headerTitle: 'Neukunde TelefonatErsttermin',
      uri,
    });
  };

  if (firstBookingStatus !== 'created' || hasFulfilledBooking) {
    return null;
  }

  if (profile?.isCareshipEmployee) {
    return <></>
  }

  return (
    <>
        <View style={styles.firstCallButton}>
          <Button
            size="sm"
            onPress={handleFirstCallButtonPress}
            icon={<Feather size={24} color={CareshipColors.white} name="phone-outgoing" />}
            theme={CSTheme}
          >
            <FormattedMessage id="FIRST_CALL_BUTTON" />
          </Button>
        </View>
        <FirstCallChecklistButton onPress={handlePressFirstCallChecklist} />
    </>
  );
}
