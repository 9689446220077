export type StatsResponse = {
  readonly booking_count: number;
  readonly minute_count: number;
  readonly gross_earnings_in_cents: number;
};

export default class Stats {
  constructor(
    public readonly bookingCount: number,
    public readonly minuteCount: number,
    public readonly grossEarningsInCents: number,
  ) {}

  public static fromObject(stats: Stats) {
    return new Stats(stats.bookingCount, stats.minuteCount, stats.grossEarningsInCents);
  }

  public static fromResponse(response: StatsResponse) {
    return new Stats(
      response.booking_count,
      response.minute_count,
      response.gross_earnings_in_cents,
    );
  }
}
