import React from 'react';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { Feather } from '@expo/vector-icons';
import styled from '@emotion/native';
import { ScrollView } from 'react-native-gesture-handler';
import { useLazyLoadQuery } from 'react-relay/hooks';
import { graphql } from 'react-relay';
import { AppNavigationParamList, AppStackNavigationProp } from '../../Root/Navigator/AppNavigator';
import ModalHeader from '../../Component/ModalHeader';
import { CareshipColors } from '../../Component/CSTheme';
import { intl } from '../../../Infrastructure/Intl';
import EditAbsenceForm from '../Component/EditAbsenceForm';
import { withLayout } from '../../HOC';
import { EditAbsenceScreenQuery } from './__generated__/EditAbsenceScreenQuery.graphql';

const Scroll = styled(ScrollView)({
  marginBottom: 32,
});

export default withLayout(function EditAbsenceScreen() {
  const {
    params: { id },
  } = useRoute<RouteProp<AppNavigationParamList, 'EditAbsence'>>();
  const navigation = useNavigation<AppStackNavigationProp<'EditAbsence'>>();

  const { node } = useLazyLoadQuery<EditAbsenceScreenQuery>(
    graphql`
      query EditAbsenceScreenQuery($id: ID!) {
        node(id: $id) {
          ... on Absence {
            id
            from
            to
            reason
          }
        }
      }
    `,
    { id },
  );

  const handleSuccess = () => {
    void navigation.goBack();
  };

  return (
    <Scroll>
      <ModalHeader
        backButton={<Feather name="x" size={24} color={CareshipColors.primary} />}
        title={intl.formatMessage({ id: 'ABSENCE_EDIT_SCREEN_TITLE' })}
      />
      {node && (
        <EditAbsenceForm
          onCompleted={handleSuccess}
          id={id}
          initialTo={node.to || ''}
          initialFrom={node.from || ''}
          initialReason={node.reason || ''}
        />
      )}
    </Scroll>
  );
});
