import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';
import CSTheme from '../CSTheme';

type EducationIconProps = SvgProps & {
  color?: string;
  size?: number;
};

export default function EducationIcon({ color, size = 24, ...props }: EducationIconProps) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" {...props}>
      <Path
        d="M10.958 4.215a2.75 2.75 0 012.133 0L23.035 8.4a.75.75 0 01-.033 1.396l-4.273 1.563.001 5.432a1.938 1.938 0 01-1.075 1.748l-.145.065-4.762 1.905a1.933 1.933 0 01-1.268.061l-.176-.06-4.763-1.906a1.94 1.94 0 01-1.216-1.65l-.006-.158v-5.436L2.05 10.162v4.884a.75.75 0 01-1.493.101l-.007-.101V9.17l.005-.091a.737.737 0 01.459-.68zm6.271 7.692l-4.26 1.56a2.75 2.75 0 01-1.89 0l-4.26-1.559v4.89c0 .156.08.298.21.377l.068.035 4.766 1.906c.078.032.163.04.245.024l.081-.024 4.765-1.906a.438.438 0 00.27-.338l.006-.077-.001-4.888zm-4.72-6.31a1.25 1.25 0 00-.97 0L3.355 9.042l8.24 3.018c.232.084.482.098.72.042l.14-.042 8.24-3.018z"
        fill={color || CSTheme.colors.text}
      />
    </Svg>
  );
}
