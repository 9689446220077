import { action, computed, makeObservable, observable } from 'mobx';
import AbstractStore from './AbstractStore';

export default class SessionStore extends AbstractStore {
  constructor() {
    super();
    makeObservable(this, {
      isRefreshing: observable,
      pushToken: observable,
      isLoggedIn: observable,
      viewData: computed,
      setIsRefreshing: action,
      setExpoPushToken: action,
      expoPushToken: computed,
      setIsLoggedIn: action,
      hydrate: action,
      reset: action,
    });
  }

  public isRefreshing: boolean = false;

  public pushToken: string | null = null;

  public isLoggedIn: boolean = false;

  public get viewData() {
    return {
      isInitialized: this.isInitialized,
      isRefreshing: this.isRefreshing,
      isLoggedIn: this.isLoggedIn,
      pushToken: this.pushToken,
    };
  }

  public setIsRefreshing(isRefreshing: boolean) {
    this.isRefreshing = isRefreshing;
  }

  public setExpoPushToken(expoPushToken: string | null) {
    this.pushToken = expoPushToken;
  }

  get expoPushToken() {
    return this.pushToken;
  }

  setIsLoggedIn(isLoggedIn: boolean) {
    this.isLoggedIn = isLoggedIn;
  }

  hydrate = (data: { isLoggedIn: boolean; isRefreshing: boolean; pushToken: string | null }) => {
    Object.assign(this, data);
  };

  reset = () => {
    this.isLoggedIn = false;
    this.isRefreshing = false;
  };
}
