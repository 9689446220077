/* eslint-disable import/extensions */
/* eslint-disable import/no-useless-path-segments */
import React, { useState, useCallback, useEffect } from 'react';
import { ScrollView } from 'react-native';
import { isPast } from 'date-fns';
import { useIsFocused } from '@react-navigation/native';

import useProfile from '../../../Context/Profile/Hook/useProfile';
import useBookings from '../../../Context/Booking/Hook/useBookings';
import Layout from '../../Component/Layout';
import { useFocusEffect } from '../../../Infrastructure/Hook';
import Account from '../Component/Account';
import DocumentModal from '../../common/DocumentModal';
import { BookingStatus } from '../../../Context/Booking/Store/Model/Booking';

export default function AccountScreen() {
  const { profile, refresh } = useProfile();
  const [showModal, setShowModal] = useState(false);
  const isInFocused = useIsFocused()

  const { bookings: pastBookings, refresh: refreshPastBookings, undocumentedBookingCount } = useBookings(
    useCallback(
      ({
        endDate,
        isUndocumented,
      }: {
        endDate: Date;
        status: BookingStatus;
        isUndocumented: boolean;
      }) => {
        return isPast(endDate) && isUndocumented;
      },
      [],
    ),
  );

  useEffect(() => {
    if (isInFocused && undocumentedBookingCount > 0) {
      setShowModal(true);
    }
  }, [pastBookings.length, undocumentedBookingCount, isInFocused]);

  useFocusEffect(refreshPastBookings);

  useFocusEffect(refresh);

  return (
    <Layout>
      <DocumentModal
        showModal={showModal}
        setShowModal={setShowModal}
        pastBookings={pastBookings}
      />
      <ScrollView>
        <Account profile={profile} />
      </ScrollView>
    </Layout>
  );
}
