import * as React from 'react';
import Svg, { Circle, SvgProps } from 'react-native-svg';
import { CareshipColors } from '../CSTheme';

interface BulletIconProps extends SvgProps {
  color?: string;
  size?: number;
}

function BulletIcon({ color = CareshipColors.slate400, size = 24, ...props }: BulletIconProps) {
  return (
    <Svg width={size} height={size} viewBox="-9 -9 24 24" {...props}>
      <Circle fill={color} cx="3" cy="3" r="3" />
    </Svg>
  );
}

export default BulletIcon;
