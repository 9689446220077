import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';
import { CareshipColors } from '../CSTheme';

interface SpeechBubbleIconProps extends SvgProps {
  color?: string;
  colorAccent?: string;
  size?: number;
}

function SpeechBubbleIcon({
  color = CareshipColors.slate400,
  colorAccent = CareshipColors.primary,
  size = 24,
  ...props
}: SpeechBubbleIconProps) {
  return (
    <Svg width={size} height={size} viewBox="0 0 25 25" {...props}>
      <Path
        d="M12.966.504a10.826 10.826 0 019.926 7.224 10.838 10.838 0 01-3.427 12.05A10.82 10.82 0 017.699 20.95l-5.044 2.404c-1.275.608-2.607-.723-2-1.998l2.402-5.05a10.827 10.827 0 01.148-10.247A10.811 10.811 0 0112.34.505L12.667.5l.299.004zM12.667 2a9.311 9.311 0 00-8.15 4.788 9.327 9.327 0 00.23 9.456 4085.222 4085.222 0 01-2.375 5.002C2.132 21.75 2 22 2 22s.243-.113.718-.34l5.041-2.4.268.16a9.32 9.32 0 0010.498-.811 9.338 9.338 0 002.952-10.382A9.326 9.326 0 0012.667 2z"
        fill={color}
      />
      <Path
        d="M8.248 9h9a.75.75 0 100-1.5h-9a.75.75 0 000 1.5zM6.748 12h12a.75.75 0 100-1.5h-12a.75.75 0 100 1.5zM8.248 15h9a.75.75 0 100-1.5h-9a.75.75 0 100 1.5z"
        fill={colorAccent}
      />
    </Svg>
  );
}

export default SpeechBubbleIcon;
