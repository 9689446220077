import { Dimensions, StyleSheet } from 'react-native';
import CSTheme, { CareshipColors } from '../../../Component/CSTheme';

export default StyleSheet.create({
  actionContainer: {
    marginTop: 32,
  },
  marginTop: {
    marginTop: 16,
  },
  clientName: {
    marginTop: 0,
    marginBottom: -4,
  },
  durationRow: {
    flexDirection: 'row',
  },
  duration: {
    marginBottom: 8,
    ...CSTheme.fonts.medium,
  },
  durationEnd: {
    color: CareshipColors.slate300,
  },
  upcomingDuration: {
    fontSize: 48,
    lineHeight: 58,
  },
  container: { alignItems: 'flex-start' },
  detailsContainer: {
    marginTop: 32,
  },
  firstAppointmentLabelRow: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  appointmentDetailsRow: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  day: {
    fontSize: 20,
    color: CareshipColors.slate300,
    lineHeight: 20,
  },
  date: { marginTop: 0, marginBottom: 8 },
  muted: {
    color: CareshipColors.slate300,
  },
  buttonGroup: {
    flexDirection: 'row',
  },
  actionBookingButton: {
    marginBottom: 8,
    marginRight: 20,
    alignSelf: 'flex-start',
  },
  clockInButton: {
    marginTop: 8,
    marginBottom: 8,
  },
  timeValueRow: {
    flexDirection: 'row',
    marginTop: 8,
  },
  timeValueCol: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginRight: 32,
  },
  timeValueLabel: {
    marginLeft: 8,
    color: CareshipColors.slate200,
  },
  mapStyle: {
    width: Dimensions.get('window').width - 32,
    height: 190,
    marginTop: 4,
  },
  servicesRow: {
    marginTop: 32,
  },
  contractCancelationHint: {
    borderTopColor: CareshipColors.white,
    borderTopWidth: 1,
    borderStyle: 'solid',
    backgroundColor: CareshipColors.slate300,
    display: 'flex',
    flexGrow: 1,
    paddingVertical: 12,
    paddingHorizontal: 18,
    flexDirection: 'column',
  },
  contractCancelationHintTitle: {
    display: 'flex',
    fontWeight: '700',
    flexDirection: 'row',
    alignItems: 'center',
  },
  contractCancelationHintTitleText: {
    marginLeft: 8,
    color: CareshipColors.white,
  },
  cellLabel: {
    marginTop: 0,
    alignSelf: 'center',
  },
});
