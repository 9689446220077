import { StyleSheet, View } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import React, { ReactNode, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { ButtonScrollTop, Container, ChecklistCard } from '../../../Component';
import {
  BusinessContractIcon,
  CalendarEditIcon,
  CalendarTimeIcon,
  CoinsBillIcon,
  FaqIcon,
  JourneyLocationIcon,
  MailCheckIcon,
  ShieldHealthIcon,
  SpeechBubbleIcon,
} from '../../../Component/Icon';

const styles = StyleSheet.create({
  checklistContainer: {
    marginTop: 32,
  },
  arrowDown: {
    display: 'flex',
    alignSelf: 'center',
    flexGrow: 1,
    marginBottom: 16,
  },
  scrollTopContainer: {
    marginTop: 24,
    marginBottom: 32,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
});

interface ChecklistItemType {
  id: string;
  title: ReactNode;
  description?: ReactNode;
  icon: ReactNode;
  items?: string[];
  cta?: {
    label: ReactNode;
    onPress: () => void;
  };
}

interface FirstAppointmentChecklistProps {
  onPressSupportingDocuments: () => void;
}

export default function FirstAppointmentChecklist({
  onPressSupportingDocuments,
}: FirstAppointmentChecklistProps) {
  const scrollViewRef = useRef<ScrollView>(null);

  const checklistItems: ChecklistItemType[] = [
    {
      id: 'checklist-item-1',
      title: <FormattedMessage id="FIRST_APPOINTMENT_CHECKLIST_ITEM_1_TITLE" />,
      description: <FormattedMessage id="FIRST_APPOINTMENT_CHECKLIST_ITEM_1_DESCRIPTION" />,
      icon: <JourneyLocationIcon />,
    },
    {
      id: 'checklist-item-2',
      title: <FormattedMessage id="FIRST_APPOINTMENT_CHECKLIST_ITEM_2_TITLE" />,
      description: <FormattedMessage id="FIRST_APPOINTMENT_CHECKLIST_ITEM_2_DESCRIPTION" />,
      cta: {
        label: <FormattedMessage id="FIRST_APPOINTMENT_CHECKLIST_ITEM_2_LINK" />,
        onPress: onPressSupportingDocuments,
      },
      icon: <FaqIcon width={24} height={24} />,
    },
    {
      id: 'checklist-item-3',
      title: <FormattedMessage id="FIRST_APPOINTMENT_CHECKLIST_ITEM_3_TITLE" />,
      icon: <BusinessContractIcon />,
    },
    {
      id: 'checklist-item-4',
      title: <FormattedMessage id="FIRST_APPOINTMENT_CHECKLIST_ITEM_4_TITLE" />,
      icon: <CoinsBillIcon />,
    },
    {
      id: 'checklist-item-5',
      title: <FormattedMessage id="FIRST_APPOINTMENT_CHECKLIST_ITEM_5_TITLE" />,
      icon: <ShieldHealthIcon />,
    },
    {
      id: 'checklist-item-6',
      title: <FormattedMessage id="FIRST_APPOINTMENT_CHECKLIST_ITEM_6_TITLE" />,
      description: <FormattedMessage id="FIRST_APPOINTMENT_CHECKLIST_ITEM_6_DESCRIPTION" />,
      icon: <CalendarTimeIcon />,
    },
    {
      id: 'checklist-item-7',
      title: <FormattedMessage id="FIRST_APPOINTMENT_CHECKLIST_ITEM_7_TITLE" />,
      description: <FormattedMessage id="FIRST_APPOINTMENT_CHECKLIST_ITEM_7_DESCRIPTION" />,
      icon: <MailCheckIcon />,
    },
    {
      id: 'checklist-item-8',
      title: <FormattedMessage id="FIRST_APPOINTMENT_CHECKLIST_ITEM_8_TITLE" />,
      description: <FormattedMessage id="FIRST_APPOINTMENT_CHECKLIST_ITEM_8_DESCRIPTION" />,
      icon: <CalendarEditIcon />,
    },
    {
      id: 'checklist-item-9',
      title: <FormattedMessage id="FIRST_APPOINTMENT_CHECKLIST_ITEM_9_TITLE" />,
      icon: <SpeechBubbleIcon />,
    },
  ];

  return (
    <ScrollView ref={scrollViewRef}>
      <View style={styles.checklistContainer}>
        {checklistItems.map((item) => (
          <ChecklistCard
            key={item.id}
            title={item.title}
            description={item.description}
            icon={item.icon}
            items={item.items}
            cta={item.cta}
          />
        ))}
      </View>
      <Container style={styles.scrollTopContainer}>
        <ButtonScrollTop scrollViewRef={scrollViewRef} />
      </Container>
    </ScrollView>
  );
}
