import { FormattedDate, FormattedMessage } from 'react-intl';
import React from 'react';
import { graphql, useFragment } from 'react-relay/hooks';
import { ContractCancelationIcon } from '../../Component/Icon';
import { CareshipColors } from '../../Component/CSTheme';
import { TopBanner } from '../../Component';
import { StatusBarContractCancelation_contract$key } from './__generated__/StatusBarContractCancelation_contract.graphql';

interface ContractCancelationBarProps {
  contractFragment: StatusBarContractCancelation_contract$key;
}

export default function StatusBarContractCancelation({
  contractFragment,
}: ContractCancelationBarProps) {
  const { cancelationScheduledFor } = useFragment(
    graphql`
      fragment StatusBarContractCancelation_contract on Contract {
        cancelationScheduledFor
      }
    `,
    contractFragment,
  );

  if (!cancelationScheduledFor) {
    return null;
  }

  return (
    <TopBanner
      icon={<ContractCancelationIcon color={CareshipColors.white} />}
      title={
        <FormattedMessage
          id="COLLABORATION_ENDS_ON"
          values={{
            endsAt: (
              <FormattedDate
                value={cancelationScheduledFor}
                year="numeric"
                month="2-digit"
                day="2-digit"
              />
            ),
          }}
        />
      }
      description={
        <FormattedMessage
          id="COLLABORATION_ENDS_ON_DESCRIPTION"
          values={{
            endsAt: (
              <FormattedDate
                value={cancelationScheduledFor}
                year="numeric"
                month="2-digit"
                day="2-digit"
              />
            ),
          }}
        />
      }
    />
  );
}
