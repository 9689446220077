import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';
import CSTheme from '../CSTheme';

type Edit2IconProps = SvgProps & {
  color?: string;
  size?: number;
};

export default function Edit2Icon({ color, size = 24, ...props }: Edit2IconProps) {
  return (
    <Svg viewBox="0 0 24 24" width={size} height={size} {...props}>
      <Path
        d="M19.023 1l.233.005c1.005.05 1.96.471 2.68 1.188a4.085 4.085 0 01-.034 5.798L8.611 21.282a.75.75 0 01-.344.196l-6.33 1.624a.75.75 0 01-.913-.913l1.623-6.33a.75.75 0 01.196-.344L16.129 2.23A4.086 4.086 0 0119.023 1zm-2.738 3.194L4.048 16.431l-1.257 4.903 4.903-1.258L19.93 7.839l-3.645-3.645zM19.033 2.5l-.206.01a2.59 2.59 0 00-1.477.629l3.637 3.638c.362-.413.582-.931.63-1.478l.01-.206a2.583 2.583 0 00-.752-1.84 2.586 2.586 0 00-1.842-.753z"
        fill={color || CSTheme.colors.text}
      />
    </Svg>
  );
}
