import React from 'react';
import { useFragment, graphql } from 'react-relay/hooks';
import { StyleSheet, View } from 'react-native';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { Text, Title } from '..';
import { CareshipColors } from '../CSTheme';
import { Smiley100, Smiley300, Smiley500 } from '../Icon';
import CardWithTooltip from './CardWithTooltip';
import { CustomerSatisfaction_customerSatisfactionScore$key } from './__generated__/CustomerSatisfaction_customerSatisfactionScore.graphql';

const styles = StyleSheet.create({
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  cardSection: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 16,
    paddingBottom: 0,
    flexBasis: '100%',
  },
  satisfactionDescription: {
    marginBottom: 16,
  },
  smileys: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-evenly',
    width: '100%',
    maxWidth: 310,
    flex: 1,
  },
  satisfactionScore: {
    marginTop: 4,
    marginBottom: 8,
  },
});

export default function CustomerSatisfaction({
  stats,
}: {
  stats: CustomerSatisfaction_customerSatisfactionScore$key;
}) {
  const { customerSatisfactionScore: averageScore } = useFragment(
    graphql`
      fragment CustomerSatisfaction_customerSatisfactionScore on CaregiverStats {
        customerSatisfactionScore
      }
    `,
    stats,
  );

  if (averageScore === null) {
    return null;
  }

  const satisfactionIdx = [1, 3.5, 4].reduce((acc, v, idx) => (v <= averageScore ? idx : acc), 0);
  const satisfactionScore = (
    <Title size="h3" center style={styles.satisfactionScore} testID="satisfactionScore">
      <FormattedNumber value={averageScore} maximumFractionDigits={1} />
    </Title>
  );

  return (
    <CardWithTooltip
      tooltipContent={
        <>
          <Title style={{ color: '#fff', marginBottom: 4 }} size="h6">
            <FormattedMessage id="CUSTOMER_SATISFACTION_TOOLTIP_TITLE" />
          </Title>
          <Text style={{ color: '#fff' }}>
            <FormattedMessage id="CUSTOMER_SATISFACTION_TOOLTIP_BODY" />
          </Text>
        </>
      }
      title={
        <Text center style={{ width: '100%' }}>
          <Text center bold uppercase>
            <FormattedMessage id="CUSTOMER_SATISFACTION_TITLE_1" />{' '}
          </Text>
          <Text center>
            <FormattedMessage id="CUSTOMER_SATISFACTION_TITLE_2" />
          </Text>
        </Text>
      }
    >
      <View style={styles.row}>
        <View style={[styles.cardSection]}>
          <View style={styles.smileys}>
            <View>
              <Smiley100
                color={satisfactionIdx === 0 ? CareshipColors.red : CareshipColors.slate200}
              />
              {satisfactionIdx === 0 && satisfactionScore}
            </View>
            <View>
              <Smiley300
                color={satisfactionIdx === 1 ? CareshipColors.primary : CareshipColors.slate200}
              />
              {satisfactionIdx === 1 && satisfactionScore}
            </View>
            <View>
              <Smiley500
                color={satisfactionIdx === 2 ? CareshipColors.secondary : CareshipColors.slate200}
              />
              {satisfactionIdx === 2 && satisfactionScore}
            </View>
          </View>
        </View>
      </View>

      <Text size="small" center uppercase style={styles.satisfactionDescription}>
        {satisfactionIdx === 0 && <FormattedMessage id="CUSTOMER_SATISFACTION_LEVEL_LOW" />}
        {satisfactionIdx === 1 && <FormattedMessage id="CUSTOMER_SATISFACTION_LEVEL_MID" />}
        {satisfactionIdx === 2 && <FormattedMessage id="CUSTOMER_SATISFACTION_LEVEL_HIGH" />}
      </Text>
    </CardWithTooltip>
  );
}
