import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';
import { CareshipColors } from '../CSTheme';

interface CoinsBillIconProps extends SvgProps {
  color?: string;
  size?: number;
}

function CoinsBillIcon({
  color = CareshipColors.slate400,
  size = 24,
  ...props
}: CoinsBillIconProps) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" {...props}>
      <Path
        d="M11.3280091,17.042161 C8.77371387,18.3193031 4.72619523,18.3193031 2.17191095,17.042161 C-0.724026983,15.5941982 -0.724026983,12.9058418 2.17191095,11.457879 C4.72620613,10.1807369 8.77372477,10.1807369 11.3280102,11.4578796 C14.223947,12.9058418 14.223947,15.5941982 11.3280091,17.042161 Z M2.84272905,12.799521 C1.05236698,13.6946982 1.05236698,14.8053418 2.8427302,15.7005196 C4.97472477,16.7665169 8.52518613,16.7665169 10.6571909,15.700519 C12.447553,14.8053418 12.447553,13.6946982 10.6571909,12.799521 C8.52519523,11.7335231 4.97473387,11.7335231 2.84272905,12.799521 Z"
        fill="#FFB300"
      />
      <Path
        d="M0,14.25 C0,13.8357864 0.335786438,13.5 0.75,13.5 C1.16421356,13.5 1.5,13.8357864 1.5,14.25 L1.5,20.25 C1.5,21.351466 3.79706798,22.5 6.75,22.5 C9.70293202,22.5 12,21.351466 12,20.25 L12,14.25 C12,13.8357864 12.3357864,13.5 12.75,13.5 C13.1642136,13.5 13.5,13.8357864 13.5,14.25 L13.5,20.25 C13.5,22.462534 10.425068,24 6.75,24 C3.07493202,24 0,22.462534 0,20.25 L0,14.25 Z"
        fill="#FFB300"
      />
      <Path
        d="M12,17.25 C12,16.8357864 12.3357864,16.5 12.75,16.5 C13.1642136,16.5 13.5,16.8357864 13.5,17.25 C13.5,19.462534 10.425068,21 6.75,21 C3.07493202,21 0,19.462534 0,17.25 C0,16.8357864 0.335786438,16.5 0.75,16.5 C1.16421356,16.5 1.5,16.8357864 1.5,17.25 C1.5,18.351466 3.79706798,19.5 6.75,19.5 C9.70293202,19.5 12,18.351466 12,17.25 Z"
        fill="#FFB300"
      />
      <Path
        d="M15.75,16.5 C15.3357864,16.5 14.9999999,16.1642136 14.9999999,15.75 C14.9999999,15.3357864 15.3357864,15 15.75,15 L21.75,15 C22.1642134,15 22.4999999,14.6642134 22.4999999,14.25 L22.4999999,2.25 C22.4999999,1.83578656 22.1642134,1.5 21.7499999,1.5 L2.24999997,1.5 C1.83578643,1.5 1.49999987,1.83578657 1.49999987,2.25 L1.49999987,9.757 C1.49999987,10.1712136 1.16421343,10.507 0.749999869,10.507 C0.335786306,10.507 -1.311342e-07,10.1712136 -1.311342e-07,9.757 L-1.311342e-07,2.25 C-1.311342e-07,1.00735944 1.0073593,-8.8817842e-16 2.24999993,-8.8817842e-16 L21.7499999,-8.8817842e-16 C22.9926405,-8.8817842e-16 23.9999999,1.00735944 23.9999999,2.25 L23.9999999,14.25 C23.9999999,15.4926406 22.9926405,16.5 21.75,16.5 L15.75,16.5 Z"
        fill={color}
      />
      <Path
        d="M8.24999998,8.25032453 C8.24999998,7.83572317 8.58584105,7.49997753 9.00004069,7.5 C9.41425426,7.50002248 9.75002247,7.83582713 9.74999998,8.25004069 L8.99999998,8.25032464 L8.24999998,8.25028395 C8.25022025,6.17904101 9.92907218,4.50032464 12.0000001,4.50032464 C14.0710637,4.50032464 15.75,6.17926117 15.75,8.25032475 C15.75,9.32952239 15.2850563,10.3564018 14.47405,11.0684007 C14.1627734,11.3416767 13.6889,11.3108712 13.4156239,10.9995947 C13.1423479,10.6883181 13.1731534,10.2144446 13.48443,9.94116859 C13.9710345,9.5139686 14.25,8.89784253 14.25,8.25032464 C14.25,7.00768828 13.2426365,6.00032464 12,6.00032464 C10.7574528,6.00032464 9.75014478,7.00754354 9.74999998,8.25005717 C9.74994572,9.25036533 8.24999998,9.25032453 8.24999998,8.25032453 Z"
        fill={color}
      />
      <Path
        d="M5.62499998,4.49999997 C5.62499998,5.12132055 5.12132055,5.625 4.50000002,5.625 C3.87867943,5.625 3.37499998,5.12132057 3.37499998,4.50000011 C3.37499998,3.87889477 3.87833047,3.37534907 4.49935424,3.37500022 C5.12132054,3.37500022 5.62499998,3.87867944 5.62499998,4.49999997 Z"
        fill={color}
      />
      <Path
        d="M20.625,12 C20.625,12.6213205 20.1213206,13.125 19.5,13.125 C18.8786794,13.125 18.375,12.6213206 18.375,12.0000001 C18.375,11.3786795 18.8786794,10.8750001 19.5,10.8750001 C19.5000699,10.8750001 19.5001399,10.8750001 19.5002099,10.8750001 C20.121434,10.8751134 20.625,11.3787494 20.625,12 Z"
        fill={color}
      />
    </Svg>
  );
}

export default CoinsBillIcon;
