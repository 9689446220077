import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';
import CSTheme from '../CSTheme';

type HelpCircleProps = SvgProps & {
  color?: string;
  size?: number;
};

function HelpCircle({ color, size = 24, ...props }: HelpCircleProps) {
  return (
    <Svg viewBox="0 0 24 24" width={size} height={size} {...props}>
      <Path
        d="M12 1c6.1 0 11 4.9 11 11s-4.9 11-11 11S1 18.1 1 12 5.9 1 12 1zm0 1.5c-5.2 0-9.5 4.3-9.5 9.5s4.3 9.5 9.5 9.5 9.5-4.3 9.5-9.5-4.3-9.5-9.5-9.5zm0 13.2h.1c.6.1 1 .5 1 1.1 0 .6-.5 1.1-1.1 1.1-.6 0-1.1-.5-1.1-1.1 0-.6.4-1.1 1-1.1h.1zm.2-9.2c2.2 0 3.6 1.2 3.6 3 0 1.1-.4 1.9-1.2 2.4l-.7.5c-.7.5-1 .8-1 1.6v.3c0 .1-.1.6-.9.6s-.9-.5-.9-.7V14c0-1.4.5-1.9 1.4-2.6l.5-.4c.5-.4.8-.8.8-1.5 0-.8-.5-1.5-1.7-1.5s-1.8.7-1.8 1.8c0 .5-.3.9-.9.9-.6 0-.9-.3-.9-.9.1-1.8 1.3-3.3 3.7-3.3z"
        fill={color || CSTheme.colors.text}
      />
    </Svg>
  );
}

export default HelpCircle;
