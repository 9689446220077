import React, { useCallback, useMemo, useRef } from 'react';
import { PreloadedQuery, useFragment, useMutation, usePreloadedQuery } from 'react-relay/hooks';
import { graphql } from 'react-relay';
import { SectionList, SectionListRenderItemInfo, View } from 'react-native';
import { Controller, Path, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from '@emotion/native';
import { pipe } from 'fp-ts/function';
import { useNavigation } from '@react-navigation/native';
import StickyFooter from '../../Component/StickyFooter';
import { Button, CheckboxInputRow, DividingHeader } from '../../Component';
import { ExperienceAndAbilitiesFormQuery } from './__generated__/ExperienceAndAbilitiesFormQuery.graphql';
import { events, trackEvent } from '../../../Infrastructure/Tracking/Tracking';
import { ExperienceAndAbilitiesFormMutation } from './__generated__/ExperienceAndAbilitiesFormMutation.graphql';
import { AccountStackNavigationProp } from '../Navigator/AccountStackNavigationProp';
import { ExperienceAndAbilitiesFormCaregiverFragment$key } from './__generated__/ExperienceAndAbilitiesFormCaregiverFragment.graphql';

const SaveButton = styled(Button)({
  marginVertical: 24,
  marginHorizontal: 16,
});

const ListHeaderSpacer = styled.View({
  height: 32,
});

const ListFooter = styled.View({
  marginTop: 'auto',
});

const SectionHeader = styled(DividingHeader)({
  marginBottom: 8,
  overflow: 'hidden',
  flex: 1,
  marginRight: 15,
});

type KeysOfUnion<T> = T extends T ? keyof T : never;

function getSelectedKeys<K extends string, V extends boolean>(record: Record<K, V>): K[] {
  return pipe(
    Object.entries(record) as [K, V][],
    (entries: [K, V][]) => entries.filter(([, v]) => v),
    (entries) => entries.map(([key]) => key),
  );
}

function getDefaultsForField<
  TMe extends { [key in TFieldName]?: readonly { readonly key: string }[] },
  TFieldName extends keyof TMe,
  TKey extends string,
>(
  fieldName: TFieldName,
  data: ReadonlyArray<{ readonly key: TKey; readonly label: string }>,
  me: TMe,
): { [key in TKey]: boolean } {
  const fieldData = me[fieldName];

  return data.reduce(
    (acc, { key }) => ({
      ...acc,
      [key]: Boolean(fieldData?.find(({ key: k }) => key === k)),
    }),
    {} as { [key in TKey]: boolean },
  );
}

const formDataToInputPayload = <
  T extends {
    experienceAndSkills: Record<TES, boolean>;
    medicalConditions: Record<TMC, boolean>;
    additionalInfo: {
      hasCar: boolean;
      hasDriversLicence: boolean;
      isSmoker: boolean;
      noHomesWithPets: boolean;
    };
  },
  TES extends string,
  TMC extends string,
>(
  input: T,
) => ({
  experienceAndSkills: getSelectedKeys(input.experienceAndSkills),
  experienceWithMedicalConditions: getSelectedKeys(input.medicalConditions),
  additionalInfo: input.additionalInfo,
});

const experienceAndAbilitiesFormCaregiverFragment = graphql`
  fragment ExperienceAndAbilitiesFormCaregiverFragment on Caregiver {
    id
    experienceAndSkills {
      key
    }
    experienceWithMedicalConditions {
      key
    }
    hasCar
    hasDriversLicence
    isSmoker
    noHomesWithPets
  }
`;

export const experienceAndAbilitiesFormQuery = graphql`
  query ExperienceAndAbilitiesFormQuery($locale: Locale!) {
    me {
      ... on Caregiver {
        ...ExperienceAndAbilitiesFormCaregiverFragment
      }
    }
    definitions {
      educationAndQualifications {
        key
        label(locale: $locale)
      }
      experienceAndSkills {
        key
        label(locale: $locale)
      }
      medicalConditions {
        key
        label(locale: $locale)
      }
    }
  }
`;

const experienceAndAbilitiesFormMutation = graphql`
  mutation ExperienceAndAbilitiesFormMutation($input: UpdateCaregiverInput!) {
    updateCaregiver(input: $input) {
      caregiver {
        ...ExperienceAndAbilitiesFormCaregiverFragment
      }
    }
  }
`;

interface ExperienceAndAbilitiesFormProps {
  preloadedQuery: PreloadedQuery<ExperienceAndAbilitiesFormQuery>;
}

export default function ExperienceAndAbilitiesForm({
  preloadedQuery,
}: ExperienceAndAbilitiesFormProps) {
  const intl = useIntl();
  const footerButtonsRef = useRef<View>(null);
  const navigation = useNavigation<AccountStackNavigationProp<'ExperienceAndAbilities'>>();
  const {
    definitions: { experienceAndSkills, medicalConditions },
    me: meFragment,
  } = usePreloadedQuery(experienceAndAbilitiesFormQuery, preloadedQuery);

  const me = useFragment(
    experienceAndAbilitiesFormCaregiverFragment,
    meFragment as ExperienceAndAbilitiesFormCaregiverFragment$key,
  );

  const defaultValues = {
    experienceAndSkills: getDefaultsForField('experienceAndSkills', experienceAndSkills, me),
    additionalInfo: {
      hasCar: Boolean(me?.hasCar),
      hasDriversLicence: Boolean(me?.hasDriversLicence),
      noHomesWithPets: Boolean(me?.noHomesWithPets),
      isSmoker: Boolean(me?.isSmoker),
    } as const,
    medicalConditions: getDefaultsForField(
      'experienceWithMedicalConditions',
      medicalConditions,
      me,
    ),
  };

  const {
    handleSubmit,
    control,
    formState: { isDirty },
  } = useForm({
    defaultValues,
  });
  const [commitMutation, isInFlight] = useMutation<ExperienceAndAbilitiesFormMutation>(
    experienceAndAbilitiesFormMutation,
  );

  const handleSave = useCallback(
    (input: typeof defaultValues) => {
      commitMutation({
        variables: {
          input: formDataToInputPayload(input),
        },
        onCompleted: () => {
          trackEvent(events.EXPERIENCE_AND_ABILITIES_UPDATED);
          navigation.navigate('Profile');
        },
      });
    },
    [navigation, commitMutation],
  );

  const handlePressSubmit = useCallback(() => {
    void handleSubmit(handleSave)();
  }, [handleSubmit, handleSave]);

  const saveButton = (
    <SaveButton testID="submitButton" disabled={!isDirty || isInFlight} onPress={handlePressSubmit}>
      <FormattedMessage id="BUTTON_SAVE" />
    </SaveButton>
  );

  type RenderItemKey = KeysOfUnion<typeof defaultValues[keyof typeof defaultValues]>;

  type RenderItem = {
    readonly key: RenderItemKey;
    readonly label: string;
  };

  const sections: {
    title: string;
    key: keyof typeof defaultValues;
    data: readonly RenderItem[];
  }[] = useMemo(
    () => [
      {
        title: intl.formatMessage({ id: 'GENERAL_EXPERIENCE' }),
        key: 'experienceAndSkills',
        data: experienceAndSkills,
      },
      {
        title: intl.formatMessage({ id: 'EXPERIENCE_WITH_MEDICAL_CONDITIONS' }),
        key: 'medicalConditions',
        data: medicalConditions,
      },
      {
        title: intl.formatMessage({ id: 'ADDITIONAL_INFORMATION' }),
        key: 'additionalInfo',
        data: [
          {
            key: 'hasCar',
            label: intl.formatMessage({ id: 'ADDITIONAL_INFORMATION_HAS_CAR' }),
          },
          {
            key: 'hasDriversLicence',
            label: intl.formatMessage({ id: 'ADDITIONAL_INFORMATION_HAS_DRIVERS_LICENCE' }),
          },
          {
            key: 'noHomesWithPets',
            label: intl.formatMessage({ id: 'ADDITIONAL_INFORMATION_NO_HOMES_WITH_PETS' }),
          },
          {
            key: 'isSmoker',
            label: intl.formatMessage({ id: 'ADDITIONAL_INFORMATION_IS_SMOKER' }),
          },
        ],
      },
    ],
    [experienceAndSkills, medicalConditions, intl],
  );

  const renderSectionHeader = useCallback(
    ({ section: { title } }) => <SectionHeader>{title}</SectionHeader>,
    [],
  );

  const renderItem = useCallback(
    ({
      item: { key, label },
      section,
      index,
    }: SectionListRenderItemInfo<RenderItem, { key: string }>) => (
      <Controller
        key={key}
        name={`${section.key}.${key}` as Path<typeof defaultValues>}
        control={control}
        render={({ field: { value, onChange } }) => (
          <CheckboxInputRow
            key={key}
            onPress={() => onChange(!value)}
            status={value ? 'checked' : 'unchecked'}
            bottommost={index === section.data.length - 1}
            label={label}
          />
        )}
      />
    ),
    [control],
  );

  return (
    <>
      <SectionList
        ListHeaderComponent={<ListHeaderSpacer />}
        sections={sections}
        keyExtractor={(item) => item.key}
        stickySectionHeadersEnabled
        renderItem={renderItem}
        renderSectionHeader={renderSectionHeader}
        ListFooterComponent={
          <ListFooter collapsable={false} ref={footerButtonsRef}>
            {saveButton}
          </ListFooter>
        }
      />
      <StickyFooter sticky={isDirty} alignWithRef={footerButtonsRef}>
        {saveButton}
      </StickyFooter>
    </>
  );
}
