import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';
import CSTheme from '../CSTheme';

type AlertCircleProps = SvgProps & {
  color?: string;
  size?: number;
};

function AlertCircle({ color, size = 24, ...props }: AlertCircleProps) {
  return (
    <Svg viewBox="0 0 24 24" width={size} height={size} {...props}>
      <Path
        d="M12 1c6.075 0 11 4.925 11 11s-4.925 11-11 11S1 18.075 1 12 5.925 1 12 1zm0 1.5a9.5 9.5 0 100 19 9.5 9.5 0 000-19zm.01 4.2a.85.85 0 01.843.743l.007.107v5.807a.85.85 0 01-1.693.106l-.007-.106V7.55c0-.47.38-.85.85-.85zm-.005 8.72l.123.007a1.125 1.125 0 11-.246 0l.123-.007z"
        fill={color || CSTheme.colors.text}
      />
    </Svg>
  );
}

export default AlertCircle;
