export type Frequency =
  | 'once_per_week'
  | '2_3_times_per_week'
  | '4_times_per_week_or_more'
  | 'every_2_weeks'
  | 'once_or_twice_a_month'
  | 'on_demand'
  | '';

export type BookingType = 'one_time' | 'regular';

export interface ScheduleResponse {
  readonly date: string;
  readonly duration_in_minutes: number;
  readonly frequency: Frequency;
  readonly booking_type: BookingType;
  readonly is_flexible: boolean;
  readonly is_all_day: boolean;
  readonly expected_appointment_schedule?: null | {
    readonly is_flexible: boolean;
    readonly preferences: string;
  };
}

interface ExpectedAppointmentSchedule {
  isFlexible: boolean;
  preferences: string;
}

export interface ScheduleObject {
  readonly date: Date;
  readonly durationInMinutes: number;
  readonly frequency: Frequency;
  readonly bookingType: BookingType;
  readonly isFlexible: boolean;
  readonly isAllDay: boolean;
}

export default class Schedule implements ScheduleObject {
  constructor(
    public readonly date: Date,
    public readonly durationInMinutes: number,
    public readonly frequency: Frequency,
    public readonly bookingType: BookingType,
    public readonly isFlexible: boolean,
    public readonly isAllDay: boolean,
    public readonly expectedAppointmentSchedule: ExpectedAppointmentSchedule,
  ) {}

  public static fromObject(schedule: Schedule) {
    return new Schedule(
      new Date(schedule.date),
      schedule.durationInMinutes,
      schedule.frequency,
      schedule.bookingType,
      schedule.isFlexible,
      schedule.isAllDay,
      schedule.expectedAppointmentSchedule,
    );
  }

  public static fromResponse(response: ScheduleResponse) {
    return new Schedule(
      new Date(response.date),
      response.duration_in_minutes,
      response.frequency,
      response.booking_type,
      response.is_flexible,
      response.is_all_day,
      {
        isFlexible:
          typeof response.expected_appointment_schedule?.is_flexible === 'boolean'
            ? response.expected_appointment_schedule?.is_flexible
            : true,
        preferences: response.expected_appointment_schedule?.preferences || '',
      },
    );
  }
}
