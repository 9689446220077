/**
 * @generated SignedSource<<863c6926c698d9a95e87790b8ab48391>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ExperienceAndSkillEnum = "CHILDCARE" | "OFFICE_WORK" | "COMPUTERS_AND_INTERNET" | "WHEELCHAIR" | "POST_REHAB" | "PAIN_RELIEF" | "MASSAGE" | "COACHING" | "%future added value";
export type MedicalConditionEnum = "DEMENTIA" | "STROKE" | "LIFE_SUPPORT" | "DEPRESSIONS" | "MENTAL_DISABILITY" | "DOWN_SYNDROME" | "PARKINSONS" | "DIABETES" | "MULTIPLE_SCLEROSIS" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ExperienceAndAbilitiesFormCaregiverFragment$data = {
  readonly id: string;
  readonly experienceAndSkills: ReadonlyArray<{
    readonly key: ExperienceAndSkillEnum;
  }>;
  readonly experienceWithMedicalConditions: ReadonlyArray<{
    readonly key: MedicalConditionEnum;
  }>;
  readonly hasCar: boolean | null;
  readonly hasDriversLicence: boolean | null;
  readonly isSmoker: boolean | null;
  readonly noHomesWithPets: boolean | null;
  readonly " $fragmentType": "ExperienceAndAbilitiesFormCaregiverFragment";
};
export type ExperienceAndAbilitiesFormCaregiverFragment$key = {
  readonly " $data"?: ExperienceAndAbilitiesFormCaregiverFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ExperienceAndAbilitiesFormCaregiverFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "key",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ExperienceAndAbilitiesFormCaregiverFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CaregiverExperienceAndSkills",
      "kind": "LinkedField",
      "name": "experienceAndSkills",
      "plural": true,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CaregiverExperienceWithMedicalConditions",
      "kind": "LinkedField",
      "name": "experienceWithMedicalConditions",
      "plural": true,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasCar",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasDriversLicence",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isSmoker",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "noHomesWithPets",
      "storageKey": null
    }
  ],
  "type": "Caregiver",
  "abstractKey": null
};
})();

(node as any).hash = "532f5f7c7d47fe5ecb0fda4039ee7ce4";

export default node;
