import { useRef } from 'react';
import { useStores } from '../../../Infrastructure/Store';
import useAuthorizedClient from '../../../Infrastructure/Hook/useAuthorizedClient';
import ProposalService from '../Service/ProposalService';

export default function useProposalService() {
  const stores = useStores();
  const authorizedClient = useAuthorizedClient();

  const { current: proposalService } = useRef(
    new ProposalService(stores.proposalStore, authorizedClient),
  );

  return proposalService;
}
