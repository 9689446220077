import { FieldErrors, ResolverOptions, ResolverResult, UnpackNestedValue } from 'react-hook-form';

function isFieldError<TFieldValues>(
  errors: FieldErrors<TFieldValues> | {},
): errors is FieldErrors<TFieldValues> {
  return Object.values(errors).length > 0;
}

export default <TFieldValues, TContext>(
    ...resolvers: ((
      values: UnpackNestedValue<TFieldValues>,
      context: TContext | undefined,
      options: ResolverOptions<TFieldValues>,
    ) => ResolverResult<TFieldValues>)[]
  ) =>
  (
    values: UnpackNestedValue<TFieldValues>,
    context: TContext | undefined,
    options: ResolverOptions<TFieldValues>,
  ): ResolverResult<TFieldValues> => {
    const validation = resolvers.map((resolver) => resolver(values, context, options));
    const errors: FieldErrors<TFieldValues> | {} = validation
      .map((v) => v.errors)
      .reduce((acc, v) => ({ ...acc, ...v }), {});

    if (isFieldError(errors)) {
      return {
        errors,
        values: {},
      };
    }

    return {
      errors: {},
      values,
    };
  };
