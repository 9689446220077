import { StyleSheet } from 'react-native';
import CSTheme, { CareshipColors } from './CSTheme';

export default StyleSheet.create({
  noGutter: {
    paddingLeft: 0,
  },
  rightGutter: {
    paddingRight: 16,
  },
  titleContainer: {
    paddingLeft: 16,
    backgroundColor: CareshipColors.white,
    paddingTop: 8,
    justifyContent: 'flex-end',
  },
  titleAfter: {
    borderBottomWidth: 1,
    borderBottomColor: CareshipColors.slate200,
  },
  title: {
    textTransform: 'uppercase',
    color: CareshipColors.slate300,
    fontFamily: CSTheme.fonts.medium.fontFamily,
    fontWeight: CSTheme.fonts.medium.fontWeight,
    marginBottom: 8,
  },
});
