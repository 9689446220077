import { StyleSheet, View } from 'react-native';
import React, { ReactNode } from 'react';
import { TouchableRipple } from 'react-native-paper';
import { Feather } from '@expo/vector-icons';
import Text from './Text';
import CSTheme from './CSTheme';
import Card from './Card';

const styles = StyleSheet.create({
  card: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 16,
  },
  textContainer: {
    display: 'flex',
    flexShrink: 1,
    marginLeft: 8,
  },
  cardTitle: {
    lineHeight: 22,
  },
  cardDescription: {
    marginTop: 8,
    lineHeight: 22,
  },
  cardItems: {
    marginTop: 8,
  },
  cardItem: {
    display: 'flex',
    flexDirection: 'row',
  },
  cardItemText: {
    lineHeight: 22,
  },
  cta: {
    marginTop: 22, // blank line
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});

export interface ChecklistCardProps {
  title: ReactNode;
  description?: ReactNode;
  icon: ReactNode;
  items?: string[];
  cta?: {
    label: ReactNode;
    onPress: () => void;
  };
}

export default function ChecklistCard({
  title,
  description,
  icon,
  items,
  cta,
}: ChecklistCardProps) {
  return (
    <Card mode="contained" marginHorizontal="base" padding="base" style={styles.card}>
      {icon}
      <View style={styles.textContainer}>
        <Text size="large" bold style={styles.cardTitle}>
          {title}
        </Text>
        {description && (
          <Text size="large" style={styles.cardDescription}>
            {description}
          </Text>
        )}
        {items && (
          <View style={styles.cardItems}>
            {items.map((item) => (
              <View key={item} style={styles.cardItem}>
                <Text size="large" style={styles.cardItemText}>
                  -{' '}
                </Text>
                <Text size="large" style={styles.cardItemText}>
                  {item}
                </Text>
              </View>
            ))}
          </View>
        )}
        {cta && (
          <TouchableRipple onPress={cta.onPress} style={styles.cta}>
            <>
              <Text size="large" style={styles.cardItemText}>
                {cta.label}
              </Text>
              <Feather name="chevron-right" color={CSTheme.colors.primary} size={24} />
            </>
          </TouchableRipple>
        )}
      </View>
    </Card>
  );
}
