import React, { ReactElement } from 'react';
import { Platform, StyleProp, StyleSheet, Text, TextStyle, TouchableOpacity } from 'react-native';
import CSTheme from './CSTheme';
import PaperButton from './PaperButton';

type HeaderActionButtonProps = {
  style?: StyleProp<TextStyle>;
  title: string;
  testID?: string;
  disabled?: boolean;
  onPress?: () => void;
};

const styles = StyleSheet.create({
  iosBtn: {
    color: CSTheme.colors.background,
    shadowOpacity: 0,
    fontSize: 16,
    ...CSTheme.fonts.regular,
    textTransform: 'none',
    letterSpacing: 0,
    paddingVertical: 4,
  },
  disabled: {
    opacity: 0.75,
  },
  androidStyle: {
    shadowOpacity: 0,
    marginRight: 8,
  },
  androidContent: {
    paddingVertical: 4,
  },
  androidLabel: {
    fontSize: 16,
    ...CSTheme.fonts.regular,
    textTransform: 'none',
    letterSpacing: 0,
  },
});

/**
 * Button component
 */
function HeaderActionButton({
  style,
  title,
  disabled,
  testID,
  onPress,
}: HeaderActionButtonProps): ReactElement {
  if (Platform.OS === 'android') {
    return (
      <PaperButton
        disabled={disabled}
        onPress={onPress}
        mode="text"
        compact
        style={styles.androidStyle}
        contentStyle={styles.androidContent}
        labelStyle={[styles.androidLabel, disabled && styles.disabled, style]}
      >
        <Text>{title}</Text>
      </PaperButton>
    );
  }
  return (
    <TouchableOpacity
      accessibilityRole="button"
      style={[
        {
          paddingVertical: 8,
          paddingHorizontal: 0,
        },
      ]}
      testID={testID}
      disabled={disabled}
      onPress={onPress}
    >
      <Text style={[styles.iosBtn, disabled && styles.disabled, style]}>{title}</Text>
    </TouchableOpacity>
  );
}

export default HeaderActionButton;
