/**
 * @generated SignedSource<<4c724fa68e6735d3032b20ab1920e76d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ReferralCodeScreenQuery$variables = {};
export type ReferralCodeScreenQuery$data = {
  readonly me: {
    readonly referral?: {
      readonly referralCode: string;
      readonly customerReferralAmount: number;
      readonly caregiverReferralAmount: number;
    };
  };
};
export type ReferralCodeScreenQuery = {
  variables: ReferralCodeScreenQuery$variables;
  response: ReferralCodeScreenQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CaregiverReferral",
      "kind": "LinkedField",
      "name": "referral",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "referralCode",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "customerReferralAmount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "caregiverReferralAmount",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Caregiver",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ReferralCodeScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ReferralCodeScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v0/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "type": "Node",
            "abstractKey": "__isNode"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "fcc8b237c6580001669da05e2200bee9",
    "id": null,
    "metadata": {},
    "name": "ReferralCodeScreenQuery",
    "operationKind": "query",
    "text": "query ReferralCodeScreenQuery {\n  me {\n    __typename\n    ... on Caregiver {\n      referral {\n        referralCode\n        customerReferralAmount\n        caregiverReferralAmount\n      }\n    }\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "9c1c0fbdff83ba38142e70026c8be621";

export default node;
