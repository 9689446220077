import { FormattedMessage } from 'react-intl';
import React from 'react';
import { isFuture, isPast, isToday, startOfDay } from 'date-fns';
import { graphql, useFragment } from 'react-relay/hooks';
import {
  CalendarAttentionIcon,
  CalendarCheckIcon,
  CalendarQuestionIcon,
  CalendarTodayIcon,
} from '../../Component/Icon';
import { CareshipColors } from '../../Component/CSTheme';
import { TopBanner } from '../../Component';
import { StatusBarFirstAppointment_contract$key } from './__generated__/StatusBarFirstAppointment_contract.graphql';
import useProfile from '../../../Context/Profile/Hook/useProfile';

interface ContractCancelationBarProps {
  contractFragment: StatusBarFirstAppointment_contract$key;
}

export default function StatusBarFirstAppointment({
  contractFragment,
}: ContractCancelationBarProps) {
  const { profile } = useProfile();
  const { firstBookingStatus, firstBooking, hasFulfilledBooking } = useFragment(
    graphql`
      fragment StatusBarFirstAppointment_contract on Contract {
        hasFulfilledBooking
        firstBookingStatus
        firstBooking {
          endDate
        }
      }
    `,
    contractFragment,
  );

  if (hasFulfilledBooking || !firstBooking || firstBookingStatus === 'declined') {
    return null;
  }

  const { endDate: endDateString } = firstBooking;
  const endDate = new Date(endDateString);
  const backgroundColor =
    firstBookingStatus === 'confirmed' && isFuture(startOfDay(endDate)) ? 'green' : 'darkGray';

  if (firstBookingStatus !== 'confirmed'  && !profile?.isCareshipEmployee) {
    return (
      <TopBanner
        backgroundColor={backgroundColor}
        icon={<CalendarQuestionIcon testID="questionIcon" color={CareshipColors.white} />}
        title={<FormattedMessage id="FIRST_APPOINTMENT_STATUS_BAR_UNCONFIRMED_TITLE" />}
      />
    );
  }

  if (isToday(endDate) && isFuture(endDate)) {
    return (
      <TopBanner
        backgroundColor={backgroundColor}
        icon={<CalendarTodayIcon testID="todayIcon" color={CareshipColors.white} />}
        title={<FormattedMessage id="FIRST_APPOINTMENT_STATUS_BAR_CONFIRMED_TODAY_TITLE" />}
        description={
          <FormattedMessage id="FIRST_APPOINTMENT_STATUS_BAR_CONFIRMED_TODAY_DESCRIPTION" />
        }
      />
    );
  }

  if (isPast(endDate)) {
    return (
      <TopBanner
        backgroundColor={backgroundColor}
        icon={<CalendarAttentionIcon testID="attentionIcon" color={CareshipColors.white} />}
        title={<FormattedMessage id="FIRST_APPOINTMENT_STATUS_BAR_CONFIRMED_PAST_TITLE" />}
        description={
          <FormattedMessage id="FIRST_APPOINTMENT_STATUS_BAR_CONFIRMED_PAST_DESCRIPTION" />
        }
      />
    );
  }

  return (
    <TopBanner
      backgroundColor={backgroundColor}
      icon={<CalendarCheckIcon testID="checkIcon" color={CareshipColors.white} />}
      title={<FormattedMessage id="FIRST_APPOINTMENT_STATUS_BAR_CONFIRMED_TITLE" />}
      description={
        !profile?.isCareshipEmployee && (<FormattedMessage id="FIRST_APPOINTMENT_STATUS_BAR_CONFIRMED_DESCRIPTION" />)
      }
    />
  );
}
