import { useRoute, RouteProp, useNavigation } from '@react-navigation/native';
import React, { useLayoutEffect } from 'react';
import { Layout } from '../../Component';
import InvoiceDetail from '../Component/InvoiceDetail';
import { AccountStackNavigationProp } from '../Navigator/AccountStackNavigationProp';
import { AccountStackNavigatorParamList } from '../Navigator/AccountStackNavigator';

export default function InvoiceDetailScreen() {
  const {
    params: { invoiceId, filename, title },
  } = useRoute<RouteProp<AccountStackNavigatorParamList, 'InvoiceDetail'>>();
  const navigation = useNavigation<AccountStackNavigationProp<'InvoiceDetail'>>();

  useLayoutEffect(() => {
    navigation.setOptions({
      title,
    });
  }, [navigation, title]);

  return (
    <Layout>
      <InvoiceDetail invoiceId={invoiceId} filename={filename} />
    </Layout>
  );
}
