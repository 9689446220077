import { useCallback, useEffect, useState } from 'react';
import { reaction } from 'mobx';
import { useStores } from '../Store';
import { events, trackEvent } from '../Tracking/Tracking';
import useServices from './useServices';
import useLogger from './useLogger';
import RelayEnvironment from '../Relay/RelayEnvironment';

type SessionState = {
  readonly isLoggedIn: boolean;
  readonly isInitialized: boolean;
};

type UseSessionReturn = [
  SessionState,
  {
    handleSubmit: (data: { username: string; password: string }) => Promise<void>;
    errorMessage: string | null;
  },
];

export default function useSession(): UseSessionReturn {
  const stores = useStores();
  const logger = useLogger();
  const { oauthService } = useServices();
  const [errorMessage, setError] = useState<string | null>(null);

  const handleSubmit = useCallback(
    async ({ username, password }: { username: string; password: string }) => {
      setError(null);

      try {
        await oauthService.authorize(username, password);

        stores.sessionStore.setIsLoggedIn(true);
        trackEvent(events.USER_LOGGED_IN);
      } catch (e) {
        logger.info('failed to log in', e);
        setError((e as Error).message);
      }
    },
    [oauthService, logger, stores.sessionStore],
  );

  const [sessionData, setSessionData] = useState<SessionState>({
    isLoggedIn: false,
    isInitialized: false,
  });

  useEffect(
    () =>
      reaction(
        () => [stores.sessionStore.isLoggedIn, stores.sessionStore.viewData],
        () => {
          logger.debug(
            `setting session data, logged ${stores.sessionStore.isLoggedIn ? 'in' : 'out'}`,
          );
          setSessionData(stores.sessionStore.viewData);

          if (!stores.sessionStore.isLoggedIn) {
            RelayEnvironment.resetEnvironment();
          }
        },
        {
          fireImmediately: true,
        },
      ),
    [logger, oauthService, stores],
  );

  return [
    sessionData,
    {
      handleSubmit,
      errorMessage,
    },
  ];
}
