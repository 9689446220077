import '@formatjs/intl-pluralrules/dist/polyfill';
import '@formatjs/intl-pluralrules/dist/locale-data/de';
import '@formatjs/intl-pluralrules/dist/locale-data/en';

import React, { ReactElement } from 'react';
import * as Notifications from 'expo-notifications';
import { LogBox, Platform, StyleSheet } from 'react-native';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { PaperProvider } from 'react-native-paper';
import { enableScreens } from 'react-native-screens';
import { RelayEnvironmentProvider } from 'react-relay/hooks';
import { de, enGB } from 'date-fns/locale';
import { ActionSheetProvider } from '@expo/react-native-action-sheet';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { ThemeProvider } from '@material-ui/styles';
import { GestureHandlerRootView } from 'react-native-gesture-handler';
import { intl, IntlProvider } from '../../Infrastructure/Intl';
import { StoreProvider, useStorePersistence } from '../../Infrastructure/Store';
import { CSTheme } from '../Component';
import ServiceProvider from '../../Infrastructure/Service/ServiceProvider';
import { useCachedAssets } from '../../Infrastructure/Hook';
import '../../Infrastructure/ErrorTracking/Sentry';
import relayEnvironment from '../../Infrastructure/Relay/RelayEnvironment';
import EmotionThemeProvider from './Theme/EmotionThemeProvider';
import materialTheme from './Theme/materialTheme';
import HtmlLang from './HtmlLang';
import AppNavigationWithContainerAndProvider from './Navigation/AppNavigationWithContainerAndProvider';
import * as Updates from 'expo-updates';
// if (Platform.OS !== 'web') {
//   LogBox.ignoreLogs([
//     'Setting a timer for a long period of time, i.e. multiple minutes',
//     'ViewPropTypes will be removed',
//   ]);
// }

enableScreens();

const styles = StyleSheet.create({
  gestureHandlerRootView: {
    flex: 1,
  },
});

Notifications.setNotificationHandler({
  handleNotification: () =>
    Promise.resolve({
      shouldShowAlert: true,
      shouldPlaySound: false,
      shouldSetBadge: false,
    }),
});

export default function App(): ReactElement | null {

  async function onFetchUpdateAsync() {
    try {
      const update = await Updates.checkForUpdateAsync();

      if (update.isAvailable) {
        console.log('update.isAvailable:true')
        await Updates.fetchUpdateAsync();
        await Updates.reloadAsync();
      }
    } catch (error) {
      // You can also add an alert() to see the error message in case of an error when fetching updates.
      console.log(`Error fetching latest Expo update: ${error}`);
    }
  }
  onFetchUpdateAsync().then(r => {console.log("checking for updates")});

  useStorePersistence();
  const isLoaded = useCachedAssets();

  if (!isLoaded) {
    return null;
  }

  return (
    <GestureHandlerRootView style={styles.gestureHandlerRootView}>
      <StoreProvider>
        <ActionSheetProvider>
          <ServiceProvider>
            <EmotionThemeProvider>
              <RelayEnvironmentProvider environment={relayEnvironment.getEnvironment()}>
                <IntlProvider>
                  <SafeAreaProvider>
                    <ThemeProvider theme={materialTheme}>
                      <MuiPickersUtilsProvider
                        locale={intl.locale.startsWith('de') ? de : enGB}
                        utils={DateFnsUtils}
                      >
                        <PaperProvider theme={CSTheme}>
                          <HtmlLang />
                          <AppNavigationWithContainerAndProvider />
                        </PaperProvider>
                      </MuiPickersUtilsProvider>
                    </ThemeProvider>
                  </SafeAreaProvider>
                </IntlProvider>
              </RelayEnvironmentProvider>
            </EmotionThemeProvider>
          </ServiceProvider>
        </ActionSheetProvider>
      </StoreProvider>
    </GestureHandlerRootView>
  );
}
