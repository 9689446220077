import { Platform, StyleSheet } from 'react-native';
import { CareshipColors } from '../../../Component/CSTheme';
import { CSTheme } from '../../../Component';

export default StyleSheet.create({
  day: {
    marginRight: 4,
    textTransform: 'uppercase',
    color: CareshipColors.slate300,
    fontSize: 14,
    fontFamily: CSTheme.fonts.medium.fontFamily,
    fontWeight: CSTheme.fonts.medium.fontWeight,
  },
  time: {
    color: CareshipColors.slate300,
    fontSize: 14,
  },
  date: {
    fontSize: 24,
    fontFamily: CSTheme.fonts.medium.fontFamily,
    fontWeight: CSTheme.fonts.medium.fontWeight,
  },
  nameContainer: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
  },
  name: {
    fontSize: 16,
    marginHorizontal: 8,
    ...Platform.select({
      android: {
        paddingBottom: 2,
      },
    }),
  },
});
