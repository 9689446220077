/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import Constants from 'expo-constants';
import { Platform } from 'react-native';
import { useCallback } from 'react';
import useAuthorizedClient from '../../../Infrastructure/Hook/useAuthorizedClient';
import { isSuccess } from '../../../Infrastructure/Service';
import Manifest from '../../../Infrastructure/Constants/Manifest';

const apiBackendUrl = Constants?.expoConfig?.extra?.apiBackendUrl;

const apiURI: string = Platform.OS === 'web' ? Manifest.extra.apiEndpoint : apiBackendUrl;


export default function useInvoiceDownloadUrl() {
  const authorizedClient = useAuthorizedClient();

  const generateUrl = useCallback(
    async (invoiceId: string) => {
      if (!invoiceId) {
        return undefined;
      }

      const res = await authorizedClient.request<{ token_id: string }>(
        `/caregiver-app/invoice/${invoiceId}/request-token`,
        {
          method: 'POST',
        },
      );

      if (isSuccess(res)) {
        return `${apiURI}/caregiver-app/invoice/download/${res.data.token_id}`;
      }

      return undefined;
    },
    [authorizedClient],
  );

  return {
    generateUrl,
  };
}
