import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';
import { CareshipColors } from '../CSTheme';

interface CheckIconProps extends SvgProps {
  color?: string;
  size?: number;
}

function CheckCircleFilledIcon({
  color = CareshipColors.slate400,
  size = 24,
  ...props
}: CheckIconProps) {
  return (
    <Svg width={size} height={size} viewBox="0 0 48 48" {...props}>
      <Path
        d="M24 2.5C12.1 2.5 2.5 12.1 2.5 24S12.1 45.5 24 45.5 45.5 35.9 45.5 24 35.9 2.5 24 2.5zm12.8 13.8l-.1.2-15 18c-.5.6-1.3.7-2 .3l-.2-.1-9-8c-.6-.6-.7-1.5-.1-2.1.5-.6 1.3-.7 1.9-.3l.2.1 7.8 7 14-16.8c.5-.6 1.5-.7 2.1-.2.6.4.8 1.3.4 1.9z"
        fill={color}
      />
    </Svg>
  );
}

export default CheckCircleFilledIcon;
