import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';
import { CareshipColors } from '../CSTheme';

interface ArrowDownIconProps extends SvgProps {
  color?: string;
  size?: number;
}

function ArrowDownIcon({
  color = CareshipColors.slate400,
  size = 24,
  ...props
}: ArrowDownIconProps) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" {...props}>
      <Path
        fill={color}
        d="M12.05,3 C12.4296958,3 12.743491,3.28215388 12.7931534,3.64822944 L12.8,3.75 L12.799,18.466 L17.6171149,13.659152 L17.7013042,13.5866161 C17.9951284,13.3690486 18.4117682,13.3936617 18.6777745,13.6601883 C18.9703814,13.9533676 18.9699175,14.4282411 18.6767382,14.720848 L18.6767382,14.720848 L12.5847228,20.800971 L12.5006171,20.8734445 C12.2070896,21.090853 11.790903,21.0665656 11.5247898,20.8006618 L11.5247898,20.8006618 L5.43987869,14.7205388 L5.36722744,14.6364489 C5.14925743,14.3429232 5.17329948,13.92625 5.43946122,13.6598787 L5.43946122,13.6598787 L5.52355106,13.5872274 C5.81707679,13.3692574 6.23374996,13.3932995 6.50012131,13.6594612 L6.50012131,13.6594612 L11.299,18.455 L11.3,3.75 C11.3,3.33578644 11.6357864,3 12.05,3 Z"
      />
    </Svg>
  );
}

export default ArrowDownIcon;
