import React, { ComponentProps } from 'react';
import { Button } from 'react-native-paper';
import { View } from 'react-native';
import styles from './Tab.styles';

type TabRowProps = ComponentProps<typeof Button> & {};

export default function TabRow({ style, children, ...props }: TabRowProps) {
  return (
    <View style={[styles.row, style]} {...props}>
      {children}
    </View>
  );
}
