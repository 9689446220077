import React, { ReactNode } from 'react';
import { Text } from 'react-native-paper';
import { StyleSheet } from 'react-native';
import CSTheme, { CareshipColors } from '../CSTheme';

type Props = {
  active?: boolean;
  children?: ReactNode;
};

const styles = StyleSheet.create({
  text: {
    fontSize: 18,
    color: CareshipColors.slate300,
    flex: 1,
    flexWrap: 'wrap',
    flexShrink: 1,
  },
  active: {
    color: CSTheme.colors.text,
  },
});

export default function Label({ active, children }: Props) {
  return <Text style={[styles.text, active && styles.active]}>{children}</Text>;
}
