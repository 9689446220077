import { action, computed, makeObservable, observable } from 'mobx';
import AbstractStore from '../../../Infrastructure/Store/AbstractStore';
import Proposal, { ProposalResponse } from './Model/Proposal';

export type ProposalListResponse = Readonly<ProposalResponse>[];

export default class ProposalStore extends AbstractStore {
  constructor() {
    super();
    makeObservable(this, {
      proposals: observable,
      viewData: computed,
      declineProposal: action,
      acceptProposal: action,
      hydrate: action,
      reset: action,
      handleProposalListResponse: action,
      handleProposalResponse: action,
    });
  }

  public proposals: Map<string, Proposal> = observable.map();

  public get viewData() {
    return {
      proposals: Array.from(this.proposals.values()).sort(
        ({ expiresAt: a }, { expiresAt: b }) => new Date(a).getTime() - new Date(b).getTime(),
      ),
      openProposalCount: Array.from(this.proposals.values()).filter(
        (proposal) => proposal.status === 'open',
      ).length,
    };
  }

  declineProposal(serviceRequestId: string) {
    this.proposals.delete(serviceRequestId);
  }

  acceptProposal(serviceRequestId: string) {
    const proposal = this.getOneByServiceRequestId(serviceRequestId);
    this.proposals.set(
      serviceRequestId,
      Proposal.fromObject({ ...proposal, status: 'accepted' } as Proposal),
    );
  }

  getOneByServiceRequestId(serviceRequestId?: string): Proposal | null {
    return (
      computed(() => {
        return serviceRequestId ? this.proposals.get(serviceRequestId) : null;
      }).get() || null
    );
  }

  hydrate = (data?: { proposals: { [id: string]: Proposal } } | null) => {
    if (!data?.proposals) {
      return;
    }

    this.proposals = new Map(
      Object.values(data.proposals).map((p) => [p.serviceRequestId, Proposal.fromObject(p)]),
    );
  };

  reset = () => {
    this.proposals = new Map<string, Proposal>();
  };

  handleProposalResponse(response: ProposalResponse) {
    this.proposals.set(response.service_request_id, Proposal.fromResponse(response));
  }

  handleProposalListResponse(response: ProposalListResponse) {
    this.proposals = new Map(
      response.map((resp) => [resp.service_request_id, Proposal.fromResponse(resp)]),
    );
  }
}
