import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  profileWrap: {
    flexDirection: 'row',
    maxWidth: '100%',
    overflow: 'hidden',
    flexShrink: 0,
  },
  title: {
    flex: 1,
  },
  profileName: {
    marginLeft: 16,
    maxWidth: '100%',
  },
  avatar: {
    backgroundColor: 'transparent',
  },
});
