import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigation } from '@react-navigation/native';
import { ScrollView } from 'react-native-gesture-handler';
import { useQueryLoader } from 'react-relay/hooks';
import Layout from '../../Component/Layout';
import { ListItemAction } from '../../Component';
import {
  AlertCircle,
  CertificateIcon,
  SpeechBubblePlainIcon,
  WebPageIcon,
} from '../../Component/Icon';
import { AccountStackNavigationProp } from '../Navigator/AccountStackNavigationProp';
import AboutMeCard, { aboutMeCardQuery } from './AboutMe/AboutMeCard';
import { AboutMeCardQuery } from './AboutMe/__generated__/AboutMeCardQuery.graphql';
import useProfile from '../../../Context/Profile/Hook/useProfile';

export default function ProfileScreen() {
  const { profile } = useProfile();
  const navigation = useNavigation<AccountStackNavigationProp<'Profile'>>();
  const handleMyServices = () => navigation.navigate('Services');
  const handleEditEducationQualification = () => navigation.navigate('EducationQualifications');
  const handleEditExperienceAndAbilities = () => navigation.navigate('ExperienceAndAbilities');
  const handleEditLanguages = () => navigation.navigate('Languages');
  const [preloadedQuery, loadQuery] = useQueryLoader<AboutMeCardQuery>(aboutMeCardQuery);

  useEffect(() => {
    loadQuery(
      {},
      {
        fetchPolicy: 'store-and-network',
      },
    );
  }, [loadQuery]);

  return (
    <Layout>
      <ScrollView>
        {preloadedQuery && <AboutMeCard preloadedQuery={preloadedQuery} />}
        {!profile?.isCareshipEmployee && (
          <ListItemAction
            onPress={handleMyServices}
            multiline
            actionLabel={' '}
            icon={<WebPageIcon />}
          >
            <FormattedMessage id="PROFILE_SERVICES" />
          </ListItemAction>
        )}

        <ListItemAction
          onPress={handleEditEducationQualification}
          actionLabel={' '}
          icon={<CertificateIcon />}
        >
          <FormattedMessage id="PROFILE_EDUCATION_AND_QUALIFICATIONS" />
        </ListItemAction>
        <ListItemAction
          onPress={handleEditExperienceAndAbilities}
          actionLabel={' '}
          icon={<AlertCircle />}
        >
          <FormattedMessage id="PROFILE_EXPERIENCE_AND_ABILITIES" />
        </ListItemAction>
        <ListItemAction
          onPress={handleEditLanguages}
          actionLabel={' '}
          icon={<SpeechBubblePlainIcon />}
          marginBottom="base"
        >
          <FormattedMessage id="PROFILE_LANGUAGES" />
        </ListItemAction>
      </ScrollView>
    </Layout>
  );
}
