/* eslint-disable global-require */
import { FontSource, useFonts } from 'expo-font';

export default function useCachedAssets() {
  const [isReady] = useFonts({
    'basier-circle-bold': require('../../../assets/BasierCircle-Bold.ttf') as FontSource,
    'basier-circle-regular': require('../../../assets/BasierCircle-Regular.ttf') as FontSource,
    'Feather': require("../../../assets/vector-icons/Feather.ttf"),
    'MaterialCommunityIcons': require("../../../assets/vector-icons/MaterialCommunityIcons.ttf"),
    'Material Design Icons': require("../../../assets/vector-icons/MaterialCommunityIcons.ttf"),
  });

  return isReady;
}
