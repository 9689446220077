/**
 * @generated SignedSource<<c3c45399a84a9624299d3738595a747a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CustomerListScreenQuery$variables = {};
export type CustomerListScreenQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ProposalContractSectionList_query">;
};
export type CustomerListScreenQuery = {
  variables: CustomerListScreenQuery$variables;
  response: CustomerListScreenQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "street",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "postalCode",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "city",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "CustomerListScreenQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "ProposalContractSectionList_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "CustomerListScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ContractConnection",
        "kind": "LinkedField",
        "name": "allContracts",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ContractEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Contract",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "contractId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isActive",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CareReceiver",
                    "kind": "LinkedField",
                    "name": "careReceiver",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Name",
                        "kind": "LinkedField",
                        "name": "name",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "firstName",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "lastName",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Address",
                        "kind": "LinkedField",
                        "name": "address",
                        "plural": false,
                        "selections": [
                          (v0/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "houseNumber",
                            "storageKey": null
                          },
                          (v1/*: any*/),
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cancelationScheduledFor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasFulfilledBooking",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Booking",
                    "kind": "LinkedField",
                    "name": "firstBooking",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endDate",
                        "storageKey": null
                      },
                      (v3/*: any*/),
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ProposalConnection",
        "kind": "LinkedField",
        "name": "allProposals",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProposalEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Proposal",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "serviceRequestId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "expiresAt",
                    "storageKey": null
                  },
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProposalCareReceiver",
                    "kind": "LinkedField",
                    "name": "careReceiver",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "gender",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ProposalAddress",
                        "kind": "LinkedField",
                        "name": "address",
                        "plural": false,
                        "selections": [
                          (v0/*: any*/),
                          (v1/*: any*/),
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "02a24a047b409600819b165e50287e2f",
    "id": null,
    "metadata": {},
    "name": "CustomerListScreenQuery",
    "operationKind": "query",
    "text": "query CustomerListScreenQuery {\n  ...ProposalContractSectionList_query\n}\n\nfragment ContractListItem_contract on Contract {\n  contractId\n  careReceiver {\n    name {\n      firstName\n      lastName\n    }\n    address {\n      street\n      houseNumber\n      postalCode\n      city\n    }\n  }\n  cancelationScheduledFor\n  hasFulfilledBooking\n  firstBooking {\n    endDate\n    status\n    id\n  }\n}\n\nfragment ProposalContractSectionList_query on Query {\n  allContracts {\n    edges {\n      node {\n        contractId\n        isActive\n        ...ContractListItem_contract\n        id\n      }\n    }\n  }\n  allProposals {\n    edges {\n      node {\n        id\n        serviceRequestId\n        expiresAt\n        status\n        ...ProposalListItem_proposal\n      }\n    }\n  }\n}\n\nfragment ProposalListItem_proposal on Proposal {\n  serviceRequestId\n  status\n  careReceiver {\n    gender\n    name\n    address {\n      street\n      postalCode\n      city\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "0618fef1a0f5ba9bb018fd2eb803024c";

export default node;
