import { StyleSheet } from 'react-native';
import CSTheme, { CareshipColors } from '../CSTheme';

export default StyleSheet.create({
  badge: {
    marginLeft: 8,
    marginTop: -2,
  },
  text: {
    textTransform: 'uppercase',
    ...CSTheme.fonts.medium,
    letterSpacing: 1,
    fontSize: 14,
    height: 50,
  },
  activeLabel: {
    color: CSTheme.colors.primary,
  },
  tabContent: {
    flexDirection: 'row',
    justifyContent: 'center',
    flex: 1,
  },
  row: {
    paddingHorizontal: 16,
    flexDirection: 'row',
    borderBottomColor: CareshipColors.slate100,
    borderBottomWidth: 1,
    justifyContent: 'center',
  },
});
