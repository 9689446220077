/**
 * @generated SignedSource<<a29e4eebae7e584983c520b6a0782400>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditContactAndAddressFormCaregiverFragment$data = {
  readonly id: string;
  readonly phoneNumber: string | null;
  readonly mobilePhoneNumber: string | null;
  readonly company: string;
  readonly address: {
    readonly street: string;
    readonly city: string;
    readonly postalCode: string;
    readonly additionalInformation: string;
  };
  readonly " $fragmentType": "EditContactAndAddressFormCaregiverFragment";
};
export type EditContactAndAddressFormCaregiverFragment$key = {
  readonly " $data"?: EditContactAndAddressFormCaregiverFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"EditContactAndAddressFormCaregiverFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditContactAndAddressFormCaregiverFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "phoneNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mobilePhoneNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "company",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Address",
      "kind": "LinkedField",
      "name": "address",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "street",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "city",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "postalCode",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "additionalInformation",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Caregiver",
  "abstractKey": null
};

(node as any).hash = "d9aabd9f0f33b3e7f186f5e67564bd52";

export default node;
