import { action, computed, makeObservable, observable } from 'mobx';
import { StoreInterface } from './StoreInterface';

export default abstract class AbstractStore implements StoreInterface {
  protected constructor() {
    makeObservable(this, {
      initialized: observable,
      isInitialized: computed,
      setInitialized: action,
    });
  }

  public initialized: boolean = false;

  get isInitialized() {
    return this.initialized;
  }

  /**
   * Kept separate from hydrate so we can mark store as initialized
   * even if no hydration was needed (no state in AsyncStorage)
   */
  setInitialized() {
    this.initialized = true;
  }

  abstract hydrate(data: unknown): void;

  abstract reset(): void;
}
