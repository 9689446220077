import styled from '@emotion/native';
import { SpacedBoxProps } from '@emotion/react';
import { View } from 'react-native';
import CSTheme, { CareshipColors } from './CSTheme';
import withSpacing, { shouldForwardProp } from '../Root/Theme/withSpacing';

interface CardProps {
  mode?: 'raised' | 'contained' | 'outlined';
}

export default styled(View, {
  shouldForwardProp,
})<CardProps & SpacedBoxProps>(
  withSpacing(
    ({ mode }) => [
      mode === 'raised' && {
        backgroundColor: CareshipColors.white,
        borderRadius: 8,
        elevation: 3,
        shadowColor: 'rgb(0,0,0)',
        shadowOpacity: 0.15,
        shadowOffset: {
          height: 4,
          width: 0,
        },
        shadowRadius: 16,
      },
      mode === 'contained' && {
        backgroundColor: CareshipColors.slate050,
        borderRadius: CSTheme.roundness,
      },
      mode === 'outlined' && {
        borderWidth: 2,
        borderStyle: 'solid',
        borderColor: CareshipColors.slate100,
        borderRadius: 8,
      },
    ],
    ({ mode }) => ({
      padding: ['contained', 'outlined'].includes(mode as string) ? 'base' : undefined,
    }),
  ),
);
