import { LinkingOptions, ParamListBase } from '@react-navigation/native';
import * as Linking from 'expo-linking';
import * as Notifications from 'expo-notifications';
import { NotificationResponse } from 'expo-notifications';
// eslint-disable-next-line import/no-extraneous-dependencies
import { getStateFromPath as getStateFromPathDefault, PathConfig } from '@react-navigation/core';
import Manifest from '../../../../Infrastructure/Constants/Manifest';
import { AppNavigationParamList } from '../AppNavigator';
import { SubNavigator } from '../../../Types/Navigator';
import { events, trackEvent } from '../../../../Infrastructure/Tracking/Tracking';
import { logger } from '../../../../Infrastructure/Service';
import rewriteMyCareshipPaths from './rewriteMyCareshipPaths';

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
const prefixes: string[] = [Linking.createURL('/'), Manifest.extra.myCareshipUrl as string];

// Type aliases enables autocompletion for the screens in the linking config
type TypedPathConfig<T extends ParamListBase, PLK> = Omit<
  PathConfig,
  'screens' | 'initialRouteName' | 'parse'
> & {
  screens?: TypedPathConfigMap<T>;
  initialRouteName?: keyof T;
  parse?: { [P in keyof PLK]: (value: string) => any };
};

type TypedPathConfigMap<PL extends ParamListBase> = {
  [PLK in keyof PL]?: TypedPathConfig<PL[PLK] extends SubNavigator<infer X> ? X : PL, PL[PLK]>;
};

interface ContractCreatedNotification extends NotificationResponse {
  notification: NotificationResponse['notification'] & {
    request: NotificationResponse['notification']['request'] & {
      content: NotificationResponse['notification']['request']['content'] & {
        data: {
          type: 'contract_was_created';
          contract_id: string;
        };
      };
    };
  };
}

interface NewProposalNotification extends NotificationResponse {
  notification: NotificationResponse['notification'] & {
    request: NotificationResponse['notification']['request'] & {
      content: NotificationResponse['notification']['request']['content'] & {
        data: {
          type: 'new_proposal';
          serviceRequestId: string;
        };
      };
    };
  };
}

type IncomingNotification =
  | NewProposalNotification
  | ContractCreatedNotification
  | NotificationResponse;

function isContractCreatedNotification(
  response: IncomingNotification,
): response is ContractCreatedNotification {
  return response.notification.request.content.data.type === 'contract_was_created';
}

function isNewProposalNotification(
  response: IncomingNotification,
): response is NewProposalNotification {
  return response.notification.request.content.data.type === 'new_proposal';
}

/**
 * This holds the mapping from parsed url to actual screens.
 * https://reactnavigation.org/docs/deep-linking#deep-link-integration
 */
export default <LinkingOptions>{
  prefixes,
  getStateFromPath: (path, options) =>
    getStateFromPathDefault(rewriteMyCareshipPaths(path), options),
  config: {
    screens: <TypedPathConfigMap<AppNavigationParamList>>{
      Auth: {
        path: '/login',
        parse: {
          isPasswordReset: (value) => value === 'true',
        },
      },
      PasswordReset: {
        path: '/new-password',
        parse: {
          token: String,
          email: String,
        },
      },
      RequestPasswordReset: {
        path: '/request-password-reset',
      },
      FirstAppointmentChecklist: {
        path: '/customers/:contractId/first-appointment-checklist',
        parse: {
          contractId: String,
        },
      },
      FirstCallChecklist: {
        path: '/customers/:contractId/first-call-checklist',
        parse: {
          contractId: String,
        },
      },
      DeclineProposal: {
        path: '/customers/proposals/:serviceRequestId/decline',
        parse: {
          serviceRequestId: String,
        },
      },
      AddAppointment: {
        path: '/bookings/new',
      },
      EditAppointment: {
        path: '/bookings/:bookingId/decline',
        parse: {
          bookingId: String,
        },
      },
      CancelAppointment: {
        path: '/bookings/:bookingId/cancel',
        parse: {
          bookingId: String,
        },
      },
      FulfillBooking: {
        path: '/bookings/:bookingId/fulfill',
        parse: {
          bookingId: String,
        },
      },
      FirstBookingConfirm: {
        path: '/customers/:contractId/first-booking-confirm',
        parse: {
          contractId: String,
        },
      },
      FirstBookingDecline: {
        path: '/customers/:contractId/first-booking-decline',
        parse: {
          contractId: String,
        },
      },
      FirstBookingUpdateConfirmationStatus: {
        path: '/customers/:contractId/first-booking-status',
        parse: {
          contractId: String,
        },
      },
      Loading: {
        path: '/loading',
      },
      App: {
        screens: {
          DashboardTab: {
            path: '',
          },
          BookingListTab: {
            path: '/bookings',
            initialRouteName: 'Diary',
            screens: {
              Diary: {
                path: '',
              },
              BookingDetail: {
                path: '/:bookingId',
                parse: {
                  bookingId: String,
                  isPastBooking: Boolean,
                },
              },
            },
          },
          AccountTab: {
            path: '/account',
            initialRouteName: 'Account',
            screens: {
              Account: {
                path: '/',
              },
              Profile: {
                path: '/profile',
              },
              EditAboutMe: {
                path: '/about-me/edit',
              },
              EditContactAndAddress: {
                path: '/contact-and-address/edit',
              },
              Services: {
                path: '/profile/services',
              },
              EducationQualifications: {
                path: '/profile/education-and-qualifications',
              },
              ExperienceAndAbilities: {
                path: '/profile/experience-and-abilities',
              },
              Languages: {
                path: '/profile/languages',
              },
              Availability: {
                path: '/availability',
              },
              ContactAndAddress: {
                path: '/contact-and-address',
              },
              FinancialOverview: {
                path: '/financial-overview',
              },
              InvoiceList: {
                path: '/financial-overview/invoice-list',
              },
              InvoiceDetail: {
                path: '/financial-overview/invoice-detail',
                parse: {
                  invoiceId: String,
                  filename: String,
                  title: String,
                },
              },
              FinancialDetails: {
                path: '/financial-overview/details',
              },
              EditFinancialDetails: {
                path: '/financial-overview/details/edit',
              },
              PdfView: {
                path: '/pdf',
              },
              WebView: {
                path: '/web-view',
              },
              HelpCenter: {
                path: '/help-center',
              },
              ReferralCode: {
                path: '/referral-code',
              },
            },
          },
          CustomerTab: {
            path: '/customers',
            initialRouteName: 'CustomerDashboard',
            screens: {
              CustomerDashboard: {
                path: '/',
              },
              ProposalDetailView: {
                path: '/proposals/:serviceRequestId',
                parse: {
                  serviceRequestId: String,
                },
              },
              CustomerDetailView: {
                path: '/:contractId',
                parse: {
                  contractId: String,
                },
              },
              ServiceDocumentation: {
                path: '/:contractId/service-documentation/',
                parse: {
                  contractId: String,
                },
              },
              ServiceDocumentationHowTo: {
                path: '/service-documentation-how-to/',
              },
              PdfView: {
                path: '/pdf',
              },
            },
          },
        },
      },
    },
  },
  subscribe(listener) {
    const onReceiveURL = ({ url }: { url: string }) => listener(url);

    // Listen to incoming links from deep linking
    Linking.addEventListener('url', onReceiveURL);

    // Listen to expo push notifications
    const subscription = Notifications.addNotificationResponseReceivedListener((response) => {
      if (isNewProposalNotification(response)) {
        logger.debug(
          `handling new_proposal notification, serviceRequestId: ${String(
            response.notification.request.content.data.contract_id,
          )}`,
        );
        trackEvent(events.NEW_PROPOSAL_NOTIFICATION_TAPPED);
        listener(
          Linking.makeUrl(
            `/customers/proposals/${response.notification.request.content.data.serviceRequestId}`,
          ),
        );
        return;
      }

      if (isContractCreatedNotification(response)) {
        logger.debug(
          `handling contract_was_created notification, contract_id: ${response.notification.request.content.data.contract_id}`,
        );
        trackEvent(events.NEW_CONTRACT_NOTIFICATION_TAPPED);
        listener(
          Linking.makeUrl(`customers/${response.notification.request.content.data.contract_id}`),
        );
        return;
      }

      logger.warn(
        `invalid notification type ${String(response.notification.request.content.data.type)}`,
      );
    });

    return () => {
      Linking.removeEventListener('url', onReceiveURL);
      subscription.remove();
    };
  },
};
