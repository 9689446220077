import React, { useEffect } from 'react';
import { useQueryLoader } from 'react-relay/hooks';
import Layout from '../../Component/Layout';
import EducationQualifications, {
  educationQualificationsQuery,
} from '../Component/EducationQualifications';
import { EducationQualificationsQuery } from '../Component/__generated__/EducationQualificationsQuery.graphql';
import { intl } from '../../../Infrastructure/Intl';

export default function EducationQualificationsScreen() {
  const [preloadedQuery, loadQuery] = useQueryLoader<EducationQualificationsQuery>(
    educationQualificationsQuery,
  );

  useEffect(() => {
    loadQuery(
      { locale: intl.locale.startsWith('en') ? 'EN' : 'DE' },
      {
        fetchPolicy: 'store-and-network',
      },
    );
  }, [loadQuery]);

  return (
    <Layout>{preloadedQuery && <EducationQualifications preloadedQuery={preloadedQuery} />}</Layout>
  );
}
