/**
 * @generated SignedSource<<5ae45223bd6f57b9b40ec8fd78f06427>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type useUpdateBookingFeedbackQuery$variables = {
  bookingId: string;
};
export type useUpdateBookingFeedbackQuery$data = {
  readonly booking: {
    readonly contract: {
      readonly id: string;
      readonly bookingFeedback: {
        readonly bookingCount: number;
        readonly disabled: boolean;
        readonly frequency: number;
      } | null;
    };
  } | null;
};
export type useUpdateBookingFeedbackQuery = {
  variables: useUpdateBookingFeedbackQuery$variables;
  response: useUpdateBookingFeedbackQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "bookingId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "bookingId",
    "variableName": "bookingId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "Contract",
  "kind": "LinkedField",
  "name": "contract",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "kind": "ClientExtension",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "BookingFeedback",
          "kind": "LinkedField",
          "name": "bookingFeedback",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "bookingCount",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "disabled",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "frequency",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ]
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useUpdateBookingFeedbackQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Booking",
        "kind": "LinkedField",
        "name": "booking",
        "plural": false,
        "selections": [
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useUpdateBookingFeedbackQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Booking",
        "kind": "LinkedField",
        "name": "booking",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e29f94d91da7fc1a8607bc168a30ffb3",
    "id": null,
    "metadata": {},
    "name": "useUpdateBookingFeedbackQuery",
    "operationKind": "query",
    "text": "query useUpdateBookingFeedbackQuery(\n  $bookingId: ID!\n) {\n  booking(bookingId: $bookingId) {\n    contract {\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "9204f0833d49647966ed6d3cfa2a82ba";

export default node;
