import React, { useEffect } from 'react';
import { useQueryLoader } from 'react-relay/hooks';
import { Layout } from '../../Component';
import EditContactAndAddressForm, {
  editContactAndAddressFormQuery,
} from '../Component/EditContactAndAddressForm';
import { EditContactAndAddressFormQuery } from '../Component/__generated__/EditContactAndAddressFormQuery.graphql';

export default function EditContactAndAddressScreen() {
  const [preloadedQuery, loadQuery] = useQueryLoader<EditContactAndAddressFormQuery>(
    editContactAndAddressFormQuery,
  );

  useEffect(() => {
    loadQuery(
      {},
      {
        fetchPolicy: 'store-and-network',
      },
    );
  }, [loadQuery]);

  return (
    <Layout>
      {preloadedQuery && <EditContactAndAddressForm preloadedQuery={preloadedQuery} />}
    </Layout>
  );
}
