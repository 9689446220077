import { Platform } from 'react-native';
import Constants from 'expo-constants';

const ANDROID_BUILD_WITH_PUSH = 27;

export default {
  // using getter to be able to easily mock build version in tests
  get notifications() {
    const androidPrePushBinary =
      Platform.OS === 'android' &&
      parseInt(Constants.nativeBuildVersion || '0', 10) < ANDROID_BUILD_WITH_PUSH;

    return !androidPrePushBinary;
  },
};
