import React from 'react';
import { useNavigation } from '@react-navigation/native';
import FirstAppointmentChecklist from '../Component/FirstAppointmentChecklist/FirstAppointmentChecklist';
import { CustomerStackNavigationProp } from '../Navigator/CustomerStackNavigationProp';
import { Layout } from '../../Component';

export default function FirstAppointmentChecklistScreen() {
  const navigation = useNavigation<CustomerStackNavigationProp<'FirstAppointmentChecklist'>>();

  const handlePressSupportingDocuments = () =>
    navigation.navigate('App', {
      screen: 'AccountTab',
      params: {
        screen: 'HelpCenter',
        initial: false,
      },
    });

  return (
    <Layout>
      <FirstAppointmentChecklist onPressSupportingDocuments={handlePressSupportingDocuments} />
    </Layout>
  );
}
