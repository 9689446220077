import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';
import { CareshipColors } from '../CSTheme';

interface BusinessContractIconProps extends SvgProps {
  color?: string;
  colorAccent?: string;
  size?: number;
}

function BusinessContractIcon({
  color = CareshipColors.slate400,
  colorAccent = CareshipColors.primary,
  size = 24,
  ...props
}: BusinessContractIconProps) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" {...props}>
      <Path
        d="M1.5 4.37L.162 2.683a.75.75 0 111.176-.932l1.5 1.892A.75.75 0 013 4.11v4.485c-.02.866.706 1.796 2.47 3.305.18.155.253.216.687.581l.327.276a.75.75 0 01-.97 1.144l-.323-.272a94.39 94.39 0 01-.697-.59c-2.113-1.808-3.03-2.98-2.994-4.462V4.37z"
        fill={color}
      />
      <Path
        d="M6.744 4.357l2.538 2.59a1.968 1.968 0 01-2.819 2.747L4.694 7.882a.75.75 0 011.074-1.048l1.77 1.813a.468.468 0 00.671-.652L5.46 5.188a.75.75 0 01-.215-.525V3.265a5.536 5.536 0 00-.85-2.097.75.75 0 011.245-.836 7.036 7.036 0 011.094 2.742l.011.126v1.157z"
        fill={color}
      />
      <Path
        d="M5.25 8.138a.75.75 0 011.5 0V20.25c0 .414.336.75.75.75H15a.75.75 0 110 1.5H7.5a2.25 2.25 0 01-2.25-2.25V8.138zM23.838 21.316a.75.75 0 11-1.176.932l-1.5-1.892A.75.75 0 0121 19.89v-4.484c.017-.692-.42-1.397-1.5-2.431v3.647a.75.75 0 01-1.287.524l-1.75-1.793a.468.468 0 00-.673.652l2.755 2.808a.75.75 0 01.215.525v1.396c.137.749.426 1.462.85 2.094a.75.75 0 01-1.245.836 7.043 7.043 0 01-1.094-2.737l-.011-.127v-1.156l-2.542-2.59a1.968 1.968 0 012.819-2.748l.463.475v-3.477a.75.75 0 011.234-.573c2.366 2 3.303 3.145 3.266 4.693v4.205l1.338 1.687z"
        fill={color}
      />
      <Path
        d="M12.978 17.25a.75.75 0 110 1.5H9a.75.75 0 110-1.5h3.978zM10.5 13.5a.75.75 0 110 1.5H9a.75.75 0 110-1.5h1.5z"
        fill={color}
      />
      <Path
        d="M19.5 5.25a.75.75 0 111.5 0A5.25 5.25 0 1115.75 0a.75.75 0 110 1.5 3.75 3.75 0 103.75 3.75z"
        fill={colorAccent}
      />
      <Path
        d="M19.72 1.344a.75.75 0 011.06 1.06l-4.098 4.099a1.314 1.314 0 01-1.863.001l-1.289-1.29a.75.75 0 011.062-1.06l1.158 1.16 3.97-3.97z"
        fill={colorAccent}
      />
    </Svg>
  );
}

export default BusinessContractIcon;
