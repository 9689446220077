import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';
import CSTheme from '../CSTheme';

type PhoneIconProps = SvgProps & {
  color?: string;
  size?: number;
};

export default function PhoneIcon({ color, size = 24, ...props }: PhoneIconProps) {
  return (
    <Svg viewBox="0 0 24 24" width={size} height={size} {...props}>
      <Path
        d="M3.402 1.711a2.432 2.432 0 013.438 0l2.97 2.974a2.43 2.43 0 01.712 1.719c0 .645-.257 1.263-.688 1.692l-.085.093a.932.932 0 00.06 1.25l4.755 4.755a.93.93 0 00.658.273.957.957 0 00.683-.298l.147-.133a2.432 2.432 0 013.265.158l2.971 2.97c.949.949.949 2.487 0 3.437l-.705.705c-1.92 1.92-4.915 2.248-7.18.805l-.053-.033A46.252 46.252 0 011.92 9.646l-.147-.241a5.793 5.793 0 01.922-6.988zm2.377 1.061a.932.932 0 00-1.317 0l-.705.705a4.289 4.289 0 00-.595 5.326l.389.567a44.714 44.714 0 0011.597 11.44l.226.135c1.67.947 3.779.67 5.148-.7l.705-.705a.932.932 0 000-1.316l-2.97-2.97c-.34-.339-.883-.363-1.223-.084l-.235.215a2.43 2.43 0 01-3.296-.13l-4.754-4.756c-.887-.888-.95-2.302-.134-3.291l.134-.147a.93.93 0 000-1.315z"
        fill={color || CSTheme.colors.text}
      />
    </Svg>
  );
}
