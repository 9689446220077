import React, { useCallback, useLayoutEffect } from 'react';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { IconButton } from 'react-native-paper';
import { ActivityIndicator } from 'react-native';
import { useIntl } from 'react-intl';
import Layout from '../../Component/Layout';
import { AccountStackNavigationProp } from '../Navigator/AccountStackNavigationProp';
import { AccountStackNavigatorParamList } from '../Navigator/AccountStackNavigator';
import { CSTheme } from '../../Component';
import { events, trackEvent } from '../../../Infrastructure/Tracking/Tracking';
import { useDownloadFile } from '../../../Infrastructure/Hook';
import { CareshipColors } from '../../Component/CSTheme';
import { DownloadIcon } from '../../Component/Icon';
import PdfReader from '../../Component/PdfReader';

type PdfScreenRouteProp = RouteProp<AccountStackNavigatorParamList, 'PdfView'>;

export default function PdfScreen() {
  const intl = useIntl();
  const navigation = useNavigation<AccountStackNavigationProp<'PdfView'>>();
  const {
    params: { uri, filename, headerTitle },
  } = useRoute<PdfScreenRouteProp>();
  const { download, isDownloadInProgress, isDownloadLoading } = useDownloadFile();

  const handleSharePdf = useCallback<() => void>(async () => {
    const sharedOrDownloaded = await download(filename, uri);

    if (sharedOrDownloaded) {
      trackEvent(events.PDF_DOCUMENT_SHARED);
    }
  }, [download, filename, uri]);

  useLayoutEffect(() => {
    navigation.setOptions({
      headerTitle,
      headerBackTitle: intl.formatMessage({ id: 'HEADER_BACK_BUTTON' }),
      headerRight: () => {
        return (
          <IconButton
            onPress={handleSharePdf}
            style={{ margin: -8 }}
            disabled={isDownloadInProgress || isDownloadLoading}
            icon={
              isDownloadLoading
                ? () => <ActivityIndicator color={CSTheme.colors.background} />
                : () => <DownloadIcon color={CareshipColors.white} />
            }
          />
        );
      },
    });
  }, [handleSharePdf, isDownloadInProgress, isDownloadLoading, navigation, headerTitle, intl]);

  return (
    <Layout>
      <PdfReader
        style={{ flex: 1 }}
        source={{
          uri,
        }}
      />
    </Layout>
  );
}
