import { StyleSheet, View } from 'react-native';
import { FormattedMessage, useIntl } from 'react-intl';
import React, { useCallback, useState } from 'react';
import { isPast, isToday } from 'date-fns';
import { useFragment, useMutation } from 'react-relay/hooks';
import { graphql } from 'react-relay';
import { useNavigation } from '@react-navigation/native';
import {
  Button,
  PressableWithConfirmation,
  CSTheme,
  InputErrorMessage,
  Text,
  Card,
} from '../../Component';
import { CareshipColors } from '../../Component/CSTheme';
import {
  ContractConfirmationActionsMutation,
  ContractConfirmationActionsMutation$variables,
} from './__generated__/ContractConfirmationActionsMutation.graphql';
import { useContractService } from '../../../Context/Contract/Hook';
import { events, trackEvent } from '../../../Infrastructure/Tracking/Tracking';
import { logger } from '../../../Infrastructure/Service';
import { ContractConfirmationActions_contract$key } from './__generated__/ContractConfirmationActions_contract.graphql';
import { CustomerStackNavigationProp } from '../Navigator/CustomerStackNavigationProp';
import useProfile from '../../../Context/Profile/Hook/useProfile';

interface ContractConfirmationActionsProps {
  contractFragment: ContractConfirmationActions_contract$key;
}

const styles = StyleSheet.create({
  buttonRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 16,
  },
  container: {
    backgroundColor: CareshipColors.slate050,
    padding: 16,
    borderRadius: 8,
    marginTop: 16,
    marginBottom: 8,
  },
  edgeButton: { minWidth: 78 },
  errorMessage: { marginTop: 16 },
});

const contractConfirmationMutation = graphql`
  mutation ContractConfirmationActionsMutation(
    $contractId: GUID!
    $status: ConfirmationStatusIntention!
  ) {
    updateContractConfirmationStatus(contractId: $contractId, confirmationStatus: $status) {
      messageId
    }
  }
`;

export default function ContractConfirmationActions({
  contractFragment,
}: ContractConfirmationActionsProps) {
  const { profile } = useProfile();
  const navigation = useNavigation<CustomerStackNavigationProp<'CustomerDetailView'>>();
  const {
    contractId,
    firstBookingStatus,
    firstBooking,
    confirmationStatus: contractStatus,
    bookings,
  } = useFragment(
    graphql`
      fragment ContractConfirmationActions_contract on Contract {
        confirmationStatus
        contractId
        firstBookingStatus
        firstBooking {
          endDate
        }
        bookings {
          edges {
            node {
              id
            }
          }
        }
      }
    `,
    contractFragment,
  );
  const contractService = useContractService();
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const intl = useIntl();
  const [commit, isInFlight] = useMutation<ContractConfirmationActionsMutation>(
    contractConfirmationMutation,
  );

  const commitWithStatus = useCallback(
    (status: ContractConfirmationActionsMutation$variables['status'], callback?: Function) => {
      setErrorMsg(null);
      return commit({
        variables: { contractId, status },
        onError: (error) => {
          logger.error(`Failed mutating contract with status: ${String(status)}`, {
            CustomerDetailMutationErrors: error.message,
          });

          setErrorMsg('ERROR_UNKNOWN');
        },
        onCompleted: (response, errors) => {
          if (errors?.length) {
            logger.error(`Failed mutating contract with status: ${String(status)}`, {
              CustomerDetailMutationErrors: errors,
            });
          }

          void contractService.fetchContract(contractId);

          if (callback) {
            callback();
          }
        },
      });
    },
    [commit, contractId, contractService],
  );

  const bookingCount = bookings.edges?.length || 0;

  const handlePressConfirm = useCallback(() => {
    trackEvent(events.CONTRACT_CONFIRMATION_YES_CONFIRM_CLICK);

    commitWithStatus('CONFIRMED', () => {
      navigation.navigate('AddAppointment', {
        contractId,
        modalHeaderTitle: intl.formatMessage({
          id: 'CONTRACT_CONFIRMATION_SECOND_APPOINTMENT_TITLE',
        }),
      });
    });
  }, [commitWithStatus, navigation, contractId, intl]);

  const handlePressReject = () => {
    trackEvent(events.CONTRACT_CONFIRMATION_NO_CONFIRM_CLICK);

    commitWithStatus('REJECTED');
  };

  const handlePressDeferConfirmation = () => {
    trackEvent(events.CONTRACT_CONFIRMATION_MAYBE_CONFIRM_CLICK);

    commitWithStatus('DEFERRED');
  };

  if (!firstBooking) {
    return null;
  }

  const { endDate: endDateString } = firstBooking;
  const endDate = new Date(endDateString);
  const isFirstBookingTodayOrPast = isToday(endDate) || isPast(endDate);

  if (
    firstBookingStatus !== 'confirmed' ||
    !isFirstBookingTodayOrPast ||
    bookingCount > 1 ||
    contractStatus !== 'PENDING'
  ) {
    return null;
  }

  if(profile?.isCareshipEmployee) {
    return <></>
  }

  return (
    <>
      <Card marginHorizontal="base" mode="contained" marginTop="base">
        <Text bold size="large">
          <FormattedMessage id="CONTRACT_CONFIRMATION_ACTION_TITLE" />
        </Text>
        <View style={styles.buttonRow}>
          <PressableWithConfirmation
            dialogTitle={intl.formatMessage(
              {
                id: 'CONTRACT_CONFIRMATION_ACTION_CONFIRM_DIALOG',
              },
              { element: 'title' },
            )}
            dialogContent={intl.formatMessage(
              {
                id: 'CONTRACT_CONFIRMATION_ACTION_CONFIRM_DIALOG',
              },
              { element: 'message' },
            )}
            confirmButtonLabel={intl.formatMessage(
              {
                id: 'CONTRACT_CONFIRMATION_ACTION_CONFIRM_DIALOG',
              },
              { element: 'confirmButtonLabel' },
            )}
            abortButtonLabel={intl.formatMessage(
              {
                id: 'CONTRACT_CONFIRMATION_ACTION_CONFIRM_DIALOG',
              },
              { element: 'cancelButtonLabel' },
            )}
            onPress={() => trackEvent(events.CONTRACT_CONFIRMATION_YES_CLICK)}
          >
            <Button
              onPress={handlePressConfirm}
              size="xs"
              color={CSTheme.colors.accent}
              style={styles.edgeButton}
              disabled={isInFlight}
            >
              <FormattedMessage id="CONTRACT_CONFIRMATION_ACTION_CONFIRM" />
            </Button>
          </PressableWithConfirmation>

          <PressableWithConfirmation
            dialogTitle={intl.formatMessage(
              {
                id: 'CONTRACT_CONFIRMATION_ACTION_MAYBE_DIALOG',
              },
              { element: 'title' },
            )}
            dialogContent={intl.formatMessage(
              {
                id: 'CONTRACT_CONFIRMATION_ACTION_MAYBE_DIALOG',
              },
              { element: 'message' },
            )}
            confirmButtonLabel={intl.formatMessage(
              {
                id: 'CONTRACT_CONFIRMATION_ACTION_MAYBE_DIALOG',
              },
              { element: 'confirmButtonLabel' },
            )}
            abortButtonLabel={intl.formatMessage(
              {
                id: 'CONTRACT_CONFIRMATION_ACTION_MAYBE_DIALOG',
              },
              { element: 'cancelButtonLabel' },
            )}
            onPress={() => trackEvent(events.CONTRACT_CONFIRMATION_MAYBE_CLICK)}
          >
            <Button
              testID="update-confirmation-status-btn"
              onPress={handlePressDeferConfirmation}
              size="xs"
              color={CSTheme.colors.text}
              disabled={isInFlight}
            >
              <FormattedMessage id="CONTRACT_CONFIRMATION_ACTION_MAYBE" />
            </Button>
          </PressableWithConfirmation>

          <PressableWithConfirmation
            dialogTitle={intl.formatMessage(
              {
                id: 'CONTRACT_CONFIRMATION_ACTION_REJECT_DIALOG',
              },
              { element: 'title' },
            )}
            dialogContent={intl.formatMessage(
              {
                id: 'CONTRACT_CONFIRMATION_ACTION_REJECT_DIALOG',
              },
              { element: 'message' },
            )}
            confirmButtonLabel={intl.formatMessage(
              {
                id: 'CONTRACT_CONFIRMATION_ACTION_REJECT_DIALOG',
              },
              { element: 'confirmButtonLabel' },
            )}
            abortButtonLabel={intl.formatMessage(
              {
                id: 'CONTRACT_CONFIRMATION_ACTION_REJECT_DIALOG',
              },
              { element: 'cancelButtonLabel' },
            )}
            onPress={() => trackEvent(events.CONTRACT_CONFIRMATION_NO_CLICK)}
          >
            <Button
              onPress={handlePressReject}
              size="xs"
              color={CSTheme.colors.error}
              style={styles.edgeButton}
              disabled={isInFlight}
            >
              <FormattedMessage id="CONTRACT_CONFIRMATION_ACTION_REJECT" />
            </Button>
          </PressableWithConfirmation>
        </View>
        {!!errorMsg && (
          <InputErrorMessage style={styles.errorMessage}>
            <FormattedMessage id={errorMsg} />
          </InputErrorMessage>
        )}
      </Card>
    </>
  );
}
