import { useIntl} from 'react-intl';
import * as DocumentPicker from 'expo-document-picker';
import * as Permissions from 'expo-permissions';
import * as ImagePicker from 'expo-image-picker';
import {ImagePickerAsset, ImagePickerResult} from 'expo-image-picker';
import { useActionSheet } from '@expo/react-native-action-sheet';
import { Platform } from 'react-native';
import * as ImageManipulator from 'expo-image-manipulator';
import { ImageInfo } from 'expo-image-picker/build/ImagePicker.types';
import { useCallback } from 'react';
import { FileData } from '../../../Infrastructure/Hook/useUploadFile';
import {alertForPermissions} from "./utils";
import * as FileSystem from 'expo-file-system';

interface CancelledResult {
  cancelled: true;
}

interface SuccessResult {
  file: FileData;
  cancelled: false;
}

async function compressAndResizeImageToJPEG(
  imageInfo: ImageInfo,
): Promise<ImageManipulator.ImageResult> {
  const MAX_DIMENSION_IN_PX = 4096;
  const dimensionToUpdate = imageInfo.width > imageInfo.height ? 'width' : 'height';
  const dimensionToUpdateTargetValue =
    imageInfo[dimensionToUpdate] > MAX_DIMENSION_IN_PX
      ? MAX_DIMENSION_IN_PX
      : imageInfo[dimensionToUpdate];

  return ImageManipulator.manipulateAsync(
    imageInfo.uri,
    [
      {
        resize: {
          [dimensionToUpdate]: dimensionToUpdateTargetValue,
        },
      },
    ],
    { format: ImageManipulator.SaveFormat.JPEG },
  );
}

async function imagePickerResultToCompressedBlobResult(
  imagePickerResult: Promise<ImagePickerResult>,
) {
  const result = await imagePickerResult;

  if (result.canceled) {
    return <CancelledResult>{ cancelled: true };
  }

  const { uri } = await compressAndResizeImageToJPEG(result as ImagePickerAsset);
  const [name] = uri.split('/').slice(-1);

  // Read the file content
  let fileContent = await FileSystem.readAsStringAsync(uri, {
    encoding: FileSystem.EncodingType.Base64,
  });

  fileContent = fileContent.indexOf(';base64,') > -1 ? fileContent.split(';base64,')[1]: fileContent;
  return <SuccessResult>{
    cancelled: false,
    file: {
      uri: `data:image/jpeg;base64,${fileContent}`, // Modify the URI to include base64 data
      name,
      type: 'image/jpeg', // Assuming the image is always converted to JPEG
    },
  };
}

export default function useConfirmAndSelectFile() {
  const intl = useIntl();
  const { showActionSheetWithOptions } = useActionSheet();

  const alertPermissionMustBeGranted = useCallback(
      (iosMessage: string, androidMessage: string) => alertForPermissions(intl,iosMessage,androidMessage),
      [intl]
  )

  return useCallback(async (): Promise<null | FileData | File> => {
    if (Platform.OS === 'web') {
      const pickerResult = await DocumentPicker.getDocumentAsync({
        type: 'image/*',
      });

      if (pickerResult.type === 'cancel' || !pickerResult.file) {
        return null;
      }
      const webResult = await new Promise<SuccessResult>((resolve) => {
        const reader = new FileReader();
        reader.onload = () => {
          const fileContent = reader.result as string;
          const base64Data = fileContent.split(',')[1];
          resolve({
            cancelled: false,
            file: {
              uri: `data:image/jpeg;base64,${base64Data}`,
              name: pickerResult.file.name,
              type: 'image/jpeg',
            },
          });
        };
        reader.readAsDataURL(pickerResult.file);
      });
      return webResult.file;
    }

    const result = await new Promise<CancelledResult | SuccessResult>((resolve) =>
      showActionSheetWithOptions(
        {
          title: intl.formatMessage({ id: 'SERVICE_DOCUMENTATION_UPLOAD_TITLE' }),
          options: [
            intl.formatMessage({ id: 'SERVICE_DOCUMENTATION_UPLOAD_CAMERA' }),
            intl.formatMessage({ id: 'SERVICE_DOCUMENTATION_UPLOAD_IMAGE' }),
            intl.formatMessage({ id: 'SERVICE_DOCUMENTATION_UPLOAD_CANCEL' }),
          ],
          cancelButtonIndex: 2,
        },
        (i?: number) => {
          const cb: undefined | (() => Promise<CancelledResult | SuccessResult>) = (<
            (() => Promise<CancelledResult | SuccessResult>)[]
          >[
            async () => {
              const permissionResponse = await Permissions.askAsync(
                ...(<Permissions.PermissionType[]>(
                  (Platform.OS === 'android'
                    ? [Permissions.CAMERA, Permissions.MEDIA_LIBRARY]
                    : [Permissions.CAMERA])
                )),
              );

              if (!permissionResponse.granted) {
                alertPermissionMustBeGranted(
                  'SERVICE_DOCUMENTATION_MISSING_CAMERA_PERMISSION_MESSAGE_IOS',
                  'SERVICE_DOCUMENTATION_MISSING_CAMERA_PERMISSION_MESSAGE_ANDROID',
                );

                return <CancelledResult>{ cancelled: true };
              }

              return imagePickerResultToCompressedBlobResult(ImagePicker.launchCameraAsync());
            },
            async () => {
              const majorVersion = parseInt(String(Platform.Version), 10);

              if (Platform.OS === 'ios' && majorVersion === 10) {
                const permissionResponse = await Permissions.askAsync(Permissions.MEDIA_LIBRARY);
                if (!permissionResponse.granted) {
                  alertPermissionMustBeGranted(
                    'SERVICE_DOCUMENTATION_MISSING_LIBRARY_PERMISSION_MESSAGE_IOS',
                    'SERVICE_DOCUMENTATION_MISSING_LIBRARY_PERMISSION_MESSAGE_ANDROID',
                  );

                  return <CancelledResult>{ cancelled: true };
                }
              }

              return imagePickerResultToCompressedBlobResult(ImagePicker.launchImageLibraryAsync());
            },
          ])[i === undefined ? -1 : i];
          resolve((cb && cb()) || { cancelled: true });
        },
      ),
    );

    if (result.cancelled) {
      return null;
    }

    return result.file;
  }, [alertPermissionMustBeGranted, intl, showActionSheetWithOptions]);
}
