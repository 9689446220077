import { graphql } from 'react-relay';

export default graphql`
  query fetchSingleBookingQuery($bookingId: ID!) {
    booking(bookingId: $bookingId) {
      id
      startDate
      endDate
      status
      contract {
        id
        hasFulfilledBooking
        cancelationScheduledFor
        firstBookingStatus
        firstBookingConfirmationStatus {
          reason
        }
      }
    }
  }
`;
