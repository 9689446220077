import React from 'react';
import AccountScreen from '../Screen/AccountScreen';
import { intl } from '../../../Infrastructure/Intl';
import screenOptions from '../../screenOptions';
import PdfScreen from '../Screen/PdfScreen';
import HelpCenterScreen from '../Screen/HelpCenterScreen';
import WebViewScreen from '../Screen/WebViewScreen';
import { Navigator, Screen } from '../Navigator/AccountStackNavigator';
import AvailabilityScreen from '../Screen/AvailabilityScreen';
import FinancialDetailsScreen from '../Screen/FinancialDetailsScreen';
import EditFinancialDetailsScreen from '../Screen/EditFinancialDetailsScreen';
import ProfileScreen from '../Screen/ProfileScreen';
import ServicesScreen from '../Screen/ServicesScreen';
import EducationQualificationsScreen from '../Screen/EducationQualificationsScreen';
import LanguagesScreen from '../Screen/LanguagesScreen';
import EditAboutMeScreen from '../Screen/AboutMe/EditAboutMeScreen';
import FinancialOverviewScreen from '../Screen/FinancialOverviewScreen';
import ReferralCodeScreen from '../Screen/ReferralCodeScreen';
import ExperienceAndAbilitiesScreen from '../Screen/ExperienceAndAbilitiesScreen';
import InvoiceListScreen from '../Screen/InvoiceListScreen';
import InvoiceDetailScreen from '../Screen/InvoiceDetailScreen';
import ContactAndAddressScreen from '../Screen/ContactAndAddressScreen';
import EditContactAndAddressScreen from '../Screen/EditContactAndAddressScreen';
import useProfile from '../../../Context/Profile/Hook/useProfile';

export default function AccountNavigation() {
  const { profile } = useProfile();

  return (
    <Navigator screenOptions={screenOptions} initialRouteName="Account">
      <Screen
        options={{
          title: intl.formatMessage({ id: 'ACCOUNT' }),
        }}
        name="Account"
        component={AccountScreen}
      />
      <Screen
        options={{
          title: intl.formatMessage({ id: 'PROFILE_ABOUT_ME' }),
        }}
        name="EditAboutMe"
        component={EditAboutMeScreen}
      />
      <Screen
        options={{
          title: intl.formatMessage({ id: 'PROFILE_EXPERIENCE_AND_ABILITIES' }),
        }}
        name="ExperienceAndAbilities"
        component={ExperienceAndAbilitiesScreen}
      />
      <Screen
        options={{
          title: intl.formatMessage({ id: 'PROFILE' }),
        }}
        name="Profile"
        component={ProfileScreen}
      />
      <Screen
        options={{
          title: intl.formatMessage({ id: 'MY_SERVICES' }),
        }}
        name="Services"
        component={ServicesScreen}
      />
      <Screen
        options={{
          headerLargeTitleStyle: {
            fontSize: 24,
          },
          title: intl.formatMessage({ id: 'PROFILE_EDUCATION_AND_QUALIFICATIONS_SCREEN_TITLE' }),
        }}
        name="EducationQualifications"
        component={EducationQualificationsScreen}
      />
      <Screen
        options={{
          title: intl.formatMessage({ id: 'PROFILE_LANGUAGES' }),
        }}
        name="Languages"
        component={LanguagesScreen}
      />
      <Screen
        options={{
          title: intl.formatMessage({ id: 'AVAILABILITY' }),
        }}
        name="Availability"
        component={AvailabilityScreen}
      />
      <Screen
        options={{
          title: intl.formatMessage({ id: 'CONTACT_AND_ADDRESS' }),
        }}
        name="ContactAndAddress"
        component={ContactAndAddressScreen}
      />
      <Screen
        options={{
          title: intl.formatMessage({ id: 'FINANCIAL_OVERVIEW' }),
        }}
        name="FinancialOverview"
        component={FinancialOverviewScreen}
      />
      <Screen
        options={{
          title: intl.formatMessage({ id: 'REFERRAL_CODE_TITLE' }),
        }}
        name="ReferralCode"
        component={ReferralCodeScreen}
      />
      <Screen
        options={{
          title: intl.formatMessage({ id: 'BANK_TAX_DETAILS' }),
        }}
        name="FinancialDetails"
        component={FinancialDetailsScreen}
      />
      <Screen
        options={{
          title: intl.formatMessage({ id: 'BANK_TAX_DETAILS' }),
        }}
        name="EditFinancialDetails"
        component={EditFinancialDetailsScreen}
      />
      <Screen
        options={{
          title: intl.formatMessage({ id: 'CONTACT_AND_ADDRESS' }),
        }}
        name="EditContactAndAddress"
        component={EditContactAndAddressScreen}
      />
      <Screen
        options={{
          title: intl.formatMessage({ id: 'HELP_CENTER' }),
        }}
        name="HelpCenter"
        component={HelpCenterScreen}
      />
      <Screen
        options={{
          headerLargeTitle: false,
          title: intl.formatMessage({ id: 'HELP_CENTER' }),
        }}
        name="WebView"
        component={WebViewScreen}
      />
      <Screen
        options={{
          headerLargeTitle: false,
          title: intl.formatMessage({ id: 'HELP_CENTER' }),
        }}
        name="PdfView"
        component={PdfScreen}
      />
      {!profile?.isCareshipEmployee && (
        <Screen
          options={{
            title: intl.formatMessage({ id: 'INVOICE_LIST' }),
          }}
          name="InvoiceList"
          component={InvoiceListScreen}
        />
      )}
      {!profile?.isCareshipEmployee && (
        <Screen
          options={{
            headerLargeTitle: false,
          }}
          name="InvoiceDetail"
          component={InvoiceDetailScreen}
        />
      )}
    </Navigator>
  );
}
