import React, { ReactNode } from 'react';
import { StyleSheet, View } from 'react-native';
import color from 'color';
import { CareshipColors } from '../CSTheme';
import HintText from './HintText';

type HintProps = {
  hint?: ReactNode;
  warning?: boolean;
  children?: ReactNode;
};

const styles = StyleSheet.create({
  hint: {
    borderBottomColor: CareshipColors.slate100,
    borderBottomWidth: 1,
    backgroundColor: CareshipColors.slate050,
    paddingVertical: 8,
    paddingHorizontal: 8,
    flexDirection: 'row',
    alignItems: 'center',
  },
  warning: {
    borderBottomColor: CareshipColors.red,
    backgroundColor: color(CareshipColors.red).alpha(0.1).rgb().string(),
  },
});

export default function HintRow({ hint, children, warning }: HintProps) {
  return (
    <View style={[styles.hint, warning ? styles.warning : undefined]}>
      {children}
      {hint && <HintText>{hint}</HintText>}
    </View>
  );
}
