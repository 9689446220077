import { StyleSheet } from 'react-native';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import useProfile from '../../../../Context/Profile/Hook/useProfile';
import { ClipboardListIcon } from '../../../Component/Icon';
import { NavFlatButton } from '../../../Component';

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 16,
    marginTop: 32,
  },
});

interface CallChecklistButtonProps {
  onPress: () => void;
}

export default function CallChecklistButton({ onPress }: CallChecklistButtonProps) {
  const { profile } = useProfile();

  if(profile?.isCareshipEmployee) {
      return <></>
  }

  return (
    <>
      <NavFlatButton
        icon={<ClipboardListIcon size={22} />}
        onPress={onPress}
        style={styles.container}
      >
        <FormattedMessage id="FIRST_CALL_CHECKLIST" />
      </NavFlatButton>
    </>
  );
}
