import { Field, FieldProps, Formik, FormikHelpers } from 'formik';
import { ScrollView, View, StyleSheet, Platform } from 'react-native';
import { TextInput } from 'react-native-paper';
import { FormattedMessage, useIntl } from 'react-intl';
import React, { useCallback } from 'react';
import { Feather } from '@expo/vector-icons';
import { useFragment } from 'react-relay/hooks';
import { graphql } from 'react-relay';
import {
  Button,
  Container,
  InputErrorMessage,
  RadioButtonGroup,
  RadioButtonItem,
  Text,
  Title,
} from '../../Component';
import { useConfirmationStatusUpdateReasons } from '../../../Context/Contract/Hook';
import { events, trackEvent } from '../../../Infrastructure/Tracking/Tracking';
import FormGroupTitle from '../../Component/Form/FormGroupTitle';
import Label from '../../Component/Form/Label';
import { CareshipColors } from '../../Component/CSTheme';
import { FirstBookingUpdateConfirmationStatusForm_contract$key } from './__generated__/FirstBookingUpdateConfirmationStatusForm_contract.graphql';
import ErrorFullscreen from '../../Component/ErrorFullscreen';

const styles = StyleSheet.create({
  submitButton: {
    marginTop: 16,
  },
  lastReasonIcon: {
    marginLeft: -16,
    marginRight: 12, // align to radio button labels
    marginTop: 4,
    alignSelf: 'flex-start',
  },
  lastReasonRow: {
    minHeight: 64,
    marginHorizontal: 16,
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 16,
    marginBottom: 32,
  },
});

interface FirstBookingUpdateConfirmationStatusFormProps {
  onSubmit: (payload: FormData) => Promise<string | void>;
  title: string;
  contractFragment: FirstBookingUpdateConfirmationStatusForm_contract$key;
}

interface FormData {
  bookingId: string;
  reason: string;
  description: string;
}

export default function FirstBookingUpdateConfirmationStatusForm({
  onSubmit,
  title,
  contractFragment,
}: FirstBookingUpdateConfirmationStatusFormProps) {
  const intl = useIntl();
  const { firstBooking, firstBookingConfirmationStatus } = useFragment(
    graphql`
      fragment FirstBookingUpdateConfirmationStatusForm_contract on Contract {
        firstBooking(isFirstBooking: true) {
          bookingId
        }
        firstBookingConfirmationStatus {
          reason
        }
      }
    `,
    contractFragment,
  );
  const reasons = useConfirmationStatusUpdateReasons(firstBookingConfirmationStatus?.reason);

  const validate = useCallback(
    (data: FormData) => {
      const errors: { [key in keyof FormData]?: string } = {};

      if (!data.reason) {
        errors.reason = intl.formatMessage({
          id: 'FIRST_APPOINTMENT_UPDATE_STATUS_MISSING_REASON',
        });
      }

      if (data.reason === 'other' && !data.description.trim()) {
        errors.description = intl.formatMessage({
          id: 'FIRST_APPOINTMENT_UPDATE_STATUS_MISSING_DESCRIPTION',
        });
      }

      return errors;
    },
    [intl],
  );

  const handleFormSubmit = async (data: FormData, helpers: FormikHelpers<FormData>) => {
    const errorCode = await onSubmit(data);

    if (errorCode) {
      helpers.setStatus({
        error: intl.formatMessage({ id: 'ERROR_RESPONSE' }, { errorCode }),
      });
    }

    trackEvent(events.FIRST_BOOKING_UPDATE_STATUS_SUBMIT_CLICK);
  };

  if (!firstBooking) {
    return <ErrorFullscreen />;
  }

  return (
    <ScrollView>
      <Container>
        <Title center thin style={{ marginTop: Platform.OS === 'android' ? 32 : undefined }}>
          {title}
        </Title>
      </Container>
      <Container vertical>
        <Text bold size="xlarge" style={{ marginVertical: 16 }}>
          <FormattedMessage id="FIRST_APPOINTMENT_UPDATE_STATUS_MODAL_REASON" />
        </Text>
      </Container>
      <Formik
        validate={validate}
        initialValues={{ reason: '', description: '', bookingId: firstBooking.bookingId }}
        onSubmit={handleFormSubmit}
      >
        {({ setFieldValue, status, handleSubmit, dirty, isSubmitting, isValid }) => (
          <View>
            {firstBookingConfirmationStatus?.reason === 'not_reachable' && (
              <>
                <Container>
                  <FormGroupTitle>
                    <FormattedMessage id="FIRST_APPOINTMENT_UPDATE_STATUS_CURRENT_REASON" />
                  </FormGroupTitle>
                  <View style={styles.lastReasonRow}>
                    <Feather
                      color={CareshipColors.slate400}
                      size={16}
                      name="arrow-right"
                      style={styles.lastReasonIcon}
                    />
                    <Label active>
                      <FormattedMessage
                        id="FIRST_APPOINTMENT_UPDATE_STATUS_REASON"
                        values={{
                          reason: `reason_${firstBookingConfirmationStatus.reason}`,
                        }}
                      />
                    </Label>
                  </View>
                  <FormGroupTitle>
                    <FormattedMessage id="FIRST_APPOINTMENT_UPDATE_STATUS_NEW_REASON" />
                  </FormGroupTitle>
                </Container>
              </>
            )}
            <Field name="reason">
              {({ field }: FieldProps<FormData['reason']>) => (
                <RadioButtonGroup
                  value={field.value}
                  onValueChange={(firstBookingDeclineReason) =>
                    setFieldValue('reason', firstBookingDeclineReason)
                  }
                >
                  {reasons.map((reason) => (
                    <RadioButtonItem
                      key={reason}
                      label={
                        <FormattedMessage
                          id="FIRST_APPOINTMENT_UPDATE_STATUS_REASON"
                          values={{
                            reason: `reason_${reason}`,
                          }}
                        />
                      }
                      value={reason}
                    />
                  ))}
                </RadioButtonGroup>
              )}
            </Field>
            <Field name="description">
              {({ field }: FieldProps<FormData['description']>) => (
                <TextInput
                  accessibilityLabel="Text input field"
                  accessibilityHint={intl.formatMessage({
                    id: 'FIRST_APPOINTMENT_UPDATE_STATUS_MODAL_DESCRIPTION',
                  })}
                  textAlign="left"
                  multiline
                  style={{
                    marginTop: -1,
                    paddingHorizontal: 16,
                    paddingBottom: 8,
                    backgroundColor: 'rgba(255,179,0,0.1)',
                  }}
                  underlineColor="rgba(255,179,0,0.15)"
                  label={intl.formatMessage({
                    id: 'FIRST_APPOINTMENT_UPDATE_STATUS_MODAL_DESCRIPTION',
                  })}
                  theme={{ roundness: 0 }}
                  maxLength={100}
                  onChangeText={(description) => setFieldValue('description', description)}
                  value={field.value}
                />
              )}
            </Field>

            <Container vertical>
              {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access */}
              {Boolean(status?.error) && <InputErrorMessage>{status?.error}</InputErrorMessage>}

              <Button
                loading={isSubmitting}
                disabled={isSubmitting || !dirty || !isValid}
                onPress={handleSubmit}
                style={styles.submitButton}
                icon={<Feather color="white" size={32} name="check" />}
              >
                <FormattedMessage id="FIRST_APPOINTMENT_UPDATE_STATUS_FORM_SUBMIT" />
              </Button>
            </Container>
          </View>
        )}
      </Formik>
    </ScrollView>
  );
}
