import styled from '@emotion/native';
import React from 'react';
import { Platform, View } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { graphql } from 'react-relay';
import { PreloadedQuery, usePreloadedQuery } from 'react-relay/hooks';
import { WithoutTheme } from '../../Root/Theme/WithoutTheme';
import { Card, ListItemAction, Text } from '../../Component';
import { InvoiceListQuery } from './__generated__/InvoiceListQuery.graphql';
import { nonNullEdges } from '../../../Infrastructure/Relay';
import YearHasAnonymizedInvoicesCard from './YearHasAnonymizedInvoicesCard';
import useFormattedInvoices from './useFormattedInvoices';
import InvoiceGroup from './InvoiceGroup';

const Year = styled(View)(({ theme: { breakpoints } }) => ({
  marginTop: 24,
  ...breakpoints.up('sm', {
    marginTop: 32,
  }),
}));

const YearName = styled(Text)({
  padding: 16,
});

const Month = styled(View)(({ theme: { breakpoints } }) => ({
  marginBottom: 24,
  ...breakpoints.up('sm', {
    marginBottom: 32,
  }),
}));

const MonthName = styled<WithoutTheme<typeof Text>>(Text)(({ theme: { colors } }) => ({
  backgroundColor: colors.slate050,
  padding: 16,
}));

const CustomerName = styled(Text)({
  padding: 16,
});

export const invoiceListQuery = graphql`
  query InvoiceListQuery {
    allInvoices {
      edges {
        node {
          contractId
          caregiverId
          customerId
          invoice {
            invoiceId
            creationDate
            invoicingPeriodTo
            invoiceNumber
            invoiceType
            originalInvoiceId
          }
          careReceiver {
            isAnonymized
            name {
              firstName
              lastName
            }
          }
        }
      }
    }
  }
`;

interface InvoiceListProps {
  preloadedQuery: PreloadedQuery<InvoiceListQuery>;
  onPressInvoice: ({
    invoiceId,
    filename,
    title,
  }: {
    invoiceId: string;
    filename: string;
    title: string;
  }) => Promise<void>;
}

export default function InvoiceList({ preloadedQuery, onPressInvoice }: InvoiceListProps) {
  const { allInvoices } = usePreloadedQuery(invoiceListQuery, preloadedQuery);

  const invoiceList = allInvoices?.edges?.filter(nonNullEdges);

  const groupedInvoices = useFormattedInvoices(invoiceList!);

  if (!groupedInvoices?.length) {
    const now = new Date();

    return (
      <View>
        <Year>
          <YearName size="large" bold muted uppercase>
            <FormattedDate year="numeric" value={now} />
          </YearName>
          <Month>
            <MonthName size="large" bold muted uppercase>
              <FormattedDate month="long" value={now} />
            </MonthName>
            <ListItemAction>
              <FormattedMessage id="INVOICE_LIST_EMPTY" />
            </ListItemAction>
          </Month>
        </Year>
      </View>
    );
  }

  return (
    <ScrollView>
      <Card marginHorizontal={Platform.select({ web: 'base' })}>
        {groupedInvoices.map(({ year, items: byMonth, hasAnonymizedInvoices }) => (
          <Year key={year}>
            <YearName size="large" bold muted uppercase>
              {year}
            </YearName>
            {hasAnonymizedInvoices && <YearHasAnonymizedInvoicesCard year={year.toString()} />}
            {byMonth.map(({ month, items: byCustomer }) => (
              <Month key={`${year};${month}`}>
                <MonthName size="large" bold muted uppercase>
                  <FormattedDate value={new Date(year, month - 1)} month="long" />
                </MonthName>
                {byCustomer.map(({ invoices, lastName, firstName, customerId }) => (
                  <View key={`${year};${month};${customerId}`}>
                    <CustomerName size="large" bold uppercase>
                      <FormattedMessage
                        id="NAME_FULL"
                        values={{
                          lastName,
                          firstName,
                        }}
                      />
                    </CustomerName>
                    {invoices.map(({ storno, node }) => (
                      <InvoiceGroup
                        key={node.invoice.invoiceId}
                        invoice={{ storno, node }}
                        onPressInvoice={onPressInvoice}
                      />
                    ))}
                  </View>
                ))}
              </Month>
            ))}
          </Year>
        ))}
      </Card>
    </ScrollView>
  );
}
