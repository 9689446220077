import React, { FC } from 'react';
import { useLocalObservable } from 'mobx-react-lite';
import stores from './stores';
import storeContext from './storeContext';

const StoreProvider: FC<{}> = ({ children }) => {
  const store = useLocalObservable(() => stores);

  return <storeContext.Provider value={store}>{children}</storeContext.Provider>;
};

export default StoreProvider;
