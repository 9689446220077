import RelayModernEnvironment from 'relay-runtime/lib/store/RelayModernEnvironment';
import { commitLocalUpdate, graphql } from 'react-relay';
import { useLazyLoadQuery, useRelayEnvironment } from 'react-relay/hooks';
import { useCallback, useEffect } from 'react';
import { getFeedbackSurveyData, setFeedbackSurveyData, StoreData } from './feedbackFormStorage';
import { useUpdateBookingFeedbackQuery } from './__generated__/useUpdateBookingFeedbackQuery.graphql';

// Create a unique ID.
function createBookingFeedbackId(contractId: string) {
  return `client:BookingFeedback:${contractId}`;
}

async function setInitialBookingFeedbackData(
  environment: RelayModernEnvironment,
  contractId: string,
) {
  const initialData = await getFeedbackSurveyData(contractId);

  commitLocalUpdate(environment, (store) => {
    const contract = store.get<{ bookingFeedback: StoreData }>(contractId);

    const dataID = createBookingFeedbackId(contractId);

    if (store.get<StoreData>(dataID)) {
      return;
    }

    // Create a new bookingFeedback record.
    const newBookingFeedback = store.create(dataID, 'BookingFeedback');

    // populate with default values
    Object.keys(initialData).forEach((k) =>
      newBookingFeedback.setValue(initialData[k as keyof StoreData], k),
    );

    // Add the bookingFeedback record to the contract
    contract?.setLinkedRecord(newBookingFeedback, 'bookingFeedback');
  });
}

export default function useUpdateBookingFeedback(bookingId: string): {
  updateBookingFeedback: (bookingFeedbackData: Partial<StoreData>) => void;
  bookingFeedback: Readonly<StoreData> | null;
} {
  const environment = useRelayEnvironment();
  const { booking } = useLazyLoadQuery<useUpdateBookingFeedbackQuery>(
    graphql`
      query useUpdateBookingFeedbackQuery($bookingId: ID!) {
        booking(bookingId: $bookingId) {
          contract {
            id
            bookingFeedback {
              bookingCount
              disabled
              frequency
            }
          }
        }
      }
    `,
    {
      bookingId,
    },
  );

  // Init local state
  useEffect(() => {
    if (!booking?.contract.id) {
      return;
    }

    void setInitialBookingFeedbackData(environment, booking.contract.id);
  }, [booking?.contract.id, environment]);

  const updateBookingFeedback = useCallback(
    (bookingFeedbackData: Partial<StoreData>) => {
      commitLocalUpdate(environment, (store) => {
        if (!booking?.contract.id) {
          return;
        }

        const dataID = createBookingFeedbackId(booking?.contract.id);

        const bookingFeedback = store.get<StoreData>(dataID);

        if (!bookingFeedback) {
          throw new Error(`bookingFeedback data not found for contract ${booking.contract.id}`);
        }

        Object.keys(bookingFeedbackData).forEach((k) =>
          bookingFeedback.setValue(bookingFeedbackData[k as keyof StoreData], k),
        );

        // Also save to persistent storage
        void setFeedbackSurveyData(booking.contract.id, {
          bookingCount: bookingFeedback.getValue('bookingCount'),
          frequency: bookingFeedback.getValue('frequency'),
          disabled: bookingFeedback.getValue('disabled'),
        });
      });
    },
    [booking?.contract.id, environment],
  );

  return {
    updateBookingFeedback,
    bookingFeedback: booking?.contract.bookingFeedback || null,
  };
}
