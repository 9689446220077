/**
 * @generated SignedSource<<75d99c6c085923e0830109db0f68fee0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type AddBookingFormQuery$variables = {};
export type AddBookingFormQuery$data = {
  readonly allContracts: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly contractId: string;
        readonly isActive: boolean;
        readonly cancelationScheduledFor: string | null;
        readonly careReceiver: {
          readonly name: {
            readonly firstName: string;
            readonly lastName: string;
          };
        };
      };
    } | null> | null;
  } | null;
};
export type AddBookingFormQuery = {
  variables: AddBookingFormQuery$variables;
  response: AddBookingFormQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ContractConnection",
    "kind": "LinkedField",
    "name": "allContracts",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ContractEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Contract",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "contractId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isActive",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cancelationScheduledFor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "CareReceiver",
                "kind": "LinkedField",
                "name": "careReceiver",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Name",
                    "kind": "LinkedField",
                    "name": "name",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "firstName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "lastName",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AddBookingFormQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AddBookingFormQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "865909747edd994dbb1e8be384b2ea5b",
    "id": null,
    "metadata": {},
    "name": "AddBookingFormQuery",
    "operationKind": "query",
    "text": "query AddBookingFormQuery {\n  allContracts {\n    edges {\n      node {\n        id\n        contractId\n        isActive\n        cancelationScheduledFor\n        careReceiver {\n          name {\n            firstName\n            lastName\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a4b41b0b79f56b7a138adc6714d86cc2";

export default node;
