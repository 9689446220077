import { pipe } from 'fp-ts/function';
import { array } from 'fp-ts';
import format from 'date-fns/format';
import { useMemo } from 'react';
import { InvoiceType } from './__generated__/InvoiceListQuery.graphql';

export interface Invoice {
  node: {
    invoice: {
      creationDate: string;
      invoicingPeriodTo: string;
      invoiceId: string;
      invoiceNumber: string;
      invoiceType: InvoiceType;
      originalInvoiceId: string | null;
    };
    contractId: string;
    caregiverId: string;
    customerId: string;
    careReceiver: {
      isAnonymized: boolean;
      name: {
        firstName: string;
        lastName: string;
      };
    };
  };
}

export interface InvoiceWithStorno extends Invoice {
  storno: null | Invoice;
}

/**
 * @param getKey - return is used as key for the object
 * @param formatGroup - formats each group
 */
const groupInvoices = <T, S>(
  getKey: (invoice: T) => string,
  formatGroup: (invoices: T[]) => S,
): ((invoices: T[]) => S[]) => {
  return (invoices: T[] = []) => {
    const grouped = invoices.reduce((acc, curr) => {
      const key = getKey(curr);

      if (!acc[key]) {
        acc[key] = [];
      }

      acc[key].push(curr);

      return acc;
    }, {} as Record<string, T[]>);

    return pipe(grouped, Object.values, array.map(formatGroup));
  };
};

const groupStorno = (invoices: Invoice[]): InvoiceWithStorno[] => {
  const grouped = invoices.reduce((acc, curr) => {
    const isStorno = curr.node.invoice.invoiceType.includes('STORNO');
    const invoiceId = isStorno ? curr.node.invoice.originalInvoiceId! : curr.node.invoice.invoiceId;

    if (!acc[invoiceId]) {
      acc[invoiceId] = {
        storno: isStorno ? curr : null,
        ...(isStorno ? {} : curr),
      } as InvoiceWithStorno;
    }

    if (isStorno) {
      acc[invoiceId].storno = curr;
    } else {
      acc[invoiceId] = {
        ...acc[invoiceId],
        ...curr,
      };
    }

    return acc;
  }, {} as Record<string, InvoiceWithStorno>);

  return Object.values(grouped);
};

const byMonth: <T extends InvoiceWithStorno>(invoices: T[]) => { month: number; items: T[] }[] =
  groupInvoices(
    (invoice) => format(new Date(invoice.node.invoice.invoicingPeriodTo), 'M'),
    (items) => ({
      month: parseInt(format(new Date(items[0].node.invoice.invoicingPeriodTo), 'M'), 10),
      items,
    }),
  );

const byYear: <T extends InvoiceWithStorno>(invoices: T[]) => { year: number; items: T[] }[] =
  groupInvoices(
    (invoice) => format(new Date(invoice.node.invoice.invoicingPeriodTo), 'yyyy'),
    (items) => ({
      year: parseInt(format(new Date(items[0].node.invoice.invoicingPeriodTo), 'yyyy'), 10),
      items,
    }),
  );

const byCustomerId = groupInvoices(
  (invoice: InvoiceWithStorno) => invoice.node.customerId,
  (items) => ({
    ...items[0].node.careReceiver.name,
    customerId: items[0].node.customerId,
    invoices: items.sort(
      (a, b) =>
        new Date(b.node.invoice.creationDate).valueOf() -
        new Date(a.node.invoice.creationDate).valueOf(),
    ),
  }),
);

function groupByCustomer(month: { month: number; items: InvoiceWithStorno[] }) {
  return byCustomerId(month.items).sort((a, b) => a.lastName.localeCompare(b.lastName));
}

function formatByMonth(items: InvoiceWithStorno[]) {
  return byMonth(items)
    .sort((a, b) => b.month - a.month)
    .map((month) => ({
      ...month,
      items: groupByCustomer(month),
    }));
}

function groupByAnonymized(invoices: Invoice[]): {
  validInvoices: Invoice[];
  anonymizedInvoices: Invoice[];
} {
  const [validInvoices, anonymizedInvoices] = invoices.reduce(
    (prev, invoice) => {
      if (invoice.node.careReceiver.isAnonymized) {
        return [[...prev[0]], [...prev[1], invoice]];
      }

      return [[...prev[0], invoice], prev[1]];
    },
    [[], []] as unknown as [Invoice[], Invoice[]],
  );

  return {
    validInvoices,
    anonymizedInvoices,
  };
}

function hasAnonymizedInvoices(year: number, anonymizedInvoices: Invoice[]) {
  return anonymizedInvoices.some(
    (invoice) =>
      parseInt(format(new Date(invoice.node.invoice.invoicingPeriodTo), 'yyyy'), 10) === year,
  );
}

interface InvoiceByYearMonthCareReceiver {
  year: number;
  hasAnonymizedInvoices: boolean;
  items: {
    month: number;
    items: {
      firstName: string;
      lastName: string;
      customerId: string;
      invoices: InvoiceWithStorno[];
    }[];
  }[];
}

function formatInvoiceData(invoices: Invoice[]): InvoiceByYearMonthCareReceiver[] {
  const { validInvoices, anonymizedInvoices } = groupByAnonymized(invoices);

  const combinedWithStorno = groupStorno(validInvoices);

  const years = byYear(combinedWithStorno).sort((a, b) => b.year - a.year);

  return years.map(({ year, items }) => ({
    year,
    hasAnonymizedInvoices: hasAnonymizedInvoices(year, anonymizedInvoices),
    items: formatByMonth(items),
  }));
}

export default function useFormattedInvoices(
  invoices: Invoice[],
): InvoiceByYearMonthCareReceiver[] {
  return useMemo(() => formatInvoiceData(invoices), [invoices]);
}
