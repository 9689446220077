import { Feather } from '@expo/vector-icons';
import React, { ComponentProps, PropsWithChildren, ReactElement, ReactNode } from 'react';
import { Pressable, StyleProp, StyleSheet, View, ViewStyle } from 'react-native';
import Text from './Text';
import Title from './Title';
import CSTheme from './CSTheme';
import Row from './Row';

const styles = StyleSheet.create({
  row: {
    paddingLeft: 4,
    flexWrap: 'wrap',
  },
  secondRow: { flexBasis: '100%' },
  icon: {
    marginRight: 8,
  },
  inner: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    flex: 1,
    flexBasis: '100%',
  },
  main: {
    overflow: 'hidden',
    marginRight: 16,
  },
  actionLabel: {
    marginRight: 4,
    textAlign: 'right',
  },
  action: {
    flexShrink: 1,
    marginLeft: 'auto',
    flexWrap: 'nowrap',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
});

interface ListItemProps {
  multiline?: boolean;
  icon?: ComponentProps<typeof Feather>['name'] | ReactElement;
  children: ReactNode;
  actionLabel?: ReactNode;
  onPress?: () => void;
  style?: StyleProp<ViewStyle>;
  titleProps?: Omit<
    ComponentProps<typeof Title>,
    'style' | 'size' | 'ellipseMode' | 'numberOfLines' | 'children'
  >;
  labelProps?: Omit<ComponentProps<typeof Text>, 'style' | 'size' | 'children'>;
}

export default function ListItem({
  icon,
  children,
  actionLabel,
  style,
  titleProps,
  onPress,
  labelProps,
  multiline,
}: PropsWithChildren<ListItemProps>) {
  const iconWithStyles = (el?: ReactElement) => {
    return el ? React.cloneElement(el, { style: styles.icon, size: 24 }) : null;
  };

  const [firstChild, ...restChildren] = Array.prototype
    .concat(children)
    .filter((e) => e) as typeof children[];

  const ListItemDataContainer = ({
    children: listItemDataContainerChildren,
  }: {
    children: ReactNode;
  }) => {
    if (onPress) {
      return (
        <Pressable accessibilityRole="button" onPress={onPress}>
          {listItemDataContainerChildren}
        </Pressable>
      );
    }

    return <View>{listItemDataContainerChildren}</View>;
  };

  return (
    <ListItemDataContainer>
      <Row style={[styles.row, style]}>
        <View style={styles.inner}>
          <>
            {typeof icon === 'string' ? (
              <Feather name={icon} color={CSTheme.colors.text} size={24} style={styles.icon} />
            ) : (
              iconWithStyles(icon)
            )}
            <View style={styles.main}>
              <Title
                ellipsizeMode="tail"
                numberOfLines={multiline ? undefined : 1}
                size="h4"
                thin
                {...titleProps}
              >
                {firstChild}
              </Title>
            </View>
            <View style={styles.action}>
              <Title
                ellipsizeMode="tail"
                muted
                size="h4"
                thin
                style={styles.actionLabel}
                numberOfLines={4}
                {...labelProps}
              >
                {actionLabel}
              </Title>
            </View>
          </>
        </View>
        {Boolean(restChildren.length) && <View style={styles.secondRow}>{restChildren}</View>}
      </Row>
    </ListItemDataContainer>
  );
}
