/**
 * @generated SignedSource<<d643ef101adc5b44e5c496d7c557774d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type CaregiverEducationAndQualificationEnum = "NO_FORMAL_NURSING_TRAINING" | "CAREGIVER_ACCORDING_TO_45B" | "HEALTH_CARE_WORKER_AND_NURSING_TRAINING" | "OTHER_MEDICAL_NURSING_TRAINING" | "PEDAGOGY_TRAINING" | "FIRST_AID" | "%future added value";
export type Locale = "EN" | "DE" | "%future added value";
export type EducationQualificationsQuery$variables = {
  locale?: Locale | null;
};
export type EducationQualificationsQuery$data = {
  readonly me: {
    readonly id?: string;
    readonly educationAndQualifications?: ReadonlyArray<{
      readonly key: CaregiverEducationAndQualificationEnum;
    }>;
  };
  readonly definitions: {
    readonly educationAndQualifications: ReadonlyArray<{
      readonly key: CaregiverEducationAndQualificationEnum;
      readonly label: string;
    }>;
  };
};
export type EducationQualificationsQuery = {
  variables: EducationQualificationsQuery$variables;
  response: EducationQualificationsQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "locale"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "key",
  "storageKey": null
},
v3 = {
  "kind": "InlineFragment",
  "selections": [
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "CaregiverEducationAndQualification",
      "kind": "LinkedField",
      "name": "educationAndQualifications",
      "plural": true,
      "selections": [
        (v2/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Caregiver",
  "abstractKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "Definitions",
  "kind": "LinkedField",
  "name": "definitions",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "EducationAndQualificationDefinition",
      "kind": "LinkedField",
      "name": "educationAndQualifications",
      "plural": true,
      "selections": [
        (v2/*: any*/),
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "locale",
              "variableName": "locale"
            }
          ],
          "kind": "ScalarField",
          "name": "label",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EducationQualificationsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v3/*: any*/)
        ],
        "storageKey": null
      },
      (v4/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EducationQualificationsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v1/*: any*/)
            ],
            "type": "Node",
            "abstractKey": "__isNode"
          }
        ],
        "storageKey": null
      },
      (v4/*: any*/)
    ]
  },
  "params": {
    "cacheID": "fb8859af96e62020ea1865e2ee0921fc",
    "id": null,
    "metadata": {},
    "name": "EducationQualificationsQuery",
    "operationKind": "query",
    "text": "query EducationQualificationsQuery(\n  $locale: Locale\n) {\n  me {\n    __typename\n    ... on Caregiver {\n      id\n      educationAndQualifications {\n        key\n      }\n    }\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n  definitions {\n    educationAndQualifications {\n      key\n      label(locale: $locale)\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "1e37e3584b4a66558d7da64c885b709e";

export default node;
