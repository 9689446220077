import { Platform } from 'react-native';
import { Breadcrumb, Severity } from '@sentry/types';
import Manifest from '../Constants/Manifest';
import Sentry from '../ErrorTracking/Sentry';
import sanitize from '../Logger/sanitize';

type LogLevel = {
  level: number;
  name: string;
  color: string;
};

export class Logger {
  private ERROR: LogLevel = {
    level: 1,
    name: 'ERROR',
    color: 'red',
  };

  private WARN: LogLevel = {
    level: 2,
    name: 'WARNING',
    color: 'orange',
  };

  private INFO: LogLevel = {
    level: 3,
    name: 'INFO',
    color: 'lightblue',
  };

  private DEBUG: LogLevel = {
    level: 4,
    name: 'DEBUG',
    color: 'grey',
  };

  private TRACE: LogLevel = {
    level: 5,
    name: 'TRACE',
    color: 'grey',
  };

  // eslint-disable-next-line class-methods-use-this
  public addBreadcrumb(breadcrumb: Breadcrumb, { __dangerouslySkipSanitize = false } = {}) {
    Sentry.addBreadcrumb(
      __dangerouslySkipSanitize ? breadcrumb : { ...breadcrumb, data: sanitize(breadcrumb.data) },
    );
  }

  public error(message: string, data?: any, { __dangerouslySkipSanitize = false } = {}) {
    Logger.log(this.ERROR, message, data, { __dangerouslySkipSanitize });

    if (__DEV__) {
      return;
    }

    this.addBreadcrumb({
      category: 'logger',
      message,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      data,
      level: Severity.Error,
    });
    Sentry.captureMessage(message, Severity.Error);
  }

  public warn(message: string, data?: any) {
    Logger.log(this.WARN, message, data);

    if (__DEV__) {
      return;
    }

    this.addBreadcrumb({
      category: 'logger',
      message,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      data,
      level: Severity.Warning,
    });
  }

  public info(message: string, data?: any) {
    Logger.log(this.INFO, message, data);
  }

  public debug(message: string, data?: any) {
    Logger.log(this.DEBUG, message, data);
  }

  public trace(message: string, data?: any) {
    Logger.log(this.TRACE, message, data);
  }

  private static log(
    logLevel: LogLevel,
    message: string,
    data?: any,
    { __dangerouslySkipSanitize = false } = {},
  ) {
    if (logLevel.level > Manifest.extra.logLevel) {
      return;
    }

    if (Platform.OS === 'web') {
      // eslint-disable-next-line no-console
      console.log(
        `%c[${logLevel.name}]`,
        `color: ${logLevel.color}`,
        message,
        data ? JSON.stringify(__DEV__ || __dangerouslySkipSanitize ? data : sanitize(data)) : '',
      );

      return;
    }

    // eslint-disable-next-line no-console
    console.log(
      `[${logLevel.name}] ${message}`,
      data !== undefined
        ? JSON.stringify(__DEV__ || __dangerouslySkipSanitize ? data : sanitize(data))
        : '',
    );
  }
}

export default new Logger();
