/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable consistent-return */
import React, { Fragment, useCallback, useEffect } from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { Dimensions, StyleSheet, View } from 'react-native';
import { graphql } from 'react-relay';
import { PreloadedQuery, usePreloadedQuery } from 'react-relay/hooks';
import {
  AddressRouteRow,
  Alert,
  Button,
  PressableWithConfirmation,
  Container,
  DividingHeader,
  Hr,
  ListItemData,
  Text,
  Title,
  Card,
} from '../../Component';
import MapWithMarker from '../../Booking/Component/MapWithMarker';
import { openMap } from '../../../Infrastructure/LinkHandler';
import { events, trackEvent } from '../../../Infrastructure/Tracking/Tracking';
import ScheduleDetails from './ScheduleDetails';
import {
  AwardIcon,
  CalendarRepetitionIcon,
  CheckIcon,
  ClockIcon,
  EuroIcon,
  RepetitionIcon,
  XIcon,
} from '../../Component/Icon';
import { CareshipColors } from '../../Component/CSTheme';
import { intl, toDurationString } from '../../../Infrastructure/Intl';
import { useAcceptProposal } from '../Hook';
import { ProposalDetailQuery } from './__generated__/ProposalDetailQuery.graphql';
import ExpiredProposal from './ExpiredProposal';
import useProfile from '../../../Context/Profile/Hook/useProfile';

const styles = StyleSheet.create({
  title: {
    marginTop: 32,
    marginBottom: 8,
  },
  alert: {
    marginTop: 16,
    marginBottom: 8,
  },
  mapStyle: {
    width: Dimensions.get('window').width - 32,
    height: 190,
    marginTop: 4,
  },
  section: {
    marginTop: 24,
  },
  careDegreeSection: {
    marginTop: 24,
  },
  additionalServices: {
    marginTop: 16,
  },
  actionButtonRow: {
    marginVertical: 24,
    flexDirection: 'row',
  },
  actionButton: {
    flexBasis: '50%',
    flexShrink: 1,
    flexGrow: 1,
    marginRight: 16,
  },
  actionButtonRight: {
    marginRight: 0,
  },
});

type ProposalDetailProps = {
  preloadedQuery: PreloadedQuery<ProposalDetailQuery>;
  serviceRequestId: string;
  onDecline: () => void;
  onAccept: () => void;
};

export const proposalDetailQuery = graphql`
  query ProposalDetailQuery($serviceRequestId: ID!) {
    proposal(serviceRequestId: $serviceRequestId) {
      description
      status
      isNewCustomer
      careReceiver {
        gender
        name
        age
        careDegree
        address {
          street
          city
          postalCode
        }
      }
      schedule {
        expectedAppointmentSchedule {
          isFlexible
          preferences
        }
        bookingType
        frequency
        durationInMinutes
        date
        isFlexible
        isAllDay
      }
      pricing {
        grossPricePerHourInCents
      }
      subServices {
        id
        label
      }
    }
  }
`;

export default function ProposalDetail({
  preloadedQuery,
  serviceRequestId,
  onDecline,
  onAccept,
}: ProposalDetailProps) {
  const { proposal } = usePreloadedQuery<ProposalDetailQuery>(proposalDetailQuery, preloadedQuery);
  const { profile } = useProfile();

  const { isAccepting, handleAccept } = useAcceptProposal(onAccept);

  // For employed care givers we automatically approve the service request for them
  useEffect(() => {
    if (profile?.isCareshipEmployee) {
      const timer = setTimeout(() => {
        if (isAccepting) {
          return;
        }
        void handleAccept(serviceRequestId);
      }, 40000);

      return () => clearTimeout(timer);
    }
  }, [profile?.isCareshipEmployee]);

  const handlePressAccept = useCallback(() => {
    if (isAccepting) {
      return;
    }

    void handleAccept(serviceRequestId);
  }, [handleAccept, serviceRequestId, isAccepting]);

  const fullAddress = proposal
    ? `${proposal.careReceiver.address.street} ${proposal.careReceiver.address.postalCode} ${proposal.careReceiver.address.city}`
    : '';

  const handleMapPress = useCallback(() => {
    openMap(fullAddress);
    trackEvent(events.PROPOSAL_DETAIL_ROUTE_CLICK);
  }, [fullAddress]);

  if (proposal === null) {
    return <ExpiredProposal />;
  }

  const showCareDegree = !['none', 'unknown'].includes(proposal.careReceiver.careDegree);

  const isExpectedScheduleIncomplete =
    proposal.schedule.expectedAppointmentSchedule?.isFlexible === false &&
    proposal.schedule.expectedAppointmentSchedule?.preferences === '';

  const hasSchedulePreferences =
    !proposal.schedule.expectedAppointmentSchedule?.isFlexible &&
    Boolean(proposal.schedule.expectedAppointmentSchedule?.preferences);

  return (
    <>
      <Container>
        <Title style={styles.title}>
          <FormattedMessage
            id="NAME_PARTIAL"
            tagName={Fragment}
            values={{ gender: proposal.careReceiver.gender, name: proposal.careReceiver.name }}
          />{' '}
          (
          <FormattedMessage
            tagName={Fragment}
            id="AGE"
            values={{ age: proposal.careReceiver.age }}
          />
          )
        </Title>
        <Text bold size="large">
          <FormattedMessage id={proposal.isNewCustomer ? 'NEW_CUSTOMER' : 'EXISTING_CUSTOMER'} />
        </Text>
        {proposal.status === 'ACCEPTED' && (
          <Alert
            style={styles.alert}
            title={intl.formatMessage({ id: 'ACCEPTED_ALERT_TITLE' })}
            icon
          >
            <Text size="large">
              <FormattedMessage id="ACCEPTED_ALERT_MESSAGE" />
            </Text>
          </Alert>
        )}
      </Container>
      <Hr />
      <Card mode="contained" marginHorizontal="base">
        <ScheduleDetails
          title={<FormattedMessage id="FIRST_APPOINTMENT" />}
          schedule={{
            date: new Date(proposal.schedule.date),
            durationInMinutes: proposal.schedule.durationInMinutes,
            isAllDay: proposal.schedule.isAllDay,
            isFlexible: proposal.schedule.isFlexible,
          }}
        />
      </Card>
      <Hr />
      <AddressRouteRow
        onPress={handleMapPress}
        street={proposal.careReceiver.address.street}
        houseNumber=""
        postalCode={proposal.careReceiver.address.postalCode}
        city={proposal.careReceiver.address.city}
      />
      <Container>
        <MapWithMarker
          cacheEnabled
          zoomEnabled={false}
          zoomTapEnabled={false}
          rotateEnabled={false}
          scrollEnabled={false}
          pitchEnabled={false}
          onPress={handleMapPress}
          style={styles.mapStyle}
          address={fullAddress}
        />
      </Container>
      <View style={styles.section}>
        <DividingHeader rightGutter>
          <FormattedMessage id="DETAILS" />
        </DividingHeader>
        {!isExpectedScheduleIncomplete && proposal.schedule.bookingType !== 'ONE_TIME' && (
          <ListItemData
            icon={<CalendarRepetitionIcon />}
            actionLabel={
              hasSchedulePreferences ? null : (
                <FormattedMessage id="PROPOSAL_EXPECTED_APPOINTMENT_SCHEDULE" />
              )
            }
            titleProps={{ muted: true }}
            labelProps={{ muted: false }}
          >
            <FormattedMessage id="PROPOSAL_EXPECTED_APPOINTMENT_SCHEDULE_LABEL" />
            {hasSchedulePreferences && (
              <>
                <Title size="h4" thin style={{ marginTop: 12 }}>
                  <FormattedMessage id="PROPOSAL_EXPECTED_APPOINTMENT_SCHEDULE_PREFERENCES_PREFIX" />
                </Title>
                <Title size="h4" thin style={{ marginTop: 4 }}>
                  „{proposal.schedule.expectedAppointmentSchedule?.preferences}“
                </Title>
              </>
            )}
          </ListItemData>
        )}
        {proposal.schedule.bookingType !== 'ONE_TIME' && (
          <ListItemData
            icon={<RepetitionIcon />}
            actionLabel={
              <FormattedMessage
                id="BOOKING_FREQUENCY"
                values={{ frequency: proposal.schedule.frequency }}
              />
            }
            titleProps={{ muted: true }}
            labelProps={{ muted: false }}
          >
            <FormattedMessage id="FREQUENCY_LABEL" />
          </ListItemData>
        )}
        <ListItemData
          icon={<ClockIcon />}
          actionLabel={toDurationString(proposal.schedule.durationInMinutes)}
          titleProps={{ muted: true }}
          labelProps={{ muted: false }}
        >
          <FormattedMessage id="DURATION_LABEL" />
        </ListItemData>
        {proposal.schedule.bookingType === 'ONE_TIME' && (
          <ListItemData
            icon={<CalendarRepetitionIcon />}
            actionLabel={
              <FormattedMessage id="PROPOSAL_EXPECTED_APPOINTMENT_SCHEDULE_NO_SCHEDULE" />
            }
            titleProps={{ muted: true }}
            labelProps={{ muted: false }}
          >
            <FormattedMessage id="PROPOSAL_EXPECTED_APPOINTMENT_SCHEDULE_LABEL" />
          </ListItemData>
        )}
        {!profile?.isCareshipEmployee && (
          <ListItemData
            icon={<EuroIcon />}
            actionLabel={
              <FormattedNumber
                value={proposal.pricing.grossPricePerHourInCents / 100}
                currency="EUR"
                currencyDisplay="symbol"
                // eslint-disable-next-line react/style-prop-object
                style="currency"
                minimumFractionDigits={2}
                maximumFractionDigits={2}
              />
            }
            titleProps={{ muted: true }}
            labelProps={{ muted: false }}
          >
            <FormattedMessage id="EARNINGS_PER_HOUR" />
          </ListItemData>
        )}
      </View>
      <View style={styles.section}>
        <DividingHeader rightGutter>
          <FormattedMessage id="SERVICES_REQUESTED" />
        </DividingHeader>
        {proposal.subServices.map((service) => (
          <ListItemData key={service.id} icon={<CheckIcon />}>
            {service.label}
          </ListItemData>
        ))}
      </View>
      {Boolean(proposal.description) && (
        <View style={styles.section}>
          <DividingHeader rightGutter>
            <FormattedMessage
              id="MORE_ABOUT_CARE_RECEIVER"
              values={{
                name: (
                  <FormattedMessage
                    id="NAME_PARTIAL"
                    values={{
                      gender: proposal.careReceiver.gender,
                      name: proposal.careReceiver.name,
                    }}
                  />
                ),
              }}
            />
          </DividingHeader>
          <Container style={styles.additionalServices}>
            <Text size="xlarge">{proposal.description}</Text>
          </Container>
        </View>
      )}
      {showCareDegree && (
        <View style={styles.careDegreeSection}>
          <ListItemData
            actionLabel={
              <FormattedMessage
                id="CARE_DEGREE"
                values={{ degree: proposal.careReceiver.careDegree }}
              />
            }
            icon={<AwardIcon />}
          >
            <FormattedMessage id="CARE_DEGREE_LABEL" />
          </ListItemData>
        </View>
      )}
      <Container style={styles.actionButtonRow}>
        {proposal.status === 'ACCEPTED' ? (
          <Alert style={styles.alert}>
            <Text center size="large">
              <FormattedMessage id="PROPOSAL_AWAITING_CUSTOMER" />
            </Text>
          </Alert>
        ) : (
          !profile?.isCareshipEmployee && (
            <>
              <PressableWithConfirmation
                dialogTitle={intl.formatMessage({ id: 'ACCEPT_PROPOSAL_CONFIRM_TITLE' })}
                dialogContent={intl.formatMessage({ id: 'ACCEPT_PROPOSAL_CONFIRM_DESCRIPTION' })}
                confirmButtonLabel={intl.formatMessage({ id: 'ACCEPT_PROPOSAL_CONFIRM_YES' })}
                abortButtonLabel={intl.formatMessage({ id: 'ACCEPT_PROPOSAL_CONFIRM_CANCEL' })}
              >
                <Button
                  onPress={handlePressAccept}
                  testID="accept-proposal-button"
                  dark={false}
                  loading={isAccepting}
                  disabled={isAccepting}
                  color={CareshipColors.green}
                  labelStyle={{ color: CareshipColors.white }}
                  style={styles.actionButton}
                  icon={<CheckIcon color={CareshipColors.white} />}
                >
                  <FormattedMessage id="PROPOSAL_ACCEPT_BUTTON" />
                </Button>
              </PressableWithConfirmation>
              <Button
                color={CareshipColors.red}
                testID="decline-proposal-button"
                style={[styles.actionButton, styles.actionButtonRight]}
                icon={<XIcon color={CareshipColors.white} />}
                onPress={onDecline}
              >
                <FormattedMessage id="PROPOSAL_DECLINE_BUTTON" />
              </Button>
            </>
          )
        )}
      </Container>
    </>
  );
}
