import { StyleSheet } from 'react-native';
import { CareshipColors } from '../CSTheme';

export default StyleSheet.create({
  wrapper: {
    flexGrow: 0,
    flexShrink: 0,
    borderStyle: 'solid',
    // overlap with divider, slightly cleaner look when pressed
    marginTop: -1,
    overflow: 'hidden',
  },
  content: {
    borderBottomWidth: 1,
    borderBottomColor: CareshipColors.slate100,
    marginLeft: 16,
    paddingTop: 16 + 1,
    paddingBottom: 16,
    flex: 1,
    justifyContent: 'center',
  },
  touchable: {
    position: 'relative',
    flex: 1,
  },
  activeBorder: {
    borderLeftColor: CareshipColors.primary,
    borderLeftWidth: 4,
    position: 'absolute',
    top: 8,
    left: -16,
    bottom: 8,
  },
  firstRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginRight: 16,
    marginBottom: 2,
    alignItems: 'center',
  },
  cellLabel: {
    alignSelf: 'center',
  },
  secondRow: {
    flexDirection: 'row',
    alignItems: 'baseline',
  },
  pushedDownFirstRow: {
    marginVertical: 4,
  },
});
