/**
 * @generated SignedSource<<f65ed9eaea9f5e9e8beef5d6f9c4776d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SupportingDocumentListQuery$variables = {};
export type SupportingDocumentListQuery$data = {
  readonly supportingDocumentSections: ReadonlyArray<{
    readonly sectionTitle: string;
    readonly documents: ReadonlyArray<{
      readonly filename: string;
      readonly uri: string;
      readonly " $fragmentSpreads": FragmentRefs<"SupportingDocumentListItem_supportingDocument">;
    }>;
  }>;
};
export type SupportingDocumentListQuery = {
  variables: SupportingDocumentListQuery$variables;
  response: SupportingDocumentListQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sectionTitle",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "filename",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uri",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SupportingDocumentListQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SupportingDocumentSections",
        "kind": "LinkedField",
        "name": "supportingDocumentSections",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "SupportingDocument",
            "kind": "LinkedField",
            "name": "documents",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "SupportingDocumentListItem_supportingDocument"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "SupportingDocumentListQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SupportingDocumentSections",
        "kind": "LinkedField",
        "name": "supportingDocumentSections",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "SupportingDocument",
            "kind": "LinkedField",
            "name": "documents",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "type",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3f5636a22d7ad92983bb91697ef3d5e9",
    "id": null,
    "metadata": {},
    "name": "SupportingDocumentListQuery",
    "operationKind": "query",
    "text": "query SupportingDocumentListQuery {\n  supportingDocumentSections {\n    sectionTitle\n    documents {\n      filename\n      uri\n      ...SupportingDocumentListItem_supportingDocument\n    }\n  }\n}\n\nfragment SupportingDocumentListItem_supportingDocument on SupportingDocument {\n  filename\n  type\n  title\n  uri\n}\n"
  }
};
})();

(node as any).hash = "ed54c6604e57fcf088900ce499bf70b0";

export default node;
