import { StyleSheet, View } from 'react-native';
import React, { ReactNode } from 'react';
import Badge from '../Badge';

const styles = StyleSheet.create({
  zeroSizeCenter: {
    position: 'absolute',
    top: 4,
    right: 0,

    width: 2,
    height: 2,
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    width: 80,
    height: 80,
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
  },
});

type IconWithBadgeProps = {
  badgeCount?: number;
  width: number;
  height: number;
  children: ReactNode;
};

export default function IconWithBadge({
  badgeCount = 0,
  width,
  height,
  children,
}: IconWithBadgeProps) {
  return (
    <View style={{ width, height, margin: 5, position: 'relative' }}>
      {children}
      {badgeCount > 0 && (
        <View style={[styles.zeroSizeCenter]}>
          <View style={styles.container} pointerEvents="none">
            <Badge small>{badgeCount}</Badge>
          </View>
        </View>
      )}
    </View>
  );
}
