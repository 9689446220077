import { Platform, ScrollView, Text, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import React, { useCallback, useState } from 'react';
import { addHours, addWeeks, format, roundToNearestMinutes } from 'date-fns';
import { Feather } from '@expo/vector-icons';
import { HelperText, Switch } from 'react-native-paper';
import { useNavigation } from '@react-navigation/native';
import { css } from '@emotion/native';
import { PreloadedQuery, usePreloadedQuery } from 'react-relay/hooks';
import { graphql } from 'react-relay';
import {
  Button,
  Container,
  DateTimeInterval,
  FormGroupTitle,
  Hr,
  InputErrorMessage,
  Label,
  RadioButtonGroup,
  RadioButtonItem,
} from '../../../Component';
import styles from './AddBookingForm.styles';
import { NonNullableProperties } from '../../../../Infrastructure/types/NonNullableProperties';
import { CareshipColors } from '../../../Component/CSTheme';
import HintText from '../../../Component/Form/HintText';
import { ErrorResponse } from '../../../../Infrastructure/Service';
import { events, trackEvent } from '../../../../Infrastructure/Tracking/Tracking';
import ContractCancelationIcon from '../../../Component/Icon/ContractCancelationIcon';
import OverlappingBookingCountRow from '../../../Component/OverlappingBookingCountRow';
import { useStores } from '../../../../Infrastructure/Store';
import { AppStackNavigationProp } from '../../../Root/Navigator/AppNavigator';
import { AddBookingFormQuery } from './__generated__/AddBookingFormQuery.graphql';
import { nonNullEdges } from '../../../../Infrastructure/Relay';

type FormData = {
  contractId: string | null;
  startDate: Date;
  endDate: Date;
};

interface AddBookingFormProps {
  preloadedQuery: PreloadedQuery<AddBookingFormQuery>;
  onSubmit: (data: NonNullableProperties<FormData>[]) => void;
  isLoading?: boolean;
  selectedContract?: string | null;
  successfulResponses?: {
    response: {
      data: string;
    };
    bookingStartDate: Date;
  }[];
  errors?: {
    response: ErrorResponse;
    bookingStartDate: Date;
  }[];
}

const abortButtonStyle = css({ marginVertical: 16 });

export const addBookingFormQuery = graphql`
  query AddBookingFormQuery {
    allContracts {
      edges {
        node {
          id
          contractId
          isActive
          cancelationScheduledFor
          careReceiver {
            name {
              firstName
              lastName
            }
          }
        }
      }
    }
  }
`;

const isActiveContract = ({ node: { isActive } }: { node: { isActive: boolean } }): boolean =>
  isActive;

export default function AddBookingForm({
  isLoading,
  preloadedQuery,
  errors = [],
  successfulResponses = [],
  selectedContract,
  onSubmit,
}: AddBookingFormProps) {
  const intl = useIntl();
  const { bookingStore } = useStores();
  const nearestQuarterHour = roundToNearestMinutes(new Date(), { nearestTo: 15 });
  const [bookingInterval, setBookingInterval] = useState({
    start: nearestQuarterHour,
    end: addHours(nearestQuarterHour, 1),
  });
  const [isRecurring, setIsRecurring] = useState<boolean>(false);
  const [contractId, setContractId] = useState<string | null>(selectedContract || null);
  const [focused, setFocused] = useState<string | null>(null);
  const { bottom: bottomInset } = useSafeAreaInsets();
  const navigation = useNavigation<AppStackNavigationProp<'AddAppointment'>>();
  const { allContracts } = usePreloadedQuery(addBookingFormQuery, preloadedQuery);

  const handleContractChange = useCallback((id: string) => {
    setContractId(id);
    setFocused('careReceiver');
  }, []);

  const recurringEmptyArray = Array.from(new Array(isRecurring ? 4 : 1));

  const newBookings = recurringEmptyArray.map((_, i) => ({
    contractId,
    startDate: addWeeks(bookingInterval.start, i),
    endDate: addWeeks(bookingInterval.end, i),
  }));

  function validate(data: FormData[]): data is NonNullableProperties<FormData>[] {
    return (
      !!data.filter((d) => d.contractId !== null).length &&
      !bookingStore.overlappingCount(newBookings)
    );
  }

  const contracts = allContracts?.edges
    ?.filter(nonNullEdges)
    .filter(isActiveContract)
    .map(({ node }) => node);

  // Overlapping bookings should be fairly uncommon, but very important to show to the user in case
  // backend throws an error.
  if (errors?.length) {
    return (
      <>
        {successfulResponses.map(({ bookingStartDate }) => (
          <HelperText type="info">
            {format(bookingStartDate, 'dd MMM, HH:mm')}{' '}
            <FormattedMessage id="BOOKING_RECURRING_SAVED" />
          </HelperText>
        ))}
        {errors.map(({ bookingStartDate, response }) => {
          const dateTime = format(bookingStartDate, 'dd MMM, HH:mm');

          return (
            <InputErrorMessage key={dateTime}>
              {dateTime}{' '}
              <FormattedMessage id="ERROR_RESPONSE" values={{ errorCode: response.errorCode }} />
            </InputErrorMessage>
          );
        })}
      </>
    );
  }

  const handleAbort = () => {
    trackEvent(events.BOOKING_CREATE_ABORT_CLICK);
    navigation.goBack();
  };

  const handleSubmit = () => {
    if (validate(newBookings)) {
      onSubmit(newBookings);

      trackEvent(events.BOOKING_CREATE_FINALIZE_CLICK);
    }
  };

  return (
    <ScrollView>
      <View style={{ marginBottom: bottomInset }}>
        <View style={[styles.container, styles.group]}>
          <RadioButtonGroup value={contractId as string} onValueChange={handleContractChange}>
            <Container>
              <FormattedMessage tagName={FormGroupTitle} id="CARE_RECIPIENT_LABEL" />
            </Container>
            {contracts?.map(
              ({
                contractId: value,
                careReceiver: {
                  name: { firstName, lastName },
                },
                cancelationScheduledFor,
              }) => (
                <RadioButtonItem
                  key={value}
                  label={intl.formatMessage({ id: 'NAME_FULL' }, { firstName, lastName })}
                  value={value}
                  style={Boolean(cancelationScheduledFor) && styles.careReceiverItemWithHint}
                  testID={value}
                >
                  {cancelationScheduledFor !== null && (
                    <View style={styles.careReceiverHint}>
                      <ContractCancelationIcon style={styles.careReceiverHintIcon} />
                      <Text style={styles.careReceiverHintText}>
                        <FormattedMessage
                          id="COLLABORATION_ENDS"
                          values={{
                            endsAt: (
                              <FormattedDate
                                value={new Date(cancelationScheduledFor)}
                                year="numeric"
                                month="2-digit"
                                day="2-digit"
                              />
                            ),
                          }}
                        />
                      </Text>
                    </View>
                  )}
                </RadioButtonItem>
              ),
            )}
          </RadioButtonGroup>
        </View>
        <Container>
          <FormattedMessage tagName={FormGroupTitle} id="APPOINTMENT_DATE_LABEL" />
          <OverlappingBookingCountRow bookings={newBookings} />
        </Container>
        <DateTimeInterval
          focused={focused === 'appointmentTime'}
          onIntervalChange={setBookingInterval}
          interval={bookingInterval}
          onFocus={() => setFocused('appointmentTime')}
        />
        <View style={[styles.group, styles.lastGroup]}>
          <Container>
            <View style={styles.recurringRow}>
              <Feather
                color={isRecurring ? CareshipColors.slate400 : CareshipColors.slate300}
                size={24}
                name="refresh-ccw"
                style={styles.recurringIcon}
              />
              <View>
                <Label active={isRecurring}>
                  <FormattedMessage id="RECURRING_BOOKING_LABEL" />
                </Label>
                <HintText>
                  <FormattedMessage id="RECURRING_BOOKING_LABEL_HINT" />
                </HintText>
              </View>
              <Switch
                style={{ marginLeft: 'auto' }}
                value={isRecurring}
                onValueChange={() => setIsRecurring((r) => !r)}
              />
            </View>
          </Container>
        </View>
        <Hr />
        <Container>
          <Button
            disabled={!validate(newBookings) || isLoading}
            loading={isLoading}
            onPress={handleSubmit}
            icon={<Feather color="white" size={32} name="check" />}
          >
            <FormattedMessage id="BUTTON_CREATE_BOOKING" />
          </Button>
          {Platform.OS === 'web' && (
            <Button
              disabled={isLoading}
              loading={isLoading}
              style={abortButtonStyle}
              dark={false}
              thin
              color="transparent"
              onPress={handleAbort}
            >
              <FormattedMessage id="BUTTON_CANCEL" />
            </Button>
          )}
        </Container>
      </View>
    </ScrollView>
  );
}
