import React, { ReactElement } from 'react';
import { Platform } from 'react-native';
import { IconButton } from 'react-native-paper';
import { Feather } from '@expo/vector-icons';
import { intl } from '../../Infrastructure/Intl';
import HeaderActionButton from './HeaderActionButton';
import CSTheme from './CSTheme';

interface HeaderSaveButtonProps {
  dirty: boolean;
  onPress: () => void;
}

export default function HeaderSaveButton({
  dirty,
  onPress,
}: HeaderSaveButtonProps): ReactElement | null {
  return Platform.select({
    default: null,
    ios: (
      <HeaderActionButton
        disabled={!dirty}
        onPress={onPress}
        title={intl.formatMessage({ id: 'BUTTON_SAVE' })}
      />
    ),
    android: (
      <IconButton
        disabled={!dirty}
        style={{ marginRight: 8 }}
        onPress={onPress}
        icon={() => <Feather color={CSTheme.colors.background} size={24} name="save" />}
      />
    ),
  });
}
