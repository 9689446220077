import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';
import { CareshipColors } from '../CSTheme';

function FaqIcon(props: SvgProps) {
  return (
    <Svg width={36} height={36} viewBox="0 0 36 36" {...props}>
      <Path
        d="M4.732 7.314a14.206 14.206 0 0125.406 2.755 1.125 1.125 0 11-2.118.76A11.956 11.956 0 106.623 21.196c.175.28.217.618.121.928l-.06.152-2.48 5.206 5.208-2.48c.297-.14.638-.144.934-.014l.144.076c1.358.845 2.871 1.41 4.45 1.659a1.125 1.125 0 01-.35 2.222 14.176 14.176 0 01-4.3-1.41l-.451-.244-7.527 3.584c-.911.434-1.86-.451-1.555-1.362l.055-.137 3.583-7.527-.172-.313a14.207 14.207 0 01.254-13.8l.255-.422z"
        fill={CareshipColors.slate400}
        fillRule="nonzero"
      />
      <Path
        stroke="#000"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M15.305 16.922h0"
      />
      <Path
        d="M27.206 15.007l-.37-.007a8.838 8.838 0 00-6.904 14.351l.241.289a8.836 8.836 0 007.903 2.945l.27-.044 4.656 2.87.132.07c.8.365 1.724-.315 1.567-1.22l-.876-5.047.128-.164a8.807 8.807 0 00-6.747-14.043zm-.36 2.244a6.557 6.557 0 014.94 10.903l-.103.135a1.125 1.125 0 00-.164.803l.537 3.092-2.904-1.79a1.125 1.125 0 00-.843-.139 6.586 6.586 0 11-1.463-13.004z"
        fill={CareshipColors.slate400}
        fillRule="nonzero"
      />
      <Path
        d="M26.625 25.688l.165.01a1.313 1.313 0 11-.33 0l.165-.01zM26.625 18.375c.57 0 1.04.423 1.115.972l.01.153v4.313a1.125 1.125 0 01-2.24.152l-.01-.152V19.5c0-.621.504-1.125 1.125-1.125zM16.045 6.399c2.054-.213 3.984 1.028 4.468 2.94.494 1.946-.682 3.892-2.669 4.531-.11.035-.178.097-.206.157l-.013.058V15a1.125 1.125 0 01-2.24.153l-.01-.153v-.914c0-1.01.634-1.894 1.574-2.283l.206-.075c.89-.286 1.37-1.08 1.177-1.836-.2-.792-1.078-1.356-2.055-1.255-.893.092-1.552.704-1.642 1.423l-.01.167a1.125 1.125 0 01-2.25 0c0-1.985 1.6-3.615 3.67-3.828zM16.5 17.438l.165.01a1.313 1.313 0 11-.33 0l.165-.01z"
        fill={CareshipColors.primary}
        fillRule="nonzero"
      />
    </Svg>
  );
}

export default FaqIcon;
