import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { ActivityIndicator, StyleSheet } from 'react-native';
import { FormattedMessage } from 'react-intl';
import { useNavigation } from '@react-navigation/native';
import { IconButton } from 'react-native-paper';
import styled from '@emotion/native';
import { logger } from '../../../Infrastructure/Service';
import { Button } from '../../Component';
import CSTheme, { CareshipColors } from '../../Component/CSTheme';
import { useDownloadFile } from '../../../Infrastructure/Hook';
import { DownloadIcon } from '../../Component/Icon';
import useInvoiceDownloadUrl from '../Hook/useInvoiceDownloadUrl';
import { AccountStackNavigationProp } from '../Navigator/AccountStackNavigationProp';
import { events, trackEvent } from '../../../Infrastructure/Tracking/Tracking';
import PdfReader from '../../Component/PdfReader';

const PdfReaderContainer = styled.View({ flex: 1, position: 'relative' });

const ButtonContainer = styled.View(({ theme: { colors } }) => ({
  height: 84,
  padding: 16,
  alignItems: 'center',
  borderTopColor: colors.slate200,
  borderTopWidth: StyleSheet.hairlineWidth,
}));

export default function InvoiceDetail({
  invoiceId,
  filename,
}: {
  invoiceId: string;
  filename: string;
}) {
  const [uri, setUri] = useState<string | undefined>(undefined);
  const { generateUrl } = useInvoiceDownloadUrl();
  const { download, isDownloadInProgress, isDownloadLoading } = useDownloadFile();
  const navigation = useNavigation<AccountStackNavigationProp<'InvoiceDetail'>>();

  const handleFileDownload = useCallback(() => {
    void (async () => {
      trackEvent(events.INVOICE_DOWNLOAD_CLICK, {
        invoice_id: invoiceId,
      });

      const uriForDownload = await generateUrl(invoiceId);

      if (!uriForDownload) {
        logger.warn(`No generated download uri for the invoiceId ${invoiceId}`);

        return;
      }

      await download(filename, uriForDownload);
    })();
  }, [download, invoiceId, generateUrl, filename]);

  const setFileForPreview = useCallback(async () => {
    const generatedUrl = await generateUrl(invoiceId);

    setUri(generatedUrl);
  }, [generateUrl, invoiceId]);

  useEffect(() => {
    void setFileForPreview();
  }, [setFileForPreview]);

  useLayoutEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <IconButton
          onPress={handleFileDownload}
          style={{ margin: -8 }}
          disabled={isDownloadInProgress || isDownloadLoading}
          icon={
            isDownloadLoading
              ? () => <ActivityIndicator color={CSTheme.colors.background} />
              : () => <DownloadIcon color={CareshipColors.white} />
          }
        />
      ),
    });
  }, [navigation, download, isDownloadInProgress, isDownloadLoading, handleFileDownload]);

  if (!uri) {
    return null;
  }

  return (
    <PdfReaderContainer>
      <PdfReader
        source={{
          uri,
        }}
      />
      <ButtonContainer>
        <Button
          size="sm"
          disabled={isDownloadInProgress || isDownloadLoading}
          onPress={handleFileDownload}
          icon={
            <DownloadIcon
              color={
                isDownloadInProgress || isDownloadLoading
                  ? CareshipColors.slate300
                  : CareshipColors.white
              }
            />
          }
        >
          <FormattedMessage id="INVOICE_PDF_VIEW_DOWNLOAD" />
        </Button>
      </ButtonContainer>
    </PdfReaderContainer>
  );
}
