import styled from '@emotion/native';
import { FormattedMessage, useIntl } from 'react-intl';
import React, { useCallback, useState } from 'react';
import { Platform, View } from 'react-native';
import { IconButton } from 'react-native-paper';
import { useNavigation } from '@react-navigation/native';
import { CareshipColors } from '../../Component/CSTheme';
import { Card, PressableWithConfirmation, Text, Title } from '../../Component';
import useDeleteAbsence from '../Hook/useDeleteAbsence';
import EditAbsenceForm from './EditAbsenceForm';
import { AccountStackNavigationProp } from '../Navigator/AccountStackNavigationProp';
import { Edit2Icon, TrashIcon } from '../../Component/Icon';

const Row = styled.View(() => [
  {
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'space-between',
    borderBottomColor: CareshipColors.slate200,
    borderStyle: 'solid',
    borderBottomWidth: 1,
    paddingVertical: 16,
  },
]);

const ActionButton = styled(IconButton)({
  margin: 0,
});

const Buttons = styled.View(() => [
  {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flex: 1,
  },
]);

const Dates = styled.View({
  marginBottom: 4,
});

interface AbsenceLineItemProps {
  readonly node: {
    readonly id: string;
    readonly from: string;
    readonly to: string;
    readonly reason: string;
  };
}

const editIcon = () => <Edit2Icon size={22} />;
const deleteIcon = () => <TrashIcon testID="delete-absence-icon" size={22} />;

const dateFormat = {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
} as const;

export default function AbsenceLineItem({
  node: { from: fromStr, id, reason, to: toStr },
}: AbsenceLineItemProps) {
  const { isInFlight, deleteAbsence } = useDeleteAbsence({ id });
  const [isEditing, setIsEditing] = useState(false);
  const navigation = useNavigation<AccountStackNavigationProp<'Availability'>>();
  const intl = useIntl();

  const handleDelete = useCallback(() => {
    if (isInFlight) {
      return;
    }

    deleteAbsence();
  }, [deleteAbsence, isInFlight]);

  const handleEdit = useCallback(() => {
    if (Platform.OS === 'web') {
      setIsEditing(true);
      return;
    }

    navigation.navigate('EditAbsence', {
      id,
    });
  }, [id, navigation]);

  if (isEditing) {
    return (
      <Card mode="raised">
        <EditAbsenceForm
          id={id}
          initialTo={toStr}
          initialFrom={fromStr}
          initialReason={reason}
          onCompleted={() => setIsEditing(false)}
        />
      </Card>
    );
  }

  const to = intl.formatDate(new Date(toStr), dateFormat);
  const from = intl.formatDate(new Date(fromStr), dateFormat);

  return (
    <Row key={id}>
      <View>
        <Dates>
          <Title size="h4" thin>
            {from}
            {' - '}
            {to}
          </Title>
        </Dates>
        <Text size="medium" muted>
          <FormattedMessage id="ABSENCE_TYPE" values={{ type: reason }} />
        </Text>
      </View>
      <Buttons>
        <ActionButton onPress={handleEdit} testID="edit-absence-button" icon={editIcon} />
        <PressableWithConfirmation
          dialogTitle={intl.formatMessage({ id: 'ABSENCE_DELETE_CONFIRM_TITLE' })}
          dialogContent={intl.formatMessage(
            { id: 'ABSENCE_DELETE_CONFIRM_CONTENT' },
            {
              to,
              from,
            },
          )}
          confirmButtonLabel={intl.formatMessage({ id: 'ABSENCE_DELETE_CONFIRM_SUBMIT' })}
          abortButtonLabel={intl.formatMessage({ id: 'BUTTON_CANCEL' })}
        >
          <ActionButton
            onPress={handleDelete}
            testID="delete-absence-button"
            disabled={isInFlight}
            style={{ margin: 0 }}
            icon={deleteIcon}
          />
        </PressableWithConfirmation>
      </Buttons>
    </Row>
  );
}
