import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';
import { CareshipColors } from '../CSTheme';

interface PdfIconProps extends SvgProps {
  color?: string;
  size?: number;
}

export default function PdfIcon({
  color = CareshipColors.slate400,
  size = 24,
  ...props
}: PdfIconProps) {
  return (
    <Svg width={size} height={size} viewBox="0 0 22 25" {...props}>
      <Path
        d="M15.9-.002c.576 0 1.124.249 1.521.684l3.982 4.372c.384.42.597.982.597 1.565v14.382c-.001 1.64-1.237 2.996-2.8 2.997H2.802C1.24 23.998.001 22.642 0 21V3C0 2.22.283 1.466.796.903A2.711 2.711 0 012.8-.002zm-1.65 1.5H2.8c-.33 0-.651.146-.895.414A1.618 1.618 0 001.5 3v18c0 .842.6 1.498 1.3 1.498h16.4c.701 0 1.3-.656 1.3-1.498V7.498h-4a2.25 2.25 0 01-2.245-2.096l-.005-.154v-3.75zM5.518 13.575l.237.006c.694.031 1.225.207 1.591.526.407.354.61.858.61 1.512 0 .717-.21 1.272-.634 1.663-.423.391-1.025.587-1.804.587H5.03V20H3.294v-6.425h2.224zm5.686 0l.253.006c.91.04 1.622.302 2.136.785.561.528.841 1.29.841 2.285 0 1.073-.295 1.899-.885 2.479-.59.58-1.42.87-2.49.87H8.981v-6.425h2.223zm8.179 0v1.393h-2.088v1.226h1.925v1.393h-1.925V20h-1.71v-6.425h3.798zm-8.16 1.402h-.506v3.6h.386c.528 0 .914-.154 1.16-.46.247-.306.37-.774.37-1.404 0-.589-.117-1.026-.35-1.31-.233-.284-.586-.426-1.06-.426zm-5.745 0H5.03v1.477h.317c.26 0 .468-.074.621-.22.154-.147.231-.349.231-.607 0-.433-.24-.65-.72-.65zM15.9 1.498h-.15v3.75c0 .38.282.693.648.743l.102.007h3.734l-3.922-4.306a.58.58 0 00-.323-.186l-.089-.008z"
        fill={color}
      />
    </Svg>
  );
}
