import { TouchableRipple } from 'react-native-paper';
import { View, StyleSheet, StyleProp, ViewStyle } from 'react-native';
import React, { ReactNode } from 'react';
import { Feather } from '@expo/vector-icons';
import CSTheme, { CareshipColors } from './CSTheme';
import Text from './Text';

const styles = StyleSheet.create({
  container: {
    backgroundColor: CareshipColors.slate050,
    padding: 16,
    borderRadius: 8,
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  text: {
    marginLeft: 12,
    flexGrow: 1,
  },
});

interface NavFlatButtonProps {
  onPress: () => void;
  children: ReactNode;
  icon: ReactNode;
  style?: StyleProp<ViewStyle>;
}

export default function NavFlatButton({ onPress, style, icon, children }: NavFlatButtonProps) {
  return (
    <TouchableRipple onPress={onPress} style={[style, styles.container]}>
      <View style={styles.content}>
        {icon}
        <Text bold size="large" style={styles.text}>
          {children}
        </Text>
        <Feather name="chevron-right" color={CSTheme.colors.disabled} size={24} />
      </View>
    </TouchableRipple>
  );
}
