import { Platform, StyleSheet, View } from 'react-native';
import { FormattedMessage } from 'react-intl';
import React, { useCallback } from 'react';
import { useNavigation } from '@react-navigation/native';
import { CaregiverProfile as CaregiverProfileModel } from '../../../Context/Profile/Store/Profile';
import CaregiverProfile from './ProfileSummary';
import { ListItemAction, Revision } from '../../Component';
import FeedbackCard from './FeedbackCard';
import { AccountStackNavigationProp } from '../Navigator/AccountStackNavigationProp';
import useHandleLogout from '../../../Infrastructure/Hook/useHandleLogout';
import { WebPageIcon, EducationIcon, MoneyBagIcon, AccountIcon, UserPlusIcon } from '../../Component/Icon';

const styles = StyleSheet.create({
  zeroSizeCenter: {
    paddingTop: 32,
    flex: 1,
  },
  profile: {
    marginBottom: 32,
  },
  revision: {
    marginTop: 'auto',
    paddingTop: 32,
    marginBottom: 8,
  },
});

export default function Account({ profile }: { profile: CaregiverProfileModel | null }) {
  const navigation = useNavigation<AccountStackNavigationProp<'Account'>>();
  const logout = useHandleLogout();
  const handleLogout = useCallback(() => {
    void logout();
  }, [logout]);

  const handleHelpCenterPress = useCallback(() => {
    navigation.navigate('HelpCenter');
  }, [navigation]);

  const handleAvailabilityPress = useCallback(() => {
    navigation.navigate('Availability');
  }, [navigation]);

  const handleEditProfile = useCallback(() => {
    navigation.navigate('Profile');
  }, [navigation]);

  const handleContactAndAddressPress = useCallback(() => {
    navigation.navigate('ContactAndAddress');
  }, [navigation]);

  const handleFinancialOverviewPress = useCallback(() => {
    navigation.navigate('FinancialOverview');
  }, [navigation]);
  const handleReferralPress = useCallback(() => {
    navigation.navigate('ReferralCode');
  }, [navigation]);

  return (
    <View style={styles.zeroSizeCenter}>
      <CaregiverProfile style={styles.profile} profile={profile} />
      <View style={{ marginBottom: 16 }}>
        {!profile?.isCareshipEmployee && (
          <ListItemAction
            actionLabel=" "
            multiline
            onPress={handleAvailabilityPress}
            icon="calendar"
          >
            <FormattedMessage id="AVAILABILITY" />
          </ListItemAction>
        )}

        <ListItemAction
          onPress={handleEditProfile}
          multiline
          actionLabel={' '}
          icon={<WebPageIcon />}
        >
          <FormattedMessage id="EDIT_PROFILE" />
        </ListItemAction>
        <ListItemAction
          onPress={handleContactAndAddressPress}
          multiline
          actionLabel={' '}
          icon={<AccountIcon />}
        >
          <FormattedMessage id="CONTACT_AND_ADDRESS" />
        </ListItemAction>
        <ListItemAction
          onPress={handleFinancialOverviewPress}
          multiline
          actionLabel={' '}
          icon={<MoneyBagIcon />}
        >
          <FormattedMessage id="FINANCIAL_OVERVIEW" />
        </ListItemAction>
        <ListItemAction
          onPress={handleReferralPress}
          multiline
          actionLabel={' '}
          icon={<UserPlusIcon />}
        >
          <FormattedMessage id="REFERRAL_CODE_TITLE" />
        </ListItemAction>
        <ListItemAction
          actionLabel=" "
          multiline
          onPress={handleHelpCenterPress}
          icon={<EducationIcon />}
        >
          <FormattedMessage id="HELP_CENTER" />
        </ListItemAction>
        {Platform.OS !== 'web' ? (
          <ListItemAction onPress={handleLogout} icon="log-out">
            <FormattedMessage id="LOGOUT" />
          </ListItemAction>
        ) : null}
      </View>
      <FeedbackCard />
      <Revision style={styles.revision} />
    </View>
  );
}
