import React, { ComponentProps, ReactElement, ReactNode } from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import { TouchableRipple } from 'react-native-paper';
import styles from './SectionListItem.styles';
import { CareshipColors } from '../CSTheme';
import CellLabel from '../CellLabel';
import Text from '../Text';

interface SectionListItemProps {
  title: ReactElement;
  label?: ReactNode;
  labelColor?: string;
  columnTitle?: ReactNode;
  columnTitleColor?: string;
  labelMode?: ComponentProps<typeof CellLabel>['mode'];
  highlighted?: boolean;
  onPress?: () => void;
  style?: StyleProp<ViewStyle>;
  children?: ReactNode;
  testID?: string;
}

export default function SectionListItem({
  onPress,
  label,
  labelMode,
  labelColor,
  title,
  highlighted,
  children,
  columnTitle,
  columnTitleColor = CareshipColors.slate400,
  style,
  testID,
}: SectionListItemProps) {

  return (
    <View style={[styles.wrapper, style]} testID={testID}>
      <TouchableRipple
        disabled={typeof onPress !== 'function'}
        rippleColor={CareshipColors.rippleColor}
        style={[styles.touchable]}
        onPress={onPress}
      >
        <View style={styles.content}>
          {highlighted && <View style={styles.activeBorder} />}
          <View style={styles.firstRow}>
            {columnTitle ? (
              <Text
                uppercase
                size="small"
                bold
                style={{ color: columnTitleColor, alignSelf: 'center' }}
              >
                {columnTitle}
              </Text>
            ) : (
              title
            )}
            {label && (
              <CellLabel mode={labelMode} color={labelColor} style={styles.cellLabel}>
                {label}
              </CellLabel>
            )}
          </View>
          {!!columnTitle && <View style={styles.pushedDownFirstRow}>{title}</View>}
          <View style={styles.secondRow}>{children}</View>
        </View>
      </TouchableRipple>
    </View>
  );
}
