import { graphql } from 'react-relay';

export default graphql`
  query fetchSingleContractQuery($contractId: ID!) {
    contract(contractId: $contractId) {
      id
      hasFulfilledBooking
      cancelationScheduledFor
      firstBookingStatus
      firstBookingConfirmationStatus {
        reason
      }
    }
  }
`;
