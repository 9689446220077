import { useEffect, useState } from 'react';
import { reaction } from 'mobx';
import { useStores } from '../../../Infrastructure/Store';
import useDefinitionService from '../../Definition/Hook/useDefinitionService';
import Service from '../Store/Model/Service';

export default function useServiceDefinitions(): Service[] {
  const stores = useStores();
  const [serviceList, setServiceList] = useState<Service[]>([]);
  const serviceService = useDefinitionService();

  useEffect(
    () =>
      reaction(
        () => ({
          services: stores.definitionStore.services,
        }),
        ({ services }) => {
          setServiceList(services);
        },
        {
          fireImmediately: true,
        },
      ),
    [stores.definitionStore],
  );

  useEffect(() => {
    void serviceService.fetchServiceList();
  }, [serviceService]);
  //
  return serviceList;
}
