import { Platform } from 'react-native';
import nativeOpenMap, { createMapLink } from 'react-native-open-maps';

export default function openMap(destinationAddress: string) {
  if (Platform.OS === 'web') {
    window.open(
      createMapLink({
        end: destinationAddress,
        provider: 'google',
      }),
      '_blank',
    );
  } else {
    nativeOpenMap({
      end: destinationAddress,
    });
  }
}
