/**
 * @generated SignedSource<<9b60e726410e1e4f82d80bb40a0abf2c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AvailabilityScreenQuery$variables = {};
export type AvailabilityScreenQuery$data = {
  readonly me: {
    readonly " $fragmentSpreads": FragmentRefs<"BookedOutFragment" | "AbsencesFragment" | "TravelDistanceFragment">;
  };
};
export type AvailabilityScreenQuery = {
  variables: AvailabilityScreenQuery$variables;
  response: AvailabilityScreenQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AvailabilityScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "BookedOutFragment"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "AbsencesFragment"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "TravelDistanceFragment"
              }
            ],
            "type": "Caregiver",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AvailabilityScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "bookedOut",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "AbsenceConnection",
                "kind": "LinkedField",
                "name": "absences",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AbsenceEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Absence",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v0/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "from",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "to",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "reason",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "kind": "ClientExtension",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__id",
                        "storageKey": null
                      }
                    ]
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "travelDistance",
                "storageKey": null
              }
            ],
            "type": "Caregiver",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v0/*: any*/)
            ],
            "type": "Node",
            "abstractKey": "__isNode"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3561ea0dae215b1a367167225e3935d0",
    "id": null,
    "metadata": {},
    "name": "AvailabilityScreenQuery",
    "operationKind": "query",
    "text": "query AvailabilityScreenQuery {\n  me {\n    __typename\n    ... on Caregiver {\n      ...BookedOutFragment\n      ...AbsencesFragment\n      ...TravelDistanceFragment\n    }\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n}\n\nfragment AbsencesFragment on Caregiver {\n  absences {\n    edges {\n      node {\n        id\n        from\n        to\n        reason\n      }\n    }\n  }\n  id\n}\n\nfragment BookedOutFragment on Caregiver {\n  id\n  bookedOut\n}\n\nfragment TravelDistanceFragment on Caregiver {\n  id\n  travelDistance\n}\n"
  }
};
})();

(node as any).hash = "d71d1f301a61e1c184063668a92cc783";

export default node;
