import React from 'react';
import { RadioButton } from 'react-native-paper';

type Props = {
  onValueChange: (value: string) => void;
  value?: string;
  children: React.ReactNode;
};

export type RadioButtonContextType = {
  value?: string;
  onValueChange: (item: string) => void;
};

export const RadioButtonContext = React.createContext<RadioButtonContextType | null>(null);

/**
 * Adding an extra level of context provider to enable clickable
 * rows with additional styling of the label
 */
export default function RadioButtonGroup({ value, onValueChange, children }: Props) {
  return (
    <RadioButtonContext.Provider value={{ value, onValueChange }}>
      <RadioButton.Group value={value || ''} onValueChange={onValueChange}>
        {children}
      </RadioButton.Group>
    </RadioButtonContext.Provider>
  );
}
