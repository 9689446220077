import React, { ReactElement, useCallback, useEffect } from 'react';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { Platform } from 'react-native';
import { Feather } from '@expo/vector-icons';
import { FormattedMessage, useIntl } from 'react-intl';
import ModalHeader from '../../Component/ModalHeader';
import { CareshipColors } from '../../Component/CSTheme';
import { Container, Title } from '../../Component';
import useBookings from '../../../Context/Booking/Hook/useBookings';
import useBooking from '../../../Context/Booking/Hook/useBooking';
import { AppNavigationParamList } from '../../Root/Navigator/AppNavigator';
import EditBookingForm from '../Component/EditBookingForm/EditBookingForm';
import useEditBooking from '../../../Context/Booking/Hook/useEditBooking';
import { BookingStackNavigationProp } from '../Navigator/BookingStackNavigationProp';
import { withLayout } from '../../HOC';

export default withLayout(function EditBookingScreen(): ReactElement {
  const intl = useIntl();
  const navigation = useNavigation<BookingStackNavigationProp<'Diary'>>();
  const route = useRoute<RouteProp<AppNavigationParamList, 'EditAppointment'>>();
  const { refresh } = useBookings();
  const { submitBooking, isSuccess, error, isLoading } = useEditBooking();
  const { booking, contract } = useBooking(route.params.bookingId);

  useEffect(() => {
    if (isSuccess) {
      refresh();
      navigation.goBack();
    }
  }, [refresh, isSuccess, navigation]);

  const handleBookingDeleted = useCallback(() => navigation.navigate('Diary'), [navigation]);
  const { lastName, firstName } = contract?.careReceiver.name || {};

  return (
    <>
      <ModalHeader
        backButton={<Feather name="x" size={24} color={CareshipColors.primary} />}
        title={intl.formatMessage({ id: 'EDIT_APPOINTMENT_MODAL_TITLE' })}
      />
      <Container vertical>
        <Title style={{ marginTop: Platform.OS === 'android' ? 32 : undefined }} center thin>
          <FormattedMessage id="NAME_FULL" values={{ firstName, lastName }} />
        </Title>
      </Container>
      {booking && contract && (
        <EditBookingForm
          onBookingDeleted={handleBookingDeleted}
          booking={{
            ...booking,
            endDate: new Date(booking.endDate),
            startDate: new Date(booking.startDate),
          }}
          careReceiver={contract.careReceiver}
          isLoading={isLoading}
          error={error}
          onSubmit={submitBooking}
        />
      )}
    </>
  );
});
