import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';
import { CareshipColors } from '../CSTheme';

interface CalendarCheckIconProps extends SvgProps {
  color?: string;
  size?: number;
}

function CalendarCheckIcon({
  color = CareshipColors.slate400,
  size = 24,
  ...props
}: CalendarCheckIconProps) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" {...props}>
      <Path
        d="M20.75 3A2.25 2.25 0 0123 5.25v15.5A2.25 2.25 0 0120.75 23H3.25A2.25 2.25 0 011 20.75V5.25A2.25 2.25 0 013.25 3zm.75 6.25h-19v11.5c0 .38.282.693.648.743l.102.007h17.5a.75.75 0 00.75-.75V9.25zm-.75-4.75H3.25a.75.75 0 00-.75.75v2.5h19v-2.5a.75.75 0 00-.648-.743L20.75 4.5zm-14-4a.75.75 0 01.743.648l.007.102V5.5a.75.75 0 01-1.493.102L6 5.5V1.25A.75.75 0 016.75.5zm10.5 0a.75.75 0 01.743.648L18 1.25V5.5a.75.75 0 01-1.493.102L16.5 5.5V1.25a.75.75 0 01.75-.75zm-1.6 11.3a.75.75 0 011.26.807l-.06.093-4.5 6a.75.75 0 01-1.046.154l-.084-.074-3-3a.75.75 0 01.976-1.133l.084.073 2.389 2.388L15.65 11.8z"
        fill={color}
      />
    </Svg>
  );
}

export default CalendarCheckIcon;
