import * as React from 'react';
import Svg, { Path, Rect, SvgProps } from 'react-native-svg';

function UpcomingIllustration(props: SvgProps) {
  return (
    <Svg viewBox="0 0 341.91 205.78" {...props}>
      <Path
        data-name="Fill-1"
        d="M238.14 102.8c0 5.8-3.27 10.51-7.31 10.51s-7.3-4.71-7.3-10.51 3.27-10.51 7.3-10.51 7.31 4.71 7.31 10.51"
        fill="#cce3ff"
        fillRule="evenodd"
      />
      <Path
        data-name="Fill-3"
        fill="#dceeff"
        fillRule="evenodd"
        d="M242.73 94.87h-23.79l4.28-24.41h15.23l4.28 24.41z"
      />
      <Path
        data-name="Fill-5"
        fill="#b6d4ff"
        fillRule="evenodd"
        d="M182.82 132.49h38.14v-22.51h-38.14v22.51z"
      />
      <Path
        data-name="Fill-7"
        fill="#dceeff"
        fillRule="evenodd"
        d="M186.21 128.31h31.56v-14.83h-31.56v14.83z"
      />
      <Path
        data-name="Fill-8"
        fill="#b6d4ff"
        fillRule="evenodd"
        d="M184.01 205.17h-3.21l7.4-72.68h5.67l-9.86 72.68z"
      />
      <Path
        data-name="Fill-9"
        fill="#b6d4ff"
        fillRule="evenodd"
        d="M208.67 205.17h-3.21l4.93-72.68h5.67l-7.39 72.68z"
      />
      <Path
        data-name="Fill-10"
        fill="#dceeff"
        fillRule="evenodd"
        d="M235.46 205.17h-3.21l-7.4-72.68h5.68l4.93 72.68z"
      />
      <Path
        data-name="Fill-11"
        fill="#dceeff"
        fillRule="evenodd"
        d="M260.11 205.17h-3.21l-9.86-72.68h5.68l7.39 72.68z"
      />
      <Path
        data-name="Fill-12"
        fill="#dceeff"
        fillRule="evenodd"
        d="M220.96 132.5h37.17v-22.52h-37.17v22.52z"
      />
      <Path
        data-name="Fill-13"
        d="M210.56 113.48a4.08 4.08 0 01-4.07 4.08h-9a4.08 4.08 0 01-4.08-4.08z"
        fill="#cce3ff"
        fillRule="evenodd"
      />
      <Path
        data-name="Fill-14"
        d="M218.6 106.84v.16a3 3 0 01-3 3h-24.69v-6.15h24.69a3 3 0 013 3"
        fill="#cce3ff"
        fillRule="evenodd"
      />
      <Path
        data-name="Fill-15"
        d="M201.84 106.87v.07a1.74 1.74 0 01-1.74 1.74H191v-3.49h9.15a1.75 1.75 0 011.74 1.75"
        fill="#dceeff"
        fillRule="evenodd"
      />
      <Path
        data-name="Fill-1"
        d="M323.92 95.9c0 8.62-5.23 15.61-11.68 15.61s-11.68-7-11.68-15.61 5.23-15.61 11.68-15.61 11.68 7 11.68 15.61"
        fill="#cce3ff"
        fillRule="evenodd"
      />
      <Path
        data-name="Stroke-3"
        fill="none"
        stroke="#dceeff"
        strokeWidth={2.97}
        d="M312.33 177.89v-81.2"
      />
      <Path
        data-name="Fill-5"
        d="M299.75 118.83c5.51 5.53 6.63 13.37 2.49 17.52s-12 3-17.48-2.5-6.63-13.38-2.49-17.52 12-3 17.48 2.5"
        fill="#cce3ff"
        fillRule="evenodd"
      />
      <Path
        data-name="Fill-8"
        d="M339.13 130.19c4.45 4.46 3.25 12.89-2.68 18.84s-14.35 7.14-18.79 2.69"
        fill="#cce3ff"
        fillRule="evenodd"
      />
      <Path
        data-name="Stroke-10"
        fill="none"
        stroke="#dceeff"
        strokeWidth={2.97}
        d="M333.14 138.04l-20.47 20.58"
      />
      <Path
        data-name="Stroke-12"
        fill="none"
        stroke="#dceeff"
        strokeWidth={2.97}
        d="M327.23 118.37l-14.96 14.81"
      />
      <Path
        data-name="Stroke-14"
        fill="none"
        stroke="#dceeff"
        strokeWidth={2.97}
        d="M296.74 150.89l15.62 15.84"
      />
      <Path
        data-name="Stroke-16"
        fill="none"
        stroke="#dceeff"
        strokeWidth={2.97}
        d="M291.35 125.54l20.89 21.18"
      />
      <Path
        data-name="Fill-18"
        d="M318.59 205.78h-12.53a17.56 17.56 0 01-17.49-17.54v-16.5h47.51v16.5a17.56 17.56 0 01-17.49 17.54"
        fill="#ecf6ff"
        fillRule="evenodd"
      />
      <Path
        data-name="Stroke-20"
        fill="none"
        stroke="#dceeff"
        strokeWidth={2.97}
        d="M336.08 180.91h-47.51"
      />
      <Path
        data-name="Stroke-22"
        fill="none"
        stroke="#dceeff"
        strokeWidth={2.97}
        d="M336.08 190.11h-47.51"
      />
      <Path
        data-name="Fill-1"
        fill="#dceeff"
        fillRule="evenodd"
        d="M311.89.75l-3.12 31.17h-40.98L270.91.75h40.98z"
      />
      <Path
        data-name="Stroke-2"
        fill="none"
        stroke="#dceeff"
        strokeWidth={1.5}
        d="M311.89.75l-3.12 31.17h-40.98L270.91.75h40.98z"
      />
      <Path
        data-name="Fill-4"
        fill="#fff"
        fillRule="evenodd"
        d="M310.98.75l-3.13 31.17h-41.74L269.23.75h41.75z"
      />
      <Path
        data-name="Stroke-5"
        fill="none"
        stroke="#dceeff"
        strokeWidth={1.5}
        d="M310.98.75l-3.13 31.17h-41.74L269.23.75h41.75z"
      />
      <Path
        data-name="Fill-7"
        fill="#cce3ff"
        fillRule="evenodd"
        d="M302.17 6.87l-1.89 18.93h-25.37l1.9-18.93h25.36z"
      />
      <Path
        data-name="Fill-8"
        fill="#dceeff"
        fillRule="evenodd"
        d="M225.58 32.77h5.57V11.01h-5.57v21.76z"
      />
      <Path
        data-name="Fill-9"
        fill="#cce3ff"
        fillRule="evenodd"
        d="M220.01 32.77h5.57V11.01h-5.57v21.76z"
      />
      <Path
        data-name="Fill-10"
        fill="#cce3ff"
        fillRule="evenodd"
        d="M231.15 32.77h5.57V11.01h-5.57v21.76z"
      />
      <Path
        data-name="Fill-11"
        d="M251.27 32.77h-14.55V11h14.55a1.54 1.54 0 011.54 1.54v18.69a1.54 1.54 0 01-1.54 1.54"
        fill="#b6d4ff"
        fillRule="evenodd"
      />
      <Path
        data-name="Fill-13"
        fill="#cce3ff"
        fillRule="evenodd"
        d="M239.1 16.34h11.33v-2.16H239.1v2.16z"
      />
      <Path
        data-name="Fill-15"
        fill="#cce3ff"
        fillRule="evenodd"
        d="M241.63 19.66h6.27V17.5h-6.27v2.16z"
      />
      <Path
        data-name="Fill-16"
        fill="#dceeff"
        fillRule="evenodd"
        d="M194.07 38.1h126.95v-5.33H194.07v5.33z"
      />
      <Path
        data-name="Fill-17"
        fill="#cce3ff"
        fillRule="evenodd"
        d="M321.02 38.1h20.89v-5.33h-20.89v5.33z"
      />
      <Path
        data-name="Fill-18"
        fill="#b6d4ff"
        fillRule="evenodd"
        d="M225.01 38.1l9.92 15.55V38.1h-9.92z"
      />
      <Path
        data-name="Fill-19"
        fill="#cce3ff"
        fillRule="evenodd"
        d="M219.53 38.1l9.91 15.55h5.49l-9.92-15.55h-5.48z"
      />
      <Path
        data-name="Fill-20"
        fill="#b6d4ff"
        fillRule="evenodd"
        d="M297.73 38.1l9.91 15.55V38.1h-9.91z"
      />
      <Path
        data-name="Fill-21"
        fill="#cce3ff"
        fillRule="evenodd"
        d="M292.24 38.1l9.91 15.55h5.49l-9.91-15.55h-5.49z"
      />
      <Path
        data-name="Fill-1"
        fill="#dceeff"
        fillRule="evenodd"
        d="M91.53 51.3H41.42V9.2h50.11v42.1z"
      />
      <Path
        data-name="Stroke-2"
        fill="none"
        stroke="#dceeff"
        strokeWidth={2}
        d="M91.53 51.3H41.42V9.2h50.11v42.1z"
      />
      <Path
        data-name="Fill-4"
        fill="#fff"
        fillRule="evenodd"
        d="M94.47 51.36h-50.1V9.26h50.1v42.1z"
      />
      <Path
        data-name="Stroke-5"
        fill="none"
        stroke="#dceeff"
        strokeWidth={2}
        d="M94.47 51.36h-50.1V9.26h50.1v42.1z"
      />
      <Path
        data-name="Fill-7"
        fill="#dceeff"
        fillRule="evenodd"
        d="M85.94 42.66H53.62V17.84h32.32v24.82z"
      />
      <Path
        data-name="Fill-1"
        fill="#b6d4ff"
        fillRule="evenodd"
        d="M26.84 180.11l-10.67 25.07h-4.85l6.43-26.3 9.09 1.23z"
      />
      <Path
        data-name="Fill-2"
        fill="#b6d4ff"
        fillRule="evenodd"
        d="M81.38 180.11L70.7 205.18h-4.84l6.43-26.3 9.09 1.23z"
      />
      <Path
        data-name="Fill-3"
        fill="#b6d4ff"
        fillRule="evenodd"
        d="M162.84 205.18H158l-10.67-25.07 9.09-1.23 6.42 26.3z"
      />
      <Path
        data-name="Fill-4"
        fill="#b6d4ff"
        fillRule="evenodd"
        d="M108.31 205.18h-4.85l-10.67-25.07 9.09-1.23 6.43 26.3z"
      />
      <Path
        data-name="Fill-5"
        d="M156.89 121.43v60.71l-63.14.13-10.38-60.05zm-4.81 4.85l-63 .68 8.73 50.5 54.28-.11z"
        fill="#b6d4ff"
        fillRule="evenodd"
      />
      <Path
        data-name="Fill-6"
        fill="#cce3ff"
        fillRule="evenodd"
        d="M74.04 84.11l16.97 98.17H16.97L0 84.11h74.04z"
      />
      <Path
        data-name="Fill-7"
        fill="#dceeff"
        fillRule="evenodd"
        d="M93.95 84.11l16.97 98.17H37.78L20.81 84.11h73.14z"
      />
      <Path
        data-name="Fill-8"
        d="M112.08 182.28H25a8.05 8.05 0 01-8-8.05v-18.54a8 8 0 018.05-8h87.06a8 8 0 018 8v18.54a8.05 8.05 0 01-8 8.05"
        fill="#cce3ff"
        fillRule="evenodd"
      />
      <Path
        data-name="Fill-10"
        fill="#dceeff"
        fillRule="evenodd"
        d="M157.22 182.28H80.11v-34.63h77.11v34.63z"
      />
      <Path
        data-name="Fill-12"
        d="M80.11 121.43v60.71l-63.11.13-10.41-60.05zm-4.81 4.85l-63 .68 8.7 50.5 54.28-.11z"
        fill="#b6d4ff"
        fillRule="evenodd"
      />
      <Path
        d="M103.55 50.16c2.87 5.37 2.53 19-1.19 20-1.48.38-4.63-.55-7.85-2.5l2 14.3H84.63l4.1-19.14a13.48 13.48 0 01-3.58-9.68c.75-10.7 15.52-8.35 18.4-2.98z"
        fill="#ffb300"
        fillRule="evenodd"
      />
      <Path
        data-name="hair"
        d="M98.84 44.31a15.53 15.53 0 00-5-.56 8.56 8.56 0 00.61-4.34 13.23 13.23 0 00-1.41-3.92 5.34 5.34 0 00-1-1.63 3.54 3.54 0 00-1.68-.9 4.34 4.34 0 00-1.93 0c-.28.06-.54.19-.81.26s-.42-.11-.63-.28a3.47 3.47 0 00-1.84-.72 4.67 4.67 0 00-2.1.25 4.12 4.12 0 00-1.75 1.12 3.15 3.15 0 00-.53.81c0 .12-.06.19-.18.24s-.24 0-.36.08a4.56 4.56 0 00-2.61 2.37 7.62 7.62 0 00-.41 3.09 13.5 13.5 0 002.28 5.87 7.33 7.33 0 003.77 2.13 10.86 10.86 0 00-2 3.67 17.83 17.83 0 00-.9 4.47 15.09 15.09 0 00.22 4.4 4.77 4.77 0 002.13 3.1 10.25 10.25 0 001.64.78 11.08 11.08 0 001.69.54 3.35 3.35 0 003.2-.69 5.43 5.43 0 00.86-1.1c.08-.13.13-.23 0-.36a2.2 2.2 0 00-.45-.41 4.3 4.3 0 01-1.51-2.84c-.14-1.17.18-2.67 1.31-3.18 1.39-.63 2.93.1 4.16.77a6.84 6.84 0 013.39-4.4c1.87-1 4-1.06 6-1.87a3.51 3.51 0 001.39-.88 2 2 0 00.4-.65 1 1 0 00-.28-.82 15.11 15.11 0 00-5.67-4.4z"
        fill="#191847"
        fillRule="evenodd"
      />
      <Path
        data-name="Skin"
        d="M145.11 133.51h.06c4.1-2.39 10.88.75 10.88 4.25 0 10.53-9.59 60.13-10.1 63.26s-5.85 4-6.17 0c-.49-6.29-1.41-28.06-.49-38.14.38-4.16.79-8.13 1.19-11.66q-7.53 13.65-23.32 39.09l-5.71-2.62q6.72-22.22 11-31.38c4.92-10.45 9.53-19.4 11.47-22.55 3.08-4.99 8.39-3.26 11.19-.25z"
        fill="#ffb300"
        fillRule="evenodd"
      />
      <Path
        data-name="shoe"
        d="M111 184.55a11.34 11.34 0 00-2.62 2.67 24.09 24.09 0 00-1.58 3.62l24.23 14c1.83-1.73 2.12-3.06.86-4s-2.3-1.75-3.12-2.41l-8.44-11.65a.37.37 0 00-.5-.08l-1.83 1.42a9 9 0 01-3.57-.88 8.56 8.56 0 01-2.43-2.54.7.7 0 00-1-.18zM138.64 198.18a11.39 11.39 0 00-.94 3.62 25.82 25.82 0 00.44 3.93h28q1.09-3.61-1.26-3.91c-1.57-.19-2.87-.36-3.9-.52l-13.14-5.87a.36.36 0 00-.47.18l-.89 2.17a8.89 8.89 0 01-3.54 1 8.49 8.49 0 01-3.31-1 .71.71 0 00-1 .34s.02.04.01.06z"
        fill="#e4e4e4"
        fillRule="evenodd"
      />
      <Path
        data-name="Leg-Back"
        d="M111.86 179.44l19.6-46.57c4.7-7.57 17.87 1.55 16.26 5.94-3.63 10-22.12 42.26-23.21 45.23z"
        fill="#5e95a0"
        fillRule="evenodd"
      />
      <Path
        data-name="Leg-and-Butt"
        d="M157.42 141.3c0 13.21-6.25 44-6.26 46.93H137.7q3-41.24 2.09-41.68c-.86-.45-35.24 3.44-45.55 3.44-14.87 0-21-9.4-21.52-26.82h29.46c6 .65 37.28 7 48.9 9.15 4.97.94 6.34 5.34 6.34 8.98z"
        fill="#80bbc1"
        fillRule="evenodd"
      />
      <Path
        data-name="Skin"
        d="M53.63 111.75l10.16 2q-15.33 28.65-16.44 30.36c-1.67 2.58-.11 7.31.46 9.79-3.6.85-2.18-4.6-6.75-3.46-4.17 1-7.7 3.7-11.59.11-.47-.44-.82-1.82.81-2.53 4-1.76 10-5.07 11-6.35q2.02-2.6 12.35-29.92zm100.53-15.29c-1.6 2.34-3.18 4.87-2.54 5.69s2.49-.08 3.22 1.25q.74 1.34-9.35 3.19L132 116.94l-5.74-7.37 18-7.91a42.74 42.74 0 019.12-7.18c.88-.28 2.38-.36.78 1.98z"
        fill="#ffb300"
        fillRule="evenodd"
      />
      <Path
        data-name="Coat-Back"
        d="M91.42 73.63l4.29-.74c9.34 9.08 13.15 30.91 19.38 33.8 5.88 2.73 14.23.09 24.14-3.48l3 6.8c-9 9.77-28.21 18-36 13.32-12.39-7.54-15.23-34.46-14.81-49.7z"
        fill="#e87613"
        fillRule="evenodd"
      />
      <Path
        data-name="Shirt"
        d="M72.72 127.66h38c1.74 0 1.41-2.51 1.16-3.78-2.89-14.62-14.19-30.38-14.19-51.19L86.7 71c-9.06 14.57-12.19 32.55-13.98 56.66z"
        fill="#dde3e9"
        fillRule="evenodd"
      />
      <Path
        data-name="Coat-Front"
        d="M90.32 71q14.75 50.39 8.13 67.13H64.29a125 125 0 014.65-25.25q-11.61 21-19.51 30.22l-8-1.56Q49.16 93.72 86 71z"
        fill="#e87613"
        fillRule="evenodd"
      />
      <Rect
        data-name="Rectangle"
        x={147.86}
        y={86.59}
        width={19.6}
        height={9.8}
        rx={1.78}
        transform="rotate(-54 157.656 91.491)"
        fill="#191847"
      />
      <Path
        data-name="Rectangle"
        d="M160 83.31l4.62 3.39a.88.88 0 01.19 1.23l-8.32 11.54a.88.88 0 01-1.24.2l-4.61-3.38a.88.88 0 01-.2-1.24l8.32-11.54a.9.9 0 011.25-.2z"
        fill="#8991dc"
        fillRule="evenodd"
      />
      <Path
        data-name="Combined-Shape"
        d="M161.89 51.76a1.1 1.1 0 011.55-.1l2 1.73 1.73-2a1.1 1.1 0 011.66 1.45l-1.73 2 2 1.74a1.1 1.1 0 01-1.44 1.66l-2-1.74-1.73 2a1.1 1.1 0 11-1.67-1.5l1.73-2-2-1.73a1.11 1.11 0 01-.1-1.51z"
        fill="#ff9b21"
        fillRule="evenodd"
      />
      <Path
        data-name="Combined-Shape-Copy"
        d="M158.71 64.84a.9.9 0 01.9.9v2.16h2.17a.9.9 0 010 1.8h-2.17v2.16a.9.9 0 01-1.8 0V69.7h-2.16a.9.9 0 010-1.8h2.16v-2.16a.9.9 0 01.9-.9z"
        fill="#ff9b21"
        fillRule="evenodd"
      />
      <Path
        data-name="Combined-Shape"
        d="M153.87 53.83a3.53 3.53 0 11-3.52 3.52 3.52 3.52 0 013.52-3.52zm0 1.76a1.76 1.76 0 101.76 1.76 1.76 1.76 0 00-1.76-1.76z"
        fill="#bedadd"
        fillRule="evenodd"
      />
      <Path
        data-name="Combined-Shape-Copy-4"
        d="M158.93 43.27a2.42 2.42 0 11-2.42 2.42 2.42 2.42 0 012.42-2.42zm0 1.21a1.21 1.21 0 101.21 1.21 1.21 1.21 0 00-1.21-1.21z"
        fill="#bedadd"
        fillRule="evenodd"
      />
      <Path
        data-name="Path-5"
        d="M146.37 96.5q4.45-4.5 6.23-4.5h3.57q.89 1.78-2.68 1.79c-.89 0-2.21 1.06-2.67 2.67a4.22 4.22 0 00.89 3.56z"
        fill="#ffb300"
        fillRule="evenodd"
      />
      <Path
        data-name="Fill-12"
        d="M81 120.5V182l-63.29.14L7.27 121.3zm-5.5 5.5l-62.7.68 8.69 51 54-.12z"
        fill="#b6d4ff"
        fillRule="evenodd"
      />
    </Svg>
  );
}

export default UpcomingIllustration;
