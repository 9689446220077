/**
 * @generated SignedSource<<b4eb4c933582b7f30a68cef58558a46c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type FirstBookingStatus = "confirmed" | "created" | "declined" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type FirstBookingDeclineForm_contract$data = {
  readonly careReceiver: {
    readonly name: {
      readonly firstName: string;
      readonly lastName: string;
    };
  };
  readonly firstBookingStatus: FirstBookingStatus;
  readonly firstBookingConfirmationStatus: {
    readonly reason: string;
  } | null;
  readonly firstBooking: {
    readonly bookingId: string;
  } | null;
  readonly " $fragmentType": "FirstBookingDeclineForm_contract";
};
export type FirstBookingDeclineForm_contract$key = {
  readonly " $data"?: FirstBookingDeclineForm_contract$data;
  readonly " $fragmentSpreads": FragmentRefs<"FirstBookingDeclineForm_contract">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FirstBookingDeclineForm_contract",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CareReceiver",
      "kind": "LinkedField",
      "name": "careReceiver",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Name",
          "kind": "LinkedField",
          "name": "name",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "firstName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lastName",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstBookingStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "firstBookingConfirmationStatus",
      "kind": "LinkedField",
      "name": "firstBookingConfirmationStatus",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "reason",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "isFirstBooking",
          "value": true
        }
      ],
      "concreteType": "Booking",
      "kind": "LinkedField",
      "name": "firstBooking",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "bookingId",
          "storageKey": null
        }
      ],
      "storageKey": "firstBooking(isFirstBooking:true)"
    }
  ],
  "type": "Contract",
  "abstractKey": null
};

(node as any).hash = "684c18d52162fc95ac2505c4027a1d26";

export default node;
