import React, { useEffect } from 'react';
import { useQueryLoader } from 'react-relay/hooks';
import { Layout } from '../../Component';
import ContactAndAddress, { contactAndAddressQuery } from '../Component/ContactAndAddress';
import { ContactAndAddressQuery } from '../Component/__generated__/ContactAndAddressQuery.graphql';

export default function ContactAndAddressScreen() {
  const [preloadedQuery, loadQuery] =
    useQueryLoader<ContactAndAddressQuery>(contactAndAddressQuery);

  useEffect(() => {
    loadQuery(
      {},
      {
        fetchPolicy: 'store-and-network',
      },
    );
  }, [loadQuery]);

  return <Layout>{preloadedQuery && <ContactAndAddress preloadedQuery={preloadedQuery} />}</Layout>;
}
