import React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';
import { CareshipColors } from '../CSTheme';

type CompanyIconProps = SvgProps & {
  color?: string;
  size?: number;
};

export default function CompanyIcon({
  color = CareshipColors.slate400,
  size = 24,
  ...props
}: CompanyIconProps) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" {...props}>
      <Path
        d="M20.25 1H3.75A2.75 2.75 0 0 0 1 3.75v16.5A2.75 2.75 0 0 0 3.75 23h16.5A2.75 2.75 0 0 0 23 20.25V3.75A2.75 2.75 0 0 0 20.25 1ZM3.75 2.5h16.5c.69 0 1.25.56 1.25 1.25v16.5c0 .69-.56 1.25-1.25 1.25H3.75c-.69 0-1.25-.56-1.25-1.25V3.75c0-.69.56-1.25 1.25-1.25Zm.536 11.641v-2.015h1.56v-.767h-1.56v-1.092h1.658V9.5H3.35v4.641h.936Zm3.38 0V9.5H6.73v4.641h.936Zm1.957 0v-1.963h.5c.195 0 .334.052.416.156a.732.732 0 0 1 .136.429l.026.89c.005.091.013.18.026.264.014.084.044.159.091.224h1.014v-.039c-.086-.048-.143-.136-.168-.267a6.267 6.267 0 0 1-.04-.565 42.73 42.73 0 0 0-.013-.341 4.983 4.983 0 0 0-.019-.283c-.026-.26-.093-.449-.202-.566-.108-.117-.279-.192-.513-.227v-.013c.264-.056.46-.183.585-.38.126-.197.188-.452.188-.764 0-.403-.108-.703-.325-.9-.216-.197-.52-.296-.91-.296H8.688v4.641h.936Zm.39-2.613h-.39v-1.339h.442c.433 0 .65.214.65.643 0 .252-.061.43-.182.537-.122.106-.295.159-.52.159Zm3.49 2.613v-3.705h.013l.825 3.705h.729l.825-3.705h.013v3.705h.858V9.5h-1.384l-.67 3.276h-.013L14.036 9.5h-1.391v4.641h.858Zm4.907 0 .215-.982h1.235l.215.982h.974L19.821 9.5h-1.157l-1.229 4.641h.976Zm1.288-1.748h-.91l.448-2.087h.013l.449 2.087Z"
        fill={color}
      />
    </Svg>
  );
}
