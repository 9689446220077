/**
 * @generated SignedSource<<a2d1b4ed6dcd195ae17b37ffc82ba2ca>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type BookedOutMutation$variables = {
  bookedOut: boolean;
};
export type BookedOutMutation$data = {
  readonly setCaregiverBookedOut: {
    readonly bookedOut: boolean;
  } | null;
};
export type BookedOutMutation = {
  variables: BookedOutMutation$variables;
  response: BookedOutMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "bookedOut"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "bookedOut",
        "variableName": "bookedOut"
      }
    ],
    "concreteType": "BookedOutPayload",
    "kind": "LinkedField",
    "name": "setCaregiverBookedOut",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "bookedOut",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BookedOutMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BookedOutMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "f14d8e5d05255738b7f41fb9b5cf92a4",
    "id": null,
    "metadata": {},
    "name": "BookedOutMutation",
    "operationKind": "mutation",
    "text": "mutation BookedOutMutation(\n  $bookedOut: Boolean!\n) {\n  setCaregiverBookedOut(bookedOut: $bookedOut) {\n    bookedOut\n  }\n}\n"
  }
};
})();

(node as any).hash = "5508d83a2de73bc9f86b673c9f1d54e6";

export default node;
