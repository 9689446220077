import { useState } from 'react';
import Booking from '../../../Context/Booking/Store/Model/Booking';
import useBookingService from '../../../Context/Booking/Hook/useBookingService';
import { intl } from '../../../Infrastructure/Intl';
import { isError } from '../../../Infrastructure/Service';
import { useShowAlert } from '../../Dialog';

export default function useCancelBooking(booking: Pick<Booking, 'startDate' | 'bookingId'>, cancellationType: string, cancellationReason: string, extraText: string): {
  cancelBooking: () => Promise<boolean>;
  isCancelling: boolean;
} {
  const [isCancelling, setIsCancelling] = useState(false);
  const bookingService = useBookingService();
  const showAlert = useShowAlert();
  const { bookingId } = booking;

  const cancelBooking = async () => {
    setIsCancelling(true);

    const result = await bookingService.cancelBooking(bookingId, cancellationType, cancellationReason, extraText);

    if (isError(result)) {
      showAlert(intl.formatMessage({ id: 'ERROR_RESPONSE' }, { errorCode: result.errorCode }));
      setIsCancelling(false);

      return false;
    }

    setIsCancelling(false);

    return true;
  };

  return { isCancelling, cancelBooking };
}
