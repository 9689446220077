import React from 'react';
import FirstCallChecklist from '../Component/FirstCallChecklist/FirstCallChecklist';
import { Layout } from '../../Component';

export default function FirstCallChecklistScreen() {
  return (
    <Layout>
      <FirstCallChecklist />
    </Layout>
  );
}
