import React, { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { AuthLayout, Card, Container, Text, Title } from '../../Component';
import { LoginForm, AuthCard, AuthTitle } from '../Component';
import { CareshipColors } from '../../Component/CSTheme';

export default function LoginScreen(): ReactElement {
  return (
    <AuthLayout>
      <AuthCard mode="raised">
        <AuthTitle>
          <FormattedMessage id="CAREGIVER_LOGIN" />
        </AuthTitle>
        <Container>
          <Card mode="contained">
            <Title size="h5" marginBottom="base">
              <FormattedMessage id="APPLICANT_LOGIN_INFO_TITLE" />
            </Title>
            <Text>
              <FormattedMessage
                id="APPLICANT_LOGIN_INFO"
                values={{
                  link: (
                    <a
                      href="https://www.careship.de/bewerberportal"
                      style={{ color: CareshipColors.primary }}
                    >
                      <FormattedMessage id="APPLICANT_LOGIN_INFO_LINK" />
                    </a>
                  ),
                }}
              />
            </Text>
          </Card>
        </Container>
        <LoginForm />
      </AuthCard>
    </AuthLayout>
  );
}
