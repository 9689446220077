import * as Sentry from 'sentry-expo';
import Constants from 'expo-constants';

Sentry.init({
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
  dsn:  Constants.expoConfig?.extra?.sentryDSN,
  debug: ! Constants.expoConfig?.releaseChannel,
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
  release:  Constants.expoConfig?.extra?.commitHash,

});

// Sentry.Browser is empty in native and Native is empty in browser environments
export default { ...Sentry.Browser, ...Sentry.Native };
