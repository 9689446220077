import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';
import { CareshipColors } from '../CSTheme';

interface ClipboardListIconProps extends SvgProps {
  color?: string;
  size?: number;
}

function ClipboardListIcon({
  color = CareshipColors.slate400,
  size = 24,
  ...props
}: ClipboardListIconProps) {
  return (
    <Svg width={size} height={size} viewBox="0 0 18 22" {...props}>
      <Path
        d="M12,0 C13.1045695,0 14,0.8954305 14,2 L15,2 C16.6568542,2 18,3.34314575 18,5 L18,5 L18,19 C18,20.6568542 16.6568542,22 15,22 L15,22 L3,22 C1.34314575,22 0,20.6568542 0,19 L0,19 L0,5 C0,3.34314575 1.34314575,2 3,2 L3,2 L4,2 C4,0.8954305 4.8954305,0 6,0 L12,0 Z M4,4 L3,4 C2.44771525,4 2,4.44771525 2,5 L2,5 L2,19 C2,19.5522847 2.44771525,20 3,20 L3,20 L15,20 C15.5522847,20 16,19.5522847 16,19 L16,19 L16,5 C16,4.44771525 15.5522847,4 15,4 L15,4 L14,4 C14,5.1045695 13.1045695,6 12,6 L6,6 C4.8954305,6 4,5.1045695 4,4 Z M13.5,16 C14.0522847,16 14.5,16.4477153 14.5,17 C14.5,17.5128358 14.1139598,17.9355072 13.6166211,17.9932723 L13.5,18 L4.5,18 C3.94771525,18 3.5,17.5522847 3.5,17 C3.5,16.4871642 3.88604019,16.0644928 4.38337887,16.0067277 L4.5,16 L13.5,16 Z M13.5,12 C14.0522847,12 14.5,12.4477153 14.5,13 C14.5,13.5128358 14.1139598,13.9355072 13.6166211,13.9932723 L13.5,14 L4.5,14 C3.94771525,14 3.5,13.5522847 3.5,13 C3.5,12.4871642 3.88604019,12.0644928 4.38337887,12.0067277 L4.5,12 L13.5,12 Z M13.5,8 C14.0522847,8 14.5,8.44771525 14.5,9 C14.5,9.51283584 14.1139598,9.93550716 13.6166211,9.99327227 L13.5,10 L4.5,10 C3.94771525,10 3.5,9.55228475 3.5,9 C3.5,8.48716416 3.88604019,8.06449284 4.38337887,8.00672773 L4.5,8 L13.5,8 Z M12,2 L6,2 L6,4 L12,4 L12,2 Z"
        fill={color}
      />
    </Svg>
  );
}

export default ClipboardListIcon;
