import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';
import { CareshipColors } from '../CSTheme';

interface UserIconProps extends SvgProps {
  color?: string;
  size?: number;
}

function UserIcon({ color = CareshipColors.slate400, size = 24, ...props }: UserIconProps) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" {...props}>
      <Path
        d="M12 12.5c5.17 0 8.75 4.199 8.75 9.75a.75.75 0 0 1-1.5 0c0-4.771-2.967-8.25-7.25-8.25-4.283 0-7.25 3.479-7.25 8.25a.75.75 0 0 1-1.5 0c0-5.551 3.58-9.75 8.75-9.75ZM12 1a5.25 5.25 0 1 1 0 10.5A5.25 5.25 0 0 1 12 1Zm0 1.5a3.75 3.75 0 1 0 0 7.5 3.75 3.75 0 0 0 0-7.5Z"
        fill={color}
      />
    </Svg>
  );
}

export default UserIcon;
