import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';
import { CareshipColors } from '../CSTheme';

interface ShieldHealthIconProps extends SvgProps {
  color?: string;
  colorAccent?: string;
  size?: number;
}

function ShieldHealthIcon({
  color = CareshipColors.slate400,
  colorAccent = CareshipColors.primary,
  size = 24,
  ...props
}: ShieldHealthIconProps) {
  return (
    <Svg width={size} height={size} viewBox="0 0 25 25" {...props}>
      <Path
        d="M3.653 2.644A20.185 20.185 0 0111.993.92c2.874-.027 5.72.56 8.365 1.729a2.058 2.058 0 011.188 1.88v7.005c0 4.818-2.932 9.138-7.376 10.868l-.946.368a3.371 3.371 0 01-2.448 0l-.946-.368c-4.444-1.73-7.375-6.05-7.375-10.868V4.531a2.064 2.064 0 011.198-1.887zm.165 1.88v7.01c0 4.247 2.585 8.055 6.501 9.58l.947.368c.472.184.996.184 1.469 0l.946-.368c3.916-1.525 6.5-5.333 6.5-9.58v-7.01a.675.675 0 00-.38-.615A18.868 18.868 0 0011.995 2.3a18.84 18.84 0 00-7.785 1.604.679.679 0 00-.39.62z"
        fill={color}
      />
      <Path
        d="M10.778 4.75l-.131.006c-.69.066-1.23.648-1.23 1.355l-.001 2.305H7.111c-.752 0-1.361.61-1.361 1.362v2.444l.006.131c.066.69.648 1.23 1.355 1.23h2.305v2.306c0 .752.61 1.361 1.362 1.361h2.444l.131-.006a1.361 1.361 0 001.23-1.355v-2.306h2.306c.752 0 1.361-.61 1.361-1.36V9.777l-.006-.131a1.361 1.361 0 00-1.355-1.23l-2.306-.001V6.111c0-.752-.61-1.361-1.36-1.361h-2.445zm2.305 1.5v2.917l.007.101a.75.75 0 00.743.649l2.917-.001v2.167h-2.917l-.101.007a.75.75 0 00-.649.743v2.917h-2.167v-2.917l-.006-.101a.75.75 0 00-.743-.649H7.25V9.916h2.917l.101-.006a.75.75 0 00.649-.743l-.001-2.917h2.167z"
        fill={colorAccent}
      />
    </Svg>
  );
}

export default ShieldHealthIcon;
