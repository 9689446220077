import { formatISO } from 'date-fns';
import { useState } from 'react';
import useBookingService from './useBookingService';
import {
  ErrorResponse,
  isError,
  isSuccess as isSuccessGuard,
  SuccessResponse,
} from '../../../Infrastructure/Service/AuthorizedClient';
import useLogger from '../../../Infrastructure/Hook/useLogger';

type ErrorResponseTuple = {
  response: ErrorResponse;
  bookingStartDate: Date;
};

type SuccessResponseTuple = {
  response: SuccessResponse<''>;
  bookingStartDate: Date;
};

export default function useNewBooking() {
  const bookingService = useBookingService();
  const [errors, setErrors] = useState<ErrorResponseTuple[]>([]);
  const [successfulResponses, setSuccessfulResponses] = useState<SuccessResponseTuple[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>();
  const logger = useLogger();

  const submitBooking = (
    bookings: {
      contractId: string;
      startDate: Date;
      endDate: Date;
    }[],
  ) => {
    void (async () => {
      setIsLoading(true);

      const result = await Promise.all(
        bookings.map(async (booking) => {
          const { contractId, startDate, endDate } = booking;

          const response = await bookingService.createBooking({
            contractId,
            startDate: formatISO(startDate),
            endDate: formatISO(endDate),
          });

          if (!isError(response)) {
            logger.info(`Saved booking for contract ${contractId}`, {
              startDate,
              endDate,
            });
          } else {
            logger.error(`Failed to save booking for contract ${contractId}`, {
              startDate,
              endDate,
            });
          }

          return {
            response,
            bookingStartDate: startDate,
          };
        }),
      );

      setIsLoading(false);

      const errorResponses = result.filter((res): res is ErrorResponseTuple =>
        isError(res.response),
      );

      const successResponses = result.filter((res): res is SuccessResponseTuple =>
        isSuccessGuard(res.response),
      );

      setSuccessfulResponses(successResponses);
      setErrors(errorResponses);
      setIsSuccess(!errorResponses.length);
    })();
  };

  return {
    submitBooking,
    errors,
    successfulResponses,
    isLoading,
    isSuccess,
  };
}
