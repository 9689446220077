import React, { FC } from 'react';
import serviceContext, { ServicesType } from './serviceContext';
import AuthorizedClient from './AuthorizedClient';
import OauthService, { oauthClientWrapper } from './OauthService';
import stores from '../Store/stores';
import logger from './Logger';
import { userTokenStore } from '../Auth/OAuthTokenStore';

const oauthService = new OauthService(stores.sessionStore, userTokenStore, oauthClientWrapper);

export const services: ServicesType = {
  oauthService,
  authorizedClient: new AuthorizedClient(oauthService, logger),
};

const ServiceProvider: FC<{}> = ({ children }) => {
  return <serviceContext.Provider value={services}>{children}</serviceContext.Provider>;
};

export default ServiceProvider;
