import { ScrollView, View } from 'react-native';
import { FormattedMessage } from 'react-intl';
import React, { useCallback, useState } from 'react';
import { Feather } from '@expo/vector-icons';
import {
  Button,
  Container,
  DateTimeInterval,
  FormGroupTitle,
  Hr,
  InputErrorMessage,
  PressableWithConfirmation,
} from '../../../Component';
import styles from './EditBookingForm.styles';
import Booking from '../../../../Context/Booking/Store/Model/Booking';
import { NonNullableProperties } from '../../../../Infrastructure/types/NonNullableProperties';
import { ErrorResponse } from '../../../../Infrastructure/Service';
import { CareshipColors } from '../../../Component/CSTheme';
import { useDeleteBooking } from '../../Hook';
import CareReceiver from '../../../../Context/Contract/Store/Model/CareReceiver';
import Name from '../../../../Context/Contract/Store/Model/Name';
import { intl } from '../../../../Infrastructure/Intl';
import { events, trackEvent } from '../../../../Infrastructure/Tracking/Tracking';
import useProfile from "../../../../Context/Profile/Hook/useProfile";

type FormData = {
  bookingId: string | null;
  startDate: Date;
  endDate: Date;
};

type Props = {
  onSubmit: ({ bookingId, startDate, endDate }: NonNullableProperties<FormData>) => void;
  booking: Pick<Booking, 'startDate' | 'endDate' | 'bookingId' | 'isFirstBooking'>;
  careReceiver: Pick<CareReceiver, 'gender'> & { name: Pick<Name, 'lastName'> };
  isLoading?: boolean;
  error?: ErrorResponse;
  onBookingDeleted: () => void;
};

function validate(data: FormData): data is NonNullableProperties<FormData> {
  return data.bookingId !== null;
}

export default function EditBookingForm({
  isLoading,
  error,
  booking,
  onSubmit,
  onBookingDeleted,
  careReceiver,
}: Props) {
  const { isDeleting, deleteBooking } = useDeleteBooking(booking);
  const [bookingInterval, setBookingInterval] = useState({
    start: booking.startDate,
    end: booking.endDate,
  });
  const [focused, setFocused] = useState<'appointmentTime' | null>(null);
  const {profile} = useProfile();

  const formData = {
    bookingId: booking.bookingId,
    startDate: bookingInterval.start,
    endDate: bookingInterval.end,
  };

  const handleDelete = useCallback<() => void>(async () => {
    if (isDeleting) {
      return;
    }

    if (await deleteBooking()) {
      onBookingDeleted();
    }
  }, [deleteBooking, isDeleting, onBookingDeleted]);

  return (
    <ScrollView>
      <View style={[styles.group]}>
        <Container>
          <FormattedMessage tagName={FormGroupTitle} id="APPOINTMENT_DATE_LABEL" />
        </Container>
        <DateTimeInterval
          focused={focused === 'appointmentTime'}
          onIntervalChange={setBookingInterval}
          onFocus={() => setFocused('appointmentTime')}
          interval={bookingInterval}
        />
      </View>
      <Container>
        {error && (
          <InputErrorMessage>
            <FormattedMessage id="ERROR_RESPONSE" values={{ errorCode: error.errorCode }} />
          </InputErrorMessage>
        )}
        <Button
          style={styles.submitButton}
          loading={isLoading}
          disabled={!validate(formData) || isLoading}
          onPress={() => {
            if (validate(formData)) {
              onSubmit(formData);
            }
          }}
          icon={<Feather color="white" size={32} name="check" />}
        >
          <FormattedMessage id="BUTTON_SAVE_CHANGES" />
        </Button>
      </Container>
      {!booking.isFirstBooking && !profile?.isCareshipEmployee && (
        <>
          <Hr style={styles.deleteHr} noMargins />
          <Container>
            <PressableWithConfirmation
              dialogTitle={intl.formatMessage({ id: 'BOOKING_DELETE_CONFIRM_TITLE' })}
              dialogContent={intl.formatMessage(
                { id: 'BOOKING_DELETE_CONFIRM_MESSAGE' },
                {
                  bookingDate: intl.formatDate(booking.startDate, {
                    day: '2-digit',
                    month: 'long',
                  }),
                  name: intl.formatMessage(
                    { id: 'NAME_WITH_SALUTATION' },
                    {
                      gender: careReceiver.gender,
                      name: careReceiver.name.lastName,
                    },
                  ),
                },
              )}
              confirmButtonLabel={intl.formatMessage({ id: 'BOOKING_DELETE_CONFIRM_SUBMIT' })}
              abortButtonLabel={intl.formatMessage({ id: 'BUTTON_CANCEL' })}
              onPress={() => trackEvent(events.BOOKING_DELETE_CLICK)}
            >
              <Button
                onPress={handleDelete}
                testID="delete-booking-button"
                dark={false}
                loading={isDeleting}
                disabled={isDeleting}
                color="white"
                size="lg"
                thin
                labelStyle={{ color: CareshipColors.red }}
                icon={<Feather color={CareshipColors.red} size={22} name="trash-2" />}
              >
                <FormattedMessage id="BOOKING_ACTION_DELETE" />
              </Button>
            </PressableWithConfirmation>
          </Container>
        </>
      )}
    </ScrollView>
  );
}
