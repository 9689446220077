import React, { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useMutation } from 'react-relay/hooks';
import { graphql } from 'react-relay';
import { format } from 'date-fns/fp';
import styled from '@emotion/native';
import { Button } from '../../Component';
import { CreateAbsenceFormMutation } from './__generated__/CreateAbsenceFormMutation.graphql';
import { logger } from '../../../Infrastructure/Service';
import AbsenceForm, { Reason } from './AbsenceForm';

const toDateString = format('Y-MM-dd');

interface CreateAbsenceFormProps {
  absenceEdgeId: string;
  onCompleted: () => void;
}

const ActionButton = styled(Button)({
  marginBottom: 24,
});

export default function CreateAbsenceForm({ absenceEdgeId, onCompleted }: CreateAbsenceFormProps) {
  const [error, setError] = useState<string | null>(null);
  const [reason, setReason] = useState<Reason>('OTHER');
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [commitMutation, isInFlight] = useMutation<CreateAbsenceFormMutation>(
    graphql`
      mutation CreateAbsenceFormMutation(
        $input: CreateCaregiverAbsenceInput!
        $connections: [ID!]!
      ) {
        createCaregiverAbsence(input: $input) {
          absence @appendNode(edgeTypeName: "AbsenceEdge", connections: $connections) {
            id
            from
            to
            reason
          }
        }
      }
    `,
  );

  const handleSubmit = useCallback(() => {
    setError(null);
    commitMutation({
      variables: {
        input: {
          from: toDateString(startDate),
          to: toDateString(endDate),
          reason,
        },
        connections: [absenceEdgeId],
      },
      onCompleted,
      onError: (e) => {
        logger.error(e.toString());
        setError('INTERNAL_SERVER_ERROR');
      },
    });
  }, [absenceEdgeId, commitMutation, endDate, onCompleted, reason, startDate]);

  return (
    <>
      <AbsenceForm
        startDate={startDate}
        onStartDateChange={setStartDate}
        endDate={endDate}
        onEndDateChange={setEndDate}
        reason={reason}
        onReasonChange={(value) => setReason(value)}
        error={error && <FormattedMessage id="ERROR_RESPONSE" values={{ errorCode: error }} />}
      >
        <ActionButton disabled={isInFlight} loading={isInFlight} onPress={handleSubmit}>
          <FormattedMessage id="ABSENCE_SAVE" />
        </ActionButton>
      </AbsenceForm>
    </>
  );
}
