import React, { useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled, { css } from '@emotion/native';
import { graphql, useMutation, useRefetchableFragment } from 'react-relay/hooks';
import { Platform } from 'react-native';
import { SelectorStoreUpdater } from 'relay-runtime';
import { Container, Loading, Text, Title } from '../../Component';
import Slider from '../../Component/Form/Slider';
import { TravelDistanceFragment$key } from './__generated__/TravelDistanceFragment.graphql';
import {
  TravelDistanceMutation,
  TravelDistanceMutation$data,
} from './__generated__/TravelDistanceMutation.graphql';
import useLogger from '../../../Infrastructure/Hook/useLogger';
import { CareshipColors } from '../../Component/CSTheme';
import { TravelDistanceIllustration } from '../../Component/Illustration';
import { useShowAlert } from '../../Dialog';

const titleStyle = css({ marginBottom: 16 });
const containerStyle = css({ marginBottom: 32 });

interface TravelDistanceProps {
  fragmentRef: TravelDistanceFragment$key;
}

const Value = styled.View({
  position: 'absolute',
  backgroundColor: CareshipColors.white,
  paddingHorizontal: 8,
  alignSelf: 'center',
  justifyContent: 'center',
  alignContent: 'center',
  top: '50%',
  height: 32,
  transform: [{ translateY: -20 }],
});

const ValueText = styled(Text)({
  color: CareshipColors.secondary,
});

const IllustrationWrap = styled.View(({ theme: { breakpoints } }) => [
  {
    aspectRatio: 288 / (48 + 32),
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: Platform.OS === 'web' ? 16 : undefined,
  },
  breakpoints.up('lg', {
    paddingVertical: 32,
  }),
]);

const Illustration = styled(TravelDistanceIllustration)({ width: '100%', maxWidth: 450, flex: 1 });

const updater =
  (id: string): SelectorStoreUpdater<TravelDistanceMutation$data> =>
  (store, { updateCaregiverTravelDistance }) => {
    const meRecord = store.get(id);

    if (meRecord && updateCaregiverTravelDistance !== null) {
      meRecord.setValue(updateCaregiverTravelDistance.travelDistance, 'travelDistance');
    }
  };

export default function TravelDistance({ fragmentRef }: TravelDistanceProps) {
  const intl = useIntl();
  const logger = useLogger();
  const showAlert = useShowAlert();
  const [displayValue, setDisplayValue] = useState(0);

  const [{ travelDistance, id }, refetch] = useRefetchableFragment(
    graphql`
      fragment TravelDistanceFragment on Caregiver
      @refetchable(queryName: "TravelDistanceFragmentRefetchQuery") {
        id
        travelDistance
      }
    `,
    fragmentRef,
  );

  const [commitMutation, isInFlight] = useMutation<TravelDistanceMutation>(
    graphql`
      mutation TravelDistanceMutation($input: CaregiverTravelDistanceInput!) {
        updateCaregiverTravelDistance(input: $input) {
          travelDistance
        }
      }
    `,
  );

  const handleTravelDistanceChange = useCallback(
    (value: number) => {
      commitMutation({
        variables: {
          input: {
            travelDistance: Math.round(value),
          },
        },
        optimisticResponse: {
          updateCaregiverTravelDistance: {
            travelDistance: Math.round(value),
          },
        },
        optimisticUpdater: updater(id),
        onError: (error) => {
          refetch({});
          showAlert(intl.formatMessage({ id: 'ERROR_UNKNOWN' }));
          logger.error('setCaregiverBookedOut mutation failed', { relayError: error });
        },
        updater: updater(id),
      });
    },
    [commitMutation, id, intl, logger, refetch, showAlert],
  );

  return (
    <Container style={containerStyle}>
      <Title style={titleStyle} uppercase size="h5" muted>
        <FormattedMessage id="TRAVEL_DISTANCE_TITLE" />
      </Title>

      <IllustrationWrap>
        <Illustration />
        <Value>
          {isInFlight ? (
            <Loading color={CareshipColors.secondary} />
          ) : (
            <ValueText bold size="large">
              <FormattedMessage
                id="TRAVEL_DISTANCE_VALUE"
                values={{ value: Math.round(displayValue) }}
              />
            </ValueText>
          )}
        </Value>
      </IllustrationWrap>

      <Slider
        disabled={isInFlight}
        onValueChange={setDisplayValue}
        onSlidingComplete={handleTravelDistanceChange}
        value={travelDistance}
        minimumValue={1}
        maximumValue={30}
      />
    </Container>
  );
}
