import { Platform } from 'react-native';
import React, { useCallback, useLayoutEffect } from 'react';
import { useFragment, useMutation } from 'react-relay/hooks';
import { graphql } from 'react-relay';
import { useNavigation } from '@react-navigation/native';
import { FormattedMessage } from 'react-intl';
import styled from '@emotion/native';
import { FieldErrors, ResolverResult, UnpackNestedValue, useForm } from 'react-hook-form';
import * as t from 'io-ts';
import * as tt from 'io-ts-types';
import { withMessage } from 'io-ts-types';
import { ioTsResolver } from '@hookform/resolvers/io-ts';
import { Button, Container, HeaderSaveButton, InputWithValidation } from '../../Component';
import { intl } from '../../../Infrastructure/Intl';
import { EditFinancialDetailsFormFragment$key } from './__generated__/EditFinancialDetailsFormFragment.graphql';
import { AccountStackNavigationProp } from '../Navigator/AccountStackNavigationProp';
import { events, trackEvent } from '../../../Infrastructure/Tracking/Tracking';
import { EditFinancialDetailsFormMutation } from './__generated__/EditFinancialDetailsFormMutation.graphql';
import { composeResolvers } from '../../../Infrastructure/Validation';

const FinancialFormButton = styled(Button)<{ topmost?: boolean; bottommost?: boolean }>(
  ({ theme: { spacingTokens }, topmost, bottommost }) => [
    {
      marginVertical: spacingTokens.lg / 2,
      marginTop: topmost ? spacingTokens.xl : undefined,
      marginBottom: bottommost ? spacingTokens.lg : undefined,
    },
  ],
);

const nonEmptyString = withMessage(tt.NonEmptyString, () => 'VALIDATION_REQUIRED');

const schema = t.type({
  iban: nonEmptyString,
  bic: nonEmptyString,
  taxNo: t.string,
  ustId: t.string,
});

const taxNoResolver = <TFieldValues extends FinancialDetailsData>(
  values: UnpackNestedValue<TFieldValues>,
): ResolverResult<TFieldValues> => {
  if (!values.taxNo && !values.ustId) {
    return {
      errors: {
        taxNo: {
          type: 'validation',
          message: 'VALIDATION_TAX_NO_REQUIRED',
        },
        ustId: {
          type: 'validation',
          message: 'VALIDATION_UST_ID_REQUIRED',
        },
      } as FieldErrors<TFieldValues>,
      values: {},
    };
  }

  return {
    errors: {},
    values,
  };
};

type FinancialDetailsData = {
  iban: string;
  bic: string;
  taxNo: string;
  ustId: string;
};

export default function EditFinancialDetailsForm({
  fragmentRef,
}: {
  fragmentRef: EditFinancialDetailsFormFragment$key;
}) {
  const {
    bank: { iban, bic },
    tax: { taxNo, ustId },
  } = useFragment(
    graphql`
      fragment EditFinancialDetailsFormFragment on Caregiver {
        id
        bank {
          bic
          iban
        }
        tax {
          taxNo
          ustId
        }
      }
    `,
    fragmentRef,
  );

  const defaultValues = { iban, bic, ustId, taxNo };

  const {
    handleSubmit,
    control,
    formState: { isDirty },
  } = useForm<FinancialDetailsData>({
    mode: 'onTouched',
    defaultValues,
    resolver: composeResolvers(ioTsResolver(schema), taxNoResolver),
  });

  const [commitMutation, isInFlight] = useMutation<EditFinancialDetailsFormMutation>(
    graphql`
      mutation EditFinancialDetailsFormMutation($input: CaregiverFinancialDetailsInput!) {
        updateCaregiverFinancialDetails(input: $input) {
          caregiverId
          bank {
            bic
            iban
          }
          tax {
            taxNo
            ustId
          }
        }
      }
    `,
  );

  const navigation = useNavigation<AccountStackNavigationProp<'EditFinancialDetails'>>();

  const handleSave = useCallback(
    (data: FinancialDetailsData) => {
      commitMutation({
        variables: {
          input: {
            bank: {
              iban: data.iban,
              bic: data.bic,
            },
            tax: {
              ustId: data.ustId,
              taxNo: data.taxNo,
            },
          },
        },
        updater: (store, { updateCaregiverFinancialDetails }) => {
          if (!updateCaregiverFinancialDetails) {
            return;
          }
          const me = store.get(updateCaregiverFinancialDetails?.caregiverId);

          const bankRecord = me?.getLinkedRecord('bank');
          bankRecord?.setValue(updateCaregiverFinancialDetails.bank.bic, 'bic');
          bankRecord?.setValue(updateCaregiverFinancialDetails.bank.iban, 'iban');

          const taxRecord = me?.getLinkedRecord('tax');
          taxRecord?.setValue(updateCaregiverFinancialDetails.tax.taxNo, 'taxNo');
          taxRecord?.setValue(updateCaregiverFinancialDetails.tax.ustId, 'ustId');
        },
        onCompleted: () => {
          navigation.navigate('FinancialDetails');
          trackEvent(events.FINANCIAL_DETAILS_UPDATED);
        },
      });
    },
    [commitMutation, navigation],
  );

  const handlePressSubmit = useCallback(() => {
    void handleSubmit(handleSave)();
  }, [handleSave, handleSubmit]);

  useLayoutEffect(() => {
    navigation.setOptions({
      headerRight: () => {
        return <HeaderSaveButton dirty={isDirty} onPress={handlePressSubmit} />;
      },
      headerBackTitle: intl.formatMessage({ id: 'BUTTON_CANCEL' }),
    });
  }, [handlePressSubmit, isDirty, navigation]);

  const handleAbort = useCallback(() => navigation.navigate('FinancialDetails'), [navigation]);

  return (
    <Container>
      <InputWithValidation
        topmost
        marginVertical="base"
        control={control}
        defaultValue={iban}
        name="iban"
        label={intl.formatMessage({ id: 'IBAN' })}
        disabled={isInFlight}
        keyboardType="ascii-capable"
        autoCapitalize="characters"
        accessibilityLabel="iban"
        accessibilityHint="IBAN"
      />
      <InputWithValidation
        control={control}
        name="bic"
        marginVertical="tight"
        defaultValue={bic}
        label={intl.formatMessage({ id: 'BIC' })}
        disabled={isInFlight}
        keyboardType="ascii-capable"
        autoCapitalize="characters"
        accessibilityLabel="BIC"
        accessibilityHint="BIC"
      />
      <InputWithValidation
        control={control}
        name="taxNo"
        defaultValue={taxNo}
        marginVertical="tight"
        label={`${intl.formatMessage({ id: 'TAX_NUMBER' })} (${intl.formatMessage({
          id: 'TAX_NUMBER_HINT',
        })})`}
        disabled={isInFlight}
        keyboardType="ascii-capable"
        autoCapitalize="characters"
        accessibilityLabel="tax id"
        accessibilityHint="tax id"
      />
      <InputWithValidation
        name="ustId"
        control={control}
        defaultValue={ustId}
        bottommost
        marginVertical="tight"
        keyboardType="ascii-capable"
        autoCapitalize="characters"
        accessibilityLabel="vat id"
        accessibilityHint="VAT id"
        disabled={isInFlight}
        label={`${intl.formatMessage({ id: 'VAT_ID' })} (${intl.formatMessage({
          id: 'VAT_ID_HINT',
        })})`}
      />
      {Platform.OS === 'web' && (
        <>
          <FinancialFormButton onPress={handlePressSubmit} disabled={isInFlight}>
            <FormattedMessage id="BUTTON_SAVE" />
          </FinancialFormButton>
          <FinancialFormButton
            dark={false}
            thin
            size="sm"
            color="transparent"
            onPress={handleAbort}
            disabled={isInFlight}
          >
            <FormattedMessage id="BUTTON_CANCEL" />
          </FinancialFormButton>
        </>
      )}
    </Container>
  );
}
