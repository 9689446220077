import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';
import { CareshipColors } from '../CSTheme';

interface DownloadIconProps extends SvgProps {
  color?: string;
  size?: number;
}

export default function DownloadIcon({
  color = CareshipColors.slate400,
  size = 24,
  ...props
}: DownloadIconProps) {
  return (
    <Svg viewBox="0 0 24 24" width={size} height={size} {...props}>
      <Path
        d="M22.2500916,15 C22.6297873,15 22.9435825,15.2821539 22.9932449,15.6482294 L23.0000916,15.75 L23.0000916,19.75 C23.0000916,20.940864 22.07493,21.9156449 20.9041404,21.9948092 L20.7500916,22 L3.25,22 C2.05913601,22 1.08435508,21.0748384 1.00519081,19.9040488 L1,19.75 L1,15.75 C1,15.3357864 1.33578644,15 1.75,15 C2.12969577,15 2.44349096,15.2821539 2.49315338,15.6482294 L2.5,15.75 L2.5,19.75 C2.5,20.1296958 2.78215388,20.443491 3.14822944,20.4931534 L3.25,20.5 L20.7500916,20.5 C21.1297873,20.5 21.4435825,20.2178461 21.4932449,19.8517706 L21.5000916,19.75 L21.5000916,15.75 C21.5000916,15.3357864 21.835878,15 22.2500916,15 Z M12.001,1 C12.3806958,1 12.694491,1.28215388 12.7441534,1.64822944 L12.751,1.75 L12.751,12.938 L15.9706699,9.71966991 C16.2369365,9.45340335 16.6536002,9.4291973 16.9472117,9.64705176 L17.0313301,9.71966991 C17.2975966,9.98593648 17.3218027,10.4026002 17.1039482,10.6962117 L17.0313301,10.7803301 L12.5313301,15.2803301 C12.2650635,15.5465966 11.8483998,15.5708027 11.5547883,15.3529482 L11.4706699,15.2803301 L6.97066991,10.7803301 C6.6777767,10.4874369 6.6777767,10.0125631 6.97066991,9.71966991 C7.23693648,9.45340335 7.65360016,9.4291973 7.94721165,9.64705176 L8.03133009,9.71966991 L11.251,12.938 L11.251,1.75 C11.251,1.33578644 11.5867864,1 12.001,1 Z"
        fill={color}
      />
    </Svg>
  );
}
