import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';
import CSTheme from '../CSTheme';

type EmailActionIconProps = SvgProps & {
  color?: string;
  size?: number;
};

export default function EmailActionIcon({ color, size = 24, ...props }: EmailActionIconProps) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" {...props}>
      <Path
        d="M8.35 15.5a.75.75 0 110 1.5H3a2.25 2.25 0 01-2.25-2.25v-12A2.25 2.25 0 013 .5h18a2.25 2.25 0 012.25 2.25v6.424a.75.75 0 11-1.5 0V2.75A.75.75 0 0021 2H3a.75.75 0 00-.75.75v12c0 .414.336.75.75.75h5.35zM21.716 1.256a.75.75 0 11.914 1.189l-8.144 6.263a4.058 4.058 0 01-4.948 0L1.393 2.446a.75.75 0 11.914-1.19l8.144 6.265c.92.707 2.2.707 3.12 0l8.144-6.264zM17.755 23.6c1.184 0 2.336-.16 3.296-.592.833-.347 1.172-.746 1.02-1.197-.153-.451-.493-.564-1.02-.339-.896.384-2.048.56-3.28.56-3.04 0-5.152-1.632-5.152-4.8 0-2.688 2.048-5.024 5.232-5.024 2.88 0 4.528 1.744 4.528 4.064 0 1.744-.432 2.704-1.264 2.704-.608 0-.768-.352-.752-.864l.126-3.394c.006-.16-.05-.827-.74-.827-.46 0-.735.198-.822.594l-.1.539h-.064c-.304-.576-1.072-.96-1.776-.96-1.664 0-2.688 1.408-2.688 3.232 0 1.84 1.04 3.152 2.656 3.152.896 0 1.536-.288 2.064-.944h.064c.16.512.784.992 1.76.992 1.824 0 3.12-1.136 3.12-4.224 0-3.456-2.608-5.616-6.064-5.616-3.808 0-6.976 2.64-6.976 6.48 0 4.432 3.056 6.464 6.832 6.464zm-.448-4.752c-.704 0-1.248-.432-1.248-1.536 0-1.152.576-1.68 1.344-1.68.592 0 1.328.368 1.328 1.504 0 1.024-.432 1.712-1.424 1.712z"
        fill={color || CSTheme.colors.text}
      />
    </Svg>
  );
}
