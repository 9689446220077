import { useMutation } from 'react-relay/hooks';
import { graphql } from 'react-relay';
import { useBookingFeedbackMutation as MutationT } from './__generated__/useBookingFeedbackMutation.graphql';

export default function useBookingFeedbackMutation() {
  return useMutation<MutationT>(graphql`
    mutation useBookingFeedbackMutation(
      $bookingId: GUID!
      $careReceiverMood: String!
      $careReceiverMoodAdditionalText: String!
      $customerEnergy: String!
      $customerEnergyAdditionalText: String!
      $activitiesPerformed: String!
    ) {
      bookingFeedback(
        bookingId: $bookingId
        careReceiverMood: $careReceiverMood
        careReceiverMoodAdditionalText: $careReceiverMoodAdditionalText
        customerEnergy: $customerEnergy
        customerEnergyAdditionalText: $customerEnergyAdditionalText
        activitiesPerformed: $activitiesPerformed
      ) {
        status
      }
    }
  `);
}
