import { StyleSheet } from 'react-native';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ExpiredIllustration } from '../../Component/Illustration';
import { Container, Text, Title } from '../../Component';

const styles = StyleSheet.create({
  wrapper: {
    alignItems: 'center',
  },
  illustration: {
    marginTop: 48,
  },
  heading: {
    marginTop: 40,
    maxWidth: 320,
  },
  subHeading: {
    marginTop: 24,
  },
});

export default function ExpiredProposal() {
  return (
    <Container style={styles.wrapper}>
      <ExpiredIllustration style={styles.illustration} width={192} height={208} />
      <Title style={styles.heading} center>
        <FormattedMessage id="PROPOSAL_UNAVAILABLE_TITLE" />
      </Title>
      <Text style={styles.subHeading} size="xlarge" center>
        <FormattedMessage id="PROPOSAL_UNAVAILABLE_SUBTITLE" />
      </Text>
    </Container>
  );
}
