import React, { useEffect } from 'react';
import { useQueryLoader } from 'react-relay/hooks';
import Layout from '../../Component/Layout';
import LanguagesForm, { languagesFormQuery } from '../Component/LanguagesForm';
import { LanguagesFormQuery } from '../Component/__generated__/LanguagesFormQuery.graphql';

export default function LanguagesScreen() {
  const [preloadedQuery, loadQuery] = useQueryLoader<LanguagesFormQuery>(languagesFormQuery);

  useEffect(() => {
    loadQuery(
      {},
      {
        fetchPolicy: 'store-and-network',
      },
    );
  }, [loadQuery]);

  return <Layout>{preloadedQuery && <LanguagesForm preloadedQuery={preloadedQuery} />}</Layout>;
}
