/**
 * @generated SignedSource<<5a3d778e6d465076d350be41166aa707>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CaregiverEducationAndQualificationEnum = "NO_FORMAL_NURSING_TRAINING" | "CAREGIVER_ACCORDING_TO_45B" | "HEALTH_CARE_WORKER_AND_NURSING_TRAINING" | "OTHER_MEDICAL_NURSING_TRAINING" | "PEDAGOGY_TRAINING" | "FIRST_AID" | "%future added value";
export type UpdateCaregiverEducationAndQualificationsInput = {
  educationAndQualifications: ReadonlyArray<CaregiverEducationAndQualificationEnum>;
  clientMutationId?: string | null;
};
export type EducationQualificationsMutation$variables = {
  input: UpdateCaregiverEducationAndQualificationsInput;
};
export type EducationQualificationsMutation$data = {
  readonly updateCaregiverEducationAndQualifications: {
    readonly caregiver: {
      readonly id: string;
      readonly educationAndQualifications: ReadonlyArray<{
        readonly key: CaregiverEducationAndQualificationEnum;
      }>;
    };
  } | null;
};
export type EducationQualificationsMutation = {
  variables: EducationQualificationsMutation$variables;
  response: EducationQualificationsMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateCaregiverEducationAndQualificationsPayload",
    "kind": "LinkedField",
    "name": "updateCaregiverEducationAndQualifications",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Caregiver",
        "kind": "LinkedField",
        "name": "caregiver",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "CaregiverEducationAndQualification",
            "kind": "LinkedField",
            "name": "educationAndQualifications",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "key",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EducationQualificationsMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EducationQualificationsMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "4c8e7e255d1dadd6a525acece174472d",
    "id": null,
    "metadata": {},
    "name": "EducationQualificationsMutation",
    "operationKind": "mutation",
    "text": "mutation EducationQualificationsMutation(\n  $input: UpdateCaregiverEducationAndQualificationsInput!\n) {\n  updateCaregiverEducationAndQualifications(input: $input) {\n    caregiver {\n      id\n      educationAndQualifications {\n        key\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "30b3266482b7e12638fb85db5aa384d9";

export default node;
