export interface NameResponse {
  readonly first_name: string;
  readonly last_name: string;
}

export interface NameObject {
  readonly firstName: string;
  readonly lastName: string;
}

export default class Name implements NameObject {
  constructor(public readonly firstName: string, public readonly lastName: string) {}

  public static fromObject(name: NameObject) {
    return new Name(name.firstName, name.lastName);
  }

  public static fromResponse(response: NameResponse) {
    return new Name(response.first_name, response.last_name);
  }
}
