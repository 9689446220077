import AuthorizedClient, {
  ErrorResponse,
  isError,
  SuccessResponse,
} from '../../../Infrastructure/Service/AuthorizedClient';
import ProposalStore, { ProposalListResponse } from '../Store/ProposalStore';
import logger from '../../../Infrastructure/Service/Logger';
import { intl } from '../../../Infrastructure/Intl';
import { ProposalDeclineFormData } from '../../../UI/Customer/Component/ProposalDeclineForm';

type ProposalErrorCodes =
  | 'PROPOSAL_ALREADY_ACCEPTED'
  | 'PROPOSAL_NOT_FOUND'
  | 'PROPOSAL_INVALID_SERVICE_REQUEST_ID'
  | 'CANNOT_DESERIALIZE_REQUEST_DATA'
  | 'INTERNAL_SERVER_ERROR';

type DeclinePayload = {
  reasons: {
    reason: string;
    details: string;
  }[];
};

export default class ProposalService {
  constructor(private proposalStore: ProposalStore, private apiClient: AuthorizedClient) {}

  async fetchProposalList(): Promise<void> {
    const endpoint = '/caregiver-app/proposal';
    const result = await this.apiClient.request<ProposalListResponse>(endpoint,{method: 'GET'});

    if (isError(result)) {
      logger.warn(
        `Failed fetching ${endpoint}. Response: ${result.status} ${JSON.stringify(
          result.errorCode,
        )}`,
      );

      return;
    }

    if (!Array.isArray(result.data)) {
      logger.error(
        `expected proposal list result.data to be an array, received result ${JSON.stringify(
          result,
        )}`,
      );

      return;
    }

    this.proposalStore.handleProposalListResponse(result.data);
  }

  async declineProposal(
    serviceRequestId: string,
    formData: ProposalDeclineFormData,
  ): Promise<void> {
    const data: DeclinePayload = {
      reasons: formData.reasons.map((r) => ({
        reason: r,
        details: r === 'other' ? formData.details : '',
      })),
    };

    const endpoint = `/caregiver-app/proposal/${serviceRequestId}/decline`;
    const result = await this.apiClient.request(endpoint, {
      method: 'POST',
      body: JSON.stringify(data),
    });

    if (!isError(result)) {
      this.proposalStore.declineProposal(serviceRequestId);
      return;
    }

    const errorCode = result.errorCode as ProposalErrorCodes;

    switch (errorCode) {
      case 'PROPOSAL_INVALID_SERVICE_REQUEST_ID':
      case 'PROPOSAL_NOT_FOUND':
        this.proposalStore.declineProposal(serviceRequestId);
        break;

      case 'CANNOT_DESERIALIZE_REQUEST_DATA':
      case 'INTERNAL_SERVER_ERROR':
      default:
        throw new Error(intl.formatMessage({ id: 'ERROR_RESPONSE' }, { errorCode }));
    }
  }

  async acceptProposal(
    serviceRequestId: string,
  ): Promise<SuccessResponse<unknown> | ErrorResponse> {
    const endpoint = `/caregiver-app/proposal/${serviceRequestId}/accept`;

    const result = await this.apiClient.request(endpoint, {
      method: 'POST',
    });

    if (!isError(result)) {
      this.proposalStore.acceptProposal(serviceRequestId);
    } else {
      const errorCode = result.errorCode as ProposalErrorCodes;

      switch (errorCode) {
        case 'PROPOSAL_ALREADY_ACCEPTED':
          this.proposalStore.acceptProposal(serviceRequestId);
          break;

        case 'PROPOSAL_INVALID_SERVICE_REQUEST_ID':
        case 'PROPOSAL_NOT_FOUND':
          this.proposalStore.declineProposal(serviceRequestId);
          break;

        case 'CANNOT_DESERIALIZE_REQUEST_DATA':
        case 'INTERNAL_SERVER_ERROR':
        default:
          throw new Error(intl.formatMessage({ id: 'ERROR_RESPONSE' }, { errorCode }));
      }
    }

    return result;
  }
}
