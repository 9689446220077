import { View } from 'react-native';
import React from 'react';
import styled from '@emotion/native';
import { PreloadedQuery, usePreloadedQuery } from 'react-relay/hooks';
import { graphql } from 'react-relay';
import { FormattedMessage } from 'react-intl';
import { useNavigation } from '@react-navigation/native';
import { Feather } from '@expo/vector-icons';
import { AccountStackNavigationProp } from '../Navigator/AccountStackNavigationProp';
import { intl } from '../../../Infrastructure/Intl';
import { Button, ListItemData, Text } from '../../Component';
import {
  UserIcon,
  CompanyIcon,
  MapPinIcon,
  MobilePhoneIcon,
  PhoneIcon,
  BirthdayIcon,
} from '../../Component/Icon';
import { ContactAndAddressQuery } from './__generated__/ContactAndAddressQuery.graphql';
import { CareshipColors } from '../../Component/CSTheme';
import useProfile from '../../../Context/Profile/Hook/useProfile';

export const contactAndAddressQuery = graphql`
  query ContactAndAddressQuery {
    me {
      ... on Caregiver {
        name {
          firstName
          lastName
        }
        company
        address {
          street
          city
          postalCode
          additionalInformation
        }
        dateOfBirth
        phoneNumber
        mobilePhoneNumber
      }
    }
  }
`;

interface ContactDataProps {
  preloadedQuery: PreloadedQuery<ContactAndAddressQuery>;
}

const MainWrapper = styled(View)({
  paddingTop: 32,
});

const ButtonWrapper = styled(View)({
  paddingLeft: 8,
  paddingRight: 8,
});

const BirthdayContainer = styled(View)({
  paddingTop: 32,
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
});

const BirthdayIconContainer = styled(View)({
  backgroundColor: CareshipColors.slate400,
  padding: 10,
  marginRight: 10,
  borderRadius: 30,
});

export default function ContactAndAddress({ preloadedQuery }: ContactDataProps) {
  const { profile } = useProfile();
  const {
    me: { company, name, phoneNumber, mobilePhoneNumber, address, dateOfBirth },
  } = usePreloadedQuery(contactAndAddressQuery, preloadedQuery);
  const navigation = useNavigation<AccountStackNavigationProp<'ContactAndAddress'>>();

  return (
    <MainWrapper>
      <ListItemData icon={<UserIcon />}>
        <Text size="large">
          {name?.firstName} {name?.lastName}
        </Text>
      </ListItemData>
      <ListItemData icon={<CompanyIcon />}>
        <Text size="large">
          {company}{' '}
          <Text size="large" muted>
            (<FormattedMessage id="CONTACT_AND_ADDRESS_COMPANY" />)
          </Text>
        </Text>
      </ListItemData>
      <ListItemData icon={<MapPinIcon />}>
        <Text size="large">
          {address?.street}, {address?.postalCode} {address?.city}
          {address?.additionalInformation && <> - {address?.additionalInformation}</>}
        </Text>
      </ListItemData>
      <ListItemData icon={<MobilePhoneIcon />}>
        <Text size="large">
          {mobilePhoneNumber}{' '}
          <Text size="large" muted>
            (<FormattedMessage id="CONTACT_AND_ADDRESS_MOBILE" />)
          </Text>
        </Text>
      </ListItemData>
      <ListItemData icon={<PhoneIcon />}>
        <Text size="large">
          {phoneNumber}{' '}
          <Text size="large" muted>
            (<FormattedMessage id="CONTACT_AND_ADDRESS_LANDLINE" />)
          </Text>
        </Text>
      </ListItemData>
      <BirthdayContainer>
        <BirthdayIconContainer>
          <BirthdayIcon color={CareshipColors.white} />
        </BirthdayIconContainer>
        {dateOfBirth && (
          <Text size="xlarge">
            <FormattedMessage id="CONTACT_AND_ADDRESS_BIRTHDAY" />{' '}
            {intl.formatDate(dateOfBirth, { day: '2-digit', month: 'long', year: 'numeric' })}
          </Text>
        )}
      </BirthdayContainer>
      {!profile?.isCareshipEmployee && (
          <ButtonWrapper>
            <Button
              style={{ marginTop: 32 }}
              size="sm"
              icon={<Feather color="white" size={24} name="edit" />}
              onPress={() => {
                navigation.navigate('EditContactAndAddress');
              }}
            >
              <FormattedMessage id="BUTTON_EDIT" />
            </Button>
          </ButtonWrapper>
      )}
    </MainWrapper>
  );
}
