import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

type ExpiredIllustrationProps = SvgProps & {
  width?: number | string;
  height?: number | string;
};

function ExpiredIllustration({ width = 310, height = 166, ...props }: ExpiredIllustrationProps) {
  return (
    <Svg width={width} height={height} x={0} y={0} viewBox="0 0 154 183" {...props}>
      <Path
        d="M126.3 104.68a44.16 44.16 0 11-44.16-44.16 44.16 44.16 0 0144.16 44.16"
        fill="#cce3ff"
        fillRule="evenodd"
      />
      <Path
        d="M117.64 104.68a44.16 44.16 0 11-44.16-44.16 44.16 44.16 0 0144.16 44.16"
        fill="#dceeff"
        fillRule="evenodd"
      />
      <Path
        d="M107 104.68a33.47 33.47 0 01-29.77 33.26 32.21 32.21 0 01-3.69.2 33.46 33.46 0 110-66.92 34.66 34.66 0 013.69.2A33.47 33.47 0 01107 104.68"
        fill="#cce3ff"
        fillRule="evenodd"
      />
      <Path
        d="M107 104.68a33.47 33.47 0 01-29.77 33.26 33.46 33.46 0 010-66.52A33.47 33.47 0 01107 104.68"
        fill="#ecf6ff"
        fillRule="evenodd"
      />
      <Path
        fill="none"
        stroke="#cce3ff"
        strokeWidth={4.992}
        d="M74.94 86.07l10.84 23.65M86.81 99.81l-23.75 25.81"
      />
      <Path
        d="M64.34.62a18 18 0 11-12.73 22 18 18 0 0112.73-22zm4.86 21.13a1.5 1.5 0 102-.54 1.5 1.5 0 00-2 .54zm-2-11.58a1.59 1.59 0 00-1 2l1.72 6.44a1.51 1.51 0 102.9-.77l-1.73-6.44a1.6 1.6 0 00-1.88-1.23z"
        fill="#dceeff"
        fillRule="evenodd"
      />
      <Path
        d="M134.33 28.63a1.6 1.6 0 012.26-.16l2.9 2.52 2.53-2.9a1.6 1.6 0 112.41 2.1l-2.52 2.91 2.9 2.52a1.6 1.6 0 01-2.1 2.42l-2.9-2.52-2.52 2.9a1.6 1.6 0 11-2.42-2.1l2.52-2.91-2.9-2.52a1.6 1.6 0 01-.16-2.26z"
        fill="#ecf6ff"
        fillRule="evenodd"
      />
      <Path
        d="M30.55 152.3a.91.91 0 01.91.91v2.73h2.73a.91.91 0 110 1.82h-2.73v2.73a.91.91 0 01-1.82 0v-2.73h-2.73a.91.91 0 010-1.82h2.73v-2.73a.91.91 0 01.91-.91z"
        fill="#dceeff"
        fillRule="evenodd"
      />
      <Path
        data-name="Combined-Shape"
        d="M5.13 37.9A5.13 5.13 0 110 43a5.13 5.13 0 015.13-5.1zm0 2.57A2.57 2.57 0 107.69 43a2.57 2.57 0 00-2.56-2.53z"
        fill="#dceeff"
        fillRule="evenodd"
      />
      <Path
        d="M136.13 136.7a3.53 3.53 0 11-3.53 3.53 3.53 3.53 0 013.53-3.53zm0 1.77a1.76 1.76 0 101.76 1.76 1.76 1.76 0 00-1.76-1.76z"
        fill="#ecf6ff"
        fillRule="evenodd"
      />
      <Path
        data-name="Combined-Shape"
        d="M63.2 162.06a2.27 2.27 0 011.25 2.82v.19l-4 15.07c-.37 1.39-1.52 2.29-2.56 2a2.29 2.29 0 01-1.25-2.83v-.19l4-15.07c.36-1.37 1.52-2.27 2.56-1.99zm46.29-4.61l.1.17 7.8 13.51c.72 1.24.54 2.69-.39 3.22a2.29 2.29 0 01-2.89-1.11l-.1-.16-7.8-13.51c-.72-1.25-.54-2.69.39-3.23a2.3 2.3 0 012.89 1.11zm-81-22a2.29 2.29 0 01-1.11 2.89l-.16.1-13.51 7.8c-1.25.72-2.69.55-3.23-.39a2.28 2.28 0 011.11-2.88l.17-.1L25.24 135c1.24-.7 2.68-.53 3.22.4zm122.88-11c1.38.37 2.28 1.51 2 2.55a2.29 2.29 0 01-2.83 1.26l-.18-.05-15.07-4c-1.39-.38-2.29-1.52-2-2.56a2.31 2.31 0 012.83-1.26l.19.05zm-130.6-35c1.39.38 2.29 1.52 2 2.56a2.31 2.31 0 01-2.83 1.26l-.19-.05-15.07-4c-1.38-.37-2.28-1.51-2-2.55a2.28 2.28 0 012.83-1.26h.18zM145.55 67.8a2.3 2.3 0 01-1.11 2.89l-.17.1-13.51 7.8c-1.24.72-2.68.55-3.22-.39a2.27 2.27 0 011.11-2.88l.16-.1 13.51-7.8c1.25-.72 2.68-.55 3.23.38zM41.89 40.37l.1.16L49.79 54c.72 1.24.54 2.69-.39 3.23a2.31 2.31 0 01-2.89-1.11l-.1-.17-7.8-13.51c-.72-1.24-.54-2.69.39-3.23a2.3 2.3 0 012.89 1.16zm56.3-8.91a2.29 2.29 0 011.25 2.83v.19l-4 15.07c-.37 1.38-1.52 2.28-2.56 2a2.28 2.28 0 01-1.25-2.82v-.19l4-15.07c.37-1.39 1.52-2.29 2.56-2.01z"
        fill="#cce3ff"
        fillRule="evenodd"
      />
    </Svg>
  );
}

export default ExpiredIllustration;
