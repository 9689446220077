import { View } from 'react-native';
import React from 'react';
import { useFragment } from 'react-relay/hooks';
import { graphql } from 'react-relay';
import { FormattedMessage } from 'react-intl';
import { Card, ListItemData, Text } from '../../Component';
import { FinancialDetailsFragment$key } from './__generated__/FinancialDetailsFragment.graphql';
import { CareshipColors } from '../../Component/CSTheme';
import { AlertCircle } from '../../Component/Icon';
import useProfile from '../../../Context/Profile/Hook/useProfile';

interface FinancialDetailsProps {
  fragmentRef: FinancialDetailsFragment$key;
  onPress: () => void;
}

export default function FinancialDetails({ fragmentRef, onPress }: FinancialDetailsProps) {
  const { profile } = useProfile();
  const data = useFragment(
    graphql`
      fragment FinancialDetailsFragment on Caregiver {
        id
        bank {
          bic
          iban
        }
        tax {
          taxNo
          ustId
        }
      }
    `,
    fragmentRef,
  );

  return (
    <View>
      <Card
        mode="contained"
        topmost
        marginHorizontal="base"
        marginVertical="base"
        padding="base"
        style={{ flexDirection: 'row' }}
      >
        <AlertCircle size={24} color={CareshipColors.slate400} />
        <Text size="large" style={{ flexWrap: 'wrap', flex: 1, paddingLeft: 8 }}>
          <FormattedMessage id="BANK_DETAILS_HELP" />
        </Text>
      </Card>
      <ListItemData
        onPress={onPress}
        actionLabel={data.bank.iban}
        titleProps={{ muted: true }}
        labelProps={{ muted: false }}
      >
        <FormattedMessage id="IBAN" />
      </ListItemData>
      <ListItemData
        onPress={onPress}
        actionLabel={data.bank.bic}
        titleProps={{ muted: true }}
        labelProps={{ muted: false }}
      >
        <FormattedMessage id="BIC" />
      </ListItemData>
      {!profile?.isCareshipEmployee && (
          <ListItemData
            multiline
            onPress={onPress}
            actionLabel={data.tax.taxNo}
            titleProps={{ muted: true }}
            labelProps={{ muted: false }}
          >
            <FormattedMessage id="TAX_NUMBER" />
            <Text muted>
              <FormattedMessage id="TAX_NUMBER_HINT" />
            </Text>
          </ListItemData>
      )}
      {!profile?.isCareshipEmployee && (
          <ListItemData
            multiline
            onPress={onPress}
            actionLabel={data.tax.ustId}
            titleProps={{ muted: true }}
            labelProps={{ muted: false }}
          >
            <FormattedMessage id="VAT_ID" />
            <Text muted>
              <FormattedMessage id="VAT_ID_HINT" />
            </Text>
          </ListItemData>
      )}
    </View>
  );
}
