/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import Constants from 'expo-constants';
import { Platform } from 'react-native';
import SessionStore from '../Store/SessionStore';
import { TokenResponseData } from '../Model/TokenResponseData';
import logger from './Logger';
import Manifest from '../Constants/Manifest';
import OAuthTokenStore from '../Auth/OAuthTokenStore';
import OAuthError from './Error/OAuthError';
import AccessDeniedError from './Error/AccessDeniedError';
import AuthenticationError from './Error/AuthenticationError';
import { OauthServiceInterface } from '../Contract/OauthServiceInterface';
import { logout } from '../Hook/useHandleLogout';

// This line below should be removed after testing
const { apiEndpoint, oauthClientId, oauthClientSecret } = Manifest.extra;

const apiBackendUrl = Constants?.expoConfig?.extra?.apiBackendUrl;
const oauth2ClientSecret = Constants?.expoConfig?.extra?.oauth2ClientSecret;
const oauth2ClientId = Constants?.expoConfig?.extra?.oauth2ClientId;

const apiURI: string = Platform.OS === 'web' ? apiEndpoint : apiBackendUrl;
const oauthId: string = Platform.OS === 'web' ? oauthClientId : oauth2ClientId;
const oauthSecret: string = Platform.OS === 'web' ? oauthClientSecret : oauth2ClientSecret;


export const oauthClientWrapper = async (endpoint: string, params: string[]) => {
  return fetch(endpoint, {
    method: 'POST',
    body: [...params, `client_id=${oauthId}`, `client_secret=${oauthSecret}`].join('&'),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
};

export default class OauthService implements OauthServiceInterface {
  private tokenRefreshPromise?: Promise<void>;

  constructor(
    private sessionStore: SessionStore,
    private tokenStore: OAuthTokenStore,
    private oauthClient: (endpoint: string, params: string[]) => Promise<Response>,
  ) {}

  async asyncGetToken(): Promise<string> {
    let accessToken = await this.tokenStore.getAccessToken();

    if (!accessToken) {
      throw new OAuthError('no access token in store');
    }

    if (accessToken && !(await this.tokenStore.isTokenExpired())) {
      return accessToken;
    }

    try {
      await this.refreshToken();
    } catch (e) {
      await logout();
      throw new AccessDeniedError(String(e));
    }

    this.tokenRefreshPromise = undefined;
    accessToken = await this.tokenStore.getAccessToken();

    if (!accessToken) {
      throw new OAuthError('no access token in store after refresh');
    }

    return accessToken;
  }

  async authorize(username: string, password: string): Promise<void> {
    const result = await this.oauthClient(`${apiURI}/auth/token`, [
      `username=${encodeURIComponent(username.trim())}`,
      `password=${encodeURIComponent(password)}`,
      'grant_type=password',
      'scope=caregiver',
    ]);

    if (result.status !== 200) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      throw new AuthenticationError((await result.json()).error as string);
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const jsonResponse: TokenResponseData = await result.json();

    await this.tokenStore.handleTokenData(jsonResponse);
    logger.debug('access token persisted');
  }

  async refreshToken(): Promise<void> {
    if (this.tokenRefreshPromise) {
      return this.tokenRefreshPromise;
    }

    this.tokenRefreshPromise = (async (): Promise<void> => {
      const refreshToken = await this.tokenStore.getRefreshToken();
      if (!refreshToken) {
        throw new OAuthError('no refresh token in store');
      }

      logger.debug(`Access token refresh initialized (${refreshToken.slice(0, 5)})`);

      const result = await this.oauthClient(`${apiURI}/auth/token`, [
        `refresh_token=${String(await this.tokenStore.getRefreshToken())}`,
        'grant_type=refresh_token',
        'scope=caregiver',
      ]);

      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const jsonResponse = await result.json();

      if (result.status !== 200) {
        logger.addBreadcrumb(
          {
            message: 'failed to refresh access token',
            data: {
              accessTokenExpiresAt: await this.tokenStore.getAccessTokenExpiresAt(),
            },
          },
          { __dangerouslySkipSanitize: true },
        );
        logger.debug(`Access token refresh failed, status: ${result.status}`);

        await this.tokenStore.clearTokenData();
        this.sessionStore.reset();

        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        logger.error(String(jsonResponse.error));

        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        throw new AccessDeniedError(String(jsonResponse.error));
      }

      await this.tokenStore.handleTokenData(jsonResponse as TokenResponseData);

      logger.debug('Access token refresh successful');
    })();

    return this.tokenRefreshPromise;
  }
}
