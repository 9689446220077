import MapView from 'react-native-maps';
import React, { ComponentProps } from 'react';
import { ViewProps } from 'react-native';
import styled from '@emotion/native';
import Manifest from '../../../Infrastructure/Constants/Manifest';

const MapWrapper = styled.View({
  position: 'relative',
  paddingBottom: '56.25%',
  maxWidth: '100%',
});

export default function MapWithMarker({
  address,
  style,
}: {
  address: string;
  style?: ViewProps['style'];
} & ComponentProps<typeof MapView>) {
  const googleMapsApiKey = Manifest.extra.googleMapsBrowserApiKey as string ?? "AIzaSyBqWNWIlp4yuMg1mkOC1XmMDOwjheh9DYw";

  return (
    <MapWrapper style={style}>
      <iframe
        title={address}
        width="100%"
        height="100%"
        style={{
          border: 0,
          position: 'absolute',
        }}
        loading="lazy"
        allowFullScreen
        src={`https://www.google.com/maps/embed/v1/place?key=${googleMapsApiKey}&q=${address}`}
      />
    </MapWrapper>
  );
}
