/**
 * @generated SignedSource<<7f84403925701a1d49d39320bd6eb6c9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type PaymentMethod = "invoice" | "sepa_direct_debit" | "direct_reimbursement" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type InsuranceDirectPaymentInfo_contract$data = {
  readonly contractId: string;
  readonly paymentMethod: PaymentMethod;
  readonly " $fragmentType": "InsuranceDirectPaymentInfo_contract";
};
export type InsuranceDirectPaymentInfo_contract$key = {
  readonly " $data"?: InsuranceDirectPaymentInfo_contract$data;
  readonly " $fragmentSpreads": FragmentRefs<"InsuranceDirectPaymentInfo_contract">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InsuranceDirectPaymentInfo_contract",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "contractId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "paymentMethod",
      "storageKey": null
    }
  ],
  "type": "Contract",
  "abstractKey": null
};

(node as any).hash = "df17cea7d97b1fbd772cab4c21e8c047";

export default node;
