import React, { cloneElement, useEffect, useState } from 'react';
import { Button, Dialog, Portal } from 'react-native-paper';
import { css } from '@emotion/native';
import Text from './Text';
import { CareshipColors } from './CSTheme';
import { PressableWithConfirmationProps } from './PressableWithConfirmation';

const dialogStyle = css({
  borderRadius: 0,
  maxWidth: 340,
  alignSelf: 'center',
});

const confirmButtonStyle = css({
  marginLeft: 16,
});

export default function PressableWithConfirmation({
  children,
  dialogTitle,
  dialogContent,
  abortButtonLabel,
  bypassConfirmation,
  confirmButtonLabel,
  onAbort,
  onConfirm,
  onPress,
}: PressableWithConfirmationProps) {
  const [isOpen, setIsOpen] = useState(false);

  const handleConfirm = () => {
    setIsOpen(false);
    if (onConfirm !== undefined) {
      onConfirm();
    }
    children.props.onPress();
  };

  const handleAbort = () => {
    if (onAbort !== undefined) {
      onAbort();
    }
    setIsOpen(false);
  };

  const button = cloneElement(children, {
    onPress: () => {
      if (onPress !== undefined) {
        onPress();
      }

      if (bypassConfirmation) {
        children.props.onPress();
        return;
      }

      setIsOpen(true);
    },
  });

  // Hack to set the position of the modal, setTimeout to wait for the dom update
  useEffect(() => {
    setTimeout(() => {
      const modal = document.querySelector('[aria-label="Close modal"]');

      if (modal && modal.parentElement) {
        modal.parentElement.style.position = 'fixed';
      }
    });
  }, [isOpen]);

  return (
    <>
      {button}
      <Portal>
        <Dialog style={dialogStyle} visible={isOpen}>
          {Boolean(dialogTitle) && <Dialog.Title>{dialogTitle}</Dialog.Title>}
          <Dialog.Content>
            <Text>{dialogContent}</Text>
          </Dialog.Content>
          <Dialog.Actions>
            <Button color={CareshipColors.blue} onPress={handleAbort}>
              {abortButtonLabel}
            </Button>
            <Button color={CareshipColors.blue} style={confirmButtonStyle} onPress={handleConfirm}>
              {confirmButtonLabel}
            </Button>
          </Dialog.Actions>
        </Dialog>
      </Portal>
    </>
  );
}
