import { View, StyleSheet } from 'react-native';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import { TouchableRipple } from 'react-native-paper';
import CSTheme, { CareshipColors } from './CSTheme';
import Container from './Container';
import Title from './Title';
import Text from './Text';
import MiniButton from './MiniButton';
import { ArrowRightIcon, MapPinIcon } from './Icon';

const styles = StyleSheet.create({
  container: {
    alignItems: 'flex-start',
  },
  addressRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    paddingVertical: 16,
  },
  routeArrow: {
    margin: 0,
    marginRight: -8,
  },
  addressWithIcon: {
    flexDirection: 'row',
  },
  addressPin: {
    marginRight: 4,
    marginTop: 4,
  },
  postcodeWithIcon: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  postcodePin: {
    marginRight: 4,
  },
  directionsButton: {
    marginTop: -8,
    marginRight: -8,
  },
});

export default function AddressRouteRow({
  street,
  houseNumber,
  postalCode,
  city,
  onPress,
}: {
  onPress: () => void;
  street: string;
  houseNumber: string;
  postalCode: string;
  city: string;
}) {
  return (
    <TouchableRipple rippleColor={CareshipColors.rippleColor} theme={CSTheme} onPress={onPress}>
      <Container style={[styles.addressRow, styles.container]}>
        <>
          {street ? (
            <View style={styles.addressWithIcon}>
              <MapPinIcon style={styles.addressPin} color={CareshipColors.slate400} size={25} />
              <View>
                <Title size="h5">{`${street} ${houseNumber}`.trim()}</Title>
                <Text>{`${postalCode} ${city}`.trim()}</Text>
              </View>
            </View>
          ) : (
            <View style={styles.postcodeWithIcon}>
              <MapPinIcon style={styles.postcodePin} color={CareshipColors.slate400} size={25} />
              <View>
                <Title size="h5">{`${postalCode} ${city}`}</Title>
              </View>
            </View>
          )}

          <MiniButton
            iconRight
            style={styles.directionsButton}
            onPress={onPress}
            icon={
              <ArrowRightIcon style={styles.routeArrow} color={CareshipColors.primary} size={24} />
            }
          >
            <FormattedMessage id="ACTION_DIRECTIONS" />
          </MiniButton>
        </>
      </Container>
    </TouchableRipple>
  );
}
