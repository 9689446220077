import { useIntl} from 'react-intl';
import * as DocumentPicker from 'expo-document-picker';
import * as Permissions from 'expo-permissions';
import * as ImagePicker from 'expo-image-picker';
import {ImagePickerAsset, ImagePickerResult} from 'expo-image-picker';
import { useActionSheet } from '@expo/react-native-action-sheet';
import { Platform } from 'react-native';
import * as ImageManipulator from 'expo-image-manipulator';
import { ImageInfo } from 'expo-image-picker/build/ImagePicker.types';
import { useCallback } from 'react';
import { FileData } from '../../../Infrastructure/Hook/useUploadFile';
import {alertForPermissions} from "./utils";

interface CancelledResult {
  cancelled: true;
}

interface SuccessResult {
  file: FileData;
  cancelled: false;
}

async function compressAndResizeImageToJPEG(
  imageInfo: ImageInfo,
): Promise<ImageManipulator.ImageResult> {
  const MAX_DIMENSION_IN_PX = 4096;
  const dimensionToUpdate = imageInfo.width > imageInfo.height ? 'width' : 'height';
  const dimensionToUpdateTargetValue =
    imageInfo[dimensionToUpdate] > MAX_DIMENSION_IN_PX
      ? MAX_DIMENSION_IN_PX
      : imageInfo[dimensionToUpdate];

  return ImageManipulator.manipulateAsync(
    imageInfo.uri,
    [
      {
        resize: {
          [dimensionToUpdate]: dimensionToUpdateTargetValue,
        },
      },
    ],
    { compress: 0.7, format: ImageManipulator.SaveFormat.JPEG },
  );
}

async function imagePickerResultToCompressedBlobResult(
  imagePickerResult: Promise<ImagePickerResult>,
) {
  const result = await imagePickerResult;

  if (result.cancelled) {
    return <CancelledResult>{ cancelled: true };
  }

  // TODO: fix the cast to ImagePickerAsset. It was made under time constrain
  const { uri } = await compressAndResizeImageToJPEG(result as ImagePickerAsset);
  const [name] = uri.split('/').slice(-1);

  const blob = await (await fetch(uri)).blob();

  return <SuccessResult>{
    cancelled: false,
    file: {
      uri,
      name,
      type: blob.type,
    },
  };
}

export default function useConfirmAndSelectFile() {
  const intl = useIntl();
  const { showActionSheetWithOptions } = useActionSheet();

  const alertPermissionMustBeGranted = useCallback(
      (iosMessage: string, androidMessage: string) => alertForPermissions(intl,iosMessage,androidMessage),
      [intl]
  )

  return useCallback(async (): Promise<null | FileData | File> => {
    if (Platform.OS === 'web') {
      const pickerResult = await DocumentPicker.getDocumentAsync({
        type: 'application/pdf,image/*',
      });

      if (pickerResult.type === 'cancel' || !pickerResult.file) {
        return null;
      }

      // TODO: Compress and resize image for web
      return pickerResult.file;
    }

    const result = await new Promise<CancelledResult | SuccessResult>((resolve) =>
      showActionSheetWithOptions(
        {
          title: intl.formatMessage({ id: 'SERVICE_DOCUMENTATION_UPLOAD_TITLE' }),
          options: [
            intl.formatMessage({ id: 'SERVICE_DOCUMENTATION_UPLOAD_CAMERA' }),
            intl.formatMessage({ id: 'SERVICE_DOCUMENTATION_UPLOAD_IMAGE' }),
            intl.formatMessage({ id: 'SERVICE_DOCUMENTATION_UPLOAD_DOCUMENT' }),
            intl.formatMessage({ id: 'SERVICE_DOCUMENTATION_UPLOAD_CANCEL' }),
          ],
          cancelButtonIndex: 3,
        },
        (i?: number) => {
          const cb: undefined | (() => Promise<CancelledResult | SuccessResult>) = (<
            (() => Promise<CancelledResult | SuccessResult>)[]
          >[
            async () => {
              const permissionResponse = await Permissions.askAsync(
                ...(<Permissions.PermissionType[]>(
                  (Platform.OS === 'android'
                    ? [Permissions.CAMERA, Permissions.MEDIA_LIBRARY]
                    : [Permissions.CAMERA])
                )),
              );

              if (!permissionResponse.granted) {
                alertPermissionMustBeGranted(
                  'SERVICE_DOCUMENTATION_MISSING_CAMERA_PERMISSION_MESSAGE_IOS',
                  'SERVICE_DOCUMENTATION_MISSING_CAMERA_PERMISSION_MESSAGE_ANDROID',
                );

                return <CancelledResult>{ cancelled: true };
              }

              return imagePickerResultToCompressedBlobResult(ImagePicker.launchCameraAsync());
            },
            async () => {
              const majorVersion = parseInt(String(Platform.Version), 10);

              if (Platform.OS === 'ios' && majorVersion === 10) {
                const permissionResponse = await Permissions.askAsync(Permissions.MEDIA_LIBRARY);
                if (!permissionResponse.granted) {
                  alertPermissionMustBeGranted(
                    'SERVICE_DOCUMENTATION_MISSING_LIBRARY_PERMISSION_MESSAGE_IOS',
                    'SERVICE_DOCUMENTATION_MISSING_LIBRARY_PERMISSION_MESSAGE_ANDROID',
                  );

                  return <CancelledResult>{ cancelled: true };
                }
              }

              return imagePickerResultToCompressedBlobResult(ImagePicker.launchImageLibraryAsync());
            },
            async () => {
              const pickerResult = await DocumentPicker.getDocumentAsync({
                type: 'application/pdf',
              });
              return pickerResult.type === 'cancel'
                ? <CancelledResult>{ cancelled: true }
                : <SuccessResult>{
                    cancelled: false,
                    file: {
                      uri: pickerResult.uri,
                      name: pickerResult.name,
                      type: 'application/pdf',
                    },
                  };
            },
          ])[i === undefined ? -1 : i];
          resolve((cb && cb()) || { cancelled: true });
        },
      ),
    );

    if (result.cancelled) {
      return null;
    }

    return result.file;
  }, [alertPermissionMustBeGranted, intl, showActionSheetWithOptions]);
}
