import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';
import { CareshipColors } from '../CSTheme';

interface CrossCircleFilledIconProps extends SvgProps {
  color?: string;
  size?: number;
}

export default function CrossCircleFilledIcon({
  color = CareshipColors.slate400,
  size = 24,
  ...props
}: CrossCircleFilledIconProps) {
  return (
    <Svg viewBox="0 0 48 48" width={size} height={size} {...props}>
      <Path
        d="M24,2.5 C12.1258779,2.5 2.5,12.1258779 2.5,24 C2.5,35.8741221 12.1258779,45.5 24,45.5 C35.8741221,45.5 45.5,35.8741221 45.5,24 C45.5,12.1258779 35.8741221,2.5 24,2.5 Z M34.0606602,13.9393398 C34.5931933,14.471873 34.6416054,15.3052003 34.2058965,15.8924233 L34.0606602,16.0606602 L26.121,24 L34.0606602,31.9393398 C34.6464466,32.5251263 34.6464466,33.4748737 34.0606602,34.0606602 C33.528127,34.5931933 32.6947997,34.6416054 32.1075767,34.2058965 L31.9393398,34.0606602 L24,26.121 L16.0606602,34.0606602 C15.4748737,34.6464466 14.5251263,34.6464466 13.9393398,34.0606602 C13.4068067,33.528127 13.3583946,32.6947997 13.7941035,32.1075767 L13.9393398,31.9393398 L21.879,24 L13.9393398,16.0606602 C13.3535534,15.4748737 13.3535534,14.5251263 13.9393398,13.9393398 C14.471873,13.4068067 15.3052003,13.3583946 15.8924233,13.7941035 L16.0606602,13.9393398 L24,21.879 L31.9393398,13.9393398 C32.5251263,13.3535534 33.4748737,13.3535534 34.0606602,13.9393398 Z"
        fill={color}
      />
    </Svg>
  );
}
