import { FieldValues } from 'react-hook-form/dist/types/fields';
import { SpacedBoxProps } from '@emotion/react';
import { Control, Controller, ErrorOption, Path, useFormState } from 'react-hook-form';
import { KeyboardTypeOptions, TextInputProps } from 'react-native';
import React, { ComponentProps } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from '@emotion/native';
import { FieldPathValue, UnpackNestedValue } from 'react-hook-form/dist/types';
import InputGroup from './InputGroup';
import TextInput from './Form/TextInput';
import InputErrorMessage from './InputErrorMessage';
import withSpacing, { shouldForwardProp } from '../Root/Theme/withSpacing';

const InputG = styled(InputGroup, {
  shouldForwardProp,
})<SpacedBoxProps>(withSpacing());

interface InputWithValidationProps<
  T extends FieldValues = FieldValues,
  N extends Path<T> = Path<T>,
> {
  control: Control<T>;
  name: N;
  defaultValue?: UnpackNestedValue<FieldPathValue<T, N>>;
  disabled?: boolean;
  label: string;
  keyboardType: KeyboardTypeOptions;
  autoCapitalize?: TextInputProps['autoCapitalize'];
  accessibilityLabel?: string;
  accessibilityHint?: string;
  topmost?: boolean;
  textAlign?: ComponentProps<typeof TextInput>['textAlign'];
  bottommost?: boolean;
}

export default function InputWithValidation<
  T extends FieldValues = FieldValues,
  N extends Path<T> = Path<T>,
>({
  label,
  disabled,
  control,
  name,
  defaultValue,
  keyboardType,
  autoCapitalize = 'sentences',
  accessibilityLabel,
  accessibilityHint,
  topmost,
  bottommost,
  marginVertical,
  marginTop,
  marginBottom,
  marginHorizontal,
  padding,
  ...inputProps
}: InputWithValidationProps<T, N> &
  SpacedBoxProps &
  Omit<ComponentProps<typeof TextInput>, keyof InputWithValidationProps>) {
  const { errors } = useFormState<T>({ control });

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange, onBlur, ref, value } }) => {
        const hasError = !!errors[name];

        return (
          <InputG
            topmost={topmost}
            bottommost={bottommost}
            marginVertical={marginVertical}
            marginTop={marginTop}
            marginBottom={marginBottom}
            marginHorizontal={marginHorizontal}
            padding={padding}
            input={
              <TextInput
                ref={ref}
                value={value as string}
                onBlur={onBlur}
                {...inputProps}
                error={Boolean(errors[name])}
                textAlign="left"
                keyboardType={keyboardType}
                autoCapitalize={autoCapitalize}
                accessibilityLabel={accessibilityLabel}
                accessibilityHint={accessibilityHint}
                disabled={disabled}
                label={label}
                onChangeText={onChange}
              />
            }
            errorMessage={
              hasError && (
                <InputErrorMessage visible={hasError}>
                  <FormattedMessage
                    id={(errors[name] as ErrorOption)?.message || 'VALIDATION_REQUIRED'}
                  />
                </InputErrorMessage>
              )
            }
          />
        );
      }}
    />
  );
}
