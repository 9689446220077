import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';
import CSTheme from '../CSTheme';

type TrashIconProps = SvgProps & {
  color?: string;
  size?: number;
};

export default function TrashIcon({ color, size = 24, ...props }: TrashIconProps) {
  return (
    <Svg viewBox="0 0 24 24" width={size} height={size} {...props}>
      <Path
        d="M14.25 1a2.25 2.25 0 012.25 2.25v1.5h4.25a.75.75 0 01.102 1.493l-.102.007H19.5v14.325c0 1.267-.907 2.333-2.094 2.42L17.25 23H6.75c-1.262 0-2.25-1.103-2.25-2.425V6.25H3.25a.75.75 0 01-.102-1.493l.102-.007H7.5v-1.5a2.25 2.25 0 012.096-2.245L9.75 1zM18 6.25H6v14.325c0 .488.303.863.66.918l.09.007h10.5c.395 0 .75-.397.75-.925V6.25zm-8.25 2.5a.75.75 0 01.743.648l.007.102v8a.75.75 0 01-1.493.102L9 17.5v-8a.75.75 0 01.75-.75zm4.5 0a.75.75 0 01.743.648L15 9.5v8a.75.75 0 01-1.493.102L13.5 17.5v-8a.75.75 0 01.75-.75zm0-6.25h-4.5a.75.75 0 00-.75.75v1.5h6v-1.5a.75.75 0 00-.648-.743L14.25 2.5z"
        fill={color || CSTheme.colors.text}
      />
    </Svg>
  );
}
