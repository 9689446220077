import React, { useLayoutEffect } from 'react';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import WebView from 'react-native-webview';
import { useIntl } from 'react-intl';
import Layout from '../../Component/Layout';
import { AccountStackNavigationProp } from '../Navigator/AccountStackNavigationProp';
import { AccountStackNavigatorParamList } from '../Navigator/AccountStackNavigator';

type WebViewScreenRouteProp = RouteProp<AccountStackNavigatorParamList, 'WebView'>;

export default function WebViewScreen() {
  const intl = useIntl();
  const navigation = useNavigation<AccountStackNavigationProp<'WebView'>>();
  const {
    params: { uri, headerTitle },
  } = useRoute<WebViewScreenRouteProp>();

  useLayoutEffect(() => {
    navigation.setOptions({
      headerTitle,
      headerBackTitle: intl.formatMessage({ id: 'HEADER_BACK_BUTTON' }),
    });
  }, [navigation, headerTitle, intl]);

  return (
    <Layout>
      <WebView style={{ flex: 1 }} source={{ uri }} />
    </Layout>
  );
}
