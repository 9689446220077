/**
 * @generated SignedSource<<04d623b79be409234cb598bc8b48b37b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CaregiverFinancialDetailsInput = {
  bank: CaregiverBankDetailsInput;
  tax: CaregiverTaxDetailsInput;
  clientMutationId?: string | null;
};
export type CaregiverBankDetailsInput = {
  iban: string;
  bic: string;
};
export type CaregiverTaxDetailsInput = {
  taxNo: string;
  ustId: string;
};
export type EditFinancialDetailsFormMutation$variables = {
  input: CaregiverFinancialDetailsInput;
};
export type EditFinancialDetailsFormMutation$data = {
  readonly updateCaregiverFinancialDetails: {
    readonly caregiverId: string;
    readonly bank: {
      readonly bic: string;
      readonly iban: string;
    };
    readonly tax: {
      readonly taxNo: string;
      readonly ustId: string;
    };
  } | null;
};
export type EditFinancialDetailsFormMutation = {
  variables: EditFinancialDetailsFormMutation$variables;
  response: EditFinancialDetailsFormMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CaregiverFinancialDetailsPayload",
    "kind": "LinkedField",
    "name": "updateCaregiverFinancialDetails",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "caregiverId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "CaregiverBankDetailsOutput",
        "kind": "LinkedField",
        "name": "bank",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "bic",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "iban",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "CaregiverTaxDetailsOutput",
        "kind": "LinkedField",
        "name": "tax",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "taxNo",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "ustId",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditFinancialDetailsFormMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditFinancialDetailsFormMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "db30507bc1e1d7268a1ad407a9ec3f3e",
    "id": null,
    "metadata": {},
    "name": "EditFinancialDetailsFormMutation",
    "operationKind": "mutation",
    "text": "mutation EditFinancialDetailsFormMutation(\n  $input: CaregiverFinancialDetailsInput!\n) {\n  updateCaregiverFinancialDetails(input: $input) {\n    caregiverId\n    bank {\n      bic\n      iban\n    }\n    tax {\n      taxNo\n      ustId\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "198b171e8ec5ee7c493addc53912d9f2";

export default node;
