/**
 * @generated SignedSource<<d8e3cfef3e00f8416d766f35dc937576>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditContactAndAddressFormQuery$variables = {};
export type EditContactAndAddressFormQuery$data = {
  readonly me: {
    readonly name?: {
      readonly firstName: string;
      readonly lastName: string;
    };
    readonly email?: string;
    readonly " $fragmentSpreads": FragmentRefs<"EditContactAndAddressFormCaregiverFragment">;
  };
};
export type EditContactAndAddressFormQuery = {
  variables: EditContactAndAddressFormQuery$variables;
  response: EditContactAndAddressFormQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "concreteType": "Name",
  "kind": "LinkedField",
  "name": "name",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "EditContactAndAddressFormQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "EditContactAndAddressFormCaregiverFragment"
              }
            ],
            "type": "Caregiver",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "EditContactAndAddressFormQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "phoneNumber",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "mobilePhoneNumber",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "company",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Address",
                "kind": "LinkedField",
                "name": "address",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "street",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "city",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "postalCode",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "additionalInformation",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Caregiver",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/)
            ],
            "type": "Node",
            "abstractKey": "__isNode"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "76d79d2db3462ce3ecb6643b20318c00",
    "id": null,
    "metadata": {},
    "name": "EditContactAndAddressFormQuery",
    "operationKind": "query",
    "text": "query EditContactAndAddressFormQuery {\n  me {\n    __typename\n    ... on Caregiver {\n      name {\n        firstName\n        lastName\n      }\n      email\n      ...EditContactAndAddressFormCaregiverFragment\n    }\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n}\n\nfragment EditContactAndAddressFormCaregiverFragment on Caregiver {\n  id\n  phoneNumber\n  mobilePhoneNumber\n  company\n  address {\n    street\n    city\n    postalCode\n    additionalInformation\n  }\n}\n"
  }
};
})();

(node as any).hash = "b38ce03fd400e26f4560792cbf23c048";

export default node;
