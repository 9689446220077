import React, { ReactElement, useCallback, useLayoutEffect } from 'react';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { Platform, ScrollView, StyleSheet, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { IconButton } from 'react-native-paper';
import { Feather } from '@expo/vector-icons';
import { useIntl } from 'react-intl';
import { CSTheme, HeaderActionButton, Layout } from '../../Component';
import useBooking from '../../../Context/Booking/Hook/useBooking';
import BookingDetail from '../Component/BookingDetail/BookingDetail';
import { AppNavigationParamList } from '../../Root/Navigator/AppNavigator';
import { events, trackEvent } from '../../../Infrastructure/Tracking/Tracking';
import { BookingStackNavigationProp } from '../Navigator/BookingStackNavigationProp';
import SuspenseFullScreenFallback from '../../Component/SuspenseFullScreenFallback';
import useProfile from "../../../Context/Profile/Hook/useProfile";

type EditAppointmentScreenRouteProp = RouteProp<AppNavigationParamList, 'EditAppointment'>;

const styles = StyleSheet.create({
  headerIcon: {
    marginRight: 8,
  },
});

export default function BookingDetailScreen(): ReactElement | null {
  const { profile } = useProfile();
  const { bottom: bottomInset } = useSafeAreaInsets();
  const intl = useIntl();
  const navigation = useNavigation<BookingStackNavigationProp<'BookingDetail'>>();
  const {
    params: { bookingId },
  } = useRoute<EditAppointmentScreenRouteProp>();

  const { booking, contract } = useBooking(bookingId);

  const handlePressFulfillBooking = useCallback(() => {
    navigation.navigate('FulfillBooking', {
      bookingId: booking!.bookingId,
    });
    trackEvent(events.BOOKING_DETAIL_DOCUMENT_CLICK);
  }, [booking, navigation]);

  const handleEditAppointment = useCallback(() => {
    navigation.navigate('EditAppointment', {
      bookingId,
    });
    trackEvent(events.BOOKING_DETAIL_EDIT_CLICK);
  }, [navigation, bookingId]);

  const handleCancelAppointment = useCallback(() => {
    navigation.navigate('CancelAppointment', {
      bookingId,
    });
    trackEvent(events.BOOKING_DETAIL_CANCEL_CLICK);
  }, [navigation, bookingId]);

  useLayoutEffect(() => {
    if (!booking) {
      return;
    }

    const headerRight =
      booking.status === 'created'
        ? () => {
            return Platform.select({
              ios: (
                <HeaderActionButton
                  onPress={handleEditAppointment}
                  title={intl.formatMessage({ id: 'BUTTON_EDIT' })}
                />
              ),
              android: (
                <IconButton
                  style={styles.headerIcon}
                  onPress={handleEditAppointment}
                  icon={() => <Feather color={CSTheme.colors.background} size={24} name="edit" />}
                />
              ),
            });
          }
        : undefined;

    const { firstName, lastName } = contract?.careReceiver.name || {};

    navigation.setOptions({
      headerRight,
      title: contract ? intl.formatMessage({ id: 'NAME_FULL' }, { firstName, lastName }) : '',
    });
  }, [booking, bookingId, contract, navigation, handleEditAppointment, handleCancelAppointment, intl]);

  if (!contract || !booking) {
    return (
      <Layout>
        <SuspenseFullScreenFallback />
      </Layout>
    );
  }

  return (
    <Layout>
      <ScrollView>
        <View style={{ paddingBottom: bottomInset }}>
          <BookingDetail
            onPressFulfillBooking={handlePressFulfillBooking}
            onPressEditBooking={handleEditAppointment}
            onPressCancelBooking={handleCancelAppointment}
            contract={contract}
            booking={booking}
          />
        </View>
      </ScrollView>
    </Layout>
  );
}
