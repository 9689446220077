import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';
import { CareshipColors } from '../CSTheme';

interface MailCheckIconProps extends SvgProps {
  color?: string;
  size?: number;
}

function MailCheckIcon({
  color = CareshipColors.slate400,
  size = 24,
  ...props
}: MailCheckIconProps) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" {...props}>
      <Path
        d="M17.25,24 C13.5220779,24 10.5,20.9779221 10.5,17.25 C10.5,13.5220779 13.5220779,10.5 17.25,10.5 C20.9779221,10.5 24,13.5220779 24,17.25 C24,20.9779221 20.9779221,24 17.25,24 Z M17.25,22.5 C20.1494949,22.5 22.5,20.1494949 22.5,17.25 C22.5,14.3505051 20.1494949,12 17.25,12 C14.3505051,12 12,14.3505051 12,17.25 C12,20.1494949 14.3505051,22.5 17.25,22.5 Z"
        fill="#FFB300"
      />
      <Path
        d="M19.3240186,15.0559752 C19.5725604,14.7246146 20.0426642,14.6574768 20.3740248,14.9060186 C20.7053854,15.1545604 20.7725232,15.6246642 20.5239814,15.9560248 L17.6181938,19.830073 C17.3570576,20.1769596 16.9585863,20.3938704 16.5255038,20.424887 C16.0924214,20.4559035 15.6671059,20.2979906 15.3576699,19.9903301 L13.8576699,18.4903301 C13.5647767,18.1974369 13.5647767,17.7225631 13.8576699,17.4296699 C14.1505631,17.1367767 14.6254369,17.1367767 14.9183301,17.4296699 L16.4167982,18.9281425 L19.3240186,15.0559752 Z"
        fill="#FFB300"
      />
      <Path
        d="M8.25,15 C8.66421356,15 9,15.3357864 9,15.75 C9,16.1642136 8.66421356,16.5 8.25,16.5 L2.25,16.5 C1.00735931,16.5 0,15.4926407 0,14.25 L0,2.25 C0,1.00735931 1.00735931,0 2.25,0 L20.25,0 C21.4926407,0 22.5,1.00735931 22.5,2.25 L22.5,9 C22.5,9.41421356 22.1642136,9.75 21.75,9.75 C21.3357864,9.75 21,9.41421356 21,9 L21,2.25 C21,1.83578644 20.6642136,1.5 20.25,1.5 L2.25,1.5 C1.83578644,1.5 1.5,1.83578644 1.5,2.25 L1.5,14.25 C1.5,14.6642136 1.83578644,15 2.25,15 L8.25,15 Z"
        fill={color}
      />
      <Path
        d="M20.9537452,0.7055103 C21.2820727,0.45297542 21.7529548,0.514417556 22.0054897,0.842745152 C22.2580246,1.17107275 22.1965824,1.64195482 21.8682548,1.8944897 L13.7243005,8.1584546 C12.2656081,9.2805937 10.2343919,9.2805937 8.77574515,8.1584897 L0.631745152,1.8944897 C0.303417556,1.64195482 0.24197542,1.17107275 0.4945103,0.842745152 C0.747045179,0.514417556 1.21792725,0.45297542 1.54625485,0.7055103 L9.69030048,6.9695454 C10.6098015,7.67689677 11.8901985,7.67689677 12.8097452,6.9695103 L20.9537452,0.7055103 Z"
        fill={color}
      />
    </Svg>
  );
}

export default MailCheckIcon;
