import React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import { Avatar, Container, Loading, Title } from '../../Component';
import { CaregiverProfile as CaregiverProfileModel } from '../../../Context/Profile/Store/Profile';
import styles from './ProfileSummary.styles';

type CaregiverProfileProps = {
  profile: CaregiverProfileModel | null;
  style?: StyleProp<ViewStyle>;
};
export default function CaregiverProfile({ profile, style }: CaregiverProfileProps) {
  if (!profile) {
    return <Loading />;
  }

  return (
    <Container style={style}>
      <View style={styles.profileWrap}>
        <Avatar
          style={styles.avatar}
          source={profile.profileImage.getImageURISource(100)}
          size={80}
        />
        <View style={styles.title}>
          <Title
            style={styles.profileName}
          >{`${profile.name?.firstName} ${profile.name?.lastName}`}</Title>
        </View>
      </View>
    </Container>
  );
}
