import React, { ReactElement, ReactNode } from 'react';
import { View, ViewProps } from 'react-native';
import InputErrorMessage from './InputErrorMessage';

type InputGroupProps = ViewProps & {
  errorMessage?: ReactNode;
  input: ReactElement<typeof InputErrorMessage>;
  children?: ReactNode;
};

export default function InputGroup({
  style,
  errorMessage,
  input,
  children,
  ...props
}: InputGroupProps) {
  return (
    <View
      style={[
        style,
        {
          marginBottom: errorMessage ? 4 : 26,
        },
      ]}
      {...props}
    >
      {input}
      {errorMessage}
    </View>
  );
}
