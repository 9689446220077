const UUID_REGEX = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89AB][0-9a-f]{3}-[0-9a-f]{12}$/i;

function isRecord(data: unknown): data is Record<string | number | any, any> {
  return data === Object(data) && !Array.isArray(data);
}

function isUUID(data: unknown): data is string {
  return Boolean(typeof data === 'string' && UUID_REGEX.exec(data));
}

function sanitize<T>(data?: T[]): T[];
function sanitize<R extends string | number | undefined | null | Record<any, any>>(data?: R): R;
function sanitize(data?: unknown): any | Record<any, any> {
  if (isRecord(data)) {
    // NOTE: ignores symbols as keys in objects since they're dropped by JSON.stringify anyway
    return Object.fromEntries(Object.entries(data).map(([k, v]) => [k, sanitize(v)]));
  }

  if (Array.isArray(data)) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return data.map((d) => sanitize(d));
  }

  if (isUUID(data)) {
    return data;
  }

  return `[sanitized ${typeof data}]`;
}

export default sanitize;
