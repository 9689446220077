import styled from '@emotion/native';
import { CareshipColors } from '../../Component/CSTheme';

export default styled.Text({
  marginTop: 38,
  marginBottom: 20,
  textAlign: 'center',
  fontSize: 20,
  lineHeight: 26,
  color: CareshipColors.slate400,
  fontFamily: 'basier-circle-bold',
});
