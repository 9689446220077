import styled from '@emotion/native';
import { Checkbox } from 'react-native-paper';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Pressable } from 'react-native';
import CSTheme, { CareshipColors } from './CSTheme';
import Text from './Text';
import Tooltip from './Tooltip';

const Row = styled.View<{ bottommost?: boolean }>(({ bottommost }) => ({
  flexDirection: 'row',
  marginBottom: bottommost ? 32 : 0,
  alignItems: 'center',
  paddingLeft: 8,
  paddingVertical: 4,
}));

const Label = styled(Text)<{ status?: 'checked' | 'unchecked' }>(
  ({ status, theme: { spacing } }) => [
    spacing({
      marginRight: 'base',
    }),
    {
      color: status === 'checked' ? CareshipColors.slate400 : CareshipColors.slate300,
    },
  ],
);

const TooltipContent = styled(Text)({
  color: CareshipColors.white,
});

interface CheckboxInputRowWithTooltipProps {
  status: 'checked' | 'unchecked';
  subserviceId: string;
  bottommost?: boolean;
  label: string;
  onPress: () => void;
}
const tooltipToLabelMapping = {
  '12104': 'SERVICE_TOOLTIP_ACCOMPANIMENT_BY_CAR',
  '12105': 'SERVICE_TOOLTIP_CARRYING_OUT_ADMINISTRATIVE_TASKS',
  '12086': 'SERVICE_TOOLTIP_COMPANIONSHIP',
  '12091': 'SERVICE_TOOLTIP_LIGHT_HOUSEWORK',
  '12098': 'SERIVCE_TOOLTIP_MOBILITY_HELP',
  '12101': 'SERVICE_TOOLTIP_LIGHT_BODY_CARE',
  // TODO: remove
  '2271': 'SERVICE_TOOLTIP_ACCOMPANIMENT_BY_CAR',
  '2272': 'SERVICE_TOOLTIP_CARRYING_OUT_ADMINISTRATIVE_TASKS',
  '2253': 'SERVICE_TOOLTIP_COMPANIONSHIP',
  '2258': 'SERVICE_TOOLTIP_LIGHT_HOUSEWORK',
  '2265': 'SERIVCE_TOOLTIP_MOBILITY_HELP',
  '2268': 'SERVICE_TOOLTIP_LIGHT_BODY_CARE',
};

export default function CheckboxInputRowWithTooltip({
  status,
  label,
  subserviceId,
  bottommost,
  onPress,
}: CheckboxInputRowWithTooltipProps) {
  return (
    <Row bottommost={bottommost}>
      <Checkbox.Android status={status} onPress={onPress} color={CSTheme.colors.primary} />
      <Pressable accessibilityRole="button" onPress={onPress} style={{ maxWidth: '85%' }}>
        <Label size="large" status={status}>
          {label}
        </Label>
      </Pressable>
      {subserviceId in tooltipToLabelMapping && (
        <Tooltip>
          <TooltipContent>
            <FormattedMessage
              id={tooltipToLabelMapping[subserviceId as keyof typeof tooltipToLabelMapping]}
            />
          </TooltipContent>
        </Tooltip>
      )}
    </Row>
  );
}
