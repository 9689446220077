import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';
import { CareshipColors } from '../CSTheme';

interface CalendarTimeIconProps extends SvgProps {
  color?: string;
  colorAccent?: string;
  size?: number;
}

function CalendarTimeIcon({
  color = CareshipColors.slate400,
  colorAccent = CareshipColors.primary,
  size = 24,
  ...props
}: CalendarTimeIconProps) {
  return (
    <Svg width={size} height={size} viewBox="0 0 25 25" {...props}>
      <Path
        d="M17.25 24a6.75 6.75 0 110-13.5 6.75 6.75 0 010 13.5zm0-1.5a5.25 5.25 0 100-10.5 5.25 5.25 0 000 10.5z"
        fill={colorAccent}
      />
      <Path
        d="M19.902 16.652a.75.75 0 110 1.5H17.25a.75.75 0 01-.75-.75V14.75a.75.75 0 111.5 0v1.902h1.902z"
        fill={colorAccent}
      />
      <Path
        d="M20.75 3a2.25 2.25 0 012.245 2.096L23 5.25v4.644a.75.75 0 01-1.493.102l-.007-.102V5.25a.75.75 0 00-.648-.743L20.75 4.5H3.25a.75.75 0 00-.743.648L2.5 5.25v15.5c0 .38.282.693.648.743l.102.007h6.497a.75.75 0 01.102 1.493L9.747 23H3.25a2.25 2.25 0 01-2.245-2.096L1 20.75V5.25a2.25 2.25 0 012.096-2.245L3.25 3h17.5z"
        fill={color}
      />
      <Path
        fill={color}
        d="M22.25 7.5V9H1.75V7.5zM6.75.5a.75.75 0 01.743.648l.007.102V5.5a.75.75 0 01-1.493.102L6 5.5V1.25A.75.75 0 016.75.5zM17.25.5a.75.75 0 01.743.648L18 1.25V5.5a.75.75 0 01-1.493.102L16.5 5.5V1.25a.75.75 0 01.75-.75z"
      />
    </Svg>
  );
}

export default CalendarTimeIcon;
