import styled from '@emotion/native';
import { FormattedDate, FormattedMessage } from 'react-intl';
import React from 'react';
import { View } from 'react-native';
import { WithoutTheme } from '../../Root/Theme/WithoutTheme';
import { Text } from '../../Component';
import { InvoiceWithStorno } from './useFormattedInvoices';
import InvoiceListItem from './InvoiceListItem';

const Group = styled(View)<{ storno?: Boolean }>(({ theme: { colors }, storno }) => ({
  borderLeftColor: colors.red,
  borderLeftWidth: storno ? 4 : 0,
}));

const StornoTitle = styled<WithoutTheme<typeof Text>>(Text)(({ theme: { colors } }) => ({
  color: colors.red,
  paddingHorizontal: 16,
  paddingTop: 16,
}));

type InvoiceGroupProps = {
  invoice: InvoiceWithStorno;
  onPressInvoice: ({
    invoiceId,
    filename,
    title,
  }: {
    invoiceId: string;
    filename: string;
    title: string;
  }) => Promise<void>;
};

export default function InvoiceGroup({ invoice, onPressInvoice }: InvoiceGroupProps) {
  return (
    <Group storno={Boolean(invoice.storno)}>
      {invoice.storno && (
        <StornoTitle size="small" bold>
          <FormattedMessage id="INVOICE_CANCELLED" />{' '}
          <FormattedDate
            value={invoice.storno.node.invoice.creationDate}
            year="numeric"
            month="2-digit"
            day="2-digit"
          />
        </StornoTitle>
      )}
      <InvoiceListItem
        invoiceId={invoice.node.invoice.invoiceId}
        invoiceNumber={invoice.node.invoice.invoiceNumber}
        invoiceType={invoice.node.invoice.invoiceType}
        onPressInvoice={onPressInvoice}
      />
      {invoice.storno && (
        <InvoiceListItem
          invoiceId={invoice.node.invoice.invoiceId}
          invoiceNumber={invoice.storno.node.invoice.invoiceNumber}
          invoiceType={invoice.storno.node.invoice.invoiceType}
          onPressInvoice={onPressInvoice}
        />
      )}
    </Group>
  );
}
