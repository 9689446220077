import React, { ComponentProps, ReactNode } from 'react';
import { Feather } from '@expo/vector-icons';
import { Link, NavigationProp, useNavigation } from '@react-navigation/native';
import { css } from '@emotion/native';
import { TextStyle } from 'react-native';
import { ParamListBase } from '@react-navigation/routers';
import { CareshipColors } from '../CSTheme';
import MenuItemLine from './MenuItemLine';
import Text from '../Text';
import isActiveRoute from './isActiveRoute';
import { AppNavigationParamList } from '../../Root/Navigator/AppNavigator';

interface MenuItemProps {
  activeRouteNames?: string[];
  iconName: ComponentProps<typeof Feather>['name'];
  children: ReactNode;
  to: string;
  onPress?: () => void;
  secondary?: boolean;
}

const getRootNavigation = <T extends NavigationProp<ParamListBase>>(navigator: T): T =>
  navigator.getParent()
    ? getRootNavigation(navigator.getParent())
    : navigator;

export default function MenuItem({
  iconName,
  to,
  children,
  onPress,
  secondary,
  activeRouteNames = [],
}: MenuItemProps) {
  const nav = useNavigation<NavigationProp<AppNavigationParamList>>();
  const rootNavigation = getRootNavigation(nav);
  const state = rootNavigation.getState();
  const isActive = isActiveRoute(state, activeRouteNames);

  const color = secondary ? CareshipColors.slate200 : CareshipColors.white;

  return (
    <Link
      to={to}
      onPress={onPress}
      style={css<TextStyle>({
        marginVertical: 8,
      })}
    >
      <MenuItemLine active={isActive}>
        <Feather name={iconName} color={isActive ? CareshipColors.slate400 : color} size={22} />
        <Text
          size="large"
          bold
          style={{
            color: isActive ? CareshipColors.slate400 : color,
            marginHorizontal: 16,
            marginTop: 3,
          }}
        >
          {children}
        </Text>
      </MenuItemLine>
    </Link>
  );
}
