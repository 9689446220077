import React, { ReactElement } from 'react';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { Platform } from 'react-native';
import { Feather } from '@expo/vector-icons';
import { FormattedMessage } from 'react-intl';
import { formatISO } from 'date-fns';
import { useLazyLoadQuery } from 'react-relay/hooks';
import { graphql } from 'react-relay';
import ModalHeader from '../../Component/ModalHeader';
import { intl } from '../../../Infrastructure/Intl';
import { CareshipColors } from '../../Component/CSTheme';
import { Container, Loading, Text, Title } from '../../Component';
import { AppNavigationParamList } from '../../Root/Navigator/AppNavigator';
import FirstBookingConfirmForm, {
  FirstBookingConfirmFormPayload,
} from '../Component/FirstBookingConfirmForm/FirstBookingConfirmForm';
import useBookingService from '../../../Context/Booking/Hook/useBookingService';
import { isError } from '../../../Infrastructure/Service';
import { CustomerStackNavigationProp } from '../Navigator/CustomerStackNavigationProp';
import { withLayout } from '../../HOC';
import { FirstBookingConfirmScreenQuery } from './__generated__/FirstBookingConfirmScreenQuery.graphql';

export default withLayout(function FirstBookingConfirmScreen(): ReactElement {
  const navigation = useNavigation<CustomerStackNavigationProp<'CustomerDashboard'>>();
  const route = useRoute<RouteProp<AppNavigationParamList, 'FirstBookingConfirm'>>();
  const bookingService = useBookingService();
  const { contract } = useLazyLoadQuery<FirstBookingConfirmScreenQuery>(
    graphql`
      query FirstBookingConfirmScreenQuery($contractId: ID!) {
        contract(contractId: $contractId) {
          careReceiver {
            name {
              firstName
              lastName
            }
          }
          ...FirstBookingConfirmForm_contract
        }
      }
    `,
    { contractId: route.params.contractId },
  );

  const handleSubmit = async ({
    bookingId,
    endDate,
    startDate,
  }: FirstBookingConfirmFormPayload): Promise<string | void> => {
    const result = await bookingService.firstBookingConfirm({
      bookingId,
      startDate: formatISO(startDate),
      endDate: formatISO(endDate),
    });

    if (isError(result)) {
      return result.errorCode;
    }

    const bookingResult = await bookingService.fetchBooking(bookingId);

    if (isError(bookingResult)) {
      navigation.navigate('CustomerDashboard');

      return Promise.resolve();
    }

    navigation.goBack();

    return Promise.resolve();
  };

  const { lastName, firstName } = contract?.careReceiver.name || {};

  return (
    <>
      <ModalHeader
        backButton={<Feather name="x" size={24} color={CareshipColors.primary} />}
        title={intl.formatMessage({ id: 'FIRST_APPOINTMENT_CONFIRM_MODAL_TITLE' })}
      />
      {!contract ? (
        <Loading />
      ) : (
        <>
          <Container>
            <Title style={{ marginTop: Platform.OS === 'android' ? 32 : undefined }} center thin>
              <FormattedMessage
                id="NAME_FULL"
                values={{
                  lastName,
                  firstName,
                }}
              />
            </Title>
            <Text bold size="xlarge" style={{ marginTop: 32 }}>
              <FormattedMessage id="FIRST_APPOINTMENT_CONFIRM_MODAL_CONFIRM_DATE" />
            </Text>
          </Container>
          <FirstBookingConfirmForm contractFragment={contract} onSubmit={handleSubmit} />
        </>
      )}
    </>
  );
});
