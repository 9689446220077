import * as t from 'io-ts';
import { withMessage } from 'io-ts-types';
import { MessageKey } from '../Intl';

// same as graphql server email type
const EMAIL_ADDRESS_REGEX =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

interface EmailAddressBrand {
  readonly EmailAddress: unique symbol;
}

export default withMessage(
  t.brand(
    t.string,
    (input): input is t.Branded<string, EmailAddressBrand> => EMAIL_ADDRESS_REGEX.test(input),
    'EmailAddress',
  ),
  () => <MessageKey>'VALIDATION_EMAIL',
);
