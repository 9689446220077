import React from 'react';
import BookingListScreen from './BookingListScreen';
import { Screen, Navigator } from '../Navigator/BookingStackNavigator';
import BookingDetailScreen from './BookingDetailScreen';
import { intl } from '../../../Infrastructure/Intl';
import screenOptions from '../../screenOptions';

export default function BookingNavigation() {
  return (
    <Navigator screenOptions={screenOptions} initialRouteName="Diary">
      <Screen
        options={{
          title: intl.formatMessage({ id: 'DIARY' }),
        }}
        name="Diary"
        component={BookingListScreen}
      />
      <Screen
        options={{
          title: '', // set in screen where data exists
        }}
        name="BookingDetail"
        component={BookingDetailScreen}
      />
    </Navigator>
  );
}
