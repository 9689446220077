import { useFragment } from 'react-relay/hooks';
import { graphql } from 'react-relay';
import React from 'react';
import { useNavigation } from '@react-navigation/native';
import { Platform } from 'react-native';
import { ListItemAction } from '../../Component';
import { SupportingDocumentListItem_supportingDocument$key } from './__generated__/SupportingDocumentListItem_supportingDocument.graphql';
import { PdfIcon, WebPageIcon } from '../../Component/Icon';
import { AccountStackNavigationProp } from '../Navigator/AccountStackNavigationProp';
import { logger } from '../../../Infrastructure/Service';

export type ViewableDocument = {
  uri: string;
  headerTitle: string;
  filename: string;
  type: string;
};

export function SupportingDocumentListItem({
  supportingDocument,
}: {
  supportingDocument: SupportingDocumentListItem_supportingDocument$key;
}) {
  const navigation = useNavigation<AccountStackNavigationProp<'HelpCenter'>>();

  const handleDocumentPress = ({ uri, headerTitle, filename, type }: ViewableDocument) => {
    if (Platform.OS === 'web' && ['pdf', 'http'].includes(type)) {
      window.location.href = uri;
      return;
    }

    switch (type) {
      case 'pdf':
        navigation.navigate('PdfView', {
          uri,
          headerTitle,
          filename,
        });
        break;
      case 'http':
        navigation.navigate('WebView', {
          uri,
          headerTitle,
        });
        break;
      default:
        logger.error(`invalid type supplied to handleDocumentPress: ${type}`);
    }
  };

  const { type, filename, uri, title } = useFragment(
    graphql`
      fragment SupportingDocumentListItem_supportingDocument on SupportingDocument {
        filename
        type
        title
        uri
      }
    `,
    supportingDocument,
  );

  return (
    <ListItemAction
      actionLabel=" "
      onPress={() =>
        handleDocumentPress({
          uri,
          headerTitle: title,
          filename,
          type,
        })
      }
      icon={type === 'pdf' ? <PdfIcon /> : <WebPageIcon />}
    >
      {title}
    </ListItemAction>
  );
}
