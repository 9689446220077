import React, { useCallback, useState, useEffect } from 'react';
import { Platform, ScrollView, StyleSheet, View } from 'react-native';
import { FormattedMessage, useIntl } from 'react-intl';
import { isFuture, isToday, isPast } from 'date-fns';
import { graphql } from 'react-relay';
import { usePreloadedQuery, PreloadedQuery } from 'react-relay/hooks';
import { useNavigation, useIsFocused } from '@react-navigation/native';

import DocumentModal from '../../common/DocumentModal';
import { Container, CSTheme, Text, Title } from '../../Component';
import { UpcomingIllustration } from '../../Component/Illustration';
import useBookings from '../../../Context/Booking/Hook/useBookings';
import BookingListItem from '../../Booking/Component/BookingListItem/BookingListItem';
import DividingHeader from '../../Component/DividingHeader';
import HighlightedBooking from './HighlightedBooking';
import { useProposals } from '../../../Context/Proposal/Hook';
import ProposalCard from './ProposalCard';
import { useFocusEffect } from '../../../Infrastructure/Hook';
import {
  MonthlyEarnings,
  CustomerSatisfaction,
  ProposalResponse,
} from '../../Component/CaregiverStats';
import { DashboardQuery } from './__generated__/DashboardQuery.graphql';
import { BookingStatus } from '../../../Context/Booking/Store/Model/Booking';
import { nonNullEdges } from '../../../Infrastructure/Relay';
import { TabNavigationProp } from '../../Root/Navigator/TabNavigationProp';
import { events, trackEvent } from '../../../Infrastructure/Tracking/Tracking';

const styles = StyleSheet.create({
  hero: {
    paddingTop: 32,
    marginBottom: 56,
    alignItems: 'center',
  },
  title: { marginTop: 48, marginBottom: 8 },
  emptyState: { justifyContent: 'flex-start' },
  container: { paddingBottom: 64, backgroundColor: CSTheme.colors.background },
  yellowBar: {
    position: 'absolute',
    backgroundColor: CSTheme.colors.primary,
    height: 95,
    width: '100%',
  },
});

const isActiveContract = ({ node: { isActive } }: { node: { isActive: boolean } }): boolean =>
  isActive;

export const dashboardQuery = graphql`
  query DashboardQuery($splitDate: DateTime!, $startDate: DateTime!) {
    allContracts {
      edges {
        node {
          id
          isActive
        }
      }
    }
    me {
      ... on Caregiver {
        stats {
          ...MonthlyEarnings_monthlyBalance
          ...CustomerSatisfaction_customerSatisfactionScore
          ...ProposalResponse_stats @arguments(startDate: $startDate, splitDate: $splitDate)
        }
      }
    }
  }
`;

type DashboardProps = {
  monthlyEarningsQueryReference: PreloadedQuery<DashboardQuery>;
};

export default function Dashboard({ monthlyEarningsQueryReference }: DashboardProps) {
  const intl = useIntl();
  const navigation = useNavigation<TabNavigationProp<'DashboardTab' | 'Diary'>>();
  const isInFocused = useIsFocused()
  const [showModal, setShowModal] = useState(false);

  const { bookings, refresh: refreshBookings } = useBookings(
    useCallback(
      ({ endDate, status }: { endDate: Date; status: BookingStatus; isUndocumented: boolean }) =>
        isFuture(endDate) && status === 'created',

      [],
    ),
  );

  const { bookings: pastBookings, refresh: refreshPastBookings, undocumentedBookingCount } = useBookings(
    useCallback(
      ({
        endDate,
        isUndocumented,
      }: {
        endDate: Date;
        status: BookingStatus;
        isUndocumented: boolean;
      }) => {
        return isPast(endDate) && isUndocumented;
      },

      [],
    ),
  );
  const { proposals, refresh: refreshProposals } = useProposals('open');

  useFocusEffect(refreshBookings);
  useFocusEffect(refreshProposals);
  useFocusEffect(refreshPastBookings);

  // only working with future bookings so checking it's today is enough
  const highlightedBooking = isToday(bookings[0]?.booking.startDate) ? bookings[0] : undefined;

  const {
    me: { stats },
    allContracts,
  } = usePreloadedQuery(dashboardQuery, monthlyEarningsQueryReference);

  const hasActiveContract =
    (allContracts?.edges || []).filter(nonNullEdges).filter(isActiveContract).length > 0;

  const handleDocumentBookingPress = useCallback(() => {
    navigation.push('FulfillBooking', {
      bookingId: highlightedBooking!.booking.bookingId,
    });

    trackEvent(events.DASHBOARD_OPEN_DOCUMENT_BOOKING);
  }, [navigation, highlightedBooking]);

  const handleBookingSelected = useCallback(
    (bookingId: string) => {
      navigation.navigate('BookingListTab', {
        screen: 'BookingDetail',
        initial: false,
        params: {
          bookingId,
        },
      });

      trackEvent(events.DASHBOARD_OPEN_BOOKING);
    },
    [navigation],
  );

  const handleProposalPress = useCallback(
    (serviceRequestId: string) => {
      navigation.navigate('CustomerTab', {
        screen: 'ProposalDetailView',
        initial: false,
        params: {
          serviceRequestId,
        },
      });

      trackEvent(events.DASHBOARD_OPEN_NEW_PROPOSAL);
    },
    [navigation],
  );

  useEffect(() => {
    if (isInFocused && undocumentedBookingCount > 0 ) {
      setShowModal(true);
    }
  }, [pastBookings.length, undocumentedBookingCount, isInFocused]);

  return (
    <ScrollView contentContainerStyle={[styles.container, !bookings.length && styles.emptyState]}>
      {Platform.OS !== 'web' && (
        /* eslint-disable-next-line react-native-a11y/has-accessibility-hint */
        <View
          accessibilityLabel="yellowDashboardBar"
          testID="yellowDashboardBar"
          style={styles.yellowBar}
        />
      )}
      <DocumentModal
        showModal={showModal}
        setShowModal={setShowModal}
        pastBookings={pastBookings}
      />
      <Container>
        {/* NOTE: contract is nullable to allow investigating why we see errors with missing
            contracts (CC-480) */}
        {highlightedBooking && highlightedBooking.contract ? (
          <HighlightedBooking
            address={`${highlightedBooking.contract.careReceiver.address.street} ${highlightedBooking.contract.careReceiver.address.houseNumber}`}
            name={intl.formatMessage(
              { id: 'NAME_FULL' },
              {
                firstName: highlightedBooking.contract.careReceiver.name.firstName,
                lastName: highlightedBooking.contract.careReceiver.name.lastName,
              },
            )}
            startTime={highlightedBooking.booking.startDate}
            onDocumentBookingPress={handleDocumentBookingPress}
            bookingId={highlightedBooking.booking.bookingId}
            onPress={handleBookingSelected}
          />
        ) : (
          <View style={styles.hero}>
            <UpcomingIllustration style={{ maxWidth: '100%' }} width={343} height={205} />
            <Title size="h4" uppercase center style={styles.title}>
              <FormattedMessage id="DASHBOARD_WELCOME_BACK" />
            </Title>
            <Text size="xlarge" muted center>
              <FormattedMessage id="DASHBOARD_SUBHEADER" />
            </Text>
          </View>
        )}
      </Container>
      {proposals.length !== 0 && (
        <Container vertical>
          {proposals.map((proposal) => (
            <ProposalCard
              key={proposal.serviceRequestId}
              proposal={proposal}
              onPress={handleProposalPress}
            />
          ))}
        </Container>
      )}

      {hasActiveContract && stats && (
        <Container style={{ marginTop: 24, marginBottom: 32 }}>
          <MonthlyEarnings stats={stats} />
          <ProposalResponse stats={stats} />
          <CustomerSatisfaction stats={stats} />
        </Container>
      )}

      {bookings.length !== 0 && (
        <>
          <DividingHeader>
            <FormattedMessage id="UPCOMING_BOOKINGS" />
          </DividingHeader>
          {bookings.slice(0, 7).map(({ booking, contract }) => (
            <BookingListItem
              key={booking.bookingId}
              booking={booking}
              contract={contract}
              showMonth
              onPress={handleBookingSelected}
            />
          ))}
        </>
      )}
    </ScrollView>
  );
}
