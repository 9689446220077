import React, { useEffect } from 'react';
import { ScrollView } from 'react-native';
import { RouteProp, useRoute } from '@react-navigation/native';
import { useQueryLoader } from 'react-relay/hooks';
import { CustomerNavigationParamList } from '../Navigator/CustomerStackNavigator';
import CustomerDetail, { customerDetailQuery } from '../Component/CustomerDetail';
import { CustomerDetailQuery } from '../Component/__generated__/CustomerDetailQuery.graphql';
import { withLayout } from '../../HOC';

export default withLayout(function CustomerDetailScreen() {
  const [preloadedQuery, loadQuery] = useQueryLoader<CustomerDetailQuery>(customerDetailQuery);
  const {
    params: { contractId },
  } = useRoute<RouteProp<CustomerNavigationParamList, 'CustomerDetailView'>>();

  useEffect(() => {
    loadQuery(
      { contractId },
      {
        fetchPolicy: 'store-and-network',
      },
    );
  }, [contractId, loadQuery]);

  return (
    <ScrollView>{preloadedQuery && <CustomerDetail preloadedQuery={preloadedQuery} />}</ScrollView>
  );
});
