/**
 * @generated SignedSource<<71e22db6d77b24a54d721d91d93f2c84>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ServicesQuery$variables = {};
export type ServicesQuery$data = {
  readonly me: {
    readonly id?: string;
    readonly subServiceIds?: ReadonlyArray<string>;
  };
  readonly definitions: {
    readonly services: ReadonlyArray<{
      readonly id: string;
      readonly serviceId: string;
      readonly label: string;
      readonly subServices: ReadonlyArray<{
        readonly id: string;
        readonly subServiceId: string;
        readonly label: string;
      }>;
    }>;
  };
};
export type ServicesQuery = {
  variables: ServicesQuery$variables;
  response: ServicesQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "kind": "InlineFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "subServiceIds",
      "storageKey": null
    }
  ],
  "type": "Caregiver",
  "abstractKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "Definitions",
  "kind": "LinkedField",
  "name": "definitions",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ServiceDefinition",
      "kind": "LinkedField",
      "name": "services",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "serviceId",
          "storageKey": null
        },
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "SubServiceDefinition",
          "kind": "LinkedField",
          "name": "subServices",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "subServiceId",
              "storageKey": null
            },
            (v2/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ServicesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v1/*: any*/)
        ],
        "storageKey": null
      },
      (v3/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ServicesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v1/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v0/*: any*/)
            ],
            "type": "Node",
            "abstractKey": "__isNode"
          }
        ],
        "storageKey": null
      },
      (v3/*: any*/)
    ]
  },
  "params": {
    "cacheID": "fa3b13e77fe77d5a317e605c148bb8ef",
    "id": null,
    "metadata": {},
    "name": "ServicesQuery",
    "operationKind": "query",
    "text": "query ServicesQuery {\n  me {\n    __typename\n    ... on Caregiver {\n      id\n      subServiceIds\n    }\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n  definitions {\n    services {\n      id\n      serviceId\n      label\n      subServices {\n        id\n        subServiceId\n        label\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a9f8fe531a65f8ea2ee80e7ceafa74bc";

export default node;
