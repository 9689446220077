import { ScrollView, View } from 'react-native';
import React from 'react';
import { PreloadedQuery, usePreloadedQuery } from 'react-relay/hooks';
import { graphql } from 'react-relay';
import { FormattedMessage } from 'react-intl';
import { css } from '@emotion/native';
import * as Linking from 'expo-linking';
import { Container, Button, DividingHeader, Text } from '../../Component';
import { SupportingDocumentListQuery } from './__generated__/SupportingDocumentListQuery.graphql';
import { SupportingDocumentListItem } from './SupportingDocumentListItem';
import { CareshipColors } from '../../Component/CSTheme';
import { PhoneIcon, EmailActionIcon } from '../../Component/Icon';
import useProfile from "../../../Context/Profile/Hook/useProfile";

export const supportingDocumentListQuery = graphql`
  query SupportingDocumentListQuery {
    supportingDocumentSections {
      sectionTitle
      documents {
        filename
        uri
        ...SupportingDocumentListItem_supportingDocument
      }
    }
  }
`;

const buttonStyle = css({ marginTop: 26 });
const boxStyle = css({ marginVertical: 24 });
const contentStyle = css({ justifyContent: 'flex-start', paddingLeft: 6 });
const labelStyle = css({ flex: 1, paddingRight: 38, fontSize: 16 });

export default function SupportingDocumentList({
  queryReference,
}: {
  queryReference: PreloadedQuery<SupportingDocumentListQuery>;
}) {
  const data = usePreloadedQuery(supportingDocumentListQuery, queryReference);
  const { profile } = useProfile();
  // these changes should be visible
  const handleEmailLink = () => {
    !profile?.isCareshipEmployee?
    void Linking.openURL(`mailto:alltagshilfen@careship.de`):
    void Linking.openURL(`mailto:info@careshipambulant.de`);
  };

  const handlePhoneLink = () => {
    !profile?.isCareshipEmployee?
    void Linking.openURL(`tel:+4930220128831`):
    void Linking.openURL(`tel:+498000009788`);
  };

  return (
    <ScrollView>
      {data.supportingDocumentSections.map(({ documents, sectionTitle }) => (
        <View key={sectionTitle} style={{ marginTop: 32 }}>
          <DividingHeader>{sectionTitle}</DividingHeader>
          {documents.map((document) => (
            <SupportingDocumentListItem key={document.uri} supportingDocument={document} />
          ))}
        </View>
      ))}

      <View key="CONTACT_CARESHIP" style={{ marginTop: 32 }}>
        <DividingHeader>
          <FormattedMessage id="CONTACT_CARESHIP" />
        </DividingHeader>
        <Container>
          <Button
            onPress={handleEmailLink}
            icon={<EmailActionIcon color={CareshipColors.white} />}
            style={buttonStyle}
            contentStyle={contentStyle}
            labelStyle={labelStyle}
            size="sm"
          >
            <FormattedMessage id="BUTTON_EMAIL" />
          </Button>
          <Button
            onPress={handlePhoneLink}
            icon={<PhoneIcon color={CareshipColors.white} />}
            color={CareshipColors.slate400}
            style={buttonStyle}
            contentStyle={contentStyle}
            labelStyle={labelStyle}
            size="sm"
          >
            <FormattedMessage id="BUTTON_CALL" />
          </Button>
          { profile?.isCareshipEmployee && (
            <View style={boxStyle}>
            </View>
          )}
          { !profile?.isCareshipEmployee && (
          <View style={boxStyle}>
            <Text size="large" center>
              <FormattedMessage id="WORKING_HOURS_LINE_1" />
            </Text>
            <Text size="large" center>
              <FormattedMessage id="WORKING_HOURS_LINE_2" />
            </Text>
          </View>
          )}
        </Container>
      </View>
    </ScrollView>
  );
}
