/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react-native-a11y/has-valid-accessibility-ignores-invert-colors */
import React, {useState, useRef, useEffect} from 'react';
import { View, Image, Platform, StyleSheet } from 'react-native';
import * as ImagePicker from 'expo-image-picker';
import { Camera, CameraType } from 'expo-camera';
import { Card, TextInput, ActivityIndicator, Modal, Portal } from 'react-native-paper';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import { Feather, AntDesign } from '@expo/vector-icons';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import { either } from 'fp-ts';

import {
  Button,
  CheckboxItem,
  Container,
  DateTimeInterval,
  FormGroupTitle,
  Hint,
  InputErrorMessage, PressableWithConfirmation,
  Tag,
  Text,
} from '../../../Component';
import styles from './FulfillBookingForm.styles';
import { events, trackEvent } from '../../../../Infrastructure/Tracking/Tracking';
import { useFulfillBooking, useServiceDefinitions } from '../../../../Context/Booking/Hook';
import { NonNullableProperties } from '../../../../Infrastructure/types/NonNullableProperties';
import { toggleSet } from '../../../../Infrastructure/Set';
import useBookingService from '../../../../Context/Booking/Hook/useBookingService';
import { isError } from '../../../../Infrastructure/Service';
import {alertForPermissions} from "../../../Customer/Hook/utils";
import useProfile from "../../../../Context/Profile/Hook/useProfile";
import {PermissionStatus} from "expo-modules-core/src/PermissionsInterface";
import ProfileStore from "../../../../Context/Profile/Store/ProfileStore";
import stores from "../../../../Infrastructure/Store/stores";
import {intl} from "../../../../Infrastructure/Intl";
import {CareshipColors} from "../../../Component/CSTheme";
import FulfillBookingFeedbackForm from "../FulfillBookingFeedback/FulfillBookingFeedbackForm";

interface FormData {
  bookingId: string | null;
  startDate: Date;
  endDate: Date;
  mainServiceIds: Set<string>;
  otherServiceDescription: string;
  otherServicesEnabled: boolean;
}

interface FulfillBookingFormProps {
  onSubmit: () => void;
  booking: {
    readonly bookingId: string;
    readonly startDate: string;
    readonly endDate: string;
  };
}

function validate(data: FormData): data is NonNullableProperties<FormData> {
  // Text field must not be empty if enabled
  if (data.otherServicesEnabled && data.otherServiceDescription.trim() === '') {
    return false;
  }

  // Must have at least one service
  if (!data.otherServicesEnabled && data.mainServiceIds.size === 0) {
    return false;
  }

  return data.bookingId !== null;
}

export default function FulfillBookingForm({ booking, onSubmit }: FulfillBookingFormProps) {
  const { submitBooking, error, isLoading } = useFulfillBooking();
  const [bookingInterval, setBookingInterval] = useState<{ start: Date; end: Date }>({
    start: new Date(booking.startDate),
    end: new Date(booking.endDate),
  });
  const [image, setImage] = useState<string|null>(null);

  const [focused, setFocused] = useState<'appointmentTime' | null>(null);
  const [mainServiceIds, setMainServiceIds] = useState<Set<string>>(new Set());
  const [otherServicesEnabled, setOtherServicesEnabled] = useState(false);
  const [otherServiceDescription, setOtherServiceDescription] = useState('');
  const intl = useIntl();
  const profile = useProfile();
  const services = useServiceDefinitions();

  const bookingService = useBookingService();
  const [permission, requestPermission] = Camera.useCameraPermissions();
  const [type, setType] = useState(CameraType.back);
  const [visible, setVisible] = React.useState(false);
  const [uploadStatus, setUploadStatus] = React.useState('');
  const [imgLoading, setImgLoading] = React.useState(false);
  const [colorStatus, setColorStatus] = React.useState('');
  const cameraRef = useRef<Camera>();

  const hideDate = false;
  const showModal = async () => {
    if(permission?.status === PermissionStatus.GRANTED) {
        setVisible(true);
    } else {
        alertForPermissions(
          intl,
            'SERVICE_DOCUMENTATION_MISSING_CAMERA_PERMISSION_MESSAGE_IOS',
            'SERVICE_DOCUMENTATION_MISSING_CAMERA_PERMISSION_MESSAGE_ANDROID',
        );
    }
  }

  const hideModal = () => setVisible(false);
  const containerStyle = { backgroundColor: 'black' };

  const formData = {
    bookingId: booking.bookingId,
    startDate: bookingInterval.start,
    endDate: bookingInterval.end,
    mainServiceIds,
    otherServicesEnabled,
    otherServiceDescription,
  };

  const handleServiceSelection = (id: string) => {
    setMainServiceIds(toggleSet(id));
  };

  const handleSubmit = () => {
    void (async () => {
      if (validate(formData)) {
        trackEvent(events.BOOKING_DOCUMENT_FINALIZE_CLICK);

        if (either.isRight(await submitBooking(formData))) {
          onSubmit();
        }
      }
    })();
  };

  const imageUpload = async (base64Img: string) => {
    let imageProof = base64Img
    const base64TagExpression = new RegExp("^data.+base64,")
    const base64Match = base64Img.match(base64TagExpression);
    const hasImageDataTag = base64Match && (base64Match?.index ?? -1) > -1

    if (!hasImageDataTag) {
      imageProof= `data:image/png;base64,${base64Img}`
    }

    const response = await bookingService.uploadImage({
      bookingId: booking?.bookingId,
      imageProof
    });

    if (isError(response)) {
      setUploadStatus('Image upload failed');
      setColorStatus('red');
    } else {
      setUploadStatus('Image uploaded');
      setColorStatus('green');
    }
    setImgLoading(false);
  }

  const handlePickImage = async () => {
    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
    });
    if (!result.canceled) {
      setImgLoading(true);

      setImage(result.assets[0]?.uri ?? null);
      await imageUpload(result.assets[0]?.uri);
    }
  };

  const handleRemoveImg = () => {
    setImage(null);
  };

  function toggleCameraType() {
    setType((current) => (current === CameraType.back ? CameraType.front : CameraType.back));
  }

  const handleTakePicture = async () => {
    const options = {
      quality: 0.75,
      base64: true,
      exif: false,
    };

    const newPhoto = await cameraRef?.current?.takePictureAsync(options);

    setImgLoading(true);
    hideModal();

    if (newPhoto && newPhoto.base64) {
      setImage(newPhoto.uri);
      await imageUpload(newPhoto.base64);
    }
  };

  useEffect(()=>{
    if(profile.profile?.isCareshipEmployee) {
      requestPermission()
    }
  },[permission?.status])

  return (
    <>
      <Portal>
        <Modal visible={visible} dismissable={false} contentContainerStyle={containerStyle}>
          <View style={style.container}>
            <Camera style={style.camera} type={type} ref={cameraRef}>
              <View style={style.buttonContainer}>
                <View style={style.cameraAction}>
                  <Button
                    icon={
                      <AntDesign style={{ marginLeft: 20 }} color="white" size={32} name="close" />
                    }
                    mode="text"
                    onPress={hideModal}
                    contentStyle={{ height: 60, width: 60 }}
                  />
                  <Button
                    mode="contained"
                    onPress={handleTakePicture}
                    contentStyle={{ paddingVertical: 0, height: 62, width: 62 }}
                  />
                  <Button
                    icon={
                      <AntDesign style={{ marginLeft: 20 }} color="white" size={32} name="sync" />
                    }
                    mode="text"
                    onPress={toggleCameraType}
                    contentStyle={{ height: 60, width: 60 }}
                  />
                </View>
              </View>
            </Camera>
          </View>
        </Modal>
      </Portal>

      <View style={[styles.group]}>
        <Container>
          <FormattedMessage tagName={FormGroupTitle} id="APPOINTMENT_DATE_LABEL" />
        </Container>
        <DateTimeInterval
          focused={focused === 'appointmentTime'}
          onIntervalChange={setBookingInterval}
          interval={bookingInterval}
          onFocus={() => setFocused('appointmentTime')}
        />
      </View>
      <View style={styles.bookingHoursRow}>
        <Container style={styles.bookingHoursCol}>
          <FormattedMessage
            id="HOURS_WITH_UNIT"
            values={{
              hours: (
                <FormattedNumber
                  value={differenceInMinutes(bookingInterval.end, bookingInterval.start) / 60}
                  maximumFractionDigits={2}
                />
              ),
            }}
          >
            {(chunks) => (
              <Tag
                color={
                  differenceInMinutes(bookingInterval.end, bookingInterval.start) >= 60 * 8
                    ? 'error'
                    : undefined
                }
              >
                {chunks}
              </Tag>
            )}
          </FormattedMessage>
          <Text style={styles.bookingHoursLabel} muted bold uppercase>
            <FormattedMessage id="WORKED" />
          </Text>
        </Container>
      </View>
      <View style={[styles.group]}>
        <Container>
          <FormattedMessage tagName={FormGroupTitle} id="APPOINTMENT_SERVICES_LABEL" />
          <Hint hint={<FormattedMessage id="APPOINTMENT_SERVICES_HINT" />} />
        </Container>
        {services.map((service) => (
          <CheckboxItem
            key={service.id}
            onPress={() => handleServiceSelection(service.id)}
            status={mainServiceIds.has(service.id) ? 'checked' : 'unchecked'}
            label={intl.locale.startsWith('en') ? service.labelEn : service.labelDe}
            testID={service.id}
          />
        ))}
        <CheckboxItem
          onPress={() => setOtherServicesEnabled((v) => !v)}
          status={otherServicesEnabled ? 'checked' : 'unchecked'}
          label={<FormattedMessage id="OTHER_SERVICES" />}
        />
        {otherServicesEnabled && (
          <View
            style={{
              borderTopColor: 'rgba(255,179,0,0.15)',
              borderTopWidth: 1,
            }}
          >
            <TextInput
              accessibilityLabel="Text input field"
              accessibilityHint={intl.formatMessage({ id: 'OTHER_SERVICES_LABEL' })}
              textAlign="left"
              multiline
              style={{
                marginTop: -1,
                paddingHorizontal: 16,
                paddingBottom: 8,
                backgroundColor: 'rgba(255,179,0,0.1)',
              }}
              underlineColor="rgba(255,179,0,0.15)"
              label={intl.formatMessage({ id: 'OTHER_SERVICES_LABEL' })}
              theme={{ roundness: 0 }}
              maxLength={100}
              onChangeText={(text) => setOtherServiceDescription(text)}
              value={otherServiceDescription}
            />
          </View>
        )}
      </View>
      <Container>
        {error && (
          <InputErrorMessage>
            <FormattedMessage id="ERROR_RESPONSE" values={{ errorCode: error.errorCode }} />
          </InputErrorMessage>
        )}
        {profile.profile?.isCareshipEmployee && (
            <>
              <FormattedMessage tagName={FormGroupTitle} id="UPLOAD_DOCUMENT_LABEL" />
              <Button
                style={styles.submitButton}
                onPress={Platform.OS === 'web' ? handlePickImage : showModal}
                icon={<AntDesign name="camerao" size={24} color="white" />}
              >
                <FormattedMessage id="BUTTON_UPLOAD_FULFILL" />
              </Button>
            </>
        )}
        {image && (
          <View>
            <Card>
              <Card.Actions>
                <Button
                  icon={<AntDesign color="black" size={32} name="close" />}
                  mode="text"
                  onPress={handleRemoveImg}
                />
                {imgLoading && <ActivityIndicator animating color="#FFB300" />}
                {uploadStatus && (
                  <Button
                    icon={<Feather color={colorStatus} size={32} name="check" />}
                    mode="text"
                    color={colorStatus}
                  >
                    {uploadStatus}
                  </Button>
                )}
              </Card.Actions>
              <Image source={{ uri: image }} style={{ height: 500 }} resizeMode="contain" />
            </Card>
          </View>
        )}

        {error && (
            <InputErrorMessage visible={true}>
                <FormattedMessage id="ERROR_RESPONSE" values={{ errorCode: error.errorCode }} />
            </InputErrorMessage>
        )}

        {profile.profile?.isCareshipEmployee && !image && (
          <PressableWithConfirmation
            dialogTitle={intl.formatMessage({ id: 'BOOKING_FULFILL_CONFIRM_TITLE' })}
            dialogContent={intl.formatMessage(
              { id: 'BOOKING_FULFILL_CONFIRM_MESSAGE' }
                )
              }
            confirmButtonLabel={intl.formatMessage({ id: 'BOOKING_FULFILL_CONFIRM_SUBMIT' })}
            abortButtonLabel={intl.formatMessage({ id: 'BUTTON_CANCEL' })}
          >

            <Button
              style={styles.submitButton}
              loading={isLoading}
              disabled={!validate(formData) || isLoading }
              onPress={handleSubmit}
              icon={<Feather color="white" size={32} name="check" />}
            >
              <FormattedMessage id="BUTTON_SUBMIT_FULFILL" />
            </Button>
          </PressableWithConfirmation>
        )}

        {!profile.profile?.isCareshipEmployee && (
            <Button
              style={styles.submitButton}
              loading={isLoading}
              disabled={!validate(formData) || isLoading }
              onPress={handleSubmit}
              icon={<Feather color="white" size={32} name="check" />}
            >
              <FormattedMessage id="BUTTON_SUBMIT_FULFILL" />
            </Button>
        )}

        {profile.profile?.isCareshipEmployee && image && (
          <Button
            style={styles.submitButton}
            loading={isLoading}
            disabled={!validate(formData) || isLoading }
            onPress={handleSubmit}
            icon={<Feather color="white" size={32} name="check" />}
          >
            <FormattedMessage id="BUTTON_SUBMIT_FULFILL" />
          </Button>
        )}

      </Container>
    </>
  );
}

const style = StyleSheet.create({
  container: {
    height: '95%',
  },
  camera: {
    flex: 1,
  },
  buttonContainer: {
    flex: 1,
    flexDirection: 'row',
  },
  cameraAction: {
    flexDirection: 'row',
    flex: 1,
    alignSelf: 'flex-end',
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: 5,
    height: '15%',
  },
  text: {
    fontSize: 24,
    fontWeight: 'bold',
    color: 'white',
  },
});
