import React, { ReactElement } from 'react';
import { AuthLayout } from '../../Component';
import { AuthCard, PasswordResetForm } from '../Component';

export default function PasswordResetScreen(): ReactElement {
  return (
    <AuthLayout>
      <AuthCard mode="raised">
        <PasswordResetForm />
      </AuthCard>
    </AuthLayout>
  );
}
