import React from 'react';
import { graphql, useFragment } from 'react-relay/hooks';
import { FormattedMessage } from 'react-intl';
import styled from '@emotion/native';
import { Card, Text } from '../../Component';
import { IsNewCustomerCard_contract$key } from './__generated__/IsNewCustomerCard_contract.graphql';

interface IsNewCustomerCardProps {
  contractFragment: IsNewCustomerCard_contract$key;
}

const NewCustomerCard = styled(Card)({
  flexDirection: 'row',
  alignItems: 'center',
});

export default function IsNewCustomerCard({ contractFragment }: IsNewCustomerCardProps) {
  const { hasFulfilledBooking, firstBookingStatus, isNewCustomer } = useFragment(
    graphql`
      fragment IsNewCustomerCard_contract on Contract {
        firstBookingStatus
        hasFulfilledBooking
        isNewCustomer
      }
    `,
    contractFragment,
  );

  if (firstBookingStatus !== 'created' || hasFulfilledBooking || isNewCustomer === null) {
    return null;
  }

  return (
    <NewCustomerCard marginHorizontal="base" marginVertical="base">
      <Text bold size="large">
        <FormattedMessage id={isNewCustomer ? 'NEW_CUSTOMER' : 'EXISTING_CUSTOMER'} />
      </Text>
    </NewCustomerCard>
  );
}
