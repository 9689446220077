import * as React from 'react';
import Svg, { Path, Rect, SvgProps } from 'react-native-svg';
import { CareshipColors } from '../CSTheme';

interface Smiley500Props extends SvgProps {
  color?: string;
  size?: number;
}

export default function Smiley500({
  color = CareshipColors.slate400,
  size = 48,
  ...props
}: Smiley500Props) {
  return (
    <Svg width={size} height={size} viewBox="0 0 48 48" {...props}>
      {/* the Rect serves as hit area when the SVG has an onPress handler */}
      <Rect width={48} height={48} fill="transparent" />
      <Path
        d="M24 2C11.8 2 2 11.8 2 24s9.8 22 22 22 22-9.8 22-22S36.2 2 24 2zm0 3c10.5 0 19 8.5 19 19s-8.5 19-19 19S5 34.5 5 24 13.5 5 24 5zm16 20c1 0 1.7 1 1.4 2-.6 1.9-1.5 3.7-2.7 5.3-3.4 4.6-8.7 7.3-14.5 7.3s-11.1-2.7-14.5-7.3C8.5 30.7 7.6 28.9 7 27c-.3-1 .4-2 1.4-2H40zm-2.2 3H10.7l.3.7c.2.4.5.8.7 1.2l.4.6c2.8 3.8 7.2 6.1 12.1 6.1s9.2-2.3 12.1-6.1c.6-.8 1.1-1.6 1.5-2.5zM19 16c1.7 0 3 1.3 3 3s-1.3 3-3 3-3-1.3-3-3 1.3-3 3-3zm10 0c1.7 0 3 1.3 3 3s-1.3 3-3 3-3-1.3-3-3 1.3-3 3-3z"
        fill={color}
      />
    </Svg>
  );
}
