/**
 * @generated SignedSource<<88a95203f9c5376602a02c6af3d0aed2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CaregiverEducationAndQualificationEnum = "NO_FORMAL_NURSING_TRAINING" | "CAREGIVER_ACCORDING_TO_45B" | "HEALTH_CARE_WORKER_AND_NURSING_TRAINING" | "OTHER_MEDICAL_NURSING_TRAINING" | "PEDAGOGY_TRAINING" | "FIRST_AID" | "%future added value";
export type ExperienceAndSkillEnum = "CHILDCARE" | "OFFICE_WORK" | "COMPUTERS_AND_INTERNET" | "WHEELCHAIR" | "POST_REHAB" | "PAIN_RELIEF" | "MASSAGE" | "COACHING" | "%future added value";
export type Locale = "EN" | "DE" | "%future added value";
export type MedicalConditionEnum = "DEMENTIA" | "STROKE" | "LIFE_SUPPORT" | "DEPRESSIONS" | "MENTAL_DISABILITY" | "DOWN_SYNDROME" | "PARKINSONS" | "DIABETES" | "MULTIPLE_SCLEROSIS" | "%future added value";
export type ExperienceAndAbilitiesFormQuery$variables = {
  locale: Locale;
};
export type ExperienceAndAbilitiesFormQuery$data = {
  readonly me: {
    readonly " $fragmentSpreads": FragmentRefs<"ExperienceAndAbilitiesFormCaregiverFragment">;
  };
  readonly definitions: {
    readonly educationAndQualifications: ReadonlyArray<{
      readonly key: CaregiverEducationAndQualificationEnum;
      readonly label: string;
    }>;
    readonly experienceAndSkills: ReadonlyArray<{
      readonly key: ExperienceAndSkillEnum;
      readonly label: string;
    }>;
    readonly medicalConditions: ReadonlyArray<{
      readonly key: MedicalConditionEnum;
      readonly label: string;
    }>;
  };
};
export type ExperienceAndAbilitiesFormQuery = {
  variables: ExperienceAndAbilitiesFormQuery$variables;
  response: ExperienceAndAbilitiesFormQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "locale"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "key",
  "storageKey": null
},
v2 = [
  (v1/*: any*/),
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "locale",
        "variableName": "locale"
      }
    ],
    "kind": "ScalarField",
    "name": "label",
    "storageKey": null
  }
],
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "Definitions",
  "kind": "LinkedField",
  "name": "definitions",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "EducationAndQualificationDefinition",
      "kind": "LinkedField",
      "name": "educationAndQualifications",
      "plural": true,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ExperienceAndSkillDefinition",
      "kind": "LinkedField",
      "name": "experienceAndSkills",
      "plural": true,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "MedicalConditionDefinition",
      "kind": "LinkedField",
      "name": "medicalConditions",
      "plural": true,
      "selections": (v2/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = [
  (v1/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ExperienceAndAbilitiesFormQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ExperienceAndAbilitiesFormCaregiverFragment"
              }
            ],
            "type": "Caregiver",
            "abstractKey": null
          }
        ],
        "storageKey": null
      },
      (v3/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ExperienceAndAbilitiesFormQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "CaregiverExperienceAndSkills",
                "kind": "LinkedField",
                "name": "experienceAndSkills",
                "plural": true,
                "selections": (v5/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "CaregiverExperienceWithMedicalConditions",
                "kind": "LinkedField",
                "name": "experienceWithMedicalConditions",
                "plural": true,
                "selections": (v5/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasCar",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasDriversLicence",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isSmoker",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "noHomesWithPets",
                "storageKey": null
              }
            ],
            "type": "Caregiver",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v4/*: any*/)
            ],
            "type": "Node",
            "abstractKey": "__isNode"
          }
        ],
        "storageKey": null
      },
      (v3/*: any*/)
    ]
  },
  "params": {
    "cacheID": "91bdba093858a0e2ce2d4138c7f44d38",
    "id": null,
    "metadata": {},
    "name": "ExperienceAndAbilitiesFormQuery",
    "operationKind": "query",
    "text": "query ExperienceAndAbilitiesFormQuery(\n  $locale: Locale!\n) {\n  me {\n    __typename\n    ... on Caregiver {\n      ...ExperienceAndAbilitiesFormCaregiverFragment\n    }\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n  definitions {\n    educationAndQualifications {\n      key\n      label(locale: $locale)\n    }\n    experienceAndSkills {\n      key\n      label(locale: $locale)\n    }\n    medicalConditions {\n      key\n      label(locale: $locale)\n    }\n  }\n}\n\nfragment ExperienceAndAbilitiesFormCaregiverFragment on Caregiver {\n  id\n  experienceAndSkills {\n    key\n  }\n  experienceWithMedicalConditions {\n    key\n  }\n  hasCar\n  hasDriversLicence\n  isSmoker\n  noHomesWithPets\n}\n"
  }
};
})();

(node as any).hash = "eb98b168830045a9da071f2f14080223";

export default node;
