/**
 * @generated SignedSource<<e1d65df4cc4b463db473bd664e77a24a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type FirstBookingStatus = "confirmed" | "created" | "declined" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type FirstAppointmentChecklistButton_contract$data = {
  readonly contractId: string;
  readonly firstBookingStatus: FirstBookingStatus;
  readonly hasFulfilledBooking: boolean;
  readonly firstBooking: {
    readonly endDate: string;
  } | null;
  readonly " $fragmentType": "FirstAppointmentChecklistButton_contract";
};
export type FirstAppointmentChecklistButton_contract$key = {
  readonly " $data"?: FirstAppointmentChecklistButton_contract$data;
  readonly " $fragmentSpreads": FragmentRefs<"FirstAppointmentChecklistButton_contract">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FirstAppointmentChecklistButton_contract",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "contractId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstBookingStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasFulfilledBooking",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Booking",
      "kind": "LinkedField",
      "name": "firstBooking",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "endDate",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Contract",
  "abstractKey": null
};

(node as any).hash = "dcabd93aed678129822912e3354aaef6";

export default node;
