import React, { ComponentProps, FC } from 'react';
import { StyleSheet } from 'react-native';
import PaperButton from './PaperButton';
import CSTheme, { CareshipColors } from './CSTheme';

const styles = StyleSheet.create({
  xs: {
    height: 32,
    paddingHorizontal: 8,
  },
  sm: {
    height: 46,
  },
  lg: {
    height: 56,
  },
  xl: {
    height: 56,
  },
  button: {
    shadowOpacity: 0,
    borderRadius: 80,
  },
  content: {
    paddingVertical: 4,
  },
  label: {
    fontSize: 14,
    marginVertical: 0,
  },
  lgLabel: {
    fontSize: 18,
  },
  lightLabel: {
    color: CareshipColors.slate400,
  },
  xlLabel: {
    fontSize: 20,
  },
  thin: {
    ...CSTheme.fonts.light,
  },
});

/**
 * Button component
 */
const Button: FC<
  ComponentProps<typeof PaperButton> & {
    size?: 'xs' | 'sm' | 'lg' | 'xl';
    thin?: boolean;
  }
> = ({
  style,
  onPress,
  disabled,
  contentStyle,
  thin,
  labelStyle,
  dark = true,
  size = 'lg',
  ...rest
}) => (
  <PaperButton
    mode="contained"
    dark={dark}
    uppercase={false}
    style={[styles.button, rest.mode === 'outlined' && { borderColor: rest.color }, style]}
    contentStyle={[styles[size], styles.content, contentStyle]}
    labelStyle={[
      !dark && styles.lightLabel,
      styles.label,
      size === 'lg' && styles.lgLabel,
      size === 'xl' && styles.xlLabel,
      thin && styles.thin,
      labelStyle,
    ]}
    disabled={disabled}
    onPress={disabled ? undefined : onPress}
    {...rest}
  />
);

export default Button;
