import styled from '@emotion/native';
import { Checkbox } from 'react-native-paper';
import React from 'react';
import CSTheme, { CareshipColors } from './CSTheme';
import Text from './Text';

const Row = styled.View<{ bottommost?: boolean }>(({ bottommost }) => ({
  flexDirection: 'row',
  marginBottom: bottommost ? 32 : 0,
  alignItems: 'center',
  paddingLeft: 8,
  paddingVertical: 4,
}));

const Label = styled(Text)<{ status?: 'checked' | 'unchecked' }>(
  ({ status, theme: { spacing } }) => [
    spacing({
      marginRight: 'base',
    }),
    {
      color: status === 'checked' ? CareshipColors.slate400 : CareshipColors.slate300,
    },
  ],
);

const Pressable = styled.Pressable({
  flex: 1,
});

interface CheckboxInputRowProps {
  status: 'checked' | 'unchecked';
  bottommost?: boolean;
  label: string;
  onPress: () => void;
}

export default function CheckboxInputRow({
  status,
  label,
  bottommost,
  onPress,
}: CheckboxInputRowProps) {
  return (
    <Row bottommost={bottommost}>
      <Checkbox.Android status={status} onPress={onPress} color={CSTheme.colors.primary} />
      <Pressable accessibilityRole="button" onPress={onPress}>
        <Label size="large" status={status}>
          {label}
        </Label>
      </Pressable>
    </Row>
  );
}
