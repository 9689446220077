import { StyleSheet } from 'react-native';
import { CareshipColors } from '../../../Component/CSTheme';

export default StyleSheet.create({
  group: {
    marginTop: 16,
    marginBottom: 16,
  },
  submitButton: {
    marginTop: 16,
    marginBottom: 16,
  },
  bookingHoursRow: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: 8,
    marginBottom: 32,
  },
  bookingHoursCol: {
    flexDirection: 'row',
  },
  bookingHoursLabel: {
    marginLeft: 8,
    color: CareshipColors.slate200,
    marginTop: 4,
  },
});
