import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';
import CSTheme from '../CSTheme';

interface CertificateIconProps extends SvgProps {
  color?: string;
  size?: number;
}

export default function CertificateIcon({
  color = CSTheme.colors.text,
  size = 24,
  ...props
}: CertificateIconProps) {
  return (
    <Svg viewBox="0 0 24 24" width={size} height={size} {...props}>
      <Path
        d="M13.528 7a.75.75 0 01.102 1.493l-.102.007H4.75a.75.75 0 01-.102-1.493L4.75 7h8.778zm-2.778 4.5a.75.75 0 01.102 1.493L10.75 13h-6a.75.75 0 01-.102-1.493l.102-.007h6zm8-2.6a5.25 5.25 0 013.585 9.085l.66 5.17a.75.75 0 01-.936.82l-.094-.031L18.6 22.56l-3.365 1.384a.75.75 0 01-1.035-.69l.006-.098.691-5.44A5.25 5.25 0 0118.75 8.9zm-2.477 9.88l-.417 3.286 2.46-1.01a.75.75 0 01.457-.036l.112.036 2.458 1.01-.4-3.144a5.23 5.23 0 01-2.193.478c-.896 0-1.74-.224-2.477-.62zM13.185 1a2.25 2.25 0 011.608.676l.121.134 2.947 3.543c.295.353.473.788.513 1.244l.009.196v.374a.75.75 0 01-1.493.102l-.007-.102v-.374c0-.14-.04-.277-.113-.395l-.062-.085-2.947-3.543a.75.75 0 00-.466-.262l-.11-.008H3.25a.75.75 0 00-.743.648L2.5 3.25v16.5c0 .38.282.693.648.743l.102.007h9.343a.75.75 0 01.102 1.493l-.102.007H3.25a2.25 2.25 0 01-2.245-2.096L1 19.75V3.25a2.25 2.25 0 012.096-2.245L3.25 1h9.935zm5.565 9.4a3.75 3.75 0 100 7.5 3.75 3.75 0 000-7.5zm-8 5.6a.75.75 0 01.102 1.493l-.102.007h-6a.75.75 0 01-.102-1.493L4.75 16h6z"
        fill={color}
      />
    </Svg>
  );
}
