/**
 * @generated SignedSource<<41e81b0a2f67098681ab7e0560b8623f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type FirstBookingStatus = "confirmed" | "created" | "declined" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type IsNewCustomerCard_contract$data = {
  readonly firstBookingStatus: FirstBookingStatus;
  readonly hasFulfilledBooking: boolean;
  readonly isNewCustomer: boolean | null;
  readonly " $fragmentType": "IsNewCustomerCard_contract";
};
export type IsNewCustomerCard_contract$key = {
  readonly " $data"?: IsNewCustomerCard_contract$data;
  readonly " $fragmentSpreads": FragmentRefs<"IsNewCustomerCard_contract">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "IsNewCustomerCard_contract",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstBookingStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasFulfilledBooking",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isNewCustomer",
      "storageKey": null
    }
  ],
  "type": "Contract",
  "abstractKey": null
};

(node as any).hash = "ea29ba72b5e967b3014349b7d76480ae";

export default node;
