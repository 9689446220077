import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  group: {
    marginTop: 16,
    marginBottom: 16,
  },
  submitButton: {
    marginTop: 16,
  },
  deleteHr: {
    marginTop: 24,
    marginBottom: 8,
  },
});
