import { useCallback, useEffect, useRef, useState } from 'react';
import { reaction } from 'mobx';
import { useStores } from '../../../Infrastructure/Store';
import Proposal, { Status } from '../Store/Model/Proposal';
import useProposalService from './useProposalService';
import useLogger from '../../../Infrastructure/Hook/useLogger';

export default function useProposals(status?: Status): {
  proposals: Proposal[];
  openProposalCount: number;
  isRefreshing: boolean;
  refresh: () => void;
} {
  const stores = useStores();
  const [proposals, setProposals] = useState<Proposal[]>([]);
  const [openProposalCount, setOpenProposalCount] = useState<number>(0);
  const proposalService = useProposalService();
  const [isRefreshing, setIsRefreshing] = useState(false);
  const logger = useLogger();
  const isRefreshingRef = useRef(isRefreshing);

  const refresh = useCallback(() => {
    logger.debug('proposals refresh requested');
    if (isRefreshingRef.current) {
      return;
    }

    setIsRefreshing(true);
    void (async () => {
      try {
        await proposalService.fetchProposalList();
      } catch (e) {
        logger.error(`failed fetching proposals, error: ${String(e)}`);
      }
      setIsRefreshing(false);
    })();
  }, [isRefreshingRef, logger, proposalService]);

  useEffect(
    () =>
      reaction(
        () => stores.proposalStore.viewData,
        (vd) => {
          setProposals(vd.proposals.filter(({ status: s }) => !status || s === status));
          setOpenProposalCount(vd.openProposalCount);
        },
        {
          fireImmediately: true,
        },
      ),
    [status, stores.proposalStore],
  );

  useEffect(() => {
    void proposalService.fetchProposalList();
  }, [logger, proposalService]);

  return {
    proposals,
    isRefreshing,
    refresh,
    openProposalCount,
  };
}
