import AsyncStorage from '@react-native-async-storage/async-storage';
import stores, { StoreName } from './stores';
import { logger } from '../Service';

export default async function asyncResetStores() {
  try {
    Object.keys(stores).forEach((s) => {
      stores[s as StoreName].reset();
    });

    await AsyncStorage.multiRemove(Object.keys(stores));
  } catch (e) {
    logger.error('failed to reset stores', e);
  }
}
