/**
 * @generated SignedSource<<a44c41cc3e7ea79c2a0a0041ec218908>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AbsencesFragment$data = {
  readonly absences: {
    readonly __id: string;
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly from: string;
        readonly to: string;
        readonly reason: string;
      };
    } | null> | null;
  };
  readonly id: string;
  readonly " $fragmentType": "AbsencesFragment";
};
export type AbsencesFragment$key = {
  readonly " $data"?: AbsencesFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AbsencesFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./AbsencesFragmentRefetchQuery.graphql'),
      "identifierField": "id"
    }
  },
  "name": "AbsencesFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AbsenceConnection",
      "kind": "LinkedField",
      "name": "absences",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AbsenceEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Absence",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "from",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "to",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "reason",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "kind": "ClientExtension",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "__id",
              "storageKey": null
            }
          ]
        }
      ],
      "storageKey": null
    },
    (v0/*: any*/)
  ],
  "type": "Caregiver",
  "abstractKey": null
};
})();

(node as any).hash = "f7b9e07a873f7bb0480cdae7360c2bf1";

export default node;
