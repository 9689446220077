import React, { ReactElement } from 'react';
import { ImageBackground, ImageSourcePropType } from 'react-native';
// @ts-ignore
import splash from '../../../../assets/splash.png';

export default function SplashScreen(): ReactElement {
  return (
    <ImageBackground
      source={splash as ImageSourcePropType}
      style={{ width: '100%', height: '100%', flex: 1 }}
    />
  );
}
