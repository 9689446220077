import { StyleSheet, View } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import React, { ReactElement, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Feather } from '@expo/vector-icons';
import { ButtonScrollTop, ChecklistCard, Container } from '../../../Component';
import { CareshipColors } from '../../../Component/CSTheme';
import FirstBookingTips from './FirstBookingTips';
import {
  BusinessContractIcon,
  CalendarTimeIcon,
  CoinsBillIcon,
  ConversationIcon,
  MailCheckIcon,
  PersonCheckIcon,
} from '../../../Component/Icon';

const styles = StyleSheet.create({
  checklistContainer: {
    marginTop: 32,
  },
  arrowDown: {
    display: 'flex',
    alignSelf: 'center',
    flexGrow: 1,
    marginBottom: 16,
  },
  scrollTopContainer: {
    marginTop: 24,
    marginBottom: 32,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
});

interface ChecklistItemType {
  id: string;
  title: ReactElement;
  description?: ReactElement;
  icon: ReactElement;
  items?: string[];
}

export default function FirstCallChecklist() {
  const scrollViewRef = useRef<ScrollView>(null);
  const intl = useIntl();
  const checklistItems: ChecklistItemType[] = [
    {
      id: 'checklist-item-1',
      title: <FormattedMessage id="FIRST_CALL_CHECKLIST_ITEM_1_TITLE" />,
      icon: <PersonCheckIcon />,
    },
    {
      id: 'checklist-item-2',
      title: <FormattedMessage id="FIRST_CALL_CHECKLIST_ITEM_2_TITLE" />,
      icon: <CalendarTimeIcon />,
    },
    {
      id: 'checklist-item-3',
      title: <FormattedMessage id="FIRST_CALL_CHECKLIST_ITEM_3_TITLE" />,
      icon: <BusinessContractIcon />,
      items: intl.formatMessage({ id: 'FIRST_CALL_CHECKLIST_ITEM_3_ITEMS' }).split(';'),
    },
    {
      id: 'checklist-item-4',
      title: <FormattedMessage id="FIRST_CALL_CHECKLIST_ITEM_4_TITLE" />,
      description: <FormattedMessage id="FIRST_CALL_CHECKLIST_ITEM_4_DESCRIPTION" />,
      icon: <ConversationIcon />,
    },
    {
      id: 'checklist-item-5',
      title: <FormattedMessage id="FIRST_CALL_CHECKLIST_ITEM_5_TITLE" />,
      icon: <CoinsBillIcon />,
    },
    {
      id: 'checklist-item-6',
      title: <FormattedMessage id="FIRST_CALL_CHECKLIST_ITEM_6_TITLE" />,
      description: <FormattedMessage id="FIRST_CALL_CHECKLIST_ITEM_6_DESCRIPTION" />,
      icon: <MailCheckIcon />,
    },
  ];

  return (
    <ScrollView ref={scrollViewRef}>
      <View style={styles.checklistContainer}>
        {checklistItems.map((item) => (
          <ChecklistCard
            key={item.id}
            title={item.title}
            description={item.description}
            icon={item.icon}
            items={item.items}
          />
        ))}
      </View>
      <Feather
        style={styles.arrowDown}
        color={CareshipColors.slate400}
        size={24}
        name="arrow-down"
      />
      <FirstBookingTips />
      <Container style={styles.scrollTopContainer}>
        <ButtonScrollTop scrollViewRef={scrollViewRef} />
      </Container>
    </ScrollView>
  );
}
