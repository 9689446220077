import Name, { NameObject, NameResponse } from './Name';
import Address, { AddressObject, AddressResponse } from './Address';

export type Gender = 'male' | 'female';

export type PaymentMethod = 'invoice' | 'sepa_direct_debit' | 'direct_reimbursement';

export interface CareReceiverResponse {
  readonly name: NameResponse;
  readonly gender: Gender;
  readonly address: AddressResponse;
  readonly phone_number: string;
  readonly email: string;
  readonly care_degree: string;
  readonly is_self_booker: boolean;
  readonly payment_method: PaymentMethod;
}

export interface CareReceiverObject {
  readonly name: NameObject;
  readonly gender: Gender;
  readonly address: AddressObject;
  readonly phoneNumber: string;
  readonly email: string;
  readonly careDegree: string;
  readonly isSelfBooker: boolean;
  readonly paymentMethod: PaymentMethod;
}

export default class CareReceiver implements CareReceiverObject {
  constructor(
    public readonly name: Name,
    public readonly gender: Gender,
    public readonly address: Address,
    public readonly phoneNumber: string,
    public readonly email: string,
    public readonly careDegree: string,
    public readonly isSelfBooker: boolean,
    public readonly paymentMethod: PaymentMethod,
  ) {}

  static fromObject(careReceiver: CareReceiverObject) {
    return new CareReceiver(
      Name.fromObject(careReceiver.name),
      careReceiver.gender,
      Address.fromObject(careReceiver.address),
      careReceiver.phoneNumber,
      careReceiver.email,
      careReceiver.careDegree,
      careReceiver.isSelfBooker,
      careReceiver.paymentMethod,
    );
  }

  static fromResponse(response: CareReceiverResponse) {
    return new CareReceiver(
      Name.fromResponse(response.name),
      response.gender,
      Address.fromResponse(response.address),
      response.phone_number,
      response.email,
      response.care_degree,
      response.is_self_booker,
      response.payment_method,
    );
  }
}
