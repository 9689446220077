import React from 'react';
import { useIntl } from 'react-intl';
import { Screen, Navigator } from '../Navigator/CustomerStackNavigator';
import CustomerListScreen from '../Screen/CustomerListScreen';
import CustomerDetailScreen from '../Screen/CustomerDetailScreen';
import screenOptions from '../../screenOptions';
import ProposalDetailScreen from '../Screen/ProposalDetailScreen';
import FirstCallChecklistScreen from '../Screen/FirstCallChecklistScreen';
import FirstAppointmentChecklistScreen from '../Screen/FirstAppointmentChecklistScreen';
import ServiceDocumentationScreen from '../Screen/ServiceDocumentationScreen';
import ServiceDocumentationPeriodScreen from '../Screen/ServiceDocumentationPeriodScreen';
import ServiceDocumentationHowToScreen from '../Screen/ServiceDocumentationHowToScreen';
import PdfScreen from '../Screen/PdfScreen';

export default function CustomerNavigation() {
  const intl = useIntl();

  return (
    <Navigator screenOptions={screenOptions} initialRouteName="CustomerDashboard">
      <Screen
        options={{
          title: intl.formatMessage({ id: 'CUSTOMERS' }),
        }}
        name="CustomerDashboard"
        component={CustomerListScreen}
      />
      <Screen
        options={{
          title: '',
        }}
        name="ProposalDetailView"
        component={ProposalDetailScreen}
      />
      <Screen
        options={{
          title: intl.formatMessage({ id: 'CUSTOMER_DETAILS_SCREEN_TITLE' }), // overridden inside screen but visible during loading
        }}
        name="CustomerDetailView"
        component={CustomerDetailScreen}
      />
      <Screen
        options={{
          title: intl.formatMessage({ id: 'FIRST_CALL_CHECKLIST' }),
        }}
        name="FirstCallChecklist"
        component={FirstCallChecklistScreen}
      />
      <Screen
        options={{
          title: intl.formatMessage({ id: 'FIRST_APPOINTMENT_CHECKLIST' }),
        }}
        name="FirstAppointmentChecklist"
        component={FirstAppointmentChecklistScreen}
      />
      <Screen
        options={{
          title: intl.formatMessage({ id: 'SERVICE_DOCUMENTATION' }),
        }}
        name="ServiceDocumentation"
        component={ServiceDocumentationScreen}
      />
      <Screen
        options={{
          title: intl.formatMessage({ id: 'SERVICE_DOCUMENTATION' }),
          headerLargeTitle: false,
          headerBackTitle: intl.formatMessage({ id: 'HEADER_BACK_BUTTON' }),
        }}
        name="ServiceDocumentationPeriod"
        component={ServiceDocumentationPeriodScreen}
      />
      <Screen
        options={{
          title: intl.formatMessage({ id: 'SERVICE_DOCUMENTATION_HOW_TO' }),
        }}
        name="ServiceDocumentationHowTo"
        component={ServiceDocumentationHowToScreen}
      />
      <Screen
        options={{
          title: intl.formatMessage({ id: 'SERVICE_DOCUMENTATION_HOW_TO' }),
          headerLargeTitle: false,
        }}
        name="PdfView"
        component={PdfScreen}
      />
    </Navigator>
  );
}
