import React, { FC, useState, ComponentProps, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useMutation } from 'react-relay/hooks';
import { graphql } from 'react-relay';
import { format } from 'date-fns/fp';
import { Platform } from 'react-native';
import styled from '@emotion/native';
import { Button } from '../../Component';
import { logger } from '../../../Infrastructure/Service';
import AbsenceForm, { Reason } from './AbsenceForm';
import { EditAbsenceFormMutation } from './__generated__/EditAbsenceFormMutation.graphql';
import { CareshipColors } from '../../Component/CSTheme';

const toDateString = format('Y-MM-dd');

const ActionButton = styled<FC<Omit<ComponentProps<typeof Button>, 'theme'>>>(Button)(
  ({ theme: { breakpoints } }) => [
    {
      marginBottom: 24,
    },
    breakpoints.up('md', {
      marginLeft: 16,
    }),
  ],
);

interface EditAbsenceFormProps {
  onCompleted: () => void;
  id: string;
  initialFrom: string;
  initialTo: string;
  initialReason: string;
}

export default function EditAbsenceForm({
  onCompleted,
  id,
  initialFrom,
  initialTo,
  initialReason,
}: EditAbsenceFormProps) {
  const [error, setError] = useState<string | null>(null);
  const [reason, setReason] = useState<Reason>(
    initialReason.toLocaleUpperCase() as unknown as Reason,
  );
  const [from, setFrom] = useState(new Date(initialFrom));
  const [to, setTo] = useState(new Date(initialTo));
  const [commitMutation, isInFlight] = useMutation<EditAbsenceFormMutation>(
    graphql`
      mutation EditAbsenceFormMutation($input: UpdateCaregiverAbsenceInput!) {
        updateCaregiverAbsence(input: $input) {
          absence {
            id
            from
            to
            reason
          }
        }
      }
    `,
  );

  const handleSubmit = useCallback(() => {
    setError(null);
    commitMutation({
      variables: {
        input: {
          id,
          from: toDateString(from),
          to: toDateString(to),
          reason,
        },
      },
      onCompleted,
      onError: (e) => {
        logger.error(e.toString());
        setError('INTERNAL_SERVER_ERROR');
      },
    });
  }, [commitMutation, to, id, onCompleted, reason, from]);

  return (
    <>
      <AbsenceForm
        startDate={from}
        onStartDateChange={setFrom}
        endDate={to}
        onEndDateChange={setTo}
        reason={reason}
        onReasonChange={(value) => setReason(value)}
        error={error && <FormattedMessage id="ERROR_RESPONSE" values={{ errorCode: error }} />}
      >
        {Platform.OS === 'web' && (
          <ActionButton
            mode="outlined"
            color={CareshipColors.slate400}
            disabled={isInFlight}
            onPress={onCompleted}
          >
            <FormattedMessage id="BUTTON_CANCEL" />
          </ActionButton>
        )}
        <ActionButton disabled={isInFlight} loading={isInFlight} onPress={handleSubmit}>
          <FormattedMessage id="ABSENCE_SAVE" />
        </ActionButton>
      </AbsenceForm>
    </>
  );
}
