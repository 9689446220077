import {useCallback, useState} from 'react';
import useConfirmAndSelectFile from './useConfirmAndSelectFile';
import {useBookingService} from "../../../Context/Booking/Hook";
import {isError, isSuccess} from "../../../Infrastructure/Service";

export default function usePickAndUploadFile(bookingId: string, onUploadCompleted: () => void, onFileSelected: ()=> void) {
  const confirmAndSelectFile = useConfirmAndSelectFile();
  const bookingService = useBookingService();

  const [status, setStatus] = useState('');
  const [fileUri, setFileUri] = useState('');
  const showPickerAndUploadFile = useCallback(async () => {
    setStatus('')
    const file = await confirmAndSelectFile();

    if (file) {
      setFileUri(file.uri);
      onFileSelected();
      const results = await bookingService.uploadImage({
        imageProof: file.uri,
        bookingId: bookingId,
      });

      if (isError(results)) {
        console.log(results)
        setStatus('failed')
        onUploadCompleted();
      } else if (isSuccess(results)) {
        setStatus('success')
        onUploadCompleted();
      }

    }
  }, [confirmAndSelectFile, onUploadCompleted, onFileSelected]);

  return { status, fileUri, showPickerAndUploadFile};
}
