import React, { ReactNode } from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';
import { FormattedMessage } from 'react-intl';
import { CareshipColors } from './CSTheme';
import Text from './Text';

type TagProps = {
  mode?: 'dot' | 'text';
  color?: string;
  children?: ReactNode;
  style?: StyleProp<ViewStyle>;
};

const styles = StyleSheet.create({
  dotView: {
    backgroundColor: CareshipColors.slate400,
    width: 16,
    height: 16,
    borderRadius: 8,
    marginRight: 8,
  },
  outerView: {
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'flex-start',
    height: 22,
  },
  dot: {},
  solid: {
    borderRadius: 16,
    paddingVertical: 4,
    paddingHorizontal: 12,
  },
  text: {},
});

export default function CellLabel({
  color = CareshipColors.slate400,
  mode = 'dot',
  style,
  children
}: TagProps) {
  return (
    <View style={[styles.outerView, styles[mode], style]}>
      {mode === 'dot' && <View style={[styles.dotView, { backgroundColor: color }]} />}
      <Text style={[{ color }]} uppercase bold size="small">
         {children}
      </Text>
    </View>
  );
}
