export default {
  BOOKING_CREATE_ABORT_CLICK: 'BOOKING_CREATE_ABORT_CLICK',
  BOOKING_CREATE_FINALIZE_CLICK: 'BOOKING_CREATE_FINALIZE_CLICK',
  BOOKING_DELETE_CLICK: 'BOOKING_DELETE_CLICK',
  BOOKING_DETAIL_DOCUMENT_CLICK: 'BOOKING_DETAIL_DOCUMENT_CLICK',
  BOOKING_DETAIL_EDIT_CLICK: 'BOOKING_DETAIL_EDIT_CLICK',
  BOOKING_DETAIL_CANCEL_CLICK: 'BOOKING_DETAIL_CANCEL_CLICK',
  BOOKING_DETAIL_ROUTE_CLICK: 'BOOKING_DETAIL_ROUTE_CLICK',
  BOOKING_DOCUMENT_FINALIZE_CLICK: 'BOOKING_DOCUMENT_FINALIZE_CLICK',
  BOOKING_LIST_ADD_APPOINTMENT: 'BOOKING_LIST_ADD_APPOINTMENT',
  BOOKING_LIST_OPEN_BOOKING: 'BOOKING_LIST_OPEN_BOOKING',
  BOOKING_LIST_TAB_CHANGE: 'BOOKING_LIST_TAB_CHANGE',
  CONTRACT_CONFIRMATION_YES_CLICK: 'CONTRACT_CONFIRMATION_YES_CLICK',
  CONTRACT_CONFIRMATION_YES_CONFIRM_CLICK: 'CONTRACT_CONFIRMATION_YES_CONFIRM_CLICK',
  CONTRACT_CONFIRMATION_MAYBE_CLICK: 'CONTRACT_CONFIRMATION_MAYBE_CLICK',
  CONTRACT_CONFIRMATION_MAYBE_CONFIRM_CLICK: 'CONTRACT_CONFIRMATION_MAYBE_CONFIRM_CLICK',
  CONTRACT_CONFIRMATION_NO_CLICK: 'CONTRACT_CONFIRMATION_NO_CLICK',
  CONTRACT_CONFIRMATION_NO_CONFIRM_CLICK: 'CONTRACT_CONFIRMATION_NO_CONFIRM_CLICK',
  DASHBOARD_OPEN_BOOKING: 'DASHBOARD_OPEN_BOOKING',
  DASHBOARD_OPEN_DOCUMENT_BOOKING: 'DASHBOARD_OPEN_DOCUMENT_BOOKING',
  DASHBOARD_OPEN_NEW_PROPOSAL: 'DASHBOARD_OPEN_NEW_PROPOSAL',
  FIRST_APPOINTMENT_CHECKLIST_CLICK: 'FIRST_APPOINTMENT_CHECKLIST_CLICK',
  FIRST_BOOKING_CONFIRM_SUBMIT_CLICK: 'FIRST_BOOKING_CONFIRM_SUBMIT_CLICK',
  FIRST_BOOKING_DECLINE_SUBMIT_CLICK: 'FIRST_BOOKING_DECLINE_SUBMIT_CLICK',
  FIRST_BOOKING_UPDATE_STATUS_SUBMIT_CLICK: 'FIRST_BOOKING_UPDATE_STATUS_SUBMIT_CLICK',
  FIRST_CALL_CHECKLIST_CLICK: 'FIRST_CALL_CHECKLIST_CLICK',
  INVOICE_DETAIL_CLICK: 'INVOICE_DETAILS_CLICK',
  INVOICE_DOWNLOAD_CLICK: 'INVOICE_DOWNLOAD_CLICK',
  NAVIGATION_CHANGE: 'NAVIGATION_CHANGE',
  NEW_CONTRACT_NOTIFICATION_TAPPED: 'NEW_CONTRACT_NOTIFICATION_TAPPED',
  NEW_PROPOSAL_NOTIFICATION_TAPPED: 'NEW_PROPOSAL_NOTIFICATION_TAPPED',
  PDF_DOCUMENT_SHARED: 'PDF_DOCUMENT_SHARED',
  PROPOSAL_ACCEPT_CLICK: 'PROPOSAL_ACCEPT_CLICK',
  PROPOSAL_ACCEPT_FINALIZE_CLICK: 'PROPOSAL_ACCEPT_FINALIZE_CLICK',
  PROPOSAL_DECLINE_CLICK: 'PROPOSAL_DECLINE_CLICK',
  PROPOSAL_DECLINE_FINALIZE_CLICK: 'PROPOSAL_DECLINE_FINALIZE_CLICK',
  PROPOSAL_DETAIL_ROUTE_CLICK: 'PROPOSAL_DETAIL_ROUTE_CLICK',
  USER_LOGGED_IN: 'USER_LOGGED_IN',
  USER_LOGGED_OUT: 'USER_LOGGED_OUT',
  EDIT_FINANCIAL_DETAILS_CLICK: 'EDIT_FINANCIAL_DETAILS_CLICK',
  FINANCIAL_DETAILS_UPDATED: 'FINANCIAL_DETAILS_UPDATED',
  ABOUT_ME_UPDATED: 'ABOUT_ME_UPDATED',
  EXPERIENCE_AND_ABILITIES_UPDATED: 'EXPERIENCE_AND_SKILLS_UPDATED',
  CONTACT_AND_ADDRESS_UPDATED: 'CONTACT_AND_ADDRESS_UPDATED',
  LANGUAGES_UPDATED: 'LANGUAGES_UPDATED',
};
