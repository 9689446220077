import { useRef } from 'react';
import { useRelayEnvironment } from 'react-relay/hooks';
import ContractService from '../Service/ContractService';
import { useStores } from '../../../Infrastructure/Store';
import useAuthorizedClient from '../../../Infrastructure/Hook/useAuthorizedClient';

export default function useContractService() {
  const stores = useStores();
  const authorizedClient = useAuthorizedClient();
  const relayEnvironment = useRelayEnvironment();

  const { current: contractService } = useRef(
    new ContractService(stores.contractStore, authorizedClient, relayEnvironment),
  );

  return contractService;
}
