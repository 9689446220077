import { cloneElement, Component, ErrorInfo, isValidElement, ReactElement } from 'react';
import logger from '../../Infrastructure/Service/Logger';

type ErrorOrRenderFn = ReactElement | ((props: { error: Error }) => ReactElement);

function isError(error: Error | null): error is Error {
  return error instanceof Error;
}

export default class ErrorBoundary extends Component<{
  errorComponent: ErrorOrRenderFn;
}> {
  state: { error: Error | null } = {
    error: null,
  };

  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  componentDidCatch(error: Error, { componentStack }: ErrorInfo) {
    logger.error(error.toString(), componentStack);
  }

  render() {
    const { children, errorComponent } = this.props;
    const { error } = this.state;

    if (!isError(error)) {
      return children;
    }

    return isValidElement(errorComponent)
      ? cloneElement(errorComponent, {
          error,
        })
      : errorComponent({ error });
  }
}
