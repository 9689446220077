import { Theme, SpacedBoxProps } from '@emotion/react';
import { FunctionInterpolation, Interpolation } from '@emotion/native';

const spacingProps = [
  'marginVertical',
  'marginTop',
  'marginBottom',
  'marginHorizontal',
  'padding',
  'margin',
  'topmost',
  'bottommost',
];

export const shouldForwardProp = (prop: string | symbol | number): boolean =>
  !spacingProps.includes(prop.toString());

export default <T extends SpacedBoxProps & { theme: Theme }>(
    styleInterpolation?: Interpolation<Omit<T, keyof SpacedBoxProps>>,
    getOptions?: (defaults: T) => SpacedBoxProps,
  ): FunctionInterpolation<T> =>
  (props) => {
    const {
      theme: { spacing },
      margin,
      marginVertical,
      marginTop,
      marginBottom,
      marginHorizontal,
      padding,
      topmost,
      bottommost,
    } = props;

    const options = getOptions ? getOptions(props) : undefined;

    return [
      spacing({
        margin,
        marginVertical: marginVertical || options?.marginVertical,
        marginTop: marginTop || options?.marginTop,
        marginBottom: marginBottom || options?.marginBottom,
        marginHorizontal: marginHorizontal || options?.marginHorizontal,
        padding: padding || options?.padding,
        topmost,
        bottommost,
      }),
      typeof styleInterpolation === 'function'
        ? styleInterpolation({ ...props })
        : styleInterpolation,
    ];
  };
