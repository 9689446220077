import { useRef } from 'react';
import { useRelayEnvironment } from 'react-relay/hooks';
import { useStores } from '../../../Infrastructure/Store';
import useAuthorizedClient from '../../../Infrastructure/Hook/useAuthorizedClient';
import BookingService from '../Service/BookingService';

export default function useBookingService() {
  const stores = useStores();
  const authorizedClient = useAuthorizedClient();
  const relayEnvironment = useRelayEnvironment();

  const { current: bookingService } = useRef(
    new BookingService(stores.bookingStore, authorizedClient, relayEnvironment),
  );

  return bookingService;
}
