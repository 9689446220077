import React, { useEffect } from 'react';
import { useQueryLoader } from 'react-relay/hooks';
import Layout from '../../Component/Layout';
import Services, { servicesQuery } from '../Component/Services';
import { ServicesQuery } from '../Component/__generated__/ServicesQuery.graphql';

export default function ServicesScreen() {
  const [preloadedQuery, loadQuery] = useQueryLoader<ServicesQuery>(servicesQuery);

  useEffect(() => {
    loadQuery(
      {},
      {
        fetchPolicy: 'store-and-network',
      },
    );
  }, [loadQuery]);

  return <Layout>{preloadedQuery && <Services preloadedQuery={preloadedQuery} />}</Layout>;
}
