import { IconButton, Portal } from 'react-native-paper';
import React, { useState } from 'react';
import styled from '@emotion/native';
import { usePopper } from 'react-popper';
import CSTheme from './CSTheme';
import { HelpCircle } from './Icon';
import { TooltipProps } from './Tooltip';

const TooltipOverlay = styled.View<{ open: boolean }>(({ open }) => ({
  pointerEvents: open ? undefined : 'none',
  opacity: open ? 1 : 0,
  borderRadius: 6,
  padding: 24,
  width: 392,
  maxWidth: 'calc(100% - 32px)',
  backgroundColor: CSTheme.colors.text,
  overflow: 'visible',
  position: 'absolute',
  shadowColor: 'rgb(73,81,89)',
  shadowOpacity: 0.5,
  shadowOffset: {
    height: 2,
    width: 0,
  },
  shadowRadius: 4,
}));

export default function Tooltip({ children, boundary: boundaryRef }: TooltipProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [referenceElement, setReferenceElement] = React.useState<HTMLSpanElement | null>(null);
  const [popperElement, setPopperElement] = React.useState<HTMLDivElement | null>(null);
  const boundary = (boundaryRef?.current as unknown as HTMLElement | null) ?? undefined;
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    strategy: 'fixed',
    modifiers: [
      {
        name: 'preventOverflow',
        options: {
          padding: boundary ? 0 : 16,
          boundary,
        },
      },
    ],
  });

  return (
    <>
      <span ref={setReferenceElement} style={styles.reference} {...attributes.reference}>
        <IconButton
          onPress={() => setIsOpen((open) => !open)}
          style={{ margin: 0 }}
          icon={() => <HelpCircle size={20} color={CSTheme.colors.text} />}
        />
        <Portal>
          <TooltipOverlay
            open={isOpen}
            // @ts-ignore
            ref={setPopperElement}
            // @ts-ignore
            style={styles.popper}
            {...attributes.popper}
          >
            {children}
          </TooltipOverlay>
        </Portal>
      </span>
    </>
  );
}
