import React, { ComponentProps, useCallback, useEffect, useState } from 'react';
import {
  addDays,
  addMinutes,
  addYears,
  differenceInMinutes,
  isBefore,
  isSameDay,
  isSameMinute,
  subDays,
  subWeeks,
} from 'date-fns';
import DateTimeInput from './DateTimeInput';
import { intl } from '../../../Infrastructure/Intl';

interface DateTimeIntervalProps {
  onFocus: () => void;
  hideDate?: boolean;
  focused?: boolean;
  onIntervalChange: (interval: { start: Date; end: Date }) => void;
  interval: { start: Date; end: Date };
  mode?: 'date' | 'datetime' | 'time';
  startLabel?: string;
  endLabel?: string;
}

export default function DateTimeInterval({
  onFocus,
  hideDate = false,
  focused: hasFocus,
  interval: { start: startDate, end: endDate },
  onIntervalChange,
  mode = 'datetime',
  endLabel,
  startLabel,
}: DateTimeIntervalProps) {
  const [focusedInput, setFocusedInput] = useState<'startTime' | 'endTime' | null>(null);
  const dateTimeOptions: Partial<ComponentProps<typeof DateTimeInput>> = {
    minuteInterval: 5,
    maximumDate: addYears(new Date(), 1),
    minimumDate: subWeeks(new Date(), 6),
  };

  useEffect(() => {
    if (!focusedInput) {
      onFocus();
    }
  }, [focusedInput, onFocus]);

  useEffect(() => {
    if (!hasFocus) {
      setFocusedInput(null);
    }
  }, [hasFocus]);

  const handleStartDateChange = (value: Date) => {
    let adjustedEnd = endDate;
    const diffStartEndInMinutes = differenceInMinutes(value, adjustedEnd);
    const changeInMinutes = differenceInMinutes(value, startDate);

    /**
     * By moving end date forward the same amount as the size of the change
     * the appointment length is kept the same. If moving whole days the same
     * rule applies keeping both end/start times the same distance from each other.
     */
    if (diffStartEndInMinutes >= 0 || Math.abs(diffStartEndInMinutes) >= 24 * 60) {
      adjustedEnd = addMinutes(adjustedEnd, changeInMinutes);
    }

    onIntervalChange({ start: value, end: adjustedEnd });
  };

  const handleEndDateChange = (value: Date) => {
    let adjustedEnd = value;

    /**
     * If the end time is before or at the same time then move it a day forward in time so
     * duration becomes positive <= 24hours
     */
    if (isBefore(adjustedEnd, startDate) || isSameMinute(adjustedEnd, startDate)) {
      adjustedEnd = addDays(adjustedEnd, 1);
    }

    /**
     * If the end time is more than 24 hours in before the start move it a day back.
     */
    if (differenceInMinutes(value, startDate) > 24 * 60) {
      adjustedEnd = subDays(adjustedEnd, 1);
    }

    onIntervalChange({ start: startDate, end: adjustedEnd });
  };

  const handleStartTimeFocussed = useCallback(() => setFocusedInput('startTime'), []);
  const handleEndTimeFocussed = useCallback(() => setFocusedInput('endTime'), []);

  return (
    <>
      {hideDate==false && (
        <DateTimeInput
          testID="start-date"
          {...dateTimeOptions}
          isFocused={focusedInput === 'startTime'}
          label={startLabel || intl.formatMessage({ id: 'DATEPICKER_START_TIME_LABEL' })}
          onChange={handleStartDateChange}
          onFocused={handleStartTimeFocussed}
          mode={mode}
          value={startDate}
        />
      )}
      {hideDate==true && (
        <DateTimeInput
          testID="start-date"
          {...dateTimeOptions}
          mode={mode === 'datetime' ? 'time' : mode}
          fadeDate={mode !== 'date' && isSameDay(startDate, endDate)}
          isFocused={focusedInput === 'startTime'}
          label={startLabel || intl.formatMessage({ id: 'DATEPICKER_START_TIME_LABEL' })}
          onChange={handleStartDateChange}
          onFocused={handleStartTimeFocussed}
          value={startDate}
        />
      )}
      <DateTimeInput
        testID="end-date"
        {...dateTimeOptions}
        mode={mode === 'datetime' ? 'time' : mode}
        fadeDate={mode !== 'date' && isSameDay(startDate, endDate)}
        isFocused={focusedInput === 'endTime'}
        label={endLabel || intl.formatMessage({ id: 'DATEPICKER_END_TIME_LABEL' })}
        onChange={handleEndDateChange}
        onFocused={handleEndTimeFocussed}
        value={endDate}
      />
    </>
  );
}
