import styled, { css } from '@emotion/native';
import { Platform, View } from 'react-native';
import { FormattedMessage } from 'react-intl';
import React, { ReactNode, useCallback } from 'react';
import { TouchableRipple } from 'react-native-paper';
import { useFragment } from 'react-relay/hooks';
import { graphql } from 'react-relay';
import { useNavigation } from '@react-navigation/native';
import { CareshipColors } from '../../Component/CSTheme';
import { PaymentMethodDirIcon } from '../../Component/Icon';
import { Card, Text } from '../../Component';
import { InsuranceDirectPaymentInfo_contract$key } from './__generated__/InsuranceDirectPaymentInfo_contract.graphql';
import { CustomerStackNavigationProp } from '../Navigator/CustomerStackNavigationProp';

const InsuranceDirectPaymentCard = styled(Card)(({ theme }) => ({
  borderColor: theme.colors.slate400,
  borderWidth: 1,
  borderStyle: 'solid',
  display: 'flex',
  flexDirection: 'row',
  borderRadius: 8,
}));

const InsuranceDirectPaymentCardContent = styled(View)({
  paddingLeft: 8,
  display: 'flex',
  flexShrink: 1,
});

const InsuranceDirectPaymentCardLink = styled(Text)<{ onPress: () => void }>({
  textDecorationLine: 'underline',
});

const insuranceDirectPaymentCardContainer = css({
  marginVertical: 32,
  marginHorizontal: 16,
});

interface InsuranceDirectPaymentCardContainerProps {
  children: ReactNode;
  onPressServiceDocumentation: () => void;
}

const InsuranceDirectPaymentCardContainer = ({
  children,
  onPressServiceDocumentation,
}: InsuranceDirectPaymentCardContainerProps) => {
  if (Platform.OS === 'web') {
    return <View style={insuranceDirectPaymentCardContainer}>{children}</View>;
  }

  return (
    <TouchableRipple
      rippleColor={CareshipColors.rippleColor}
      onPress={onPressServiceDocumentation}
      style={insuranceDirectPaymentCardContainer}
    >
      {children}
    </TouchableRipple>
  );
};

interface InsuranceDirectPaymentInfoProps {
  contractFragment: InsuranceDirectPaymentInfo_contract$key;
}

export default function InsuranceDirectPaymentInfo({
  contractFragment,
}: InsuranceDirectPaymentInfoProps) {
  const navigation = useNavigation<CustomerStackNavigationProp<'CustomerDetailView'>>();
  const { contractId, paymentMethod } = useFragment(
    graphql`
      fragment InsuranceDirectPaymentInfo_contract on Contract {
        contractId
        paymentMethod
      }
    `,
    contractFragment,
  );

  const handlePressServiceDocumentationPeriod = useCallback(() => {
    navigation.navigate('ServiceDocumentation', { contractId });
  }, [navigation, contractId]);

  if (paymentMethod !== 'direct_reimbursement') {
    return null;
  }

  return (
    <InsuranceDirectPaymentCardContainer
      onPressServiceDocumentation={handlePressServiceDocumentationPeriod}
    >
      <InsuranceDirectPaymentCard padding="base">
        <View>
          <PaymentMethodDirIcon />
        </View>
        <InsuranceDirectPaymentCardContent>
          <Text bold size="large">
            <FormattedMessage id="INSURANCE_DIRECT_PAYMENT_TITLE" />
          </Text>
          <Text>
            <Text size="large">
              <FormattedMessage id="INSURANCE_DIRECT_PAYMENT_DESCRIPTION" />{' '}
            </Text>
            <InsuranceDirectPaymentCardLink
              size="large"
              onPress={handlePressServiceDocumentationPeriod}
            >
              <FormattedMessage id="INSURANCE_DIRECT_PAYMENT_LINK" />
            </InsuranceDirectPaymentCardLink>
          </Text>
        </InsuranceDirectPaymentCardContent>
      </InsuranceDirectPaymentCard>
    </InsuranceDirectPaymentCardContainer>
  );
}
