import { useIntl } from 'react-intl';
import React from 'react';
import { Helmet } from 'react-helmet';

export default function HtmlLang() {
  const { locale } = useIntl();
  const [countryCode] = locale.split('-');

  return (
    <Helmet>
      <html lang={countryCode} />
    </Helmet>
  );
}
