import { useContext } from 'react';
import storeContext from '../Store/storeContext';

export default function useStores() {
  const stores = useContext(storeContext);

  if (!stores) {
    // avoids having to null check when using the hook
    throw new Error('useStores must be used within a StoreProvider.');
  }

  return stores;
}
