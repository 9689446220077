/**
 * @generated SignedSource<<37036e31e78dea86b76a5ee28e9e5c54>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FirstBookingDeclineScreenQuery$variables = {
  contractId: string;
};
export type FirstBookingDeclineScreenQuery$data = {
  readonly contract: {
    readonly " $fragmentSpreads": FragmentRefs<"FirstBookingDeclineForm_contract">;
  } | null;
};
export type FirstBookingDeclineScreenQuery = {
  variables: FirstBookingDeclineScreenQuery$variables;
  response: FirstBookingDeclineScreenQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "contractId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "contractId",
    "variableName": "contractId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FirstBookingDeclineScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Contract",
        "kind": "LinkedField",
        "name": "contract",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "FirstBookingDeclineForm_contract"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FirstBookingDeclineScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Contract",
        "kind": "LinkedField",
        "name": "contract",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CareReceiver",
            "kind": "LinkedField",
            "name": "careReceiver",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Name",
                "kind": "LinkedField",
                "name": "name",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "firstName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lastName",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstBookingStatus",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "firstBookingConfirmationStatus",
            "kind": "LinkedField",
            "name": "firstBookingConfirmationStatus",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "reason",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "isFirstBooking",
                "value": true
              }
            ],
            "concreteType": "Booking",
            "kind": "LinkedField",
            "name": "firstBooking",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "bookingId",
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": "firstBooking(isFirstBooking:true)"
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "bd24abf30948e1514e385cb9aba5bcbe",
    "id": null,
    "metadata": {},
    "name": "FirstBookingDeclineScreenQuery",
    "operationKind": "query",
    "text": "query FirstBookingDeclineScreenQuery(\n  $contractId: ID!\n) {\n  contract(contractId: $contractId) {\n    ...FirstBookingDeclineForm_contract\n    id\n  }\n}\n\nfragment FirstBookingDeclineForm_contract on Contract {\n  careReceiver {\n    name {\n      firstName\n      lastName\n    }\n  }\n  firstBookingStatus\n  firstBookingConfirmationStatus {\n    reason\n  }\n  firstBooking(isFirstBooking: true) {\n    bookingId\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "d38dd9a0fb9a735dda66245f569631c0";

export default node;
