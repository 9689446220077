import RelayModernEnvironment from 'relay-runtime/lib/store/RelayModernEnvironment';
import { loadQuery } from 'react-relay/hooks';
import AuthorizedClient, { isError } from '../../../Infrastructure/Service/AuthorizedClient';
import ContractStore, { ContractAggregateListResponse } from '../Store/ContractStore';
import logger from '../../../Infrastructure/Service/Logger';
import { ContractAggregateResponse } from '../Store/Model/Contract';
import fetchSingleContract from './fetchSingleContract';
import fetchAllContracts from './fetchAllContracts';
import { fetchSingleContractQuery } from './__generated__/fetchSingleContractQuery.graphql';
import { fetchAllContractsQuery } from './__generated__/fetchAllContractsQuery.graphql';

/**
 * @deprecated only required while bookings are fetched from backend api
 * instead of graphql.
 */
export default class ContractService {
  constructor(
    private contractStore: ContractStore,
    private apiClient: AuthorizedClient,
    private relayEnvironment: RelayModernEnvironment,
  ) {}

  async fetchContract(contractId: string): Promise<void> {
    const endpoint = `/caregiver-app/contract-aggregate/${contractId}`;
    // also refresh the relay store
    loadQuery<fetchSingleContractQuery>(
      this.relayEnvironment,
      fetchSingleContract,
      { contractId },
      {
        fetchPolicy: 'store-and-network',
      },
    );
    const result = await this.apiClient.request<ContractAggregateResponse>(endpoint, {method:"GET"});

    if (!isError(result)) {
      this.contractStore.handleContractResponse(result.data);

      return;
    }

    logger.warn(
      `Failed fetching ${endpoint}. Response: ${result.status} ${JSON.stringify(result.errorCode)}`,
    );
  }

  async fetchContractList(): Promise<void> {
    const endpoint = '/caregiver-app/contract-aggregate';
    loadQuery<fetchAllContractsQuery>(
      this.relayEnvironment,
      fetchAllContracts,
      {},
      {
        fetchPolicy: 'store-and-network',
      },
    );
    const result = await this.apiClient.request<ContractAggregateListResponse>(endpoint, {method:"GET"});

    if (isError(result)) {
      logger.warn(
        `Failed fetching ${endpoint}. Response: ${result.status} ${JSON.stringify(
          result.errorCode,
        )}`,
      );

      return;
    }

    if (!Array.isArray(result.data)) {
      logger.error(
        `expected contract list result.data to be an array, received result ${JSON.stringify(
          result,
        )}`,
      );

      return;
    }

    this.contractStore.handleContractListResponse(result.data);
  }
}
