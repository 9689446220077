import * as React from 'react';
import Svg, { Path, SvgProps, Line } from 'react-native-svg';
import { CareshipColors } from '../CSTheme';

interface ConversationIconProps extends SvgProps {
  color?: string;
  size?: number;
}

function ConversationIcon({
  color = CareshipColors.slate400,
  size = 24,
  ...props
}: ConversationIconProps) {
  return (
    <Svg width={size} height={size} viewBox="0 0 26 26" {...props}>
      <Path
        d="M3.65443601,5.37605557 C5.58154454,2.30464292 9.09311665,0.603088395 12.6979084,0.99398367 C16.3027002,1.38487894 19.3679124,3.79960474 20.5918548,7.21271005 C20.731674,7.60261194 20.5289418,8.03203568 20.13904,8.17185482 C19.7491381,8.31167396 19.3197143,8.10894184 19.1798952,7.71903995 C18.149802,4.84650565 15.5700611,2.81422654 12.5361999,2.48524164 C9.50233859,2.15625675 6.54693277,3.58831738 4.9250416,6.17327663 C3.30315044,8.75823589 3.29948706,12.0423199 4.91560718,14.6308911 C5.03181544,14.8170242 5.05976598,15.0429437 4.99607679,15.2492514 L4.9565799,15.350496 L3.303,18.821 L6.77450395,17.1684201 C6.97245542,17.0741709 7.19987919,17.0719637 7.39752159,17.1584489 L7.49358669,17.2090671 C8.3984122,17.7729446 9.40740394,18.1490829 10.4605434,18.3151079 C10.8697038,18.3796111 11.1491036,18.7635913 11.0846004,19.1727517 C11.0200972,19.5819121 10.636117,19.8613119 10.2269566,19.7968087 C9.22549478,19.6389305 8.25761804,19.32121 7.35985338,18.8572637 L7.059,18.694 L2.04116271,21.0834132 C1.43390068,21.3725444 0.801320361,20.7826495 1.00470213,20.1750088 L1.04158677,20.0838373 L3.43,15.066 L3.31529688,14.8573191 C1.78168517,11.9756944 1.83856288,8.49409523 3.48454123,5.65751719 L3.65443601,5.37605557 Z"
        fill={color}
        fillRule="nonzero"
      />
      <Line
        x1="10.703125"
        y1="11.78125"
        x2="10.703125"
        y2="11.78125"
        stroke="#000000"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M18.6373796,10.5045046 L18.3907175,10.5 C16.1271259,10.5004459 14.0637184,11.7974914 13.0823887,13.837304 C12.1010451,15.8771455 12.3754115,18.2988862 13.788225,20.0674863 L13.9488107,20.2600045 C15.2619472,21.7680675 17.2483188,22.5028002 19.2171356,22.2232963 L19.3977175,22.194 L22.5013034,24.1076553 L22.5892236,24.1545937 C23.1222082,24.3970388 23.7386348,23.9442427 23.6338669,23.3409297 L23.0497175,19.976 L23.1352289,19.8664627 C24.3956389,18.1657426 24.6496353,15.9009932 23.7651218,13.948736 C22.8439708,11.9156145 20.8538293,10.5873369 18.6373796,10.5045046 Z M18.3970495,12.0003925 C20.1204322,11.9925902 21.6875952,12.9980009 22.3988148,14.5677718 C23.1100372,16.1375488 22.8325664,17.9786971 21.6902934,19.2691426 L21.6219856,19.3591413 C21.5203446,19.5163932 21.4804416,19.707408 21.5129431,19.8945703 L21.8707175,21.956 L19.9343817,20.7627614 C19.7666625,20.6593505 19.5649853,20.6260947 19.3729441,20.6701823 C17.7285243,21.0476985 16.0132403,20.4495097 14.960196,19.1312789 C13.9071516,17.8130481 13.7026518,16.0079972 14.4340989,14.4875959 C15.1655373,12.9672127 16.7035202,12.0003925 18.3970495,12.0003925 Z"
        fill={color}
        fillRule="nonzero"
      />
      <Path
        d="M18.25,17.625 L18.3597581,17.6318175 C18.7912005,17.6858108 19.125,18.0539239 19.125,18.5 C19.125,18.9832492 18.7332492,19.375 18.25,19.375 C17.7667508,19.375 17.375,18.9832492 17.375,18.5 C17.375,18.0539239 17.7087995,17.6858108 18.1402419,17.6318175 L18.25,17.625 Z"
        fill="#FFB300"
        fillRule="nonzero"
      />
      <Path
        d="M18.25,12.75 C18.6296958,12.75 18.943491,13.0321539 18.9931534,13.3982294 L19,13.5 L19,16.375 C19,16.7892136 18.6642136,17.125 18.25,17.125 C17.8703042,17.125 17.556509,16.8428461 17.5068466,16.4767706 L17.5,16.375 L17.5,13.5 C17.5,13.0857864 17.8357864,12.75 18.25,12.75 Z"
        fill="#FFB300"
        fillRule="nonzero"
      />
      <Path
        d="M11.1969666,4.76571807 C12.566158,4.6242547 13.8526471,5.45150878 14.1756322,6.72579942 C14.5044505,8.02310388 13.7205088,9.3209082 12.395987,9.74667716 C12.322517,9.77029416 12.2770333,9.81108387 12.2588955,9.85113445 L12.2498492,9.89027524 L12.2498492,10.5 C12.2498492,10.9142136 11.9140628,11.25 11.4998492,11.25 C11.1201535,11.25 10.8063583,10.9678461 10.7566958,10.6017706 L10.7498492,10.5 L10.7498494,9.89070769 C10.7494612,9.21754867 11.1728609,8.62792628 11.7996089,8.36898057 L11.9369442,8.31864365 C12.5300405,8.12799219 12.84954,7.59906536 12.7216108,7.09433956 C12.5878486,6.56659996 12.0028673,6.19043813 11.3511244,6.2577755 C10.7557976,6.31928402 10.316734,6.72742505 10.2569596,7.20650389 L10.25,7.31819431 C10.249913,7.73240786 9.91405603,8.06812376 9.49984247,8.06803676 C9.08562892,8.06794976 8.74991302,7.73209281 8.75,7.31787925 C8.75027796,5.99454547 9.81715336,4.90827888 11.1969666,4.76571807 Z"
        fill="#FFB300"
        fillRule="nonzero"
      />
      <Path
        d="M11.5,12.125 L11.6097581,12.1318175 C12.0412005,12.1858108 12.375,12.5539239 12.375,13 C12.375,13.4832492 11.9832492,13.875 11.5,13.875 C11.0167508,13.875 10.625,13.4832492 10.625,13 C10.625,12.5539239 10.9587995,12.1858108 11.3902419,12.1318175 L11.5,12.125 Z"
        fill="#FFB300"
        fillRule="nonzero"
      />
    </Svg>
  );
}

export default ConversationIcon;
