import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';
import { CareshipColors } from '../CSTheme';

interface SpeechBubblePlainIconProps extends SvgProps {
  color?: string;
  size?: number;
}

function SpeechBubblePlainIcon({
  color = CareshipColors.slate400,
  size = 24,
  ...props
}: SpeechBubblePlainIconProps) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" {...props}>
      <Path
        d="m12.002 1 .296.003A11 11 0 1 1 6.76 21.661l-.214-.121-5.259 2.154a.75.75 0 0 1-1.01-.881l.032-.097 2.153-5.26-.152-.273a10.986 10.986 0 0 1-.087-10.23l.166-.31A10.985 10.985 0 0 1 12.002 1Zm.001 1.5a9.486 9.486 0 0 0-8.069 14.497.75.75 0 0 1 .058.68l-1.618 3.95 3.95-1.616a.75.75 0 0 1 .574.002l.106.055a9.5 9.5 0 1 0 5-17.568Z"
        fill={color}
      />
    </Svg>
  );
}

export default SpeechBubblePlainIcon;
