import { ImageURISource } from 'react-native';

const fallbackImage =
  // eslint-disable-next-line global-require
  require('../../../UI/Account/Assets/images/profile-fallback-image.jpg') as ImageURISource;

const BASE_URL = 'https://static.careship.de/user/image/';

export interface ProfileImageObject {
  readonly imageHash?: string;
}

export default class ProfileImage {
  constructor(readonly imageHash?: string) {}

  public getImageURISource(size: 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800): ImageURISource {
    if (this.imageHash) {
      return { uri: `${BASE_URL}${this.imageHash}/${size}w.jpg` };
    }

    return fallbackImage;
  }
}
