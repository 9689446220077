/**
 * @generated SignedSource<<9eecc841f1704df20c2d6c138b269046>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FirstBookingConfirmForm_contract$data = {
  readonly firstBooking: {
    readonly bookingId: string;
    readonly startDate: string;
    readonly endDate: string;
  } | null;
  readonly " $fragmentType": "FirstBookingConfirmForm_contract";
};
export type FirstBookingConfirmForm_contract$key = {
  readonly " $data"?: FirstBookingConfirmForm_contract$data;
  readonly " $fragmentSpreads": FragmentRefs<"FirstBookingConfirmForm_contract">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FirstBookingConfirmForm_contract",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "isFirstBooking",
          "value": true
        }
      ],
      "concreteType": "Booking",
      "kind": "LinkedField",
      "name": "firstBooking",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "bookingId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "startDate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "endDate",
          "storageKey": null
        }
      ],
      "storageKey": "firstBooking(isFirstBooking:true)"
    }
  ],
  "type": "Contract",
  "abstractKey": null
};

(node as any).hash = "7c620a3d472959ccec7c9a56a40074c4";

export default node;
