/* eslint-disable */
import Constants from 'expo-constants';
import { Platform } from 'react-native';
import { AppManifest } from 'expo-constants/build/Constants.types';

/**
 * This is used to resolve config values from the extra field in app.json, matched to
 * the release channel.
 * In dev we resolve to the "dev" key.
 * "default" can be used in order to avoid repeating the same value many times.
 */

/** Channels/environments we may add config for */
type Channel = 'default' | 'dev' | 'qa-automation' | 'internal' | 'alpha' | 'production';
const CHANNELS: Channel[] = ['default', 'dev', 'qa-automation', 'internal', 'alpha', 'production'];

type JSONParseReturnType = ReturnType<JSON['parse']>;
type Handler = {
  get: (obj: JSONParseReturnType, prop: string) => JSONParseReturnType;
};

type Primitive = boolean | string | number;

export function isPrimitive(value: unknown): value is Primitive {
  return value !== Object(value);
}

function assertValidKeys(value: JSONParseReturnType): void {
  if (!value || value.constructor !== Object) {
    return;
  }

  const keys = Object.keys(value);
  const intersection = keys.filter((k) => CHANNELS.includes(<Channel>k));

  if (intersection.length !== 0 && intersection.length !== keys.length) {
    const extraKeys = keys.filter((k) => !CHANNELS.includes(<Channel>k)).join(', ');

    throw Error(
      `Invalid channel key mixed with channel keys in app.json extra. Found ${extraKeys} along ${intersection.join(
        ', ',
      )}`,
    );
  }
}

function hasAvailableConfig(value: JSONParseReturnType, releaseChannel: string = 'default') {
  return releaseChannel in value || 'default' in value;
}

export type ResolvedManifest = Omit<AppManifest, 'extra'> & {
  extra: {
    apiEndpoint: string;
    graphqlEndpoint: string;
    oauthClientId: string;
    oauthClientSecret: string;
    features: Record<string, Primitive>;
  } & Record<string, any>;
};

export function manifestResolver(
  extra: JSONParseReturnType,
  releaseChannel: string,
): ResolvedManifest['extra'] {
  function getValueByChannel(value: JSONParseReturnType) {
    const valueByChannel =
      value[releaseChannel] !== undefined ? value[releaseChannel] : value.default;

    if (isPrimitive(valueByChannel)) {
      return valueByChannel;
    }

    return manifestResolver(valueByChannel, releaseChannel);
  }

  function handleArray(v: JSONParseReturnType): JSONParseReturnType {
    if (isPrimitive(v)) {
      return v;
    }

    if (Array.isArray(v)) {
      return v.map(handleArray);
    }

    return getValueByChannel(v);
  }

  if (Array.isArray(extra)) {
    // @ts-ignore
    return extra.map(handleArray);
  }

  const handler: Handler = {
    get(obj, prop) {
      const value = obj[prop];

      if (isPrimitive(value)) {
        return value;
      }

      assertValidKeys(value);

      if (hasAvailableConfig(value, releaseChannel)) {
        return getValueByChannel(value);
      }

      return manifestResolver(value, releaseChannel);
    },
  };

  return new Proxy(extra, handler);
}

export function resolveReleaseChannel(releaseChannel: string | undefined = 'default'): string {
  // Set release channel from RELEASE_CHANNEL in index.html if available (when target is web),
  // if not use releaseChannel from Constants and fall back to 'dev' when in dev mode.
  return getWindowEnv('RELEASE_CHANNEL') || releaseChannel || 'dev';
}

const resolvedExtra: ResolvedManifest['extra'] = manifestResolver(
  {
    features: {},
    ...Constants.expoConfig?.extra,
  },
  resolveReleaseChannel( Constants.expoConfig?.releaseChannel || 'default'),
);

function getWindowEnv(varName: string): undefined | string {
  return Platform.OS === 'web' && !__DEV__ ? (window as any)[varName] : undefined;
}

const apiEndpoint: string = getWindowEnv('API_BACKEND_URL') || resolvedExtra.apiEndpoint;
const graphqlEndpoint: string = getWindowEnv('GRAPHQL_URL') || resolvedExtra.graphqlEndpoint;
const oauthClientId: string = getWindowEnv('OAUTH2_CLIENT_ID') || resolvedExtra.oauthClientId;
const environmentInventoryName: string =
  getWindowEnv('ENVIRONMENT_INVENTORY_NAME') || resolvedExtra.environmentInventoryName;
const oauthClientSecret: string =
  getWindowEnv('OAUTH2_CLIENT_SECRET') || resolvedExtra.oauthClientSecret;

export default <ResolvedManifest>{
  ... Constants.expoConfig,
  extra: {
    ...resolvedExtra,
    apiEndpoint,
    graphqlEndpoint,
    oauthClientId,
    oauthClientSecret,
    environmentInventoryName,
  },
};
