import  AsyncStorage  from '@react-native-async-storage/async-storage';

class PlatformLocalStorage {
  static getItem(key: string, values: object) {
    try{
      const value = AsyncStorage.getItem(key).then(results=>{console.log('value updated...');values.hideModalUntil = results;});
      console.log(typeof(value));
      console.log('waiting...');
      console.log(values.hideModalUntil)
    }
    catch(e)
    {
      values.hideModalUntil = '';
    }

  }

  static setItem(key: string, value: any) {
    AsyncStorage.setItem(key, JSON.stringify(value));
  }

}

export default PlatformLocalStorage;
