/**
 * App entry point, based on expo's entry point `node_modules/expo/AppEntry.js`
 */

// not possible to use Intl without a polyfill on android with managed expo
import 'intl';
import 'intl/locale-data/jsonp/en-GB';
import 'intl/locale-data/jsonp/de-DE';

// import registerRootComponent from 'expo/build/launch/registerRootComponent';
import registerRootComponent from 'expo/build/launch/registerRootComponent';

import App from './UI/Root/App';

registerRootComponent(App);
