import { StyleSheet, TouchableOpacity } from 'react-native';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Title } from '../../../Component';
import { CareshipColors } from '../../../Component/CSTheme';
import { CheckCircleFilledIcon } from '../../../Component/Icon';

interface FulfillBookingFeedbackConfirmationProps {
  onPress: () => void;
}

const styles = StyleSheet.create({
  container: {
    maxWidth: 295,
    alignSelf: 'center',
    alignItems: 'center',
    marginTop: 32,
  },
  title: { color: CareshipColors.secondary },
  icon: { marginTop: 16 },
});

export default function FulfillBookingFeedbackConfirmation({
  onPress,
}: FulfillBookingFeedbackConfirmationProps) {
  return (
    <TouchableOpacity accessibilityRole="button" style={styles.container} onPress={onPress}>
      <Title size="h4" center style={styles.title}>
        <FormattedMessage id="BOOKING_FEEDBACK_THANK_YOU" />
      </Title>
      <CheckCircleFilledIcon style={styles.icon} color={CareshipColors.secondary} size={43} />
    </TouchableOpacity>
  );
}
