import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';
import { CareshipColors } from '../CSTheme';

interface BankIconProps extends SvgProps {
  color?: string;
  size?: number;
}

export default function BankIcon({
  color = CareshipColors.slate400,
  size = 24,
  ...props
}: BankIconProps) {
  return (
    <Svg viewBox="0 0 24 24" width={size} height={size} {...props}>
      <Path
        d="M22.256 21.02a.75.75 0 01.102 1.493l-.102.007H1.77a.75.75 0 01-.102-1.493l.102-.007h20.486zM11.44 1.334a1.907 1.907 0 011.118 0l.08.03 8.665 3.84a.75.75 0 01.446.685v1.639c0 .767-.724 1.222-1.5 1.222h-1v6.272a1.483 1.483 0 011.198 1.334l.007.144V18l-.001.019h1.802a.75.75 0 01.102 1.494l-.102.007H1.77a.75.75 0 01-.102-1.493l.102-.007 1.776-.001V16.5c0-.74.52-1.355 1.203-1.478V8.75H3.75c-.73 0-1.415-.403-1.493-1.09l-.007-.132V5.889a.75.75 0 01.446-.685zM19 16.5H5V18h14v-1.5zM9.083 8.75H6.25V15h2.833V8.75zm4.334 0h-2.834V15h2.834V8.75zm4.333 0h-2.833V15h2.833V8.75zm-5.781-6.015L3.75 6.377v.873h16.5v-.873l-8.165-3.618-.116-.024z"
        fill={color}
      />
    </Svg>
  );
}
