import React, { ComponentProps, ReactElement } from 'react';
import { Text, TouchableRipple } from 'react-native-paper';
import { View } from 'react-native';
import styled from '@emotion/native';
import styles from './Tab.styles';
import CSTheme, { CareshipColors } from '../CSTheme';
import Badge from '../Badge';
import { WithoutTheme } from '../../Root/Theme/WithoutTheme';

const TabButton = styled(TouchableRipple)<{
  active?: boolean;
}>(({ active }) => [
  {
    flex: 1,
    paddingVertical: 16,
    height: 50,
    borderBottomWidth: 2,
    borderBottomColor: 'transparent',
    alignItems: 'center',
    justifyContent: 'center',
  },
  active && {
    borderBottomColor: CareshipColors.primary,
  },
]);

type TabProps = ComponentProps<WithoutTheme<typeof TouchableRipple>> & {
  active?: boolean;
  badgeCount?: number;
};

export default function Tab({
  style,
  active,
  children,
  badgeCount,
  ...props
}: TabProps): ReactElement {
  return (
    <TabButton rippleColor={CareshipColors.rippleColor} style={style} {...props}>
      <View style={styles.tabContent}>
        <Text style={[styles.text, active && styles.activeLabel]}>{children}</Text>
        {badgeCount ? (
          <Badge color={active ? CSTheme.colors.primary : undefined} style={styles.badge}>
            {badgeCount}
          </Badge>
        ) : null}
      </View>
    </TabButton>
  );
}
