import {Alert, Linking, Platform} from "react-native";
import {IntlShape} from "react-intl";
import * as IntentLauncher from "expo-intent-launcher";
import {ActivityAction} from "expo-intent-launcher/src/IntentLauncher";
import * as Application from "expo-application";

const openAppPreferences = (): void => {
    if (Platform.OS === 'ios') {
        void Linking.openURL('app-settings:');
        return;
    }

    void IntentLauncher.startActivityAsync(ActivityAction.APPLICATION_DETAILS_SETTINGS, {
        data: `package:${String(Application.applicationId)}`,
    });
};

/**
 * Renders the Alert component for Settings configurations.
 * The input messages are platform dedicated.
 *
 * The Alert options are `Cancel` or `Settings` - app preferences.
 *
 * @param intl
 * @param iosMessage
 * @param androidMessage
 */
export function alertForPermissions(intl: IntlShape, iosMessage: string, androidMessage: string) {
    let title = intl.formatMessage({
        id: iosMessage,
    });
    let description = '';

    // text does not fit as title on android
    if (Platform.OS === 'android') {
        title = '';
        description = intl.formatMessage({
            id: androidMessage,
        });
    }

    Alert.alert(title, description, [
        {
            text: intl.formatMessage({ id: 'BUTTON_CANCEL' }),
            style: 'cancel',
        },
        {
            text: 'Settings',
            onPress: openAppPreferences,
        },
    ]);
}
