import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

function ConfettiLarge(props: SvgProps) {
  return (
    <Svg viewBox="0 0 668 116" width="668" height="116" {...props}>
      <Path fill="#3B9BA9" d="M30.9 8.4l-8.1-5.8-5.4 3.2 10.4 6z" />
      <Path fill="#FFB300" d="M27.9 62.5l2.9-9.6-4.8-4-2.3 11.8z" />
      <Path fill="#FFB300" d="M391.9 14.4l2.8-9.6-4.8-4-2.3 11.8z" />
      <Path fill="#FFB300" d="M208 13l1.1-6.1-3.9-2-.4 7.3z" />
      <Path fill="#FFB300" d="M645.9 20.1l1-6.2-3.9-2-.4 7.4z" />
      <Path fill="#FFB300" d="M81.8 5.1L82.1-5l-5.7-2.6.8 12z" />
      <Path fill="#FFB300" d="M120.6 21l-3-9.6-6.2-.6 4.7 11.1z" />
      <Path fill="#FFB300" d="M40.7 114.2l-3-9.6-6.3-.6 4.7 11.1z" />
      <Path fill="#FFB300" d="M14.8 26l-3.2-9.6-6.2-.5 4.8 11z" />
      <Path fill="#FFB300" d="M253 18l6.7-7.5-2.7-5.7-7 9.8z" />
      <Path fill="#FFB300" d="M472.3 6.3l-4.5-9-6.2.3 6.3 10.2z" />
      <Path fill="#3B9BA9" d="M186.6 20.4l4.6-8.9-3.9-4.9-4.5 11.2z" />
      <Path fill="#3B9BA9" d="M64.5 16.6l1.1-5.4-3.8-1.9-.5 6.5z" />
      <Path fill="#3B9BA9" d="M310.6 10.5l.9-5.4-4-1.7-.2 6.5z" />
      <Path fill="#3B9BA9" d="M418.4 9.7l-2.6-4.8 3.2-3 2.3 6.1z" />
      <Path fill="#3B9BA9" d="M152.6 3.5l.9-5.4-3.9-1.7-.2 6.4z" />
      <Path fill="#3B9BA9" d="M4.8 59.7l3.4-4.2L5.6 52l-3.3 5.6z" />
      <Path fill="#3B9BA9" d="M565.8 14.7l3.4-4.2-2.6-3.5-3.3 5.6z" />
      <Path fill="#3B9BA9" d="M16.7 78.2l-.7-5.4-4.3-.6 1.7 6.3z" />
      <Path fill="#3B9BA9" d="M618.4 6l-.7-5.4-4.2-.5 1.6 6.3z" />
    </Svg>
  );
}
export default ConfettiLarge;
