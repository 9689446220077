import React, { useCallback } from 'react';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { Platform } from 'react-native';
import { Layout } from '../../Component';
import ServiceDocumentationList from '../Component/ServiceDocumentationList';
import { CustomerStackNavigationProp } from '../Navigator/CustomerStackNavigationProp';
import { CustomerNavigationParamList } from '../Navigator/CustomerStackNavigator';
import { retrieveServiceDocumentationDownloadUrl } from '../Hook';
import useAuthorizedClient from '../../../Infrastructure/Hook/useAuthorizedClient';

export default function ServiceDocumentationScreen() {
  const navigation = useNavigation<CustomerStackNavigationProp<'ServiceDocumentation'>>();
  const {
    params: { contractId },
  } = useRoute<RouteProp<CustomerNavigationParamList, 'ServiceDocumentation'>>();
  const authorizedClient = useAuthorizedClient();

  const onPressServiceDocumentationPeriod = useCallback<
    (serviceDocumentationPeriodId: string, title: string) => void
  >(
    async (serviceDocumentationPeriodId: string, title: string) => {
      if (Platform.OS !== 'web') {
        navigation.navigate('ServiceDocumentationPeriod', {
          serviceDocumentationPeriodId,
          title,
        });

        return;
      }

      const uri = await retrieveServiceDocumentationDownloadUrl(
        authorizedClient,
        serviceDocumentationPeriodId,
      );

      if (uri) {
        window.location.href = uri;
      }
    },
    [authorizedClient, navigation],
  );

  // const onPressServiceDocumentationHowTo = () => navigation.navigate('ServiceDocumentationHowTo');
  const onPressServiceDocumentationHowTo = () => {
    const uri =
      'https://www.careship.de/app/uploads/2023/04/Careship_Leistungsnachweis_Leitfaden.pdf';
    if (Platform.OS === 'web') {
      window.location.href = uri;

      return;
    }

    navigation.navigate('PdfView', {
      filename: 'Careship_Leistungsnachweis_Leitfaden.pdf',
      headerTitle: 'Leistungsnachweis Leitfaden',
      uri,
    });
  };

  const onPressServiceDocumentationTemplatePdf = () => {
    const uri =
      'https://www.careship.de/app/uploads/2023/04/Leistungsnachweis_Vorlage_pro-Monat.pdf';

    if (Platform.OS === 'web') {
      window.location.href = uri;

      return;
    }

    navigation.navigate('PdfView', {
      filename: 'Leistungsnachweis_Vorlage_202111.pdf',
      headerTitle: 'Leistungsnachweis Vorlage',
      uri,
    });
  };

  return (
    <Layout>
      <ServiceDocumentationList
        contractId={contractId}
        onPressServiceDocumentationPeriod={onPressServiceDocumentationPeriod}
        onPressServiceDocumentationHowTo={onPressServiceDocumentationHowTo}
        onPressServiceDocumentationTemplatePdf={onPressServiceDocumentationTemplatePdf}
      />
    </Layout>
  );
}
