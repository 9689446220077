import React, { useEffect } from 'react';
import { useQueryLoader } from 'react-relay/hooks';
import { Layout } from '../../Component';
import FinancialOverview, { financialOverviewQuery } from '../Component/FinancialOverview';
import { FinancialOverviewQuery } from '../Component/__generated__/FinancialOverviewQuery.graphql';

export default function FinancialOverviewScreen() {
  const [financialOverviewQueryReference, loadStatsQuery] =
    useQueryLoader<FinancialOverviewQuery>(financialOverviewQuery);

  useEffect(() => {
    loadStatsQuery({}, { fetchPolicy: 'store-and-network' });
  }, [loadStatsQuery]);

  return (
    <Layout>
      {financialOverviewQueryReference && (
        <FinancialOverview financialOverviewQueryReference={financialOverviewQueryReference} />
      )}
    </Layout>
  );
}
