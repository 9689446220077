import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';
import { CareshipColors } from '../CSTheme';

interface XIconProps extends SvgProps {
  color?: string;
  size?: number;
}

function XIcon({ color = CareshipColors.slate400, size = 24, ...props }: XIconProps) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" {...props}>
      <Path
        fill={color}
        d="M3.4 2.4l8.6 8.5 8.5-8.5c.3-.3.8-.3 1.1 0 .3.3.3.7.1 1l-.1.1-8.5 8.5 8.5 8.5c.3.3.3.8 0 1.1-.3.3-.7.3-1 .1l-.1-.1-8.5-8.5-8.5 8.5c-.3.3-.8.3-1.1 0-.3-.3-.3-.7-.1-1l.1-.1 8.5-8.5-8.4-8.5c-.3-.3-.3-.8 0-1.1.2-.2.7-.2.9 0z"
      />
    </Svg>
  );
}

export default XIcon;
