export type PricingResponse = {
  readonly gross_customer_price_per_hour_in_cents: number;
  readonly gross_caregiver_price_per_hour_in_cents: number;
  readonly commission_rate: number;
};

export default class Pricing {
  constructor(
    readonly grossCustomerPricePerHourInCents: number,
    readonly grossCaregiverPricePerHourInCents: number,
    readonly commissionRate: number,
  ) {}

  static fromObject(pricing: Pricing) {
    return new Pricing(
      pricing.grossCustomerPricePerHourInCents,
      pricing.grossCaregiverPricePerHourInCents,
      pricing.commissionRate,
    );
  }

  static fromResponse(response: PricingResponse) {
    return new Pricing(
      response.gross_customer_price_per_hour_in_cents,
      response.gross_caregiver_price_per_hour_in_cents,
      response.commission_rate,
    );
  }
}
