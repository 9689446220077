/**
 * @generated SignedSource<<c0ac8d8d42813ec4afdae6e4a66f0564>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MonthlyEarnings_monthlyBalance$data = {
  readonly monthlyDocumentedDurationInMinutes: number;
  readonly monthlyDocumentedGrossEarningsInCents: number;
  readonly " $fragmentType": "MonthlyEarnings_monthlyBalance";
};
export type MonthlyEarnings_monthlyBalance$key = {
  readonly " $data"?: MonthlyEarnings_monthlyBalance$data;
  readonly " $fragmentSpreads": FragmentRefs<"MonthlyEarnings_monthlyBalance">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MonthlyEarnings_monthlyBalance",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "monthlyDocumentedDurationInMinutes",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "monthlyDocumentedGrossEarningsInCents",
      "storageKey": null
    }
  ],
  "type": "CaregiverStats",
  "abstractKey": null
};

(node as any).hash = "762392eef36aeb1a235d3b9f4da4ae39";

export default node;
