import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';
import CSTheme from '../CSTheme';

type EuroIconProps = SvgProps & {
  color?: string;
  size?: number;
};

function EuroIcon({ color, size = 24, ...props }: EuroIconProps) {
  return (
    <Svg viewBox="0 0 24 24" width={size} height={size} {...props}>
      <Path
        d="M12 1.2C6.1 1.2 1.2 6.1 1.2 12S6.1 22.8 12 22.8 22.8 17.9 22.8 12 17.9 1.2 12 1.2zm0 1.6c5.1 0 9.2 4.1 9.2 9.2s-4.1 9.2-9.2 9.2-9.2-4.1-9.2-9.2S6.9 2.8 12 2.8zm.2 4c.8 0 1.5.2 2.2.5.6.3 1.1.7 1.5 1.1.3.5.5.9.5 1.4 0 .2-.1.4-.2.6s-.4.3-.6.3c-.3 0-.5-.1-.6-.2s-.3-.3-.4-.6c-.3-.5-.6-.9-.9-1.1-.4-.2-.8-.4-1.3-.4-.8 0-1.5.3-2 1-.4.2-.6.6-.7 1.1h2.9c.2 0 .4.2.4.4s-.1.3-.3.4H9.6V12.6h3c.2 0 .4.2.4.4s-.1.3-.3.4h-3c0 .2.1.4.2.6.2.5.5.9.9 1.2.4.3.9.4 1.4.4.6 0 1.1-.1 1.5-.4s.7-.7.9-1.3c.1-.3.2-.5.3-.6.1-.2.3-.2.6-.2.2 0 .4.1.6.3.2.2.3.4.3.6 0 .3-.1.7-.2 1s-.4.7-.7 1.1c-.3.4-.8.6-1.3.9-.5.2-1.1.3-1.8.3-.5 0-1-.1-1.4-.2-.4-.1-.8-.3-1.2-.5-.4-.2-.7-.5-1-.8-.4-.5-.6-.8-.8-1.2-.2-.4-.3-.8-.4-1.2h-1c-.2 0-.4-.2-.4-.4s.1-.3.3-.4h1v-.7-.7h-.9c-.2 0-.4-.2-.4-.4s.1-.3.3-.4h1.1c.1-.3.1-.5.2-.7.2-.6.6-1.2 1-1.6.4-.5 1-.8 1.5-1s1.3-.3 1.9-.3z"
        fill={color || CSTheme.colors.text}
      />
    </Svg>
  );
}

export default EuroIcon;
