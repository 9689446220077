/**
 * @generated SignedSource<<6784f55639c2e43c54000ec8c7473873>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DeleteCaregiverAbsenceInput = {
  id: string;
  clientMutationId?: string | null;
};
export type useDeleteAbsenceMutation$variables = {
  input: DeleteCaregiverAbsenceInput;
};
export type useDeleteAbsenceMutation$data = {
  readonly deleteCaregiverAbsence: {
    readonly deletedId: string;
    readonly clientMutationId: string | null;
  } | null;
};
export type useDeleteAbsenceMutation = {
  variables: useDeleteAbsenceMutation$variables;
  response: useDeleteAbsenceMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deletedId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "clientMutationId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useDeleteAbsenceMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteCaregiverAbsencePayload",
        "kind": "LinkedField",
        "name": "deleteCaregiverAbsence",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useDeleteAbsenceMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteCaregiverAbsencePayload",
        "kind": "LinkedField",
        "name": "deleteCaregiverAbsence",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "deleteRecord",
            "key": "",
            "kind": "ScalarHandle",
            "name": "deletedId"
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "79acc4d7af82eea01a9be7c5a66f9e11",
    "id": null,
    "metadata": {},
    "name": "useDeleteAbsenceMutation",
    "operationKind": "mutation",
    "text": "mutation useDeleteAbsenceMutation(\n  $input: DeleteCaregiverAbsenceInput!\n) {\n  deleteCaregiverAbsence(input: $input) {\n    deletedId\n    clientMutationId\n  }\n}\n"
  }
};
})();

(node as any).hash = "8a7d1c368ec798fb96d92c169cf8e17f";

export default node;
