import styled from '@emotion/native';
import React, { MutableRefObject, ReactNode, useEffect, useRef, useState } from 'react';
import { Animated, Dimensions, Platform, View } from 'react-native';
import { sidebarMenuWidth } from './Navigation/SidebarMenu';
import { CareshipColors } from './CSTheme';
import Content from './Layout/Content';

const positionFixedWeb: 'absolute' = Platform.select({
  web: 'fixed' as 'absolute',
  default: 'absolute',
});

const Sticky = styled(Animated.View)<{ sticky?: boolean }>(({ sticky, theme: { breakpoints } }) => [
  {
    borderTopColor: CareshipColors.slate050,
    borderTopWidth: 1,
    position: positionFixedWeb,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'white',
    display: sticky ? 'flex' : 'none',
  },
  breakpoints.up('md', {
    marginLeft: sidebarMenuWidth,
  }),
]);

export default function StickyFooter({
  sticky,
  alignWithRef,
  children,
}: {
  sticky?: boolean;
  alignWithRef: MutableRefObject<View | null>;
  children: ReactNode;
}) {
  const [height, setHeight] = useState(0);
  const [isAnimating, setIsAnimating] = useState(sticky);
  const [hasChanged, setHasChanged] = useState(false);
  const offsetRef = useRef(new Animated.Value(0));
  const stickyRef = useRef<View>(null);

  useEffect(() => {
    let aborted = false;

    void (async () => {
      const alignWithPageY: number = await new Promise((resolve) =>
        alignWithRef.current?.measure((x, y, width, _height, pageX, pageY) => {
          if (__DEV__ && pageY === undefined) {
            // eslint-disable-next-line no-console
            console.error(
              '[ERROR] the collapsable prop on the alignWithRef View must be set as collapsable={false} ' +
                'else android may optimize away the rendering and pageY will be undefined',
            );
          }

          resolve(pageY);
        }),
      );

      const stickyPageY: number = await new Promise((resolve) =>
        stickyRef.current?.measure((x, y, width, _height, _pageX, pageY) => resolve(pageY)),
      );

      const delta = alignWithPageY - stickyPageY;

      const activePoint = 0;
      const restPoint = Platform.select({
        default: Math.min(height, !sticky ? delta : height + delta),
        web: Math.min(
          height,
          !sticky ? height + delta - Dimensions.get('window').height : height + delta,
        ),
      });

      if (aborted || height === 0) {
        return;
      }

      if (sticky) {
        offsetRef.current.setValue(restPoint - 1);
      }

      setIsAnimating(true);
      setHasChanged(true);
      Animated.timing(offsetRef.current, {
        toValue: sticky ? activePoint : restPoint - 1,
        useNativeDriver: false,
        duration: 160,
      }).start(({ finished }) => {
        if (!finished) {
          return;
        }

        setIsAnimating(false);

        if (!sticky) {
          offsetRef.current.setValue(height);
        }
      });
    })();

    return () => {
      aborted = true;
    };
  }, [alignWithRef, height, sticky]);

  return (
    <Sticky
      ref={stickyRef}
      style={[
        {
          transform: [{ translateY: offsetRef.current }],
          height: height || undefined,
          opacity: hasChanged ? 1 : 0,
        },
      ]}
      onLayout={({
        nativeEvent: {
          layout: { height: h },
        },
      }) => {
        if (!height && Math.abs(h - height) > 2) {
          setHeight(h);
          offsetRef.current.setValue(height);
        }
      }}
      sticky={isAnimating || sticky}
    >
      <Content>{children}</Content>
    </Sticky>
  );
}
