import React, { ReactNode, useRef } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { addMinutes } from 'date-fns';
import { StyleSheet, Text as RNText, View } from 'react-native';
import styled from '@emotion/native';
import { CSTheme, Text, Title } from '../../Component';
import Tooltip from '../../Component/Tooltip';
import { CareshipColors } from '../../Component/CSTheme';

const styles = StyleSheet.create({
  flexibleHint: {
    marginTop: 8,
    marginBottom: 8,
    flexShrink: 1,
  },
  bold: {
    ...CSTheme.fonts.medium,
  },
  time: {
    marginLeft: 'auto',
  },
  title: {
    marginTop: 4,
  },
  iconRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    marginTop: -4,
  },
  dateRow: {
    marginTop: 12,
    marginBottom: 8,
    flexDirection: 'row',
  },
});

const TextWithTooltip = styled.View({
  display: 'flex',
  flexDirection: 'row',
  overflow: 'hidden',
  marginLeft: -8,
});

const TooltipBodyText = styled.Text({
  color: CareshipColors.white,
});

interface ScheduleDetailsProps {
  schedule: {
    date: Date;
    durationInMinutes: number;
    isAllDay: boolean;
    isFlexible: boolean;
  };
  title: ReactNode;
  iconRight?: ReactNode;
}

export default function ScheduleDetails({
  schedule: { date, durationInMinutes, isAllDay, isFlexible },
  title,
  iconRight,
}: ScheduleDetailsProps) {
  const tooltipBoundaryRef = useRef<View>(null);

  return (
    <View ref={tooltipBoundaryRef}>
      <View style={styles.iconRow}>
        <Text style={styles.title} muted uppercase size="small">
          {title}
        </Text>
        {iconRight}
      </View>
      <View style={styles.dateRow}>
        <Title size="h2" thin muted>
          <FormattedDate value={date} weekday="short" />,{' '}
        </Title>
        <Title size="h2" muted>
          <FormattedDate value={date} day="2-digit" month="short" />
        </Title>
        <Title size="h2" muted style={styles.time}>
          {isAllDay ? (
            <FormattedMessage id="ALL_DAY" />
          ) : (
            <>
              <FormattedDate value={date} minute="2-digit" hour="2-digit" hour12={false} />
              <Title size="h2" muted thin>
                -
                <FormattedDate
                  value={addMinutes(date, durationInMinutes)}
                  minute="2-digit"
                  hour="2-digit"
                  hour12={false}
                />
              </Title>
            </>
          )}
        </Title>
      </View>
      {isFlexible && (
        <TextWithTooltip>
          <Tooltip boundary={tooltipBoundaryRef}>
            <TooltipBodyText>
              <FormattedMessage id="FLEXIBLE_APPOINTMENT_TOOLTIP_BODY" />
            </TooltipBodyText>
          </Tooltip>
          <Text size="medium" style={styles.flexibleHint}>
            <FormattedMessage
              id="FLEXIBLE_APPOINTMENT"
              values={{
                prefix: (
                  <RNText style={styles.bold}>
                    <FormattedMessage id="FLEXIBLE_APPOINTMENT_PREFIX" />
                  </RNText>
                ),
              }}
            />
          </Text>
        </TextWithTooltip>
      )}
    </View>
  );
}
