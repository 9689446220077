import { TouchableRipple } from 'react-native-paper';
import { Platform, View } from 'react-native';
import { FormattedMessage } from 'react-intl';
import React, { RefObject } from 'react';
import styled from '@emotion/native';
import { ScrollView } from 'react-native-gesture-handler';
import Text from './Text';
import { ArrowUpIcon } from './Icon';
import { CareshipColors } from './CSTheme';

const ScrollButtonContainer = styled(View)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: 8,
});

const Icon = styled(ArrowUpIcon)({
  marginLeft: 8,
});

const Touchable = styled(TouchableRipple)({
  borderRadius: 8,
});

export default function ButtonScrollTop({
  scrollViewRef,
}: {
  scrollViewRef: RefObject<ScrollView>;
}) {
  const onPress = () => {
    if (Platform.OS === 'web') {
      window.scrollTo(0, 0);

      return;
    }

    scrollViewRef.current?.scrollTo({ y: 0 });
  };

  return (
    <Touchable
      accessibilityRole="button"
      onPress={onPress}
      rippleColor={CareshipColors.rippleColor}
    >
      <ScrollButtonContainer>
        <Text size="large">
          <FormattedMessage id="SCROLL_UP" />
        </Text>
        <Icon color={CareshipColors.slate300} />
      </ScrollButtonContainer>
    </Touchable>
  );
}
