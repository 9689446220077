import { Platform } from 'react-native';
import * as Notifications from 'expo-notifications';
import { AndroidAudioUsage } from 'expo-notifications';
import * as Device from 'expo-device';
import logger from '../Service/Logger';

export const ANDROID_CHANNEL_ID = 'Default';

export default async function asyncRequestPushNotificationPermission(): Promise<string> {
  if (!Device.isDevice && Platform.OS !== 'android') {
    throw new Error('Must use physical device for Push Notifications');
  }

  let { status } = await Notifications.getPermissionsAsync();

  logger.debug(`current NOTIFICATIONS permission: ${status}`);
  if (status !== 'granted') {
    status = (await Notifications.requestPermissionsAsync()).status;
  }

  logger.debug(`new NOTIFICATIONS permission: ${status}`);
  if (status !== 'granted') {
    logger.info('Failed to get push token for push notification!');
    throw new Error('denied');
  }

  logger.debug('requesting expo token');
  const token = await Notifications.getExpoPushTokenAsync();
  logger.debug(`successfully retrieved token`);

  if (Platform.OS === 'android') {
    await Notifications.setNotificationChannelAsync(ANDROID_CHANNEL_ID, {
      name: 'Default',
      importance: Notifications.AndroidImportance.HIGH,
      vibrationPattern: [0, 250, 250, 250],
      audioAttributes: { usage: AndroidAudioUsage.NOTIFICATION },
    });
  }

  return token?.data;
}
