import React from 'react';
import { ActivityIndicator } from 'react-native-paper';
import styled from '@emotion/native';

const Loader = styled(ActivityIndicator)({
  flex: 1,
  justifyContent: 'center',
});

export default function Loading({ color }: { color?: string }) {
  return <Loader color={color} />;
}
