import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

function ConfettiSmall(props: SvgProps) {
  return (
    <Svg viewBox="0 0 400 92" width="400" height="92" {...props}>
      <Path fill="#3B9BA9" d="M32.9 8.9l-7.5-5.3-4.8 2.9L30 12z" />
      <Path fill="#FFB300" d="M180.9 18.1l1-6.1-3.9-2-.3 7.3z" />
      <Path fill="#FFB300" d="M393.8 23.1l1-6.1-3.9-2-.4 7.3z" />
      <Path fill="#FFB300" d="M337.7 8l-2.2-5.8-4.3.2 3.3 6.6z" />
      <Path fill="#FFB300" d="M73.7 10.2l.3-10-5.7-2.6.8 11.9z" />
      <Path fill="#FFB300" d="M110.7 18.1L108 9.5l-5.7-.6 4.3 10z" />
      <Path fill="#FFB300" d="M23.6 93.4l-2.8-8-5.6-.1 4.4 9.1z" />
      <Path fill="#FFB300" d="M15.8 29l-2.9-8.6-5.6-.5 4.4 10z" />
      <Path fill="#FFB300" d="M219.3 15.9l6-6.8-2.4-5.1-6.4 8.7z" />
      <Path fill="#3B9BA9" d="M159.5 17.2l4.2-8-3.6-4.4-4.1 10.1z" />
      <Path fill="#3B9BA9" d="M48.4 24.6l1.1-5.3-3.8-1.9-.5 6.4z" />
      <Path fill="#3B9BA9" d="M125.5 6.6l.9-5.3-3.9-1.8-.2 6.5z" />
      <Path fill="#3B9BA9" d="M7.7 51.5l3.4-4.2-2.6-3.4-3.3 5.5z" />
      <Path fill="#3B9BA9" d="M17.8 66.7l-1.1-5.3-4.3-.2 2.1 6.1z" />
      <Path fill="#3B9BA9" d="M372.5 14.4l3.4-4.2-2.6-3.4-3.3 5.5z" />
      <Path fill="#FFB300" d="M260.7 21l-1-6 3.9-2.1.4 7.4z" />
      <Path fill="#FFB300" d="M24.6 52.1l3-5.4-3-3.2-2.8 6.8z" />
      <Path fill="#3B9BA9" d="M274.1 11.1l.7-5.4 4.3-.5-1.7 6.2z" />
      <Path fill="#3B9BA9" d="M294.2 16.6l-1.1-5.3 3.9-1.9.5 6.4z" />
    </Svg>
  );
}
export default ConfettiSmall;
