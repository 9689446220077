import React from 'react';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { useLazyLoadQuery } from 'react-relay/hooks';
import { graphql } from 'react-relay';
import useBookings from '../../../Context/Booking/Hook/useBookings';
import { AppNavigationParamList, AppStackNavigationProp } from '../../Root/Navigator/AppNavigator';
import { FulfillBookingScreenQuery } from './__generated__/FulfillBookingScreenQuery.graphql';
import FulfillBookingFlow from '../Component/FulfillBookingFlow';
import { withLayout } from '../../HOC';

export default withLayout(function FulfillBookingScreen(): JSX.Element | null {
  const navigation = useNavigation<AppStackNavigationProp<'FulfillBooking'>>();
  const route = useRoute<RouteProp<AppNavigationParamList, 'EditAppointment'>>();
  const { refresh } = useBookings();

  const { booking } = useLazyLoadQuery<FulfillBookingScreenQuery>(
    graphql`
      query FulfillBookingScreenQuery($bookingId: ID!) {
        booking(bookingId: $bookingId) {
          ...FulfillBookingFlowFragment
        }
      }
    `,
    {
      bookingId: route.params.bookingId,
    },
    {
      fetchPolicy: 'store-and-network',
    },
  );

  const handleSubmit = () => {
    refresh();
    navigation.goBack();
  };

  if (!booking) {
    return null;
  }

  return <FulfillBookingFlow bookingFragment={booking} onSubmit={handleSubmit} />;
});
