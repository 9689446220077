import { useCallback } from 'react';
import useUpdateBookingFeedback from './useUpdateBookingFeedback';
import { StoreData } from './feedbackFormStorage';

interface ShouldShowFeedbackReturn {
  shouldShowBookingSurvey: boolean;
  shouldPromptForContinuation: boolean;
  disableFeedbackSurveyPermanently: () => void;
  increaseBookingFeedbackCounter: () => void;
  setFeedbackSurveyFrequency: (frequency: 1 | 3) => void;
}

function getShouldShowFeedback(bookingFeedback: Readonly<StoreData> | null) {
  if (!bookingFeedback || bookingFeedback.disabled) {
    return false;
  }

  return (
    bookingFeedback.bookingCount <= 3 ||
    bookingFeedback.bookingCount % bookingFeedback.frequency === 0
  );
}

function getShouldPromptForContinuation(bookingFeedback: Readonly<StoreData> | null) {
  if (!bookingFeedback || bookingFeedback.disabled) {
    return false;
  }

  return bookingFeedback.bookingCount >= 3 && bookingFeedback.bookingCount % 3 === 0;
}

export default function useShouldShowBookingFeedback(bookingId: string): ShouldShowFeedbackReturn {
  const { bookingFeedback, updateBookingFeedback } = useUpdateBookingFeedback(bookingId);

  const disableFeedbackSurveyPermanently = useCallback(
    () =>
      updateBookingFeedback({
        disabled: true,
      }),
    [updateBookingFeedback],
  );

  const setFeedbackSurveyFrequency = useCallback(
    (frequency: 1 | 3) =>
      updateBookingFeedback({
        frequency,
      }),
    [updateBookingFeedback],
  );

  const increaseBookingFeedbackCounter = useCallback(
    () =>
      updateBookingFeedback({
        bookingCount: (bookingFeedback?.bookingCount || 0) + 1,
      }),
    [bookingFeedback?.bookingCount, updateBookingFeedback],
  );

  return {
    shouldShowBookingSurvey: getShouldShowFeedback(bookingFeedback),
    shouldPromptForContinuation: getShouldPromptForContinuation(bookingFeedback),
    disableFeedbackSurveyPermanently,
    increaseBookingFeedbackCounter,
    setFeedbackSurveyFrequency,
  };
}
