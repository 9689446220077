/**
 * @generated SignedSource<<2808e6fe4212e62ecae73966bcee0cd9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ProposalStatusEnum = "OPEN" | "DECLINED" | "ACCEPTED" | "%future added value";
export type useAcceptProposalQuery$variables = {
  id: string;
};
export type useAcceptProposalQuery$data = {
  readonly proposal: {
    readonly id: string;
    readonly status: ProposalStatusEnum;
  } | null;
};
export type useAcceptProposalQuery = {
  variables: useAcceptProposalQuery$variables;
  response: useAcceptProposalQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "serviceRequestId",
        "variableName": "id"
      }
    ],
    "concreteType": "Proposal",
    "kind": "LinkedField",
    "name": "proposal",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useAcceptProposalQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useAcceptProposalQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "efade25d32f8edacab6d1e43200f5396",
    "id": null,
    "metadata": {},
    "name": "useAcceptProposalQuery",
    "operationKind": "query",
    "text": "query useAcceptProposalQuery(\n  $id: ID!\n) {\n  proposal(serviceRequestId: $id) {\n    id\n    status\n  }\n}\n"
  }
};
})();

(node as any).hash = "3a96e4bd2163ba41d43a77a84449a4a9";

export default node;
