import { Platform, StyleSheet, View } from 'react-native';
import React, { ReactNode } from 'react';
import CSTheme, { CareshipColors } from './CSTheme';
import Text from './Text';

const styles = StyleSheet.create({
  gray: {
    backgroundColor: CareshipColors.slate300,
  },
  green: {
    backgroundColor: CareshipColors.secondary,
  },
  darkGray: {
    backgroundColor: CareshipColors.slate400,
  },
  container: {
    borderTopColor: CareshipColors.white,
    borderTopWidth: 1,
    borderStyle: 'solid',
    display: 'flex',
    flexGrow: 1,
    paddingVertical: 12,
    paddingHorizontal: 18,
    flexDirection: 'column',
    ...Platform.select({
      web: {
        borderRadius: CSTheme.roundness,
        marginHorizontal: 16,
        marginTop: 32,
      },
    }),
  },
  titleRow: {
    display: 'flex',
    fontWeight: '700',
    flexDirection: 'row',
    alignItems: 'center',
  },
  titleContainer: {
    flex: 1,
  },
  title: {
    marginLeft: 8,
    color: CareshipColors.white,
  },
  description: {
    color: CareshipColors.white,
    marginLeft: 32,
    lineHeight: 24,
  },
});

interface TopBannerProps {
  icon: ReactNode;
  title: ReactNode;
  description?: ReactNode;
  backgroundColor?: 'darkGray' | 'green' | 'gray';
}

export default function TopBanner({
  icon,
  title,
  description,
  backgroundColor = 'gray',
}: TopBannerProps) {
  return (
    <View style={[styles.container, styles[backgroundColor]]}>
      <View style={styles.titleRow}>
        <View>{icon}</View>
        <View style={styles.titleContainer}>
          <Text bold size="large" style={styles.title}>
            {title}
          </Text>
        </View>
      </View>
      {description && (
        <Text size="large" style={styles.description}>
          {description}
        </Text>
      )}
    </View>
  );
}
