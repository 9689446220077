import { DefaultTheme } from 'react-native-paper';

export const CareshipColors = {
  primary: '#FFB300',
  secondary: '#3B9BA9',
  green: '#55AD9A',
  red: '#E02553',
  error: 'rgba(230,51,85,0.15)',
  white: '#FFFFFF',
  black: '#000000',
  blue: '#297EE1',
  slate050: '#F4F6F9', // Background
  slate100: '#E6EBF1', // Light dividers and borders / lines,
  slate200: '#C9D1D8', // Darker divider and border / lines,
  slate300: '#93A3B2', // Secondary text, input boarders, some icons,
  slate400: '#495159', // Header, icons, body color, input - active ,
  rippleColor: '#FFB3001A',
};

const basierCircleBold = 'basier-circle-bold';
const basierCircleRegular = 'basier-circle-regular';

const CSTheme: ReactNativePaper.Theme = {
  version: 2,
  dark: false,
  mode: 'exact',
  roundness: 8,
  colors: {
    ...DefaultTheme.colors,
    primary: CareshipColors.primary,
    background: CareshipColors.white,
    surface: CareshipColors.white,
    accent: CareshipColors.secondary,
    error: CareshipColors.red,
    text: CareshipColors.slate400,
  },
  fonts: {
    bodyLarge: {
      fontFamily: basierCircleRegular
    },
    bodySmall: {
      fontFamily: basierCircleRegular
    },
    medium: {
      fontFamily: basierCircleBold,
      fontWeight: 'normal',
    },
    regular: {
      fontFamily: basierCircleRegular,
      fontWeight: 'normal',
    },
    light: {
      fontFamily: basierCircleRegular,
      fontWeight: 'normal',
    },
    thin: {
      fontFamily: basierCircleRegular,
      fontWeight: 'normal',
    },
  },
  animation: {
    scale: 1,
  },
};

export default CSTheme;
