import { Linking } from 'react-native';

export default (path: string) => {
  if (/^\/?apply/.test(path)) {
    void Linking.openURL('https://bewerbung.careship.de/bewerbungsformular/');
    return '';
  }

  if (/^\/?new-password(.)+type=signup/.test(path)) {
    void Linking.openURL(`https://careship.de/bewerberportal/${path.replace(/^\//, '')}`);
    return '';
  }

  return path
    .replace(/^\/?home/, '/')
    .replace(/\/?bookings\/proposals\/?\?serviceRequestId=([^&]+).*/, '/customers/proposals/$1')
    .replace(/^\/?bookings\/proposals/, '/customers')
    .replace(/^\/?bookings(\/)?(open|done)/, '/bookings')
    .replace(/^\/?profile\/availability/, '/account/availability')
    .replace(/^\/?profile\/availability/, '/account/referral-code')
    .replace(/^\/?profile\/personal/, '/account/contact-and-address')
    .replace(/^\/?profile\/bank-account/, '/account/financial-overview')
    .replace(/^\/?profile/, '/account/profile')
    .replace(/^\/?reset-password/, '/request-password-reset');
};
