import { useCallback } from 'react';
import useConfirmAndSelectFile from './useConfirmAndSelectFile';
import { useUploadFile } from '../../../Infrastructure/Hook';

export default function usePickAndUploadFile(uploadUri: string, onUploadCompleted: () => void) {
  const confirmAndSelectFile = useConfirmAndSelectFile();
  const { uploadFile, status, progress } = useUploadFile();

  const showPickerAndUploadFile = useCallback(async () => {
    const file = await confirmAndSelectFile();

    if (file) {
      await uploadFile(file, uploadUri);
      onUploadCompleted();
    }
  }, [confirmAndSelectFile, onUploadCompleted, uploadFile, uploadUri]);

  return { status, showPickerAndUploadFile, progress };
}
