import React, { FC } from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';

const Form: FC<{ style: StyleProp<ViewStyle> }> = ({ style, ...props }) => (
  <View style={style}>
    <form {...props} />
  </View>
);

export default Form;
