import { useIntl } from 'react-intl';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Route } from '@react-navigation/core';

type FormatFn = (
  options: { title?: string } | undefined,
  route: Pick<Route<string>, 'name'> | undefined,
) => string;

export default function useDocumentTitleFormatter(): FormatFn {
  const intl = useIntl();

  return (options, route) => {
    const title = options?.title ?? route?.name;

    return intl.formatMessage({ id: 'DOCUMENT_TITLE' }, { title });
  };
}
