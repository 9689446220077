import React, { ReactElement } from 'react';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { Feather } from '@expo/vector-icons';
import { useLazyLoadQuery } from 'react-relay/hooks';
import { graphql } from 'react-relay';
import ModalHeader from '../../Component/ModalHeader';
import { intl } from '../../../Infrastructure/Intl';
import { CareshipColors } from '../../Component/CSTheme';
import { KeyboardAvoidingSafeScrollView, Loading } from '../../Component';
import { AppNavigationParamList } from '../../Root/Navigator/AppNavigator';
import useBookingService from '../../../Context/Booking/Hook/useBookingService';
import { isError } from '../../../Infrastructure/Service';
import FirstBookingUpdateConfirmationStatusForm from '../Component/FirstBookingUpdateConfirmationStatusForm';
import { CustomerStackNavigationProp } from '../Navigator/CustomerStackNavigationProp';
import { FirstBookingUpdateConfirmationStatusScreenQuery } from './__generated__/FirstBookingUpdateConfirmationStatusScreenQuery.graphql';
import { withLayout } from '../../HOC';

export default withLayout(function FirstBookingUpdateConfirmationStatusScreen(): ReactElement {
  const navigation = useNavigation<CustomerStackNavigationProp<'CustomerDashboard'>>();
  const route =
    useRoute<RouteProp<AppNavigationParamList, 'FirstBookingUpdateConfirmationStatus'>>();
  const { contract } = useLazyLoadQuery<FirstBookingUpdateConfirmationStatusScreenQuery>(
    graphql`
      query FirstBookingUpdateConfirmationStatusScreenQuery($contractId: ID!) {
        contract(contractId: $contractId) {
          careReceiver {
            name {
              firstName
              lastName
            }
          }
          ...FirstBookingUpdateConfirmationStatusForm_contract
          firstBookingConfirmationStatus {
            reason
          }
        }
      }
    `,
    { contractId: route.params.contractId },
  );
  const bookingService = useBookingService();

  const handleSubmit = async ({
    bookingId,
    description,
    reason,
  }: {
    bookingId: string;
    reason: string;
    description: string;
  }): Promise<string | void> => {
    const result = await bookingService.firstBookingUpdateConfirmationStatus({
      bookingId,
      description,
      reason,
    });

    if (isError(result)) {
      return result.errorCode;
    }

    const bookingResult = await bookingService.fetchBooking(bookingId);

    if (isError(bookingResult)) {
      navigation.navigate('CustomerDashboard');

      return Promise.resolve();
    }

    navigation.goBack();

    return Promise.resolve();
  };

  const { lastName, firstName } = contract?.careReceiver.name || {};

  return (
    <>
      <ModalHeader
        backButton={<Feather name="x" size={24} color={CareshipColors.primary} />}
        title={intl.formatMessage({ id: 'FIRST_APPOINTMENT_UPDATE_STATUS_MODAL_TITLE' })}
      />
      {!contract ? (
        <Loading />
      ) : (
        <KeyboardAvoidingSafeScrollView>
          <FirstBookingUpdateConfirmationStatusForm
            title={intl.formatMessage({ id: 'NAME_FULL' }, { firstName, lastName })}
            contractFragment={contract}
            onSubmit={handleSubmit}
          />
        </KeyboardAvoidingSafeScrollView>
      )}
    </>
  );
});
