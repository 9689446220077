/**
 * @generated SignedSource<<4240a06d00093dd0200a9e6389b435e7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type Status = "created" | "fulfilled" | "invoiced" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ContractListItem_contract$data = {
  readonly contractId: string;
  readonly careReceiver: {
    readonly name: {
      readonly firstName: string;
      readonly lastName: string;
    };
    readonly address: {
      readonly street: string;
      readonly houseNumber: string;
      readonly postalCode: string;
      readonly city: string;
    };
  };
  readonly cancelationScheduledFor: string | null;
  readonly hasFulfilledBooking: boolean;
  readonly firstBooking: {
    readonly endDate: string;
    readonly status: Status;
  } | null;
  readonly " $fragmentType": "ContractListItem_contract";
};
export type ContractListItem_contract$key = {
  readonly " $data"?: ContractListItem_contract$data;
  readonly " $fragmentSpreads": FragmentRefs<"ContractListItem_contract">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ContractListItem_contract",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "contractId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CareReceiver",
      "kind": "LinkedField",
      "name": "careReceiver",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Name",
          "kind": "LinkedField",
          "name": "name",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "firstName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lastName",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Address",
          "kind": "LinkedField",
          "name": "address",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "street",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "houseNumber",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "postalCode",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "city",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cancelationScheduledFor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasFulfilledBooking",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Booking",
      "kind": "LinkedField",
      "name": "firstBooking",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "endDate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Contract",
  "abstractKey": null
};

(node as any).hash = "351de9dd3f976caddcabc1fca0e8cd73";

export default node;
