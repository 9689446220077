/**
 * @generated SignedSource<<6f99dfff7096dba1d0b64a13b3c3d3ad>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ServiceDocumentationRow_serviceDocumentationQuery$data = {
  readonly id: string;
  readonly month: string;
  readonly serviceDocumentationId: string;
  readonly lastUploadedFile: {
    readonly filename: string;
    readonly mimeType: string;
    readonly pathInStorage: string;
  } | null;
  readonly " $fragmentType": "ServiceDocumentationRow_serviceDocumentationQuery";
};
export type ServiceDocumentationRow_serviceDocumentationQuery$key = {
  readonly " $data"?: ServiceDocumentationRow_serviceDocumentationQuery$data;
  readonly " $fragmentSpreads": FragmentRefs<"ServiceDocumentationRow_serviceDocumentationQuery">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./serviceDocumentationRowRefetchQuery.graphql'),
      "identifierField": "id"
    }
  },
  "name": "ServiceDocumentationRow_serviceDocumentationQuery",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "month",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "serviceDocumentationId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "LastUploadedFile",
      "kind": "LinkedField",
      "name": "lastUploadedFile",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "filename",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "mimeType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "pathInStorage",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ServiceDocumentation",
  "abstractKey": null
};

(node as any).hash = "3ba7bbf719f025e745f0bc591455aaec";

export default node;
