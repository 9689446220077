/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import Constants from 'expo-constants';
import { Platform } from 'react-native';
import { useCallback, useState } from 'react';
import useServices from './useServices';
import useLogger from './useLogger';
import Manifest from '../Constants/Manifest';

const { apiEndpoint } = Manifest.extra as { apiEndpoint: string };

const apiBackendUrl = Constants?.expoConfig?.extra?.apiBackendUrl;

const apiURI: string = Platform.OS === 'web' ? apiEndpoint : apiBackendUrl ;

export interface FileData {
  uri: string;
  name: string;
  type: string;
}

export default function useUploadFile() {
  const [progress, setProgress] = useState(0);
  const [status, setStatus] = useState<'progress' | 'failed' | 'completed' | null>(null);
  const logger = useLogger();
  const { oauthService } = useServices();

  const uploadFile = useCallback(
    async (blob: FileData | Blob, uploadUrl: string): Promise<void> => {
      const formData = new FormData();
      // FormData append DOES expect an object in react native, blob silently fails
      // @ts-ignore
      formData.append('files', blob);
      setProgress(0);
      setStatus('progress');

      const token = await oauthService.asyncGetToken();

      return new Promise((resolve) => {
        logger.info(`uploading file to ${uploadUrl}`);
        const request = new XMLHttpRequest();
        request.open('POST', `${apiURI}${uploadUrl}`);
        request.setRequestHeader('Authorization', `Bearer ${token}`);

        request.upload.addEventListener('progress', (e) => {
          setProgress(e.loaded / e.total);
        });

        request.addEventListener('load', () => {
          if (request.status === 204) {
            logger.info(
              `file upload completed, response: ${request.status} - ${request.responseText}`,
            );
            setStatus('completed');
          } else {
            logger.error(
              `failed to upload file, response: ${request.status} - ${request.responseText}`,
            );
            setStatus('failed');
          }

          resolve();
        });

        request.send(formData);
      });
    },
    [logger, oauthService],
  );

  return { status, progress, uploadFile };
}
