import React from 'react';
import { StyleSheet, View } from 'react-native';
import { FormattedMessage } from 'react-intl';
import { Container, Title, Text } from '../../../Component';
import {
  CalendarEditIcon,
  MultipleActionTimeIcon,
  AccountingCoinsIcon,
  ChemieStimmtIcon,
} from '../../../Component/Icon';
import { BookingUpcomingIllustration } from '../../../Component/Illustration';

const styles = StyleSheet.create({
  container: {
    width: 320,
    alignSelf: 'center',
    alignItems: 'center',
  },
  illustration: {
    maxWidth: '100%',
  },
  title: {
    marginTop: 48,
    marginBottom: 20,
  },
  subline: {
    marginBottom: 24,
  },
  listItems: {
    paddingVertical: 24,
    alignSelf: 'center',
    alignItems: 'center',
    width: 320,
  },
  listIcon: {
    marginBottom: 16,
  },
  listTitle: {
    marginBottom: 8,
  },
});

export default function EmptyBookingList() {
  return (
    <Container vertical style={styles.container}>
      <BookingUpcomingIllustration style={styles.illustration} width={343} height={205} />
      <Title uppercase center size="h5" style={styles.title}>
        <FormattedMessage id="BOOKING_LIST_EMPTY_HEADER" />
      </Title>
      <Text size="xlarge" muted center style={styles.subline}>
        <FormattedMessage id="BOOKING_LIST_EMPTY_SUBLINE" />
      </Text>

      <View style={styles.listItems}>
        <MultipleActionTimeIcon style={styles.listIcon} />
        <Title center size="h4" style={styles.listTitle}>
          <FormattedMessage id="BOOKING_LIST_EMPTY_ITEM_1_HEADER" />
        </Title>
        <Text size="large" muted center>
          <FormattedMessage id="BOOKING_LIST_EMPTY_ITEM_1_TEXT" />
        </Text>
      </View>

      <View style={styles.listItems}>
        <CalendarEditIcon style={styles.listIcon} />
        <Title center size="h4" style={styles.listTitle}>
          <FormattedMessage id="BOOKING_LIST_EMPTY_ITEM_2_HEADER" />
        </Title>
        <Text size="large" muted center>
          <FormattedMessage id="BOOKING_LIST_EMPTY_ITEM_2_TEXT" />
        </Text>
      </View>

      <View style={styles.listItems}>
        <ChemieStimmtIcon style={styles.listIcon} />
        <Title center size="h4" style={styles.listTitle}>
          <FormattedMessage id="BOOKING_LIST_EMPTY_ITEM_3_HEADER" />
        </Title>
        <Text size="large" muted center>
          <FormattedMessage id="BOOKING_LIST_EMPTY_ITEM_3_TEXT" />
        </Text>
      </View>

      <View style={styles.listItems}>
        <AccountingCoinsIcon style={styles.listIcon} />
        <Title center size="h4" style={styles.listTitle}>
          <FormattedMessage id="BOOKING_LIST_EMPTY_ITEM_4_HEADER" />
        </Title>
        <Text size="large" muted center>
          <FormattedMessage id="BOOKING_LIST_EMPTY_ITEM_4_TEXT" />
        </Text>
      </View>
    </Container>
  );
}
