import React, { useEffect } from 'react';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { Feather } from '@expo/vector-icons';
import { useQueryLoader } from 'react-relay/hooks';
import AddBookingForm, { addBookingFormQuery } from '../Component/AddBookingForm/AddBookingForm';
import ModalHeader from '../../Component/ModalHeader';
import { intl } from '../../../Infrastructure/Intl';
import { CareshipColors } from '../../Component/CSTheme';
import useNewBooking from '../../../Context/Booking/Hook/useNewBooking';
import useBookings from '../../../Context/Booking/Hook/useBookings';
import { AppNavigationParamList, AppStackNavigationProp } from '../../Root/Navigator/AppNavigator';
import { withLayout } from '../../HOC';
import { AddBookingFormQuery } from '../Component/AddBookingForm/__generated__/AddBookingFormQuery.graphql';

type AddAppointmentScreenRouteProp = RouteProp<AppNavigationParamList, 'AddAppointment'>;

export default withLayout(function AddBookingScreen() {
  const navigation = useNavigation<AppStackNavigationProp<'AddAppointment'>>();
  const { refresh } = useBookings();
  const { submitBooking, isSuccess, errors, isLoading, successfulResponses } = useNewBooking();
  const { params } = useRoute<AddAppointmentScreenRouteProp>();
  const contractId = params?.contractId;
  const modalHeaderTitle = params?.modalHeaderTitle;
  const [preloadedQuery, loadQuery] = useQueryLoader<AddBookingFormQuery>(addBookingFormQuery);

  useEffect(() => {
    if (isSuccess) {
      refresh();
      navigation.goBack();
    }
  }, [refresh, isSuccess, navigation]);

  useEffect(() => {
    loadQuery(
      {},
      {
        fetchPolicy: 'store-and-network',
      },
    );
  }, [loadQuery]);

  return (
    <>
      <ModalHeader
        backButton={<Feather name="x" size={24} color={CareshipColors.primary} />}
        title={modalHeaderTitle || intl.formatMessage({ id: 'ADD_APPOINTMENT_MODAL_TITLE' })}
      />
      {preloadedQuery && (
        <AddBookingForm
          preloadedQuery={preloadedQuery}
          isLoading={isLoading}
          errors={errors}
          selectedContract={contractId}
          successfulResponses={successfulResponses}
          onSubmit={submitBooking}
        />
      )}
    </>
  );
});
