import { StyleSheet, View } from 'react-native';
import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Text, Container } from '../../../Component';
import { CareshipColors } from '../../../Component/CSTheme';
import { BulletIcon } from '../../../Component/Icon';

const styles = StyleSheet.create({
  title: {
    marginBottom: 16,
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    paddingHorizontal: 16,
    paddingTop: 16,
  },
  bullet: {
    marginRight: 16,
  },
  textContainer: {
    display: 'flex',
    flexShrink: 1,
  },
  description: {
    marginTop: 8,
    lineHeight: 22,
  },
});

export default function FirstBookingTips() {
  const intl = useIntl();

  return (
    <>
      <Container>
        <Text style={styles.title} size="xlarge" bold center>
          <FormattedMessage id="FIRST_CALL_CHECKLIST_FIRST_BOOKING_TIPS_TITLE" />
        </Text>
        <Text size="large" center>
          <FormattedMessage id="FIRST_CALL_CHECKLIST_FIRST_BOOKING_TIPS_DESCRIPTION" />
        </Text>
      </Container>
      {[
        {
          id: 'first-booking-item-1',
          title: intl
            .formatMessage({ id: 'FIRST_CALL_CHECKLIST_FIRST_BOOKING_TIP_1_TITLE' })
            .toUpperCase(),
          titleColor: CareshipColors.green,
          description: intl.formatMessage({
            id: 'FIRST_CALL_CHECKLIST_FIRST_BOOKING_TIP_1_DESCRIPTION',
          }),
        },
        {
          id: 'first-booking-item-2',
          title: intl
            .formatMessage({ id: 'FIRST_CALL_CHECKLIST_FIRST_BOOKING_TIP_2_TITLE' })
            .toUpperCase(),
          titleColor: CareshipColors.slate400,
          description: intl.formatMessage({
            id: 'FIRST_CALL_CHECKLIST_FIRST_BOOKING_TIP_2_DESCRIPTION',
          }),
        },
        {
          id: 'first-booking-item-3',
          title: intl
            .formatMessage({ id: 'FIRST_CALL_CHECKLIST_FIRST_BOOKING_TIP_3_TITLE' })
            .toUpperCase(),
          titleColor: CareshipColors.red,
          description: intl.formatMessage({
            id: 'FIRST_CALL_CHECKLIST_FIRST_BOOKING_TIP_3_DESCRIPTION',
          }),
        },
      ].map((item) => (
        <View key={item.id} style={styles.container}>
          <BulletIcon size={24} style={styles.bullet} />
          <View style={styles.textContainer}>
            <Text size="large" bold style={{ color: item.titleColor }}>
              {item.title}
            </Text>
            <Text size="medium" muted style={styles.description}>
              {item.description}
            </Text>
          </View>
        </View>
      ))}
    </>
  );
}
