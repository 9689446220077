import styled from '@emotion/native';
import { View } from 'react-native';
import { SpacedBoxProps } from '@emotion/react';
import withSpacing, { shouldForwardProp } from '../Root/Theme/withSpacing';

interface ContainerProps extends SpacedBoxProps {
  vertical?: boolean;
}

export default styled(View, {
  shouldForwardProp,
})<ContainerProps>(
  withSpacing(
    () => [],
    ({ vertical }) => ({
      marginHorizontal: 'base',
      marginVertical: vertical ? 'base' : undefined,
    }),
  ),
);
