import Name, { NameObject, NameResponse } from './Name';

export interface CustomerResponse {
  readonly name: NameResponse;
  readonly phone_number: string;
  readonly email: string;
}

export interface CustomerObject {
  readonly name: NameObject;
  readonly phoneNumber: string;
  readonly email: string;
}

export default class Customer implements CustomerObject {
  constructor(
    public readonly name: Name,
    public readonly phoneNumber: string,
    public readonly email: string,
  ) {}

  public static fromObject(customer: CustomerObject) {
    return new Customer(Name.fromObject(customer.name), customer.phoneNumber, customer.email);
  }

  public static fromResponse(response: CustomerResponse) {
    return new Customer(Name.fromResponse(response.name), response.phone_number, response.email);
  }
}
