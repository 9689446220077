import * as React from 'react';
import Svg, { Path, Rect, SvgProps } from 'react-native-svg';
import { CareshipColors } from '../CSTheme';

interface Smiley300Props extends SvgProps {
  color?: string;
  size?: number;
}

export default function Smiley300({
  color = CareshipColors.slate400,
  size = 48,
  ...props
}: Smiley300Props) {
  return (
    <Svg width={size} height={size} viewBox="0 0 48 48" {...props}>
      {/* the Rect serves as hit area when the SVG has an onPress handler */}
      <Rect width={48} height={48} fill="transparent" />
      <Path
        d="M24 2c12.2 0 22 9.8 22 22s-9.8 22-22 22S2 36.2 2 24 11.8 2 24 2zm0 3C13.5 5 5 13.5 5 24s8.5 19 19 19 19-8.5 19-19S34.5 5 24 5zm7.9 26.3c.7.5.9 1.4.4 2.1-1.6 2.3-4.4 3.4-8.4 3.5H19.5c-.8 0-1.5-.7-1.5-1.5s.6-1.4 1.3-1.5h4c3.4 0 5.5-.8 6.5-2.2.5-.7 1.4-.9 2.1-.4zM19 16c1.7 0 3 1.3 3 3s-1.3 3-3 3-3-1.3-3-3 1.3-3 3-3zm10 0c1.7 0 3 1.3 3 3s-1.3 3-3 3-3-1.3-3-3 1.3-3 3-3z"
        fill={color}
      />
    </Svg>
  );
}
