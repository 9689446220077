import { useIsFocused } from '@react-navigation/native';
import { StackNavigationOptions } from '@react-navigation/stack';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import navigationContainerRefContext from '../Navigation/navigationContainerRefContext';

export type ScreenOptions = Pick<StackNavigationOptions, 'headerShown' | 'title'>;

export default function useScreenOptions(): ScreenOptions {
  const navigationContainerRef = useContext(navigationContainerRefContext);
  const [screenOptions, setScreenOptions] = useState<ScreenOptions>({});
  const prevScreenOptions = useRef<ScreenOptions>({});
  const isFocused = useIsFocused();

  const setScreenOptionsIfChanged = useCallback(
    ({ title, headerShown }: ScreenOptions) => {
      if (!isFocused || title === prevScreenOptions.current.title) {
        return;
      }

      prevScreenOptions.current = { title, headerShown };

      setScreenOptions({ title, headerShown });
    },
    [isFocused],
  );

  useEffect(() => {
    setScreenOptionsIfChanged(navigationContainerRef?.getCurrentOptions() || {});

    return navigationContainerRef?.addListener('options', ({ data: { options } }) => {
      setScreenOptionsIfChanged(options);
    });
  }, [setScreenOptionsIfChanged, navigationContainerRef]);

  return screenOptions;
}
