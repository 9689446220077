/**
 * @generated SignedSource<<c0acc85a6530f8ea43505f059592fea9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type BookingDetailQuery$variables = {
  contractId: string;
};
export type BookingDetailQuery$data = {
  readonly contract: {
    readonly id: string;
    readonly serviceRequest: {
      readonly schedule: {
        readonly isFlexible: boolean;
      };
    };
  } | null;
};
export type BookingDetailQuery = {
  variables: BookingDetailQuery$variables;
  response: BookingDetailQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "contractId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "contractId",
        "variableName": "contractId"
      }
    ],
    "concreteType": "Contract",
    "kind": "LinkedField",
    "name": "contract",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ServiceRequest",
        "kind": "LinkedField",
        "name": "serviceRequest",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Schedule",
            "kind": "LinkedField",
            "name": "schedule",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isFlexible",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BookingDetailQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BookingDetailQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "2be303b4c9013d6c0e10c7aedbcc4275",
    "id": null,
    "metadata": {},
    "name": "BookingDetailQuery",
    "operationKind": "query",
    "text": "query BookingDetailQuery(\n  $contractId: ID!\n) {\n  contract(contractId: $contractId) {\n    id\n    serviceRequest {\n      schedule {\n        isFlexible\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3ff0c3b6b1c18a67ad31b348f0f9688a";

export default node;
