import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';
import CSTheme from '../CSTheme';

interface WebpageIconProps extends SvgProps {
  color?: string;
  size?: number;
}

export default function WebPageIcon({ color, size = 24, ...props }: WebpageIconProps) {
  return (
    <Svg viewBox="0 0 24 24" width={size} height={size} {...props}>
      <Path
        d="M20.75 1C21.993 1 23 2.055 23 3.357v17.286C23 21.945 21.993 23 20.75 23H3.25C2.007 23 1 21.945 1 20.643V3.357C1 2.055 2.007 1 3.25 1zm.75 5.5h-19v14.143c0 .434.336.786.75.786h17.5c.414 0 .75-.352.75-.786V6.5zM19.281 18c.397 0 .719.336.719.75a.74.74 0 01-.621.743l-.098.007H14.97c-.397 0-.719-.336-.719-.75 0-.38.27-.693.621-.743L14.97 18h4.312zm-7.031 0a.75.75 0 01.102 1.493l-.102.007h-7.5a.75.75 0 01-.102-1.493L4.75 18h7.5zm7-3.25a.75.75 0 01.102 1.493l-.102.007H4.75a.75.75 0 01-.102-1.493l.102-.007h14.5zm-.705-6.25C19.35 8.5 20 9.172 20 10v1.5c0 .828-.651 1.5-1.455 1.5H5.455C4.65 13 4 12.328 4 11.5V10c0-.828.651-1.5 1.455-1.5zm0 1.5H5.455v1.5h13.09V10zm2.205-7.429H3.25c-.414 0-.75.352-.75.786V5h19V3.357c0-.434-.336-.786-.75-.786z"
        fill={color || CSTheme.colors.text}
      />
    </Svg>
  );
}
