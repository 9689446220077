import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Container, Title } from '../../../Component';
import { CareshipColors } from '../../../Component/CSTheme';

interface FeedbackFrequencyPromptProps {
  onFrequencyChange: (frequency: 1 | 3) => void;
  onDisableFeedback: () => void;
}

export default function FeedbackFrequencyPrompt({
  onDisableFeedback,
  onFrequencyChange,
}: FeedbackFrequencyPromptProps) {
  return (
    <>
      <Container vertical>
        <Title size="h5" center>
          <FormattedMessage id="BOOKING_FEEDBACK_SET_FREQUENCY_TITLE" />
        </Title>
      </Container>
      <Container vertical>
        <Button size="sm" color={CareshipColors.secondary} onPress={() => onFrequencyChange(1)}>
          <FormattedMessage id="BOOKING_FEEDBACK_SET_FREQUENCY_ALWAYS" />
        </Button>
      </Container>
      <Container>
        <Button size="sm" color={CareshipColors.slate400} onPress={() => onFrequencyChange(3)}>
          <FormattedMessage id="BOOKING_FEEDBACK_SET_FREQUENCY_EVERY_THIRD" />
        </Button>
      </Container>
      <Container vertical>
        <Button size="sm" color={CareshipColors.red} onPress={onDisableFeedback}>
          <FormattedMessage id="BOOKING_FEEDBACK_SET_FREQUENCY_NEVER" />
        </Button>
      </Container>
    </>
  );
}
