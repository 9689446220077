import React, { useLayoutEffect } from 'react';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { graphql, useLazyLoadQuery } from 'react-relay/hooks';
import { Layout } from '../../Component';
import { CustomerNavigationParamList } from '../Navigator/CustomerStackNavigator';
import { CustomerStackNavigationProp } from '../Navigator/CustomerStackNavigationProp';
import ServiceDocumentationPeriod from '../Component/ServiceDocumentationPeriod';
import { ServiceDocumentationPeriodScreenQuery } from './__generated__/ServiceDocumentationPeriodScreenQuery.graphql';

function ServiceDocumentationPeriodLoader({
  serviceDocumentationId,
}: {
  serviceDocumentationId: string;
}) {
  const query = graphql`
    query ServiceDocumentationPeriodScreenQuery($serviceDocumentationId: ID!) {
      node(id: $serviceDocumentationId) {
        ...ServiceDocumentationPeriodScreen_serviceDocumentationQuery
      }
    }
  `;
  const { node } = useLazyLoadQuery<ServiceDocumentationPeriodScreenQuery>(
    query,
    {
      serviceDocumentationId,
    },
    {},
  );
  return <>{node && <ServiceDocumentationPeriod fragment={node} />}</>;
}

export default function ServiceDocumentationPeriodScreen() {
  const navigation = useNavigation<CustomerStackNavigationProp<'ServiceDocumentationPeriod'>>();
  const {
    params: { serviceDocumentationPeriodId, title },
  } = useRoute<RouteProp<CustomerNavigationParamList, 'ServiceDocumentationPeriod'>>();

  useLayoutEffect(() => {
    navigation.setOptions({
      title,
    });
  }, [navigation, title]);

  return (
    <Layout>
      <ServiceDocumentationPeriodLoader serviceDocumentationId={serviceDocumentationPeriodId} />
    </Layout>
  );
}
