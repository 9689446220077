import Address, { AddressResponse } from './Address';

export type Gender = 'male' | 'female';

export type CareReceiverResponse = {
  readonly name: string;
  readonly gender: Gender;
  readonly address: AddressResponse;
  readonly age: number;
  readonly care_degree: string;
};

export default class CareReceiver {
  constructor(
    public readonly name: string,
    public readonly gender: Gender,
    public readonly address: Address,
    public readonly age: number,
    public readonly careDegree: string,
  ) {}

  static fromObject(careReceiver: CareReceiver) {
    return new CareReceiver(
      careReceiver.name,
      careReceiver.gender,
      Address.fromObject(careReceiver.address),
      careReceiver.age,
      careReceiver.careDegree,
    );
  }

  static fromResponse(response: CareReceiverResponse) {
    return new CareReceiver(
      response.name,
      response.gender,
      Address.fromResponse(response.address),
      response.age,
      response.care_degree,
    );
  }
}
