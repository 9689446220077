import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  dateGroup: {
    marginTop: 32,
    marginBottom: 16,
  },
  submitButton: {
    marginTop: 16,
  },
});
