import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { StyleSheet, View } from 'react-native';
import {IconButton, TextInput} from 'react-native-paper';
import {Button, Container, Label, Title} from '../../../Component';
import BookingFeedbackPrivacyHint from './BookingFeedbackPrivacyHint';
import { Smiley100, Smiley300, Smiley500 } from '../../../Component/Icon';
import { CareshipColors } from '../../../Component/CSTheme';
import {intl} from "../../../../Infrastructure/Intl";

const styles = StyleSheet.create({
  container: {
    marginTop: 15,
  },
  buttons: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 24,
  },
  button: {
    position: 'relative',
    zIndex: 1,
  },
  hitArea: {
    position: 'absolute',
    backgroundColor: 'blue',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
});

interface QuestionThreeProps {
  onSubmit: (value: { activitiesPerformed: string }) => void;
  isLoading: boolean;
}

export default function QuestionThree({ onSubmit, isLoading }: QuestionThreeProps) {
  const [fieldValue, setFieldValue] = useState<string>('');

  return (
    <>
      <Container style={styles.container}>
        <Title size="h5" center>
          <FormattedMessage id="BOOKING_FEEDBACK_TITLE_QUESTION_THREE" />
        </Title>
      </Container>
      <Container style={styles.container}>
        <Label />
        <TextInput mode='outlined' placeholder={intl.formatMessage({ id: 'BOOKING_FEEDBACK_ACTIVITIES_FREE_TEXT' })} onChangeText={(fieldValue) => setFieldValue(fieldValue)}/>
      </Container>
      <Container style={styles.container}>
        {/*<BookingFeedbackPrivacyHint />*/}
        <Button
          loading={isLoading}
          onPress={() => onSubmit({ activitiesPerformed: fieldValue })}
        >
          <FormattedMessage id="BOOKING_FEEDBACK_SUBMIT_STEP_3" />
        </Button>
      </Container>
    </>
  );
}
