import { StyleProp, ViewStyle } from 'react-native';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import { useFragment } from 'react-relay/hooks';
import { graphql } from 'react-relay';
import Title from '../../Component/Title';
import { CareshipColors } from '../../Component/CSTheme';
import { SectionListItem, Text } from '../../Component';
import { ProposalListItem_proposal$key } from './__generated__/ProposalListItem_proposal.graphql';

interface ProposalListItemProps {
  style?: StyleProp<ViewStyle>;
  addressStyle?: StyleProp<ViewStyle>;
  onPress: () => void;
  proposalFragment: ProposalListItem_proposal$key;
}

export default function ProposalListItem({
  onPress,
  addressStyle,
  style,
  proposalFragment,
}: ProposalListItemProps) {
  const { serviceRequestId, status, careReceiver } = useFragment(
    graphql`
      fragment ProposalListItem_proposal on Proposal {
        serviceRequestId
        status
        careReceiver {
          gender
          name
          address {
            street
            postalCode
            city
          }
        }
      }
    `,
    proposalFragment,
  );
  const labelColor = {
    OPEN: CareshipColors.primary,
    ACCEPTED: CareshipColors.secondary,
    DECLINED: CareshipColors.red,
  }[status as string];

  const {
    gender,
    name,
    address: { street, postalCode, city },
  } = careReceiver;

  return (
    <SectionListItem
      title={
        <Title size="h3" ellipsizeMode="tail" style={{ flex: 1 }} numberOfLines={1}>
          <FormattedMessage id="NAME_PARTIAL" values={{ gender, name }} />
        </Title>
      }
      style={style}
      onPress={onPress}
      label={<FormattedMessage id="PROPOSAL_STATUS" values={{ status }} />}
      labelColor={labelColor}
      testID={serviceRequestId}
    >
      <Text size="large" style={addressStyle}>
        {street || `${postalCode} ${city}`}
      </Text>
    </SectionListItem>
  );
}
