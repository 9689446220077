import * as React from 'react';
import Svg, { Path, Rect, SvgProps } from 'react-native-svg';
import { CareshipColors } from '../CSTheme';

interface Smiley100Props extends SvgProps {
  color?: string;
  size?: number;
}

export default function Smiley100({
  color = CareshipColors.slate400,
  size = 48,
  ...props
}: Smiley100Props) {
  return (
    <Svg width={size} height={size} viewBox="0 0 48 48" {...props}>
      {/* the Rect serves as hit area when the SVG has an onPress handler */}
      <Rect width={48} height={48} fill="transparent" />
      <Path
        d="M24 2c12.2 0 22 9.8 22 22s-9.8 22-22 22S2 36.2 2 24 11.8 2 24 2zm0 3C13.5 5 5 13.5 5 24s8.5 19 19 19 19-8.5 19-19S34.5 5 24 5zm-.7 26h.6c4 .1 6.8 1.2 8.4 3.5.5.7.3 1.6-.4 2.1s-1.6.3-2.1-.4c-1-1.4-3.1-2.2-6.5-2.2h-4c-.7-.1-1.3-.7-1.3-1.5s.7-1.5 1.5-1.5h3.8zM19 16c1.7 0 3 1.3 3 3s-1.3 3-3 3-3-1.3-3-3 1.3-3 3-3zm10 0c1.7 0 3 1.3 3 3s-1.3 3-3 3-3-1.3-3-3 1.3-3 3-3z"
        fill={color}
      />
    </Svg>
  );
}
