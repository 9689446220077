/**
 * @generated SignedSource<<629ef37847aa43b0b9a2b11fe6cc4810>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type FirstBookingStatus = "confirmed" | "created" | "declined" | "%future added value";
export type fetchAllContractsQuery$variables = {};
export type fetchAllContractsQuery$data = {
  readonly allContracts: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly hasFulfilledBooking: boolean;
        readonly cancelationScheduledFor: string | null;
        readonly firstBookingStatus: FirstBookingStatus;
        readonly firstBookingConfirmationStatus: {
          readonly reason: string;
        } | null;
      };
    } | null> | null;
  } | null;
};
export type fetchAllContractsQuery = {
  variables: fetchAllContractsQuery$variables;
  response: fetchAllContractsQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ContractConnection",
    "kind": "LinkedField",
    "name": "allContracts",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ContractEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Contract",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasFulfilledBooking",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cancelationScheduledFor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "firstBookingStatus",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "firstBookingConfirmationStatus",
                "kind": "LinkedField",
                "name": "firstBookingConfirmationStatus",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "reason",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "fetchAllContractsQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "fetchAllContractsQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "f256e418368afeb437d0c11ae029b0fd",
    "id": null,
    "metadata": {},
    "name": "fetchAllContractsQuery",
    "operationKind": "query",
    "text": "query fetchAllContractsQuery {\n  allContracts {\n    edges {\n      node {\n        id\n        hasFulfilledBooking\n        cancelationScheduledFor\n        firstBookingStatus\n        firstBookingConfirmationStatus {\n          reason\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "7af6afaee347f778c1a7e6a3cb9a86bb";

export default node;
