/**
 * @generated SignedSource<<5044ea235b24ce4476d3f2a3e3d8792e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type InvoiceType = "CUSTOMER" | "COMMISSION" | "CUSTOMER_STORNO" | "COMMISSION_STORNO" | "%future added value";
export type InvoiceListQuery$variables = {};
export type InvoiceListQuery$data = {
  readonly allInvoices: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly contractId: string;
        readonly caregiverId: string;
        readonly customerId: string;
        readonly invoice: {
          readonly invoiceId: string;
          readonly creationDate: string;
          readonly invoicingPeriodTo: string;
          readonly invoiceNumber: string;
          readonly invoiceType: InvoiceType;
          readonly originalInvoiceId: string | null;
        };
        readonly careReceiver: {
          readonly isAnonymized: boolean;
          readonly name: {
            readonly firstName: string;
            readonly lastName: string;
          };
        };
      };
    } | null> | null;
  } | null;
};
export type InvoiceListQuery = {
  variables: InvoiceListQuery$variables;
  response: InvoiceListQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "contractId",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "caregiverId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "customerId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "InvoiceData",
  "kind": "LinkedField",
  "name": "invoice",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "invoiceId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "creationDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "invoicingPeriodTo",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "invoiceNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "invoiceType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "originalInvoiceId",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "CareReceiverData",
  "kind": "LinkedField",
  "name": "careReceiver",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isAnonymized",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Name",
      "kind": "LinkedField",
      "name": "name",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "firstName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lastName",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "InvoiceListQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "InvoiceConnection",
        "kind": "LinkedField",
        "name": "allInvoices",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "InvoiceEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Invoice",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  (v1/*: any*/),
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "InvoiceListQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "InvoiceConnection",
        "kind": "LinkedField",
        "name": "allInvoices",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "InvoiceEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Invoice",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  (v1/*: any*/),
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c736723f7702ca814504df14dd157dee",
    "id": null,
    "metadata": {},
    "name": "InvoiceListQuery",
    "operationKind": "query",
    "text": "query InvoiceListQuery {\n  allInvoices {\n    edges {\n      node {\n        contractId\n        caregiverId\n        customerId\n        invoice {\n          invoiceId\n          creationDate\n          invoicingPeriodTo\n          invoiceNumber\n          invoiceType\n          originalInvoiceId\n        }\n        careReceiver {\n          isAnonymized\n          name {\n            firstName\n            lastName\n          }\n        }\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "82e45f582e5018c754fc3c6830a72b5a";

export default node;
