import React from 'react';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { Feather } from '@expo/vector-icons';
import { ScrollView } from 'react-native-gesture-handler';
import styled from '@emotion/native';
import { Layout } from '../../Component';
import CreateAbsenceForm from '../Component/CreateAbsenceForm';
import { AppNavigationParamList, AppStackNavigationProp } from '../../Root/Navigator/AppNavigator';
import ModalHeader from '../../Component/ModalHeader';
import { CareshipColors } from '../../Component/CSTheme';
import { intl } from '../../../Infrastructure/Intl';

const Scroll = styled(ScrollView)({
  marginBottom: 32,
});

function CreateAbsence({ absenceEdgeId }: { absenceEdgeId: string }) {
  const navigation = useNavigation<AppStackNavigationProp<'CreateAbsence'>>();

  const handleSuccess = () => {
    void navigation.goBack();
  };

  return <CreateAbsenceForm onCompleted={handleSuccess} absenceEdgeId={absenceEdgeId} />;
}

export default function CreateAbsenceScreen() {
  const {
    params: { absenceEdgeId },
  } = useRoute<RouteProp<AppNavigationParamList, 'CreateAbsence'>>();
  return (
    <Layout>
      <Scroll>
        <ModalHeader
          backButton={<Feather name="x" size={24} color={CareshipColors.primary} />}
          title={intl.formatMessage({ id: 'ABSENCE_SCREEN_TITLE' })}
        />
        <CreateAbsence absenceEdgeId={absenceEdgeId} />
      </Scroll>
    </Layout>
  );
}
