import { formatISO } from 'date-fns';
import { useState } from 'react';
import useBookingService from './useBookingService';
import { ErrorResponse, isError } from '../../../Infrastructure/Service';
import useLogger from '../../../Infrastructure/Hook/useLogger';

export default function useCancelBooking() {
  const bookingService = useBookingService();
  const logger = useLogger();
  const [error, setError] = useState<ErrorResponse>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>();

  const submitBooking = ({
    bookingId,
    startDate,
    endDate,
  }: {
    bookingId: string;
    startDate: Date;
    endDate: Date;
  }) => {
    void (async () => {
      setIsLoading(true);
      const result = await bookingService.updateBooking({
        bookingId,
        startDate: formatISO(startDate),
        endDate: formatISO(endDate),
      });

      setIsLoading(false);

      // likely overlapping booking if reason is present
      if (isError(result)) {
        setError(result);

        return;
      }

      setIsSuccess(true);

      logger.info(
        `Cancelled booking (${bookingId}) ${startDate.toISOString()} - ${endDate.toISOString()}`,
        '',
      );
    })();
  };

  return {
    submitBooking,
    error,
    isLoading,
    isSuccess,
  };
}
