import React, { ReactElement } from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';
import Text from './Text';
import CSTheme, { CareshipColors } from './CSTheme';

function maxBadgeCount(value: number) {
  if (value > 99) {
    return '99+';
  }

  return value.toString();
}

const styles = StyleSheet.create({
  outerSmall: {
    flex: 0,
    borderRadius: 8,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 2,
    backgroundColor: 'white',
    flexShrink: 0,
  },
  outerMedium: {
    flex: 0,
    flexShrink: 0,
    flexGrow: 0,
    borderRadius: 10,
    justifyContent: 'center',
    padding: 2,
    backgroundColor: 'white',
  },
  inner: {
    flexShrink: 0,
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
  },
  mediumInner: {
    borderRadius: 10,
    height: 20,
    paddingHorizontal: 4,
  },
  smallInner: {
    borderRadius: 10,
    height: 14,
    paddingHorizontal: 4,
  },
  largeInner: {
    borderRadius: 11,
    height: 22,
    paddingHorizontal: 6,
  },
  singleInnerLarge: {
    width: 14,
  },
  singleInnerSmall: {
    width: 14,
  },
  text: {
    flexShrink: 0,
    alignSelf: 'stretch',
    textAlign: 'center',
    textAlignVertical: 'center',
    color: 'white',
    ...CSTheme.fonts.medium,
  },
  textSmall: {
    fontSize: 9,
    lineHeight: 11,
  },
  textLarge: {
    fontSize: 12,
    lineHeight: 13.5,
  },
});

export default function Badge({
  children,
  small,
  medium,
  style,
  color,
}: {
  children: number | string;
  style?: StyleProp<ViewStyle>;
  small?: boolean;
  medium?: boolean;
  color?: string;
}): ReactElement {
  const label = typeof children === 'number' ? maxBadgeCount(children) : children;
  let minWidth: number = 22;

  if (medium) {
    minWidth = 20;
  }

  if (small) {
    minWidth = 14;
  }

  // Using nested Views to create a border, a normal border gets very small background
  // bleed around it.
  const singleInnerStyle = small ? styles.singleInnerSmall : styles.singleInnerLarge;
  const singleCharacter = label.length === 1;

  return (
    <View style={[small && styles.outerSmall, medium && styles.outerMedium, style]}>
      <View
        style={[
          styles.inner,
          !small && !medium && styles.largeInner,
          small && styles.smallInner,
          medium && styles.mediumInner,
          singleCharacter && singleInnerStyle,
          {
            backgroundColor: color || CareshipColors.red,
            minWidth,
          },
        ]}
      >
        <Text bold style={[styles.text, small ? styles.textSmall : styles.textLarge]}>
          {label}
        </Text>
      </View>
    </View>
  );
}
