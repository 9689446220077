import React, { useCallback, useLayoutEffect } from 'react';
import { useLazyLoadQuery } from 'react-relay/hooks';
import { graphql } from 'react-relay';
import { ScrollView } from 'react-native-gesture-handler';
import { Platform } from 'react-native';
import { IconButton } from 'react-native-paper';
import { Feather } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { FormattedMessage } from 'react-intl';
import styled from '@emotion/native';
import { Button, CSTheme, HeaderActionButton, Layout } from '../../Component';
import FinancialDetails from '../Component/FinancialDetails';
import { FinancialDetailsScreenQuery } from './__generated__/FinancialDetailsScreenQuery.graphql';
import { intl } from '../../../Infrastructure/Intl';
import { events, trackEvent } from '../../../Infrastructure/Tracking/Tracking';
import { AccountStackNavigationProp } from '../Navigator/AccountStackNavigationProp';
import useProfile from "../../../Context/Profile/Hook/useProfile";

const FinancialFormButton = styled(Button)<{}>(({ theme: { spacingTokens, spacing } }) => [
  {
    marginVertical: spacingTokens.lg,
    alignSelf: 'flex-start',
  },
  spacing({ marginHorizontal: 'base' }),
]);

interface FinancialDetailsContainerProps {
  handleEditFinancialDetails: () => void;
}

function FinancialDetailsContainer({ handleEditFinancialDetails }: FinancialDetailsContainerProps) {

  const {profile} = useProfile();
  const data = useLazyLoadQuery<FinancialDetailsScreenQuery>(
    graphql`
      query FinancialDetailsScreenQuery {
        me {
          ... on Caregiver {
            ...FinancialDetailsFragment
          }
        }
      }
    `,
    {},
  );

  return (
    <ScrollView>
      <FinancialDetails onPress={handleEditFinancialDetails} fragmentRef={data.me} />

      {Platform.OS === 'web' && !profile?.isCareshipEmployee &&(
        <FinancialFormButton
          size="sm"
          icon={<Feather color="white" size={24} name="edit" />}
          onPress={handleEditFinancialDetails}
        >
          <FormattedMessage id="BUTTON_EDIT" />
        </FinancialFormButton>
      )}
    </ScrollView>
  );
}

export default function FinancialDetailsScreen() {

  const navigation = useNavigation<AccountStackNavigationProp<'FinancialDetails'>>();

  const handleEditFinancialDetails = useCallback(() => {
    navigation.navigate('EditFinancialDetails');
    trackEvent(events.EDIT_FINANCIAL_DETAILS_CLICK);
  }, [navigation]);

  useLayoutEffect(() => {
    const headerRight = () =>
      Platform.select({
        ios: (
          <HeaderActionButton
            onPress={handleEditFinancialDetails}
            title={intl.formatMessage({ id: 'BUTTON_EDIT' })}
          />
        ),
        android: (
          <IconButton
            style={{ marginRight: 8 }}
            onPress={handleEditFinancialDetails}
            icon={() => <Feather color={CSTheme.colors.background} size={24} name="edit" />}
          />
        ),
      });

    navigation.setOptions({
      headerRight,
    });
  }, [handleEditFinancialDetails, navigation]);

  return (
    <Layout>
      <FinancialDetailsContainer handleEditFinancialDetails={handleEditFinancialDetails} />
    </Layout>
  );
}
