/**
 * @generated SignedSource<<bcd29b34ff33013e85695993c872068b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CustomerSatisfaction_customerSatisfactionScore$data = {
  readonly customerSatisfactionScore: number | null;
  readonly " $fragmentType": "CustomerSatisfaction_customerSatisfactionScore";
};
export type CustomerSatisfaction_customerSatisfactionScore$key = {
  readonly " $data"?: CustomerSatisfaction_customerSatisfactionScore$data;
  readonly " $fragmentSpreads": FragmentRefs<"CustomerSatisfaction_customerSatisfactionScore">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CustomerSatisfaction_customerSatisfactionScore",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "customerSatisfactionScore",
      "storageKey": null
    }
  ],
  "type": "CaregiverStats",
  "abstractKey": null
};

(node as any).hash = "0d7a11ae294a972096b2c934c1026e26";

export default node;
