import React, { Suspense } from 'react';
import styled, { css } from '@emotion/native';
import { Linking, Text } from 'react-native';
import { CareshipLogo } from './index';
import { CareshipColors } from './CSTheme';
import SuspenseFullScreenFallback from './SuspenseFullScreenFallback';
// @ts-expect-error
import background from '../../../assets/login-background.jpg';
import type { AuthLayoutProps } from './AuthLayout';

const Wrapper = styled.View({
  flex: 1,
  alignItems: 'center',
  justifyContent: 'center',
});

const Page = styled.View({
  flex: 1,
  justifyContent: 'flex-start',
  position: 'relative',
});

// @ts-expect-error (boxShadow/lineHeight only applies to web)
const Header = styled.View({
  backgroundColor: 'white',
  paddingLeft: 22,
  lineHeight: 60,
  boxShadow: '0 1px 0 0 rgba(0, 0, 0, .1)',
  height: 61,
});

const BackgroundImage = styled.Image({
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
});

const logoStyles = css({
  position: 'relative',
  top: 1,
  maxWidth: 170,
});

const url = 'https://www.careship.de/';

export default function AuthLayout({ children }: AuthLayoutProps) {
  return (
    <Page>
      {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
      <BackgroundImage accessibilityIgnoresInvertColors source={background} />
      <Header>
        {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
        <Text style={{ width: 170 }} onPress={() => Linking.openURL(url)}>
          <CareshipLogo color={CareshipColors.primary} style={logoStyles} />
        </Text>
      </Header>
      <Wrapper>
        <Suspense fallback={<SuspenseFullScreenFallback />}>{children}</Suspense>
      </Wrapper>
    </Page>
  );
}
