export default class Pricing {
  constructor(
    public readonly durationInMinutes: number,
    public readonly grossPricePerHourInCents: number,
  ) {}

  get grossPriceInCents(): number {
    return this.grossPricePerHourInCents * (this.durationInMinutes / 60);
  }
}
