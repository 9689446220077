import React, { ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from '@emotion/native';
import { View } from 'react-native';
import { Link } from '@react-navigation/native';
import { Title, Text } from '../../Component';
import { WithoutTheme } from '../../Root/Theme/WithoutTheme';
import ReferralCodeCard from './ReferralCodeCard';
import CSTheme from '../../Component/CSTheme';
import useShare from '../../../Infrastructure/Hook/useShare';

const StepContainer = styled.View(({ theme: { breakpoints } }) => [
  {
    marginHorizontal: 16,
    marginBottom: 32,
    display: 'flex',
  },
  breakpoints.up('md', {
    flexDirection: 'row',
  }),
]);

const StepNumber = styled.View(({ theme: { colors, breakpoints } }) => [
  {
    borderRadius: 32,
    borderColor: colors.slate400,
    borderWidth: 1,
    height: 32,
    width: 32,
    display: 'flex',
    justifyContent: 'center',
    alignSelf: 'center',
    marginBottom: 8,
  },
  breakpoints.up('md', {
    alignSelf: 'flex-start',
    marginRight: 16,
  }),
]);

const StepContent = styled.View({
  flexShrink: 1,
});

const StepsTitle = styled<WithoutTheme<typeof Text>>(Text)(({ theme: { breakpoints } }) => [
  {
    marginBottom: 24,
    alignSelf: 'center',
    marginHorizontal: 16,
  },
  breakpoints.up('md', {
    alignSelf: 'flex-start',
  }),
]);

const StepTitle = styled(Title)({
  marginBottom: 8,
});

const BankAccountLink = styled<WithoutTheme<typeof Link>>(Link)(({ theme: { colors } }) => ({
  color: colors.primary,
  fontFamily: CSTheme.fonts.medium.fontFamily,
}));

interface StepProps {
  number: string | number;
  children: ReactNode;
}

function Step({ number, children }: StepProps) {
  return (
    <StepContainer>
      <StepNumber>
        <Title size="h3" center>
          {number}
        </Title>
      </StepNumber>
      <StepContent>{children}</StepContent>
    </StepContainer>
  );
}

interface ReferralCodeProps {
  customerReferralAmount: number;
  caregiverReferralAmount: number;
  referralCode: string;
}

export default function ReferralCode({
  customerReferralAmount,
  caregiverReferralAmount,
  referralCode,
}: ReferralCodeProps) {
  const intl = useIntl();
  const { isShareSupported } = useShare();

  return (
    <View>
      <ReferralCodeCard
        customerReferralAmount={customerReferralAmount}
        caregiverReferralAmount={caregiverReferralAmount}
        referralCode={referralCode}
      />
      <StepsTitle bold muted uppercase>
        <FormattedMessage id="REFERRAL_CODE_STEPS_TITLE" />
      </StepsTitle>
      <Step number="1">
        <StepTitle size="h5">
          <FormattedMessage id="REFERRAL_CODE_STEPS_FIRST_TITLE" />
        </StepTitle>
        <Text size="large">
          {isShareSupported ? (
            <FormattedMessage id="REFERRAL_CODE_STEPS_FIRST_TEXT_SHARE_CODE" />
          ) : (
            <FormattedMessage id="REFERRAL_CODE_STEPS_FIRST_TEXT_COPY_CODE" />
          )}
        </Text>
      </Step>
      <Step number="2">
        <StepTitle size="h5">
          <FormattedMessage id="REFERRAL_CODE_STEPS_SECOND_TITLE" />
        </StepTitle>
        <Text size="large">
          <FormattedMessage id="REFERRAL_CODE_STEPS_SECOND_TEXT" />
        </Text>
      </Step>
      <Step number="3">
        <StepTitle size="h5">
          <FormattedMessage id="REFERRAL_CODE_STEPS_THIRD_TITLE" />
        </StepTitle>
        <Text size="large">
          <FormattedMessage
            id="REFERRAL_CODE_STEPS_THIRD_TEXT"
            values={{
              customerReferralAmount: intl.formatNumber(customerReferralAmount, {
                style: 'currency',
                currency: 'EUR',
                minimumFractionDigits: 0,
              }),
              caregiverReferralAmount: intl.formatNumber(caregiverReferralAmount, {
                style: 'currency',
                currency: 'EUR',
                minimumFractionDigits: 0,
              }),
              bankAccount: (
                <BankAccountLink to="/account/financial-overview/details">
                  <FormattedMessage id="REFERRAL_CODE_STEPS_THIRD_BANK_ACCOUNT" />
                </BankAccountLink>
              ),
            }}
          />
        </Text>
      </Step>
    </View>
  );
}
