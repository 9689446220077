import Name from './Name';
import ProfileImage, { ProfileImageObject } from './ProfileImage';
import { NameObject } from '../../Contract/Store/Model/Name';

export interface CaregiverProfile {
  readonly caregiverId: string;
  readonly name: {
    readonly firstName: string;
    readonly lastName: string;
  };
  readonly profileImage: ProfileImage;
  readonly isCareshipEmployee: boolean;
}

export interface ProfileObject {
  readonly caregiverId: string;
  readonly name: NameObject;
  readonly profileImage: ProfileImageObject;
  readonly isCareshipEmployee: boolean;
}

export default class Profile implements ProfileObject {
  constructor(
    public readonly caregiverId: string,
    public readonly name: Name,
    public readonly profileImage: ProfileImage,
    public readonly isCareshipEmployee: boolean,
  ) {}

  public get viewData(): CaregiverProfile {
    return {
      caregiverId: this.caregiverId,
      name: {
        firstName: this.name.firstName,
        lastName: this.name.lastName,
      },
      profileImage: this.profileImage,
      isCareshipEmployee: this.isCareshipEmployee,
    };
  }
}
