import { useEffect } from 'react';
import asyncRequestPushNotificationPermission from '../asyncRequestPushNotificationPermission';
import { logger } from '../../Service';
import { useStores } from '../../Store';
import FeatureSwitch from '../../Constants/FeatureSwitch';
import useProfile from '../../../Context/Profile/Hook/useProfile';
import usePushTokenService from './usePushTokenService';

export default function usePushTokenRegistration() {
  const { sessionStore } = useStores();
  const pushTokenService = usePushTokenService();
  const { profile } = useProfile();
  const caregiverId = profile?.caregiverId;

  useEffect(() => {
    let isCancelled = false;
    if (!caregiverId || !FeatureSwitch.notifications) {
      return () => {};
    }

    void (async () => {
      try {
        logger.debug('requesting push notification permission and token');
        const token = await asyncRequestPushNotificationPermission();

        if (isCancelled) {
          return;
        }

        sessionStore.setExpoPushToken(token);

        try {
          await pushTokenService.register();
        } catch (e) {
          logger.error('failed to register push token', e, { __dangerouslySkipSanitize: true });
        }
      } catch (e) {
        logger.info('push notifications not granted');
      }
    })();

    return () => {
      isCancelled = true;
      void (async () => {
        if (!caregiverId) {
          return;
        }

        sessionStore.setExpoPushToken(null);

        logger.debug('unregistering push token');

        try {
          await pushTokenService.deregister();
        } catch (e) {
          logger.warn('failed to deregister push token', e);
        }
      })();
    };
  }, [caregiverId, pushTokenService, sessionStore]);
}
