import { useCallback, useEffect, useRef, useState } from 'react';
import { reaction } from 'mobx';
import { Severity } from '@sentry/types';
import { useStores } from '../../../Infrastructure/Store';
import { BookingUnion } from '../Store/Model/Booking';
import Contract from '../../Contract/Store/Model/Contract';
import useLogger from '../../../Infrastructure/Hook/useLogger';
import useBookingService from './useBookingService';
import { useContractService } from '../../Contract/Hook';

// NOTE: contract is nullable to allow investigating why we see errors with missing contracts (CC-480)
export type BookingContractTuple = { booking: BookingUnion; contract: Contract | null };

export default function useBookings(filterFn?: (booking: BookingUnion) => boolean): {
  bookings: BookingContractTuple[];
  undocumentedBookingCount: number;
  refresh: () => void;
  isLoading: boolean;
} {
  const stores = useStores();
  const logger = useLogger();
  const bookingService = useBookingService();
  const contractService = useContractService();

  const [isLoading, setIsLoading] = useState(false);
  const isLoadingRef = useRef(isLoading);
  const [bookingData, setBookingList] = useState<{
    bookings: BookingContractTuple[];
    undocumentedBookingCount: number;
  }>({
    bookings: [],
    undocumentedBookingCount: 0,
  });

  useEffect(
    () =>
      reaction(
        () => ({
          bookings: stores.bookingStore.viewData.bookings,
          contract: stores.contractStore.viewData,
          undocumentedBookingCount: stores.bookingStore.viewData.undocumentedBookingCount,
        }),
        ({ bookings: bookingViewData, contract: contractViewData, undocumentedBookingCount }) => {
          let bookings: BookingContractTuple[] = [];

          if (contractViewData.length && bookingViewData.length) {
            logger.addBreadcrumb({
              level: Severity.Info,
              type: 'stores',
              data: {
                contractViewData,
                bookingViewData,
              },
            });
            bookings = bookingViewData
              .filter(
                (booking) =>
                  !(
                    booking.isFirstBooking &&
                    booking.firstBookingStatus === 'declined' &&
                    stores.contractStore.getContract(booking.contractId)?.isActive === false
                  ) && (filterFn ? filterFn(booking) : true),
              )
              .map((booking) => {
                const contract = stores.contractStore.getContract(booking.contractId);

                if (!contract) {
                  logger.error(
                    `Contract (${booking.contractId}) not found for booking ${booking.bookingId}`,
                  );
                }

                return { booking, contract };
              });
          }

          setBookingList({
            undocumentedBookingCount,
            bookings,
          });
        },
        {
          fireImmediately: true,
        },
      ),
    [filterFn, logger, stores.bookingStore, stores.contractStore],
  );

  const fetchData = useCallback(() => {
    let isCancelled = false;
    logger.debug('bookings refresh requested');

    if (isLoadingRef.current || isCancelled) {
      return () => {};
    }

    setIsLoading(true);
    void (async () => {
      await contractService.fetchContractList();
      await bookingService.fetchBookingList();

      if (!isCancelled) {
        setIsLoading(false);
      }
    })();
    return () => {
      isCancelled = true;
    };
  }, [bookingService, contractService, logger]);

  useEffect(fetchData, [fetchData]);

  return { ...bookingData, refresh: fetchData, isLoading };
}
