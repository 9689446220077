/**
 * @generated SignedSource<<b31779669e3785063611ea2eff343913>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type FirstBookingStatus = "confirmed" | "created" | "declined" | "%future added value";
export type Status = "created" | "fulfilled" | "invoiced" | "%future added value";
export type fetchSingleBookingQuery$variables = {
  bookingId: string;
};
export type fetchSingleBookingQuery$data = {
  readonly booking: {
    readonly id: string;
    readonly startDate: string;
    readonly endDate: string;
    readonly status: Status;
    readonly contract: {
      readonly id: string;
      readonly hasFulfilledBooking: boolean;
      readonly cancelationScheduledFor: string | null;
      readonly firstBookingStatus: FirstBookingStatus;
      readonly firstBookingConfirmationStatus: {
        readonly reason: string;
      } | null;
    };
  } | null;
};
export type fetchSingleBookingQuery = {
  variables: fetchSingleBookingQuery$variables;
  response: fetchSingleBookingQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "bookingId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "bookingId",
        "variableName": "bookingId"
      }
    ],
    "concreteType": "Booking",
    "kind": "LinkedField",
    "name": "booking",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "startDate",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "endDate",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Contract",
        "kind": "LinkedField",
        "name": "contract",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasFulfilledBooking",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "cancelationScheduledFor",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstBookingStatus",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "firstBookingConfirmationStatus",
            "kind": "LinkedField",
            "name": "firstBookingConfirmationStatus",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "reason",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "fetchSingleBookingQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "fetchSingleBookingQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "d5eeba74a2c34939fd7ecea7c5ef97eb",
    "id": null,
    "metadata": {},
    "name": "fetchSingleBookingQuery",
    "operationKind": "query",
    "text": "query fetchSingleBookingQuery(\n  $bookingId: ID!\n) {\n  booking(bookingId: $bookingId) {\n    id\n    startDate\n    endDate\n    status\n    contract {\n      id\n      hasFulfilledBooking\n      cancelationScheduledFor\n      firstBookingStatus\n      firstBookingConfirmationStatus {\n        reason\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5b5bf1e1c94f0b4341dfe5deac08aa58";

export default node;
