/**
 * @generated SignedSource<<068566544fef08c75c81d37620aeae21>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type FirstBookingStatus = "confirmed" | "created" | "declined" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type FirstCallActions_contract$data = {
  readonly contractId: string;
  readonly firstBookingStatus: FirstBookingStatus;
  readonly hasFulfilledBooking: boolean;
  readonly careReceiver: {
    readonly phoneNumber: string;
  };
  readonly " $fragmentType": "FirstCallActions_contract";
};
export type FirstCallActions_contract$key = {
  readonly " $data"?: FirstCallActions_contract$data;
  readonly " $fragmentSpreads": FragmentRefs<"FirstCallActions_contract">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FirstCallActions_contract",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "contractId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstBookingStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasFulfilledBooking",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CareReceiver",
      "kind": "LinkedField",
      "name": "careReceiver",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "phoneNumber",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Contract",
  "abstractKey": null
};

(node as any).hash = "33ac92745247b7cc1aa7722b20edadd9";

export default node;
